<div class="class-detail">
    <div class="class-header">
      <div class="row m-0">
        <div class="col-lg-12 col-md-12 d-flex">
          <div class="header-info">
            <div class="card-body pt-1">
              <div class="content-view">
                <div class="fs-16" [category-indicator]="classDetail.CategoryId"> {{classDetail.DisplayName |
                  translate}}</div>
                <div class="user-card__content-view" *ngIf="staffs && staffs.length">
                  <span class="trainer-name text-ellipsis">
                    {{staffs[0].fullName }}
                  </span>
                  <div class="class-detail__slots-show-action" *ngIf="staffs && staffs.length > 1"
                    [ngbPopover]="staffPopover" placement="auto" [autoClose]="'outside'" container="body">
                    +{{staffs.length-1}}
                  </div>
                </div>

                <div class="class-detail__location text-muted">
                  <div *ngFor="let location of locations  | slice:0:(showAll.location ? locations.length : 1 )"
                    [title]="location.value">
                    <span class="icon-Group-749 mr-small"></span> {{location?.value}}
                  </div>
                  <div class="privatelesson-booking-detail__slots-show-action ml-2"
                    *ngIf="locations && locations.length > 1" [ngbPopover]="locationPopover" placement="auto"
                    [autoClose]="'outside'" container="body">
                    +{{locations.length-1}}
                  </div>
                </div>
              </div>
              <div class="location-info">
                <div class="text-muted"><span class="icon-Calender"></span> {{classDetailDate | formatSettingsDate:
                  cs.settings.value.General.DateFormat}} | {{ classDetail.startTime | localizedDate : 'LT' }}
                  - {{ classDetail.endTime | localizedDate : 'LT' }}</div>
              </div>
            </div>
          </div>

          <div class="class-detail__filter">
            <div class="class-detail__search">
              <dynamic-form style="width:100%" [config]="searchconfig" #searchConfigForm>
              </dynamic-form>
              <div class="display_filter">
                <a [ngbPopover]="myPopover" placement="auto" [autoClose]="'outside'" container="body">
                  <span class="icon-Filter"><span class="path1"></span><span class="path2"></span><span
                      class="path3"></span></span>
                </a>
                <ng-template #myPopover>
                  <app-filter-content [displayOptions]="displayOptions" [isMessageAvailable]="true"
                    (optionSelection)="filterReservations($event)"></app-filter-content>
                </ng-template>
              </div>
            </div>
          </div>
        </div>
        <ng-template #descriptionPopover>
          <div class="description">{{classDetail.Description || ('No Description' | translate)}}</div>
        </ng-template>
      </div>
    </div>
    <div class="class-detail__subheader">
      <div class="class-detail__seat-actions">
        <activities-app-button *ngFor="let control of buttonControls" [buttontype]="control" (valueChange)="changeEvent(control.name)">
        </activities-app-button>
      </div>
    </div>
    <div class="booking-pannel">
      <div class="session-details" *ngIf="addonsForActivity.length > 0 && showAddOnIcon">
        <div class="addon-notes" *ngIf="addonsForActivity.length > 0 && showAddOnIcon">
          <div class="addon-container">
            <div class="addon-title"><i class="icon icon-addons"></i> {{'addons' | translate}}</div>
            <div class="addon-description">
              <div class="reservation-notes__notes-details" *ngFor="let addOn of addonsForActivity">
                <div>
                  <span>{{addOn.AddonId | getAddonName : _settings}}<span style="color:red" *ngIf="addOn.IsMandatory">
                      *</span></span>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
      <div class="booking-details" [ngClass]="{'w-100' : !(addonsForActivity.length > 0 && showAddOnIcon)}">
        <mat-tab-group
          [selectedIndex]="attendees && attendees.length > 0 ? 0 : classDetail.IsForStandbyReservations && (standbyAttendees && standbyAttendees.length > 0) ? 1 : 0">
          <mat-tab [label]="'Reservations' | translate">
            <ng-container
              *ngIf="(attendees | attendeesFilter : searchAttendeesText | filterByState : optionsForFilter) as result">
              <!-- <div class="attendees-count" *ngIf="result.length">
              {{result.length}} {{'Reservations' | translate}}
            </div> -->
              <div class="attendees">
                <table class="table">
                  <thead class="user-card user-card__data-header">
                    <tr>
                      <th>
                        <div class="user-sno">
                          <!-- <mat-checkbox></mat-checkbox> -->
                        </div>
                      </th>
                      <th>
                        <div class="user-card__content-view pointer noSelect"
                          (click)="sortClick(sortOptions.guestName)">
                          {{'GuestDetailText' | translate}}
                          <i class="sortIcon show"
                            [ngClass]="{'icon-Sort-Up': sortData.sortBy===sortOptions.guestName && sortData.sortOrder==='asc', 'icon-Sort-Down noSort': sortData.sortBy===sortOptions.guestName && sortData.sortOrder==='desc', 'icon-sort-arrow1 noSort': sortData.sortBy!==sortOptions.guestName}"></i>
                        </div>
                      </th>
                      <th>
                        <div class="pointer noSelect">
                          {{'bookingAndConfirmation' | translate}}
                        </div>
                      </th>
                      <th>
                        <div class="user-card__covers pointer noSelect" (click)="sortClick(sortOptions.size)">
                          {{'sizeText' | translate}}
                          <i class="sortIcon show"
                            [ngClass]="{'icon-Sort-Up': sortData.sortBy===sortOptions.size && sortData.sortOrder==='asc', 'icon-Sort-Down noSort': sortData.sortBy===sortOptions.size && sortData.sortOrder==='desc', 'icon-sort-arrow1 noSort': sortData.sortBy!==sortOptions.size}"></i>
                        </div>
                      </th>
                      <th>
                        <div class="user-card__payment">
                          {{'paymentText' | translate}}
                        </div>
                      </th>
                      <th>
                        <div class="user-card__status-col pointer noSelect" (click)="sortClick(sortOptions.status)">
                          {{'Status' | translate}}
                          <i class="sortIcon show"
                            [ngClass]="{'icon-Sort-Up': sortData.sortBy===sortOptions.status && sortData.sortOrder==='asc', 'icon-Sort-Down noSort': sortData.sortBy===sortOptions.status && sortData.sortOrder==='desc', 'icon-sort-arrow1 noSort': sortData.sortBy!==sortOptions.status}"></i>
                        </div>
                      </th>
                      <th class="table-action">
                        <div class="user-card__actions">
                          {{'actionText' | translate}}
                        </div>
                      </th>
                      <th>
                        <div class="user-card__delete">

                        </div>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr class="user-card"
                      *ngFor="let attendee of result | sortData: sortData.sortBy: sortData.sortOrder">
                      <td>
                        <div class="user-sno">
                          <!-- <mat-checkbox [checked]="attendee.selected == true"></mat-checkbox> -->
                          <i class="icon-VIP" [ngClass]="{'VIP-selected':attendee?.Contact.IsVip}"></i>
                        </div>
                      </td>
                      <td>
                        <div class="user-card__content-view" [ngStyle]="{'cursor' : (attendee.State != PartyState.Cancelled && attendee.State != PartyState.Left) ? 'pointer' : 'default'}" (click)="attendee.State != PartyState.Cancelled && attendee.State != PartyState.Left && !noActionRequired ? editParty(attendee) : false">
                          <h3 class="user-card__name text-ellipsis" *ngIf="attendee.Contact">
                            {{attendee?.Contact?.FirstName}} {{attendee?.Contact?.LastName || ''}}
                          </h3>
                          <p class="user-card__mobile text-ellipsis">
                            {{attendee?.Contact?.PhoneNumber | phoneNumberFormatter: attendee?.Contact?.CountryId}}</p>
                        </div>
                      </td>
                      <td>
                        <div class="reference-number">
                          <div class="conirmation">
                            {{attendee?.ConfirmationCode}}
                          </div>
                          <div class="reference">
                            {{attendee?.BookingReferenceId}}
                          </div>
                        </div>
                      </td>
                      <td>
                        <div class="user-card__covers">
                          <span *ngIf="attendee.Size"><span class="icon-add-contact"></span>
                            {{attendee.Size}}</span>
                        </div>
                      </td>
                      <td>
                        <div class="user-card__payment">
                          <ng-container>
                            <span
                              class="user-card__payment--label payment-{{attendee | partyPaymentState }}"
                              container="body" placement="auto" [autoClose]="'outside'"
                              [ngbPopover]="financialStatusPopover" (click)="financialDetails(attendee)">
                              {{(attendee | partyPaymentState) | translate }}
                            </span>
                            <!-- <span class="icon icon-information1 ml-2" ></span> -->
                            <!-- <span class="user-card__payment--amount">
                            {{_settings.General.OperationCurrency}}{{paymentDetail.amount | number : '1.2-2'}}
                          </span> -->
                          </ng-container>
                        </div>
                      </td>
                      <td>
                        <div class="user-card__status-col">
                          <div class="user-card__status user-card__status--{{attendee.State}}">
                            {{'Status_'+PartyState[attendee.State]
                            | translate}}</div>
                        </div>
                      </td>
                      <td class="table-action">
                        <div class="user-card__actions">
                          <button *ngIf="attendee.State == PartyState.Pending && !attendee.checkInWithDues" mat-button
                            class="btn btn--checkin" (click)="checkInClick(attendee)">
                            {{'CheckIn' | translate}}
                          </button>
                          <button *ngIf="attendee.checkInWithDues && attendee.State == PartyState.Pending" mat-button
                            [matMenuTriggerFor]="menu" class="btn btn--checkin"> {{'CheckIn' | translate}} <span
                              class="icon-down-arrow" style="font-size:10px"></span></button>
                          <mat-menu #menu="matMenu"
                            [hidden]="!(attendee.checkInWithDues && attendee.State == PartyState.Pending)">
                            <button mat-menu-item (click)="checkInClick(attendee)">{{'CheckIn' | translate}}</button>
                            <button mat-menu-item (click)="checkInWithDues(attendee)">{{'checkInWithDues' |
                              translate}}</button>
                          </mat-menu>
                          <button *ngIf="attendee.State == PartyState.Seated " mat-button
                          [matMenuTriggerFor]="menuForCheckOut" class="btn btn--checkin" [disabled]="noActionRequired"> {{'CheckOut' | translate}} <span
                            class="icon-down-arrow" style="font-size:10px"></span></button>
                          <mat-menu #menuForCheckOut="matMenu">
                          <button mat-menu-item (click)="undoCheckIn(attendee)">{{'Unseat' | translate}}</button>
                          <button mat-menu-item (click)="checkOutClick(attendee)">{{'CheckOut' | translate}}</button>
                        </mat-menu>
                        </div>
                      </td>
                      <td>
                        <div class="user-card__cancel">
                          <div class="package-booking user-card__data-align-content"
                            *ngIf="attendee.PackageName !== null && attendee.PackageName !== undefined" [matTooltipDisabled]="(attendee.PackageName !== null && attendee.PackageName !== undefined)" matTooltip="{{'packages' | translate}}">
                            <span class="icon-offer-redemption" [matTooltipDisabled]="!(attendee.PackageName !== null && attendee.PackageName !== undefined)" matTooltip="{{attendee.PackageName}}"></span>
                          </div>
                          <span class="icon icon-note mr-0 user-card__data-align-content" *ngIf="attendee?.Notes?.length > 0" [autoClose]="'outside'" matTooltip="{{'reservationNotes' | translate}}"
                            container="body" placement="auto" [ngbPopover]="reservationNotesPopOver" triggers="manual" #reservationNotePopOver="ngbPopover"
                            (click)="partyService.isChatPopoverOpened ? return : reservationNotePopOver.open();reservationNotes(attendee)"></span>
                            <ng-container *ngIf="(attendee.Contact | partyGuestFieldMapping:  mappedGuestFields) as partyGuestFields">
                              <div class="user-card__data-align-content" *ngIf="partyGuestFields?.length" matTooltip="{{'CustomGuestFields'| translate}}">
                                <span class="icon icon-Group-197 mr-0" [autoClose]="'outside'" container="body"
                                  placement="auto" container="body" [ngbPopover]="customGuestFieldsContainer" triggers="manual" #customGuestFieldsPopover="ngbPopover"
                                  (click)="partyService.isChatPopoverOpened ? return : customGuestFieldsPopover.open();setCustomGuestField(attendee.Contact)">
                                  <!-- <span class="class-detail__profile-icon icon-profile1"></span> -->
                                </span>
                              </div>
                            </ng-container>
                              
                          <div class="user-card__data-align-content" *ngIf="attendee?.BookingContactAddonItems?.length > 0">
                            <span class="icon icon-addons mr-0" [autoClose]="'outside'" container="body" triggers="manual" matTooltip="{{'addons'| translate}}"
                              placement="auto" container="body" [ngbPopover]="addOnForBooking" #addOnForBookingPopover="ngbPopover"
                              (click)="partyService.isChatPopoverOpened ? return : addOnForBookingPopover.open();updateAddOnDetails(attendee)">
                              <!-- <span class="class-detail__profile-icon icon-profile1"></span> -->
                            </span>
                          </div>
                          <ng-container *ngIf="!noActionRequired">
                          <chat-info-container class="user-card__data-align-content" [party]="attendee"></chat-info-container>
                        </ng-container>
                          <span class="icon-more user-card__data-align-content" mat-button [matMenuTriggerFor]="moreOptionsReservationTab"
                            [matMenuTriggerData]="{attendee:attendee}"
                            [ngClass]="{'noPointerEvents': partyService.isChatPopoverOpened}"></span>
                        </div>
                      </td>
                    </tr>
                    <tr *ngIf="result.length < 1" class="mt-4">
                      <td colspan="7">
                        <div class="no-attendees">
                          {{'NoAttendees' | translate}}
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </ng-container>
          </mat-tab>
          <mat-tab [label]="'StandByList' | translate" *ngIf="classDetail.IsForStandbyReservations">
            <ng-container
              *ngIf="(standbyAttendees | attendeesFilter : searchAttendeesText | filterByState : [PartyState.Pending] ) as result">

              <div class="attendees">
                <table class="table">
                  <thead class="user-card user-card__data-header">
                    <tr>
                      <th>
                        <div class="user-sno">
                          <!-- <mat-checkbox></mat-checkbox> -->
                        </div>
                      </th>
                      <th>
                        <div class="py-2 user-card__content-view pointer noSelect"
                          (click)="sortClick(sortOptions.guestName)">
                          {{'GuestDetailText' | translate}}
                          <i class="sortIcon show"
                            [ngClass]="{'icon-Sort-Up': sortData.sortBy===sortOptions.guestName && sortData.sortOrder==='asc', 'icon-Sort-Down noSort': sortData.sortBy===sortOptions.guestName && sortData.sortOrder==='desc', 'icon-sort-arrow1 noSort': sortData.sortBy!==sortOptions.guestName}"></i>
                        </div>
                      </th>
                      <th>
                        <div class="pointer noSelect">
                          {{'bookingAndConfirmation' | translate}}
                        </div>
                      </th>
                      <th>
                        <div class="user-card__covers pointer noSelect" (click)="sortClick(sortOptions.size)">
                          {{'sizeText' | translate}}
                          <i class="sortIcon show"
                            [ngClass]="{'icon-Sort-Up': sortData.sortBy===sortOptions.size && sortData.sortOrder==='asc', 'icon-Sort-Down noSort': sortData.sortBy===sortOptions.size && sortData.sortOrder==='desc', 'icon-sort-arrow1 noSort': sortData.sortBy!==sortOptions.size}"></i>
                        </div>
                      </th>
                      <th>
                        <div class="user-card__payment">
                          {{'paymentText' | translate}}
                        </div>
                      </th>
                      <th>
                        <div class="user-card__status-col pointer noSelect" (click)="sortClick(sortOptions.status)">
                          {{'Status' | translate}}
                          <i class="sortIcon show"
                            [ngClass]="{'icon-Sort-Up': sortData.sortBy===sortOptions.status && sortData.sortOrder==='asc', 'icon-Sort-Down noSort': sortData.sortBy===sortOptions.status && sortData.sortOrder==='desc', 'icon-sort-arrow1 noSort': sortData.sortBy!==sortOptions.status}"></i>
                        </div>
                      </th>
                      <th class="standby-table-action">
                        <div class="user-card__standby-actions">
                          {{'actionText' | translate}}
                        </div>
                      </th>
                      <th>
                        <div class="user-card__delete">

                        </div>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr class="user-card"
                      *ngFor="let attendee of result | sortData: sortData.sortBy: sortData.sortOrder">
                      <td>
                        <div class="user-sno">
                          <!-- <mat-checkbox [checked]="attendee.selected == true"></mat-checkbox> -->
                          <i class="icon-VIP" [ngClass]="{'VIP-selected':attendee?.Contact.IsVip}"></i>
                        </div>
                      </td>
                      <td>
                        <div class="user-card__content-view" 
                          (click)="attendee.State != PartyState.Cancelled && attendee.State != PartyState.Left && !noActionRequired? editParty(attendee) : false">
                          <h3 class="user-card__name text-ellipsis" *ngIf="attendee.Contact">
                            {{attendee?.Contact?.FirstName}} {{attendee?.Contact?.LastName || ''}}
                          </h3>
                          <p class="user-card__mobile text-ellipsis">
                            {{attendee?.Contact?.PhoneNumber | phoneNumberFormatter: attendee?.Contact?.CountryId}}</p>
                        </div>
                      </td>
                      <td>
                        <div class="reference-number">
                          <div class="conirmation">
                            {{attendee?.ConfirmationCode}}
                          </div>
                          <div class="reference">
                            {{attendee?.BookingReferenceId}}
                          </div>
                        </div>
                      </td>
                      <td>
                        <div class="user-card__covers">
                          <span *ngIf="attendee.Size"><span class="icon-add-contact"></span>
                            {{attendee.Size}}</span>
                        </div>
                      </td>
                      <td>
                        <div class="user-card__payment">
                          <ng-container *ngIf="(attendee | partyPaymentState) as paymentState">
                            <span *ngIf="paymentState!=='NA'"
                              class="user-card__payment--label payment-{{paymentState}}"
                              container="body" placement="auto" [autoClose]="'outside'"
                              [ngbPopover]="financialStatusPopover" (click)="financialDetails(attendee)">
                              {{paymentState | translate}}
                            </span>
                            <span *ngIf="paymentState=='NA'">
                              {{'NA' | translate}}
                            </span>
                          </ng-container>
                        </div>
                      </td>
                      <td>
                        <div class="user-card__status-col">
                          <span class="user-card__status user-card__status--7">{{'Standby'
                            | translate}}</span>
                        </div>
                      </td>
                      <td class="standby-table-action">
                        <div class="user-card__standby-actions">
                          <button mat-button class="btn btn--checkin" (click)="rejectClick(attendee)" [disabled]="noActionRequired">
                            {{ 'RejectText' | translate}}
                          </button>
                          <button *ngIf="!attendee?.isPast" [disabled]="attendee?.isPast || noActionRequired" mat-button
                            class="btn btn--accept" (click)="confirmClick(attendee)">
                            {{'AcceptText' | translate}}
                          </button>
                        </div>
                      </td>
                      <td>
                        <div class="user-card__cancel">
                          <div class="package-booking user-card__data-align-content"
                            *ngIf="attendee.PackageName !== null && attendee.PackageName !== undefined" [matTooltipDisabled]="(attendee.PackageName !== null && attendee.PackageName !== undefined)" matTooltip="{{'packages' | translate}}">
                            <span class="icon-offer-redemption" [matTooltipDisabled]="!(attendee.PackageName !== null && attendee.PackageName !== undefined)" matTooltip="{{attendee.PackageName}}"></span>
                          </div>
                          <span class="icon icon-note mr-0 user-card__data-align-content" *ngIf="attendee?.Notes?.length > 0" [autoClose]="'outside'" matTooltip="{{'reservationNotes' | translate}}"
                            container="body" placement="auto" [ngbPopover]="reservationNotesPopOver" triggers="manual" #reservationNotePopOver="ngbPopover"
                            (click)="partyService.isChatPopoverOpened ? return : reservationNotePopOver.open();reservationNotes(attendee)"></span>
                          <div class=""
                            *ngIf="mappedGuestFields && mappedGuestFields.length && attendee?.Contact?.ContactCustomFields?.length" matTooltip="{{'CustomGuestFields'| translate}}">
                            <span class="icon icon-Group-197 mr-0 user-card__data-align-content" [autoClose]="'outside'" container="body"
                              placement="auto" container="body" [ngbPopover]="customGuestFieldsContainer" triggers="manual" #customGuestFieldsPopover="ngbPopover"
                              (click)="partyService.isChatPopoverOpened ? return : customGuestFieldsPopover.open();setCustomGuestField(attendee.Contact)">
                              <!-- <span class="class-detail__profile-icon icon-profile1"></span> -->
                            </span>
                          </div>
                          <div class="user-card__data-align-content" *ngIf="attendee?.BookingContactAddonItems?.length > 0">
                            <span class="icon icon-addons mr-0" [autoClose]="'outside'" container="body" matTooltip="{{'addons'| translate}}"
                              placement="auto" container="body" [ngbPopover]="addOnForBooking" triggers="manual" #addOnForBookingPopover="ngbPopover"
                              (click)="partyService.isChatPopoverOpened ? return : addOnForBookingPopover.open();updateAddOnDetails(attendee)">
                              <!-- <span class="class-detail__profile-icon icon-profile1"></span> -->
                            </span>
                          </div>
                          <ng-container *ngIf="!noActionRequired">
                          <chat-info-container class="user-card__data-align-content" [party]="attendee"></chat-info-container>
                        </ng-container>
                          <span class="icon-more user-card__data-align-content" mat-button [matMenuTriggerData]="{attendee:attendee}"
                            [matMenuTriggerFor]="moreOptionsStandByTab"
                            [ngClass]="{'noPointerEvents': partyService.isChatPopoverOpened}"></span>
                        </div>
                      </td>
                    </tr>
                    <tr *ngIf="result.length < 1" class="mt-4">
                      <td colspan="7">
                        <div class="no-attendees">
                          {{'NoAttendees' | translate}}
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>

            </ng-container>
          </mat-tab>
        </mat-tab-group>
      </div>
    </div>

</div>


<!-- <div class="privatelesson-booking-detail__actions">
  <activities-app-button *ngFor="let control of buttonControls" [buttontype]="control" (valueChange)="changeEvent($event)">
  </activities-app-button>
</div> -->

<div>
  <ng-template #financialStatusPopover>
    <app-financial-status-popup [partyData]="financialPartyData"></app-financial-status-popup>
  </ng-template>
</div>

<!-- Reservation Notes popover -->
<div>
  <ng-template #reservationNotesPopOver>
    <div>
      <div class="parties-grid__scrollSize">
        <div class="reservation-notes">
          <div class="reservation-notes__header seat-popover-header">
            {{'reservationNotes' | translate}}
          </div>
          <div class="reservation-notes__content seat-popover-content">
            <div class="reservation-notes__notes-details seat-border-color"
              style="justify-content: space-between;gap:20px" *ngFor="let notes of notesReservation">
              <div>{{notes.Text}}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ng-template>
</div>

<!-- Custom Guest Fileds for booking -->

<ng-template #customGuestFieldsContainer>
  <div>
    <div class="parties-grid__scrollSize">
      <div class="reservation-notes">
        <div class="reservation-notes__header seat-popover-header">
          {{'CustomGuestFields' | translate}}
        </div>
        <div class="reservation-notes__content seat-popover-content">
          <div class="reservation-notes__notes-details seat-border-color list-item"
            *ngFor="let guestField of customGuestFieldsForBooking">
            <label class="guest-field-name">
              {{guestField.CustomFieldName}}
            </label>
            <span>: {{guestField.CustomFieldValue}}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #addOnForBooking>
  <app-add-on-popover [party]="partyWithAddon"></app-add-on-popover>
</ng-template>



<mat-menu #moreOptionsReservationTab="matMenu">
  <ng-template matMenuContent let-attendee="attendee">
    <button mat-menu-item *ngIf="attendee?.WaiverFormURL != null" (click)="showPartyWaiverForm(attendee)">
      {{'waiverForm' | translate}}</button>
    <button mat-menu-item (click)="showPartyAuditLog(attendee)"> {{'auditlog' |
      translate}}</button>
    <button mat-menu-item
      *ngIf="isRetailEnabled && attendee.State != PartyState.Cancelled && attendee.State != PartyState.Left"
      (click)="moveToShopScreen(attendee)">{{'shop' |
      translate}}</button>
    <button mat-menu-item *ngIf="isRetailEnabled && attendee.State != PartyState.Cancelled && attendee.State != PartyState.Left && !noActionRequired" (click)="editParty(attendee)">{{'edit' | translate}}</button>
    <button mat-menu-item *ngIf="attendee.State == PartyState.Pending"
      (click)="cancelPartyForSessionOrClass(attendee)">{{'Cancel' | translate}}</button>
      <button [hidden]="true" mat-menu-item (click)="dashboardFunctions.PrintWristBand(attendee)"> {{'PrintWristBand' |
        translate}}</button>
  </ng-template>
</mat-menu>
<mat-menu #moreOptionsStandByTab="matMenu">
  <ng-template matMenuContent let-attendee="attendee">
    <button mat-menu-item (click)="showPartyAuditLog(attendee)"> {{'auditlog' |
      translate}}</button>
    <button mat-menu-item *ngIf="attendee.State != PartyState.Cancelled && attendee.State != PartyState.Left && !noActionRequired"
      (click)="editParty(attendee)">{{'edit' | translate}}</button>
  </ng-template>
</mat-menu>

<ng-template #staffPopover>
  <div class="staff-list" *ngFor="let staff of staffs">
    <div class="staff-name"> {{staff.fullName}}</div>
  </div>
</ng-template>


<ng-template #locationPopover>
  <div class="staff-list" *ngFor="let location of locations">
    <div class="staff-name"> {{location?.value}}</div>
  </div>
</ng-template>