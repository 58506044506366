import { DatePipe } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Injectable, OnDestroy } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ApiService } from '@app/activities-timeline/services/api.service';
import { AppService } from '@app/app.service';
import { ConfirmationPopupComponent } from '@components/confirmation-popup/confirmation-popup.component';
import { AutomaticRefundState, BlockActivityType, BookingBehavior, CancelActivityType, ClassType, ComponentTypes, ConciergeTrackingType, FinancialEffectType, GetPartiesFilter, GetPartyOptions, GetReservationsOperationOptions, GetSlotsOperationOptions, Labels, OperationResultState, Operations, PartyState, PartyType, PaymentGateways, PropertyType, ReservationEmailNotificationType, ReservationSourceType, ReservationType, ResponseType, SlottingMode, SlottingType, SlotType, TableLayoutConfig, ViewBy, PaymentMethod, FinancialEffectAction, TimeLineAvailablityShowTypes, ValidationMessageType, SingleDayActivityType} from '@constants/commonenums';
import * as globals from '@constants/globalConstants';
import { popupDialogDimension } from '@constants/globalConstants';
import { urlConfig } from '@constants/url-config';
import { CacheService } from '@core/services/cache.service';
import { ConciergePhoneNumberDTO } from '@models/ConciergeTrackingDTO';
import { LayoutConfig } from '@models/global.interface';
import { ContactDTO, CustomGuestFieldsMappingDTO, FullContactDTO, GetContactOptions, PartyPaymentResult, PartyPrepaymentState, ReservationDTO, PartyChargeState } from '@models/InputContact';
import { CheckItems, AddOnAvailability, DateShiftDTO, MoveOperationDetailsDTO, PartyIDs, SeatingEstimatePartyDTO, SeatingEstimateResultDTO, SeatOperationDetailsDTO, SlotDTO, UpdatedPartySlotDTO, UpdatedWalkInDTO } from '@models/InputReservation';
import { UpdatedManualSlotDTO } from '@models/ManualSlotDTO';
import { BookingType, CoverType, OpenBookingDTO, PrivateLessonBookingDTO } from '@models/OpenBookingDTO';
import { ActivityCustomizationAppliesTo, ActivityCustomizationType, AddonDTO, BookingChargeType, BookingInfo, ContactHistoryItemDTO, IGResponseDTO, ItemType, LayoutDTO, ManualSlotIdDTO, PageMethod, PartyNoteDTO, PrePaymentMode, PropertyInfo, RetailOpenItemDTO, SelectionType, SettingsDTO, StatusDTO, SupportedReservationEmailConfirmationTypes, TicketModel, IGCalculationType, LockMode, MemberRound, PartyEmailSendBehavior, SeatingInfoDTO, BookingContactAddonItemDTO, SettingType } from '@models/RestaurantDTO';
import { SessionBookingDTO } from '@models/SessionBookingDTO';
import { AuditLogOperationOptions } from '@models/SimpleAuditLogItemDTO';
import { newTimeRangeDTO, TimeRangeDTO } from '@models/TimeRangeDTO';
import { WaitTimeDTO } from '@models/WaitTimesDTO';
import { TranslateService } from '@ngx-translate/core';
import { CustomPopupComponent } from '@popup-module/components/custom-popup/custom-popup.component';
import { ComponentDetails, TabsModel } from '@popup-module/models/popup.interface';
import { PopupService } from '@popup-module/popup.service';
import { HttpService } from '@services/http.service';
import { Utilities } from '@utilities/utilities';
import { endOfDay, format, startOfDay } from 'date-fns';
import _ from 'lodash';
import moment from 'moment';
import { BehaviorSubject, Observable, Subject, Subscription } from 'rxjs';
import { delay, map } from 'rxjs/operators';
import { ISubscription } from 'rxjs/Subscription';
import { ActivityPaymentSummaryComponent } from '../components/activity-payment-summary/activity-payment-summary.component';
import { AdditionalRatePaymentIframeComponent } from '../components/additional-rate-payment-iframe/additional-rate-payment-iframe.component';
import { OpenPaymentSummaryComponent } from '../components/open-payment-summary/open-payment-summary.component';
import { RefundPopupComponent } from '../components/refund-popup/refund-popup.component';
import { TicketComponent } from '../components/ticket/ticket.component';
import { LockSessionRequestDTO, MultiplePropertyUnlockRequestDTO } from '../models/ActivityLockSessionDTO';
import { PrivatelessonPaymentSummaryComponent } from '../components/privatelesson-payment-summary/privatelesson-payment-summary.component';
import { SetRetailItems } from './retail/activities-retail-item-builder';
import { PackageDTO, PackageShortDTO } from '../models/PackageDTO';
import { AddOnForBookingComponent } from '../components/add-on-for-booking/add-on-for-booking.component';
import { BlockSessionComponent } from '../components/block-session/block-session/block-session.component';
import { LoginResultDTO } from '../models/LoginResultDTO';
import { ActivityRatePlanRequest } from '../models/ActivityRatePlanRequestDTO';
import { seatRetailService } from './seatretail.service';
import { PricingStrategy, RatePlanCalculator, RatePlanDTO } from '../models/RatePlanDTO';
import { RatePlanSummaryComponent } from '../components/rate-plan-summary/rate-plan-summary.component';
import { Transaction } from '@app/retail/retail-reports/business/retail-reports/transaction.report';
import { CommonUtilities } from '@app/common/shared/shared/utilities/common-utilities';
import { ButtonType } from '@app/common/shared/shared/globalsContant';
import { AlertType } from '@app/common/shared/shared/enums/enums';
import { TimelineFilterState } from '../models/TimelineFilters.model';
import { AppPopupComponent } from '@app/popup-module/components/app-popup/app-popup.component';
import { MatSnackBar,MatSnackBarHorizontalPosition,MatSnackBarVerticalPosition } from '@angular/material/snack-bar';
import { BlockActvityDataDTO } from '@app/popup-module/components/services/advance-block.data';
import { ActivityBlockingRuleLookUp } from '../models/TableBlockingDTO';
import { EngageMemberDetailDTO, MemberRatePlaneDTO } from '../models/EngageMemberDetailDTO';
import { EngageMemberByCardIdRequestDTO } from '../models/EngageMemberByCardIdRequestDTO';
import { VCartCommonService } from './vcart-common.services';
import { RatePlanComponent } from '../components/rate-plan/rate-plan.component';
import { FinancialEffectResponse } from '../models/FinancialEffect.model';
import { PaymentConfirmationPopupComponent } from '../components/payment-confirmation-popup/payment-confirmation-popup.component';
import * as GlobalConst from '@app/shared/constants/globalConstants';
import * as Util from '@app/shared/utilities/util-functions';
import { ReservationSource } from '@app/pos/const/global.const';
@Injectable({
  providedIn: 'root'
})
export class PartyService implements OnDestroy {
  reservationType: ReservationType;
  tabsModal: TabsModel;
  /* Party Creation selections */
  selectedSize: any;
  selectedGuest: any;
  selectedDate: any;
  selectedTime: any;
  selectedTable: any;
  selectedArea: any;
  selectedSpecialMeal: any;
  selectedCoverTypes: any;
  selectedBookingTypes: any;
  autoWaitTime: SeatingEstimateResultDTO;
  timetabTitle: any;
  slots_holder: any[];
  allAvailableSlots: any[];
  reservedSlots: any[];
  tableSelected: Subject<any> = new Subject<any>();
  selectedServers: Subject<any> = new Subject<any>();
  tableSelectedNames: Subject<any> = new Subject<any>();
  validateGuestForm: Subject<any> = new Subject<any>();
  ratePlan$: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  promoCodeApplied$: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  allShifts: any[] = [];
  slotsinTimeRange: any;
  selectedParty$ = new BehaviorSubject<any>(null);
  setPopUpPosition$ = new BehaviorSubject<any>(null);
  CommunalselectedParty$ = new BehaviorSubject<any>(null);
  selectedServer$ = new BehaviorSubject<any>(null);
  selectedMembershipDetail$ = new BehaviorSubject<any>(null);
  dashboardReservationCounts: any;
  dashboardStatus: any;
  dashboardMessages: any;
  reservationData: any;
  timerange: TimeRangeDTO = {} as TimeRangeDTO;
  tabChange$: Subject<any> = new Subject<any>();
  tabChangeEvent$: Subject<any> = new Subject<any>();
  currentTabActions: any[] = [];
  statusChange$: Subject<any> = new Subject<any>();
  openNextPopUp$: Subject<any> = new Subject<any>();
  contactData$: Subject<any> = new Subject<any>(); //ContactDTO[] = [];
  public predefinedNotesId: any;
  public predefinedNotesIcon: any;
  public predefinedfNotes: any;
  ratePlanObject: any;
  resultupdate$: Subject<any> = new Subject<any>();
  allBookedActivities = {
    ByClass: [],
    ByLocation: [],
    ByStaff: []
  }
  slotAvailabilitySubject$: Subject<any> = new Subject<any>();
  partySlots$: Subject<any> = new Subject<any>();
  reservationFormGroup: UntypedFormGroup;
  headlinesForm: UntypedFormGroup;
  selectedOpenBookingSlots: any = {}
  selectedPrivateLessonBookingSlots: any = {}
  messageSent$: Subject<any> = new Subject<any>();
  slotLockId: number;
  MultiSlotLockIds: any;
  isEditData = false;
  isEditCart = false;
  editCartId: number;
  //listners = new Subject<any>();
  consentPolicyId: any;
  isPastReservation = false;
  isAssignTables = false;
  Parties$ = new BehaviorSubject<any[]>([]);
  SelectedContactParties$ = new BehaviorSubject<any[]>([]);
  CancelledReservations$ = new BehaviorSubject<any[]>([]);
  contacts$ = new BehaviorSubject<any>([]);
  StandbyParties$ = new BehaviorSubject<any[]>([]);
  shifts$ = new BehaviorSubject<DateShiftDTO[]>(null);
  RemovedParty$ = new BehaviorSubject<any[]>([]);
  reloadDashboardStatusAndMessages$ = new BehaviorSubject<boolean>(true);
  showNoTableSelectedAlert = false;
  IsUpdateReservation = false;
  tryUndoNoShowReservation = false;
  filteredReservations: ReservationDTO[];
  currentSeatingAreaId: number;
  isOverBookSlotSelected: boolean = false;
  isNewGuestFromReservation: boolean = false;
  LastPartyRequest;
  LastPartyServerIds;
  SelectedTimelineView = this.getDefaultTimelineView(globals.activitiesTimelineView, ViewBy.location);
  allLessonBookings: any;
  isCartUpdated: any = 0;
  layoutCofiguartion: LayoutConfig = {
    from: TableLayoutConfig.preferredTables
  };
  currentRatePlanRequest: any;
  SelectedTableIds: number[] = [];
  highlightSelectedTables: number[] = [];
  dataRetentionPolicyChecked = false;
  partyOverrideRemainingTimeInMins: any;
  partyCreationSubscription: ISubscription;
  partyPagerNumber: string = "";
  conciergeTrackingDialogRef: MatDialogRef<any>;
  standbyConversion: boolean = false;
  StandbySlots: any[] = [];
  isStandbySlotSelected: boolean = false;
  isGuestComponentLoaded: boolean = false;
  slotLockIds: number[] = [];
  currentPartyId: number = 0;
  public reservationDialogRef: MatDialogRef<CustomPopupComponent>;
  public openBookingDialogRef: MatDialogRef<CustomPopupComponent>;
  public privateLessonBookingDialogRef: MatDialogRef<CustomPopupComponent>;
  public activityBookingDialogRef: MatDialogRef<CustomPopupComponent>;
  public additionalChargePaymentIframeDialogRef: MatDialogRef<CustomPopupComponent>;
  warningInfoDialogRef: MatDialogRef<any>;
  quickSeatpartyselectedSize: number = 0;
  quickSeatCoverTypeQuantity:any[]=[];
  RecentlyLeftPartiesList = [];
  standbyPartiesList = [];
  fromGuestCharges: boolean = false;
  previousSelectedSlotId: number;
  orderDetails: ContactHistoryItemDTO[] = [];
  feedbackDetails: any;
  pastReservationsData: any;
  cancelType: CancelActivityType;
  refundData;
  selectedMemberGuestPreferences: any;
  customFieldValidation: any = [];
  guestFieldValidation: boolean = true;
  ReasonForReject: any = '';
  updateContactOnly = false;
  negotiationComment = '';
  negotiableRatesChanged = false;
  isNegotiationEnabled = false;;
  operationType = true;
  editRates = false;
  negotiateRates = false;
  rateNegotiationObject: any;
  isPaymentavailable = false;
  ReasonFromPopUp: any = '';
  paymentInfo: any;
  Operations: number;
  BookingBehaviour: any;
  contactList$ = new BehaviorSubject<ContactDTO[]>([]);
  bookingCancellationPayload = null;
  bookingsCount$ = new BehaviorSubject<any>(null);
  selectGuestSearch$ = new BehaviorSubject<any>(null);
  activityChanges$ = new BehaviorSubject<any>(null);
  packageMatchValidation$ = new BehaviorSubject<any>(null);
  bookingSize: number;
  bookingConfirmationData: { Token: string, UniqueId: string}[] | any = [];
  reservationConfirmationData: any;
  SessionSlotsAvailableDates: any;
  packages: any[] = [];
  ratePlanForBooking: any;
  FinancialEffectId: number;
  ActualDepartureTime: any = null;
  showretailPayment: boolean;
  selectedBooking: any;
  RetailCardTokenId: number;
  IsSkipPayment: boolean = false;
  negotiateServiceCharge: boolean = false;
  public iconArray: any = [
    { name: 'Glutens Allergy', icon_name: 'icon-Gluten-Free' },
    { name: 'Birthday', icon_name: 'icon-Birthday' },
    { name: 'Egg Allergy', icon_name: 'icon-Eggs' },
    { name: 'Peanuts Allergy', icon_name: 'icon-Peanuts' },
    { name: 'Vegetarian', icon_name: 'icon-Vegetarian' },
    { name: 'Anniversary', icon_name: 'icon-Anniversary' },
    { name: 'High Chair Required', icon_name: 'icon-High-Chair' },
    { name: 'Baby Carrier Required', icon_name: 'icon-Baby-Carrier' },
    { name: 'Need High Chair', icon_name: 'icon-High-Chair' },
    { name: 'edit', icon_name: 'icon-edit' },
    { name: 'VIP', icon_name: 'icon-Path-495' },
    { name: 'Allergy', icon_name: 'icon-Gluten-Free' }

  ];
  // Please update language.json for corresponding updates in the below array
  public DayArray: Array<any> = [
    { id: 0, value: 'todayText' },
    { id: 1, value: 'week' }
  ];
  public TimeArray: Array<any> = [
    { id: 1, value: 'shiftText' },
    { id: 2, value: 'partySize' },

  ];
  public WaitListSortByOptions: Array<any> = [
    { id: 1, value: 'timeText' },
    { id: 2, value: 'partySize' },

  ];
  public ShiftSelectionByOptions: Array<any> = [
    { id: 0, value: 'By Shift' },
    { id: 1, value: 'By Time Range' },
    { id: 2, value: 'By Day' },
  ];
  public AllocationTypeOptions: Array<any> = [
    { id: 2, value: 'Maintenance' },
    { id: 3, value: 'Titanium Member' },
    { id: 4, value: 'Gold Member' },
    { id: 5, value: 'Silver Member' },
    { id: 6, value: 'Platinum Member' },
    { id: 7, value: 'Any Member' }

  ];
  isOverrideAvailable = false;
  noReservationImgUrl: string = urlConfig.noReservationsUrl;
  slotMode: any;
  confirmSubscription: ISubscription;
  cancelSubscription: ISubscription;
  public _settings: SettingsDTO = {} as SettingsDTO;
  //public _layout: LayoutDTO = {} as LayoutDTO;
  waitlists: any;
  partiesList = [];
  partyNotes: PartyNoteDTO[] = [];
  partyPageMethod: PageMethod;
  reservationHost: any;
  closeParent$: Subject<boolean> = new Subject<boolean>();
  isRetailOpenItemUpdated$: Subject<any> = new Subject<any>();
  popupSubscription: any;
  confSubscription: any;
  confirmationSubscription: ISubscription;
  totalWaitimeMinutes: number = 0;
  confirmationMessages: any;
  SlotIds = 0;
  isExistingContactQS: Boolean = false;
  manualSlotsGroup: any[] = [];
  subscriptions: Subscription = new Subscription();
  selectedSpecialMealName = new BehaviorSubject<string>(null);
  selectedSpecialMealPrice = new BehaviorSubject<number>(0);
  navigationShiftIdFromReservation: number = 0;
  openHours: any[];
  openHoursRange: any[];
  isActionCompleted$: Subject<boolean> = new Subject<boolean>();
  proceedToRetailPayment: boolean = false;
  openBookingData: OpenBookingDTO;
  privateLessonBookingData: PrivateLessonBookingDTO;
  addToCartRequestObj: any;
  sessionBookingData: SessionBookingDTO;
  openBookingDataSaveObj;
  privateLessonBookingDataSaveObj;
  sessionBookingDataSaveObj;
  TotalCartBookingObj: any = [];
  ReservationAttemptData: any;
  MultipleReservationAttemptData: any;
  cartCount = 0;
  savesessions: Boolean;
  isStandBy = false;
  editIframeUrl: any;
  updateTableAvailablity$ = new BehaviorSubject<boolean>(true);
  cachedMoveTables = [];
  slotUnlockTimer: any;
  reservationDateForBlockingRule = null;
  changedServerIds: number[];
  isMoveTableAPITriggered = false;
  cancelBookingDataObj;
  cancelBookingContactObj;
  guestChargeCancelledPartyDetails = null;
  lockData: SlotDTO;
  ratePlan: any;
  ticketSubscription: Subscription;
  cancelTicketSubscription: Subscription;
  isfrombuynow = false;
  isfromBuyNowCartId: number = 0;
  isfromBuyNowAmountNegotiated = false;
  isSlotLocked: boolean = false;
  overrideTimeAPISuccessEvent$ = new Subject<any>();
  triggerEmail: boolean = false;
  selectedSpecialMealId: any = -1;
  customGuestFieldsMappingValidations: CustomGuestFieldsMappingDTO[] = [];
  printresult: any;
  selecetedPrinter: any;
  selectedTableObjectForDrag = null;
  weekStartDate = null;
  weekEndDate = null;
  isTimelineInWeekView = false;
  noOfDaysLoaded: number = 0;
  allWeekreservations = [];
  allWeekActivitySessions = {} as any;
  addOnList: any = [];
  selectedSlot$ = new BehaviorSubject<any>(null);
  selectedPackage: any;
  selectedPackageId: number;
  packageListPopup: any;
  standbyDialog: any;
  allWeekPrivateStandByBookings = {} as any;
  reservationFromGuestBook: boolean = false;
  blockSessionForm: UntypedFormGroup;
  bookingPaymentType: number = PaymentMethod.Prepayment;
  checkInAndCheckOutRatePlan: any;
  chargeRatePlan:any;
  allSessionsHeadLines = {} as any;
  isPaymentSuccessfull$ = new Subject<number>();
  bookingDetailViewPopupDialogRef: any;
  updateOpenBookingList$ = new BehaviorSubject<any>(null);
  selectedTableLocationId: number;
  BookingOpenedFromSidePanel: boolean = false;
  isChatPopoverOpened: boolean = false;
  modifySingleSessionMappingValue = [];
  modeifySingleSessionForm: UntypedFormGroup;
  paymentMethodChanges$: Subject<any> = new Subject<any>();
  timelineCommonFilters: TimelineFilterState = globals.TimelineDefaultFilter;
  TimeLineAvailablityShowType = TimeLineAvailablityShowTypes.AvailableCapacityByTotalCapacity;
  ratePlanSummaryState = false;
  retailTransSelectedGuestDetails = {} as {id: number, isOpenTransaction: boolean};
  retailClientSearch:any;
  redirectPath: string = '';
  horizontalPosition: MatSnackBarHorizontalPosition = 'center';
  verticalPosition: MatSnackBarVerticalPosition = 'bottom';
  activityBlockingChange$ = new BehaviorSubject<ActivityBlockingRuleLookUp>(null);
  selectedTimeSlot: any;
  lockCartType = LockMode.Manual;
  chitPrintPartyData: any[] =[];
  
  constructor(public httpService: HttpService, public popupService: PopupService, public http: HttpClient,
    private fb: UntypedFormBuilder, public cs: CacheService, private datePipe: DatePipe,  public retailservice: seatRetailService,
    private utilities: CommonUtilities, private _snackBar: MatSnackBar, private ts?: TranslateService, private dialog?: MatDialog, public api?: ApiService, private VCartService?:VCartCommonService ) {
    this.createReservationForm();
    this.SelectedTimelineView = this.getDefaultTimelineView(globals.activitiesTimelineView, ViewBy.location);
    this.subscriptions.add(cs.settings.subscribe(sett => {
      this._settings = sett;
      this.TimeLineAvailablityShowType = this._settings ?  this._settings.PropertySetting?.[0].TimeLineAvailablityShowType : TimeLineAvailablityShowTypes.AvailableCapacityByTotalCapacity;
    }));
    this.subscriptions.add(cs.igIntegrateDataRefresh$.subscribe(errorCode => {
      if (errorCode) {
        this.HandleResponse(errorCode);
      }
    }))
  }

  getDateFormats() {
    return {
      display: {
        dateInput: this.cs.settings.value.General.DateFormat,
        monthYearLabel: this.cs.settings.value.General.DateFormat,
        dateA11yLabel: this.cs.settings.value.General.DateFormat,
        monthYearA11yLabel: this.cs.settings.value.General.DateFormat,
      }
    }
  }

  setReservations(futureReservations) {
    let reservations = [];
    futureReservations.forEach(reservation => {
      if (reservation.UpcomingReservations.length > 0) {
        reservation.UpcomingReservations.forEach((upcomingReservation) => {
          reservations.push({
            DepartureTime: upcomingReservation.DepartureTime,
            Id: upcomingReservation.Id,
            ReservedFor: upcomingReservation.ReservedFor,
            SeatingTime: upcomingReservation.SeatingTime,
            Size: upcomingReservation.Size,
            State: PartyState.Pending,
            TableIds: [reservation.StandaloneTableId],
            Type: PartyType.Reservation
          });
        });
      }
    });
    if (reservations.length > 0) {
      const processedId = [];
      reservations.forEach(res => {
        const isReservationProcessed = processedId.length > 0 && processedId.filter(resID => resID === res.Id).length > 0;
        const filterRes = reservations.filter(x => x.Id === res.Id && x.TableIds !== res.TableIds);
        if (!isReservationProcessed) {
          if (filterRes.length > 0) {
            filterRes.forEach(y => {
              if (res.TableIds.length > 0) {
                res.TableIds.push(...y.TableIds);
              }
            });
          }
        }
        processedId.push(res.Id);
      });
    }
    reservations = _.uniqBy(reservations, 'Id');
    //this.subscriptions.add(this.cs.state.subscribe(val => {
    //if (val) {
    reservations.push(...this.cs.state.value?.SeatingParties);
    //}
    // }));
    return reservations;
  }

  /**
         * Return partyAmount, negotitation, bookingAmount, taxOnServiceCharge, minRateAmountWithTax.
         *
         * @param party booking details.
         */
  getBookingAmount(party){

    let activeBookingIds = party?.BookedSessions?.filter(f=>f.SessionState != PartyState.Cancelled)?.map(m=>m.Id);
    let activeBookingAmount =  activeBookingIds?.length  ?  party.BookingAmounts?.filter(f=> activeBookingIds.includes(f.BookedSessionId)) :  party.BookingAmounts;
    let bookingServiceChargeAmount = _.sumBy(_.filter(activeBookingAmount, function(booking) {return booking.BookingChargeType == BookingChargeType.ServiceCharge }), (o) => o["Amount"])
    let partyAmount = (party.SpecialMealAmount || 0) + (party.RatePlanAmount || 0) + (party.TaxAmount || 0) + (party.AddonItemAmount || 0) + (party.AddonItemTaxAmount || 0) + (party.PackageDiscountAmount || 0) + (party.TotalServiceChargeAmount || bookingServiceChargeAmount || 0)
    let negotitation = _.sumBy(_.filter(activeBookingAmount, ['BookingChargeType', BookingChargeType.Negotiation]), (o) => o["NegotiatedAmount"]); //support old booking
     negotitation = (negotitation ||0) + (_.sumBy(activeBookingAmount, (o) => o["NegotiatedAmount"]))
    let bookingAmount = _.sumBy(_.filter(activeBookingAmount, function(booking) {return booking.BookingChargeType == BookingChargeType.ServiceCharge || booking.BookingChargeType == BookingChargeType.CoverType || booking.BookingChargeType == BookingChargeType.Location}), (o) => o["Amount"]); // minrate service charge also included
    let taxOnServiceCharge = _.sumBy(_.filter(activeBookingAmount, function(booking) {return booking.BookingChargeType == BookingChargeType.ServiceCharge || booking.BookingChargeType == BookingChargeType.CoverType || booking.BookingChargeType == BookingChargeType.Location}), (o) => o["BookingChargeTaxAmount"]);
    let minRateAmountWithTax = _.sumBy(_.filter(activeBookingAmount, ['BookingChargeType', BookingChargeType.MinRateCoverTypeAdjustment]), (o) => ((o["Amount"] || 0) + (o["NegotiatedAmount"] || 0)));
    partyAmount += (negotitation || 0) + (minRateAmountWithTax || 0) + (taxOnServiceCharge || 0); // total sum of the party amount // addonNegotitation - includes both addon and session negotiation
    return {partyAmount,negotitation,taxOnServiceCharge,minRateAmountWithTax,bookingAmount};
  }

  getParties(startDate, endDate, option: GetReservationsOperationOptions = GetReservationsOperationOptions.Both, PropertyId = Utilities.RestaurantId()) {
    this.timerange.Start = startOfDay(startDate);
    this.timerange.End = endOfDay(endDate);
    this.timerange.Start = new Date(this.timerange.Start.getTime());
    this.timerange.End = new Date(this.timerange.End.setDate(this.timerange.End.getDate()))
    return this.httpService.post(
      `${urlConfig.loadReservationsURL}?restaurantId=${PropertyId}&options=${option}`,
      { Start: moment(this.timerange.Start).format('YYYY-MM-DD'), End: moment(this.timerange.End).format('YYYY-MM-DD') }, false, PropertyId)
      .pipe(map(data => data));
  }
  getAllBookings(startDate, endDate, partyType: PartyType = PartyType.Reservation, option: GetPartyOptions = GetPartyOptions.Full) {
    this.timerange.Start = startOfDay(startDate);
    this.timerange.End = endOfDay(endDate);
    this.timerange.Start = new Date(this.timerange.Start.getTime());
    this.timerange.End = new Date(this.timerange.End.getTime())
    return this.httpService.post(
      `${urlConfig.getAllBookings}?partytype=${partyType}&propertyid=${Utilities.RestaurantId()}&startdate=${moment(this.timerange.Start).format('YYYY-MM-DD')}
      &enddate=${moment(this.timerange.End).format('YYYY-MM-DD')}&options=${option}`).pipe(map(data => data));
  }

  getTableAvailablity(startDate, endDate) {
    this.timerange.Start = startOfDay(startDate);
    this.timerange.End = endOfDay(endDate);
    this.timerange.Start = new Date(this.timerange.Start.getTime());
    this.timerange.End = new Date(this.timerange.End.setDate(this.timerange.End.getDate() + 1))
    return this.httpService.post(
      `${urlConfig.getTableAvailablityURL}?restaurantId=${Utilities.RestaurantId()}`,
      { Start: moment(this.timerange.Start).format('YYYY-MM-DD'), End: moment(this.timerange.End).format('YYYY-MM-DD') })
      .pipe(map(data => data));
  }

  createReservationForm() {
    this.reservationFormGroup = this.fb.group({
      selectedSize: [''],
      selectedArea: [''],
      selectedType: [''],
      selectedDate: [''],
      selectedTime: [''],
      selectedTable: [''],
      selectedGuest: [''],
      selectedSpecialMeal: [''],
      selectedCoverTypes: [''],
      selectedBookingTypes: [''],
      selectedPagerNumber: [''],
      selectedHotelId: [''],
      selectedConciergeId: [''],
      selectedPartyStatus: [''],
      selectedSalesContactIds: [''],
    });
    this.lockData = null;
    this.isfrombuynow = false;
  }

  triggerReload() {
    //this.listners.next();
  }
  getPartyStatuses() {
    return this.httpService.get(urlConfig.partyStatusesGetURL + Utilities.RestaurantId()).pipe(
      map((data: any) => data.Payload as StatusDTO));
  }


  public get MakeReservationItemsVisible() {
    return this.reservationType === ReservationType.Reservation;
  }
  postSeatedPartyStatus(restaurantId: number, partyId: number, statusId: number) {

    return this.httpService.post(`${urlConfig.seatedPartyStatusURL}?restaurantId=${restaurantId}&partyId=${partyId}&statusId=${statusId}`, null);

  }
  getAutoWaitTime(partySize: number, partyDetails?: any) {
    this.selectedSize = partySize;
    this.httpService.post(`${urlConfig.seatingEstimateURL}?restaurantId=${Utilities.RestaurantId()}`,
      this.buildWaitTimeRequest(partyDetails)).subscribe(
        data => {
          if (data) {
            this.autoWaitTime = data.Payload;
            this.slotAvailabilitySubject$.next(this.autoWaitTime);
          }
        }
      );
  }

  overrideDepartureTime(partyId) {
    return this.httpService.post(`${urlConfig.overrideDepartureTimeUrl}?restaurantId=${Utilities.RestaurantId()}&partyId=${partyId}&minutesFromNow=${this.partyOverrideRemainingTimeInMins}`)
      .subscribe(data => { this.overrideTimeAPISuccessEvent$.next(true); });
  }


  buildWaitTimeRequest(partyDetails): any {
    const waitTimeRequest: SeatingEstimatePartyDTO = {} as SeatingEstimatePartyDTO;
    const { Id = null, ArrivedAt = null, SeatingAreaId = null, SeatingTypeId = null, TableIds = null, ReservedFor = null,
      CreatedAt = null , eventId = null } = partyDetails;
    waitTimeRequest.Id = Id;
    waitTimeRequest.IsReservation = ReservedFor ? true : false;
    waitTimeRequest.CreatedAt = this.reservationType === ReservationType.Reservation || this.reservationType === ReservationType.StandbyParties || this.reservationType === ReservationType.PrivateLessonBooking ? CreatedAt : null;
    waitTimeRequest.ArrivedAt = ArrivedAt;
    waitTimeRequest.ReservedFor = ReservedFor;
    waitTimeRequest.SeatingAreaId = SeatingAreaId === -1 ? null : SeatingAreaId;
    waitTimeRequest.SeatingTypeId = SeatingTypeId === -1 ? null : SeatingTypeId;
    waitTimeRequest.Size = (partyDetails && partyDetails.Size) ? partyDetails.Size : this.selectedSize;
    waitTimeRequest.TableIds = TableIds;
    waitTimeRequest.eventId = eventId;

    // const waitTimeAutoRequest: SeatingEstimateRequestDTO =  {} as SeatingEstimateRequestDTO;
    // waitTimeAutoRequest.RestaurantId = Utilities.RestaurantId();
    // waitTimeAutoRequest.SeatingEstimateDTO = waitTimeRequest;
    return waitTimeRequest;
  }

  createReservation(request, seatingTime, dialogRef?: MatDialogRef<any>) {
    request.LanguageId = Number(sessionStorage.getItem('languageId')) || globals.DEFAULT_LANGUAGE_ID;
    this.subscriptions.add(this.httpService.post(`${urlConfig.createReservationURL}?restaurantId=${Utilities.RestaurantId()}`,
      request).subscribe(
        data => {
          if (data) {
            if (this.slotLockId) {
              this.slotLockId = null;
              clearTimeout(this.slotUnlockTimer);
            }
            this.openPurchaseForm(data, seatingTime);
            this.popupSubscription = this.closeParent$.subscribe((data) => {
              if (data) {
                if (dialogRef) {
                  dialogRef.close();
                  if (this.popupSubscription) { this.popupSubscription.unsubscribe(); }
                  if (this.partyCreationSubscription) { this.partyCreationSubscription.unsubscribe(); }
                }
              }
            });
            this.partyCreationSubscription = this.popupService.confirmedAction$.subscribe(val => {
              if (val == ComponentTypes.PartyCreation) {
                if (this.closeParent$) {
                  if (this.reservationDialogRef) {
                    this.reservationDialogRef.close();
                    this.reservationDialogRef = null;
                  }
                  this.closeParent$.next(true);
                }
              }
            });
          }
        }
      ));
  }

  createOpenBooking(request, ignoreBookingValidation: boolean, seatingTime?, dialogRef?: MatDialogRef<any>) {
    this.subscriptions.add(this.httpService.post(`${urlConfig.createOpenBookingURL}?restaurantId=${Utilities.RestaurantId()}&ignoreBookingValidation=${ignoreBookingValidation}`,
      request).subscribe(
        data => {

          if (data.State == OperationResultState.ConsentMessages && data.ValidationMessages?.length) {
            this.showExistingReservationPopup(ComponentTypes.AddOpenBooking, request, data.ValidationMessages, dialogRef, null)
            return;
          }

          this.popupService.closeDialog$.next();
          // this.openPurchaseForm(data, null);

          this.showAppPopup(this.ts.instant('ReservationSuccessMessage'));
          if (data.Payload && data.Payload && data.Payload.EmailAddress && this.cs.settings.value.General.HostConfirmationEmailSendBehavior == PartyEmailSendBehavior.Prompt) {
            // this.openConfirmationDialog(data, null, null, ReservationEmailNotificationType.Created);
            this.showEmailNotification(data, ReservationEmailNotificationType.Created);
          }
          /*   if (data.Payload && this._settings.General.RetailIntegrationDTO.IsEnabled && this._settings.RetailItems && this._settings.RetailItems.length > 0) {

              this.SetRetailItem(data.Payload.PartyId);
            } else  */
          {
            if (data.Payload && data.Payload.PurchaseForm) {
              Utilities.openPurchaseForm(data.Payload.PurchaseForm.Url);
            } else if (data.Payload && data.Payload.NewParties && Object.keys(data.Payload.NewParties).length) {
              Utilities.openPurchaseForm(data.Payload.NewParties[Object.keys(data.Payload.NewParties)[0]]
              .PurchaseForm.Url);
            }
          }
        }
      ));
  }


  saveOrderDetails(OrderDetails) {
    return this.httpService.post(`${urlConfig.saveRetailOrderDetails}?restaurantId=${Utilities.RestaurantId()}`, OrderDetails);
  }


  RetailCreateOperation(cartIds) {
    let newRetailOpenItemDTO: RetailOpenItemDTO[] = [];
    let parties = this.TotalCartBookingObj.find(x => cartIds.includes(x.Id));
    var obj = new SetRetailItems(this.datePipe, this.cs, this.retailservice, this.cs.propertySettings);
    obj.CreateBooking(parties, this.MultipleReservationAttemptData);
  }

  RetailUpdateOperation(PartyId, newamount, refundData: FinancialEffectResponse, isStandby, financialData: FinancialEffectResponse) {
    var obj = new SetRetailItems(this.datePipe, this.cs, this.retailservice, this.cs.propertySettings);

    let parties;
    // if (isStandby || this.BookingBehaviour == BookingBehavior.PrivateLesson) {
    //   parties = this.StandbyParties$.value.filter(x => x.Id == PartyId);
    //   if (!parties || parties.length == 0) {
    //     parties = this.Parties$.value.filter(x => x.Id == PartyId);
    //   }
    // }
    // else {
    //   parties = this.Parties$.value.filter(x => x.Id == PartyId);

    // }
    parties = this.Parties$.value.filter(x => x.Id == PartyId);
    if (!parties || parties.length == 0) {
      parties = this.StandbyParties$.value.filter(x => x.Id == PartyId);
    }
    if (financialData && Utilities.isRetailEnabledProperty(this._settings.General.RetailIntegrationDTO) && (financialData.PaymentGateway == PaymentGateways.AuthorizePay || financialData.PaymentGateway == PaymentGateways.IntegratedPayment)) {
      this.retailservice.oldReservation.fromWidget = true;
      parties = [this.retailservice.oldReservation, ...parties]
    }
   
    obj.UpdateBooking(parties[0], financialData);
    this.bookingDetailViewPopupDialogRef?.close();
  }

  //RetailCancelOperation(){
  // var obj = new SetRetailItems(this.datePipe, this.cs, this.retailservice, this.cs.propertySettings);
  //}
  
  RetailCanceloperation(Party?, TransactionData?, isStandby?, cancelledParty?, packageParties?) {
    let newRetailOpenItemDTO: RetailOpenItemDTO[] = [];
    this.ReservationAttemptData = TransactionData;
   this.MultipleReservationAttemptData = TransactionData;
   this.ReservationAttemptData = TransactionData;
    var obj = new SetRetailItems(this.datePipe, this.cs, this.retailservice, this.cs.propertySettings);
    obj.CancelBooking(Party, TransactionData);
    this.bookingDetailViewPopupDialogRef?.close();
  }

  RetailCheckInOperation(Party?, TransactionData?) {
    let party = this.Parties$.value.find(x => x.Id == Party.Id) || Party;
    var obj = new SetRetailItems(this.datePipe, this.cs, this.retailservice, this.cs.propertySettings);
    this.Operations = Operations.checkIn;
    this.ReservationAttemptData = TransactionData;
    this.LastPartyRequest = Party;
    this.selectedBooking = party;
    this.FinancialEffectId = TransactionData?.FinancialEffectId ?? this.FinancialEffectId;
    this.selectedParty$.next(party);
    obj.CheckinOperation(party, TransactionData,Operations.checkIn);
    this.bookingDetailViewPopupDialogRef.close();
  }

  RetailCheckoutOperation(Party?, TransactionData?) {
    let party = this.Parties$.value.find(x => x.Id == Party.Id) || Party;
    var obj = new SetRetailItems(this.datePipe, this.cs, this.retailservice, this.cs.propertySettings);
    this.Operations = Operations.checkOut;
    this.ReservationAttemptData = TransactionData;
    this.FinancialEffectId = TransactionData?.FinancialEffectId ?? this.FinancialEffectId;
    this.selectedBooking = party;
    this.selectedParty$.next(party);
    obj.CheckoutOperation(party, TransactionData);
    this.bookingDetailViewPopupDialogRef.close();
  }
ChargeGuestOperation(Party?, TransactionData?){
  let party = this.Parties$.value.find(x => x.Id == Party.Id) || Party;
  var obj = new SetRetailItems(this.datePipe, this.cs, this.retailservice, this.cs.propertySettings);
  this.Operations = Operations.charge;
  this.ReservationAttemptData = TransactionData;
  this.MultipleReservationAttemptData = TransactionData;
  this.FinancialEffectId = TransactionData?.FinancialEffectId ?? this.FinancialEffectId;
  obj.chargeParty(party, TransactionData);
 
}


  RetailAdditionalChargeOperation(PartyId, newamount, refundData, isStandby, financialData) {
    var obj = new SetRetailItems(this.datePipe, this.cs, this.retailservice, this.cs.propertySettings);

    let parties;
    if (isStandby || this.BookingBehaviour == BookingBehavior.PrivateLesson) {
      parties = this.StandbyParties$.value.filter(x => x.Id == PartyId);
      if (!parties || parties.length == 0) {
        parties = this.Parties$.value.filter(x => x.Id == PartyId);
      }
    }
    else {
      parties = this.Parties$.value.filter(x => x.Id == PartyId);

    }
    if (financialData && Utilities.isRetailEnabledProperty(this._settings.General.RetailIntegrationDTO) && (financialData.PaymentGateway == PaymentGateways.AuthorizePay || financialData.PaymentGateway == PaymentGateways.IntegratedPayment)) {
      this.retailservice.oldReservation.fromWidget = true;
    }
    parties = [this.retailservice.oldReservation, ...parties]
    this.ReservationAttemptData = financialData;
    obj.AdditionalChargeOperation(parties, financialData);
    this.bookingDetailViewPopupDialogRef?.close();
  }

  SetRetailItem(cartIds, PartyId?, amount?, TransactionData?, isStandby?, financialData?, cancelledParty?, cancellationFee?) {
    let newRetailOpenItemDTO: RetailOpenItemDTO[] = [];
    let parties;
    let Transaction = cancelledParty ? TransactionData?.cancelFinancialEffect : TransactionData;

    if (cancelledParty && cancelledParty.Type == PartyType.StandBy) {
      isStandby = true;
    }

    if (cartIds) {
      parties = this.TotalCartBookingObj.filter(x => cartIds.includes(x.Id));

    } else {
      if (this.guestChargeCancelledPartyDetails) {
        parties = [];
        parties.push(this.guestChargeCancelledPartyDetails);
      } else if (isStandby || this.BookingBehaviour == BookingBehavior.PrivateLesson) {
        parties = this.StandbyParties$.value.filter(x => x.Id == PartyId);
        if (!parties || parties.length == 0) {
          parties = this.Parties$.value.filter(x => x.Id == PartyId);
        }
      }
      else {
        parties = this.Parties$.value.filter(x => x.Id == PartyId);

      }
      if (financialData && ((financialData.PartyPaymentType == FinancialEffectType.PartialRefund) || (financialData.PartyPaymentType == FinancialEffectType.FullRefund)) && (financialData.OldTotalAmount == financialData.PaymentAmount)) {
        parties.forEach(party => {
          party.hideZeroPrice = false;
        });
      }
      if (financialData && Utilities.isRetailEnabledProperty(this._settings.General.RetailIntegrationDTO) && (financialData.PaymentGateway == PaymentGateways.AuthorizePay || financialData.PaymentGateway == PaymentGateways.IntegratedPayment)) {
        this.retailservice.oldReservation.fromWidget = true;
        parties = [this.retailservice.oldReservation, ...parties]
      }
      else if (cancelledParty && Utilities.isRetailEnabledProperty(this._settings.General.RetailIntegrationDTO) && (Transaction.PartySourceId == this._settings.PartySources.find(source => source.ExternalId?.toLowerCase() == 'restaurant')?.Id)) {
        this.retailservice.oldReservation = _.cloneDeep(cancelledParty);
        this.retailservice.oldReservation.fromWidget = true;
        this.retailservice.oldReservation.fromCancel = true;
        let otherParties = _.cloneDeep(parties);
        otherParties.forEach(element => {
          element.fromCancel = false;
          element.hideZeroPrice = true;
        });
        parties = [this.retailservice.oldReservation, ...otherParties]
      }
    }

    parties.forEach(item => {
      let Name = "Open Booking";
      let RetailItemId;
      let settings;
      let ExternalPOSItemId;
      let party;
      if (cartIds) {
        party = item.CartItemDetail;
      } else {
        party = item;
      }
      if (party.RestaurantId) {
        settings = this.cs.propertySettings.value[party.RestaurantId].settings;
      } else if (party.PropertyId) {
        settings = this.cs.propertySettings.value[party.PropertyId].settings;
      }
      if ((party.BookingBehavior == BookingBehavior.OpenBooking || party.BookingBehavior == BookingBehavior.PrivateLesson || this.retailservice.oldReservation.fromWidget || this.retailservice.oldReservation.fromCancel) && this.Operations == 1 &&
        item.RatePlanTotal && item.RatePlanTotal.RatePlanCalculations && item.RatePlanTotal.RatePlanCalculations.length) {
        for (let i in item.SlotLockResult) {
          Name = "Open Booking";
          let slotRate = null;
          let ignoreNegotiatedAmt = false;
          let ratePlanTimeRange = item.RatePlanTotal.RatePlanCalculations.filter(ratePlan => ratePlan.TimeRange.Start == i);
          if (ratePlanTimeRange.length) {
            if (ratePlanTimeRange[0].DepositAmount > 0) {
              ignoreNegotiatedAmt = true;
            }
            slotRate = ignoreNegotiatedAmt ? ratePlanTimeRange[0].DepositAmount : ratePlanTimeRange[0].TotalAmount;
            let formatedDayValue = this.datePipe.transform(new Date(i), 'MMM d');
            let formatedStartTime = this.datePipe.transform(new Date(i), 'hh:mm a');
            let formatedEndTime = this.datePipe.transform(new Date(ratePlanTimeRange[0].TimeRange.End), 'hh:mm a');

            if (party.SpecialMealId) {

              let sm = settings.SpecialMeals.filter(activity => activity.Id == party.SpecialMealId)[0];
              let retail = sm.RetailItemId ? settings.RetailItems.filter(r => r.Id == sm.RetailItemId)[0] : settings.RetailItems.filter(r => r.IsDefault)[0];
              if (this.Operations == 1 && sm.PrePaymentMode == PrePaymentMode.DepositPaymentMode) {
                Name = `${sm.Name} (Deposit) (${formatedDayValue}, ${formatedStartTime} - ${formatedEndTime})`;
                retail = this.getRetailDepositItem(settings);
              } else {
                Name = `${sm.Name} (${formatedDayValue}, ${formatedStartTime} - ${formatedEndTime})`;
              }

              RetailItemId = retail.RetailItemId;
              ExternalPOSItemId = retail.ExternalPOSItemId;
            } else {
              let retail = settings.RetailItems.filter(r => r.IsDefault)[0];
              RetailItemId = retail.RetailItemId;
              ExternalPOSItemId = retail.ExternalPOSItemId;
              Name = `${Name} (${formatedDayValue}, ${formatedStartTime} - ${formatedEndTime})`
            }
            let attemptData = null;
            this.ReservationAttemptData.filter(attempt => +attempt.CartId === item.Id).forEach(data => {
              if (data.Slots && data.Slots.length) {
                attemptData = data.Slots.filter(slot => slot.LocalTime == i)
              }
            });
            const openItemPayload = {} as RetailOpenItemDTO;
            let negotiatedAmount = ignoreNegotiatedAmt ? 0 : party.Slots.filter(slot => slot.Time == i)[0]?.NegotiatedAmount || 0;
            openItemPayload.PartyId = item.Id;
            openItemPayload.Id = party.Id || item.Id;
            openItemPayload.RatePlanAmount = slotRate + Number(negotiatedAmount || 0);
            openItemPayload.ItemDescription = Name;
            openItemPayload.SalesPrice = slotRate + Number(negotiatedAmount || 0);
            openItemPayload.ItemId = RetailItemId;
            openItemPayload.Description = Name;
            openItemPayload.BookingType = party.BookingBehavior;
            openItemPayload.TransactionId = Transaction?.LastRetailTrasaction?.TransactionId;
            openItemPayload.TicketNumber = Transaction?.LastRetailTrasaction?.TicketNumber;
            openItemPayload.QuantitySold = 1;
            openItemPayload.ExternalPOSItemId = ExternalPOSItemId;
            openItemPayload.GuestId = party.Contact.Id;
            openItemPayload.fromWidget = party.fromWidget ? true : false
            openItemPayload.GuestName = this.getGuestName(party?.Contact?.FirstName, party?.Contact?.LastName);
            openItemPayload.CartId = item.Id;
            openItemPayload.PayeeId = party.Id;
            openItemPayload.BookingAttemptId = attemptData && attemptData.length ? attemptData[0].ReservationAttemptId : null;
            openItemPayload.hideZeroPrice = party.hideZeroPrice ? true : false;
            newRetailOpenItemDTO.push(openItemPayload);
          }
        }

      } else {
        let TotalAmount = 0;
        if (this.Operations == 1) {
          if (item.RatePlanTotal.TotalDepositAmount > 0) {
            TotalAmount = item.RatePlanTotal.TotalDepositAmount;
          }
          else {
            TotalAmount = (amount ? Number(amount) : Number(item.RatePlanTotal.TotalRatePlan || 0) + Number(party.RatePlanAmount || 0) + Number(party.SpecialMealAmount || 0) + Number(party.NegotiatedAmount || 0) + Number(item.TotalPackageDiscountedAmount ? item.TotalPackageDiscountedAmount : 0));
          }
        }
        if (this.Operations == 2) {
          TotalAmount = Number(Transaction.TotalAmount - (Transaction.TaxAmount || 0) || 0);
        }
        if (this.Operations == 3) {
          this.ReservationAttemptData = Transaction;
          TotalAmount = amount;
        }
        if ((this.Operations == Operations.update && !party.SpecialMealId)) {
          let formatedDayValue = this.datePipe.transform(new Date(party.SeatingTime), 'MMM d');
          let formatedStartTime = this.datePipe.transform(new Date(party.SeatingTime), 'hh:mm a');
          let formatedEndTime = this.datePipe.transform(new Date(party.DepartureTime), 'hh:mm a');
          Name = `${Name} (${formatedDayValue}, ${formatedStartTime} - ${formatedEndTime})`;
        }
        if (this.Operations == Operations.checkIn || this.Operations == Operations.checkOut) {
          this.ReservationAttemptData = Transaction;

          if (this.ReservationAttemptData.CollectAdditionalCharge) {
            if (Transaction.PartyPaymentType == FinancialEffectType.NoEffect) {
              TotalAmount = Transaction.OldTotalAmount + (Transaction.AdditionalCharge || 0) - (Transaction.TaxAmount || 0)
            } else {
              TotalAmount = Transaction.TotalAmount + (Transaction.AdditionalCharge || 0) - (Transaction.TaxAmount || 0)
            }
          }
          else
            TotalAmount = Transaction.TotalAmount - (Transaction.TaxAmount || 0)

        }
        if (TotalAmount || party.fromCancel != undefined || (!TotalAmount && !party.state)) {
          if (party.SpecialMealId) {
            let sm = this.cs.specialMealListForMerchant.filter(activity => activity.Id == party.SpecialMealId)[0];
            let retail = sm.RetailItemId ? settings.RetailItems.filter(r => r.Id == sm.RetailItemId)[0] : settings.RetailItems.filter(r => r.IsDefault)[0];
            if (this.Operations == 3 && !party.fromCancel) {
              Name = this.productName(Transaction, sm.Name);
            } else {
              Name = sm.Name;
              if (this.BookingBehaviour == BookingBehavior.PrivateLesson) {
                let formatedDayValue = this.datePipe.transform(new Date(party.SeatingTime), 'MMM d');
                let formatedStartTime = this.datePipe.transform(new Date(party.SeatingTime), 'hh:mm a');
                let formatedEndTime = this.datePipe.transform(new Date(party.DepartureTime), 'hh:mm a');
                Name = `${Name} (${formatedDayValue}, ${formatedStartTime} - ${formatedEndTime})`;
              }
            }
            if (sm.PrePaymentMode == PrePaymentMode.DepositPaymentMode) {
              Name = Name + ' (Deposit)';
              if (this.Operations == Operations.create) {
                retail = this.getRetailDepositItem(settings);
              }
            }
            RetailItemId = retail.RetailItemId;
            ExternalPOSItemId = retail.ExternalPOSItemId;
          } else {
            if (this.retailservice.oldReservation.fromWidget) {
              let bookingName = this.ts.instant("openBooking");
              let formatedDayValue = this.datePipe.transform(new Date(this.retailservice.oldReservation.StartDate), 'MMM d');
              let formatedStartTime = this.datePipe.transform(new Date(this.retailservice.oldReservation.SeatingTime), 'hh:mm a');
              let formatedEndTime = this.datePipe.transform(new Date(this.retailservice.oldReservation.DepartureTime), 'hh:mm a');
              Name = `${bookingName} (${formatedDayValue}, ${formatedStartTime} - ${formatedEndTime})`
            }
            let retail = settings.RetailItems.filter(r => r.IsDefault)[0];
            RetailItemId = retail.RetailItemId;
            ExternalPOSItemId = retail.ExternalPOSItemId;
            if (this.Operations == 3 && party.fromCancel == undefined) {
              Name = this.productName(Transaction, Name);
            }
          }
          let ratePlanAmountAsCalculated = 0;
          if (party.fromWidget || party.fromCancel != undefined) {
            ratePlanAmountAsCalculated = party.RatePlanAmount * -1;
            if (this.Operations == Operations.checkIn || this.Operations == Operations.checkOut) {
              ratePlanAmountAsCalculated = Transaction.OldTotalAmount * -1;
            }
            if (party.fromCancel) {
              ratePlanAmountAsCalculated = (Transaction.PaymentAmount + Transaction.TotalAmount) * -1
            }
            else if (party.fromCancel == false) {
              ratePlanAmountAsCalculated = Transaction.TotalAmount
            }
          }
          else {
            ratePlanAmountAsCalculated = TotalAmount

          }
          const openItemPayload = {} as RetailOpenItemDTO;
          openItemPayload.PartyId = party.Id || item.Id;
          openItemPayload.Id = party.Id || item.Id;
          openItemPayload.RatePlanAmount = ratePlanAmountAsCalculated;
          openItemPayload.ItemDescription = Name;
          openItemPayload.SalesPrice = TotalAmount;
          openItemPayload.ItemId = RetailItemId;
          openItemPayload.Description = Name;
          openItemPayload.BookingType = party.BookingBehavior;
          openItemPayload.TransactionId = Transaction?.LastRetailTrasaction?.TransactionId;
          openItemPayload.TicketNumber = Transaction?.LastRetailTrasaction?.TicketNumber;
          openItemPayload.QuantitySold = 1;
          openItemPayload.ExternalPOSItemId = ExternalPOSItemId;
          openItemPayload.GuestId = party.Contact.Id;
          openItemPayload.fromWidget = party.fromWidget ? true : false
          openItemPayload.CartId = item.Id;
          openItemPayload.PayeeId = party.Id;
          openItemPayload.GuestName = this.getGuestName(party?.Contact?.FirstName, party?.Contact?.LastName);
          openItemPayload.hideZeroPrice = party.hideZeroPrice ? true : cancelledParty && !(TotalAmount > 0) ? true : false;
          openItemPayload.BookingAttemptId = (this.Operations == 2 && Transaction) ? Transaction.ReservationAttemptId : null;
          newRetailOpenItemDTO.push(openItemPayload);
        }
      }
    });

    let cancellationFeeParty = null;
    cancellationFeeParty = cancelledParty || parties[0]; // only one party will be cancelled, checked in, cheked out
    if (cancellationFee > 0 && cancellationFeeParty) {
      let settings;
      if (cancellationFeeParty.RestaurantId) {
        settings = this.cs.propertySettings.value[cancellationFeeParty.RestaurantId].settings;
      } else if (cancellationFeeParty.PropertyId) {
        settings = this.cs.propertySettings.value[cancellationFeeParty.PropertyId].settings;
      }

      let retailItem = settings.RetailItems.filter(r => (r.ItemType == ItemType.CancellationFee) || (r.ItemType == ItemType.DepositAndCancellationFee))[0];
      if (!retailItem) {
        this.showErrorPopUp(this.ts.instant('cancellationFeeMapError'), '450px', '400px');
        return;
      }
      const openItemPayload = {} as RetailOpenItemDTO;
      openItemPayload.PartyId = cancellationFeeParty.Id;
      openItemPayload.Id = cancellationFeeParty.Id;
      openItemPayload.RatePlanAmount = cancellationFee;
      openItemPayload.ItemDescription = "Cancellation Fee";
      openItemPayload.CancellationFee = cancellationFee;
      openItemPayload.SalesPrice = cancellationFee;
      openItemPayload.ItemId = retailItem.RetailItemId;
      openItemPayload.Description = "Cancellation Fee";
      //openItemPayload.BookingType = cancellationFeeParty.BookingBehavior;
      openItemPayload.TransactionId = Transaction?.LastRetailTrasaction?.TransactionId;
      openItemPayload.TicketNumber = Transaction?.LastRetailTrasaction?.TicketNumber;
      openItemPayload.QuantitySold = 1;
      openItemPayload.ExternalPOSItemId = retailItem.ExternalPOSItemId;
      openItemPayload.GuestId = cancellationFeeParty.Contact.Id;
      openItemPayload.fromWidget = cancellationFeeParty.fromWidget ? true : false; //need to ask
      openItemPayload.CartId = cancellationFeeParty.Id;
      openItemPayload.PayeeId = cancellationFeeParty.Id;
      openItemPayload.GuestName = this.getGuestName(cancellationFeeParty?.Contact?.FirstName, cancellationFeeParty?.Contact?.LastName);
      openItemPayload.hideZeroPrice = true;
      openItemPayload.ignoreTransaction = true;
      //openItemPayload.BookingAttemptId = (this.Operations == 2 && Transaction) ? Transaction.ReservationAttemptId : null;
      newRetailOpenItemDTO.push(openItemPayload);
    }
    this.retailservice.SetSeatOpenItemToRetail(newRetailOpenItemDTO, this.Operations, Transaction?.LastRetailTrasaction?.TransactionId, Transaction?.LastRetailTrasaction?.TicketNumber, PartyId, this.ReservationAttemptData, financialData);
  }

  getRetailDepositItem(settings): any {
    if (settings.RetailItems.find(r => (r.ItemType == ItemType.Deposit) || (r.ItemType == ItemType.DepositAndCancellationFee))) {
      return settings.RetailItems.filter(r => (r.ItemType == ItemType.Deposit) || (r.ItemType == ItemType.DepositAndCancellationFee))[0];
    }
  }

  getPackageName(PackageId , message){
    let packageName;
    if (PackageId) {
      packageName = this.cs.availablePackages.find(pack => pack.Id == PackageId)?.Name;
    }
    let packageValidationMessage;
    this.ts.get(message, { packageName }).subscribe(msgVal => {
      packageValidationMessage = msgVal;
    })
    return packageValidationMessage;
  }
  
  getDiscountPercentForCartItem(SpecialMealId: number, packageId: number = null) {
    let selectedPackage;
    if (packageId) {
      selectedPackage = this.packages.find(pack => pack.Id == packageId)
    } else {
      selectedPackage = this.selectedPackage
    }
    if (selectedPackage) {
      let packageDetails = {
        PackageId: selectedPackage.Id,
        PackageName: selectedPackage.Name,
        PackageDiscount: selectedPackage?.DiscountPercent || null,

      } as PackageShortDTO;
      if (selectedPackage.SingleDayActivityType == SingleDayActivityType.SpecificActivity) {
        packageDetails.PackageDiscount = selectedPackage.PackageActivities.find(item => (!item.ActivityId && !SpecialMealId) || (item.ActivityId && item.ActivityId == SpecialMealId))?.DiscountPercent;
      }
      return packageDetails;
    } else {
      return null;
    }
  }

  getGuestName(fName, lName) {
    let name = "";
    if (fName)
      name = fName;
    if (lName)
      name = name + " " + lName;
    return name.trim();
  }

  productName(Transaction, Name) {
    let updatedName = '';
    if (Transaction.TotalAmount && Transaction.CancellationAmount) {
      updatedName = `${Name} `;
    } else if (Transaction.TotalAmount) {
      updatedName = Name;
    }
    return updatedName;
  }

  retailCheckInOrCheckOut(party,partyId, activityId, bookedSessionId, isCheckOut, retailTransactions, operationType, reservationAttemptId?) {
    // let checkinCheckOutTransaction = {
    //   RetailTransactions: retailTransactions,
    //   RatePlanTotalNegotiation: this.ratePlanObject.RatePlan
    // }
    let checkinCheckOutTransaction = {
      "RatePlan" : this.ratePlanObject.RatePlan,
      "PropertyId" :  Utilities.RestaurantId(),
      "ReservationId":partyId ,
      "ReservationAttemptId": reservationAttemptId,
      "FinancialEffectId": this.FinancialEffectId,
      "BookedSessionId": bookedSessionId,
      "IsCheckOut": isCheckOut ,
      "OperationType": operationType
    }
    this.http.post(`${urlConfig.retailCheckInOrCheckOut}`, checkinCheckOutTransaction).subscribe(response => {
      console.log(response)
      let sessionObject = this.Parties$.value.find(data => data.Id == partyId.Id);
      if (this.Operations == Operations.checkIn) {
        this.openConfirmationDialog(response, this.ts.instant('attendeeCheckedIn') + ((bookedSessionId > 0 || activityId == null) ? this.ts.instant('sessionMsg') : this.ts.instant('classText') + this.getActivityName(activityId)), null, null, null, null, sessionObject);
        const isIGIntegrated = this.cs.settings.value.General.IgIntegrationDTO.IsEnabled;
        if (isIGIntegrated && !activityId) {
          let IgServerId = this.cs.settings.value.Servers.find(x => x.IgServerId != null)?.IgServerId;
          if (IgServerId) {
            this.LastPartyServerIds = [IgServerId];
          }
          this.openCheck(response);
        }
      }
      else {
        this.openConfirmationDialog(response, "The attendee is successfully checked out for " + (bookedSessionId > 0 ? this.ts.instant('sessionMsg') : this.ts.instant('classText') + this.getActivityName(activityId)), null, null, null, null, sessionObject);
      }
    });
  }
  cancelClassOrSessionWithoutPayment(party, cancelAllSessions, bookedSessionId, chargeCancellation, cancellationReason , financialEffectId) {
    this.subscriptions.add(this.cancelClassOrSession(party.Id, cancelAllSessions, bookedSessionId, chargeCancellation, cancellationReason , financialEffectId).subscribe(
      (data) => {
        if (data.Payload != null) {
          this.showAppPopup(this.ts.instant('cancelSuccessMsg'));
          this.showEmailPopup(data, data.Payload, ReservationEmailNotificationType.Cancelled, data.Payload.PropertyId);
          this.bookingDetailViewPopupDialogRef?.close();
        }
      }))
  }
  cancelOpenBooking(party,financialData) {
    this.subscriptions.add(this.cancelActivity(party.Id , false , financialData.FinancialEffectId).subscribe((response) => {
      this.showAppPopup(this.ts.instant('cancelSuccessMsg'));
      this.showEmailPopup(response, response.Payload, ReservationEmailNotificationType.Cancelled, response.Payload.PropertyId);
      this.bookingDetailViewPopupDialogRef?.close();
      this.selectedBooking = null;
    }))
  }


  updateOpenBooking(request, ignoreBookingValidation: boolean, reservationDate?, dialogRef?: MatDialogRef<any>, IsCardNeeded = false) {

    this.subscriptions.add( this.getFinancialDetails(request.PartyId, FinancialEffectAction.Update,this.ratePlanObject?.RatePlan,this.ratePlanObject?.ShopItems).subscribe(data => {
        if (data.ValidationMessages.length > 0) {
          this.openConfirmationDialog(data, data.ValidationMessages[0].Message, reservationDate);
        }
        const specialMeals = this.cs.settings.value.SpecialMeals.filter(x => x.Id == request.SpecialMealId)[0];
        let isDeferredPaymentMode = false;
        let includenoshow = false;
        let specialMealFee = false;
        let needConfirmationBeforeUpdaing = false;
        if (specialMeals != null && specialMeals.IsPrepaymentRequired && !specialMeals.ChargeOnBooking) {
          isDeferredPaymentMode = true;
          includenoshow = false;
          specialMealFee = true;
        }

        if (data && data.State === OperationResultState.Success) {
          this.paymentInfo = data.Payload;
          request.FinancialEffectId = this.paymentInfo.FinancialEffectId || null;
          if (this.ReservationAttemptData) {
            Object.keys(this.paymentInfo).forEach(key => {
              this.ReservationAttemptData[key] = this.paymentInfo[key];
            })
          }
          if (data.Payload && data.Payload.RedirectUrl) {
            this.editIframeUrl = data.Payload.RedirectUrl;
          }
          const updateConfirmationUpdateReservationPrice = ((data.Payload.PaymentTarget == 1 || data.Payload.PaymentTarget == 3 || data.Payload.PaymentTarget == 4) && data.Payload.TotalAmount != 0) ? data.Payload.TotalAmount : null;
          const PaymentAmount = data.Payload.PaymentAmount != null ? data.Payload.PaymentAmount : null;
          const RefundOption = data.Payload;
          const showUpdatedPaymentPopUp = false;
          let textLabel = '';
          switch (data.Payload.PartyPaymentType) {

            case 0: // NotSupported
              textLabel = this.ts.instant('NotSupported') + this.ts.instant('ReservationBetweenLocationPrePaidNotSupported');
              return;
              break;
            case 1: // NoPaymentsInvolved
              needConfirmationBeforeUpdaing = false;
              // No messaging in this case
              break;
            case 2: // NoEffect
              needConfirmationBeforeUpdaing = true;
              textLabel = this.ts.instant('NoChargeOrRefundForThisReservation');
              break;
            case 8: // PartialCharge //Done
              needConfirmationBeforeUpdaing = true;
              if (data.Payload.IsCreditCardNeeded) {
                textLabel = this.ts.instant("reservationUpdatedWithSeatingTypeRequiredPayment") + this.cs.operationCurrency + data.Payload.RatePlan.Unpaid.toFixed(2) + '. ' + this.ts.instant('enterCreditCard');
                this.popupService.nextBtnEnabled$.next(true);
              }
              else {
                if(data.Payload.RatePlan.Paid > 0)
                textLabel = this.ts.instant('CardUsedForTheOriginalBookingWillCharged') + this.cs.operationCurrency + data.Payload.RatePlan.Unpaid.toFixed(2) + '.';
                else 
                textLabel = this.ts.instant('CardNeededToUpdateReservation') + this.cs.operationCurrency + data.Payload.RatePlan.Unpaid.toFixed(2) + '.'
              }
              break;
            case 9: // PartialRefund //Done
              needConfirmationBeforeUpdaing = true;
              textLabel = this.ts.instant('Refundrequestof') + this.cs.operationCurrency + data.Payload.RatePlan.Unpaid.toFixed(2) + this.ts.instant('hasbeenprocessed') + '. ';
              break;
            case 10: // NoShowFeeUpdated //Done
              needConfirmationBeforeUpdaing = false;
              break;
            case 11: // Authorize //Done
              needConfirmationBeforeUpdaing = true;
              textLabel = this.ts.instant('CardUsedAtBookingWillBeChargedAtMealTime') + this.cs.operationCurrency + updateConfirmationUpdateReservationPrice;
              break;
            case 12: // Authorize updated //Done
              needConfirmationBeforeUpdaing = true;
              textLabel = this.ts.instant('CardUsedAtBookingWillBeChargedAtMealTime') + this.cs.operationCurrency + updateConfirmationUpdateReservationPrice;
              break;
          }

          ////Need to be removed
          needConfirmationBeforeUpdaing = true;

          if (needConfirmationBeforeUpdaing && !this.IsSkipPayment) {
            if(request.isDragged) 
            this.showPaymentConfirmationPopup(request, textLabel, reservationDate, dialogRef, ComponentTypes.EditOpenBooking, RefundOption, IsCardNeeded || data.Payload?.RedirectUrl, data.Payload);
            else
            this.showUpdatedPaymentConfirmationPopUp(request, textLabel, reservationDate, dialogRef, ComponentTypes.EditOpenBooking, RefundOption, IsCardNeeded || data.Payload?.RedirectUrl, data.Payload);
          } else {
            if (Utilities.isRetailEnabledProperty(this._settings.General.RetailIntegrationDTO)) {
              this.confirmUpdateOpenBooking(request, false, dialogRef, reservationDate);
              return;
            }
            this.popupService.nextBtnEnabled$.next(false);
          }
        }
      }));
    // this.subscriptions.add(this.httpService.post(`${urlConfig.updateOpenBookingURL}?restaurantId=${Utilities.RestaurantId()}`,
    //  request).subscribe(
    //    data => {
    //      this.popupService.closeDialog$.next();
    //      this.showPopUp('Reservation done Successfully');
    //      if (data.Payload && data.Payload.PurchaseForm) {
    //        window.open(data.Payload.PurchaseForm.Url, 'Payment Form', 'height=750,width=500');
    //      } else if (data.Payload && data.Payload.NewParties && Object.keys(data.Payload.NewParties).length) {
    //        window.open(data.Payload.NewParties[Object.keys(data.Payload.NewParties)[0]]
    //          .PurchaseForm.Url, 'Payment Form', 'height=750,width=500');
    //      }
    //    }
    //  ));
  }

  createPrivateLessonBooking(request, ignoreBookingValidation: boolean, seatingTime?, dialogRef?: MatDialogRef<any>) {
    this.subscriptions.add(this.httpService.post(`${urlConfig.createPrivateLessonBookingURL}?restaurantId=${Utilities.RestaurantId()}&ignoreBookingValidation=${ignoreBookingValidation}`,
      request).subscribe(
        data => {

          if (data.State == OperationResultState.ConsentMessages && data.ValidationMessages?.length) {
            this.showExistingReservationPopup(ComponentTypes.AddPrivateLessonBooking, request, data.ValidationMessages, dialogRef, null)
            return;
          }

          this.popupService.closeDialog$.next();
          // this.openPurchaseForm(data, null);

          this.showAppPopup(this.ts.instant('ReservationSuccessMessage'));
          if (data.Payload && data.Payload && data.Payload.EmailAddress && this.cs.settings.value.General.HostConfirmationEmailSendBehavior == PartyEmailSendBehavior.Prompt) {
            // this.openConfirmationDialog(data, null, null, ReservationEmailNotificationType.Created);
            this.showEmailNotification(data, ReservationEmailNotificationType.Created);
          }
          /* if (data.Payload && this._settings.General.RetailIntegrationDTO.IsEnabled && this._settings.RetailItems && this._settings.RetailItems.length > 0) {

            this.SetRetailItem(data.Payload.PartyId);
          } else  */{
            if (data.Payload && data.Payload.PurchaseForm) {
              Utilities.openPurchaseForm(data.Payload.PurchaseForm.Url);
            } else if (data.Payload && data.Payload.NewParties && Object.keys(data.Payload.NewParties).length) {
              Utilities.openPurchaseForm(data.Payload.NewParties[Object.keys(data.Payload.NewParties)[0]]
              .PurchaseForm.Url);
            }
          }
        }
      ));
  }

  updatePrivateLessonBooking(request, ignoreBookingValidation: boolean, reservationDate?, dialogRef?: MatDialogRef<any>, IsCardNeeded = false) {

    this.subscriptions.add(this.getFinancialDetails(request.PartyId, FinancialEffectAction.Update,this.ratePlanObject?.RatePlan,this.ratePlanObject?.ShopItems).subscribe(data => {
        if (data.ValidationMessages.length > 0) {
          this.openConfirmationDialog(data, data.ValidationMessages[0].Message, reservationDate);
        }
        const specialMeals = this.cs.settings.value.SpecialMeals.filter(x => x.Id == request.SpecialMealId)[0];
        let isDeferredPaymentMode = false;
        let includenoshow = false;
        let specialMealFee = false;
        let needConfirmationBeforeUpdaing = false;
        if (specialMeals != null && specialMeals.IsPrepaymentRequired && !specialMeals.ChargeOnBooking) {
          isDeferredPaymentMode = true;
          includenoshow = false;
          specialMealFee = true;
        }

        if (data && data.State === OperationResultState.Success) {
          this.paymentInfo = data.Payload;
          if(!request?.FinancialEffectId) {
            request.FinancialEffectId = data.Payload.FinancialEffectId;
          }
          this.ReservationAttemptData = data.Payload.RatePlanTotal;
          if (data.Payload && data.Payload.RedirectUrl) {
            this.editIframeUrl = data.Payload.RedirectUrl;


          }
          const updateConfirmationUpdateReservationPrice = ((data.Payload.PaymentTarget == 1 || data.Payload.PaymentTarget == 3 || data.Payload.PaymentTarget == 4) && data.Payload.TotalAmount != 0) ? data.Payload.TotalAmount : null;
          const PaymentAmount = data.Payload.PaymentAmount != null ? data.Payload.PaymentAmount : null;
          const RefundOption = data.Payload;
          const showUpdatedPaymentPopUp = false;
          let textLabel = '';
          switch (data.Payload.PartyPaymentType) {

            case 0: // NotSupported
              textLabel = this.ts.instant('NotSupported') + this.ts.instant('ReservationBetweenLocationPrePaidNotSupported');
              return;
              break;
            case 1: // NoPaymentsInvolved
              needConfirmationBeforeUpdaing = false;
              // No messaging in this case
              break;
            case 2: // NoEffect
              needConfirmationBeforeUpdaing = true;
              textLabel = this.ts.instant('NoChargeOrRefundForThisReservation');
              break;
            case 8: // PartialCharge //Done
              needConfirmationBeforeUpdaing = true;
              if (data.Payload.IsCreditCardNeeded) {
                textLabel = this.ts.instant("reservationUpdatedWithSeatingTypeRequiredPayment") + this.cs.operationCurrency + data.Payload.RatePlan.Unpaid.toFixed(2) + '. ' + this.ts.instant('enterCreditCard');
                this.popupService.nextBtnEnabled$.next(true);
              }
              else {
                if(data.Payload.RatePlan.Paid > 0)
                textLabel = this.ts.instant('CardUsedForTheOriginalBookingWillCharged') + this.cs.operationCurrency + data.Payload.RatePlan.Unpaid.toFixed(2) + '.';
                else
                textLabel = this.ts.instant('CardNeededToUpdateReservation') + this.cs.operationCurrency + data.Payload.RatePlan.Unpaid.toFixed(2) + '.';
              }
              break;
            case 9: // PartialRefund //Done
              needConfirmationBeforeUpdaing = true;
              textLabel = this.ts.instant('Refundrequestof') + this.cs.operationCurrency + data.Payload.RatePlan.Unpaid.toFixed(2) + this.ts.instant('hasbeenprocessed') + '. ';
              break;
            case 10: // NoShowFeeUpdated //Done
              needConfirmationBeforeUpdaing = false;
              break;
            case 11: // Authorize //Done
              needConfirmationBeforeUpdaing = true;
              textLabel = this.ts.instant('CardUsedAtBookingWillBeChargedAtMealTime') + this.cs.operationCurrency + updateConfirmationUpdateReservationPrice;
              break;
            case 12: // Authorize updated //Done
              needConfirmationBeforeUpdaing = true;
              textLabel = this.ts.instant('CardUsedAtBookingWillBeChargedAtMealTime') + this.cs.operationCurrency + updateConfirmationUpdateReservationPrice;
              break;
          }

          if (needConfirmationBeforeUpdaing && !this.IsSkipPayment) {
            if(request.isDragged) 
            this.showPaymentConfirmationPopup(request, textLabel, reservationDate, dialogRef, ComponentTypes.EditPrivateLessonBooking, RefundOption, IsCardNeeded || data.Payload?.RedirectUrl, data.Payload);
            else
            this.showUpdatedPaymentConfirmationPopUp(request, textLabel, reservationDate, dialogRef, ComponentTypes.EditPrivateLessonBooking, RefundOption, IsCardNeeded || data.Payload?.RedirectUrl, data.Payload);
          } else {
            if (Utilities.isRetailEnabledProperty(this._settings.General.RetailIntegrationDTO)) {
              this.confirmUpdatePrivateLessonBooking(request, false, dialogRef, reservationDate);
              return;
            }
            this.popupService.nextBtnEnabled$.next(false);
          }
        }
      }));
  }

  createSessionBooking(request, ignoreBookingValidation: boolean) {
    request.PageMethod = request.Contact.PreferredPageMethod;
    //this.getcustomFieldValidation(request.Contact);
    if (this.guestFieldValidation) {
      this.subscriptions.add(this.httpService.post(`${urlConfig.createSessionBookingURL}?restaurantId=${Utilities.RestaurantId()}&ignoreBookingValidation=${ignoreBookingValidation}`,
        request).subscribe(
          data => {
            if (data.State == OperationResultState.ConsentMessages && data.ValidationMessages?.length) {
              this.showExistingReservationPopup(ComponentTypes.AddActivityBooking, request, data.ValidationMessages, null, null)
            }
            else {
              this.popupService.closeDialog$.next();

              this.openPurchaseForm(data, null);
            }
          }
        ));
    }
  }

  updateSessionBooking(request, reservationDate?, dialogRef?: MatDialogRef<any>, IsCardNeeded = false) {
    // request.PageMethod = request.Contact?.PreferredPageMethod;
    this.subscriptions.add(this.getFinancialDetails(request.PartyId, FinancialEffectAction.Update,this.ratePlanObject?.RatePlan,this.ratePlanObject?.ShopItems).subscribe(data => {
        if (data.ValidationMessages.length > 0) {
          this.openConfirmationDialog(data, data.ValidationMessages[0].Message, reservationDate);
        }
        const specialMeals = this.cs.settings.value.SpecialMeals.filter(x => x.Id == request.SpecialMealId)[0];
        let isDeferredPaymentMode = false;
        let includenoshow = false;
        let specialMealFee = false;
        let needConfirmationBeforeUpdaing = false;
        if (specialMeals != null && specialMeals.IsPrepaymentRequired && !specialMeals.ChargeOnBooking) {
          isDeferredPaymentMode = true;
          includenoshow = false;
          specialMealFee = true;
        }

        if (data && data.State === OperationResultState.Success) {
          this.paymentInfo = data.Payload;
          this.ReservationAttemptData = data.Payload;
          // Object.keys(this.paymentInfo).forEach(key => {
          //   this.ReservationAttemptData[key] = this.paymentInfo[key];
          // })
          if (data.Payload && data.Payload.RedirectUrl) {
            this.editIframeUrl = data.Payload.RedirectUrl;


          }
          const updateConfirmationUpdateReservationPrice = ((data.Payload.PaymentTarget == 1 || data.Payload.PaymentTarget == 3 || data.Payload.PaymentTarget == 4) && data.Payload.TotalAmount != 0) ? data.Payload.TotalAmount : null;
          const PaymentAmount = data.Payload.PaymentAmount != null ? data.Payload.PaymentAmount : null;
          const showUpdatedPaymentPopUp = false;
          const RefundOption = data.Payload;
          let textLabel = '';
          switch (data.Payload.PartyPaymentType) {

            case 0: // NotSupported
              textLabel = this.ts.instant('NotSupported') + this.ts.instant('ReservationBetweenLocationPrePaidNotSupported');
              return;
              break;
            case 1: // NoPaymentsInvolved
              needConfirmationBeforeUpdaing = false;
              // No messaging in this case
              break;
            case 2: // NoEffect
              needConfirmationBeforeUpdaing = true;
              textLabel = this.ts.instant('NoChargeOrRefundForThisReservation');
              break;
            case 8: // PartialCharge //Done
              needConfirmationBeforeUpdaing = true;
              if (data.Payload.IsCreditCardNeeded) {
                textLabel = this.ts.instant("reservationUpdatedWithSeatingTypeRequiredPayment") + this.cs.operationCurrency + data.Payload.RatePlan.Unpaid.toFixed(2) + '. ' + this.ts.instant('enterCreditCard');
                this.popupService.nextBtnEnabled$.next(true);
              }
              else {
                if(data.Payload.RatePlan.Paid > 0)
                textLabel = this.ts.instant('CardUsedForTheOriginalBookingWillCharged') + this.cs.operationCurrency + data.Payload.RatePlan.Unpaid.toFixed(2) + '.';
                else 
                textLabel = this.ts.instant('CardNeededToUpdateReservation') + this.cs.operationCurrency + data.Payload.RatePlan.Unpaid.toFixed(2) + '.';
              }
              break;
            case 9: // PartialRefund //Done
              needConfirmationBeforeUpdaing = true;
              textLabel = this.ts.instant('Refundrequestof') + this.cs.operationCurrency + (data.Payload.RatePlan.Unpaid.toFixed(2) * -1) + this.ts.instant('hasbeenprocessed') + '. ';
              break;
            case 10: // NoShowFeeUpdated //Done
              needConfirmationBeforeUpdaing = false;
              break;
            case 11: // Authorize //Done
              needConfirmationBeforeUpdaing = true;
              textLabel = this.ts.instant('CardUsedAtBookingWillBeChargedAtMealTime') + this.cs.operationCurrency + updateConfirmationUpdateReservationPrice;
              break;
            case 12: // Authorize updated //Done
              needConfirmationBeforeUpdaing = true;
              textLabel = this.ts.instant('CardUsedAtBookingWillBeChargedAtMealTime') + this.cs.operationCurrency + updateConfirmationUpdateReservationPrice;
              break;
          }
          if (needConfirmationBeforeUpdaing && !this.IsSkipPayment) {
            this.showUpdatedPaymentConfirmationPopUp(request, textLabel, null, dialogRef, ComponentTypes.EditActivityBooking, RefundOption, IsCardNeeded || data.Payload?.RedirectUrl, data.Payload);
          } else {
            if (Utilities.isRetailEnabledProperty(this._settings.General.RetailIntegrationDTO)) {
              this.confirmUpdateSessionBooking(request, false, dialogRef);
              return;
            }
            this.popupService.nextBtnEnabled$.next(false);
          }
        }
      }));
  }
  confirmUpdateSessionBooking(request, ignoreBookingValidation: boolean, dialogRef) {
    this.isStandBy = request.IsForStandbyReservations;
    request.FinancialEffectId = this.paymentInfo.FinancialEffectId || null;
    let url = this.isStandBy ? (`${urlConfig.updateStandbyActivity}?restaurantId=${Utilities.RestaurantId()}&ignoreBookingValidation=${ignoreBookingValidation}&updateContactOnly=${this.updateContactOnly}`) :
      (`${urlConfig.updateSessionBookingURL}?restaurantId=${Utilities.RestaurantId()}&ignoreBookingValidation=${ignoreBookingValidation}`)

    this.subscriptions.add(this.httpService.post(url,
      request).subscribe(data => {

        if (data.State == OperationResultState.ConsentMessages && data.ValidationMessages?.length) {
          this.showExistingReservationPopup(ComponentTypes.EditActivityBooking, request, data.ValidationMessages, dialogRef, null)
          return;
        }

        this.IsUpdateReservation = true;
        this.bookingConfirmationData = data.Payload;
        this.bookingConfirmationData.PropertyId = Utilities.RestaurantId();
        let { EnableTicketPrinting } = this.cs.specialMealListForMerchant.find(meal => meal.Id === data.Payload.SpecialMealId);
        let checkedInSessions = data.Payload.ConfirmedSessionsForCart.filter((ticket) => ticket.SessionType === PartyType.Reservation && (ticket.SessionState !== PartyState.Seated && ticket.SessionState !== PartyState.Left))
        if (EnableTicketPrinting && checkedInSessions?.length > 0) {
          if (data) {
            if (dialogRef) {
              dialogRef.close();
            }
          }
          this.ShowTicket();
        } else {
          // this.openConfirmationDialog(data, this.ts.instant(Labels[Labels.reservationupdatedconfirmationtext]), null, null, ComponentTypes.PartyCreation);
          if (dialogRef) {
            dialogRef.close();
          }
          this.showAppPopup(this.ts.instant(Labels[Labels.reservationupdatedconfirmationtext]))
        }
        if (data.Payload && data.Payload.EmailAddress && this.cs.settings.value.General.HostUpdateEmailSendBehavior == PartyEmailSendBehavior.Prompt)  {
          // this.openConfirmationDialog(data, null, null, ReservationEmailNotificationType.Updated);
          this.showEmailNotification(data, ReservationEmailNotificationType.Updated);
        }
        // if (data.Payload && data.Payload.PurchaseForm) {
          //   window.open(data.Payload.PurchaseForm.Url, 'Payment Form', 'height=750,width=500');
          // }
          if (this.popupSubscription) {
            this.popupSubscription.unsubscribe();
          }
          if (this.partyCreationSubscription) { this.partyCreationSubscription.unsubscribe(); }
        this.subscriptions.add(this.popupSubscription = this.closeParent$.subscribe((data) => {
          if (data) {
            if (dialogRef) {
              dialogRef.close();
            }
          }
        }));
        this.partyCreationSubscription = this.popupService.confirmedAction$.subscribe(val => {
          if (val == ComponentTypes.PartyCreation) {
            if (this.closeParent$)
              this.closeParent$.next(true);
          }
        });
        if (this.cs.settings.value.General.RetailIntegrationDTO.IsEnabled && this.cs.settings.value.RetailItems && this.cs.settings.value.RetailItems.length > 0) {
          this.isRetailOpenItemUpdated$.next(data);
        }
      }));
  }

  confirmUpdateOpenBooking(request, ignoreBookingValidation, resDialogRef, reservationDate?) {
    let url;
    if (request.IsForStandbyReservations) {
      url = `${urlConfig.UpdateStandByOpenBookingURL}?restaurantId=${Utilities.RestaurantId()}&ignoreBookingValidation=${ignoreBookingValidation}`
    } else {
      url = `${urlConfig.updateOpenBookingURL}?restaurantId=${Utilities.RestaurantId()}&ignoreBookingValidation=${ignoreBookingValidation}`
    }
    this.subscriptions.add(this.httpService.post(url, request).subscribe(data => {
      if (data.State == OperationResultState.ConsentMessages && data.ValidationMessages?.length) {
        this.showExistingReservationPopup(ComponentTypes.EditOpenBooking, request, data.ValidationMessages, resDialogRef, reservationDate)
        return;
      }

      this.IsUpdateReservation = true;
      this.showAppPopup(this.ts.instant(Labels[Labels.reservationupdatedconfirmationtext]));
      if (resDialogRef) {
        resDialogRef.close();
      }
      if (data.Payload && data.Payload.EmailAddress && this.cs.settings.value.General.HostUpdateEmailSendBehavior == PartyEmailSendBehavior.Prompt)  {
        this.showEmailNotification(data, ReservationEmailNotificationType.Updated);
      }

      if (this.popupSubscription) {
        this.popupSubscription.unsubscribe();
      }
      if (this.partyCreationSubscription) { this.partyCreationSubscription.unsubscribe(); }
      this.subscriptions.add(this.popupSubscription = this.closeParent$.subscribe((data) => {
        if (data) {
          if (resDialogRef) {
            resDialogRef.close();
          }
        }
      }));
      this.partyCreationSubscription = this.popupService.confirmedAction$.subscribe(val => {
        if (val == ComponentTypes.PartyCreation) {
          if (this.closeParent$) {
            this.closeParent$.next(true);
          }
        }
      });
      if (this.cs.settings.value.General.RetailIntegrationDTO.IsEnabled && this.cs.settings.value.RetailItems && this.cs.settings.value.RetailItems.length > 0) {
        this.isRetailOpenItemUpdated$.next(data);
      }
    }));

  }

  confirmUpdatePrivateLessonBooking(request, ignoreBookingValidation, resDialogRef, reservationDate?) {
    this.isStandBy = request.IsForStandbyReservations;
    let url = this.isStandBy ? (`${urlConfig.updatePrivateLessonBookingURL}?restaurantId=${Utilities.RestaurantId()}&ignoreBookingValidation=${ignoreBookingValidation}&updateContactOnly=${this.updateContactOnly}`) :
      (`${urlConfig.updatePrivateLessonBookingURL}?restaurantId=${Utilities.RestaurantId()}&ignoreBookingValidation=${ignoreBookingValidation}`)
    this.subscriptions.add(this.httpService.post(url,
      request).subscribe(data => {      //resDialogRef.close();
        //this.popupService.closeDialog$.next();
        if (data.State == OperationResultState.ConsentMessages && data.ValidationMessages?.length) {
          this.showExistingReservationPopup(ComponentTypes.EditPrivateLessonBooking, request, data.ValidationMessages, resDialogRef, reservationDate)
          return;
        }

        this.IsUpdateReservation = true;
        if (data.Payload && data.Payload.EmailAddress && this.cs.settings.value.General.HostUpdateEmailSendBehavior == PartyEmailSendBehavior.Prompt)  {
          // this.openConfirmationDialog(data, null, null, ReservationEmailNotificationType.Updated);
          this.showEmailNotification(data, ReservationEmailNotificationType.Updated);
        }
        let anyConfirmedReservations = data.Payload?.ConfirmedSessionsForCart?.filter(p => p.SessionType == PartyType.Reservation)?.length;
        let { EnableTicketPrinting, IsForStandbyReservations } = this.cs.specialMealListForMerchant.find(meal => meal.Id === data.Payload.SpecialMealId);
        if ((EnableTicketPrinting && !IsForStandbyReservations && anyConfirmedReservations && anyConfirmedReservations.length) || (this.Parties$.value.find((x) => x.Id === data.Payload.PartyId)?.isConfirmedReservation && EnableTicketPrinting)) {
          this.bookingConfirmationData = data.Payload;
          if (data) {
            if (resDialogRef) {
              resDialogRef.close();
            }
          }
          this.ShowTicket();
        } else {
          if (resDialogRef) {
            resDialogRef.close();
          }
          this.showAppPopup(this.ts.instant(Labels[Labels.reservationupdatedconfirmationtext]));
          // this.openConfirmationDialog(data, this.ts.instant(Labels[Labels.reservationupdatedconfirmationtext]), reservationDate, null, ComponentTypes.PartyCreation);
        }
        // if (data.Payload && data.Payload.PurchaseForm) {
        //   window.open(data.Payload.PurchaseForm.Url, 'Payment Form', 'height=750,width=500');
        // }
        if (this.popupSubscription) {
          this.popupSubscription.unsubscribe();
        }
        if (this.partyCreationSubscription) { this.partyCreationSubscription.unsubscribe(); }
        this.subscriptions.add(this.popupSubscription = this.closeParent$.subscribe((data) => {
          if (data) {
            if (resDialogRef) {
              resDialogRef.close();
            }
          }
        }));
        this.partyCreationSubscription = this.popupService.confirmedAction$.subscribe(val => {
          if (val == ComponentTypes.PartyCreation) {
            if (this.closeParent$) {
              this.closeParent$.next(true);
            }
          }
        });
        if (this.cs.settings.value.General.RetailIntegrationDTO.IsEnabled && this.cs.settings.value.RetailItems && this.cs.settings.value.RetailItems.length > 0) {
          this.isRetailOpenItemUpdated$.next(data);
        }

      }));
  }


  confirmChargeGuest(ReservationAttemptId){
    // let RatePlanTotalNegotiation = {
    //   Negotiation: null,
    //   applyNegotiationOnOtherCharges: [
    //     {
    //       bookingChargeType: BookingChargeType.ServiceCharge,
    //       TotalAmount: this.chargeRatePlan.TotalServiceCharge,
    //       NegotiatedAmount: null,
    //       UseAfterNegotiation: this.negotiateServiceCharge
    //     }
    //   ],
    //   RatePlanCalculationInfoNegotiation: this.chargeRatePlan.RatePlanCalculations
    // }
    let requestPayload ={
    "PropertyId": Utilities.RestaurantId(),
    "ReservationId": this.selectedBooking.Id,
    "FinancialEffectId": this.ratePlanObject.FinancialEffectId || null,
    "RatePlan": this.ratePlanObject?.RatePlan || null,
    "ReservationAttemptId":ReservationAttemptId?.[0]
    }
    this.subscriptions.add(this.httpService.post(`${urlConfig.retailPartyPayment}`,
    requestPayload).subscribe(data => {
      let response = data.Payload;
     if (data.State == 0) {
      this.openConfirmationDialog(data, this.selectedBooking.PrepaymentState == PartyPrepaymentState.RefundDuePending ? this.ts.instant('Party has been refunded successfully') : this.ts.instant('Party has been charged successfully'), null, null, ComponentTypes.actionCharged);
     }
    }));
  }

  form

  showPopUp(message) {
    const popUpMessage = [{
      confirmationMessage: message, showAlert: false
    }];
    const componentInfo = Utilities.setComponentDetails(ConfirmationPopupComponent, 'small', 'action', popUpMessage,
      '');
    const okbutton = this.ts.instant('ok');
    const dialogRef = this.openCustomPopup(componentInfo, ComponentTypes.commonconfirmmessage,
      popupDialogDimension.actionDialogWidth, popupDialogDimension.actionDialogHeight, false, '', okbutton, '', true);
  }

  showAppPopup(message) {
    const popUpMessage = [{
      confirmationMessage: message, showAlert: false
    }];
    const componentInfo = Utilities.setComponentDetails(ConfirmationPopupComponent, 'small', 'action', popUpMessage,
      '');
    const okbutton = this.ts.instant('ok');
    const dialogRef = this.openAppPopup(componentInfo, ComponentTypes.commonconfirmmessage,
      popupDialogDimension.actionDialogWidth, popupDialogDimension.actionDialogHeight, false, '', okbutton, '', true);

    return dialogRef;
  }

  openPurchaseForm(result, seatingTime) {
    if (result.Payload && result.Payload.EmailAddress && this.cs.settings.value.General.HostConfirmationEmailSendBehavior == PartyEmailSendBehavior.Prompt) {
      // this.openConfirmationDialog(result, null, null, ReservationEmailNotificationType.Created);
      this.showEmailNotification(result, ReservationEmailNotificationType.Created);
    }
    this.openConfirmationDialog(result, this.ts.instant(Labels[Labels.reservationconfirmedconfirmationtext]), seatingTime, null, ComponentTypes.PartyCreation);
   /*  if (result.Payload && this._settings.General.RetailIntegrationDTO.IsEnabled && this._settings.RetailItems && this._settings.RetailItems.length > 0) {

      this.SetRetailItem(result.Payload.PartyId);
    } else */ {
      if (result.Payload && result.Payload.PurchaseForm) {
        Utilities.openPurchaseForm(result.Payload.PurchaseForm.Url);
      }
    }
  }

  openPurchaseFormActivity(result, seatingTime) {
    if (result.Payload && result.Payload.EmailAddress) {
      // this.openConfirmationDialog(result, null, null, ReservationEmailNotificationType.Created);
      this.showEmailNotification(result, ReservationEmailNotificationType.Created);
    }
    this.openConfirmationDialog(result, this.ts.instant(Labels[Labels.reservationconfirmedconfirmationtext]), seatingTime, null, ComponentTypes.PartyCreation);
    if (result.Payload && result.Payload.PurchaseForm) {
      Utilities.openPurchaseForm(result.Payload.PurchaseForm.Url);
    }
  }
  unseatParty(partyId) {
    this.LastPartyRequest = this.Parties$.value.length > 0 ? this.Parties$.value.filter(val => val.Id == partyId)[0] : this.cs.state.value.SeatingParties.filter(val => val.Id == partyId)[0];
    return this.httpService.post(`${urlConfig.unseatPartyURL}?restaurantId=${Utilities.RestaurantId()}&partyId=${partyId}`, null);
  }

  noShowParty(partyId) {
    return this.httpService.post(`${urlConfig.noShowPartyURL}?restaurantId=${Utilities.RestaurantId()}&partyId=${partyId}`, null);
  }
  clearParty(partyId) {
    return this.httpService.post(`${urlConfig.clearPartyURL}?restaurantId=${Utilities.RestaurantId()}&partyId=${partyId}`, null);
  }

  checkOutOpenBooking(partyId) {
    return this.httpService.post(`${urlConfig.CheckOutOpenBookingURL}?restaurantId=${Utilities.RestaurantId()}&partyId=${partyId}`, null);
  }

  checkOutPrivateLessonBooking(partyId) {
    return this.httpService.post(`${urlConfig.CheckOutPrivateLessonBookingURL}?restaurantId=${Utilities.RestaurantId()}&partyId=${partyId}`, null);
  }

  getPartyPaymentStaus(partyId) {
    return this.httpService.get(`${urlConfig.getPartyPaymentStausURL}?partyId=${partyId}`);
  }

  updateReservation(request, reservationDate?, dialogRef?: MatDialogRef<any>) {

    this.subscriptions.add(this.httpService.post(`${urlConfig.financialEffectForUpdatedReservation}?restaurantId=${Utilities.RestaurantId()}`,
      request).subscribe(data => {
        if (data.ValidationMessages.length > 0) {
          this.openConfirmationDialog(data, data.ValidationMessages[0].Message, reservationDate);
        }
        const specialMeals = this.cs.settings.value.SpecialMeals.filter(x => x.Id == request.SpecialMealId)[0];
        let isDeferredPaymentMode = false;
        let isWebReservation = false;
        let includenoshow = false;
        let specialMealFee = false;
        let needConfirmationBeforeUpdaing = false;
        if (request && request.PartySourceId) {
          isWebReservation = this.isWebReservation(request.PartySourceId);
        }
        if (specialMeals != null && specialMeals.IsPrepaymentRequired && !specialMeals.ChargeOnBooking) {
          isDeferredPaymentMode = true;
          includenoshow = false;
          specialMealFee = true;
        }

        if (data && data.State === OperationResultState.Success) {
          const updateConfirmationUpdateReservationPrice = ((data.Payload.PaymentTarget == 1 || data.Payload.PaymentTarget == 3 || data.Payload.PaymentTarget == 4) && data.Payload.TotalAmount != 0) ? data.Payload.TotalAmount : null;
          const PaymentAmount = data.Payload.PaymentAmount != null ? data.Payload.PaymentAmount : null;
          const RefundOption = data.Payload;
          const showUpdatedPaymentPopUp = false;

          let textLabel = '';
          switch (data.Payload.PartyPaymentType) {

            case 0: // NotSupported
              textLabel = this.ts.instant('NotSupported') + this.ts.instant('ReservationBetweenLocationPrePaidNotSupported');
              return;
            case 1: // NoPaymentsInvolved
              needConfirmationBeforeUpdaing = false;
              // No messaging in this case
              break;
            case 2: // NoEffect
              needConfirmationBeforeUpdaing = true;
              textLabel = this.ts.instant('NoChargeOrRefundForThisReservation');
              break;
            case 8: // PartialCharge //Done
              needConfirmationBeforeUpdaing = true;
              if (data.Payload.IsCreditCardNeeded) {
                textLabel = this.ts.instant('reservationUpdatedWithSeatingTypeRequiredPayment') + this.cs.operationCurrency + data.Payload.PaymentAmount.toFixed(2) + '. ' + this.ts.instant('enterCreditCard');
              }
              else {
                textLabel = this.ts.instant('CardUsedForTheOriginalBookingWillCharged') + this.cs.operationCurrency + data.Payload.PaymentAmount.toFixed(2) + '.';
              }
              break;
            case 9: // PartialRefund //Done
              needConfirmationBeforeUpdaing = true;
              textLabel = this.ts.instant('Refundrequestof') + this.cs.operationCurrency + PaymentAmount.toFixed(2) + this.ts.instant('hasbeenprocessed') + '. ';
              //textLabel = this.ts.instant('CardUsedForOriginalReservationWillBeRefunded + this.cs.operationCurrency + PaymentAmount.toFixed(2) + '. ' + this.ts.instant('SeveralBusinessDays;
              break;
            case 10: // NoShowFeeUpdated //Done
              needConfirmationBeforeUpdaing = false;
              break;
            case 11: // Authorize //Done
              needConfirmationBeforeUpdaing = true;
              textLabel = this.ts.instant('CardUsedAtBookingWillBeChargedAtMealTime') + this.cs.operationCurrency + updateConfirmationUpdateReservationPrice;
              break;
            case 12: // Authorize updated //Done
              needConfirmationBeforeUpdaing = true;
              textLabel = this.ts.instant('CardUsedAtBookingWillBeChargedAtMealTime') + this.cs.operationCurrency + updateConfirmationUpdateReservationPrice;
              break;
          }

          if (needConfirmationBeforeUpdaing) {
            this.showUpdatedPaymentConfirmationPopUp(request, textLabel, reservationDate, dialogRef, ComponentTypes.reservation, RefundOption);
          } else {
            request.LanguageId = Number(sessionStorage.getItem('languageId')) || globals.DEFAULT_LANGUAGE_ID;
            this.subscriptions.add(this.httpService.post(`${urlConfig.updateReservationURL}?restaurantId=${Utilities.RestaurantId()}`,
              request).subscribe(data => {
                this.IsUpdateReservation = true;
                if (data.Payload && data.Payload.EmailAddress && this.cs.settings.value.General.HostUpdateEmailSendBehavior == PartyEmailSendBehavior.Prompt)  {
                  // this.openConfirmationDialog(data, null, null, ReservationEmailNotificationType.Updated);
                  this.showEmailNotification(data, ReservationEmailNotificationType.Updated);
                }
                this.openConfirmationDialog(data, this.ts.instant(Labels[Labels.reservationupdatedconfirmationtext]), reservationDate, null, ComponentTypes.PartyCreation);
                if (data.Payload && data.Payload.PurchaseForm) {
                  Utilities.openPurchaseForm(data.Payload.PurchaseForm.Url);
                }
                this.popupSubscription = this.closeParent$.subscribe((data) => {
                  if (data) {
                    if (dialogRef) {
                      dialogRef.close();
                      if (this.popupSubscription) { this.popupSubscription.unsubscribe(); }
                      if (this.partyCreationSubscription) { this.partyCreationSubscription.unsubscribe(); }
                      if (this.cancelSubscription) { this.cancelSubscription.unsubscribe(); }
                    }
                  }
                });
                this.partyCreationSubscription = this.popupService.confirmedAction$.subscribe(val => {
                  if (val == ComponentTypes.PartyCreation) {
                    if (this.closeParent$)
                      this.closeParent$.next(true);
                  }
                });
              }));
          }
        }
      }));
  }

  async CheckRatePlanDifference(tableIds: any, inputData: any, isAssignTable: boolean = false): Promise<boolean> {
    let ratePlanAmount = 0;
    if (tableIds && tableIds.length > 0) {
      if (isAssignTable) {
        var response = await this.SetRatePlan(tableIds, inputData.SeatingTime);
        ratePlanAmount = (response != null && response.Payload != null) ? response.Payload.TotalRatePlan : 0;
      }
      else {
        var response = await this.SetRatePlan(tableIds);
        ratePlanAmount = (response != null && response.Payload != null) ? response.Payload.TotalRatePlan : 0;
      }
    }
    if ((inputData.RatePlanAmount || inputData.RatePlanAmount > 0) && ratePlanAmount == 0) {
      return false;
    }
    else
      return true;
  }

  showUpdatedPaymentConfirmationPopUp(request, textLabel, reservationDate, resDialogRef: MatDialogRef<any>, componentType, refundData?: FinancialEffectResponse, IsCreditCardNeeded?, financialData?: FinancialEffectResponse) {
    if (this.confirmSubscription) {
      this.confirmSubscription.unsubscribe();
    }
    if (this.cancelSubscription) {
      this.cancelSubscription.unsubscribe();
    }
    const cancelText = this.ts.instant('Cancel');
    const title = this.ts.instant('Confirmation');
    const msg = textLabel;
    let cancelUpdation = false;
    const updateText = this.ts.instant('Proceed');
    const showAlert = true;
    const popUpMessage = [{
      confirmationMessage: msg, dialogTitle: this.ts.instant('Confirmation'), showAlert
    }];
    const componentDetails: ComponentDetails = {
      componentName: ConfirmationPopupComponent,
      dimensionType: 'small',
      popupType: 'active',
      popUpDetails: {
        isStepper: false,
        eventName: 'notifyParent'
      },
      popupInput: popUpMessage,
      popupTitle: popUpMessage[0].dialogTitle
    };
    const dialogRef = this.dialog.open(CustomPopupComponent, {
      disableClose: true,
      width: '600px',
      height: '410px',
      data: {
        title,
        update: updateText,
        cancel: cancelText,
        componentDetails,
        from: componentType,
        back: false,
        standalone: true,
        showAlert: true,
        showClose: false
      }
    });
    let refundConfirmSubscription = this.popupService.confirmedAction$.subscribe(val => {
      refundConfirmSubscription.unsubscribe();
      let newamount = refundData && refundData.PartyPaymentType == 9 ? -refundData.RatePlan.Total : refundData.RatePlan.Total;
      if (request && this.cs.settings.value.General.RetailIntegrationDTO.IsEnabled && this.cs.settings.value.RetailItems && this.cs.settings.value.RetailItems.length > 0) {
        resDialogRef?.close();
        if (financialData) {
          this.ReservationAttemptData = financialData;
        }
        this.RetailUpdateOperation(request.PartyId, newamount, refundData, request.IsForStandbyReservations, financialData);
        //  this.SetRetailItem(null, request.PartyId, newamount, refundData, request.IsForStandbyReservations, financialData);

      }
      else {
        if (refundData && refundData.RefundOption) {
          this.showRefundOptions(refundData, val, request, reservationDate, resDialogRef, componentType);
        } else {
          this.updateRefundReservation(val, request, reservationDate, resDialogRef, IsCreditCardNeeded);
        }
      }
    });
    let refundCancelSubscription = this.popupService.cancelledAction$.subscribe(val => {
      if ((val.value === ComponentTypes.reservation || val.value === ComponentTypes.EditActivityBooking) && !cancelUpdation) {
        cancelUpdation = true;
        this.popupService.nextBtnEnabled$.next(false);
        //if (this.confirmSubscription) {
        //  this.confirmSubscription.unsubscribe();
        // }
        //if (this.cancelSubscription) {
        //  this.cancelSubscription.unsubscribe();
        // }
      }

      if (componentType === ComponentTypes.EditActivityBooking || componentType === ComponentTypes.EditOpenBooking || componentType === ComponentTypes.EditPrivateLessonBooking) {
        this.tabChange$.next(1);
      }
      if ((val.from == ComponentTypes.EditActivityBooking || val.from == ComponentTypes.EditOpenBooking || val.from == ComponentTypes.EditPrivateLessonBooking) && !Utilities.isRetailEnabledProperty(this.cs.settings.value.General.RetailIntegrationDTO)) {
        if (val.value == 0) {
          this.popupService.enableSaveBtnAuthorise$.next(true)
        } else if (val.value == 1) {
          resDialogRef?.close();
        }
      }
    });
    dialogRef.afterClosed().subscribe(() => {
      if (refundConfirmSubscription) { refundConfirmSubscription.unsubscribe(); }
      if (refundCancelSubscription) { refundCancelSubscription.unsubscribe(); }
      if (this.confirmSubscription) {
        this.confirmSubscription.unsubscribe();
      }
      if (this.cancelSubscription) {
        this.cancelSubscription.unsubscribe();
      }
    })
  }

  updateRefundReservation(val, request, reservationDate, resDialogRef, IsCreditCardNeeded?) {
    if (val === ComponentTypes.reservation) {
      var updateReservation = true;
      request.LanguageId = Number(sessionStorage.getItem('languageId')) || globals.DEFAULT_LANGUAGE_ID
      this.subscriptions.add(this.httpService.post(`${urlConfig.updateReservationURL}?restaurantId=${Utilities.RestaurantId()}`,
        request).subscribe(data => {
          this.IsUpdateReservation = true;
          if (data.Payload && data.Payload.EmailAddress && this.cs.settings.value.General.HostUpdateEmailSendBehavior == PartyEmailSendBehavior.Prompt)  {
            this.showEmailNotification(data, ReservationEmailNotificationType.Updated);
          }
          this.openConfirmationDialog(data, this.ts.instant(Labels[Labels.reservationupdatedconfirmationtext]), reservationDate, null, ComponentTypes.PartyCreation);
          if (data.Payload && data.Payload.PurchaseForm) {
            Utilities.openPurchaseForm(data.Payload.PurchaseForm.Url);
          }
        }));
      this.subscriptions.add(this.popupSubscription = this.closeParent$.subscribe((data) => {
        if (data) {
          if (resDialogRef) {
            resDialogRef.close();
            if (this.popupSubscription) { this.popupSubscription.unsubscribe(); }
            if (this.partyCreationSubscription) { this.partyCreationSubscription.unsubscribe(); }
            if (this.cancelSubscription) { this.cancelSubscription.unsubscribe(); }
          }
        }
      }));
      this.partyCreationSubscription = this.popupService.confirmedAction$.subscribe(val => {
        if (val == ComponentTypes.PartyCreation) {
          if (this.closeParent$) {
            this.closeParent$.next(true);
          }
        }
      });
    }
    else if (val === ComponentTypes.EditActivityBooking || val === ComponentTypes.EditOpenBooking || val === ComponentTypes.EditPrivateLessonBooking) {
      if (this.confirmSubscription) {
        this.confirmSubscription.unsubscribe();
      }
      if (this.cancelSubscription) {
        this.cancelSubscription.unsubscribe();
      }
      if (val === ComponentTypes.EditActivityBooking && !IsCreditCardNeeded) {
        this.confirmUpdateSessionBooking(request, false, resDialogRef);
      } else if (val === ComponentTypes.EditOpenBooking && !IsCreditCardNeeded) {
        this.confirmUpdateOpenBooking(request, false, resDialogRef, reservationDate);
      }
      else if (val === ComponentTypes.EditPrivateLessonBooking && !IsCreditCardNeeded) {
        this.confirmUpdatePrivateLessonBooking(request, false, resDialogRef, reservationDate);
      }
    }
  }

  showRefundOptions(refundData, val, request, reservationDate, resDialogRef, componentType?) {
    if (this.confirmSubscription) {
      this.confirmSubscription.unsubscribe();
    }

    refundData.memberShipId = this.selectedGuest?.memberShipId || null;
    const componentDetails: ComponentDetails = {
      componentName: RefundPopupComponent,
      popupType: 1 != null ? '' : 'action',
      dimensionType: 'large',
      popUpDetails: {
        isStepper: false,
        eventName: 'notifyParent'
      },
      popupInput: refundData,
      popupTitle: this.ts.instant('SelectRefundvalues')
    };
    const dialogRef = this.dialog.open(CustomPopupComponent, {
      disableClose: true,
      height: '80vh',
      width: '80%',
      maxWidth: '60vw',
      data: {
        title: this.ts.instant('SelectRefundstrategy'),
        update: 'Proceed',
        cancel: 'Cancel',
        componentDetails,
        from: ComponentTypes.guestBookService,
        back: false,
        standalone: true,
        showAction: true,
      },
    });
    let refundOptionCancelSubscription = this.popupService.cancelledAction$.subscribe(value => {
      this.popupService.nextBtnEnabled$.next(false);
      if (refundOptionCancelSubscription) { refundOptionCancelSubscription.unsubscribe(); }
      if (refundOptionConfirmSubscription) { refundOptionConfirmSubscription.unsubscribe(); }
      if (componentType === ComponentTypes.EditActivityBooking || componentType === ComponentTypes.EditOpenBooking || componentType === ComponentTypes.EditPrivateLessonBooking) {
        this.tabChange$.next(1);
      }
    });
    let refundOptionConfirmSubscription = this.popupService.confirmedAction$.subscribe(value => {
      console.log(this.refundData);
      if (value == ComponentTypes.guestBookService) {
        request.RefundData = [...this.refundData];
        this.updateRefundReservation(val, request, reservationDate, resDialogRef);
        if (refundOptionCancelSubscription) { refundOptionCancelSubscription.unsubscribe(); }
        if (refundOptionConfirmSubscription) { refundOptionConfirmSubscription.unsubscribe(); }
      }
    });
    dialogRef.afterClosed().subscribe(() => {
      if (refundOptionCancelSubscription) { refundOptionCancelSubscription.unsubscribe(); }
      if (refundOptionConfirmSubscription) { refundOptionConfirmSubscription.unsubscribe(); }
    })
  }

  updateOperation(val, request, reservationDate, resDialogRef) {
    if (val === ComponentTypes.reservation) {
      if (this.confirmSubscription) {
        this.confirmSubscription.unsubscribe();
      }
      if (this.cancelSubscription) {
        this.cancelSubscription.unsubscribe();
      }
      //updateReservation = true;
      request.LanguageId = Number(sessionStorage.getItem('languageId')) || globals.DEFAULT_LANGUAGE_ID
      this.subscriptions.add(this.httpService.post(`${urlConfig.updateReservationURL}?restaurantId=${Utilities.RestaurantId()}`,
        request).subscribe(data => {
          //resDialogRef.close();
          //this.popupService.closeDialog$.next();
          this.IsUpdateReservation = true;
          this.openConfirmationDialog(data, this.ts.instant(Labels[Labels.reservationupdatedconfirmationtext]), reservationDate, null, ComponentTypes.PartyCreation);
          if (data.Payload && data.Payload.PurchaseForm) {
            Utilities.openPurchaseForm(data.Payload.PurchaseForm.Url);
          }
          if (this.popupSubscription) {
            this.popupSubscription.unsubscribe();
          }
          if (this.partyCreationSubscription) { this.partyCreationSubscription.unsubscribe(); }
          this.subscriptions.add(this.popupSubscription = this.closeParent$.subscribe((data) => {
            if (data) {
              if (resDialogRef) {
                resDialogRef.close();
              }
            }
          }));
          this.partyCreationSubscription = this.popupService.confirmedAction$.subscribe(val => {
            if (val == ComponentTypes.PartyCreation) {
              if (this.closeParent$) {
                this.closeParent$.next(true);
              }
            }
          });
        }));
    }
    else if (val === ComponentTypes.EditActivityBooking) {
      if (this.confirmSubscription) {
        this.confirmSubscription.unsubscribe();
      }
      if (this.cancelSubscription) {
        this.cancelSubscription.unsubscribe();
      }
      this.confirmUpdateSessionBooking(request, false, resDialogRef);
    }
    else if (val === ComponentTypes.EditPrivateLessonBooking) {
      if (this.confirmSubscription) {
        this.confirmSubscription.unsubscribe();
      }
      if (this.cancelSubscription) {
        this.cancelSubscription.unsubscribe();
      }
      this.confirmUpdatePrivateLessonBooking(request, false, resDialogRef);
    }
    else if (val === ComponentTypes.EditOpenBooking) {
      if (this.confirmSubscription) {
        this.confirmSubscription.unsubscribe();
      }
      if (this.cancelSubscription) {
        this.cancelSubscription.unsubscribe();
      }
      //updateReservation = true;
      this.subscriptions.add(this.httpService.post(`${urlConfig.updateOpenBookingURL}?restaurantId=${Utilities.RestaurantId()}&ignoreBookingValidation=${false}`,
        request).subscribe(data => {
          //resDialogRef.close();
          //this.popupService.closeDialog$.next();

          if (data.State == OperationResultState.ConsentMessages && data.ValidationMessages?.length) {
            this.showExistingReservationPopup(ComponentTypes.EditOpenBooking, request, data.ValidationMessages, resDialogRef, reservationDate)
            return;
          }

          this.IsUpdateReservation = true;
          if (data.Payload && data.Payload.EmailAddress && this.cs.settings.value.General.HostUpdateEmailSendBehavior == PartyEmailSendBehavior.Prompt)  {
            // this.openConfirmationDialog(data, null, null, ReservationEmailNotificationType.Updated);
            this.showEmailNotification(data, ReservationEmailNotificationType.Updated);
          }
          this.openConfirmationDialog(data, this.ts.instant(Labels[Labels.reservationupdatedconfirmationtext]), reservationDate, null, ComponentTypes.PartyCreation);
          if (data.Payload && data.Payload.PurchaseForm) {
            Utilities.openPurchaseForm(data.Payload.PurchaseForm.Url);
          }
          if (this.popupSubscription) {
            this.popupSubscription.unsubscribe();
          }
          if (this.partyCreationSubscription) { this.partyCreationSubscription.unsubscribe(); }
          this.subscriptions.add(this.popupSubscription = this.closeParent$.subscribe((data) => {
            if (data) {
              if (resDialogRef) {
                resDialogRef.close();
              }
            }
          }));
          this.partyCreationSubscription = this.popupService.confirmedAction$.subscribe(val => {
            if (val == ComponentTypes.PartyCreation) {
              if (this.closeParent$) {
                this.closeParent$.next(true);
              }
            }
          });
        }));
    }
  }

  getSpecialEventAvailability(tableIds : any[] = []){
    let tableWithSpecialEvent = [];
    if(tableIds?.length){
      tableWithSpecialEvent  = this.layoutCofiguartion.tables.filter(table => tableIds.includes(table.Id))?.filter(item => item.EventId);
    }
    return tableWithSpecialEvent;
  }
  getSpecialEventName(tableWithSpecialEvent){
    let eventName = this.cs.settings.value.SpecialMeals.find(meal => meal.Id == tableWithSpecialEvent[0].EventId)?.Name;
    return this.ts.instant('specialEventSelectionWarning' , {specialMealName : eventName , eventName: eventName})

  }
  specialEventValidation(tableWithSpecialEvent) {
    if (tableWithSpecialEvent?.length) {
      const popUpMessage = [{
        confirmationMessage: this.getSpecialEventName(tableWithSpecialEvent),
        dialogTitle: this.ts.instant('alert'),
        showAlert: true
      }];
      const componentDetails: ComponentDetails = Utilities.setComponentDetails(ConfirmationPopupComponent, 'small', 'active', popUpMessage,
        popUpMessage[0].dialogTitle);
      const dialogData =  {
        disableClose: true,
        width: '550px',
        height: '400px',
        data: {
          title: popUpMessage[0].dialogTitle,
          update: 'Yes',
          cancel: 'No',
          componentDetails,
          from: ComponentTypes.specialEventSelection,
          back: false,
          standalone: true,
          showAlert: false
        }
      };
      return dialogData;

    }
  }

  SendConfirmaionEmail(partyId: number, email: string, type: ReservationEmailNotificationType, cancelledIds: any, bookedSessionId: number, classOrSessionBooking: boolean, confirmedBookedSessionId: any = null) {
    let language = localStorage.getItem(`${sessionStorage.getItem(`sessionGUID${Utilities.getSessionStorageType()}`)}_language`);
    if (Utilities.IsActivitiesVenues(this.cs.settings.value.PropertyType))
      return this.httpService.post(`${urlConfig.sendConfirmationForActivitiesURL}?restaurantId=${Utilities.RestaurantId()}&partyId=${partyId}&emailAddress=${email}&type=${type}&language=${language}&bookedSessionId=${bookedSessionId}&classOrSessionBooking=${classOrSessionBooking}&isStandBy=${this.isStandBy}&confirmedBookedSessionId=${confirmedBookedSessionId}`, cancelledIds);
    else
      return this.httpService.post(`${urlConfig.sendConfirmationURL}?restaurantId=${Utilities.RestaurantId()}&partyId=${partyId}&emailAddress=${email}&type=${type}`, null);
  }

  getHistoryUrl(contactId, allRestaurantHistory) {
    return this.httpService.get(`${urlConfig.getHistoryUrl}?restaurantId=${Utilities.RestaurantId()}&contactId=${contactId}&allRestaurantHistory=${allRestaurantHistory}`);
  }

  getWaiverFormUrl(confirmationCode) {
    return this.httpService.get(`${urlConfig.getWaiverFormUrl}?restaurantId=${Utilities.RestaurantId()}&confirmationCode=${confirmationCode}`);
  }

  getPartyAuditLog(partyId: number) {
    return this.httpService.get(`${urlConfig.getPartyAuditLogUrl}?restaurantId=${Utilities.RestaurantId()}&partyId=${partyId}&options=${AuditLogOperationOptions.Simplified}`);
  }

  getActivityAuditLog(activityId: number) {
    return this.httpService.get(`${urlConfig.getActivityAuditLogUrl}?restaurantId=${Utilities.RestaurantId()}&activityId=${activityId}&options=${AuditLogOperationOptions.Simplified}`);
  }

  getActivityBlockAuditLog(rule) {
    let req = {
      Entities: [ 15 ],
      EntityIds:[ rule.CustomizationId]
    }
    const postURL = `${urlConfig.getAuditLog}?restaurantId=${Utilities.RestaurantId()}`;
    return this.httpService.post(postURL, req);
  }

  getManualSlotAuditLog(slotId: number, isDefault: boolean) {
    return this.httpService.get(`${urlConfig.getManualSlotAuditLogUrl}?restaurantId=${Utilities.RestaurantId()}&manualSlotId=${slotId}&isDefault=${isDefault}&options=${AuditLogOperationOptions.Simplified}`);
  }

  getCartList() {
    return this.httpService.get(`${urlConfig.getCartList}?hostid=${Utilities.getHostId()}&merchantid=${this.cs.settings.value.General.MerchantId}`)
    .pipe(map(response => {
      if(response.Payload){
        response.Payload = response.Payload.filter(({ CartItemDetail: { PartySourceId } }) => PartySourceId !== ReservationSource.POS);
      response.Payload.forEach((CartItem, index) => {
       // CartItem.CartGroupName =  CartItem.CartGroupName || (CartItem.CartItemDetail.Contact?.FirstName || '') + (CartItem.CartItemDetail.Contact?.LastNameName || '') + ` - #${CartItem.Id}`
        CartItem.CartItemDetail.ExpiresAt
      });
    }
     return response;
    }));
  }

  lockCartItems(cartIds, propertyId, bypassLock,expireTime) {
    return this.httpService.post(`${urlConfig.lockCartItems}?propertyId=${propertyId}&hostid=${Utilities.getHostId()}&merchantid=${this.cs.settings.value.General.MerchantId}&bypassLock=${bypassLock}&ExpireAt=${expireTime}`, cartIds, false, propertyId);
  }
  extendCartLock(cartIds, propertyId, holdMinutes: number) {    
    return this.httpService.post(`${urlConfig.extendCartLock}?propertyId=${propertyId}&holdMinutes=${holdMinutes}`, cartIds, false, propertyId);
  }

  lockCartForMultipleProperties(cartIdWithPropertyIdList, bypassLock) {
    return this.httpService.post(`${urlConfig.lockCartForMultipleProperties}?bypassLock=${bypassLock}`, cartIdWithPropertyIdList);
  }

  unLockCartForMultipleProperties(cartIdWithPropertyIdList:MultiplePropertyUnlockRequestDTO[]) {
    return this.httpService.post(`${urlConfig.unlockCartForMultipleProperties}`, cartIdWithPropertyIdList);
  }

  unlockCartItems(propertyId, cartIds) {
    return this.httpService.post(`${urlConfig.unlockCartItems}?propertyid=${propertyId}&hostid=${Utilities.getHostId()}&merchantid=${this.cs.settings.value.General.MerchantId}`, cartIds, false, propertyId);
  }

  proceedCartBooking(cartIds) {
    return this.httpService.post(`${urlConfig.proceedCartBooking}?propertyid=${Utilities.RestaurantId()}&hostid=${Utilities.getHostId()}&merchantid=${this.cs.settings.value.General.MerchantId}`, cartIds);
  }

  proceedCartBookingForMultipleProperties(RegisterReservationAttemptRequestDTO , ignoreValidation:boolean = false) {
    return this.httpService.post(`${urlConfig.RegisterMultiplePropertyActivityReservationAttempts}?ignoreValidation=${ignoreValidation}`, RegisterReservationAttemptRequestDTO);
  }

  proceedDiningRegisterReservationAttempts(RegisterReservationAttemptRequestDTO){
    return this.httpService.post(urlConfig.RegisterMultipleReservationAttempts, RegisterReservationAttemptRequestDTO);
  }

  removeCartItems(cartIds) {
    return this.httpService.post(`${urlConfig.deleteCartItems}?restaurantId=${Utilities.RestaurantId()}`, cartIds);
  }

  createWaitList(request: UpdatedWalkInDTO, isQuickSeat: boolean = false, from?: ComponentTypes, dialogRef?: MatDialogRef<any>) {
    this.LastPartyRequest = request;
    this.LastPartyServerIds = request.ServerIdForNonAssignedTables;
    this.subscriptions.add(this.httpService.post(`${urlConfig.createWalkInURL}?restaurantId=${Utilities.RestaurantId()}`, request).subscribe((response) => {
      if (response && !isQuickSeat) {
        this.openConfirmationDialog(response, this.ts.instant(Labels[Labels.waitlistcreatedconfirmationtext]), null, null,
          ComponentTypes.PartyCreation);

        this.popupSubscription = this.closeParent$.subscribe((data) => {
          if (data) {
            if (dialogRef) {
              dialogRef.close();
              this.popupService.closeDialog$.next(true);
              if (this.popupSubscription) { this.popupSubscription.unsubscribe(); }
              if (this.partyCreationSubscription) { this.partyCreationSubscription.unsubscribe(); }
              if (this.cancelSubscription) { this.cancelSubscription.unsubscribe(); }
            }
          }
        });
        this.partyCreationSubscription = this.popupService.confirmedAction$.subscribe(val => {
          if (val === ComponentTypes.PartyCreation) {
            if (this.closeParent$) {
              if (this.reservationDialogRef) {
                this.reservationDialogRef.close();
                this.reservationDialogRef = null;
              }
              this.closeParent$.next(true);
            }
          }
        });
      }
      
      let booking =  this.Parties$?.value?.find(party => party.Id == response.Payload.PartyId);
      booking.TableIds = request.TableIds;
      // let isSameTable = _.isEqual(booking.TableIds, this.SelectedTableIds);
      // if(!isSameTable && this.SelectedTableIds?.length) {
      //   booking.TableIds = this.SelectedTableIds;
      //   let tableNames = Utilities.getTableNamesFromStandaloneTables(this.SelectedTableIds, this.cs.layout.value.FloorPlans);
      //   booking.TableNames = tableNames;
      // }
      this.chitPrintPartyData = [booking];
      if (isQuickSeat) {
        if (response.Payload && response.Payload.PurchaseForm) {
          Utilities.openPurchaseForm(response.Payload.PurchaseForm.Url);
        }

        const isIGIntegrated = this.cs.settings.value.General.IgIntegrationDTO.IsEnabled;
        if (isIGIntegrated) {
          this.openCheck(response);
        }
        else {
          setTimeout(() => {
           this.toPrintchit();
          }, 200)
        }
      }
    }));
  }

  openCheck(response?) {
    const isOpenCheckWithItems = this.cs.propertySettings.value[Utilities.RestaurantId()].settings.PropertySetting[0]?.OpenWithCheckItem;
    if (isOpenCheckWithItems && response && response.Payload && (response.Payload.PurchaseForm || response.Payload.Url)) {
      let partyId = response.Payload.PartyId ? response.Payload.PartyId : this.LastPartyRequest.Id;
      this.isPaymentSuccessfull$.subscribe((responsePartyId) => { //payment is involved 
        if (responsePartyId == partyId) {// check if payment is done and append price details to the response later check on change processor side for emitting
          this.RequestPartyPaymentStatus(partyId);
        }
      })
    }
    else if (isOpenCheckWithItems && this.LastPartyRequest && this.LastPartyRequest.Id > 0) { // deffered payment     
      this.RequestPartyPaymentStatus(this.LastPartyRequest.Id);
    }
    else {
      let partyId = response && response.Payload && response.Payload.PartyId > 0 ? response.Payload.PartyId : this.LastPartyRequest.Id;
      this.RequestOpenCheck(partyId);
    }
  }

  RequestOpenCheck(partyId, price?, taxAmount?) {
    const isIGIntegrated = this.cs.settings.value.General.IgIntegrationDTO.IsEnabled;
    const isOpenCheckWithItems = this.cs.propertySettings.value[Utilities.RestaurantId()].settings.PropertySetting[0]?.OpenWithCheckItem;
    const igCheckSettings = this.cs.propertySettings.value[Utilities.RestaurantId()].settings.IGCheckConfigurations;
    let middlewareIpAddress = this.cs.settings.value.General.IgIntegrationDTO.MiddlewareIpAddress;
    if (middlewareIpAddress && isIGIntegrated) {
      const requestedPayload = {} as SeatOperationDetailsDTO;
      requestedPayload.PartyId = partyId;
      requestedPayload.RestaurantId = Utilities.RestaurantId();
      requestedPayload.PartySize = this.LastPartyRequest.Size;
      const serverVal = Utilities.getIGServerForStandaloneTables(this.LastPartyRequest.TableIds, this.cs.layout.value.FloorPlans, this.cs.settings.value);
      requestedPayload.ServerIds = serverVal && serverVal.length > 0 ? serverVal : this.LastPartyServerIds;
      requestedPayload.TableNames = Utilities.getTableNamesFromStandaloneTables(this.LastPartyRequest.TableIds, this.cs.layout.value.FloorPlans);
      const MiddlewareserviceURl = this.GetOpenCheckURL(price);
      this.cs.IGServerCallInOpenCheck = true;
      if(price && price > 0 && taxAmount) {
        price = price - taxAmount;
      }

      if (price && isOpenCheckWithItems) {
        let checkItems = new CheckItems();
        checkItems.itemid = 0; //defaulting to 0 this will be mapped in middleware
        if(igCheckSettings) {
          if(igCheckSettings.IGCheckCalculationType == IGCalculationType.FlatRate){
            if(igCheckSettings.AmountCriteria > price){
              checkItems.price = -price;
            }
            else{
              checkItems.price = igCheckSettings.AmountCriteria;
            }
          }
          if(igCheckSettings.IGCheckCalculationType == IGCalculationType.Percentage){
            let amtCriteria = igCheckSettings.AmountCriteria.replace('%','');
            checkItems.price = price * (amtCriteria/100);
          }
        }
        else
        {
          checkItems.price = -price;
        }
        checkItems.quantity = 1;
        requestedPayload.CheckItems = [checkItems];
      }
      return this.http.post(MiddlewareserviceURl, requestedPayload).subscribe(data => {
        const response = data as IGResponseDTO;
        this.cs.IGServerCallInOpenCheck = false;
        this.toPrintchit();
      });
    }
    else {
      this.toPrintchit();
    }
  }

  RequestPartyPaymentStatus(partyId) {
    this.getPartyPaymentStaus(partyId).subscribe(data => {
      if (data && data.Payload && data.Payload.PaidAmount > 0 && (data.Payload.ChargeState == PartyChargeState.Charged || data.Payload.ChargeState == PartyChargeState.None)) {
        this.RequestOpenCheck(partyId, data.Payload.PaidAmount, data.Payload.TaxAmount);
      }
      else {
        this.RequestOpenCheck(partyId);
      }
    });
  }

  GetOpenCheckURL(price) {
    const IsHttpsEnabled = this.cs.settings.value.General.IgIntegrationDTO.IsHttpsEnabled;
    let middlewareIpAddress = this.cs.settings.value.General.IgIntegrationDTO.MiddlewareIpAddress;
    const openCheckURL = `${IsHttpsEnabled ? 'https' : 'http'}://${middlewareIpAddress}/api/check`;
    const openCheckWithItemURL = `${IsHttpsEnabled ? 'https' : 'http'}://${middlewareIpAddress}/api/checkprocess/OpenCheckWithItem`;
    const isOpenCheckWithItems = this.cs.propertySettings.value[Utilities.RestaurantId()].settings.PropertySetting[0]?.OpenWithCheckItem;
    return isOpenCheckWithItems && price ? openCheckWithItemURL : openCheckURL;
  }



  HandleResponse(errorMessage: string) {
    const popUpMessage = [{
      confirmationMessage: errorMessage,
      dialogTitle: this.ts.instant('accordionmessagetext'),
      showAlert: true
    }];
    let okbutton = this.ts.instant('ok');
    const componentDetails: ComponentDetails = Utilities.setComponentDetails(ConfirmationPopupComponent, 'small', 'action',
      popUpMessage, popUpMessage[0].dialogTitle);
    this.openCustomPopup(componentDetails, ComponentTypes.login, '450px', 'auto', true, '', okbutton,
      '', true);
    this.cs.IGServerCallInOpenCheck = false;
    this.cs.IGServerCallInMoveCheck = false;
  }
  public openCustomPopup(componentDetails: ComponentDetails, fromComponent: ComponentTypes, width?: string, height?: string,
    back?: boolean, title?: string, update?: string, cancel?: string, showAction?: boolean, maxWidth?: string,
    panelClass?: string) {
    const dialogRef = this.dialog.open(CustomPopupComponent, {
      disableClose: true,
      width,
      height,
      maxWidth,
      panelClass,
      data: {
        title,
        update,
        cancel,
        componentDetails,
        from: fromComponent,
        back,
        standalone: true,
        showAction,

      }
    });
    return dialogRef;
  }
  public openAppPopup(componentDetails: ComponentDetails, fromComponent: ComponentTypes, width?: string, height?: string,
    back?: boolean, title?: string, update?: string, cancel?: string, showAction?: boolean, maxWidth?: string,
    panelClass?: string) {
    const dialogRef = this.dialog.open(AppPopupComponent, {
      disableClose: true,
      width,
      height,
      maxWidth,
      panelClass,
      data: {
        title,
        update,
        cancel,
        componentDetails,
        from: fromComponent,
        back,
        standalone: true,
        showAction,

      }
    });
    return dialogRef;
  }
  MoveCheck() {
    const isIGIntegrated = this.cs.settings.value.General.IgIntegrationDTO.IsEnabled;
    let middlewareIpAddress = this.cs.settings.value.General.IgIntegrationDTO.MiddlewareIpAddress;
    const IsHttpsEnabled = this.cs.settings.value.General.IgIntegrationDTO.IsHttpsEnabled;
    if (middlewareIpAddress && isIGIntegrated) {
      const requestedPayload = {} as MoveOperationDetailsDTO;
      requestedPayload.PartyId = this.LastPartyRequest.Id;
      requestedPayload.RestaurantId = Utilities.RestaurantId();
      const serverVal = Utilities.getIGServerForStandaloneTables(this.LastPartyRequest.TableIds, this.cs.layout.value.FloorPlans, this.cs.settings.value);
      requestedPayload.ServerIds = serverVal && serverVal.length > 0 ? serverVal : this.LastPartyServerIds;
      requestedPayload.TableNames = Utilities.getTableNamesFromStandaloneTables(this.LastPartyRequest.TableIds, this.cs.layout.value.FloorPlans);
      let MiddlewareserviceURl = `${IsHttpsEnabled ? 'https' : 'http'}://${middlewareIpAddress}/api/checkMove`;
      this.cs.IGServerCallInMoveCheck = true;
      return this.http.post(MiddlewareserviceURl, requestedPayload).subscribe(data => {
        const response = data as IGResponseDTO;
        if (response.ErrorMessage) {
          this.HandleResponse(this.ts.instant(Labels[Labels.MoveCheckErrorMsg]));
        }
        this.cs.IGServerCallInMoveCheck = false;
      })
    }
  }
  getContact(contactId) {
    const contactOptions: GetContactOptions = GetContactOptions.FullWithStatsAndPredefinedNotesIncludeCreditCards;

    return this.httpService.get(
      `${urlConfig.getguestInfoUrl}?restaurantId=${Utilities.RestaurantId()}&contactId=${contactId}&options=${contactOptions}`);
  }

  updateWaitlist(request: UpdatedWalkInDTO, dialogRef: MatDialogRef<any>) {
    this.subscriptions.add(this.httpService.post(`${urlConfig.updateWalkInURL}?restaurantId=${Utilities.RestaurantId()}`, request).subscribe((response) => {
      if (response) {
        this.openConfirmationDialog(response, this.ts.instant(Labels[Labels.waitlistupdatedconfirmationtext]), null, null,
          ComponentTypes.PartyCreation);
        this.popupSubscription = this.closeParent$.subscribe((data) => {
          if (data) {
            if (dialogRef) {
              dialogRef.close();
              if (this.popupSubscription) { this.popupSubscription.unsubscribe(); }
              if (this.partyCreationSubscription) { this.partyCreationSubscription.unsubscribe(); }
              if (this.cancelSubscription) { this.cancelSubscription.unsubscribe(); }
            }
          }
        });
        this.partyCreationSubscription = this.popupService.confirmedAction$.subscribe(val => {
          if (val === ComponentTypes.PartyCreation) {
            if (this.closeParent$) {
              this.closeParent$.next(true);
            }
          }
        });
      }
    }));
  }

  showErrorPopUp(errorMsg, width, height, title?) {
    const popUpMessage = [{
      confirmationMessage: errorMsg,
      dialogTitle: this.ts.instant('alert'),
      showAlert: true
    }];
    const componentDetails: ComponentDetails = {
      componentName: ConfirmationPopupComponent,
      popupType: 'action',

      popUpDetails: {
        isStepper: false,
        eventName: 'notifyParent'
      },
      popupInput: popUpMessage,
      popupTitle: title || this.ts.instant('alert')
    };

    const dialogRef = this.dialog.open(CustomPopupComponent, {
      disableClose: true,
      width: width,
      height: height,
      data: {
        title: this.ts.instant('alert'),
        showAction: true,
        update: 'ok',
        componentDetails,
        from: ComponentTypes.AlertMessage,
      }
    });
  }

  showEmailPopup(data, response, notificationType, propertyId = null) {
    if (data.Payload.length > 1) {
      data.Payload.forEach(party => {
        if (party.EmailAddress && (this.cs.propertySettings.value[party.PropertyId].settings.General.HostConfirmationEmailSendBehavior == PartyEmailSendBehavior.Prompt)) {
          let cancelledIds = party.CancelledIds ? party.CancelledIds : null;
          let bookedSessionId = party.BookedSessionId ? party.BookedSessionId : null;
          let classOrSessionBooking = party.ClassOrSessionBooking ? party.ClassOrSessionBooking : false;
          this.SendConfirmaionEmail(party.PartyId, party.EmailAddress, notificationType, cancelledIds, bookedSessionId, classOrSessionBooking, null).subscribe(data => {
          });
        }
      })
    } else if (propertyId && response.EmailAddress && (this.cs.propertySettings.value[propertyId].settings.General.HostConfirmationEmailSendBehavior == PartyEmailSendBehavior.Prompt)) {
      if (data.Payload.length) {
        data.Payload = data.Payload.flat();
        data.Payload = data.Payload[0];
      }
      // this.openConfirmationDialog(data, null, null, notificationType);
      this.showEmailNotification(data, notificationType);
    } else if (response.EmailAddress && notificationType == ReservationEmailNotificationType.Cancelled) {
      // this.openConfirmationDialog(data, null, null, notificationType);
      this.showEmailNotification(data, notificationType);
    }
    this.bookingCancellationPayload = null;
  }

  showExistingRetailReservationPopup(messages) {
    let msg = this.ts.instant('existingBookingInfoMessage');
    let validationMsgs = messages.map(messageData => messageData?.Message || messageData)
    this.showWarningInfoPopUp(msg, ComponentTypes.ExistngReservationWarning, '450px', '750px', validationMsgs)
}

  openConfirmationDialog(response, message, slotTime?, forConfirmationEmail: ReservationEmailNotificationType = null, fromComponent: ComponentTypes = null, confirmedSessionId: any = null, sessionObject = null, partyId = null, callback?) {
    if (this.cancelSubscription) {
      this.cancelSubscription.unsubscribe();
    }
    this.triggerEmail = false;
    const confirmationMessages = Utilities.getConfirmationMessage(response, message, forConfirmationEmail, confirmedSessionId);
    const componentDetails: ComponentDetails = {
      componentName: ConfirmationPopupComponent,
      popupType: forConfirmationEmail != null ? '' : 'action',
      dimensionType: 'small',
      popUpDetails: {
        isStepper: false,
        eventName: 'notifyParent'
      },
      popupInput: confirmationMessages,
      popupTitle: confirmationMessages[0].dialogTitle,
      notDisableOkButton: forConfirmationEmail != null ? false : response.notDisableOkButton
    };
    const dialogRef = this.dialog.open(CustomPopupComponent, {
      disableClose: true,
      height: popupDialogDimension.actionDialogHeight,
      width: popupDialogDimension.actionDialogWidth,
      data: {
        title: Utilities.getConfirmationDialogTitle(forConfirmationEmail),
        update: forConfirmationEmail != null ? this.ts.instant('close') : this.ts.instant('ok'),
        cancel: forConfirmationEmail != null ? this.ts.instant('sendConfirmationEmail') : '',
        componentDetails,
        from: (fromComponent) ? fromComponent : ComponentTypes.guestBookService,
        back: false,
        standalone: true,
        showAction: true,
        booingData: sessionObject
      },
    });
    //   this.ShowTicket(response, message, slotTime, forConfirmationEmail, fromComponent, confirmedSessionId);
    this.reservationConfirmationData = response.Payload;
    let cancelSubscription = this.popupService.cancelledAction$.subscribe(val => {
      if (val && val.from == ComponentTypes.PartyCreation) {
        if (this.closeParent$) {
          this.closeParent$.next(true);
        }
      }
      else if ((val && val.popupInput != null && val.popupInput.sendConfirmationEmail && val.popupInput.email != null && val.value == 1) && !this.triggerEmail) {
        this.triggerEmail = true;
        dialogRef?.close();
        this.SendConfirmaionEmail(val.popupInput.partyId, val.popupInput.email, val.popupInput.reservationEmailNotificationType, val.popupInput.cancelledIds, val.popupInput.bookedSessionId, val.popupInput.classOrSessionBooking, val.popupInput.confirmedBookedSessionId).subscribe(data => {
          if (data) {
            this.reservationDialogRef ? this.reservationDialogRef.close() : '';
            this.reservationDialogRef ? this.reservationDialogRef = null : '';
          }
        });
      }
    });
    dialogRef.afterClosed().subscribe((val) => {
      this.selectedGuest = null;
      if (cancelSubscription) {
        cancelSubscription.unsubscribe();
      }

      if(val == ComponentTypes.RejectBooking && callback) {
        callback(partyId);
      }
    });
  }

  openAppConfirmationDialog(response, title: string, confirmationMessages, popupType: 'action' | '' | null, saveBtnLabel: string, cancelBtnLabel: string): MatDialogRef<AppPopupComponent, any> {
    if (this.cancelSubscription) {
      this.cancelSubscription.unsubscribe();
    }
    this.triggerEmail = false;
    const componentDetails: ComponentDetails = {
      componentName: ConfirmationPopupComponent,
      popupType,
      dimensionType: 'small',
      popUpDetails: {
        isStepper: false,
        eventName: 'notifyParent'
      },
      popupInput: confirmationMessages,
      popupTitle: confirmationMessages[0].dialogTitle
    };
    const dialogRef = this.dialog.open(AppPopupComponent, {
      disableClose: true,
      height: popupDialogDimension.actionDialogHeight,
      width: popupDialogDimension.actionDialogWidth,
      data: {
        title,
        update: saveBtnLabel,
        cancel: cancelBtnLabel,
        componentDetails,
        from: null,
        back: false,
        standalone: true,
        showAction: true
      },
    });
    this.reservationConfirmationData = response.Payload;
    return dialogRef;
  }
  selectMemberRatePlane(request: MemberRatePlaneDTO){
    
    if(this.selectedGuest && this.selectedGuest?.memberShipId){
      const contact  = this.selectedGuest;
      if(!contact.MemberActive){
        return contact.MemberRateTypeMembershipExpiry;
      }else {
        const memberShipId = contact?.TrackMembershipNumber | this.selectedGuest?.memberShipId;
        const memberRound = this.getMemeberRound(memberShipId,
          contact.GolfPoints,request.ActivityId,request.Location,request.Slots,request.sessionGroupId); 
        if(memberRound && memberRound.ConsumedMinute <= memberRound.EligibleRounds){
          return contact.RateType;
        }else{
          return contact.MemberRateTypeAfterRoundsUsed;
        }
      }
    }
    return "";
   }
  
  calculateTimeDifferenceToMinutes(datetimeStr1, datetimeStr2) {
    const momentDate1 = moment(datetimeStr1);
    const momentDate2 = moment(datetimeStr2);
  
    return momentDate2.diff(momentDate1, 'minutes');
  }

  getTimeDifference(startTime, endTime) {
    const start = new Date(`1970-01-01T${startTime}`);
    const end = new Date(`1970-01-01T${endTime}`);
    const timeDifference = end.getTime() - start.getTime();
  
    // Calculate hours, minutes, and seconds from the time difference
    const hours = Math.floor(timeDifference / (1000 * 60 * 60));
    const minutes = Math.floor((timeDifference % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((timeDifference % (1000 * 60)) / 1000);
  
    return { hours, minutes, seconds };
  }


  calculateTotalMinute(specialMealId?,groupId?,slots?,duration?) : number{
    if(specialMealId){
      if(groupId){
          let specialMeal = this.cs.settings.value.SpecialMeals.find(f=>f.Id ==specialMealId );
          return this.getClassBookingDuration(groupId,specialMeal);
      }else if(slots?.length > 0){
        let min = 0;
        slots.forEach(sessionGroup => {
          if(sessionGroup.sessions){
            sessionGroup.sessions.forEach(s=>{
            min += this.calculateTimeDifferenceToMinutes(s.Start,s.End);
          });
          }else{
             min += sessionGroup.Duration;
          }
        });
        return min;
      }else if(slots){
        return _.sum(slots?.map(x => x?.DurationInMinutes || x?.Duration || 0));
      }
    }else{
      return slots ? _.sum(slots?.map(x => x?.DurationInMinutes || x?.Duration || 0)) : duration;
    }
    return 0;
  }

  getClassBookingDuration(sessionGroupId, activity) {
    let durationInMins = 0;
    if (sessionGroupId && activity ) {
        const activitySessions = activity.ActivitySessions.filter(session => session.SessionGroupId === sessionGroupId);
        for (let dt = new Date(activity.StartDate); dt <= new Date(activity.EndDate); dt.setDate(dt.getDate() + 1)) {
            for (const session of activitySessions) {
                if (dt.getDay() === session.Dayofweek) {
                  let timeDifference =  this.getTimeDifference(session.StartTime,session.EndTime)
                    durationInMins += timeDifference.minutes + timeDifference.hours * 60
                }
            }
        }
    }
    return Math.floor(durationInMins); 
  }


  getMemeberRoundRedeemObjectByFinancialData(booking,financialData){
    // let min = 0;
    // let memeberRound = null;
    // let groupId = booking.SessionGroupId || booking?.Sessions?.SessionGroupId;
    // if(booking?.SpecialMealId && groupId){
    //   memeberRound =  this.getMemeberRound(booking.Contact['TrackMembershipNumber'],
    //                                     booking.Contact?.GolfPoints,
    //                                     booking?.SpecialMealId ,
    //                                     null,null,groupId,null);
    // }else{
    //     let financialInfo = financialData?.RatePlanTotal?.RatePlanCalculations || financialData?.RatePlanCalculations;
    //     if(financialData){
    //       financialInfo.forEach(s=>{
    //         min += this.calculateTimeDifferenceToMinutes(s.TimeRange.Start,s.TimeRange.End);
    //       });
    //     }
      
    //     memeberRound =  this.getMemeberRound(booking.Contact['TrackMembershipNumber'],
    //                                     booking.Contact?.GolfPoints,
    //                                     booking?.SpecialMealId ,
    //                                     booking?.TableIds[0],null,null,min);
    // }
    // if(memeberRound){
    //   this.SetRateCode(memeberRound,booking);
    //   memeberRound.SessionStartTime = booking.StartDate;
    // }
    // return memeberRound;
    return 0;
  }

  getMemeberRound(trackMembershipNumber,golfPoints,specialMealId,tableIds?,slots?,groupId?,consumedMinute?){
    if(trackMembershipNumber){
      let memberRound : MemberRound = { 
        CourseCode : '',
        CourseName : '',
        EligibleRounds : 0,
        PlayerLinkId : trackMembershipNumber,
        ConsumedMinute :  consumedMinute || this.calculateTotalMinute(specialMealId,groupId,slots), 
        playerCategoryId : 3,
        SessionStartTime : '',
        RateTypeCode : ''
      };
      const round = golfPoints || this.selectedGuest?.GolfPoints;
      const cources = this.getmemeberPoint(round, specialMealId ,tableIds)
      if(cources && memberRound?.ConsumedMinute && cources.EligibleRounds >= memberRound?.ConsumedMinute){
        memberRound.CourseCode =  cources.BucketCode;
        memberRound.CourseName =  cources.BucketName;
        memberRound.EligibleRounds =  cources.EligibleRounds;
        return memberRound;
      }
    }
    return null;
  }
 
  SetRateCode(memeberRound,booking){

    let memeberInformation = booking?.Contact?.MemeberInformation || booking?.Contact;

    if(memeberRound.EligibleRounds >= 0){
      memeberRound.RateTypeCode = memeberInformation?.RateType || memeberInformation?.ProfileInfoGetByCardId?.ProfileValue?.RateType
    }else{
      memeberRound.RateTypeCode = memeberInformation?.MemberRateTypeAfterRoundsUsed || memeberInformation?.ProfileInfoGetByCardId?.ProfileValue?.RateTypeAfterRoundsUsed
    }
  }

  getmemeberPoint(points:any[],specialMealId?,standaloneTableId?){
    let reserveCode = null;
      if(specialMealId != null){
        const specialMeal =  this.cs.settings.value.SpecialMeals.find(f=>f.Id == specialMealId);
        if(specialMeal?.CategoryId){
          const category = this.cs.settings.value.Categories.find(f=>f.Id == specialMeal.CategoryId);
          if(category){
            const subCategories = category.SubCategories.find(f=>f.Id == specialMeal.SubcategoryId);
            if(subCategories){
              reserveCode = subCategories.Text;
            }
          }
        }

      }else if(standaloneTableId != null){
        const standaloneTable = this.cs.layout.value.FloorPlans[0].StandaloneTables.find(f=>f.Id == standaloneTableId);
        if(standaloneTable?.SeatingTypeId){
          const seatingType = this.cs.settings.value.SeatingTypes.find(f=>f.Id == standaloneTable.SeatingTypeId);
          if(seatingType){
            reserveCode = seatingType.Description;
          }
        }
       
      }
      
      if(reserveCode && reserveCode != ''){
          return points?.find(f=>f.BucketName.toLowerCase() == reserveCode.toLowerCase());
      }
      return null;
  }

  async setSelectedGuest(party) : Promise<EngageMemberDetailDTO> {
    if(party.Contact?.TrackMembershipNumber){

      let engageIntegrationData = this._settings.General.EngageIntegrationDTO;
      let obj: EngageMemberByCardIdRequestDTO = new EngageMemberByCardIdRequestDTO(party.Contact.TrackMembershipNumber, engageIntegrationData?.TenantId, engageIntegrationData?.SiteId);
      this.api.getEngageMemberByCardId(obj).subscribe((memberDetailResponse: EngageMemberDetailDTO) => {
        party.Contact['GolfPoints'] = memberDetailResponse.GolfPoints;
        party.Contact['MemeberInformation'] = memberDetailResponse;
        return memberDetailResponse;    
      })
    }
    return null;
  }

 async openCheckInWithDuesPopUp(party, financialData, componentType, applyAdditionalCharge) {
    if (this.cancelSubscription) {
      this.cancelSubscription.unsubscribe();
    }
    let financialEffectSubscription;
    let input = {
      financialData: financialData,
      party: party,
      fromCheckInOrCheckout:true
    }
    this.popupService.restrictCloseDialog = false;
    //await this.setSelectedGuest(party);
    const componentDetails: ComponentDetails = {
      componentName: RatePlanComponent,
      dimensionType: 'large',
      popupType: 'active',
      popUpDetails: {
        isStepper: false,
        eventName: 'notifyParent'
      },
      popupInput: input,
      popupTitle: this.ts.instant('rateSummary')

    };
    const dialogRef = this.dialog.open(CustomPopupComponent, {
      disableClose: true,
      width: '650px',
      height: '700px',
      data: {
        title: this.ts.instant('rateSummary'),
        update: this.ts.instant('BuyNow'),
        cancel: this.ts.instant('cancel'),
        componentDetails,
        from: componentType,
        back: false,
        standalone: true,
        showAction: true,
        bookingData: input,
        party: party,
      },
    });
    let cancelSubscription = this.popupService.cancelledAction$.subscribe(val => {
      if (val && val.from == ComponentTypes.CheckInRatePlanSummary || val.from == ComponentTypes.CheckOutRatePlanSummary) {
        if (cancelSubscription) {
          cancelSubscription.unsubscribe();
        }
      }
    });

    let confirmedSubscription = this.popupService.confirmedAction$.subscribe(async val => {
  
      if (!this.ratePlanSummaryState) {
        this.applyNegotiationAlert();
        return;
      }    
    if (val == ComponentTypes.CheckInRatePlanSummary || val == ComponentTypes.CheckOutRatePlanSummary) {

        if (confirmedSubscription) {
          confirmedSubscription.unsubscribe();
        }
   
      //  party.Contact['MemeberRoundRedeem'] = this.getMemeberRoundRedeemObjectByFinancialData(party,financialData);

     //   this.getNegotiatedValueForBooking(party)
        party.RatePlan = this.ratePlanObject.RatePlan;
        this.FinancialEffectId = financialData.FinancialEffectId;
        party.FinancialEffectId = this.FinancialEffectId;
         
        if(party.RatePlan.Negotiated !== 0) {
          this.getFinancialDetails(party.Id, val == ComponentTypes.CheckInRatePlanSummary ? FinancialEffectAction.CheckIn : FinancialEffectAction.CheckOut, this.ratePlanObject.RatePlan,this.ratePlanObject.ShopItems).subscribe(updatedFinancialData =>{
            this.checInOrCheckOutOperation(val, party, updatedFinancialData.Payload);
          })
         
        }
        else{
          this.checInOrCheckOutOperation(val, party, financialData);
        }

        // if(this.ratePlanForBooking.OverAllNegotiation !== 0) {   
        //   financialEffectSubscription = this.getFinancialEffectForCheckInWithDues(party, val == ComponentTypes.CheckInRatePlanSummary ? FinancialEffectAction.CheckIn : FinancialEffectAction.CheckOut, applyAdditionalCharge).subscribe(data => {
        //     if(financialEffectSubscription) {
        //       financialEffectSubscription.unsubscribe();
        //     }
        //    this.ratePlanForBooking = data?.Payload?.RatePlanTotal;
        //   //  this.getNegotiatedValueForBooking(party)
        //   this.checkInAndCheckOutRatePlan = this.getCheckInCheckOutRatePlan();
        //   this.selectedBooking = party;
        //   if (val == ComponentTypes.CheckInRatePlanSummary) {
        //     this.RetailCheckInOperation(party, data?.Payload)
        //   } else {
        //     this.RetailCheckoutOperation(party, data?.Payload)
        //   }
        //   });
        // }
        // else {
        //   if(this.ratePlanForBooking && financialData){
        //     financialData.RatePlanTotal = this.ratePlanForBooking;
        //   }else{
        //     this.ratePlanForBooking = financialData?.RatePlanTotal;
        //   }
        //     //  this.getNegotiatedValueForBooking(party)
        //   this.checkInAndCheckOutRatePlan = this.getCheckInCheckOutRatePlan();
        //   this.selectedBooking = party;
        //   if (val == ComponentTypes.CheckInRatePlanSummary) {
        //     this.RetailCheckInOperation(party, financialData)
        //   } else {
        //     this.RetailCheckoutOperation(party, financialData)
        //   }
        // }

      }
      if(componentType == ComponentTypes.ChargeGuest){
        if (confirmedSubscription) {
          confirmedSubscription.unsubscribe();
        }
      //  this.getNegotiatedValueForBooking(party)
      party.RatePlan = this.ratePlanObject.RatePlan;
      this.FinancialEffectId = financialData.FinancialEffectId;
      party.FinancialEffectId = this.FinancialEffectId; 
      if(party.RatePlan.Negotiated !== 0) {
        this.getFinancialDetails(party.Id,  FinancialEffectAction.Update , this.ratePlanObject.RatePlan,this.ratePlanObject.ShopItems).subscribe(updatedFinancialData =>{
          this.ratePlanObject = updatedFinancialData.Payload;
          this.chargeRatePlan = updatedFinancialData?.Payload?.RatePlanTotal;
          this.selectedBooking = party;
          this.ChargeGuestOperation(party, updatedFinancialData.Payload);
        })
       
      }
      else{
      this.ChargeGuestOperation(party, financialData);
      }
        // if(this.ratePlanForBooking.OverAllNegotiation !== 0) {   
        //   financialEffectSubscription = this.getFinancialEffectForCheckInWithDues(party, FinancialEffectAction.Update, false).subscribe(data => {
        //     if(financialEffectSubscription) {
        //       financialEffectSubscription.unsubscribe();
        //     }
        //   this.ratePlanForBooking = data?.Payload?.RatePlanTotal;
        //    //  this.getNegotiatedValueForBooking(party)
        //   this.chargeRatePlan = data?.Payload?.RatePlanTotal;
        //   this.selectedBooking = party;
        //     this.ChargeGuestOperation(party,data?.Payload);
        //   });
        // }
        // else {
        //   // if(this.ratePlanForBooking && financialData){
        //   //   financialData.RatePlanTotal = this.ratePlanForBooking;
        //   // }else{
        //   //   this.ratePlanForBooking = financialData?.RatePlanTotal;
        //   // }
        //     //  this.getNegotiatedValueForBooking(party)
        //   this.chargeRatePlan = this.ratePlanObject.RatePlan;
        //   this.selectedBooking = party;
        //   this.ChargeGuestOperation(party,financialData)
        // }
       
      }
    })
    dialogRef.afterClosed().subscribe(() => {
      if (cancelSubscription) {
        cancelSubscription.unsubscribe();
      }
      if(confirmedSubscription) {
        confirmedSubscription.unsubscribe();
      }

    });
  }
  checInOrCheckOutOperation(val,party,financialData){
    if (val == ComponentTypes.CheckInRatePlanSummary) {
      this.RetailCheckInOperation(party, financialData)
    } else {
      this.RetailCheckoutOperation(party, financialData)
    }
  }
  getCheckInCheckOutRatePlan() {
    let RatePlanTotalNegotiation = {
      Negotiation: null,
      MinRateNegotiatedAdjustment:  this.ratePlanForBooking?.MinRateAdjustment?.Amount - this.ratePlanForBooking?.MinRateAdjustment?.MinRateAdjustmentAmount,
      applyNegotiationOnOtherCharges: [
        {
          bookingChargeType: BookingChargeType.ServiceCharge,
          TotalAmount: this.ratePlanForBooking?.TotalServiceCharge || 0,
          NegotiatedAmount: null,
          UseAfterNegotiation: this.negotiateServiceCharge
        }
      ],
      RatePlanCalculationInfoNegotiation: this.ratePlanForBooking.RatePlanCalculations
    }
    return RatePlanTotalNegotiation;
  }

  applyNegotiationAlert() {
    const popUpMessage = [{
      confirmationMessage: this.ts.instant('applyNegotiatedValues'),
      dialogTitle: this.ts.instant('alert'),
      showAlert: true
    }];
    const componentInfo = Utilities.setComponentDetails(ConfirmationPopupComponent, 'small', 'static', popUpMessage);
    let convertDialog = this.openCustomPopup(componentInfo, ComponentTypes.negAlert,
      '450px', '350px', false, popUpMessage[0].dialogTitle, this.ts.instant('ok'), '', true);
    this.popupService.confirmedAction$.subscribe(data =>{
      if(ComponentTypes.negAlert == data)
        this.popupService.restrictCloseDialog = false;
    })
     
  }

 
  getNegotiatedValueForBooking(selectedData) {
    this.ratePlanForBooking?.RatePlanCalculations?.forEach(data => {
      if (data.RatePlanVariants) {
        if ((data.totalBaseRate * selectedData.BookingSize) != data.TotalAmount && (data.RatePlanVariants.GuestTypeVariant == undefined && data.RatePlanVariants.BookingTypeVariant == undefined)) {
          data.RatePlanVariants.NegotiatedAmount = +(data.sessionCharge * selectedData.BookingSize) - ((data.RatePlanVariants.OriginalBaseRate || 0) * selectedData.BookingSize);
          data.RatePlanVariants.TotalVariants = data.TotalBaseRate;
        }
        if (data.RatePlanVariants.GuestTypeVariant && data.RatePlanVariants.GuestTypeVariant.length) {
          data.RatePlanVariants.GuestTypeVariant = data.RatePlanVariants.GuestTypeVariant.filter(guest => guest.covers > 0)
          data.RatePlanVariants.GuestTypeVariant.forEach(guest => {
            guest.NegotiatedAmount = (guest.coverTypeValue - guest.initialValue) * guest.covers;
            guest.Variant = guest.initialValue;
          });
        }
        if (data.RatePlanVariants.BookingTypeVariant && data.RatePlanVariants.BookingTypeVariant.length) {
          data.RatePlanVariants.BookingTypeVariant = data.RatePlanVariants.BookingTypeVariant.filter(type => type.bookingTypes > 0)
          data.RatePlanVariants.BookingTypeVariant.forEach(type => {
            type.NegotiatedAmount = (type.bookingTypeValue - type.initialValue) * type.bookingTypes;
            type.Variant = type.initialValue;
          })
        }
        if (data.RatePlanVariants.OriginalLocationVariant) {
          data.RatePlanVariants.LocationNegotiatedAmount = (data.RatePlanVariants.locationAmount) - ( data.RatePlanVariants.OriginalLocationVariant *(this.ratePlanForBooking?.RatePlanCalculatorType == RatePlanCalculator.Duration? 1 : selectedData.BookingSize));
        }
        if (data.RatePlanVariants.OriginalInstructorVariant) {
          data.RatePlanVariants.InstructorNegotiatedAmount = (data.RatePlanVariants.instructorAmount) - (data.RatePlanVariants.OriginalInstructorVariant * (this.ratePlanForBooking?.RatePlanCalculatorType == RatePlanCalculator.Duration? 1 : selectedData.BookingSize));
        }
        if (data.MemberShipVariant) {
          data.NegotiatedMemberShipVariant = (data.membershipAmount - data.MemberShipVariant) || 0;
        }
      }
      if (data.AddonAttemptDetails && data.AddonAttemptDetails.length) {
        data.AddonAttemptDetails.forEach(addOn => {
          addOn.NegotiatedAmount = (addOn.perItemValue * addOn.Quantity) - (addOn.TotalAmount);
        })
      }


    });
  }
  ShowTicket(forConfirmationEmail?) {
    if (this.ticketSubscription) {
      this.ticketSubscription.unsubscribe();
    }
    if (this.cancelTicketSubscription) {
      this.cancelTicketSubscription.unsubscribe();
    }
    const componentDetails = {
      componentName: TicketComponent,
      dimensionType: 'small',
      popupType: 'active',
      popUpDetails: {
        isStepper: false,
        eventName: 'notifyParent'
      }
    };
    const dialogRef = this.dialog.open(CustomPopupComponent, {
      disableClose: true,
      minWidth: '65%',
      width: '75%',
      minHeight: '60%',
      height: '75%',
      data: {
        title: Utilities.getConfirmationDialogTitle(forConfirmationEmail),
        update: this.ts.instant('printText'),
        cancel: this.ts.instant('cancel'),
        componentDetails,
        from: ComponentTypes.TicketComponent,
        printSectionId: "ticket-print-section",
        back: false,
        standalone: true,
        type: 'action-primary-print',
        TicketType: this._settings?.General?.TicketType
        //showFooter: false
      }
    });
    //  this.popupService.restrictCloseDialog = true;

    this.confSubscription = this.popupService.confirmedAction$.subscribe((val) => {
      if (val == ComponentTypes.TicketComponent) {
        let TicketType = this._settings.General.TicketType;
        let TicketLayoucode = this._settings.General.LayoutCode;
        if (TicketType) {
          let printer;
          if (this.selecetedPrinter) {
            printer = this._settings.PrintersInfo.filter(x => x.Id == this.selecetedPrinter)[0];
          } else {
            printer = this._settings.PrintersInfo.filter(x => x.IsDefault)[0];
          }
          if (printer) {
            this.printresult.forEach(x => {
              let BookingDate = (moment(x.StartDate)).format(this.cs.settings.value.General.DateFormat) + " - " + (moment(x.EndDate)).format(this.cs.settings.value.General.DateFormat);
              if (new Date(x.StartDate).setHours(0, 0, 0, 0) == new Date(x.EndDate).setHours(0, 0, 0, 0)) {
                BookingDate = (moment(x.StartDate)).format(this.cs.settings.value.General.DateFormat);
              }
              let packname = this.cs.availablePackages.find(pack => pack.Id == x.PackageId)?.Name;
              const layoutdata = {} as TicketModel;
              layoutdata.BookingInfo = [];
              layoutdata.PropertyInfo = [];
              const propertyModel = {} as PropertyInfo;
              propertyModel.Key = "PropertyName";
              propertyModel.Value = this._settings.General.RestaurantName;
              layoutdata.PropertyInfo.push(propertyModel);
              const BookingModel1 = {} as BookingInfo;
              BookingModel1.Key = "GuestName";
              BookingModel1.Value = x.Name;
              BookingModel1.Title = "Player Name";
              layoutdata.BookingInfo.push(BookingModel1);
              const BookingModel2 = {} as BookingInfo;
              BookingModel2.Key = "Date";
              BookingModel2.Value = BookingDate;
              BookingModel2.Title = "Date";
              layoutdata.BookingInfo.push(BookingModel2);
              const BookingModel3 = {} as BookingInfo;
              BookingModel3.Key = "ActivityName";
              BookingModel3.Value = x.ClassName;
              BookingModel3.Title = "Activity/Event Name";
              layoutdata.BookingInfo.push(BookingModel3);
              const BookingModel4 = {} as BookingInfo;
              BookingModel4.Key = "Time";
              BookingModel4.Value = this.timeformat(x.StartTime) + " - " + this.timeformat(x.EndTime);
              BookingModel4.Title = "Timing";
              layoutdata.BookingInfo.push(BookingModel4);
              const BookingModel5 = {} as BookingInfo;
              BookingModel5.Key = "ConfirmationCode";
              BookingModel5.Value = x.ConfirmationCode;
              BookingModel5.Title = "Confirmation Code";
              layoutdata.BookingInfo.push(BookingModel5);
              const BookingModel6 = {} as BookingInfo;
              BookingModel6.Key = "LocationName";
              BookingModel6.Value = x.TableName;
              BookingModel6.Title = "Location";
              layoutdata.BookingInfo.push(BookingModel6);
              const BookingModel7 = {} as BookingInfo;
              BookingModel7.Key = "PartySize";
              BookingModel7.Value = x.Size.toString();
              BookingModel7.Title = "Party Size";
              layoutdata.BookingInfo.push(BookingModel7);
              const BookingModel8 = {} as BookingInfo;
              BookingModel8.Key = "QRCodeData";
              BookingModel8.Value = x.barcodeInput;
              BookingModel8.Title = "";
              layoutdata.BookingInfo.push(BookingModel8);
              const BookingModel9 = {} as BookingInfo;
              BookingModel9.Key = "PacakageName";
              BookingModel9.Value = packname;
              BookingModel9.Title = "Pacakage";
              layoutdata.BookingInfo.push(BookingModel9);
              this.callPrintService(printer?.IPAddress, TicketLayoucode, TicketType, printer?.Port, layoutdata, packname);
            });
          }
        } else {
          // print preview to do
        }
      }
    });

    this.subscriptions.add(dialogRef.afterClosed().subscribe(() => {
      if (this.confSubscription) {
        this.confSubscription.unsubscribe();
      }
      if (this.ticketSubscription) {
        this.ticketSubscription.unsubscribe();
      }
      if (this.cancelTicketSubscription) {
        this.cancelTicketSubscription.unsubscribe();
      }
      if (this.cancelSubscription) {
        this.cancelSubscription.unsubscribe();
      }
    }));

    this.cancelSubscription = this.popupService.cancelledAction$.subscribe((val) => {
      if (val.from == ComponentTypes.TicketComponent) {
        dialogRef.close();
      }
    });
  }

  timeformat(date) {
    let h = new Date(date).getHours();
    let m = new Date(date).getMinutes();
    let x = h >= 12 ? 'pm' : 'am';
    h = h % 12;
    h = h ? h : 12;
    let m1 = m < 10 ? '0' + m : m;
    var mytime = h + ':' + m1 + ' ' + x;
    return mytime;
  }
  callPrintService(IpAddress, layoutCode, TicketType, port, LayoutData, packName) {
    let jsonString = JSON.stringify(LayoutData);
    let postPrintService = this._settings.General.PrinterApi + "/Printer";
    let urls = `${postPrintService}?IpAddress=${IpAddress}&TagType=${TicketType}&NoofTags=1&LayoutCode=${layoutCode}&LayoutData=${jsonString}&PrinterPort=${port}&PackageName=${packName}`;
    this.subscriptions.add(this.http.post(urls, null).subscribe(data => {
    }));
  }


  getSlots(timerange: TimeRangeDTO , specialMealId?:number, partyIdToIgnore?: number): Observable<any> {
    const timeRangeDetails: newTimeRangeDTO = {
      Start: new Date(timerange.Start.getTime() - (timerange.Start.getTimezoneOffset() * 60000)).toJSON(),
      End: new Date(timerange.End.getTime() - (timerange.End.getTimezoneOffset() * 60000)).toJSON()
    };
    return this.httpService.post(
      `${urlConfig.getSlotsOperation}?restaurantId=${Utilities.RestaurantId()}&specialMealId=${specialMealId ? specialMealId : null}&partyIdToIgnore=${partyIdToIgnore ? partyIdToIgnore : null}&options=${GetSlotsOperationOptions.None}`,
      { Start: timeRangeDetails.Start.split('Z')[0], End: timeRangeDetails.End.split('Z')[0] });
  }

  getSlotsForTable(locations, instructors, activityId, partyId, date, lockIdsToIgnore?: number[]): Observable<any> {
    return this.httpService.post(`${urlConfig.GetSlotsForTablesURL}?restaurantId=${Utilities.RestaurantId()}&options=1`, {
      TableIds: locations,
      InstructorIds: instructors,
      ActivityId: activityId,
      PartyId: partyId,
      TimeRange: {
        Start: date + "T00:00:00.000",
        End: date + "T23:59:59.999"
      },
      lockIdsToIgnore
    });
  }

  getTimeDetails(headerDate: any, partySize?:number , selectedDate?:Date , specialMeal?:number, partyIdToIgnore?:number) {
    const timerange: TimeRangeDTO = {} as TimeRangeDTO;
    const date = selectedDate ? selectedDate : (!this.reservationFormGroup.value.selectedDate ? headerDate : this.reservationFormGroup.value.selectedDate);
    this.openHours = Utilities.getRestaurantOpenHoursForDay(this.cs.settings.value.OpenHours, date);
    this.openHoursRange = Utilities.GetWideRange(this.openHours);
    timerange.Start = startOfDay(date);
    timerange.End = endOfDay(date);
    this.subscriptions.add(this.getSlots(timerange , specialMeal, partyIdToIgnore).subscribe((result) => {
      this.slotsinTimeRange = result;
      this.setSlots(result , partySize ,  selectedDate);
      this.slotAvailabilitySubject$.next(result);
    }));
  }

  setStandbySlot(wishedTime: any) {
    if (this.StandbySlots.filter(x => x.DateTime == wishedTime).length == 0) {
      this.StandbySlots.push({ DateTime: wishedTime });
    }
  }

  getIcon(note: any) {
    return this.iconArray.filter(x => x.name == note.name)[0].icon_name + ' allergyIcon_spacing';
  }

  setSlots(data, partySize?:number , selectedDate?:Date) {
    let slotAvailable = false;
    this.slots_holder = [];
    this.reservedSlots = [];
    const LoadedShifts = [];
    this.SlotIds = 0;
    if (data && data.Payload && data.Payload.length > 0) {
      const reservationDate = selectedDate ? selectedDate : ( this.reservationFormGroup.value.selectedDate ? new Date(this.reservationFormGroup.value.selectedDate)
        : new Date(Utilities.getRestaurantDateTime(this.cs.settings.value.General.DaylightDelta)));
      if (this.reservationFormGroup.value.selectedSize == null || this.reservationFormGroup.value.selectedSize == "") {
        this.reservationFormGroup.value.selectedSize = 2;
      }
      this.selectedSize = partySize !== null && partySize !== undefined ? partySize : this.reservationFormGroup.value.selectedSize;
      Array.prototype.forEach.call(data.Payload, element => {
        if (element.Date.includes('Z')) {
          element.Date = element.Date.split('Z')[0];
        }
        const slotDate = format(new Date(element.Date), 'MM/DD/YYYY'); // new Date(element.Date);
        const today = format(new Date(reservationDate), 'MM/DD/YYYY'); // reservationDate;
        if (slotDate === today) {
          Array.prototype.forEach.call(element.Shifts, element1 => {
            LoadedShifts.push(element1.Shift);
            Array.prototype.forEach.call(element1.Slots, element2 => {
              if (element2.DateTime.includes('Z')) {
                element2.DateTime = element2.DateTime.split('Z')[0];
              }
              const selecteSize = partySize ? partySize : this.reservationFormGroup.value.selectedSize;
              element2.slotName = element1.Shift.Name;
              this.setAvailableSlots(element2);
              // if (element2.LimitedMaxPartySize > 0 && element2.LimitedMaxPartySize >= selecteSize) {

              // } else if (element2.LimitedMaxPartySize > 0) {
              //   element2.slotName = element1.Shift.Name;
              //   this.setAvailableSlots(element2);
              // }
            });

          });
        }
      });
      if(this.cs.settings.value.General.SlottingMode == SlottingMode.Manual){
        this.reservedSlots = _.cloneDeep(this.slots_holder.filter((slot) => slot.PartyId != null)).sort((a, b) => Utilities.parseDateString(a.DateTime).getTime() - Utilities.parseDateString(b.DateTime).getTime());
      }
      slotAvailable = this.genearateOverBookingSlots(LoadedShifts)
    }



    if (this.slots_holder !== undefined) {
      this.slots_holder.sort((a, b) => Utilities.parseDateString(a.DateTime).getTime() - Utilities.parseDateString(b.DateTime).getTime());
    }
    if (this.cs.settings.value.General.SlottingMode == SlottingMode.Auto) {
      this.slots_holder = this.slots_holder.filter((slot) => !slot.PartyId);
    }

    if (this.selectedSize) {
      this.isOverrideAvailable = _.every(this.slots_holder, ['IsDisabled', true]) ||
        ((this.cs.settings.value.General.SlottingMode == SlottingMode.Auto && this.slots_holder.filter(slot => this.selectedSize && this.selectedSize >= slot.LimitedMinPartySize &&
          this.selectedSize <= slot.LimitedMaxPartySize).length === 0) || this.cs.settings.value.General.SlottingMode == SlottingMode.Manual) ||
        slotAvailable || this.slots_holder.filter(slot => slot.LimitedMinPartySize < 0 && slot.LimitedMaxPartySize < 0).length > 0;
    }
    // TODO filter slots based on seatingArea
    // if (this.reservationFormGroup.value.selectedArea !== null && this.reservationFormGroup.value.selectedArea.Id > 0) {
    //   this.slots_holder = this.slots_holder.filter((slot) => slot.SeatingAreaId === this.reservationFormGroup.value.selectedArea.Id);
    // }
    if (this.cs.settings.value.General.SlottingMode == SlottingMode.Auto) {
      this.allAvailableSlots = this.slots_holder.filter((slot) => !slot.IsDisabled && !slot.PartyId);
    }
    else {
      this.allAvailableSlots = this.slots_holder.filter((slot) => !slot.IsDisabled);
    }
  
    this.partySlots$.next(true);
  }



  genearateOverBookingSlots(Shifts) {
    let isSlotAdded = false;
    const Slotval = this.cs.settings.value.General.TimeSlotUnitInMinutes;

    const Startval = new Date(this.openHoursRange[0].split('Z')[0]);
    const Endval = new Date(this.openHoursRange[1].split('Z')[0]);
    let Startloop = new Date(Startval);

    while (Startloop <= Endval) {
      let isOpenHourExist = false;
      this.openHours.forEach(element => {
        if (Utilities.datetimeBetween(element.EffectiveRange.Start, element.EffectiveRange.End, Startloop)) {
          isOpenHourExist = true;
        }
      });

      let isShiftExist = false;
      Shifts.forEach(element => {
        if (Utilities.datetimeBetween(element.EffectiveRange.Start, element.EffectiveRange.End, Startloop)) {
          isShiftExist = true;
        }
      });

      if (!isShiftExist || !isOpenHourExist) {
        const newMinites = new Date(Startloop).getMinutes() + Slotval;
        const newDate = Startloop.setMinutes(newMinites);
        Startloop = new Date(newDate);
        continue;
      }

      let isSlotExist;
      isSlotExist = this.slots_holder.filter((slot) => {
        if (new Date(slot.DateTime).getTime() == Startloop.getTime()) {
          return true;
        }
      });
      if (this.cs.settings.value.General.SlottingMode == SlottingMode.Auto) {
        if (!isSlotExist || isSlotExist.length > 1) {
          const repeatedSlots = new Date(isSlotExist[0].DateTime).getTime();
          let removeSlot: any = [];
          removeSlot = isSlotExist.filter((slot) => !slot.PartyId && !(this.selectedSize >= slot.LimitedMinPartySize && this.selectedSize <= slot.LimitedMaxPartySize));
          const isPartySlot = isSlotExist.filter((slot) => slot.PartyId);
          if (removeSlot && removeSlot.length > 0 && removeSlot.length != isSlotExist.length) {
            removeSlot.forEach(rslot => {
              const findIndex = this.slots_holder.findIndex((slot) => new Date(slot.DateTime).getTime() == repeatedSlots && rslot.LimitedMinPartySize == slot.LimitedMinPartySize && rslot.LimitedMaxPartySize == slot.LimitedMaxPartySize);
              if (findIndex > -1) {
                this.slots_holder.splice(findIndex, 1);
              }
            });

          } else {
            if (removeSlot.length == isSlotExist.length && isSlotExist.length > 1) {
              let updatelength = removeSlot.length;
              removeSlot.forEach(rslot => {
                const findIndex = this.slots_holder.findIndex((slot) => new Date(slot.DateTime).getTime() == repeatedSlots && rslot.LimitedMinPartySize == slot.LimitedMinPartySize && rslot.LimitedMaxPartySize == slot.LimitedMaxPartySize);
                if (findIndex > -1 && updatelength > 1) {
                  this.slots_holder.splice(findIndex, 1);
                }
                updatelength--;
              });
            }
          }

        }
      }
      else {
        if (isSlotExist && isSlotExist.length > 0) {
          const repeatedSlots = new Date(isSlotExist[0].DateTime).getTime();
          let removeSlot: any = [];
          removeSlot = isSlotExist.filter((slot) => slot.PartyId != null);
          const isPartySlot = isSlotExist.filter((slot) => slot.PartyId);
          if (removeSlot && removeSlot.length > 0 && removeSlot.length != isSlotExist.length) {
            removeSlot.forEach(rslot => {
              const findIndex = this.slots_holder.findIndex((slot) => slot.PartyId != null && new Date(slot.DateTime).getTime() == repeatedSlots && rslot.LimitedMinPartySize == slot.LimitedMinPartySize && rslot.LimitedMaxPartySize == slot.LimitedMaxPartySize);
              if (findIndex > -1) {
                this.slots_holder.splice(findIndex, 1);
              }
            });

          } else {
            if (removeSlot.length == isSlotExist.length) {
              removeSlot.forEach(rslot => {
                const findIndex = this.slots_holder.findIndex((slot) => slot.PartyId != null && new Date(slot.DateTime).getTime() == repeatedSlots && rslot.LimitedMinPartySize == slot.LimitedMinPartySize && rslot.LimitedMaxPartySize == slot.LimitedMaxPartySize);
                if (findIndex > -1) {
                  this.slots_holder.splice(findIndex, 1);
                }
              });
            }
          }
        }
      }
      /* if (isPartySlot.length > 1) {
            let partyIDs = isPartySlot[0].PartyId;
            this.slots_holder = this.slots_holder.filter((slot) => slot.PartyId != partyIDs && new Date(slot.DateTime).getTime() == repeatedSlots);
          } */
      const tempTimeSlot = Startloop;
      if ((this.cs.settings.value.General.SlottingMode == SlottingMode.Auto && (!isSlotExist || isSlotExist.length == 0)) || this.cs.settings.value.General.SlottingMode == SlottingMode.Manual && (!isSlotExist || isSlotExist.filter((slot) => slot.PartyId == null).length == 0)) {
        const slot: SlotDTO = this.generateSlot(Startloop);
        this.setAvailableSlots(slot);
        isSlotAdded = true
      }
      const newMinites = new Date(tempTimeSlot).getMinutes() + Slotval;
      const newDate = tempTimeSlot.setMinutes(newMinites);
      Startloop = new Date(newDate);
    }

    return isSlotAdded;
  }

  generateSlot(dateTime): SlotDTO {
    const slot: SlotDTO = <SlotDTO>{};
    slot.Id = 0;
    slot.IsWebReservable = true;
    slot.LimitedMinPartySize = 0;
    slot.LimitedMaxPartySize = 0;
    slot.ManualSlotType = 0;
    slot.OriginalMaxPartySize = 0;
    slot.OriginalMinPartySize = 0;
    slot.PartyId = null;
    slot.LockExpiresAt = null;
    slot.LockedAt = null;
    slot.SeatingAreaId = null;
    slot.Type = SlotType.Time;
    slot.UpdatedAt = null;
    const slotTime = Utilities.Date(dateTime);
    slot.DateTime = slotTime.format('YYYY-MM-DDTHH:mm:ss');
    return slot;
  }

  GetSlotsFallsBetweenRange(slots, startTime, endTime) {
    const starttimes = new Date(startTime).getTime();
    const endtimes = new Date(endTime).getTime();
    return slots.filter((slot) => {
      if (new Date(slot.DateTime).getTime() >= starttimes && new Date(slot.DateTime).getTime() <= endtimes) {
        return true;
      }
    });
  }

  setAvailableSlots(slot) {
    const maxPastTime = this.cs.settings.value.General.MaxPastTimeForReservationsInMinutes;
    const currentTime = Utilities.getRestaurantDateTime(this.cs.settings.value.General.DaylightDelta);
    let minutes = this.cs.settings.value.PropertySetting[0].ShowOngoingSlotsTill ? (this.cs.settings.value.PropertySetting[0].ShowOngoingSlotsTill * 1000) : 60000;
    const past1hr = new Date(currentTime.valueOf() - (maxPastTime * minutes));
    const slotTime = Utilities.parseDateString(slot.DateTime);
    slot.IsDisabled = slot.IsDisabled ? slot.IsDisabled : slotTime >= past1hr ? false : true;
    if (slot.LockedAt && !slot.IsDisabled)
      slot.IsDisabled = Utilities.formateDateString(slot.LockExpiresAt).getTime() > currentTime.getTime()
    this.SlotIds = this.SlotIds + 1;
    slot.Id = slot.Id == 0 ? this.SlotIds : slot.Id;
    if (this.cs.settings.value.General.SlottingMode == SlottingMode.Auto) {
      if ((slot.LimitedMinPartySize <= 0 && slot.LimitedMaxPartySize <= 0)) {
        slot.IsOverRide = true;
      } else if ((this.selectedSize >= slot.LimitedMinPartySize &&
        this.selectedSize <= slot.LimitedMaxPartySize)) {
        slot.IsOverRide = false;
      }
      else {
        slot.IsOverRide = true;
      }
    } else {
      slot.IsOverRide = false;
    }
    if (slot && slot.AllocationTypeId) {
      const AllocationType = this.cs.settings.value.AllocationType;
      let isAnyMember = AllocationType.find(type => type.Id === slot.AllocationTypeId).AllocationName == 'AnyMembers';
      slot.AllocationName = isAnyMember;
    }

    if (this.cs.settings.value.General.SlottingMode == SlottingMode.Auto) {
      if (!slot.PartyId) {
        this.slots_holder.push(slot);
      }else{
         this.reservedSlots.push(slot); 
      }
    }
    else {
      this.slots_holder.push(slot);
    }
  }

  seatParty(party, serverId? , ignoreEventBlock:boolean=false): any {
    let _request = _.cloneDeep(party);
    _request.AddOns = [];
    if(_request.BookingContactAddonItems) { 
    _request.BookingContactAddonItems.map(addonItem => {
      this.cs.settings.value.Addons.map(addonDetail => {
        if(addonItem.AddonId === addonDetail.AddonId) {
          _request.AddOns.push( {"Name": addonDetail.AddonName, "Price": addonItem.Amount,"AddonId": addonDetail.AddonId, "Quantity": addonItem.AddonCount, "Category": 0, "OverBooked": addonItem.OverBooked } );
        }
      })
    })
    delete _request.BookingContactAddonItems;
  }
    this.GetFinancialEffect(_request, true, false, serverId , null , null , ignoreEventBlock);
  }
  SetRatePlan(selectedTableIds: any[], seatingTime?: any, editPartyData?: any): any {
    let totalAmount = 0;
    if (selectedTableIds) {
      var DateTime = this.reservationFormGroup.value?.selectedTime?.DateTime ? Utilities.Date(new Date(this.reservationFormGroup.value.selectedTime.DateTime)).format('YYYY-MM-DDTHH:mm:ss')
        : editPartyData?.ReservedFor ? Utilities.Date(new Date(editPartyData.ReservedFor)).format('YYYY-MM-DDTHH:mm:ss')
          : editPartyData?.WishedTime ? Utilities.Date(new Date(editPartyData.WishedTime)).format('YYYY-MM-DDTHH:mm:ss')
            : Utilities.Date(new Date(seatingTime)).format('YYYY-MM-DDTHH:mm:ss');
      var selectedTime = _.cloneDeep(DateTime);
      var slots = {
        "LocalTime": selectedTime
      }
      var slot = [];
      slot.push(slots);
      var selectedDate = (this.reservationFormGroup.value.selectedDate != "") ? this.reservationFormGroup.value.selectedDate : new Date(seatingTime);

      var request = {
        Slots: slot,
        ActivityId: null,
        GuestTypes: null,
        TableIds: selectedTableIds,
        Size: 1,
        SessionSlot: null,
        TrackMembershipNumber: this.selectedGuest && this.selectedGuest.TrackMembershipNumber,
        MemberType: this.selectedGuest?.MembershipType?.AllocationName,
        IsMemberActive: this.selectedGuest?.MemberActive,
        BookingBehavior: null,
        SeatingTypeIds: null
      } as ActivityRatePlanRequest;

      let response = this.api.GetRatePlanCalculated(request).pipe(delay(1000)).toPromise();
      return response;;
    }
  }


  SetRatePlanBySeatingType(seatingtTypeIds: any[], seatingTime: any, data: any ,  ignoreBookingValidation:boolean = false): any {
    if (seatingtTypeIds) {
      var DateTime = Utilities.Date(new Date(seatingTime)).format('YYYY-MM-DDTHH:mm:ss');
      var selectedTime = _.cloneDeep(DateTime);
      var slots = {
        "LocalTime": selectedTime
      }
      var slot = [];
      slot.push(slots);

      var request = {
        Slots: slot,
        ActivityId: null,
        GuestTypes: null,
        TableIds: null,
        Size: data.Size,
        SessionSlot: null,
        TrackMembershipNumber: this.selectedGuest && this.selectedGuest.TrackMembershipNumber,
        MemberType: this.selectedGuest?.MembershipType?.AllocationName,
        IsMemberActive: this.selectedGuest?.MemberActive,
        BookingBehavior: null,
        SeatingTypeIds: seatingtTypeIds,
        CoverTypes: data.CoverTypes,


      } as ActivityRatePlanRequest;

      let response = this.api.GetRatePlanCalculated(request , null  ,ignoreBookingValidation).pipe(delay(1000)).toPromise();
      return response;;
    }
  }




  GetFinancialEffect(request: any, isSeatParty: boolean, isAssignTable: boolean, serverId?, tableId?, tableIds?: number[] , ignoreEventBlock:boolean = false): any {
    this.isActionCompleted$.next(false);

    var url = (this.cs.settings.value.PropertySetting[0].SlottingType == SlottingType.Dining) ? urlConfig.financialEffectForUpdatedReservation : urlConfig.financialEffectForUpdatedActivity;
    let postURL = `${url}?restaurantId=${Utilities.RestaurantId()}&isSeating=${true}&isAssignTable=${false}`;
    if (isAssignTable)
      postURL = `${url}?restaurantId=${Utilities.RestaurantId()}&isSeating=${false}&isAssignTable=${true}`;
    if (!request.CoverTypes?.length && request.CoverTypeQuantities?.length) {
      request.CoverTypes = request.CoverTypeQuantities;
    }
    delete request.CoverTypeQuantities;
    // if (!request.DurationInMinutes && request.Duration) {
    //   request.DurationInMinutes = request.Duration;
    // }
    this.httpService.post(postURL,
      request).subscribe(data => {
        if (data.ValidationMessages.length > 0) {
          this.openConfirmationDialog(data, data.ValidationMessages[0].Message);
        }
        const specialMeals = this.cs.settings.value.SpecialMeals.filter(x => x.Id == request.SpecialMealId)[0];
        let isDeferredPaymentMode = false;
        let includenoshow = false;
        let specialMealFee = false;
        let needConfirmationBeforeUpdaing = false;
        let waiveOffPayment = false;
        if (specialMeals != null && specialMeals.IsPrepaymentRequired && !specialMeals.ChargeOnBooking) {
          isDeferredPaymentMode = true;
          includenoshow = false;
          specialMealFee = true;
        }

        if (data && data.ValidationMessages.length === 0) {
          const updateConfirmationUpdateReservationPrice = ((data.Payload.PaymentTarget == 1 || data.Payload.PaymentTarget == 3 || data.Payload.PaymentTarget == 4) && data.Payload.TotalAmount != 0) ? data.Payload.TotalAmount : null;
          const PaymentAmount = data.Payload.PaymentAmount != null ? data.Payload.PaymentAmount : null;
          const showUpdatedPaymentPopUp = false;
          let textLabel = '';
          switch (data.Payload.PartyPaymentType) {

            case 0: // NotSupported
              textLabel = this.ts.instant('NotSupported') + this.ts.instant('ReservationBetweenLocationPrePaidNotSupported');
              return;
              break;
            case 1: // NoPaymentsInvolved
              needConfirmationBeforeUpdaing = false;
              // No messaging in this case
              break;
            case 2: // NoEffect
              needConfirmationBeforeUpdaing = false;
              textLabel = this.ts.instant('NoChargeOrRefundForThisReservation');
              break;
            case 3:
            case 8: // PartialCharge //Done
              needConfirmationBeforeUpdaing = true;
              if (data.Payload.IsCreditCardNeeded) {
                textLabel = this.ts.instant("reservationUpdatedWithSeatingTypeRequiredPayment") + this.cs.operationCurrency + data.Payload.RatePlan.Unpaid.toFixed(2) + '. ' + this.ts.instant('enterCreditCard');
                waiveOffPayment = true;
                //textLabel = this.ts.instant('reservationUpdatedWithSeatingTypeRequiredPayment + this.cs.operationCurrency + data.Payload.PaymentAmount.toFixed(2) + '. ' + this.ts.instant('enterCreditCard;
              }
              else {
                if(data.Payload.RatePlan.Paid > 0) {
                textLabel = this.ts.instant('CardUsedForTheOriginalBookingWillCharged') + this.cs.operationCurrency + data.Payload.RatePlan.Unpaid.toFixed(2) + '. ';
                waiveOffPayment = true;
                }else {
                  textLabel = this.ts.instant('CardNeededToUpdateReservation') + this.cs.operationCurrency + data.Payload.RatePlan.Unpaid.toFixed(2) + '.';
                  waiveOffPayment = true;
                }
              }
              break;
            case 9: // PartialRefund //Done
            case 14:
            case 5:
              needConfirmationBeforeUpdaing = true;
              textLabel = this.ts.instant('Refundrequestof') + this.cs.operationCurrency + PaymentAmount.toFixed(2) + this.ts.instant('hasbeenprocessed') + '. ';
              // waiveOffPayment = true;
              //textLabel = en_data.CardUsedForOriginalReservationWillBeRefunded + this.cs.operationCurrency + PaymentAmount.toFixed(2) + '. ' + en_data.SeveralBusinessDays;
              break;
            case 10: // NoShowFeeUpdated //Done
              needConfirmationBeforeUpdaing = false;
              break;
            case 11: // Authorize //Done
              needConfirmationBeforeUpdaing = true;
              textLabel = this.ts.instant('CardUsedAtBookingWillBeChargedAtMealTime') + this.cs.operationCurrency + updateConfirmationUpdateReservationPrice;
              waiveOffPayment = true;
              break;
            case 12: // Authorize updated //Done
              needConfirmationBeforeUpdaing = true;
              textLabel = this.ts.instant('CardUsedAtBookingWillBeChargedAtMealTime') + this.cs.operationCurrency + updateConfirmationUpdateReservationPrice;
              waiveOffPayment = true;
              break;
          }

          if (needConfirmationBeforeUpdaing) {
            return this.showUpdatedPaymentPopUp(request, textLabel, isSeatParty, isAssignTable, serverId, tableId, tableIds, waiveOffPayment);
          } else {
            if (isSeatParty) {
              const seatingInfo = {
                StandaloneTableIds: request.TableIds,
                ServerIdForNonAssignedTables: serverId ? serverId : null
              };
              this.LastPartyRequest = request;
              this.LastPartyServerIds = serverId ? serverId : null;
              let booking =  this.Parties$?.value?.find(party => party.Id == request?.Id);
              booking.TableIds = request.TableIds;
              // let isSameTable = _.isEqual(booking.TableIds, this.SelectedTableIds);
              // if(!isSameTable && this.SelectedTableIds?.length) {
              //   booking.TableIds = this.SelectedTableIds;
              //   let tableNames = Utilities.getTableNamesFromStandaloneTables(this.SelectedTableIds, this.cs.layout.value.FloorPlans);
              //   booking.TableNames = tableNames;
              // }
              this.chitPrintPartyData = [booking];
              var url = (this.cs.settings.value.PropertySetting[0].SlottingType == SlottingType.Dining) ? urlConfig.seatPartyURL : urlConfig.CheckInOpenBookingURL;
              return this.httpService.post(
                url + '?restaurantId=' + Utilities.RestaurantId() + '&partyId=' + request.Id + '&isSeatOrMoveParty=' + true + '&isAssignTable=' + false + '&isChargable=' + false + '&ignoreEventBlock=' + ignoreEventBlock, seatingInfo).subscribe(data => {
                  if (data.State === OperationResultState.Success) {
                    this.openCheck(data);
                  }
                }
                );
            }
            else if (isAssignTable) {
              let selectedTable = { } as SeatingInfoDTO;
              selectedTable.StandaloneTableIds = tableIds;
              const postURL = `${urlConfig.assignTables}?restaurantId=${Utilities.RestaurantId()}&partyId=${request.Id}&isSeatOrMoveParty=${false}&isAssignTable=${true}&isChargable=${false}&ignoreEventBlock=${ignoreEventBlock}`;
              return this.httpService.post(postURL, selectedTable).subscribe();
            }
            else {
              this.LastPartyRequest = request;
              this.LastPartyRequest.TableIds = tableId;
              this.LastPartyServerIds = serverId;
              const seatingInfo = {
                StandaloneTableIds: tableId,
                ServerIdForNonAssignedTables: serverId
              };
              return this.httpService.post(
                urlConfig.movePartyURL + '?restaurantId=' + Utilities.RestaurantId() + '&partyId=' + request.Id + '&isSeatOrMoveParty=' + true + '&isAssignTable=' + false + '&isChargable=' + false, seatingInfo).subscribe(data => {
                  this.isActionCompleted$.next(true);
                });
              this.isMoveTableAPITriggered = true;
            }
          }
        }
      });
  }
  showUpdatedPaymentPopUp(request, textLabel, isSeatParty, isAssignTable, serverId, tableId, tableIds, waiveOffPayment?: boolean , ignoreEventBlock:boolean=false): any {
    if (this.confirmSubscription) {
      this.confirmSubscription.unsubscribe();
    }
    if (this.cancelSubscription) {
      this.cancelSubscription.unsubscribe();
    }
    const cancelText = waiveOffPayment ? this.ts.instant('waiveOff') : this.ts.instant('cancel');
    const title = this.ts.instant('confirmation')
    const msg = textLabel;
    let cancelUpdation = false;
    let updateReservation = false;
    const updateText = waiveOffPayment ? this.ts.instant('payNow') : this.ts.instant('Proceed');;
    const showAlert = true;

    const popUpMessage = [{
      confirmationMessage: msg, dialogTitle: this.ts.instant('confirmation'), showAlert
    }];
    const componentDetails: ComponentDetails = {
      componentName: ConfirmationPopupComponent,
      dimensionType: 'small',
      popupType: 'active',
      popUpDetails: {
        isStepper: false,
        eventName: 'notifyParent'
      },
      popupInput: popUpMessage,
      popupTitle: popUpMessage[0].dialogTitle
    };
    const dialogRef = this.dialog.open(CustomPopupComponent, {
      disableClose: true,
      width: '600px',
      height: '410px',
      data: {
        title,
        update: updateText,
        cancel: cancelText,
        componentDetails,
        from: ComponentTypes.reservation,
        back: false,
        standalone: true,
        showAlert: true
      }
    });

    this.confirmSubscription = this.popupService.confirmedAction$.subscribe(val => {
      if (val === ComponentTypes.reservation) {
        if (this.confirmSubscription) {
          this.confirmSubscription.unsubscribe();
        }
        if (this.cancelSubscription) {
          this.cancelSubscription.unsubscribe();
        }
        updateReservation = true;
        const seatingInfo = {
          StandaloneTableIds: request.TableIds,
          ServerIdForNonAssignedTables: serverId ? serverId : null
        };
        if (isSeatParty) {
          this.LastPartyRequest = request;
          this.LastPartyServerIds = serverId ? serverId : null;
          let booking =  this.Parties$?.value?.find(party => party.Id == request.Id);
          booking.TableIds = request.TableIds;
          // let isSameTable = _.isEqual(booking.TableIds, this.SelectedTableIds);
          // if(!isSameTable && this.SelectedTableIds?.length) {
          //   booking.TableIds = this.SelectedTableIds;
          //   let tableNames = Utilities.getTableNamesFromStandaloneTables(this.SelectedTableIds, this.cs.layout.value.FloorPlans);
          //   booking.TableNames = tableNames;
          // }
          this.chitPrintPartyData = [booking];
          var url = (this.cs.settings.value.PropertySetting[0].SlottingType == SlottingType.Dining) ? urlConfig.seatPartyURL : urlConfig.CheckInOpenBookingURL;
          return this.httpService.post(url + '?restaurantId=' + Utilities.RestaurantId() + '&partyId=' + request.Id + '&isSeatOrMoveParty=' + true + '&isAssignTable=' + false + '&isChargable=' + true + '&ignoreEventBlock=' + ignoreEventBlock, seatingInfo).subscribe(data => {
            if (data.Payload) {
              Utilities.openPurchaseForm(data.Payload.Url);
            }
            if (data.State === OperationResultState.Success) {
              this.openCheck(data);
            }
            if (this.popupSubscription) {
              this.popupSubscription.unsubscribe();
            }
          });
        }
        else if (isAssignTable) {
          const postURL = `${urlConfig.assignTables}?restaurantId=${Utilities.RestaurantId()}&partyId=${request.Id}&isSeatOrMoveParty=${false}&isAssignTable=${true}&isChargable=${true}&ignoreEventBlock=${ignoreEventBlock}`;
          this.httpService.post(postURL, tableIds).subscribe(data => {
            if (data.Payload) {
              Utilities.openPurchaseForm(data.Payload.Url);
            }
            if (this.popupSubscription) {
              this.popupSubscription.unsubscribe();
            }
            return;
          });
        }
        else {
          this.LastPartyRequest = request;
          this.LastPartyRequest.TableIds = tableId;
          this.LastPartyServerIds = serverId;
          const seatingInfo = {
            StandaloneTableIds: tableId,
            ServerIdForNonAssignedTables: serverId
          };
          this.httpService.post(
            urlConfig.movePartyURL + '?restaurantId=' + Utilities.RestaurantId() + '&partyId=' + request.Id + '&isSeatOrMoveParty=' + true + '&isAssignTable=' + false + '&isChargable=' + true, seatingInfo).subscribe(data => {
              if (data.Payload) {
                Utilities.openPurchaseForm(data.Payload.Url);
              }
              if (this.popupSubscription) {
                this.popupSubscription.unsubscribe();
              }
              this.isActionCompleted$.next(true);
            });
          this.isMoveTableAPITriggered = true;
        }
      }
    });
    this.cancelSubscription = this.popupService.cancelledAction$.subscribe(val => {
      if (val.value !== 0) {
        if (val.value === ComponentTypes.reservation && !cancelUpdation) {
          cancelUpdation = true;
          if (this.confirmSubscription) {
            this.confirmSubscription.unsubscribe();
          }
          if (this.cancelSubscription) {
            this.cancelSubscription.unsubscribe();
          }
        }
        if (isSeatParty) {
          const seatingInfo = {
            StandaloneTableIds: request.TableIds,
            ServerIdForNonAssignedTables: serverId ? serverId : null
          };
          this.LastPartyRequest = request;
          this.LastPartyServerIds = serverId ? serverId : null;
          let booking =  this.Parties$?.value?.find(party => party.Id == request.Id);
          booking.TableIds = request.TableIds;
          // let isSameTable = _.isEqual(booking.TableIds, this.SelectedTableIds);
          // if(!isSameTable && this.SelectedTableIds?.length) {
          //   booking.TableIds = this.SelectedTableIds;
          //   let tableNames = Utilities.getTableNamesFromStandaloneTables(this.SelectedTableIds, this.cs.layout.value.FloorPlans);
          //   booking.TableNames = tableNames;
          // }
          this.chitPrintPartyData = [booking];
          var url = (this.cs.settings.value.PropertySetting[0].SlottingType == SlottingType.Dining) ? urlConfig.seatPartyURL : urlConfig.CheckInOpenBookingURL;
          return this.httpService.post(
            url + '?restaurantId=' + Utilities.RestaurantId() + '&partyId=' + request.Id + '&isSeatOrMoveParty=' + true + '&isAssignTable=' + false + '&isChargable=' + false +'&ignoreEventBlock=' + ignoreEventBlock, seatingInfo).subscribe(data => {
              if (data.State === OperationResultState.Success) {
                this.openCheck(data);
              }
            }
            );
        }
        else if (isAssignTable) {
          let selectedTable = { } as SeatingInfoDTO;
          selectedTable.StandaloneTableIds = tableIds;
          const postURL = `${urlConfig.assignTables}?restaurantId=${Utilities.RestaurantId()}&partyId=${request.Id}&isSeatOrMoveParty=${false}&isAssignTable=${true}&isChargable=${false}&ignoreEventBlock=${ignoreEventBlock}`;
          return this.httpService.post(postURL, selectedTable).subscribe();
        }
        else {
          this.LastPartyRequest = request;
          this.LastPartyRequest.TableIds = tableId;
          this.LastPartyServerIds = serverId;
          const seatingInfo = {
            StandaloneTableIds: tableId,
            ServerIdForNonAssignedTables: serverId
          };
          this.httpService.post(
            urlConfig.movePartyURL + '?restaurantId=' + Utilities.RestaurantId() + '&partyId=' + request.Id + '&isSeatOrMoveParty=' + true + '&isAssignTable=' + false + '&isChargable=' + false, seatingInfo).subscribe(data => {
              this.isActionCompleted$.next(true);
            });
          this.isMoveTableAPITriggered = true;
        }
      }
    });
    dialogRef.afterClosed().subscribe(() => {
      if (this.confirmSubscription) {
        this.confirmSubscription.unsubscribe();
      }
      if (this.cancelSubscription) {
        this.cancelSubscription.unsubscribe();
      }
    })
  }

  reseatParty(party, serverId?): any {
    //let ServerIdForNonAssignedTables: serverId ? serverId : null
    //this.LastPartyRequest = party;
    //this.LastPartyServerIds = serverId ? serverId : null;
    return this.httpService.post(
      urlConfig.reseatPartyURL + '?restaurantId=' + Utilities.RestaurantId() + '&partyId=' + party.Id + '&serverIdForNonAssignedTables=' + serverId);
  }

  moveParty(tableId, serverId, party): any {
    let _request = _.cloneDeep(party);
    if (_request) {
      _request.TableIds = tableId;
      _request.AddOns = [];
      if(_request.BookingContactAddonItems) { 
      _request.BookingContactAddonItems.map(addonItem => {
        this.cs.settings.value.Addons.map(addonDetail => {
          if(addonItem.AddonId === addonDetail.AddonId) {
            _request.AddOns.push( {"Name": addonDetail.AddonName, "Price": addonItem.Amount,"AddonId": addonDetail.AddonId, "Quantity": addonItem.AddonCount, "Category": 0, "OverBooked": addonItem.OverBooked } );
          }
        })
      })
      delete _request.BookingContactAddonItems;
    }
      this.GetFinancialEffect(_request, false, false, serverId, tableId);
    }
  }

  getPredefinedNotes(notes: any) {
    if (notes.RelatedId !== undefined || notes.RelatedId != null) {
      this.predefinedNotesId = notes.RelatedId;
      const icon = this.predefinedfNotes.filter(x => x.Id === notes.RelatedId);
      if (icon && icon.length > 0) {
        const iconName = icon[0].Text;
        this.predefinedNotesIcon = this.iconArray.filter(x => x.name === iconName.trim())[0].icon_name + ' allergyIcon_spacing';
        if (this.predefinedNotesIcon !== undefined) {
          return this.predefinedNotesIcon;
        }
      } else {
        return '';
      }
    }
  }


  updateGuest(isConfirmationNeeded: boolean, guestDetails) {
    if (this.reservationFormGroup.valid) {
      return this.httpService.post(urlConfig.updateContactsUrl + '?restaurantId=' + Utilities.RestaurantId(), guestDetails).subscribe(response => {
        if (response) {
          if (isConfirmationNeeded) {
            this.openConfirmationDialog(response, this.ts.instant(Labels[Labels.contactupdatedconfirmationtext]));
          }
        }
      });
    }
  }


  cancelClassOrSession(partyId: number, cancelAllOrRemainingSessions: boolean, bookedSessionId: number = null, chargeCancellation = false, cancellationReason = null,financialEffectId) {
    return this.httpService.post(
      `${urlConfig.cancelClassOrSessionBookingURL}${Utilities.RestaurantId()}&partyId=${partyId}&cancelAllOrRemainingSessions=${cancelAllOrRemainingSessions}&bookedSessionId=${bookedSessionId}&chargeCancellation=${chargeCancellation}&cancellationReason=${cancellationReason}&FinancialEffectId=${financialEffectId}`, null);
  }

  getCancellationCharge(partyId: number, cancelAllOrRemainingSessions: boolean, bookedSessionId: number = null) {
    return this.httpService.post(
      `${urlConfig.GetCancellationChargeURL}${Utilities.RestaurantId()}&partyId=${partyId}&cancelAllOrRemainingSessions=${cancelAllOrRemainingSessions}&bookedSessionId=${bookedSessionId}`);
  }

  cancelReservation(partyId: number) {
    return this.httpService.post(
      `${urlConfig.cancelReservationURL}${Utilities.RestaurantId()}&partyId=${partyId}`, null);
  }



  cancelActivity(partyId: any, rejectStandby: boolean = false,financialEffectId:number = null) {
    return this.httpService.post(
      `${urlConfig.cancelActivityURL}${Utilities.RestaurantId()}&partyId=${partyId}&isFromRejectStandBy=${rejectStandby}&FinancialEffectId=${financialEffectId}`, null);
  }

  cancelMultipleActivity(partyId: any) {
    return this.httpService.post(
      `${urlConfig.cancelMulipleActivityURL}${Utilities.RestaurantId()}&bookingId=${partyId}`, []);
  }

  cancelWaitlist(partyId: number) {
    return this.httpService.post(
      `${urlConfig.removeWalkInURL}${Utilities.RestaurantId()}&partyId=${partyId}`, null);
  }


  postPartyMessage(partyId: number, method: number, Message: string, code: string) {
    const postURL = `${urlConfig.postPartyMessageURL}?restaurantId=${Utilities.RestaurantId()}&partyId=${partyId}&method=${method}&templateCode=${code}`;
    return this.httpService.post(postURL, {Message});
  }

  getNoShowFeeCalculator(selectedDateTime?, selectedsize?, selectedarea?) {
    let _selectedDateTime = selectedDateTime ? selectedDateTime : this.reservationFormGroup.get('selectedTime').value.DateTime;
    let _selectedsize = selectedsize ? selectedsize : this.reservationFormGroup.get('selectedSize').value;
    let _selectedArea = selectedarea ? selectedarea : this.reservationFormGroup.get('selectedArea').value.Id;
    _selectedDateTime = moment(_selectedDateTime).format('YYYY-MM-DDTHH:mm:ss');
    let _selectedTableIds = [];
    _selectedTableIds = this.reservationFormGroup.get('selectedTable').value;

    const postURL = `${urlConfig.getNoShowFeeURL}?restaurantId=${Utilities.RestaurantId()}&localDateTime=${_selectedDateTime}&partySize=${_selectedsize}&seatingAreaId=${_selectedArea}`;
    return this.httpService.post(postURL, _selectedTableIds);
  }

  getStandaloneTablesFutureState(selectedDateTime?, selectedsize?, selectedarea?) {

    let _selectedDateTime = selectedDateTime ? selectedDateTime : this.reservationFormGroup.get('selectedTime').value.DateTime;
    let _selectedsize = selectedsize ? selectedsize : this.reservationFormGroup.get('selectedSize').value;
    let _selectedArea = selectedarea ? selectedarea : this.reservationFormGroup.get('selectedArea').value.Id;
    _selectedDateTime = moment(_selectedDateTime).format('YYYY-MM-DDTHH:mm:ss');
    return this.httpService.get(urlConfig.getStandaloneTableFutureStateURL
      + '?restaurantId=' + Utilities.RestaurantId() + '&localDateTime=' + _selectedDateTime + '&partySize=' + _selectedsize + '&seatingAreaId=' + _selectedArea).pipe(map((response: any) => {
        if (response) {
          const fututreTableStates = response.Payload;
          const standaloneTables = this.cs.layout.value.FloorPlans.filter(x => x.IsDefault == true)[0].StandaloneTables;
          const StandaloneTablesWithStates = [];

          for (let i = 0; i < standaloneTables.length; i++) {
            StandaloneTablesWithStates.push({
              ...standaloneTables[i],
              ...(fututreTableStates.find((state) => state.StandaloneTableId === standaloneTables[i].Id))
            }
            );
          }
          this.layoutCofiguartion = {
            from: TableLayoutConfig.reservationTableSelection,
            tables: StandaloneTablesWithStates
          };
          return this.layoutCofiguartion;
        } else {
          return ResponseType.Failed;
        }
      }));
  }
  getStandaloneTablesFutureStateAllFloorTables(selectedDateTime?, selectedsize?, selectedarea? , specialMealId?) {
    let _selectedDateTime = selectedDateTime ? selectedDateTime : this.reservationFormGroup?.get('selectedTime')?.value?.DateTime;
    let _selectedsize = selectedsize || this.bookingSize || this.reservationFormGroup?.get('selectedSize')?.value;
    let _selectedArea = selectedarea ? selectedarea : this.reservationFormGroup?.get('selectedArea').value ? this.reservationFormGroup?.get('selectedArea').value.Id : -1;
    let _eventId = this.selectedSpecialMealId > 0 ? this.selectedSpecialMealId : ( specialMealId || null);
    _selectedDateTime = moment(_selectedDateTime).format('YYYY-MM-DDTHH:mm:ss');
    return this.httpService.get(urlConfig.getStandaloneTableFutureStateURL
      + '?restaurantId=' + Utilities.RestaurantId() + '&localDateTime=' + _selectedDateTime + '&partySize=' + _selectedsize + '&seatingAreaId=' + _selectedArea + '&eventId=' + _eventId).pipe(map((response: any) => {
        if (response) {
          return response.Payload;
        } else {
          return ResponseType.Failed;
        }
      }));
  }
  manualSlotUnlock(lockId) {
    const postURL = `${urlConfig.slotManualUnlockURL}?restaurantId=${Utilities.RestaurantId()}&lockId=${lockId}`;
    return this.httpService.post(postURL);
  }

  autoSlotUnlock(lockId) {
    const postURL = `${urlConfig.slotAutoUnlockURL}?restaurantId=${Utilities.RestaurantId()}&lockId=${lockId}`;
    return this.httpService.post(postURL);
  }

  unlockSessions(lockId) {
    return this.httpService.post(`${urlConfig.unlockSessionsURL}?restaurantId=${Utilities.RestaurantId()}&lockId=${lockId}`)
  }

  releaseMultiple(lockIds) {
    return this.httpService.post(`${urlConfig.releaseMultipleURL}?restaurantId=${Utilities.RestaurantId()}`, lockIds)
  }

  getAutoSlotLockTime(slotTime, partySize, seatingAreaId, lockIdToIgnore) {
    const postURL = `${urlConfig.slotAutoLockURL}?restaurantId=${Utilities.RestaurantId()}&localTime=${slotTime}
&partySize=${partySize}&seatingAreaId=${seatingAreaId}&lockIdToIgnore=${lockIdToIgnore}`;
    return this.httpService.post(postURL, null);
  }

  getManualSlotTime(partySize, slotTime, slotId, slotType, lockIdToIgnore) {
    const postURL = `${urlConfig.slotManualURL}?restaurantId=${Utilities.RestaurantId()}&partySize=${partySize}
&lockIdToIgnore=${lockIdToIgnore}`;
    const requestedPayload = {} as ManualSlotIdDTO;
    requestedPayload.DateTime = slotTime;
    requestedPayload.Id = slotId;
    requestedPayload.IsDefault = false;
    if (slotType == 2) { requestedPayload.IsDefault = true; }
    return this.httpService.post(postURL, requestedPayload);
  }

  createManualSlot(request: UpdatedManualSlotDTO) {
    return this.httpService.post(`${urlConfig.createManualSlot}?restaurantId=${Utilities.RestaurantId()}`,
      request);
  }

  updateManualSlot(request: UpdatedManualSlotDTO) {
    return this.httpService.post(`${urlConfig.updateManualSlot}?restaurantId=${Utilities.RestaurantId()}`,
      request);
  }

  removeManualSlot(ids: ManualSlotIdDTO[], remove: boolean = false) {
    return this.httpService.post(`${urlConfig.removeManualSlot}?restaurantId=${Utilities.RestaurantId()}&remove=${remove}`,
      ids);
  }

  setWebReservableManualSlot(ids: ManualSlotIdDTO[], webReservable: boolean = false) {
    return this.httpService.post(`${urlConfig.setWebReservableManualSlot}?restaurantId=${Utilities.RestaurantId()}&webReservable=${webReservable}`,
      ids);
  }


  rejectStandActivity(reason: any, party: any, email: string, bookedSessionId?: any, callback?) {
    // this.getFinancialEffectForCancelWithDues(party.Id, false, false, [bookedSessionId], false).subscribe(data => {
      let cancelledItems =[{
        "BookedSessionId": bookedSessionId || null,
        "Amount": 0 //no cancellation fee for standby
          }]
      this.getCancelFinancialDetails(party.Id, cancelledItems).subscribe(data => {
      if (data.ValidationMessages.length > 0) {
        this.openConfirmationDialog(data, data.ValidationMessages[0].Message,);
      }
      else {

        if (data.Payload != null) {
          this.selectedBooking = party;
          let financialData = data.Payload;
          if (financialData.ReturnItem && financialData.ReturnItem.length && Utilities.isRetailEnabledProperty(this.cs.settings.value.General.RetailIntegrationDTO)) {
            this.Operations = Operations.cancel;
            let cancelledParty = this.StandbyParties$.value.find(booking => booking.Id == party.Id);
            this.bookingCancellationPayload = data;
            cancelledParty.cancelAllSessions = false;
            cancelledParty.cancellationReason = reason;
            cancelledParty.bookedSessionId = bookedSessionId;
            cancelledParty.chargeCancellation = cancelledParty;
            this.cancelBookingDataObj = cancelledParty;
            this.cancelBookingDataObj.CancellationFee = data.Payload.cancelFinancialEffect?.CancellationAmount;
            this.RetailCanceloperation(party, data.Payload, true, cancelledParty);
          } 
          else {
            this.confirmRejectStandActivity(party.Id, reason, bookedSessionId, callback);
          }
          if(this.standbyDialog){
            this.standbyDialog.close();
          }
        }
        
        else {
          this.confirmRejectStandActivity(party.Id, reason, bookedSessionId, callback);
        }
        if (callback) {
          callback(party.Id);
        }
      }
      // let skipPaymentState = [FinancialEffectType.NotSupported , FinancialEffectType.ChargeCancellationLater,FinancialEffectType.NoFullRefund , FinancialEffectType.NoPaymentsInvolved]
      // if ((financialData.PartyPaymentType == FinancialEffectType.NoEffect && !financialData?.CancellationAmount) || skipPaymentState.includes(financialData.PartyPaymentType)) {
      //   this.confirmRejectStandActivity(party.Id, reason, bookedSessionId, callback);
      // }
      // else if (financialData.PartyPaymentType == FinancialEffectType.PartialRefund || financialData.PartyPaymentType == FinancialEffectType.PartialCharge || financialData.PartyPaymentType == FinancialEffectType.FullRefund || financialData?.CancellationAmount > 0) {
      //   this.Operations = Operations.cancel;
      //   let cancelledParty = this.StandbyParties$.value.find(booking => booking.Id == party.Id);
      //   this.bookingCancellationPayload = data;
      //   cancelledParty.cancelAllSessions = false;
      //   cancelledParty.cancellationReason = reason;
      //   cancelledParty.bookedSessionId = bookedSessionId;
      //   cancelledParty.chargeCancellation = cancelledParty;
      //   this.cancelBookingDataObj = cancelledParty;
      //   this.cancelBookingDataObj.CancellationFee = data.Payload.cancelFinancialEffect?.CancellationAmount;
      //   this.RetailCanceloperation(party, data.Payload, true, cancelledParty);
      //   if (callback) {
      //     callback(party.Id, true);
      //   }
      // }
    })
  }
  confirmRejectStandActivity(partyId, reason, bookedSessionId, callback?) {
    return this.httpService.post(`${urlConfig.rejectStandbyActivity}?restaurantId=${Utilities.RestaurantId()}&partyId=${partyId}&comment=${reason}&bookedSessionId=${bookedSessionId}`, null).subscribe(data => {
      this.isStandBy = true;
      if (data.ValidationMessages.length > 0) {
        this.openConfirmationDialog(data, data.ValidationMessages[0].Message,);
      }
      else {
        if (data.Payload.PaymentState && data.Payload.PaymentState == 1 && data.Payload.PaymentAmount > 0 && data.Payload.PaymentType == FinancialEffectType.PartialRefund) {// Refund
          this.openConfirmationDialog(data, "A refund for " + this.cs.settings.value.General.OperationCurrency + data.Payload.PaymentAmount + " has been successfully processed for the cancelled " + (!data.Payload.BookedSessionId ? "Class Booking" : "session(s)"));
        } else if (data.Payload.PaymentState && data.Payload.PaymentState == 1 && data.Payload.PaymentAmount > 0 && data.Payload.PaymentType == FinancialEffectType.PartialCharge) {// Charge
          this.openConfirmationDialog(data, " The party is successfully charged for " + this.cs.settings.value.General.OperationCurrency + data.Payload.PaymentAmount + " for the attended sessions and cancelled this session.");
        } else {
          this.showAppPopup(" Booking cancelled successfully.");
        }
        if (data.Payload && data.Payload.EmailAddress && (this.cs.settings.value.General.HostCancellationEmailSendBehavior == PartyEmailSendBehavior.Prompt)) {
          // this.openConfirmationDialog(data, '', null, ReservationEmailNotificationType.Cancelled, ComponentTypes.RejectBooking, null, null, partyId, callback);
         
          const _confirmationMessages = Utilities.getConfirmationMessage(data, null, ReservationEmailNotificationType.Cancelled, null);
          let _title = Utilities.getConfirmationDialogTitle(ReservationEmailNotificationType.Cancelled);
          let _dialogRef = this.openAppConfirmationDialog(data, _title, _confirmationMessages, '', 'Cancel', this.ts.instant('sendConfirmationEmail'));
          _dialogRef.afterClosed().subscribe((dataValue) => {

            if(dataValue && dataValue.length){
              let _val = dataValue[0];
              if(_val?.partyId && _val?.email){
                this.triggerEmail = true;
                this.SendConfirmaionEmail(_val.partyId, _val.email,  ReservationEmailNotificationType.Cancelled, _val.cancelledIds, _val.bookedSessionId, _val.classOrSessionBooking, _val.confirmedBookedSessionId).subscribe(data => {
                  if (data) {
                    this.reservationDialogRef ? this.reservationDialogRef.close() : '';
                    this.reservationDialogRef ? this.reservationDialogRef = null : '';
                  }
                });
              }
            }            
          });
        }
        if(callback){
          callback(partyId);
        }
      }
    });
  }
  rejectPrivateStandActivity(reason: any, party: any, email: string, bookingId?: any, callback?, isOpenBooking?) {
  //  this.getFinancialEffectForCancelWithDues(partyId, false, false, null, false).subscribe(data => {
    let cancelledItems =[{
      "BookedSessionId": null,
      "Amount": 0 // no cancellation fee for standby 
        }]
      this.getCancelFinancialDetails(party.Id, cancelledItems).subscribe(data => {
      this.isStandBy = true;
      let financialData = data.Payload;
        if (data.Payload != null) {
          this.selectedBooking = party;
          let financialData = data.Payload;
          if (financialData.ReturnItem && financialData.ReturnItem.length && Utilities.isRetailEnabledProperty(this.cs.settings.value.General.RetailIntegrationDTO)) {
            this.Operations = 3;
            this.BookingBehaviour = BookingBehavior.PrivateLesson;
            this.cancelBookingDataObj = party;
            this.cancelBookingDataObj.CancellationFee = financialData?.CancellationAmount;
            this.bookingCancellationPayload = data;
            this.RetailCanceloperation(party, data.Payload, this.isStandBy);
          } 
          else {
            this.httpService.post(`${urlConfig.rejectStandbyPrivateActivity}?restaurantId=${Utilities.RestaurantId()}&comment=${reason}&bookingId=${bookingId}`, [party.Id]).subscribe(data => {
              this.openConfirmationDialogueAfterCancel(data , party.Id);
            })
          }
          if(this.standbyDialog){
            this.standbyDialog.close();
          }
        }


        // this.BookingBehaviour = BookingBehavior.PrivateLesson;
        // if (financialData && financialData.PaymentAmount && Utilities.isRetailEnabledProperty(this.cs.settings.value.General.RetailIntegrationDTO)) {
        //   this.Operations = Operations.cancel;
        //   this.bookingCancellationPayload = data;
        //   //if (this.BookingBehaviour == BookingBehavior.PrivateLesson) {
        //   let party = this.StandbyParties$.value.find(x => x.Id == partyId);
        //   if (party) {
        //     this.cancelBookingDataObj = party;
        //   }
        //   //}
        //   this.cancelBookingDataObj.CancellationFee = financialData?.CancellationAmount;
        //   if(this.standbyDialog){
        //     this.standbyDialog.close();
        //   }
        //   this.RetailCanceloperation(partyId, financialData, this.isStandBy, this.cancelBookingDataObj)
          // this.SetRetailItem(null, partyId, amount, data.Payload, this.isStandBy, null, this.cancelBookingDataObj);

        
        else {
          if (isOpenBooking) {
            this.cancelActivity(party.Id, true).subscribe(data => {
              this.openConfirmationDialogueAfterCancel(data);
            })
          }
          else {
            this.httpService.post(`${urlConfig.rejectStandbyPrivateActivity}?restaurantId=${Utilities.RestaurantId()}&comment=${reason}&bookingId=${bookingId}`, [party.Id]).subscribe(data => {
              this.openConfirmationDialogueAfterCancel(data);
            })
          }
        }
        if (callback) {
          callback(party.Id);
        }
      
    });
  }

  openAppMessagePopup(data){
    let _confirmationMessages  = Utilities.getConfirmationMessage(data, this.ts.instant(Labels[Labels.reservationaddedfromCart]));
    let _title = Utilities.getConfirmationDialogTitle(null);
    this.openAppConfirmationDialog(data, _title, _confirmationMessages, 'action', 'Ok', '');
  }

  openConfirmationDialogueAfterCancel(data , partyId?:number) {
    let partyData = data.Payload[partyId];
    if (partyData.PaymentState && partyData.PaymentState == 1 && partyData.PaymentAmount > 0 && partyData.PaymentType == FinancialEffectType.PartialRefund) {// Refund
      this.openConfirmationDialog(data, "A refund for " + this.cs.settings.value.General.OperationCurrency + partyData.PaymentAmount + " has been successfully processed for the cancelled " + (!partyData.BookedSessionId ? "Class Booking" : "session(s)"));
    } else if (partyData.PaymentState && partyData.PaymentState == 1 && partyData.PaymentAmount > 0 && partyData.PaymentType == FinancialEffectType.PartialCharge) {// Charge
      this.openConfirmationDialog(data, " The party is successfully charged for " + this.cs.settings.value.General.OperationCurrency + partyData.PaymentAmount + " for the attended sessions and cancelled this session.");
    } else {
      this.openConfirmationDialog(data, this.ts.instant(Labels[Labels.reservationcancelled]), '');
    }
    if (partyData && partyData?.EmailAddress &&(this.cs.settings.value.General.HostCancellationEmailSendBehavior == PartyEmailSendBehavior.Prompt)) {
      // this.openConfirmationDialog(data, '', null, ReservationEmailNotificationType.Cancelled), ComponentTypes.RejectBooking;
      data.Payload = data.Payload[partyId]
      this.showEmailNotification(data, ReservationEmailNotificationType.Cancelled);
    }
  }

 

  slotUnlockTimerFunc() {
    if (this.slotUnlockTimer) {
      clearTimeout(this.slotUnlockTimer);
    }
    this.slotUnlockTimer = setTimeout(() => {
      this.unlockSlot(this.slotLockId);
    }, (4 * 60 * 1000))
  }

  unlockSlot(slockLockId) {
    const slotMode = this.cs.settings.value.General.SlottingMode;
    if (slockLockId) {
      if (slotMode === SlottingMode.Auto) {
        this.subscriptions.add(this.autoSlotUnlock(slockLockId).subscribe((slot) => {
          this.slotLockId = null;
          if (this.slotUnlockTimer) {
            clearTimeout(this.slotUnlockTimer);
          }
        }));
      } else if (slotMode === SlottingMode.Manual) {
        this.subscriptions.add(this.manualSlotUnlock(slockLockId).subscribe((slot) => {
          this.slotLockId = null;
          if (this.slotUnlockTimer) {
            clearTimeout(this.slotUnlockTimer);
          }
        }));
      }
    }
  }

  GetPendingChargeParties() {
    const getURL = `${urlConfig.getPendingChargeURL}?restaurantId=${Utilities.RestaurantId()}`;
    return this.httpService.get(getURL);
  }
  ChargeParty(partyId) {
    const postURL = `${urlConfig.chargePartyURL}?restaurantId=${Utilities.RestaurantId()}&partyId=${partyId}`;
    return this.httpService.post(postURL);
  }
  AdditionalChargeParty(partyId, paymentneed = true, paymentTransaction = null) {
    const postURL = `${urlConfig.additionalChargePartyURL}?restaurantId=${Utilities.RestaurantId()}&partyId=${partyId}&&isPaymentNeed=${paymentneed}`;
    return this.httpService.post(postURL, paymentTransaction);
  }
  IgnoreParty(partyId) {
    const postURL = `${urlConfig.ignoreChargingPartyURL}?restaurantId=${Utilities.RestaurantId()}&partyId=${partyId}`;
    return this.httpService.post(postURL, null);
  }
  IgnoreParties(partyIds: number[]) {
    const postURL = `${urlConfig.ignoreChargingPartiesURL}?restaurantId=${Utilities.RestaurantId()}`;
    const requestedPayload = {} as PartyIDs;
    requestedPayload.partyIds = partyIds;
    return this.httpService.post(postURL, partyIds);
  }
  GetPartiesForBilling(contactId: number, filter: GetPartiesFilter, options: GetPartyOptions) {
    const getURL = `${urlConfig.getPartiesForBilling}?restaurantId=${Utilities.RestaurantId()}&contactId=${contactId}&filter=${filter}&options=${options}`;
    return this.httpService.get(getURL).toPromise();
  }
  RefundParty(partyId) {
    const postURL = `${urlConfig.refundParty}?restaurantId=${Utilities.RestaurantId()}&partyId=${partyId}`;
    return this.httpService.post(postURL);
  }
  RefundOverpayment(partyId, refundData) {
    const postURL = `${urlConfig.refundOverpayment}?restaurantId=${Utilities.RestaurantId()}&partyId=${partyId}`;
    return this.httpService.post(postURL, refundData);
  }

  completeReservation(restaurantId: number, partyId: number) {
    const postURL = `${urlConfig.completeReservationURL}?restaurantId=${restaurantId}&partyId=${partyId}`;
    return this.httpService.get(postURL);
  }

  postArrivedStatus(restaurantId: number, partyId: number, requestedPayload , ignoreEventBlock:boolean = false) {
    const postURL = `${urlConfig.seatPartyURL}?restaurantId=${restaurantId}&partyId=${partyId}&ignoreEventBlock=${ignoreEventBlock}`;
    return this.httpService.post(postURL, requestedPayload);
  }

  postPageMultiplePartiesMessage(text: string, requestedPayload, code:string) {
    const postURL = `${urlConfig.postPageMultiplePartiesMessageURL}?restaurantId=${Utilities.RestaurantId()}&message=${encodeURIComponent(text)}&templateCode=${code}`;
    return this.httpService.post(postURL, requestedPayload);
  }

  assignTables(partyId: number, tableIds: any, party? , ignoreEventBlock: boolean=false): Observable<any> {
    if (party.Type == ReservationType.Reservation) {
      let _request = _.cloneDeep(party);
      _request.TableIds = tableIds;
      _request.AddOns = [];
      if(_request.BookingContactAddonItems) { 
      _request.BookingContactAddonItems.map(addonItem => {
        this.cs.settings.value.Addons.map(addonDetail => {
          if(addonItem.AddonId === addonDetail.AddonId) {
            _request.AddOns.push( {"Name": addonDetail.AddonName, "Price": addonItem.Amount,"AddonId": addonDetail.AddonId, "Quantity": addonItem.AddonCount, "Category": 0, "OverBooked": addonItem.OverBooked } );
          }
        })
      })
      delete _request.BookingContactAddonItems;
    }
      return this.GetFinancialEffect(_request, false, true, null, null, tableIds , ignoreEventBlock);
    }
    else {
      const postURL = `${urlConfig.assignTables}?restaurantId=${Utilities.RestaurantId()}&partyId=${partyId}&ignoreEventBlock=${ignoreEventBlock}`;
      return this.httpService.post(postURL, tableIds);
    }
  }

  undoNoShowWaitlist(partyId: number) {
    const postURL = `${urlConfig.undoNoShow}?restaurantId=${Utilities.RestaurantId()}&partyId=${partyId}`;
    return this.httpService.post(postURL, null);
  }

  GetAllBookedPackageActivities(propertyId, startDate, endDate) {
    const getURL = `${urlConfig.GetAllBookedPackageActivities}${propertyId}&startDate=${startDate}&endDate=${endDate}`;
    return this.httpService.get(getURL);
  }

  CancelAllBookedPackageActivities(propertyId, packageConfirmationId, packageId) {
    const getURL = `${urlConfig.CancelAllBookedPackageActivities}${propertyId}&packageConfirmationId=${packageConfirmationId}&packageId=${packageId}`;
    return this.httpService.get(getURL);
  }


  SaveHeadlines(activityAttributes) {
    const postURL = `${urlConfig.SaveActivitiesAttribute}?restaurantId=${Utilities.RestaurantId()}`;
    return this.httpService.post(postURL, activityAttributes);
  }

  DeleteHeadlines(Payload, sessionId) {
    const postURL = `${urlConfig.DeleteActivityAttribute}?restaurantId=${Utilities.RestaurantId()}&attributeId=${Payload.activityAttributeId}&activitySessionId=${sessionId}`;
    return this.httpService.post(postURL, Payload);
  }





  ngOnDestroy() {
    if (this.confirmSubscription) {
      this.confirmSubscription.unsubscribe();
    }
    if (this.cancelSubscription) {
      this.cancelSubscription.unsubscribe();
    }
    if (this.confSubscription) {
      this.confSubscription.unsubscribe();
    }
    if (this.popupSubscription) {
      this.popupSubscription.unsubscribe();
    }
    if (this.subscriptions) {
      this.subscriptions.unsubscribe();
    }
    if (this.slotUnlockTimer) {
      clearTimeout(this.slotUnlockTimer);
    }
  }
  UpdateSeatingPartySize(partyId: number, partySize: number) {
    const postURL = `${urlConfig.UpdateSeatingPartySize}?restaurantId=${Utilities.RestaurantId()}&partyId=${partyId}&partySize=${partySize}`;
    return this.httpService.post(postURL, null);
  }


  undoNoShowReservation(partyId: number, slot: UpdatedPartySlotDTO , dialogRef?) {
    this.subscriptions.add(this.httpService.post(`${urlConfig.undoNoShowReservation}?restaurantId=${Utilities.RestaurantId()}&partyId=${partyId}`,
      slot).subscribe(
        data => {
          this.tryUndoNoShowReservation = false;
          if (data.ValidationMessages.length > 0) {
            this.openConfirmationDialog(data, data.ValidationMessages[0].Message, slot.Time);
          }
          else {
            this.popupService.closeDialog$.next();
            this.openConfirmationDialog(data, this.ts.instant(Labels[Labels.reservationupdatedconfirmationtext]), slot.Time);
          }
           dialogRef?.close();
        }));
  }

  getPartyInfo(partyId: number): Observable<any> {
    const url = `${urlConfig.getPartyDetails}?restaurantId=${Utilities.RestaurantId()}&partyId=${partyId}`;
    return this.httpService.get(url).pipe(map(data => {
      if(!data.Payload?.CoverTypeQuantities?.length && data.Payload.Size){
        data.Payload.CoverTypeQuantities = [ {CoverTypeId: null, Covers: data.Payload.Size} ]
      }
      return data;
    }));
  }

  changeContact(partyId: number, guest: FullContactDTO) {
    this.subscriptions.add(this.httpService.post(`${urlConfig.changeContact}?restaurantId=${Utilities.RestaurantId()}&partyId=${partyId}`,
      guest).subscribe(data => {
        if (data.ValidationMessages.length > 0) {
          this.openConfirmationDialog(data, data.ValidationMessages[0].Message);
        }
        else {
          this.popupService.closeDialog$.next();
          this.openConfirmationDialog(data, this.ts.instant(Labels[Labels.contactupdatedconfirmationtext]));
        }
      }));
  }
  getWaitTimesReport() {
    const getURL = `${urlConfig.waitTimesReportUrl}?restaurantId=${Utilities.RestaurantId()}`;
    return this.httpService.get(getURL);
  }
  setManualWaitTime(waitTimes: WaitTimeDTO[]) {
    const postURL = `${urlConfig.setManualUrl}?restaurantId=${Utilities.RestaurantId()}`;
    return this.httpService.post(postURL, waitTimes);
  }
  resetManualWaitTime() {
    const postURL = `${urlConfig.resetManualUrl}?restaurantId=${Utilities.RestaurantId()}`;
    return this.httpService.post(postURL);
  }
  resetRestaurantCache() {
    const getURL = `${urlConfig.resetRestaurantCache}?restaurantId=${Utilities.RestaurantId()}`;
    return this.httpService.get(getURL);
  }

  getConciergeTrackingList(type: ConciergeTrackingType, hotelId?): Observable<any> {

    if (type == ConciergeTrackingType.Hotel) {
      return this.httpService.get(`${urlConfig.getHotelListURL}?restaurantId=${Utilities.RestaurantId()}`);
    }
    else {
      return this.httpService.get(`${urlConfig.getConciergeListURL}?restaurantId=${Utilities.RestaurantId()}&hotelId=${hotelId}`);
    }
  }

  getConcierge(conciergeId): Observable<any> {
    return this.httpService.get(`${urlConfig.getConciergeURL}?restaurantId=${Utilities.RestaurantId()}&conciergeId=${conciergeId}`);
  }

  updateHotelList(data): Observable<any> {
    return this.httpService.post(`${urlConfig.postHotelListURL}?restaurantId=${Utilities.RestaurantId()}`, data);
  }

  updateConciergeList(data): Observable<any> {
    return this.httpService.post(`${urlConfig.postConciergeListURL}?restaurantId=${Utilities.RestaurantId()}`, data);
  }

  addConciergePhoneNumber(data: ConciergePhoneNumberDTO): Observable<any> {
    return this.httpService.post(`${urlConfig.postConciergeNewPhoneNumberURL}?restaurantId=${Utilities.RestaurantId()}`, data);
  }

  updateConciergePhoneNumber(data: ConciergePhoneNumberDTO): Observable<any> {
    return this.httpService.post(`${urlConfig.postConciergeOldPhoneNumberURL}?restaurantId=${Utilities.RestaurantId()}`, data);
  }

  getConciergePhoneNumber(conciergeId): Observable<any> {
    return this.httpService.get(`${urlConfig.getConciergePhoneNumberURL}?restaurantId=${Utilities.RestaurantId()}&conciergeId=${conciergeId}`);
  }

  deleteConciergePhoneNumber(conciergePhoneId): Observable<any> {
    return this.httpService.post(`${urlConfig.deleteConciergePhoneNumberURL}?restaurantId=${Utilities.RestaurantId()}&phoneNumberId=${conciergePhoneId}`);
  }
  removeStandbyParty(partyId: number) {
    return this.httpService.post(
      `${urlConfig.removeStandbyUrl}${Utilities.RestaurantId()}&partyId=${partyId}`, null);
  }

  createStandbyParty(request, dialogRef?: MatDialogRef<any>) {
    this.subscriptions.add(this.httpService.post(`${urlConfig.createStandbyUrl}${Utilities.RestaurantId()}`,
      request).subscribe(
        data => {
          if (data) {
            if (dialogRef) {
              dialogRef.close();
              this.popupService.closeDialog$.next();
            }
          }
        }));
  }


  updateStandByActivities(request, ignoreBookingValidation, dialogRef?: MatDialogRef<any>) {
    this.subscriptions.add(this.httpService.post(`${urlConfig.updateStandbyActivity}${Utilities.RestaurantId()}&ignoreBookingValidation=${ignoreBookingValidation}`,
      request).subscribe(data => {
        if (data) {
          if (dialogRef) {
            dialogRef.close();
          }
        }
      }));

  }
  updateStandbyParty(request, dialogRef?: MatDialogRef<any>) {
    this.subscriptions.add(this.httpService.post(`${urlConfig.updateStandbyUrl}${Utilities.RestaurantId()}`,
      request).subscribe(data => {
        if (data) {
          if (dialogRef) {
            dialogRef.close();
          }
        }
      }));
  }

  convertActivityStandbyToReservation(party, ignoreBookingValidation, dialogRef, callback?) {
    let updatereservatioDto = this.buildreservationRequest(party);
    this.subscriptions.add(this.httpService.post(`${urlConfig.ConvertStandBytoReservationActivity}${Utilities.RestaurantId()}&ignoreBookingValidation=${ignoreBookingValidation}&BookedSessionId=${party.BookedSessionId}`, updatereservatioDto).subscribe(data => {
      if (data.State == OperationResultState.ConsentMessages && data.ValidationMessages?.length) {
        this.showExistingReservationPopup(ComponentTypes.ConvertStandBytoReservationActivity, updatereservatioDto, data.ValidationMessages, dialogRef, party)
        return;
      }
      if (data.Payload && data.Payload.PartyId && data.Payload.ConfirmationCode) {
        this.isStandBy = false; // Standby has been converted to reservation
      }
      if (data.Payload && data.Payload && data.Payload.EmailAddress && this.cs.settings.value.General.HostConfirmationEmailSendBehavior == PartyEmailSendBehavior.Prompt) {
        // this.openConfirmationDialog(data, null, null, ReservationEmailNotificationType.Created, null, party.BookedSessionId);
        this.showEmailNotification(data, ReservationEmailNotificationType.Created, party.BookedSessionId)
      }
      if (data.Payload?.SpecialMealId && this.cs.specialMealListForMerchant.find(meal => meal.Id === data.Payload.SpecialMealId).EnableTicketPrinting) {
        this.bookingConfirmationData = data.Payload;
        this.bookingConfirmationData.PropertyId = Utilities.RestaurantId();
        this.ShowTicket();
      }
      if (data.Payload && data.Payload.PartyId && data.Payload.ConfirmationCode && callback) {
        callback(data.Payload.PartyId);
      }
      if (data.Payload && data.Payload.PartyId && data.Payload.ConfirmationCode && !callback) {
        if (Utilities.isRetailEnabledProperty(this.cs.settings.value.General.RetailIntegrationDTO)) {
          this.popupService.closeDialog$.next(true);
        }
      }
      if (this.popupSubscription) {
        this.popupSubscription.unsubscribe();
      }
    }));
  }
  buildreservationRequest(party: any) {
    let req = {
      "Id": party.Id,
      "Size": party.Size,
      "RemindAboutVisit": false,
      "RemindAboutVisitEmail": false,
      "AgreedWithTermsOfUse": true,
      "Contact": party.Contact,
      "Slot": null,
      "PartyId": party.Id,
      "SeatingAreaId": null,
      "SeatingTypeId": null,
      "ConfirmationMessageOption": 0,
      "Notes": [],
      "TableIds": party.TableIds,
      "InstructorIds": '',
      "BookingTypes": party.BookingTypeQuantities,
      "CoverTypes": party.CoverTypeQuantities,
      "IsForStandbyReservations": false,
      "IsCommunalTable": false,
      "FromWebsite": true,
      "Language": 0,
      "ReservationAttemptId": null,
      "waiverFormTransactionId": null,
      "SecondaryContactsList": null,
      "BookingBehavior": (party.SessionGroupId || party.BookedSessions?.length) ? BookingBehavior.ClassOrSession : party.SpecialMealId ? BookingBehavior.PrivateLesson : BookingBehavior.OpenBooking,
      // "BookingBehavior": party.isOpenBooking ? BookingBehavior.OpenBooking :  (party.Duration > 0 || party.isPrivateBooking) ? BookingBehavior.PrivateLesson : BookingBehavior.ClassOrSession,
      "SpecialMealId": party.SpecialMealId,
      "StartDate": new Date(party.StartDate).toDateString(),//(moment(party.StartDate).format()).split("+")[0],
      "EndDate": new Date(party.EndDate).toDateString(),//(moment(party.EndDate).format()).split("+")[0]
      "SelectedAddOns": null
    }
    if (req.BookingBehavior != BookingBehavior.ClassOrSession) {
      req["Slots"] = [{
        Time: party.SeatingTime ? party.SeatingTime : (moment(party.StartDate).format()).split("T")[0] + 'T' + party.StartTime.toLocaleTimeString('en-US', { hour12: false }),
        LockId: null,
        Type: 3,
        DurationInMinutes: party.Duration
      }];
      req["Slot"] = {
        Time: party.SeatingTime ? party.SeatingTime : (moment(party.StartDate).format()).split("T")[0] + 'T' + party.StartTime.toLocaleTimeString('en-US', { hour12: false }),
        LockId: null,
        Type: 3,
        DurationInMinutes: party.Duration
      };
    } else {
      req["Sessions"] = this.getSlotObj(party);
    }

    if (party.BookingContactAddonItems && party.BookingContactAddonItems.length) {
      req.SelectedAddOns = party.BookingContactAddonItems.map(addon => { return { "AddonId": addon.AddonId, "Quantity": addon.AddonCount }; });
    }

    return req;
  }

  getSlotObj(lesson) {
    return {
      "LockId": null,
      "Sessions": lesson.BookedSessionId ? [
        {
          "Date": lesson.BookedDate,
          "SessionIds": [
            (lesson.BookedSessions && lesson.BookedSessions.length > 0) ? lesson.BookedSessions[0].ActivitySessionId : lesson.BookedSessionId
          ],
          "sessions": [

          ]
        }
      ] : null,
      "SessionGroupId": lesson.SessionGroupId ? lesson.SessionGroupId : null
    }
  }





  convertReservationToStandby(request, partyId?, dialogRef?: MatDialogRef<any>) {

    let isWebReservation = false;

    if (request && request.PartySourceId) {
      isWebReservation = this.isWebReservation(request.PartySourceId);
    }
    this.subscriptions.add(this.httpService.post(`${urlConfig.reservationToStandbyUrl}${Utilities.RestaurantId()}&partyId=${partyId}`,
      request).subscribe(data => {
        if (data) {
          if (data.Payload) {
            //this.partyService.reservationType = ReservationType.Reservation;
            if (data.Payload.AutomaticRefundState && data.Payload.AutomaticRefundState == AutomaticRefundState.Succeeeded) {
              const message = `${this.ts.instant('refundFor')} ${this.cs.operationCurrency} ${data.Payload.RefundAmount} ${this.ts.instant('refundForCancellation')}`;
              this.openConfirmationDialog(data, message);
            }
            if (data.Payload.AutomaticRefundState && data.Payload.AutomaticRefundState == AutomaticRefundState.Failed) {
              const message = `${this.ts.instant('attemptedRefund')} ${this.cs.operationCurrency} ${data.Payload.RefundAmount} ${this.ts.instant('refundFailed')}`;
              this.openConfirmationDialog(data, message);
            }
            if (data.Payload && data.Payload.EmailAddress && !isWebReservation && ((this.cs.settings.value.General.HostCancellationEmailSendBehavior == PartyEmailSendBehavior.Prompt))) {
              // this.openConfirmationDialog(data, null, null, ReservationEmailNotificationType.Cancelled);
              this.showEmailNotification(data, ReservationEmailNotificationType.Cancelled);
            }
          }
          if (dialogRef) {
            dialogRef.close();
          }
        }
      }));
  }
  isStandbyData(data: any) {
    return (this.reservationType == ReservationType.StandbyParties || this.reservationType == ReservationType.PrivateLessonBooking) && data;
  }

  postFeedBackMessage(requestedPayload) {
    const postURL = `${urlConfig.UpdateFeedback}?restaurantId=${Utilities.RestaurantId()}`;
    return this.httpService.post(postURL, requestedPayload);
  }

  getCountryId(code) {
    if (code) {
      let countryId = this.cs.settings.value.Countries.find(country => country.CountryPhoneCode == code)?.Id;
      return countryId ? countryId : this.cs.settings.value.Countries.find(country => country.Name == "USA").Id;
    }
  }

  checkInOpenBooking(request) {
    const seatingInfo = {
      StandaloneTableIds: request.TableIds,
      ServerIdForNonAssignedTables: null
    };
    this.httpService.post(urlConfig.CheckInOpenBookingURL + '?restaurantId=' + Utilities.RestaurantId() + '&partyId=' + request.Id + '&isSeatOrMoveParty=' + true + '&isAssignTable=' + false + '&isChargable=' + false, seatingInfo).subscribe(response => {
      let sessionObject;
      sessionObject = this.Parties$.value.find(data => data.Id == request.Id);
      if (!sessionObject) {
        sessionObject = this.StandbyParties$.value.find(data => data.Id == request.Id)
      }
      let isRetailEnabled = Utilities.isRetailEnabledPropertyWithToken(this.cs.settings.value.General.RetailIntegrationDTO);
      if (response.Payload && response.Payload.updateFinancialEffect) {
        this.Operations = Operations.checkIn;
        this.selectedParty$.next(request);
        this.retailservice.oldReservation = _.cloneDeep(request);
        if (!response.Payload?.AdditionalCharge) {
          response.Payload.updateFinancialEffect.CollectAdditionalCharge = false;
          this.SetRetailItem(null, request.Id, null, response.Payload.updateFinancialEffect, null, response.Payload.updateFinancialEffect, null)
        }
      }

      if (response.Payload && response.Payload.Url) {
        Utilities.openPurchaseForm(response.Payload.Url);
      }
      if (response.Payload?.AdditionalCharge > 0) {

        this.additionalChargeInfo(response.Payload.PartyId, response.Payload, true, response.Payload.updateFinancialEffect);
      }

      if (response.Payload && response.Payload.PaymentState && response.Payload.PaymentState == 1 && response.Payload.PaymentAmount > 0 && response.Payload.PaymentType == FinancialEffectType.PartialRefund) {// Refund
        if (isRetailEnabled)
          this.openConfirmationDialog(response, this.ts.instant('redirectretailToRefund', {paymentAmount: this.cs.settings.value.General.OperationCurrency + response.Payload.PaymentAmount.toFixed(2)}), null, null, null, null, sessionObject);
        else
          this.openConfirmationDialog(response, this.ts.instant('refundPaymentSuccessProcessCheckin', {paymentAmount:  this.cs.settings.value.General.OperationCurrency + response.Payload.PaymentAmount.toFixed(2)}) + (request.SessionGroupId > 0 ? this.ts.instant("classText") + this.getActivityName(request.SpecialMealId) : this.ts.instant('sessionMsg')), null, null, null, null, sessionObject);
      }
      else if (response.Payload && response.Payload.PaymentState && response.Payload.PaymentState == 1 && response.Payload.PaymentAmount > 0 && response.Payload.PaymentType == FinancialEffectType.PartialCharge) {// Charge
        if (isRetailEnabled)
          this.openConfirmationDialog(response, this.ts.instant('redirectRetailForPendingAmountCollectText', {paymentAmount: this.cs.settings.value.General.OperationCurrency + response.Payload.PaymentAmount.toFixed(2)}), null, null, null, null, sessionObject);
        else
          this.openConfirmationDialog(response, this.ts.instant('attendeeChargeForPendingCheckin', {paymentAmount: this.cs.settings.value.General.OperationCurrency + response.Payload.PaymentAmount.toFixed(2)}) + (request.SessionGroupId > 0 ? this.ts.instant("classText") + this.getActivityName(request.SpecialMealId) : this.ts.instant('sessionMsg')), null, null, null, null, sessionObject);
      }
      else if (this.Operations != Operations.checkIn) {
        this.openConfirmationDialog(response, this.ts.instant('attendeeCheckinSuccessText') + (request.SessionGroupId > 0 ? this.ts.instant('classText') + this.getActivityName(request.SpecialMealId) : this.ts.instant('sessionMsg')), null, null, null, null, sessionObject);
      }
      if (response.State === OperationResultState.Success) {
        this.LastPartyRequest = request;
        const isIGIntegrated = this.cs.settings.value.General.IgIntegrationDTO.IsEnabled;
        if (isIGIntegrated) {
          let IgServerId = this.cs.settings.value.Servers.find(x => x.IgServerId != null)?.IgServerId;
          if (IgServerId) {
            this.LastPartyServerIds = [IgServerId];
          }
          this.openCheck(response);
        }
      }
    });
  }

  validateAvailabilityForCheckInWithDues(party) {
    let paymentState = [
      PartyPrepaymentState.PrepaymentRequired,
      PartyPrepaymentState.PrepaymentInProgress,
      PartyPrepaymentState.PrepaymentFailed,
      PartyPrepaymentState.PartialPrepayment,
      PartyPrepaymentState.RefundFailed,
      PartyPrepaymentState.PaymentDuePending,
      PartyPrepaymentState.RefundDuePending
    ]
    return paymentState.includes(party.PrepaymentState);
  }

  /***** Clear Party *****/
  checkOutOpenOrPrivateBooking(party) {

    this.CalculateAdditionalCharge(party.Id).subscribe(response=>{
      this.ActualDepartureTime = response.Payload?.ActualPartyDepartureTime;
      if(response.Payload && response.Payload.TotalAdditionalRate > 0){

        if (Utilities.isUserHasAccessToRetail(Utilities.RestaurantId())) {
          this.showAdditionalChargePopup(party, response.Payload);
        }
        else{
          this.utilities.showAlert(this.ts.instant('userPermissionMsg'), AlertType.Info, ButtonType.Ok);
        }
      }
      else{
        this.checkOutBooking(party, false);
      }
    })
  }

  checkOutBooking(party, applyAdditionalCharge){
    if (this.validateAvailabilityForCheckInWithDues(party)) {
      this.checkOutWithDues(party, applyAdditionalCharge);
    } else {
      this.checkOutWithoutDues(party, applyAdditionalCharge);
    }
  }

  
  checkOutWithDues(bookingDetail, applyAdditionalCharge) {
    if (Utilities.isUserHasAccessToRetail(Utilities.RestaurantId())) {
      this.getFinancialDetails(bookingDetail.Id, FinancialEffectAction.CheckOut, null,null).subscribe(data => {
        console.log(data);
        this.openCheckInWithDuesPopUp(bookingDetail, data.Payload, ComponentTypes.CheckOutRatePlanSummary, applyAdditionalCharge);
      })
    }else{
      this.utilities.showAlert(this.ts.instant('userPermissionMsg'), AlertType.Info, ButtonType.Ok);
    }
  }

  chargeGuest(bookingDetail, applyAdditionalCharge) {
    if (Utilities.isUserHasAccessToRetail(Utilities.RestaurantId())) {
      // this.getFinancialEffectForCheckInWithDues(bookingDetail, FinancialEffectAction.Update, applyAdditionalCharge).subscribe(data => {
      //   console.log(data);
      //   this.openCheckInWithDuesPopUp(bookingDetail, data.Payload, ComponentTypes.ChargeGuest, applyAdditionalCharge);
      // })
      this.selectedBooking = bookingDetail;
      this.getFinancialDetails(bookingDetail.Id, FinancialEffectAction.Update,this.ratePlanObject?.RatePlan,this.ratePlanObject?.ShopItems).subscribe(data =>{
        this.openCheckInWithDuesPopUp(bookingDetail, data.Payload, ComponentTypes.ChargeGuest, applyAdditionalCharge);
      })
    }else{
      this.utilities.showAlert(this.ts.instant('userPermissionMsg'), AlertType.Info, ButtonType.Ok);
    }
  }

  checkOutWithoutDues(party, applyAdditionalCharge){
    this.getFinancialDetails(party.Id, FinancialEffectAction.CheckOut, null,null).subscribe(response => {
      if(response?.Payload?.PaymentAmount){
        this.RetailCheckoutOperation(party, response?.Payload)
      }
      else{
        this.subscriptions.add(this.checkOutOpenBooking(party.Id).subscribe((response) => {
          this.openConfirmationDialog(response, " The attendee is successfully charged for pending " + this.cs.settings.value.General.OperationCurrency + response.Payload.PaymentAmount.toFixed(2) + " and checked out of " + (party.SessionGroupId > 0 ? this.ts.instant("classText") + this.getActivityName(party.SpecialMealId) : this.ts.instant('sessionMsg')), null);
        }));
      }
    });
  }

  showAdditionalChargePopup(party, chargeInfo){
    let confirmAdditionalCharge = false;
      let totalAmount =chargeInfo.TotalAdditionalRate;
      let msg = 'The ' + 'guest has spent additional duration of ' + chargeInfo.AdditionalDuration + ' minute(s) which incurred an additional charge of total amount ' + this.cs.settings.value.General.OperationCurrency + totalAmount.toFixed(2) + ((chargeInfo.TaxAmount > 0 || chargeInfo.ServiceChargeAmount > 0) ? '(include of ' + (chargeInfo.TaxAmount > 0 ? 'tax ' : '') + (chargeInfo.ServiceChargeAmount > 0 ? 'and service charge' : '') + ((chargeInfo.TaxAmount > 0 || chargeInfo.ServiceChargeAmount > 0) ? ')' : '') : '') + '. Click Yes to capture additional charge or No to Ignore charge.';
      this.showWarningInfoPopUp(msg, ComponentTypes.AdditionalCharge, '350px', '500px', this.ts.instant('additionalCharge'))
      this.subscriptions.add(this.warningInfoDialogRef.afterClosed().subscribe(event => {
        let isRetailEnabled = Utilities.isRetailEnabledPropertyWithToken(this.cs.settings.value.General.RetailIntegrationDTO);
        //For Retail
        if (isRetailEnabled && (event == 1 || confirmAdditionalCharge)) {
            this.checkOutBooking(party, confirmAdditionalCharge)
          }
        this.UnSubscribePopUpAction(confirmCheckOutPopUpConfirmationSubscription);
      }));

      let confirmCheckOutPopUpConfirmationSubscription = this.popupService.confirmedAction$.subscribe(val => {
        if (val === ComponentTypes.AdditionalCharge) {
          confirmAdditionalCharge = true;
        }
      });

  }

  additionalChargeInfo(partyId, chargeInfo, isFromCheckIn, FinancialEffect) {
    let confirmAdditionalCharge = false;
    if (chargeInfo.AdditionalCharge > 0) {
      let totalAmount = (chargeInfo.AdditionalCharge + (chargeInfo.TaxAmount || 0) + (chargeInfo.ServiceChargeAmount || 0));
      let msg = 'The ' + (isFromCheckIn ? 'previous ' : '') + 'guest has spent additional duration of ' + chargeInfo.AdditionalDuration + ' minute(s) which incurred an additional charge of total amount ' + this.cs.settings.value.General.OperationCurrency + totalAmount.toFixed(2) + ((chargeInfo.TaxAmount > 0 || chargeInfo.ServiceChargeAmount > 0) ? '(include of ' + (chargeInfo.TaxAmount > 0 ? 'tax ' : '') + (chargeInfo.ServiceChargeAmount > 0 ? 'and service charge' : '') + ((chargeInfo.TaxAmount > 0 || chargeInfo.ServiceChargeAmount > 0) ? ')' : '') : '') + '. Click Yes to capture additional charge or No to Ignore charge.';
      this.showWarningInfoPopUp(msg, ComponentTypes.AdditionalCharge, '350px', '500px', this.ts.instant('additionalCharge'))
      this.subscriptions.add(this.warningInfoDialogRef.afterClosed().subscribe(event => {
        let isRetailEnabled = Utilities.isRetailEnabledPropertyWithToken(this.cs.settings.value.General.RetailIntegrationDTO);
        //For Retail
        if (isRetailEnabled && FinancialEffect) {
          if (confirmAdditionalCharge) {
            FinancialEffect.CollectAdditionalCharge = true;
            //this.SetRetailItem(null, partyId, null, FinancialEffect, null, FinancialEffect, null);
            let newamount = FinancialEffect && FinancialEffect.PartyPaymentType == 9 ? -FinancialEffect.PaymentAmount : FinancialEffect.PaymentAmount;
            this.RetailAdditionalChargeOperation(partyId, newamount, FinancialEffect, false, FinancialEffect);
          } else {
            if (FinancialEffect.PartyPaymentType == FinancialEffectType.PartialCharge
              || FinancialEffect == FinancialEffectType.PartialRefund) {
              FinancialEffect.CollectAdditionalCharge = false;
              this.SetRetailItem(null, partyId, null, FinancialEffect, null, FinancialEffect, null);
            }
            else {
              this.AdditionalChargeParty(partyId, false).subscribe(response => {
                this.resultupdate$.next(true);
              });
            }
          }
        } else {
          //For Authorize
          if (confirmAdditionalCharge) {
            if (chargeInfo.showIframe) {
              this.openIframeForAdditionalCharge(chargeInfo);
            }
            else {
              this.AdditionalChargeParty(partyId, true).subscribe(response => {
                if (response.Payload && response.Payload.PaymentResult && response.Payload.PaymentResult == PartyPaymentResult.Success && response.Payload.PaymentAmount > 0) {
                  this.openConfirmationDialog(response, "An additional charge of " + this.cs.settings.value.General.OperationCurrency + response.Payload.PaymentAmount.toFixed(2) + " has been successfully charged for the party", null);
                }
              });
            }
          }
        }
        this.UnSubscribePopUpAction(confirmCheckOutPopUpConfirmationSubscription);
      }));

      let confirmCheckOutPopUpConfirmationSubscription = this.popupService.confirmedAction$.subscribe(val => {
        if (val === ComponentTypes.AdditionalCharge) {
          confirmAdditionalCharge = true;
        }
      });
    }
  }

  openIframeForAdditionalCharge(additionalChargeInfo) {
    let cancelText = '';
    //let title = 'Confirm Booking';
    let noShowSet = false;
    let confirmCheckin = false;
    let updateText = '';
    let showAlert = false;
    let noShowFeePopUp = false;

    // const popUpMessage = [{
    //   confirmationMessage: message, dialogTitle: title, showAlert: showAlert, ListOfItem: data
    // }];

    const componentDetails: ComponentDetails = {
      componentName: AdditionalRatePaymentIframeComponent,
      dimensionType: 'small',
      popupType: 'action',
      popUpDetails: {
        isStepper: false,
        eventName: 'notifyParent'
      },

      popupInput: additionalChargeInfo,
      popupTitle: this.ts.instant('additionalCharge')
    };

    this.additionalChargePaymentIframeDialogRef = this.dialog.open(CustomPopupComponent, {
      disableClose: true,
      width: '800px',
      height: '800px',
      data: {
        title: this.ts.instant('additionalCharge'),
        update: updateText,
        cancel: cancelText,
        componentDetails,
        from: ComponentTypes.AdditionalCharge,
        back: false,
        standalone: true,
        showAlert: false
      }
    });
  }

  getActivityName(activityId): string {
    return this.cs.settings.value.SpecialMeals.find(activity => activity.Id == activityId)?.Name;
  }

  checkInPrivateLessonBooking(request) {
    const seatingInfo = {
      StandaloneTableIds: request.TableIds,
      ServerIdForNonAssignedTables: null
    };
    this.httpService.post(urlConfig.CheckInPrivateLessonBookingURL + '?restaurantId=' + Utilities.RestaurantId() + '&partyId=' + request.Id + '&isSeatOrMoveParty=' + true + '&isAssignTable=' + false + '&isChargable=' + false, seatingInfo).subscribe(data => {
      if (data.Payload) {
        Utilities.openPurchaseForm(data.Payload.Url);
      }
      if (data.State === OperationResultState.Success) {
        this.openCheck(data);
      }
    });
  }

  showExistingReservationPopup(fromType: ComponentTypes, reservationDto, sessions, dialogRef, reservationDate, proceed?) {
    sessions = sessions.filter((messageData) => messageData.Type == ValidationMessageType.ConsentMessages);
    sessions = sessions.map(messageData => messageData?.Message || messageData);
    let proceedBooking = false;

    if (sessions && sessions.length) {
      let msg = this.ts.instant('existingBookingInfoMessage');
      this.showWarningInfoPopUp(msg, ComponentTypes.ExistngReservationWarning, '450px', '750px', sessions)
      this.warningInfoDialogRef.afterClosed().subscribe(event => {
        if (proceed || (proceedBooking && fromType == ComponentTypes.CreateCartBooking)) {
          this.bookCart(reservationDto, true);
        } else {
          if (proceedBooking) {
            this.proceedBooking(fromType, reservationDto, dialogRef, reservationDate);
          }
        }
        this.UnSubscribePopUpAction(confirmCheckInPopUpConfirmationSubscription);
      });

      let confirmCheckInPopUpConfirmationSubscription = this.popupService.confirmedAction$.subscribe(val => {
        if (val === ComponentTypes.ExistngReservationWarning) {
          proceedBooking = true;
          this.warningInfoDialogRef.close();
        }
      });
    }
  }


  lockTables(cartId) {
    this.ratePlan = null;
    if (this.BookingBehaviour == BookingBehavior.ClassOrSession) {
      let { ActivityId, FromDate, Slots, CoverTypes, BookingTypes, Location, StaffId, BookingSize, SessionGroupId, AllowStandBy } = this.sessionBookingData;
      const classType = this.sessionBookingData.ClassType;
      let lockRequest = {
        ActivityId: ActivityId,
        StartDate: FromDate,
        BookingSize: BookingSize,
        ClassType: classType,
        lockIdToIgnore: this.slotLockId || null,
        Sessions: classType == ClassType.Class ? [] : Slots,
        SessionGroupId: classType == ClassType.Class ? SessionGroupId : null,
        ReservationIdToIgnore: null,
        IsForStandbyReservations: AllowStandBy || false
      } as LockSessionRequestDTO;
      this.subscriptions.add(
        this.api.lockSessions(lockRequest, AllowStandBy).subscribe(response => {
          this.lockData = response.Payload;
          this.slotLockId = this.lockData?.SlotLockIdDTO?.Id;
          this.TotalCartBookingObj.forEach(item => {
            if (item.Id == cartId) {
              item.SlotLockResult = this.lockData;
            }
          });

          this.proceedCard(cartId);
        }));
    } else if (this.BookingBehaviour == BookingBehavior.PrivateLesson) {

      let { ActivityId, Slots, CoverTypes, BookingTypes, Location, LocationName, BookingSize, StaffId } = this.privateLessonBookingData;
      let slots = Slots.map(slot => {
        return {
          "LocalTime": slot.LocalTime,
          "PartySize": BookingSize,
          "SeatingAreaId": null,
          "SeatingTypeId": null,
          "IsCommunalTable": false,
          "FromWebsite": true,
          "DurationInMinutes": slot.Duration,
          "TableIds": [Location]
        }
      });
      if (slots.length) {
        this.subscriptions.add(this.api.lockTables(slots, true).subscribe(response => {

          this.isSlotLocked = true;
          const lockData = Object.values(response.Payload) as any[];
          this.lockData = response.Payload;
          this.slotLockIds = lockData?.map(x => {
            if (x?.SlotLockIdDTO?.Id) {
              return x?.SlotLockIdDTO?.Id;
            }
            else {
              return 0;
            }
          });
          this.proceedCard(cartId);
        }));

      }
    } else if (this.BookingBehaviour == BookingBehavior.OpenBooking) {


      let { Slots, CoverTypes, BookingTypes, Location, BookingSize } = this.openBookingData;
      let slots = Slots.map(slot => {
        return {
          "LocalTime": slot.LocalTime,
          "PartySize": BookingSize,
          "SeatingAreaId": null,
          "SeatingTypeId": null,
          "IsCommunalTable": false,
          "FromWebsite": true,
          "DurationInMinutes": slot.Duration,
          "ReservationIdToIgnore": null,
          "TableIds": [Location]
        }
      });

      if (slots.length) {
        this.subscriptions.add(this.api.lockTables(slots).subscribe(response => {
          const lockData = Object.values(response.Payload) as any[];
          this.lockData = response.Payload;
          this.isSlotLocked = true;
          this.slotLockIds = lockData?.map(x => {
            if (x?.SlotLockIdDTO?.Id) {
              return x?.SlotLockIdDTO?.Id;
            }
            else {
              return 0;
            }
          });
          this.proceedCard(cartId);
        }));
      }
    }
  }

getLockIds(){

}

  addBookingToCart(reqdata, ignoreBookingValidation, restaurantDate, dialogRef, isfrombuynow, cartSubscription) {
    if (reqdata.SecondaryContactsList && reqdata.SecondaryContactsList !== null) {
      reqdata.SecondaryContactsList = reqdata.SecondaryContactsList.map(contact => {
        contact.isNew = false;
        return contact;
      });
    }
    this.isfrombuynow = isfrombuynow;
    if(!reqdata.ExpireAt && this.lockData){
      reqdata.ExpireAt = this.lockData.hasOwnProperty("ExpiresAt") ? this.lockData.ExpiresAt : Object.values(this.lockData)[0]?.ExpiresAt;
    }
    if(this.openBookingData){
      reqdata['BookingContacts'] = this.openBookingData.BookingContacts;
    }
    
    var request = { CartItemDetail: reqdata, CartGroupName:reqdata.CartGroupName ,LockIds : this.slotLockIds?.filter(lockId => lockId)};
    request = {...request,...this.ratePlanObject}
    this.selectedPackage = null;
    this.selectedPackageId = null;

    //rateplan details
    if(!reqdata.rateSummary){
      reqdata.rateSummary = {
        Amt: 0,
        TaxAmt: 0,
        SCAmt: 0,
        TaxOnSCAmt: 0
      }
    }

    this.subscriptions.add(this.httpService.post(`${urlConfig.addBookingtoCart}?propertyid=${Utilities.RestaurantId()}&hostid=${Utilities.getHostId()}&merchantid=${this.cs.settings.value.General.MerchantId}&fromExternalSystem=${this.cs.isIframeEnabled}`,
      request).subscribe(data => {
        if(this.cs.isIframeEnabled){
          this.subscriptions.add(this.getSyncContact(reqdata.Contact?.Id || data?.Payload?.ContactId || null).subscribe(contactDetail => {
            this.bookingHandler(data , reqdata, ignoreBookingValidation, restaurantDate, dialogRef, isfrombuynow, cartSubscription , contactDetail.Payload , this.ratePlanObject);
          }))
        }else{
          this.bookingHandler(data , reqdata, ignoreBookingValidation, restaurantDate, dialogRef, isfrombuynow, cartSubscription, null , this.ratePlanObject);
        }
      }));
  }

  bookingHandler(data,reqdata, ignoreBookingValidation, restaurantDate, dialogRef, isfrombuynow, cartSubscription , syncContact , ratePlanObject){
    if (data.ValidationMessages.length > 0) {
      this.openConfirmationDialog(data, data.ValidationMessages[0].Message,);
      if (cartSubscription) {
        cartSubscription.unsubscribe();
      }
    }
    else {
      reqdata.PropertyId = Utilities.RestaurantId();//  this.appService.restaurantId;
      let cartId = data.Payload?.CartId ? data.Payload.CartId : data.Payload;
      let contactId = data.Payload?.ContactId ? data.Payload.ContactId : null;
      if(contactId){
        reqdata.Contact.Id = contactId;
      }
      if(syncContact?.CommonGuestProfileUUID){
        reqdata.Contact.CommonGuestProfileUUID = syncContact.CommonGuestProfileUUID;
      }
      if(syncContact?.GuestProfileServiceId){
        reqdata.Contact.guestProfileServiceId = syncContact.GuestProfileServiceId;
      }
      if(data.Payload?.FinancialEffectId){
        reqdata.FinancialEffectId = data.Payload?.FinancialEffectId;
      }
      

      var cartData = {
        CartItemDetail: {...reqdata, ...this.lockData},
        Id: cartId,
        PropertyId: Utilities.RestaurantId(),
        HostId: Utilities.getHostId(),
        MerchantId: this.cs.settings.value.General.MerchantId,
        SlotLockResult: this.lockData,
        LockSlots: [],
        selected: false,
        CartGroupName: reqdata.CartGroupName, 
      }
      cartData = { ...cartData , ...ratePlanObject}

      if (cartData.SlotLockResult && cartData.SlotLockResult.SlotLockIdDTO && isfrombuynow) {
        cartData.LockSlots = [cartData.SlotLockResult.SlotLockIdDTO];

      } else if (cartData.SlotLockResult && isfrombuynow) {
        cartData.LockSlots = Object.values(cartData.SlotLockResult).filter(slotLock => slotLock && slotLock['SlotLockIdDTO']).map(slotLock => slotLock['SlotLockIdDTO']);
      }
      this.TotalCartBookingObj.push(cartData);
      this.cartCount = this.TotalCartBookingObj.length;
      this.popupService.restrictCloseDialog = false;
      if (isfrombuynow) {
        if (this.lockData || reqdata.IsForStandbyReservations) {
          this.isfromBuyNowCartId = data.Payload?.CartId;
          this.proceedCard(data.Payload?.CartId);
        }
        else {
          this.isfromBuyNowCartId = data.Payload?.CartId;
          this.lockTables(data.Payload?.CartId);
        }
        if (Utilities.isRetailEnabledProperty(this.cs.settings.value.General.RetailIntegrationDTO)) {
          dialogRef.close();
          this.popupService.closeDialog$.next();
          if (cartSubscription) {
            cartSubscription.unsubscribe();
          }
        }
      } else {
        if(this.cs.isIframeEnabled){
          this.proceedCard(data.Payload?.CartId , true);
        }
        dialogRef.close();
        this.popupService.closeDialog$.next();
        let _confirmationMessages  = Utilities.getConfirmationMessage(data, this.ts.instant(Labels[Labels.reservationaddedfromCart]));
        let _title = Utilities.getConfirmationDialogTitle(null);
        this.openAppConfirmationDialog(data, _title, _confirmationMessages, 'action', 'Ok', '');

        if (cartSubscription) {
          cartSubscription.unsubscribe();
        }
      }

    }
  }

  getCartExpireTime(){
    let cartExpireTime = null;
    for (let cartIndex = 0; cartIndex < this.TotalCartBookingObj.length; cartIndex++) {
      const element = this.TotalCartBookingObj[cartIndex]?.CartItemDetail;
      if(element.ExpireAt && (moment(element.ExpireAt).diff(moment(Utilities.getRestaurantDateTime(this._settings.General.DaylightDelta)), "seconds") > 0)){
        cartExpireTime = element.ExpireAt;
        break;
      }
    }
    return cartExpireTime;
  }

  updateCart(reqdata, cartId, ignoreBookingValidation, restaurantDate, dialogRef, cartSubscription) {
    if (reqdata.SecondaryContactsList && reqdata.SecondaryContactsList !== null) {
      reqdata.SecondaryContactsList = reqdata.SecondaryContactsList.map(contact => {
        contact.isNew = false;
        return contact;
      });
    }
    var request = { CartItemDetail: reqdata, Id: cartId, CartGroupName: reqdata.CartGroupName  };
    request = {...request,...this.ratePlanObject}
    let selectedCartItem = this.TotalCartBookingObj.find(cartObj => cartObj.Id == cartId);
    if(selectedCartItem){
      selectedCartItem.RatePlan = _.cloneDeep(this.ratePlanObject?.RatePlan)
    }
    if(!reqdata.rateSummary){
      reqdata.rateSummary = {
        Amt: 0,
        TaxAmt: 0,
        SCAmt: 0,
        TaxOnSCAmt: 0
      }
    }
    
    this.subscriptions.add(this.httpService.post(`${urlConfig.updateCartItems}?propertyid=${Utilities.RestaurantId()}&hostid=${Utilities.getHostId()}&merchantid=${this.cs.settings.value.General.MerchantId}`,
      request).subscribe(data => {
        this.editCartId = cartId;
        if (data.ValidationMessages.length > 0) {
          this.openConfirmationDialog(data, data.ValidationMessages[0].Message,);
        }
        else {
          this.popupService.restrictCloseDialog = false;
          this.openConfirmationDialog(data, this.ts.instant(Labels[Labels.reservationupdatedtoCart]), '');
          dialogRef.close();
          this.popupService.closeDialog$.next();
          if (cartSubscription) {
            cartSubscription.unsubscribe();
          }
          setTimeout(() => {
            const Index = this.TotalCartBookingObj.findIndex(x => x.Id === cartId);
            if (Index > -1) {
              reqdata.Contact.Id = data.Payload?.ContactId ? data.Payload?.ContactId : reqdata.Contact.Id ;
              reqdata.FinancialEffectId = data.Payload?.FinancialEffectId || null;
              this.TotalCartBookingObj[Index].CartItemDetail = reqdata;
              this.TotalCartBookingObj[Index].SlotLockResult = reqdata?.SlotLockResultDTO;
            }
            this.cartCount = this.TotalCartBookingObj.length;
            this.isCartUpdated = Utilities.getRandomDecimal();
            if(this.cs.isIframeEnabled){
              this.proceedCard(cartId, true , true);
            //  this.VCartService.createCart(this.TotalCartBookingObj.find(x => x.Id === cartId) , true);    
            }
          }, 500)
        }
      }));
  }

  proceedCard(ItemIds , restrictBooking?:boolean , isEditCart? : boolean) {


    this.TotalCartBookingObj.forEach(item => {
      if (item.Id == ItemIds) {
        item.selected = true;
      } else {
        item.selected = false;
      }
    });
    let cartData = this.TotalCartBookingObj.filter(({ selected }) => selected);
    let cartIds = this.TotalCartBookingObj.filter(({ selected }) => selected).map(({ Id }) => Id);
    if (cartData && cartData.length) {
      let reservationAttemptDto = []
      cartData.forEach(cartItem => {
        reservationAttemptDto.push({
          "PropertyId": cartItem.PropertyId,
          "PartySize": cartItem.CartItemDetail.Size,
          "ActivityId": cartItem.CartItemDetail.SpecialMealId,
          "InstructorIds": cartItem.CartItemDetail.InstructorIds,
          "SeatingTypeIds": cartItem.CartItemDetail.SeatingTypeIds,
          "TableIds": cartItem.CartItemDetail.TableIds,
          "Slots": cartItem.CartItemDetail.Slots?.map(slotData => { return { "LocalTime": slotData.Time, "Duration": slotData.DurationInMinutes } }),
          "Sessions": cartItem.CartItemDetail.Sessions,
          "CoverTypes": cartItem.CartItemDetail.CoverTypes,
          "BookingTypes": cartItem.CartItemDetail.BookingTypes,
          "BookingBehavior": cartItem.CartItemDetail.BookingBehavior,
          "NegotiatedAmount": this.isfromBuyNowAmountNegotiated ? this.sessionBookingDataSaveObj.NegotiatedAmount : cartItem.CartItemDetail.NegotiatedAmount,
          "MemberType": cartItem.CartItemDetail.MemberType,
          "CartId": cartItem.Id,
          "StartDate": cartItem.CartItemDetail.StartDate,
          "EndDate": cartItem.CartItemDetail.EndDate,
          "AddOns": cartItem.CartItemDetail.SelectedAddOns
        })
      })
      if (this.bookingPaymentType == PaymentMethod.Prepayment && !restrictBooking) {
        this.prePaymentBookingOperation(reservationAttemptDto, ItemIds, cartIds);
      } else if (this.IsSkipPayment && !restrictBooking) {
        this.skipPaymentOperation(ItemIds);
      }else if(restrictBooking){
        this.buildExternalBookingRequest(reservationAttemptDto, cartData[0] , isEditCart);
      }
    }
  }
  buildExternalBookingRequest(reservationAttemptDto, cartData , isEditCart?:boolean){
    if(cartData.CartItemDetail.BookingBehavior !== null && cartData.CartItemDetail.BookingBehavior !== undefined){
      this.subscriptions.add(this.proceedCartBookingForMultipleProperties(reservationAttemptDto).subscribe(data => {

        cartData.CartItemDetail['ReservationAttemptId'] =  cartData.CartItemDetail.PaymentMethod == PaymentMethod.Prepayment ?  data.Payload?.ResponseDTOs[0]?.ReservationAttemptId : null;
       cartData.CartItemDetail['OverallAmount'] =  data.Payload?.PaymentAmount;
         this.VCartService.createCart(cartData , isEditCart);
      }));
    }else{
      let slot = this.reservationFormGroup.get('selectedTime').value;
      let diningAttemptDto = [
        {
          BookingBehavior:0,
          PartySize: cartData.CartItemDetail.Size,
          // SpecialMealId: cartData.CartItemDetail?.SpecialMealId || null,
          ActivityId:cartData.CartItemDetail?.SpecialMealId || null,
          IsCommunalTable: false,
          PropertyId: cartData.PropertyId,
          IsMemberActive:false,
          FromWebsite: false,
          CartId: cartData?.Id,
          SeatingTypeIds:[],
          Slots:[{
            LocalTime: slot.DateTime,
            Duration:0,
          }]
        }
      ]
      this.subscriptions.add(this.proceedCartBookingForMultipleProperties(diningAttemptDto).subscribe(data => {

        cartData.CartItemDetail['ReservationAttemptId'] =  data.Payload?.ResponseDTOs[0]?.ReservationAttemptId;
        cartData.CartItemDetail['OverallAmount'] =  data.Payload?.PaymentAmount;
          this.VCartService.createCart(cartData , isEditCart);
       }));
    }
  }
  skipPaymentOperation(ItemIds) {
    // this.TotalCartBookingObj.forEach(item => {
    //   let items = this.ReservationAttemptData.filter(x => x.CartId == item.Id)[0];
    //   if (items) {
    //     item.TotalPackageDiscountPercent = items.TotalPackageDiscountPercent ? items.TotalPackageDiscountPercent : null;
    //     item.TotalPackageDiscountedAmount = items.TotalPackageDiscountedAmount ? items.TotalPackageDiscountedAmount : null;
    //     item.PackageId = this.selectedPackage ? this.selectedPackage.Id : null;
    //     item.RatePlanTotal = items.RatePlanTotal;
    //     item.Slots = items.Slots;
    //   }
    //   if (item.Id == ItemIds) {
    //     item.selected = true;
    //   }
    // });
    this.reservationConfirm();
  }
  prePaymentBookingOperation(reservationAttemptDto, ItemIds, cartIds) {
    this.subscriptions.add(this.proceedCartBookingForMultipleProperties(reservationAttemptDto).subscribe(data => {

      this.ReservationAttemptData = data.Payload.ResponseDTOs;
      this.MultipleReservationAttemptData = data.Payload;
      this.TotalCartBookingObj.forEach(item => {
        let items = this.ReservationAttemptData.filter(x => x.CartId == item.Id)[0];
        if (items) {
          item.TotalPackageDiscountPercent = items.TotalPackageDiscountPercent ? items.TotalPackageDiscountPercent : null;
          item.TotalPackageDiscountedAmount = items.TotalPackageDiscountedAmount ? items.TotalPackageDiscountedAmount : null;
          item.PackageId = this.selectedPackage ? this.selectedPackage.Id : null;
          item.RatePlanTotal = items.RatePlanTotal;
          item.Slots = items.Slots;
        }
        if (item.Id == ItemIds) {
          item.selected = true;
        }
      });
      if (data.Payload.ShouldCreditCardBeEntered) {
        if (this._settings.General.RetailIntegrationDTO.IsEnabled && this._settings.RetailItems && this._settings.RetailItems.length > 0) {
          this.Operations = 1;
          this.RetailCreateOperation(cartIds);
          //this.SetRetailItem(cartIds);
          // this._as.showCartItem = false;
        }
        else {
          if (data.Payload.CreditCardIframeUrl) {
            /* this.showIframe = true;
            this.iframeURL = this.sanitizer.bypassSecurityTrustResourceUrl(data.Payload.CreditCardIframeUrl + "&origin=" + window.location.origin)
            this.iframeURL = this.iframeURL.changingThisBreaksApplicationSecurity ? this.iframeURL.changingThisBreaksApplicationSecurity : this.iframeURL;
            this.PaymentGateway = data.Payload.PaymentGateway;
            this._as.isCartPaymentInProgress = true; */
            this.updateTabs();
            this.popupService.nextEvent$.next(true);
          }
          else {
            /*  this.showMessage(this.ts.instant('SorryForPaymentOffline')); */
          }
        }
      }
      else {
        this.reservationConfirm();
      }
    }));
  }
  reservationConfirm() {

    var selectedCartItems = this.TotalCartBookingObj.filter(({ selected }) => selected);;
    var CartBookingInputList = [];
    selectedCartItems.forEach(item => {

      var obj = {
        CartItemId: item.Id,
        BookingType: item.CartItemDetail.BookingBehavior,
        LockId: 0,
        SlotTime: '',
        ReservationAttemptId: 0,
        PropertyId: item.PropertyId,
        EnableTicketPrinting: false,
        TicketPerPerson: false,
        IsForStandbyReservations: item.CartItemDetail.IsForStandbyReservations || false,
        PackageDiscountPercent: item.TotalPackageDiscountPercent ? item.TotalPackageDiscountPercent : null,
        PackageDiscountedAmount: item.TotalPackageDiscountedAmount ? item.TotalPackageDiscountedAmount : null,
        PackageId: item.PackageId ? item.PackageId : null,
        FinancialEffectId: this.FinancialEffectId,
        PaymentMethod: this.bookingPaymentType,
        RetailCardTokenId: this.RetailCardTokenId,
        LanguageId: Number(sessionStorage.getItem('languageId')) || globals.DEFAULT_LANGUAGE_ID
      }
      if (item.CartItemDetail && item.CartItemDetail.SpecialMealId && BookingBehavior.OpenBooking !== item.CartItemDetail.BookingBehavior) {
        let { EnableTicketPrinting, TicketPerPerson, IsForStandbyReservations } = this._settings.SpecialMeals.find(meal => meal.Id === item.CartItemDetail.SpecialMealId);
        obj.EnableTicketPrinting = EnableTicketPrinting;
        obj.TicketPerPerson = TicketPerPerson;
        obj.IsForStandbyReservations = IsForStandbyReservations;
      }
      if (item.SlotLockResult && (item.SlotLockResult.SlotLockIdDTO || this.IsSkipPayment)) {
        obj.LockId = item.SlotLockResult.SlotLockIdDTO ? item.SlotLockResult.SlotLockIdDTO.Id : null;
        obj.ReservationAttemptId = this.getReservationAttemptId(item.Id);
        CartBookingInputList.push(obj);
      } else if (item.SlotLockResult && (BookingBehavior.OpenBooking == item.CartItemDetail.BookingBehavior || (BookingBehavior.PrivateLesson == item.CartItemDetail.BookingBehavior && !item.CartItemDetail.IsForStandbyReservations))) {
        item.LockSlots = Object.keys(item.SlotLockResult).forEach(slotLockKey => {
          let tempObj = { ...obj };
          tempObj.SlotTime = slotLockKey;
          tempObj.ReservationAttemptId = this.getOpenBookingReservationAttemptId(item.Id, slotLockKey);
          tempObj.LockId = item.SlotLockResult[slotLockKey]?.SlotLockIdDTO?.Id;
          CartBookingInputList.push(tempObj);
        });
      }
      else if (!item.SlotLockResult?.SlotLockIdDTO && item.CartItemDetail.IsForStandbyReservations && BookingBehavior.OpenBooking !== item.CartItemDetail.BookingBehavior) {
        obj.LockId = item.SlotLockResult.SlotLockIdDTO ? item.SlotLockResult.SlotLockIdDTO.Id : null;
        obj.ReservationAttemptId = this.getReservationAttemptId(item.Id);
        CartBookingInputList.push(obj);
      }
      else if (item.CartItemDetail && item.CartItemDetail.IsForStandbyReservations) {
        if (!item.SlotLockResult && item.LockSlots?.length) {
          item.CartItemDetail.Slots?.forEach(slot => {
            let tempObj = { ...obj };
            tempObj.SlotTime = slot.Time;
            tempObj.ReservationAttemptId = this.getReservationAttemptId(item.Id);
            CartBookingInputList.push(tempObj);
          });
        }else if(BookingBehavior.OpenBooking === item.CartItemDetail.BookingBehavior){
          let tempObj = { ...obj };
          CartBookingInputList.push(tempObj);
        }
      }
    });


    this.bookCart(CartBookingInputList, false);
  }
  getReservationAttemptId(cartId) {
    if (this.IsSkipPayment) {
      return null
    } else {
      return this.ReservationAttemptData.find(item => +item.CartId === cartId)?.ReservationAttemptId;
    }
  }

  getOpenBookingReservationAttemptId(cartId, key) {
    if (this.IsSkipPayment) {
      return null
    } else {
      return this.ReservationAttemptData.find(item => +item.CartId === cartId)?.Slots?.find(slot => slot.LocalTime === key)?.ReservationAttemptId;
    }
  }


  bookCart(CartBookingInputList, ignoreBookingValidation) {

    this.subscriptions.add(this.bookCartItemsForMultipleProperties(CartBookingInputList, ignoreBookingValidation).subscribe(response => {
      this.popupService.closeDialog$.next();
      if (response.State == OperationResultState.ConsentMessages && response.ValidationMessages?.length) {
        this.showExistingReservationPopup(ComponentTypes.CreateCartBooking, CartBookingInputList, response.ValidationMessages, null, null, false)
        return;
      }

      this.bookingConfirmationData = response.Payload[0];
      let anyConfirmedReservations = response.Payload[0]?.filter(party => party.ConfirmedSessionsForCart && party.ConfirmedSessionsForCart.length && party.ConfirmedSessionsForCart.filter(p => p.SessionType == PartyType.Reservation)?.length);
      let enableTickets = CartBookingInputList.filter((ticket) => ticket.EnableTicketPrinting == true && ticket.IsForStandbyReservations == false);
      if (enableTickets && enableTickets.length > 0 && anyConfirmedReservations && anyConfirmedReservations.length) {
        this.ShowTicket();
      } else {

        const _confirmationMessages = Utilities.getConfirmationMessage(response, this.ts.instant('CartBookSuccessMessageText'), null, null);
        let _title = Utilities.getConfirmationDialogTitle(null);

        this.openAppConfirmationDialog(response, _title, _confirmationMessages, 'action', this.ts.instant('ok'), '');
      }
      let bookedCartItems = CartBookingInputList.map(({CartItemId}) => CartItemId)
      let cartSumaryDataList = this.TotalCartBookingObj.filter(item => !bookedCartItems.includes(item.Id));
      this.TotalCartBookingObj = cartSumaryDataList.filter(item => item);
      this.cartCount = cartSumaryDataList.length;

      // this.calculateCartTotal();
      if ((response.Payload[0] && response.Payload[0].length === 1)) {
        let propertyId = response.Payload[0][0].PropertyId;
        if (response.Payload[0][0].EmailAddress && (this.cs.propertySettings.value[propertyId]?.settings?.General.HostConfirmationEmailSendBehavior == PartyEmailSendBehavior.Prompt)) {
          let data = response;
          data.Payload = data.Payload[0][0];
          this.popupService.restrictCloseDialog = false;
          // this.openConfirmationDialog(data, null, null, ReservationEmailNotificationType.Created);

          this.showEmailNotification(data, ReservationEmailNotificationType.Created);
        }
      }
    }))
  }

  showEmailNotification(data, notificationType: ReservationEmailNotificationType, confirmedSessionId?){
    const _confirmationMessages = Utilities.getConfirmationMessage(data, null, notificationType, confirmedSessionId);
    let _title = Utilities.getConfirmationDialogTitle(notificationType);
    let _dialogRef = this.openAppConfirmationDialog(data, _title, _confirmationMessages, '', 'Cancel', this.ts.instant('sendConfirmationEmail'));

    _dialogRef.afterClosed().subscribe((dataValue) => {

      if(dataValue && dataValue.length){
        let _val = dataValue[0];
        if(_val?.partyId && _val?.email){
          this.triggerEmail = true;
          this.SendConfirmaionEmail(_val.partyId, _val.email, notificationType, _val.cancelledIds, _val.bookedSessionId, _val.classOrSessionBooking, _val.confirmedBookedSessionId).subscribe(data => {
            if (data) {
              this.reservationDialogRef ? this.reservationDialogRef.close() : '';
              this.reservationDialogRef ? this.reservationDialogRef = null : '';
            }
          });
        }
      }

      
    });
  }

  updateTabs() {
    let tabdata = [];
    if (this.BookingBehaviour == BookingBehavior.PrivateLesson) {
      tabdata = [{
        tabComponent: PrivatelessonPaymentSummaryComponent,
        tabLabel: 'Payment',
        tabIcon: 'Group-748',
        isDisabled: false
      }];
    } else if (this.BookingBehaviour == BookingBehavior.ClassOrSession) {
      tabdata = [{
        tabComponent: ActivityPaymentSummaryComponent,
        tabLabel: 'Payment',
        tabIcon: 'Group-748',
        isDisabled: false
      }];
    }
    else if (this.BookingBehaviour == BookingBehavior.OpenBooking) {
      tabdata = [{
        tabComponent: OpenPaymentSummaryComponent,
        tabLabel: 'Payment',
        tabIcon: 'Group-748',
        isDisabled: false
      }];
    }

    let gettabs = this.tabsModal.tabs.length;
    let tabisavilable = this.tabsModal.tabs.filter(tab => {
      if (this.BookingBehaviour == BookingBehavior.PrivateLesson)
        return tab.tabComponent == PrivatelessonPaymentSummaryComponent;

      else if (this.BookingBehaviour == BookingBehavior.ClassOrSession)
        return tab.tabComponent == ActivityPaymentSummaryComponent;

      else
        return tab.tabComponent == OpenPaymentSummaryComponent;

    });

    if (!(tabisavilable.length > 0)) {
      tabdata.forEach(element => {
        this.tabsModal.tabs.push(element);
      });
    }

  }

  addOrRemoveAddOn(specialMealId,standBy?) {
    if(standBy){
      let index = this.tabsModal.tabs.findIndex(data => data.tabComponent == AddOnForBookingComponent)
      if (index > -1) {
        this.tabsModal.tabs = this.tabsModal.tabs.filter((data, dataIndex) => index != dataIndex);
      }
    }
    else{
    let getAddOns = Utilities.getAddons(this.cs.settings.value.Addons, specialMealId);
    if (getAddOns?.length > 0 && getAddOns.filter(addOn => addOn.IsVisible == true).length > 0) {
      let index = this.tabsModal.tabs.findIndex(data => data.tabComponent == AddOnForBookingComponent)
      if (index == -1) {
        this.tabsModal.tabs.splice(1, 0, {
          tabComponent: AddOnForBookingComponent,
          tabLabel: 'addons',
          tabHeader: 'addons',
          tabIcon: 'Group-748',
          enabledIcon: true,
          isDisabled: false
        })
      }
    }
    else {
      let index = this.tabsModal.tabs.findIndex(data => data.tabComponent == AddOnForBookingComponent)
      if (index > -1) {
        this.tabsModal.tabs = this.tabsModal.tabs.filter((data, dataIndex) => index != dataIndex);
      }
    }
  }

    this.tabsModal.tabs = [...this.tabsModal.tabs];
  }

  UpdateReservationAttempt(PaymentCallbackRequest) {
    const updateUrl = urlConfig.updateReservationAttempt;
    return this.httpService.post(updateUrl, PaymentCallbackRequest)
  }

  BookingSummary(request) {
    const updateUrl =  `${urlConfig.EngageBookingSummary}?propertyId=${Utilities.RestaurantId()}`;
    return this.httpService.post(updateUrl, request,true);
  }

  cancelRetailOrderDetails(partyid, cancelRequest, applyCancellationCharge, cancellationReason, reservationAttemptId, bookedSessionId, retailTransactions, cancelAllSession,financialEffectId) {
   let cancelPayload = {
      "ReservationId" :  partyid,
      "ReservationAttemptId" : this.cancelBookingDataObj?.ReservationAttemptId ? this.cancelBookingDataObj?.ReservationAttemptId : reservationAttemptId,
      "FinancialEffectId" : this.FinancialEffectId ? this.FinancialEffectId : financialEffectId,
      //"Fees" : [{"bookedSessionId" : bookedSessionId, "Fee": this.cancelBookingDataObj.chargeCancellation ? (cancelRequest?.length ? cancelRequest[0]?.TotalAmount : (this.cancelBookingDataObj.cancellationFee || 0)): 0}],
      "Fees" : [{"bookedSessionId" : bookedSessionId, "Fee": applyCancellationCharge ?  (this.cancelBookingDataObj?.cancellationFee||0) : 0}],
      "BookedSessionId" : bookedSessionId ? [bookedSessionId] : null,
      "CancellationReason" : cancellationReason,
      "CancelAllSession" : cancelAllSession,
      
   }
    const cancelUrl = `${urlConfig.cancelRetailOrderDetails}?propertyId=${Utilities.RestaurantId()}`;;
    return this.httpService.post(cancelUrl, cancelPayload)
  }

  setRetailTransaction(transactions) {
    const retailURL = `${urlConfig.setRetailTransaction}?restaurantId=${Utilities.RestaurantId()}`;
    return this.httpService.post(retailURL, transactions)
  }

  RegisterReservationAttempt(RegisterReservationAttemptRequestDTO) {
    return this.httpService.post(urlConfig.registerBookActivityAttempt + '?restaurantId=' + Utilities.RestaurantId(), RegisterReservationAttemptRequestDTO).pipe(map((data: any) => data.Payload))
  }

  GetRegisterReservationAttempt(reservationAttemptId: Number) {
    return this.httpService.get(`${urlConfig.getReservationAttemptState}?reservationAttemptId=${reservationAttemptId}`).pipe(map((data: any) => data.Payload));
  }

  GetRegisterMultipleReservationAttempt(reservationAttemptIds: Number) {
    return this.httpService.post(`${urlConfig.getMultipleReservationAttemptStates}`, reservationAttemptIds).pipe(map((data: any) => data.Payload));
  }

  bookCartItems(data, ignoreValidation) {
    return this.httpService.post(`${urlConfig.bookCartItems}?propertyid=${data[0].PropertyId}&hostid=${Utilities.getHostId()}&merchantid=${this.cs.settings.value.General.MerchantId}&ignoreBookingValidation=${ignoreValidation}`, data, false, data[0].PropertyId);
  }

  blockSession(activityCustomizations: BlockActvityDataDTO | any) {
    const retailURL = `${urlConfig.BlockSession}?restaurantId=${Utilities.RestaurantId()}`;
    return this.httpService.post(retailURL, activityCustomizations);

  }

  unBlockSession(blockId, sessionId) {
    const retailURL = `${urlConfig.unblockSession}?restaurantId=${Utilities.RestaurantId()}&blockId=${blockId}&activitySessionId=${sessionId}`;
    return this.httpService.post(retailURL);

  }

  unblockActivity(activityCustomizations){
    const retailURL = `${urlConfig.unblockSession}?restaurantId=${Utilities.RestaurantId()}`;
    return this.httpService.post(retailURL, activityCustomizations);
  }

  modifySession(reqObj, activityId: number, sendMail: boolean = false) {
    const url = `${urlConfig.modifySession}?restaurantId=${Utilities.RestaurantId()}&activityId=${activityId}&sendMail=${sendMail}`;
    return this.httpService.post(url, reqObj);

  }
  
  cancelSession(reqObj, activityId: number, sendMail: boolean = false) {
    const url = `${urlConfig.modifySession}?restaurantId=${Utilities.RestaurantId()}&activityId=${activityId}&sendMail=${sendMail}&isSessionCancelled=${true}`;
    return this.httpService.post(url, reqObj);

  }

  bookCartItemsForMultipleProperties(data, ignoreValidation) {
    return this.httpService.post(`${urlConfig.bookCartForMultipleProperties}?ignoreBookingValidation=${ignoreValidation}`, data, false, data[0].PropertyId);
  }
  getFinancialEffectForCheckInWithDues(party, action, applyAdditionalCharge) {
    const retailURL = `${urlConfig.financialEffectForUpdatedActivity}?restaurantId=${Utilities.RestaurantId()}&financialEffectAction=${action}&applyAdditionalCharge=${applyAdditionalCharge}`;
    return this.httpService.post(retailURL, party);
  }
  getFinancialEffectForCancelWithDues(partyId, allsessionneedtocancel, findCancelbyStatus, bookingsessionids: any, applyCancellationCharge) {
    const cancelledURL = `${urlConfig.financialEffectForCancelledActivity}?restaurantId=${Utilities.RestaurantId()}&partyId=${partyId}&allsessionneedtocancel=${allsessionneedtocancel}&findCancelbyStatus=${findCancelbyStatus}&applyCancellationCharge=${applyCancellationCharge}`;
    return this.httpService.post(cancelledURL, [bookingsessionids])
  }

  getFinancialDetails(partyId, action: FinancialEffectAction,ratePlan,shopItem , ignoreValidation:boolean = false){
    let payload ={
      "PropertyId": Utilities.RestaurantId(),
      "ReservationId": partyId,
      "Action": action,
      "Source": 0,
      "BookAttemptRequest": null,
      "RatePlan": ratePlan || null,
      "ShopItems": shopItem || null,
      
    }
    return this.httpService.post(`${urlConfig.financialEffectUrl}?ignoreValidation=${ignoreValidation}`, payload);
  }

  getCancelFinancialDetails(partyId, cancelledItems,applyCharge?,cancelAllSessions? , ignoreValidation:boolean = false){
    let payload ={
      
        "ApplyCharge": applyCharge === false ? false : true,
        "CancelItem":cancelledItems,
        "PropertyId": Utilities.RestaurantId(),
        "ReservationId": partyId,
        "cancelAllSessions": cancelAllSessions || false
    
    }
        return this.httpService.post(`${urlConfig.CancelBookingFinancialEffectUrl}?ignoreValidation=${ignoreValidation}`, payload);
  }


  proceedBooking(fromType: ComponentTypes, reservationDto, dialogRef?, reservationDate?) {
    switch (fromType) {
      case ComponentTypes.AddOpenBooking:
        this.createOpenBooking(reservationDto, true, null, dialogRef);
        break;
      case ComponentTypes.AddPrivateLessonBooking:
        this.createPrivateLessonBooking(reservationDto, true, null, dialogRef);
        break;
      case ComponentTypes.AddActivityBooking:
        this.createSessionBooking(reservationDto, true);
        break;
      case ComponentTypes.ConvertStandBytoReservationActivity:
        this.convertActivityStandbyToReservation(reservationDate, true, dialogRef);
        break;
      case ComponentTypes.EditOpenBooking:
        this.confirmUpdateOpenBooking(reservationDto, true, dialogRef, reservationDate);
        break;
      case ComponentTypes.EditActivityBooking:
        this.confirmUpdateSessionBooking(reservationDto, true, dialogRef);
        break;
      case ComponentTypes.EditPrivateLessonBooking:
        this.confirmUpdatePrivateLessonBooking(reservationDto, true, dialogRef, reservationDate);
        break;
    }
  }

  UnSubscribePopUpAction(obj) {
    if (obj) {
      obj.unsubscribe();
    }
  }
  showWarningInfoPopUp(message, componentType, popupHeight, popupWidth, data?, title = 'confirm') {

    let cancelText = 'No';
    //let title = 'Confirm Booking';
    let noShowSet = false;
    let confirmCheckin = false;
    let updateText = 'Yes';
    let showAlert = false;
    let noShowFeePopUp = false;

    const popUpMessage = [{
      confirmationMessage: message, dialogTitle: title, showAlert: showAlert, ListOfItem: data
    }];

    const componentDetails: ComponentDetails = {
      componentName: ConfirmationPopupComponent,
      dimensionType: 'small',
      popupType: 'active',
      popUpDetails: {
        isStepper: false,
        eventName: 'notifyParent'
      },
      popupInput: popUpMessage,
      popupTitle: popUpMessage[0].dialogTitle
    };

    this.warningInfoDialogRef = this.dialog.open(CustomPopupComponent, {
      disableClose: true,
      width: popupWidth,
      height: popupHeight,
      data: {
        title,
        update: updateText,
        cancel: cancelText,
        componentDetails,
        from: componentType,
        back: false,
        standalone: true,
        showAlert: true
      }
    });
  }

  isWebReservation(partySourceId: number) {
    if (this.cs.settings.value.PartySources.filter(partySource => partySource.Id === partySourceId).length > 0) {
      return true;
    }
    else {
      return false;
    }
  }
  
  GetRetailTransaction(transId) {
    const getURL = `${urlConfig.getRetailTransaction}?restaurantId=${Utilities.RestaurantId()}&transactionId=${transId}`;
    return this.httpService.get(getURL);
  }
  
  GetRetailGuestInfo(guestids) {
    const getURL = `${urlConfig.getRetailGuestInfo}?restaurantId=${Utilities.RestaurantId()}`;
    return this.httpService.post(getURL, guestids);
  }

  getDefaultTimelineView(viewList, fallback?: ViewBy) {
    return viewList.find(({ isDefault }) => isDefault)?.id || fallback || viewList[0].id;
  }


  CheckOutValidation() {
    const popUpMessage = [{
      confirmationMessage: this.ts.instant("additionalPaymentPending"),
      dialogTitle: this.ts.instant('error')
    }];
    let okbutton = this.ts.instant('ok');
    const componentDetails: ComponentDetails = Utilities.setComponentDetails(ConfirmationPopupComponent, 'small', 'action',
      popUpMessage, popUpMessage[0].dialogTitle);
    this.openCustomPopup(componentDetails, ComponentTypes.RoleTypeAccess, '450px', 'auto', true, '', okbutton,
      '', true);
  }


  getDatesOverview(timerange): Observable<any> {
    const timeRangeDetails: newTimeRangeDTO = {
      Start: new Date(timerange.Start.getTime() - (timerange.Start.getTimezoneOffset() * 60000)).toJSON(),
      End: new Date(timerange.End.getTime() - (timerange.End.getTimezoneOffset() * 60000)).toJSON()
    };
    return this.httpService.post(
      `${urlConfig.getDatesOverview}?restaurantId=${Utilities.RestaurantId()}`,
      { Start: timeRangeDetails.Start.split('Z')[0], End: timeRangeDetails.End.split('Z')[0] });
    }

  CalculateAdditionalCharge(partyId) {
    const cancelledURL = `${urlConfig.CalculateAdditionalChargeURL}?restaurantId=${Utilities.RestaurantId()}&partyId=${partyId}`;
    return this.httpService.post(cancelledURL)
  }

  getSelectedAddOns() {
    let selectedAddOns: any = this.addOnList.flat().filter(x => x.selectedQuantity > 0 && x.IsVisible);
    return selectedAddOns?.map(addOn => { return { Name: addOn.AddonName, Price: addOn.Price, AddonId: addOn.AddonId, Quantity: addOn.selectedQuantity, Category: addOn.PricingCategory, OverBooked: addOn.OverBooked } });
  }


  getAddonBlocker(cDate, startTime, endTime, fromWeek, ActivityId?,) {
    let cNewDate = new Date(new Date(cDate).setHours(0, 0, 0, 0));
    let AddonBlockerEnabled = false;
    let AddonsIds = [];
    let currentAddons = Utilities.getAddonActivityType(this._settings.Addons, ActivityId);
    if (currentAddons && currentAddons.length) {
      let parties;
      if (fromWeek) {
        parties = this.allWeekreservations.find(weekReservation => new Date(weekReservation.Date).getTime() == cNewDate.getTime())?.ActivityBookings || [];
      }
      else {
        parties = this.Parties$.value;
      }
      if (parties) {
        let otherparty = parties.filter(x => x.SpecialMealId != ActivityId && new Date(new Date(x.StartDate).setHours(0, 0, 0, 0)).getTime() <= cNewDate.getTime() && new Date(new Date(x.EndDate).setHours(0, 0, 0, 0)).getTime() >= cNewDate.getTime());
        for (let index = 0; index < otherparty.length; index++) {
          let currentParty = otherparty[index];
          const list = currentParty?.BookingContactAddonItems?.filter(x => currentAddons.map(y => y.AddonId).includes(x.AddonId));
          if (list && list.length && currentParty) {
            if (currentParty?.BookedSessions && currentParty?.BookedSessions.length > 0) {
              let currentBookedSession = currentParty.BookedSessions.filter(bs => new Date(new Date(bs.BookedDate).setHours(0, 0, 0, 0)).getTime() == cNewDate.getTime());
              for (let x = 0; x < currentBookedSession.length; x++) {
                let currentBookedSessions = currentParty.BookedSessions[x];
                let ActivitySession = this._settings.SpecialMeals.filter((i) =>
                  i.ActivitySessions.some((y) => y.ActivitySessionId == currentBookedSessions.ActivitySessionId)
                );
                if(ActivitySession?.length) {
                  let activitySessions = ActivitySession[0].ActivitySessions.filter(as => as.ActivitySessionId == currentBookedSessions.ActivitySessionId);
                if (Utilities.dateRangeOverlaps(startTime, endTime, this.getDateTime(cNewDate, activitySessions[0].StartTime), this.getDateTime(cNewDate, activitySessions[0].EndTime))) {
                  AddonBlockerEnabled = true;
                  return AddonBlockerEnabled;
                }
              }
              else {
                return AddonBlockerEnabled;
              }
              }
            } else {
              if (currentParty?.SessionGroupId) {
                var currentDayofweek = cNewDate.getDay();
                let ActivitySession = this._settings.SpecialMeals.filter((i) =>
                  i.ActivitySessions.some((y) => y.SessionGroupId == currentParty.SessionGroupId && y.Dayofweek == currentDayofweek)
                )
                if(ActivitySession?.length) {
                let activitySessions = ActivitySession[0].ActivitySessions.filter(as => as.SessionGroupId == currentParty.SessionGroupId && as.Dayofweek == currentDayofweek);
                if (Utilities.dateRangeOverlaps(startTime, endTime, this.getDateTime(cNewDate, activitySessions[0].StartTime), this.getDateTime(cNewDate, activitySessions[0].EndTime))) {
                  AddonBlockerEnabled = true;
                  return AddonBlockerEnabled;
                }
              }
              else {
                return AddonBlockerEnabled;
              }
              }
              else {
                if (format(new Date(currentParty.ReservedFor), 'MM/DD/YYYY') === format(cNewDate, 'MM/DD/YYYY')) {
                  if (Utilities.dateRangeOverlaps(startTime, endTime, currentParty.SeatingTime, currentParty.DepartureTime)) {
                    AddonBlockerEnabled = true;
                    return AddonBlockerEnabled;
                  }
                }
              }
            }
          }
          if (AddonBlockerEnabled) {
            break;
          }
        }
      }
    }
    return AddonBlockerEnabled;
  }


  getActivityCustomisation(rowData, session, resDayWeek?) {
    //    let columnDate = dayOfWeek ? dayOfWeek.find(x => x.dayOfWeek == session.Dayofweek)?.date || this.cs.headerDate : this.cs.headerDate;
  //  let activityCustomisation = this.cs.settings.value.SpecialMeals.find(data => data.Id == session.SpecialMealId)?.ActivityCustomizations;
    //  let day = moment.isMoment(this.cs.headerDate) ? this.cs.headerDate.day() : this.cs.headerDate.getDay();
    if(this.activityBlockingChange$.value){
    let activityCustomisation = Object.values(this.activityBlockingChange$.value.ActivityBlockingRule).filter((data: any) => data.ActivityId == session.SpecialMealId)
    let columnDate = resDayWeek ? resDayWeek : this.cs.headerDate;
    if (activityCustomisation?.filter((cust: any) => (cust.ActivitySessionIds.includes(session.SessionId) || cust.ActivitySessionIds.includes(session.ActivitySessionId)) && (moment(columnDate).format("YYYY-MM-DD") >= moment(cust.StartDate).format("YYYY-MM-DD")) && (moment(columnDate).format("YYYY-MM-DD") <= moment(cust.EndDate).format("YYYY-MM-DD")) && (cust.AppliesTo == ActivityCustomizationAppliesTo.HostAppAndWidget || cust.AppliesTo == ActivityCustomizationAppliesTo.HostApp)).length > 0) {
      return this.ts.instant('unblockbuttontext');
    }
  }
    return this.ts.instant('blockbuttontext');
  }

  getHostName(hostId) {
    return this.cs.settings.value.Hosts.find(user => user.Id == hostId)?.Name || ''
  }


  promptBlockSession(activityData, item, blockSession) {
    let options;
    let title;
    if (blockSession) {
      title = this.ts.instant('blockSession');
      options = [
        { id: BlockActivityType.OnlyThis, value: this.ts.instant('blockOneSession') },
        { id: BlockActivityType.AllRemaining, value: this.ts.instant('blockAllSession') }
      ]
    }
    else {
      title = this.ts.instant('unblockSession');
      options = [
        { id: BlockActivityType.OnlyThis, value: this.ts.instant('unblockOneSession') },
        { id: BlockActivityType.AllRemaining, value: this.ts.instant('unblockAllSession') }
      ]
    }
    const popUpMessage = [{
      dialogTitle: title,
      showAlert: false,
      options: options,
      activityName: activityData.Name,
      activityData: item,
      blockSession: blockSession
    }];
    const componentDetails: ComponentDetails = Utilities.setComponentDetails(BlockSessionComponent, 'small', '', popUpMessage,
      popUpMessage[0].dialogTitle);
    let headlinePopup = this.openCustomPopup(componentDetails, blockSession ? ComponentTypes.BlockSessionActivity : ComponentTypes.UnblockSessionActivity, '500px', 'auto', false,
      popUpMessage[0].dialogTitle, this.ts.instant('save'), 'Cancel', true);

    let blockPopUpConfirmSubscription = this.popupService.confirmedAction$.subscribe(val => {
      if (val === ComponentTypes.BlockSessionActivity) {
        if (blockPopUpConfirmSubscription) {
          blockPopUpConfirmSubscription.unsubscribe();
        }
        let hostData = Utilities.tryParseObject<LoginResultDTO>(localStorage.getItem(`${sessionStorage.getItem(`sessionGUID${Utilities.getSessionStorageType()}`)}_loginResult`))?.HostId;
        let activityCustomizations = {
          PropertyId: Utilities.RestaurantId(),
          ActivityBlockInfos: [{
            CustomizationId: null,
            ActivityId: item.SpecialMealId,
            ActivitySessionIds: this.blockSessionForm.value.blockType == BlockActivityType.OnlyThis ? [item.SessionId] : this.getActivitySessionIds(item.SpecialMealId, activityData.weeklyViewDate ? activityData.weeklyViewDate.getDay() : this.cs.headerDate.getDay()),  
          }],
          CustomizationType: ActivityCustomizationType.Block,
          StartDate: activityData.weeklyViewDate ? (moment(activityData.weeklyViewDate).format('YYYY-MM-DD') ) : (moment(this.cs.headerDate).format('YYYY-MM-DD')),
          EndDate: activityData.weeklyViewDate ? (moment(activityData.weeklyViewDate).format('YYYY-MM-DD') ) : (moment(this.cs.headerDate).format('YYYY-MM-DD')),
          // EndDate: this.appService.headerDate$.value,
          ActionBy: hostData ? hostData : null,
          CreatedAt: activityData.weeklyViewDate ? (moment(activityData.weeklyViewDate).format('YYYY-MM-DD') + 'T' + moment(activityData.startTime).format('HH:mm:ss')) : (moment(this.cs.headerDate).format('YYYY-MM-DD') + 'T' + moment(activityData.startTime).format('HH:mm:ss')),
          UpdatedAt: activityData.weeklyViewDate ? (moment(activityData.weeklyViewDate).format('YYYY-MM-DD') + 'T' + moment(activityData.startTime).format('HH:mm:ss')) : (moment(this.cs.headerDate).format('YYYY-MM-DD') + 'T' + moment(activityData.startTime).format('HH:mm:ss')),
          ActionReason: this.blockSessionForm.value.blockReason,
        };
        this.blockSession(activityCustomizations).subscribe(data => {
          //this.updateActivityCustomisation(data.Payload, item.SpecialMealId);
        })
        //   blockPopUpConfirmSubscription.unsubscribe();
        //  this.UnSubscribePopUpAction(cancelPopUpConfirmSubscription);
      }
      if (val === ComponentTypes.UnblockSessionActivity) {
        if (blockPopUpConfirmSubscription) {
          blockPopUpConfirmSubscription.unsubscribe();
        }

        let hostData = Utilities.tryParseObject<LoginResultDTO>(localStorage.getItem(`${sessionStorage.getItem(`sessionGUID${Utilities.getSessionStorageType()}`)}_loginResult`))?.HostId;
       
        let activityCustomizations = {
          PropertyId: Utilities.RestaurantId(),
          ActivityBlockInfos: [{
            CustomizationId: null,
            ActivityId: item.SpecialMealId,
            ActivitySessionIds: this.blockSessionForm.value.blockType == BlockActivityType.OnlyThis ? [item.SessionId] : this.getActivitySessionIds(item.SpecialMealId, activityData.weeklyViewDate ? activityData.weeklyViewDate.getDay() : this.cs.headerDate.getDay()),  
          }],
          CustomizationType: ActivityCustomizationType.Block,
          StartDate: activityData.weeklyViewDate ? (moment(activityData.weeklyViewDate).format('YYYY-MM-DD')) : (moment(this.cs.headerDate).format('YYYY-MM-DD')),
          EndDate: activityData.weeklyViewDate ? (moment(activityData.weeklyViewDate).format('YYYY-MM-DD')) : (moment(this.cs.headerDate).format('YYYY-MM-DD')),
          // EndDate: this.appService.headerDate$.value,
          ActionBy: hostData ? hostData : null,
          CreatedAt: activityData.weeklyViewDate ? (moment(activityData.weeklyViewDate).format('YYYY-MM-DD')) : (moment(this.cs.headerDate).format('YYYY-MM-DD')),
          UpdatedAt: activityData.weeklyViewDate ? (moment(activityData.weeklyViewDate).format('YYYY-MM-DD')) : (moment(this.cs.headerDate).format('YYYY-MM-DD')),
          ActionReason: this.blockSessionForm.value.blockReason,
        };
       
        this.unblockActivity(activityCustomizations).subscribe(data => {
          //this.updateActivityCustomisation(data.Payload, item.SpecialMealId);
        })
      }
    });
    let cancelPopUpCloseSubscription = this.popupService.cancelledAction$.subscribe(val => {
      if (cancelPopUpCloseSubscription) {
        cancelPopUpCloseSubscription.unsubscribe();
        if (blockPopUpConfirmSubscription) {
          blockPopUpConfirmSubscription.unsubscribe();
        }
      }
    });
  }

  getActivitySessionIds(activityId, dayOfWeek){
   var activity =  this.cs.settings.value.SpecialMeals.filter(s=>s.Id === activityId)[0];
   if(activity){
    return activity.ActivitySessions.filter(as=>as.Dayofweek === dayOfWeek).map(as=>as.ActivitySessionId);
   }
   return []
  }

  updateActivityCustomisation(activityCustomisation, activityId) {
    if (activityCustomisation) {
      let index = this._settings.SpecialMeals.findIndex(activity => activity.Id == activityId);
      this._settings.SpecialMeals[index].ActivityCustomizations = activityCustomisation;
      this.cs.settings.next(this._settings);
    }
  }

  getDateTime(cnewDate, TimeStamp) {
    let time = TimeStamp.split(':');
    let cNewDate = _.cloneDeep(cnewDate);
    cNewDate.setTime(cNewDate.getTime() + ((time[0] * 3600000) + (time[1] * 60000) + (time[2] * 1000)));
    return new Date(cNewDate);
  }
  getAddonAvailability(AddonsList: AddonDTO[], ActivityId?) {
    let AddonList = [];
    AddonsList.forEach(element => {
      const mapp = element.AddonActivitiesMappings.find(x => x.ActivityId == ActivityId);
      if (mapp) {
        const addOnAvailability = {} as AddOnAvailability;
        addOnAvailability.AddonId = mapp.AddonId;
        if (element.SelectionType == SelectionType.PerLesson) {
          addOnAvailability.AvailableQuantity = 1;
        }
        else {
          addOnAvailability.AvailableQuantity = element.AddOnDetails[0].IsUnlimited ? 99999 : element.AddOnDetails[0].Quantity;
        }
        addOnAvailability.IsUnlimited = element.AddOnDetails[0].IsUnlimited == true;
        addOnAvailability.LockedQuantity = 0;
        addOnAvailability.OverAllQuantity = element.AddOnDetails[0].IsUnlimited ? 99999 : element.AddOnDetails[0].Quantity;
        addOnAvailability.SoldQuantity = 0;
        addOnAvailability.SpecialMealId = ActivityId;
        AddonList.push(addOnAvailability)
      }
    });

    return AddonList;
  }

  getWristBandInfo(partyId) {    
    return this.httpService.post(
      `${urlConfig.GetPartyCartInfoURL}?restaurantId=${Utilities.RestaurantId()}&partyId=${partyId}`);
  }

  printWristBand(Attendee,WristBandInfo)
  {    
      let TicketType = this._settings.General.TicketType;
      let TicketLayoucode = this._settings.General.LayoutCode;
      if (TicketType) {
        let printer;
        if (this.selecetedPrinter) {
          printer = this._settings.PrintersInfo.filter(x => x.Id == this.selecetedPrinter)[0];
        } else {
          printer = this._settings.PrintersInfo.filter(x => x.IsDefault)[0];
        }
        if (printer) {
          let x=Attendee; 
            let BookingDate = (moment(x.StartDate)).format(this.cs.settings.value.General.DateFormat) + " - " + (moment(x.EndDate)).format(this.cs.settings.value.General.DateFormat);
            if (new Date(x.StartDate).setHours(0, 0, 0, 0) == new Date(x.EndDate).setHours(0, 0, 0, 0)) {
              BookingDate = (moment(x.StartDate)).format(this.cs.settings.value.General.DateFormat);
            }
            let packname = this.cs.availablePackages.find(pack => pack.Id == x.PackageId)?.Name;
            const layoutdata = {} as TicketModel;
            layoutdata.BookingInfo = [];
            layoutdata.PropertyInfo = [];
            const propertyModel = {} as PropertyInfo;
            propertyModel.Key = "PropertyName";
            propertyModel.Value = this._settings.General.RestaurantName;
            layoutdata.PropertyInfo.push(propertyModel);
            const BookingModel1 = {} as BookingInfo;
            BookingModel1.Key = "GuestName";
            BookingModel1.Value = x.Name;
            BookingModel1.Title = "Player Name";
            layoutdata.BookingInfo.push(BookingModel1);
            const BookingModel2 = {} as BookingInfo;
            BookingModel2.Key = "Date";
            BookingModel2.Value = BookingDate;
            BookingModel2.Title = "Date";
            layoutdata.BookingInfo.push(BookingModel2);
            const BookingModel3 = {} as BookingInfo;
            BookingModel3.Key = "ActivityName";
            BookingModel3.Value = x.ClassName;
            BookingModel3.Title = "Activity/Event Name";
            layoutdata.BookingInfo.push(BookingModel3);
            const BookingModel4 = {} as BookingInfo;
            BookingModel4.Key = "Time";
            BookingModel4.Value = this.timeformat(x.StartTime) + " - " + this.timeformat(x.EndTime);
            BookingModel4.Title = "Timing";
            layoutdata.BookingInfo.push(BookingModel4);
            const BookingModel5 = {} as BookingInfo;
            BookingModel5.Key = "ConfirmationCode";
            BookingModel5.Value = x.ConfirmationCode;
            BookingModel5.Title = "Confirmation Code";
            layoutdata.BookingInfo.push(BookingModel5);
            const BookingModel6 = {} as BookingInfo;
            BookingModel6.Key = "LocationName";
            BookingModel6.Value = x.TableName;
            BookingModel6.Title = "Location";
            layoutdata.BookingInfo.push(BookingModel6);
            const BookingModel7 = {} as BookingInfo;
            BookingModel7.Key = "PartySize";
            BookingModel7.Value = x.Size.toString();
            BookingModel7.Title = "Party Size";
            layoutdata.BookingInfo.push(BookingModel7);
            const BookingModel8 = {} as BookingInfo;
            BookingModel8.Key = "QRCodeData";
            BookingModel8.Value = WristBandInfo;
            BookingModel8.Title = "";
            layoutdata.BookingInfo.push(BookingModel8);
            const BookingModel9 = {} as BookingInfo;
            BookingModel9.Key = "PacakageName";
            BookingModel9.Value = packname;
            BookingModel9.Title = "Pacakage";
            layoutdata.BookingInfo.push(BookingModel9);
            this.callPrintService(printer?.IPAddress, TicketLayoucode, TicketType, printer?.Port, layoutdata, packname);
          
        }
      } else {
        // print preview to do
      }
  }
  
  showSnackBar(partyMessage) {
    if(partyMessage.IsIncoming && sessionStorage.getItem('isAppLoggedIn'))
    this._snackBar.open(this.ts.instant('txtMsgReceived'), this.ts.instant('closeText'), {
      horizontalPosition: this.horizontalPosition,
      verticalPosition: this.verticalPosition,
      duration: 3000,
    });
  }
  snackbarInfoMessage(message: string){
    this._snackBar.open(message, '' , {
      horizontalPosition: this.horizontalPosition,
      verticalPosition: this.verticalPosition,
      duration: 3000,
    });
  }
  createDiningReservation(request){
    return this.httpService.post(`${urlConfig.createReservationURL}?restaurantId=${Utilities.RestaurantId()}`,
      request);
  }
  createDiningStandbyParty(request){
    return this.httpService.post(`${urlConfig.createStandbyUrl}${Utilities.RestaurantId()}`,
      request);
  }
  updateDiningStandbyParty(request) {
    return this.httpService.post(`${urlConfig.updateStandbyUrl}${Utilities.RestaurantId()}`,
      request);
  }
  convertDiningStandbyToReservation(request , partyId){
    return this.httpService.post(`${urlConfig.convertToReservationUrl}${Utilities.RestaurantId()}&partyId=${partyId}`,
    request);
  }
  updateDiningReservation(request){
   return this.httpService.post(`${urlConfig.updateReservationURL}?restaurantId=${Utilities.RestaurantId()}`,
      request);
  }
  financialEffectForUpdatedReservation(request){
    return this.httpService.post(`${urlConfig.financialEffectForUpdatedReservation}?restaurantId=${Utilities.RestaurantId()}`,
    request);
  }
  disableEnableAutoSlot(request: any,isDisable: boolean) {
    return this.httpService.post(`${urlConfig.DisableEnableAutoSlot}?restaurantId=${Utilities.RestaurantId()}&disable=${isDisable}`,
      request);
  }
  
  getWebReservableShift(id: number,date: Date) {
    return this.httpService.get(`${urlConfig.IsWebReservable}?restaurantId=${Utilities.RestaurantId()}&shiftId=${id}&localDate=${Utilities.Date(date).format('YYYY-MM-DDTHH:mm:ss')}`);
  }

  setWebReservableShift(id: number,date: Date,isWebReserve:boolean) {
    return this.httpService.post(`${urlConfig.SetWebReservable}?restaurantId=${Utilities.RestaurantId()}&shiftId=${id}&localDate=${Utilities.Date(date).format('YYYY-MM-DDTHH:mm:ss')}&isWebReservable=${isWebReserve}`,{});
  }

  getShiftAuditLog(id:number,date: Date,option: number) {
    return this.httpService.get(`${urlConfig.SetWebReservable}?restaurantId=${Utilities.RestaurantId()}&shiftId=${id}&localDate=${date}&options=${option})`);
  }

  getSyncContact(contactId: number) {
    return this.httpService.post(`${urlConfig.VCartSyncContact}?propertyId=${Utilities.RestaurantId()}&contactId=${contactId}` , {});
  }
  
  getBookedAddonItems(request: any) {
    return this.httpService.post(`${urlConfig.GetBookedAddonItems}` , request);
  }

  hasEmailPromptForCreate(supportedEmailType){
    let supportedCombinations = [1,3,5,7];
    return supportedCombinations.includes(supportedEmailType);;
  }
   
  hasEmailPromptForUpdate(supportedEmailType){
    let supportedCombinations = [2,3,6,7];
    return supportedCombinations.includes(supportedEmailType);
  }

  hasEmailPromptForCancelled(supportedEmailType){
    let supportedCombinations = [4,5,6,7];
    return supportedCombinations.includes(supportedEmailType);;
  }

  showPaymentConfirmationPopup(request, textLabel, reservationDate, resDialogRef: MatDialogRef<any>, componentType, refundData?, IsCreditCardNeeded?, financialData?) {
    if (this.confirmSubscription) {
      this.confirmSubscription.unsubscribe();
    }
    if (this.cancelSubscription) {
      this.cancelSubscription.unsubscribe();
    }
    const cancelText = this.ts.instant('Cancel');
    const title = this.ts.instant('Confirmation');
    const msg = textLabel;
    const updateText = this.ts.instant('Proceed');
    const showAlert = true;
    const requestDetails = {
      requestObj: request, date: reservationDate, dialog: resDialogRef, refund: refundData, IsCreditCardNeeded: IsCreditCardNeeded, financialData: financialData, componentType: componentType
    }
    const popUpMessage = [{
      confirmationMessage: msg, dialogTitle: title, showAlert, request: requestDetails
    }];
    const componentDetails: ComponentDetails = {
      componentName: PaymentConfirmationPopupComponent,
      dimensionType: 'small',
      popupType: 'active',
      popUpDetails: {
        isStepper: false,
        eventName: 'notifyParent'
      },
      popupInput: popUpMessage,
      popupTitle: popUpMessage[0].dialogTitle
    };
    const dialogRef = this.dialog.open(CustomPopupComponent, {
      disableClose: true,
      width: '700px',
      height: '520px',
      data: {
        title,
        update: updateText,
        cancel: cancelText,
        componentDetails,
        from: componentType,
        back: false,
        standalone: true,
        showAlert: true,
        showClose: false
      }
    });
    dialogRef.afterClosed().subscribe(() => {
      if (this.confirmSubscription) {
        this.confirmSubscription.unsubscribe();
      }
      if (this.cancelSubscription) {
        this.cancelSubscription.unsubscribe();
      }
    })
  }
  reinstateReservation(attendee){
    this.subscriptions.add(this.api.undoCheckIn(attendee.Id, attendee.SpecialMealId, attendee.BookedSessionId).subscribe(response => {
      if (response.State == OperationResultState.Success) {
      this.openConfirmationDialog(response, this.ts.instant('attendeeReInState'));
      }
    }));
  }

getValidationMessage(data){
  let warningMessages  = [];
  if(data.ValidationMessages.length){
    warningMessages = data.ValidationMessages.filter(msg => msg.Type == ValidationMessageType.ConsentMessages);
  }
  return warningMessages || [];
}
  
  printChit(divId) {
    var panel = document.getElementById(divId);
    var printWindow = window.open('', 'print', 'height=800,width=800,fullscreen=no,scrollbars=yes,titlebar=yes,menubar=yes');
    printWindow.document.write('<html><head>');
    printWindow.document.write('<style>' + GlobalConst.chitPrintStyles +
      
    '</style>')
  
    printWindow.document.write('</head><body onload="window.print()" onmouseover="window.close()">');
    printWindow.document.write(panel.innerHTML);
    printWindow.document.write('</body></html>');
    printWindow.document.close();
    printWindow.focus();
  }
  toPrintchit() {
    if (this.cs?.settings?.value?.MerchantSettings?.AutoPrintChit?.SettingValue == SettingType.ENABLE) {
      Util.printChit('Chit-Print_Print-Section', GlobalConst.chitPrintStyles);
    }
  }

 showPromoCodeValidation(message) {
  const popUpMessage = [{
    confirmationMessage: message,
    dialogTitle: this.ts.instant('Message'),
    showAlert: true
  }];
  const componentDetails: ComponentDetails = Utilities.setComponentDetails(ConfirmationPopupComponent, 'small', 'action', popUpMessage,
    popUpMessage[0].dialogTitle);
  this.openCustomPopup(componentDetails, ComponentTypes.reservation, '450px', 'auto', true, '', 'Ok',
    'Cancel', true);
}
}
