import { DatePipe } from '@angular/common';
import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CacheService } from '@app/core/services/cache.service';
import { PopupService } from '@app/popup-module/popup.service';
import { DynamicFormComponent } from '@app/shared/dynamicform/dynamic-form/dynamic-form.component';
import { FieldConfig } from '@app/shared/dynamicform/models/field-config.interface';
import { ShiftDTO, StaffBlockDTO } from '@app/shared/models/RestaurantDTO';
import { newTimeRangeDTO } from '@app/shared/models/TimeRangeDTO';
import { SlotTimeFormatter } from '@app/shared/pipes/slot-time-formatter.pipe';
import { PartyService } from '@app/shared/services/party.service';
import { ServerService } from '@app/shared/services/server.service';
import { Utilities } from '@app/shared/utilities/utilities';
import { TranslateService } from '@ngx-translate/core';
import moment from 'moment';
import { Subscription } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-block-staff',
  templateUrl: './block-staff.component.html',
  styleUrls: ['./block-staff.component.scss']
})
export class BlockStaffComponent implements OnInit {
  blockStaffConfig: FieldConfig[] = [];
  blockStaffData: StaffBlockDTO;
  blockStaffDetails: UntypedFormGroup;
  staffSchedules: any[] = [];
  @ViewChild('blockStaffForm', { static: true }) blockStaffForm: DynamicFormComponent;
  subscriptions: Subscription = new Subscription();
  breakHourError: string = '';
  breakHourShiftId: number;
  breakHourScheduleId: number[] = [];
  isValidBreakHour: boolean = true;

  constructor(@Inject(MAT_DIALOG_DATA) public dialogData, public dialogRef: MatDialogRef<BlockStaffComponent>, private _ts: TranslateService, private _cs: CacheService, private _pp: PopupService, private _stfPipe: SlotTimeFormatter) { }

  ngOnInit(): void {
    this.blockStaffData = this.dialogData.componentDetails.popupInput[0].staffBlockData;
    this.staffSchedules = this.dialogData.componentDetails.popupInput[0].staffSchedules;
    this.breakHourShiftId = this.blockStaffData.ShiftId;
    if(this.breakHourShiftId && !this.blockStaffData.StaffScheduleId?.length) {
    this.staffSchedules?.forEach(schedule => {
      if(schedule.ScheduleId) {
        let isCurrentShift = schedule.Shifts.find(shift => [this.breakHourShiftId].includes(shift.ShiftId));
        if(isCurrentShift) {
          this.breakHourScheduleId.push(schedule.ScheduleId);
        }
      }
    })
  }
  else {
    this.breakHourScheduleId = this.blockStaffData.StaffScheduleId;
  }
    this.loadFormConfig();
  }

  ngAfterViewInit(): void {
    this.formValueChanges();
    this.isFormValid();
    let cancelSubscription = this._pp.cancelledAction$.subscribe(cancelled => {
      this.dialogRef.close({ action: "cancelled" });
      cancelSubscription?.unsubscribe();
    })
    let confirmSubscription = this._pp.confirmedAction$.subscribe(confirmed => {
      confirmSubscription?.unsubscribe();
      if (this.blockStaffForm?.form?.get('StaffIds')?.value) {
        let selectedStaffSchedules = this.staffSchedules.filter(schedule => this.blockStaffForm?.form?.get('StaffIds')?.value?.includes(schedule.ServerId));
        selectedStaffSchedules = selectedStaffSchedules.filter(schedule => (new Date(this.blockStaffForm?.form?.get('CurrentDate')?.value) >= new Date(schedule.StartDate) && new Date(this.blockStaffForm?.form?.get('CurrentDate')?.value) <= new Date(schedule.EndDate)));
        let ScheduleIds = selectedStaffSchedules?.map(({ ScheduleId }) => ScheduleId);
        this.breakHourScheduleId = ScheduleIds ? ScheduleIds : [];
      }
      let reqObj: StaffBlockDTO = this.getBlockorBreakHourObj();
      this.dialogRef.close({ action: "confirmed", reqObj: reqObj });
    })
  }

  getBlockorBreakHourObj(): StaffBlockDTO {
    return {
      BreakHourId: this.blockStaffData?.BreakHourId || null,
      Date: new Date((moment(this.blockStaffForm?.form?.get('CurrentDate')?.value).format('YYYY-MM-DD'))) || new Date((moment(this.blockStaffData.Date).format('YYYY-MM-DD'))),
      StaffScheduleId: this.breakHourScheduleId,
      StaffIds: this.blockStaffForm?.form?.get('StaffIds')?.value || this.blockStaffData?.StaffIds,
      ShiftId: this.breakHourShiftId,
      EffectiveRange: this.getEffectiveBreakorBlockHoursTime(),
    };
  }

  getEffectiveBreakorBlockHoursTime() : newTimeRangeDTO {
    const breakStart = moment(this.blockStaffForm?.form?.get('StartTime')?.value, 'hh:mm:ss A').toDate();
    const breakEnd = moment(this.blockStaffForm?.form?.get('EndTime')?.value, 'hh:mm:ss A').toDate();
    const timeRangeDetails: newTimeRangeDTO = {
      Start: new Date(breakStart.getTime() - (breakStart.getTimezoneOffset() * 60000)).toJSON(),
      End: new Date(breakEnd.getTime() - (breakEnd.getTimezoneOffset() * 60000)).toJSON(),
      Reason: this.blockStaffForm?.form?.get('Description')?.value
    };
    return timeRangeDetails;
  }

  loadFormConfig() {
    this.blockStaffConfig = [
      {
        type: 'date',
        name: 'CurrentDate',
        inputType: 'text',
        appearance: false,
        validation: [Validators.required],
        label: this._ts.instant("reservationSelectDate"),
        value: this.blockStaffData?.Date || this._cs.headerDate,
        class: 'w-50',
        minDate: Utilities.getRestaurantDateTime(this._cs.settings.value.General.DaylightDelta),
        isStarSymbolRequired: true,
      },
      {
        type: 'select',
        name: 'StaffIds',
        label: this._ts.instant('Instructor'),
        options: this.getStaffs(),
        value: this.blockStaffData?.StaffIds || [],
        appearance: false,
        validation: [Validators.required],
        isTranslate: true,
        selectMultipleOptions: true,
        disableErrorStateMatcher: true,
        class: this.blockStaffData.isEdit ? "basic-select w-50 disabled" : "basic-select w-50",
        selectAscendingSortOrder: true,
        isStarSymbolRequired: true,
        disabled: this.blockStaffData.isEdit || false,
      },
      {
        type: 'timepicker',
        name: 'StartTime',
        placeholder: this._ts.instant('starttime'),
        appearance: false,
        label: this._ts.instant('starttime'),
        class: "block-staff__date w-100",
        validation: [Validators.required],
        value: this.blockStaffData?.EffectiveRange?.Start ? moment(this.blockStaffData.EffectiveRange.Start).format("HH:mm") : null,
        isStarSymbolRequired: true,
      },
      {
        type: 'timepicker',
        name: 'EndTime',
        placeholder: this._ts.instant('endtime'),
        appearance: false,
        label: this._ts.instant('endtime'),
        class: "block-staff__date w-100",
        validation: [Validators.required],
        value: this.blockStaffData?.EffectiveRange?.End ? moment(this.blockStaffData.EffectiveRange.End).format("HH:mm") : null,
        isStarSymbolRequired: true,
      },
      {
        type: 'textarea',
        name: 'Description',
        label: this._ts.instant('ModifyReason'),
        inputType: 'text',
        showHint: true,
        charLength: 400,
        validation: [Validators.required],
        value: this.blockStaffData?.EffectiveRange?.Reason || '',
        appearance: false,
        class: 'block-staff__description w-100',
        isStarSymbolRequired: true,
      }
    ];
  }

  
  getStaffs() {
    let staffs = [];
    this._cs.settings.value.Servers.forEach(function (value) {
      if (!value.IsTemplate)
      staffs.push({
            id: value.Id,
            value: (value.Name + ' ' + (value.LastName || ''))
          })
    });
    return this.sortStaffs(staffs);
  }

  sortStaffs(staffs) {
    const sortByValue = (a, b) => a.value.localeCompare(b.value, 'en', { numeric: true });
    return staffs.sort(sortByValue);
  }

  formValueChanges() {
    this.subscriptions.add(this.blockStaffForm.form.valueChanges
      .pipe(map((_) => this.blockStaffForm.form.getRawValue()))
      .subscribe(data => {
        if(data.StaffIds && data.StartTime && data.StartTime?.toLowerCase() != 'invalid date' && data.EndTime && data.EndTime?.toLowerCase() != 'invalid date') {
          let staffIds = data.StaffIds ? data.StaffIds : this.blockStaffData.StaffIds;
          let totalDuration = this.toValidateStartEndTime(data.StartTime, data.EndTime);
          if(totalDuration && ((totalDuration % this._cs.settings.value.General.TimeSlotUnitInMinutes) != 0)) {
            this.breakHourError = this._ts.instant('Invalid Time Range');
            return;
          }
          this.staffShiftsRange(data.StartTime, data.EndTime, staffIds);
        }
        this.isFormValid();
    }));
  }

  toValidateStartEndTime(startTime, endTime) {
    startTime = moment(startTime, 'hh:mm:ss A');
    endTime = moment(endTime, 'hh:mm:ss A');
    let duration;
    if (startTime.isValid() && endTime.isValid()) {
      if (startTime.isAfter(endTime)) {
        endTime.set("date", endTime.get("date") + 1)
      }
      duration = moment.duration(endTime.diff(startTime));
      return duration.asMinutes();
    }
    return duration;
  }

  staffShiftsRange(startTime: string, endTime: string, staffIds: number[]) {
    if (staffIds?.length) {
      this.isValidBreakHour = true;
      staffIds.forEach(staffId => {
        if (this.isValidBreakHour) {
          let selectedStaff = this.staffSchedules?.filter(schedule => schedule.ServerId == staffId);
          let staffScheduleRange = selectedStaff?.find(schedule => (new Date(this.blockStaffForm?.form?.get('CurrentDate')?.value) >= new Date(schedule.StartDate) && new Date(this.blockStaffForm?.form?.get('CurrentDate')?.value) <= new Date(schedule.EndDate)));
          let shifts = staffScheduleRange?.Shifts?.filter(shift => shift.DayOfWeek == new Date(this.blockStaffForm?.form?.get('CurrentDate')?.value).getDay());
          let shiftIds = shifts?.map(({ ShiftId }) => ShiftId);
          let shiftDetail = this._cs.settings.value.Shifts?.filter(shift => shiftIds?.includes(shift.Id));
          this.validateBreakHoursRange(shiftDetail, startTime, endTime);
        }
      });
    }
  }

  validateBreakHoursRange(shiftDetail: ShiftDTO[], startTime: string, endTime: string) {
    let breakHourInRange: boolean = false;
    let shiftId: number;
    shiftDetail?.forEach(details => {
      let scheduleStartTime = this._stfPipe.transform(details.EffectiveRange.Start);
      let scheduleEndTime = this._stfPipe.transform(details.EffectiveRange.End);
      const shiftStart = moment(scheduleStartTime, 'hh:mm:ss A');
      const shiftEnd = moment(scheduleEndTime, 'hh:mm:ss A');                
      const breakStart = moment(startTime, 'hh:mm:ss A');
      const breakEnd = moment(endTime, 'hh:mm:ss A');
      if(shiftStart.isAfter(shiftEnd)){
        shiftEnd.set("date", shiftEnd.get("date") + 1)
      }
      if(breakStart.isAfter(breakEnd)){
        breakEnd.set("date", breakEnd.get("date") + 1)
      }
      let isBreakHourInShift = (breakStart.isBetween(shiftStart, shiftEnd) || breakStart.isSame(shiftStart)) && (breakEnd.isBetween(shiftStart, shiftEnd) || breakEnd.isSame(shiftEnd)) ;
      if(!isBreakHourInShift){
        breakStart.set("date", breakStart.get("date") + 1);
        breakEnd.set("date", breakEnd.get("date") + 1);
        isBreakHourInShift = breakStart.isBetween(shiftStart, shiftEnd) && breakEnd.isBetween(shiftStart, shiftEnd);
      }
      if(!isBreakHourInShift){
        return;
      }
      else {
        breakHourInRange = isBreakHourInShift;
        shiftId = details.Id;
      }
    });
    if(!breakHourInRange) {
      this.breakHourError = this._ts.instant('The hours selected does not match with the shift hours of the selected Instructors. Please select different time');
      this.isValidBreakHour = false;
      return;
    }
    else {
      this.breakHourError = null;
      this.isValidBreakHour = true;
      this.breakHourShiftId = shiftId;
    }
  }

  isFormValid() {
    if(this.blockStaffForm.valid && !this.breakHourError) {
      this._pp.saveBtnEnable$.next(true);
    }
    else {
      this._pp.saveBtnEnable$.next(false);
    }
  }

  ngOnDestroy() {
    if(this.subscriptions) {
      this.subscriptions.unsubscribe();
    }
  }

}
