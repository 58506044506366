<ng-container *ngIf="(timelineData  | timelineFilter : filterData : searchText) as finalDataList">
<div *cdkVirtualFor="let rowData of (finalDataList | sortData: sortBy);let i = index;trackBy:instructorData">
  <div class="table-timeline__row table-timeline__row--body">
    <div class="table-timeline__column sticky-left">
      <div class="instructor-image-view">
        <ng-container *ngIf="(rowData.CategoryId | activityColor) as categoryStyle">
          <div class="userprofile__instructor-initial"  [ngStyle]="{'backgroundColor': categoryStyle?.backgroundColor }">
            {{rowData | guestNameFormatter}}
          </div>
        </ng-container>
      </div>
      <span class="userprofile__instructor-name" [title]="rowData | instructorNameFormatter">
        {{rowData | instructorNameFormatter}}</span>
        <div class="expand-icon" *ngIf="rowData.showExpandButton">
          <img *ngIf="!rowData.expandMode" (click)="rowData.expandMode=true" src="../../../../assets/images/unfold_more.svg" alt="">
          <img *ngIf="rowData.expandMode" (click)="rowData.expandMode=false" src="../../../../assets/images/unfold_less.svg" alt="">
        </div>
    </div>
    <div class="table-row-container" gridColumnsGenerator="7" [columnWidth]="columnWidth * 4">      
      <div *ngFor="let dayData of rowData.columnData" class="day-column" [class.isDragged]="dragColumn == dayData.dayOfWeek" >
        <div class="drop-placeholder" *ngIf="dragHandlerService.dragStarted" [attr.rowId]="rowData.Id" [attr.rowName]="rowData.Name"> </div>
        <!-- [class.empty-column]="session.empty" [class.add-column]="session.empty && selectedViewId==2" -->
        <ng-container *ngIf="dayData && dayData.data && dayData.data.length; else empty " >
          <div class="day-column__session-card"
           [class.active]="draggedSession?.uniqId === session.uniqId" [hidden]="draggedSession?.uniqId === session.uniqId && dragHandlerService.scrollSubscriptionData" (click)="activitySelected(session, rowData , dayData?.date)"
           *ngFor="let session of (dayData.data | sortByTime: (dayData.date | localizedDate: 'yyyy-MM-DD')) | slice:0:(rowData.expandMode ? dayData.data.length : defaultMaxDisplay) " [style.transform]="draggedSession && draggedSession?.uniqId === session.uniqId && styleObject.transform" >
           <div *ngIf="selectedViewId != 1 && (session.isOpenBooking || session.ActivitySessionId || (session.isPrivateLessonBooking && !session.ReservedFor)) && (RolesAndPermissionsType.ModifyActivity | rolesAndPermission) && !cs.isIframeEnabled && session.StatusCode !== StatusMaster.Cancelled" class="drag-handler" cdkDrag cdkDragLockAxis="y" [cdkDragFreeDragPosition]="dragHandlerPosition" (cdkDragStarted)="dragStartHandler($event, rowData, session)" (cdkDragMoved)="dragMoveHandler($event)" (cdkDragReleased)="dropHandler($event)" ></div> 
           <div class="table-timeline__column no-border innerCard"
            >
            <div class="session-card-small" 
              [ngClass]="{'available-session': !session.isPrivateLessonBooking && session.StatusCode !== StatusMaster.Cancelled && (session.isOpenBooking || partyService.allWeekActivitySessions[session.SessionId]?.Available > 0 || partyService.allWeekActivitySessions['group_'+session.SessionGroupId]?.Available > 0 || (!partyService.allWeekActivitySessions[session.SessionId] && !partyService.allWeekActivitySessions['group_'+session.SessionGroupId] && session.Available > 0)), 'unavailable-session' : !session.isOpenBooking && (partyService.allWeekActivitySessions[session.SessionId]?.Available == 0 || (session.SessionGroupId && partyService.allWeekActivitySessions['group_'+session.SessionGroupId]?.Available == 0) || session.Available == 0), 'confirmed-session': session.isPrivateLessonBooking && session.ReservedFor, 'standby-session' : (session.isPrivateLessonBooking && !session.ReservedFor) || (session.MaxActivityStrength && session.AllowStandBy && partyService.allWeekActivitySessions[session.SessionId]?.Available == 0) , 'cancelled-session' : session.StatusCode == StatusMaster.Cancelled}"
              [style.backgroundColor]="(session.CategoryId | activityColor)?.backgroundColor">
              <!-- [category-indicator]="session.CategoryId" indicatorType="border" -->
              <!-- <i *ngIf="session.isPrivateLessonBooking" class="icon-private_class_booking_icon private-lesson-icon"></i> -->
              <div class="session-card-small__lesson-name" *ngIf="(selectedViewId==0 || selectedViewId==2 || session.isPrivateLessonBooking)">
                {{ (selectedViewId == 0 || selectedViewId == 2 ) ? session.className: '' }} 
                {{ (session.isPrivateLessonBooking ||  session.isOpenBooking) ? session.guestDetail : '' }} 
                <span *ngIf="session.Size">,&nbsp; <span class="icon icon-add-contact"></span> {{session.Size}}</span>
              </div>
              
              <!-- <div *ngIf="selectedViewId==1 && session.Locations && session.Locations.length" class="session-card-small__lesson-name">
                <span class="icon-Group-749"></span> 
                <span>&nbsp;{{session.Locations[0].Name}}</span>
                <span *ngIf="session.Locations.length > 1">&nbsp; + {{session.Locations.length - 1}}</span>
              </div> -->
              <div *ngIf="session.timeDetail && !session.isOpenBooking" class="session-card-small__location" [title]="session.timeDetail">
                <span >{{session.timeDetail}} </span>              
              </div>
              <div class="small" *ngIf="(session.MaxActivityStrength && session.AllowStandBy && partyService.allWeekActivitySessions[session.SessionId]?.Available == 0)">
                ({{'standByAvailability' | translate}})
              </div>
              <div *ngIf="selectedViewId==1 && session.Locations && session.Locations.length" class="session-card-small__location">
                <span class="session-card-small__lesson-name">
                  <!-- <span class="icon-Group-749"></span>  -->
                  <span class="icon icon-placeholder"  *ngIf="session.StatusCode !== StatusMaster.Cancelled"></span>
                  <span>&nbsp;{{session.Locations[0]?.Name}}</span>
                  <span *ngIf="session.Locations.length > 1" class="highlight">&nbsp; + {{session.Locations.length - 1}}</span>
                </span>
              </div>
              <div class="session-icons">
                <span *ngIf="session.activityCustomisation == 'Unblock'" class="icon-block_1"></span>
                <span class="icon-booking-cancel-icon" *ngIf="session.StatusCode == StatusMaster.Cancelled"  [matTooltip]="session?.cancelledBy" ><span
                    class="path1"></span><span class="path2"></span></span>
              </div>
              <div>
               
                <div *ngIf="!session.isOpenBooking &&  !session.isPrivateLessonBooking">
                  <span [ngbPopover]="blockPopover" (click)="sessionStatus($event,rowData,session)" container="body"
                    placement="bottom auto" [autoClose]="'outside'" #ps="ngbPopover" class="icon-legends icon-settings"></span>
                  <ng-template #blockPopover>
                    <div class="parties-grid__scrollSize">
                      <div class="reservation-notes">
                        <div class="reservation-notes__header seat-popover-header">
                          {{'settingsMenuText' | translate}}
                        </div>
                        <div class="reservation-notes__content seat-popover-content">
                          <div class="reservation-notes__notes-details seat-border-color" (click)="ps.close();promptBlockSession($event,rowData,session,dayData, sessionStatusText)">                                 
                              <span class="seat-grid-header" [class.disabled] = "!(RolesAndPermissionsType.ManageActivityBlocks | rolesAndPermission)"
                              >{{sessionStatusText}}
                              {{'SessionPluralText' | translate}}</span>
                              <!-- <span (click)="promptBlockSession($event,rowData,session, sessionStatusText);ps.close()" class="icon-dashboard-tick" style="font-size: 18px;"></span> -->
                           
                          </div>
                        </div>
                        <div class="reservation-notes__content seat-popover-content">
                          <div class="reservation-notes__notes-details seat-border-color"
                            (click)="ps.close();modifySession($event,rowData,session, dayData.date)">
                            <span class="seat-grid-header pointer" [class.disabled] = "!(RolesAndPermissionsType.ModifyActivity | rolesAndPermission)">
                              {{'ModifySession' | translate}}</span>
                          </div>
                        </div>
                        <div class="reservation-notes__content seat-popover-content">
                          <div class="reservation-notes__notes-details seat-border-color"
                            (click)="ps.close();cancelSession($event,rowData,session, dayData.date)">
                            <span class="seat-grid-header pointer" [class.disabled] = "cs.isIframeEnabled">
                              {{'cancelSession' | translate}}</span>
                          </div>
                        </div>
                      </div>
                    </div>
  
                  </ng-template>
                </div>
              </div>
              <div class="session-card-small__seats-row">
                <div class="session-card-small__seats-column session-card-small__seats-column--left"
                  *ngIf="!session.isOpenBooking && !session.isPrivateLessonBooking">
                  <div *ngIf="!session.SessionGroupId && partyService.allWeekActivitySessions[session.SessionId];else group_availability" class="session-card-small__seat-count session-card-small__seat-count--1" [ngClass]="{'cancel-details' : session.StatusCode == StatusMaster.Cancelled}">
                    <span *ngIf="session.StatusCode !== StatusMaster.Cancelled" matTooltip="{{partyService.TimeLineAvailablityShowType == TimeLineAvailablityShowTypeEnum.AvailableCapacityByTotalCapacity  ? ('AvailableToBook' | translate) : ('Booked' | translate)}}">
                      {{partyService.allWeekActivitySessions[session.SessionId]?.OverBooked && partyService.TimeLineAvailablityShowType == TimeLineAvailablityShowTypeEnum.AvailableCapacityByTotalCapacity ? 0 :
                        partyService.allWeekActivitySessions[session.SessionId] | sessionAvailability: 'numerator'}}</span> <span class="text-sub"
                        *ngIf="session.StatusCode !== StatusMaster.Cancelled" matTooltip="{{partyService.TimeLineAvailablityShowType == TimeLineAvailablityShowTypeEnum.BookedCapacityByAvailableCapacity  ? ('AvailableToBook' | translate) : ('TotalCapacity' | translate)}}">
                        {{partyService.TimeLineAvailablityShowType == TimeLineAvailablityShowTypeEnum.BookedCapacityByAvailableCapacity ? ' -- ' : '/' }}
                      {{ partyService.allWeekActivitySessions[session.SessionId] | sessionAvailability: 'denominator'  }}
                    </span>
                  <span class="overbooked-session-text"
                    *ngIf="partyService.allWeekActivitySessions[session.SessionId]?.OverBooked">({{'OverbookedBy' | translate}}
                    {{partyService.allWeekActivitySessions[session.SessionId]?.OverBooked}})</span>
                    <span class="cancel-session-text cancel-completed" *ngIf="session.StatusCode == StatusMaster.Cancelled && partyService.allWeekActivitySessions[session.SessionId]?.Cancelled">{{'cancelledBookings' | translate}}   {{ partyService.allWeekActivitySessions[session.SessionId]?.Cancelled}}  </span>
                    <span class="cancel-session-text cancel-pending" [matTooltip] ="('TobeCancelMessage' | translate)" *ngIf="session.StatusCode == StatusMaster.Cancelled && partyService.allWeekActivitySessions[session.SessionId]?.bookings">{{'TobeCancel' | translate}}  {{ partyService.allWeekActivitySessions[session.SessionId]?.bookings}} </span>
                    <!-- <span class="cancel-session-text no-booking" *ngIf="session.StatusCode == StatusMaster.Cancelled && partyService.allWeekActivitySessions[session.SessionId]?.totalBookings == 0">{{'noBookings' | translate}} </span> -->
                  </div>
                  <ng-template #group_availability>
                      <div
                        *ngIf="session.SessionGroupId && partyService.allWeekActivitySessions['group_'+session.SessionGroupId];else availability_detail"
                        class="session-card-small__seat-count session-card-small__seat-count--1" matTooltip="{{partyService.TimeLineAvailablityShowType == TimeLineAvailablityShowTypeEnum.AvailableCapacityByTotalCapacity  ? ('AvailableToBook' | translate) : ('Booked' | translate)}}">
                        {{ partyService.allWeekActivitySessions['group_'+session.SessionGroupId] | sessionAvailability: 'numerator'  }} <span class="text-sub"
                        matTooltip="{{partyService.TimeLineAvailablityShowType == TimeLineAvailablityShowTypeEnum.BookedCapacityByAvailableCapacity  ? ('AvailableToBook' | translate) : ('TotalCapacity' | translate)}}">
                        {{partyService.TimeLineAvailablityShowType == TimeLineAvailablityShowTypeEnum.BookedCapacityByAvailableCapacity ? ' -- ' : '/' }}
                          {{ partyService.allWeekActivitySessions['group_'+session.SessionGroupId] | sessionAvailability: 'denominator'  }}
                        </span>
                        <span class="overbooked-session-text"
                          *ngIf="partyService.allWeekActivitySessions['group_'+session.SessionGroupId]?.OverBooked">({{'OverbookedBy' |
                          translate}} {{partyService.allWeekActivitySessions['group_'+session.SessionGroupId]?.OverBooked}})</span>
                      </div>
                </ng-template>
                    <ng-template #availability_detail>
                      <div class="session-card-small__seat-count session-card-small__seat-count--1">
                        <span matTooltip="{{partyService.TimeLineAvailablityShowType == TimeLineAvailablityShowTypeEnum.AvailableCapacityByTotalCapacity  ? ('AvailableToBook' | translate) : ('Booked' | translate)}}"> {{ session | sessionAvailability: 'numerator'  }} </span><span
                              class="text-sub" matTooltip="{{partyService.TimeLineAvailablityShowType == TimeLineAvailablityShowTypeEnum.BookedCapacityByAvailableCapacity  ? ('AvailableToBook' | translate) : ('TotalCapacity' | translate)}}" >
                              {{partyService.TimeLineAvailablityShowType == TimeLineAvailablityShowTypeEnum.BookedCapacityByAvailableCapacity ? ' -- ' :'/' }}
                              {{ session | sessionAvailability: 'denominator'  }}
                            </span>
                    
                      </div>
                    </ng-template>
                </div>
                <!-- <div *ngIf="session.isOpenBooking || session.isPrivateLessonBooking;"
                  class="session-card-small__seats-column session-card-small__seats-column--left">
                  <div class="session-card-small__seat-count session-card-small__seat-count--1">
                    {{session.Duration}} {{'mins' | translate}}
                  </div>
                </div> -->
                <div class="session-card-small__seats-column session-card-small__seats-column--left"
                  *ngIf="session.isPrivateLessonBooking || session.isOpenBooking">
  
                  <i *ngIf="session.isPrivateLessonBooking" class="icon-private_class_booking_icon private-lesson-icon" style="font-size: 22px;"></i>
                  <div *ngIf="session.timeDetail && session.isOpenBooking" class="session-card-small__location" [title]="session.timeDetail">
                    <span >{{session.timeDetail}}</span>              
                  </div>
                  <span class="overbooked-session-text overbook-ellipsis p-0 "
                  *ngIf="session?.OverBooked && session?.OverBooked > 0" [matTooltip] ="('OverbookedBy' | translate) + ' ' + session?.OverBooked">({{'OverbookedBy' | translate}}
                  {{session?.OverBooked}})</span>
                </div>
                <div class="session-card-small__seats-column session-card-small__seats-column--right">                  
                  <i *ngIf="session.isVIP || partyService.allWeekActivitySessions[session.SessionId]?.isVIP || partyService.allWeekActivitySessions['group_'+session.SessionGroupId]?.IsVIP" class="icon-VIP" [ngClass]="{'VIP-selected':session.isVIP || partyService.allWeekActivitySessions[session.SessionId]?.isVIP || partyService.allWeekActivitySessions['group_'+session.SessionGroupId]?.IsVIP}"></i>
                </div>
              </div>
              <div class="head-line">
                <span> <i class="icon-public" matTooltip="{{'PublicQuickNote' | translate}}" *ngIf="session.SlotPublicHeadline"></i>{{session.SlotPublicHeadline}}</span>
               <span> <i class="icon-public_off" matTooltip="{{'PrivateQuickNote' | translate}}" *ngIf="session.SlotPrivateHeadline"></i>{{session.SlotPrivateHeadline}}</span>
              </div>
            </div>
            <div class="lock-icon">              
              <span *ngIf="session.addonblocked" class="icon-No-sahre2"><span class="path1"></span><span class="path2"></span><span class="path3"></span></span>
            </div>

          </div>
          </div>
          <div class="add-lesson flat" (click)="addBookingToSlot(rowData,dayData.date, i)" *ngIf="selectedViewId==2 && dayData?.data?.length">
            +
          </div>
          <div class="show-more-label" *ngIf="!rowData.expandMode && dayData.data && dayData.data.length > defaultMaxDisplay" (click)="rowData.expandMode=true">
            + {{dayData.data.length - defaultMaxDisplay}}
          </div>
        </ng-container>
        
        <ng-template #empty>
          <div class="innerCard addLesson" *ngIf="selectedViewId==2">
            <span class="add-lesson" (click)="addBookingToSlot(rowData,dayData.date, i)">+</span>
          </div>
          <div *ngIf="selectedViewId!=2" class="table-timeline__column no-border innerCard emptyCard"> </div>
        </ng-template>
      </div>
    </div>
  </div>
</div>
<div class="noDataFound" style="padding: 10px 0;text-align: center;" *ngIf="!finalDataList || (finalDataList && !finalDataList.length)">
  {{'noDataFound' | translate}}
</div>
</ng-container>
