import { ChangeDetectorRef, Component, Directive, ElementRef, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, Pipe, PipeTransform, SimpleChange } from "@angular/core";
import { AppService } from "@app/app.service";
import { AttributeType, ClassType, ComponentTypes, GetReservationsOperationOptions, LessonType, PartyState, PartyType, RolesAndPermissionsType, SlottingType, Status, TimeLineAvailablityShowTypes, ViewBy } from "@app/shared/constants/commonenums";
import { DateStateDTO } from "@app/shared/models/InputReservation";
import { ActivityCustomizationAppliesTo, SettingsDTO } from "@app/shared/models/RestaurantDTO";
import { PartyService } from "@app/shared/services/party.service";
import { Utilities } from "@app/shared/utilities/utilities";
import { TranslateService } from "@ngx-translate/core";
import _, { groupBy, orderBy, sortBy } from "lodash";
import moment from "moment";
import { Subscription } from "rxjs";
import { format } from "date-fns";
import { CacheService } from "@app/core/services/cache.service";
import { DashboardFunctions } from "@app/shared/utilities/dashboard-functions";
// import { Session } from "protractor";
import { ActivitiesTimelineService, ModifyConfirmationData } from "@app/activities-timeline/services/activities-timeline.service";
import { DueState, PaidState } from "@app/shared/constants/globalConstants";
import { PartyPrepaymentState } from "@app/shared/models/InputContact";
import { TimelineDragHandlerService } from "@app/activities-timeline/services/timeline-scroll-handler.service";
import { ModifySessionRequestState } from "@app/shared/models/global.interface";

@Component({
  selector: 'app-weekly-view',
  templateUrl: './weekly-view.component.html',
  styleUrls: ['./weekly-view.component.scss']
})

export class WeeklyViewComponent implements OnInit, OnChanges, OnDestroy {
  private _dataList: any;
  dragColumn: any;

  @Input('dataList')
  set dataList(data) {
    this._dataList = data;
  };
  public rowDataDate;
  @Input('viewBy') selectedViewId;
  @Input('columnWidth') columnWidth;
  @Input('searchText') searchText;
  @Input('settings') _settings;
  @Output('addBookingToSlot') addBookingToSlotEvent = new EventEmitter();
  @Output('activitySelected') activitySelectedEvent = new EventEmitter();
  @Input('filterData') filterData;

  startDayOfweek;
  defaultMaxDisplay: number = 3;
  sessionStatusText:string ='';
  filteredDataList = [];
  timelineData = [];
  subscriptions: Subscription = new Subscription();
  getReservationSubscriptions: Subscription = new Subscription();
  // allreservations = [];
  // allActivitySessions = {} as any;
  intialSubscritionParty: boolean = true;
  intialSubscritionStandByParty: boolean = true;
  TimeLineAvailablityShowTypeEnum =  TimeLineAvailablityShowTypes;
  sortBy = ['CategoryName', (data) => { return data.SortOrder ? data.SortOrder : undefined }, "Name"]
  @Output() modifySessionEvent = new EventEmitter();
  @Output() cancelSessionEvent = new EventEmitter();
  @Input('timelineCommonFilters') timelineCommonFilters;
  settingsUpdated: boolean = false;

  dragStarted: boolean = false;
  draggedSession: any;
  draggedRow: any;
  styleObject = {
    transform: 'none'
  }
  dragHandlerPosition: {x: number, y: number} = {x: 0, y: 0};
  @Input('viewPort') viewPortContainer: any;
  temp: boolean = true;
  RolesAndPermissionsType = RolesAndPermissionsType;
  StatusMaster = Status;

  constructor(private appService: AppService, public partyService: PartyService, public translateService: TranslateService,
    private cd: ChangeDetectorRef, public cs: CacheService, private dashboardFunctions: DashboardFunctions, private ats: ActivitiesTimelineService, public dragHandlerService: TimelineDragHandlerService) {
    this.rowDataDate = new Date(this.appService.headerSelectedDate);
    // this.ats.viewPort = null;
  }

  ngOnInit(): void {
    this.subscriptions.add(this.partyService.Parties$.subscribe(parties => {
      if (Utilities.RestaurantId() === this.appService.restaurantId && this.partyService.noOfDaysLoaded === 7 && !this.intialSubscritionParty) {
        this.filteredDataList = [];
        this.partyService.allWeekActivitySessions = {};
        this.updateView();
      }
      this.intialSubscritionParty = false;
    }));
    this.subscriptions.add(this.partyService.StandbyParties$.subscribe(parties => {
      if (Utilities.RestaurantId() === this.appService.restaurantId && this.partyService.noOfDaysLoaded === 7 && !this.intialSubscritionStandByParty) {
        this.filteredDataList = [];
        this.partyService.allWeekActivitySessions = {};
        this.updateView();
      }
      this.intialSubscritionStandByParty = false;
    }));
    this.subscriptions.add(this.appService.headerSelectedDate$.subscribe((date) => {
      this.rowDataDate = new Date(date);
      // this.filteredDataList = [];
      // this.initReservations();
      // if(this.partyService.noOfDaysLoaded === 7){

      this.filteredDataList = [];
      this.partyService.allWeekActivitySessions = {};

      if (this.getReservationSubscriptions) {
        this.getReservationSubscriptions.unsubscribe();
        this.getReservationSubscriptions = new Subscription();
      }
      this.updateView();


      // }
    }));
    this.subscriptions.add(this.cs.settings.subscribe(sett => {
      if(sett && Utilities.RestaurantId() === this.appService.restaurantId){
        this.settingsUpdated = true;
        this.updateView();
      }
    }));
  }

  dragStartHandler(event, rowData, session){
    if(event.event.type === 'touchmove'){
      this.dragHandlerService.dragStartPoints.left = event.event.touches[0].clientX + 2;
      this.dragHandlerService.dragStartPoints.top = event.event.touches[0].clientY + 2;
    }else if(event.event.type === "mousemove"){
      this.dragHandlerService.dragStartPoints.left = event.event.clientX + 2;
      this.dragHandlerService.dragStartPoints.top = event.event.clientY + 2;
    }
    this.dragHandlerService.dragStarted = true;
    this.dragStarted = true;
    this.draggedSession = session;
    this.dragColumn = session.Dayofweek;
    this.draggedRow = rowData;
  }

  dragMoveHandler(event){
    let distance;
    if(event.event.type === 'touchmove'){
      distance = {
        x: event.event.touches[0].clientX - this.dragHandlerService.dragStartPoints.left,
        y: event.event.touches[0].clientY - this.dragHandlerService.dragStartPoints.top,
      }
      let elm = document.elementFromPoint(event.event.touches[0].clientX, event.event.touches[0].clientY);

      if(elm){

        let direction : 1 | -1 | any = +elm.getAttribute("direction");
        let axis : 'left' | 'top' | any = elm.getAttribute("axis");
  
        let rowId: any = +elm.getAttribute("rowId");
        if(rowId){
          this.dragHandlerService.hoveredRowId = rowId;
        }
  
        if(direction && axis){
          !this.dragHandlerService.scrollSubscriptionData && this.dragHandlerService.autoScrollHandler(direction, this.ats.viewPort, axis);
        }else if(this.dragHandlerService.scrollSubscriptionData){
          this.dragHandlerService.mouseLeave();
        }
      }else if(this.dragHandlerService.scrollSubscriptionData){
        this.dragHandlerService.mouseLeave();
      }

    }else if(event.event.type === "mousemove"){
      distance = {
        x: event.event.clientX - this.dragHandlerService.dragStartPoints.left,
        y: event.event.clientY - this.dragHandlerService.dragStartPoints.top,
      }
    }else {
      return;
    }
    this.styleObject.transform = `translate(0px,${distance.y}px)`

    
  }

  dropHandler(event){
    let propertyId: string;
    let propertyName: string;

    if (event.event.type == 'touchend') {
      let touchPoints = event.event.changedTouches[0];
      let dropElm = document.elementFromPoint(touchPoints.clientX, touchPoints.clientY);
      propertyId = dropElm.getAttribute("rowId");
      propertyName = dropElm.getAttribute("rowName");
    } else{
      propertyId = event.event.target.getAttribute("rowId");
      propertyName = event.event.target.getAttribute("rowName");
    }

    this.dragHandlerService.dragStarted = false;
    this.dragHandlerService.hoveredRowId = null;
    this.dragHandlerPosition = {x: 0, y:0}

    if(propertyId && propertyName){
      let changes = [];
      let subText, title = '';
  
      let changeLabel = this.selectedViewId == ViewBy.Instructor ? 'instructor' : this.selectedViewId == ViewBy.location ? 'location' : '';
      propertyId != this.draggedRow.Id && changes.push({label: changeLabel, newValue: `${propertyName}`, oldValue: `${this.draggedRow.Name}`, newPropertyId: `${propertyId}`});
      subText = this.draggedSession.timeDetail;

      if(this.draggedSession.isOpenBooking || this.draggedSession.isPrivateLessonBooking){
        title = this.draggedSession.guestDetail;
      } else{
        title = this.draggedSession.className;
      }

      if(changes.length){      
        let data: ModifyConfirmationData = {
          title,
          subText,
          changes,
        }
        if (this.draggedSession.isOpenBooking || this.draggedSession.isPrivateLessonBooking) {
          this.ats.getBooking(data, this.draggedSession);
        }
        else if (this.draggedSession.ActivitySessionId) {
          this.sessionModifyConfirmation(data);
        }
      }
    }
    this.draggedRow = null;
    this.draggedSession = null;
    this.dragStarted = false;
   
  }

  sessionModifyConfirmation(modifyData) {
    this.draggedSession.SessionLocationMappings?.map(location => {
      location.ActivitySessionId = this.draggedSession.ParentSessionId ? location.ActivitySessionId : 0,
        location.Id = this.draggedSession.ParentSessionId ? location.Id : 0,
        location.StandaloneTableId = location.StandaloneTableId
    })
    this.draggedSession.SessionStaffMappings?.map(staff => {
      staff.ActivitySessionId = this.draggedSession.ParentSessionId ? staff.ActivitySessionId : 0,
        staff.Id = this.draggedSession.ParentSessionId ? staff.Id : 0,
        staff.StandaloneTableId = staff.ServerId
    })
    let locationIndex = modifyData.changes?.find(elem => elem.label == 'location');
    if (this.draggedSession.SessionLocationMappings?.length && locationIndex) {
      this.draggedSession.SessionLocationMappings[0].StandaloneTableId = Number(locationIndex.newPropertyId);
    }
    this.draggedSession.TableIds = locationIndex ? [Number(locationIndex.newPropertyId)] : this.draggedSession.TableIds;
    let instructorIndex = modifyData.changes?.find(elem => elem.label == 'instructor');
    if (this.draggedSession.SessionStaffMappings?.length && instructorIndex) {
      this.draggedSession.SessionStaffMappings[0].ServerId = Number(instructorIndex.newPropertyId);
    }
    let ActivitySessionId = this.draggedSession.ParentSessionId ? this.draggedSession.ActivitySessionId : 0
    const reqObj: ModifySessionRequestState[] = [{
      ActivitySessionId,
      ParentSessionId: this.draggedSession.ParentSessionId ? this.draggedSession.ParentSessionId : this.draggedSession.ActivitySessionId,
      Dayofweek: this.draggedSession.Dayofweek,
      SpecialMealId: this.draggedSession.SpecialMealId,
      Index: this.draggedSession.Index,
      SessionDate: moment(this.draggedSession.Date).format('YYYY-MM-DD') + "T00:00:00.000",
      Reason: this.draggedSession.Reason,
      AllocationTypeId: null,
      StartTime: this.draggedSession.StartTime,
      EndTime: this.draggedSession.EndTime,
      SessionLocationMappings: this.draggedSession.SessionLocationMappings,
      SessionStaffMappings: this.draggedSession.SessionStaffMappings,
      SessionCustomization: this.draggedSession.SessionCustomization,
      SessionGroupId: this.draggedSession.SessionGroupId
    }];
    modifyData.request = reqObj;
    this.ats.updateConfirmation(modifyData);
  }


  ngAfterViewInit(){
    this.subscriptions.add(this.partyService.activityBlockingChange$.subscribe(parties => {
      this.filteredDataList = [];
      this.partyService.allWeekActivitySessions = {};
      this.updateView();
    }));
  }

  ngOnChanges(changes: { [propName: string]: SimpleChange }) {
    if(!this.ats.viewPort){
      this.ats.viewPort = this.viewPortContainer;
    }
    let _filterChangesOnly = true;
    if (changes['rowDataDate'] && changes['rowDataDate'].previousValue != changes['rowDataDate'].currentValue
      || changes['selectedViewId'] && changes['selectedViewId'].previousValue != changes['selectedViewId'].currentValue
      || changes['searchText'] && changes['searchText'].previousValue != changes['searchText'].currentValue
      || changes['_dataList'] && _.isEqual(changes['_dataList'].previousValue, changes['_dataList'].currentValue)) {
        _filterChangesOnly = false;
        if(this.settingsUpdated){
          this.updateView();
        }
    }

    if(_filterChangesOnly && changes['timelineCommonFilters']) {
      this.bookedSessionsFilterHandler();
    }
  }

  updateView() {
    this.startDayOfweek = this.rowDataDate.getDay();
    Promise.resolve().then(() => {
      if (this.selectedViewId === ViewBy.Lessons) {
        this.setDataByClass();
      } else if (this.selectedViewId === ViewBy.Instructor) {
        this.setDataByInstructor();
      } else if (this.selectedViewId === ViewBy.location) {
        this.setDataByLocation();
      }
      this.initReservations();
      this.bookedSessionsFilterHandler();
    })
  }

  initReservations() {
    let [start, end] = Utilities.getWeekDateRange(this.rowDataDate || this.appService.headerSelectedDate);
    if (moment(start).format('YYYY-MM-DD') !== moment(this.partyService.weekStartDate).format('YYYY-MM-DD') || moment(end).format('YYYY-MM-DD') !== moment(this.partyService.weekEndDate).format('YYYY-MM-DD')) {
      this.partyService.weekStartDate = start;
      this.partyService.weekEndDate = end;
      this.partyService.allWeekActivitySessions = {};
      this.partyService.allWeekreservations = [];
      this.partyService.noOfDaysLoaded = 0;
      this.loadMultiplereservations(start, end)
    } else {
      this.partyService.allWeekActivitySessions = {};
      this.partyService.allWeekreservations = [];
      this.loadReservationsFromCache();
    }
  }

  loadReservationsFromCache() {
    if (this.partyService.noOfDaysLoaded >= 7) {
      this.processReservations([...this.partyService.Parties$.value, ...this.partyService.StandbyParties$.value]);
      this.bookedSessionsFilterHandler();
      this.setAddonBlocker();
    }
  }

  loadMultiplereservations(start, end) {
    let currentPropertyId = Utilities.RestaurantId();
    this.partyService.weekStartDate = start;
    this.partyService.weekEndDate = end;
    this.partyService.noOfDaysLoaded = 0;
    let currdate = new Date(start.getTime());
    this.dateIterator(currdate, currentPropertyId, end);
  }

  dateIterator(currdate, currentPropertyId, end) {
    this.loadReservations(currdate, currdate, currentPropertyId);
    currdate.setDate(currdate.getDate() + 1);
    if (currdate.getTime() <= end.getTime()) {
      this.dateIterator(currdate, currentPropertyId, end)
    }

    // ((currdate) => {
    //       setTimeout(() => {
    //         currdate.setDate(currdate.getDate() + 1);
    //         if(currdate.getTime() <= end.getTime()){
    //           this.dateIterator(currdate, currentPropertyId, end)
    //         }
    //       }, 2000 * currdate.getDay())
    // })(new Date(currdate.getTime()))
  }

  loadReservations(startDate, endDate, currentPropertyId) {
    this.getReservationSubscriptions.add(this.partyService.getParties(startDate, endDate, GetReservationsOperationOptions.Full, currentPropertyId).subscribe((data) => {
      if (data) {
        data.Payload[0].ActivityBookings = data.Payload[0].ActivityBookings.filter((reservation: any) => {
          if (reservation.BookedSessions) {
            reservation.BookedSessions = reservation.BookedSessions.filter(bookedSession => format(data.Payload[0].Date, 'MM/DD/YYYY') === format(bookedSession.BookedDate, 'MM/DD/YYYY'));
            return !!reservation.BookedSessions.length
          } else if (reservation.SpecialMealId && reservation.SessionGroupId) {
            return true;
          } else {
            return format(data.Payload[0].Date, 'MM/DD/YYYY') === format(reservation.ReservedFor || reservation.WishedTime, 'MM/DD/YYYY')
          }
        })
        data.Payload[0].ActivityStandByBookings = data.Payload[0].ActivityStandByBookings.filter((reservation: any) => {
          if (reservation.BookedSessions) {
            reservation.BookedSessions = reservation.BookedSessions.filter(bookedSession => format(data.Payload[0].Date, 'MM/DD/YYYY') === format(bookedSession.BookedDate, 'MM/DD/YYYY'));
            return !!reservation.BookedSessions.length
          } else if (reservation.SpecialMealId && reservation.SessionGroupId) {
            return true;
          } else {
            return format(data.Payload[0].Date, 'MM/DD/YYYY') === format(reservation.ReservedFor || reservation.WishedTime, 'MM/DD/YYYY')
          }
        })
        let currentReservations: DateStateDTO[] = [data.Payload[0]];

        this.partyService.allWeekreservations = [...this.partyService.allWeekreservations, ...currentReservations];


        let filteredActivityBookings = [...currentReservations[0].ActivityBookings];
        this.processReservations(filteredActivityBookings);
        if (this.selectedViewId === ViewBy.Lessons) {
          let filteredActivityStandByBookings = currentReservations[0].ActivityStandByBookings.filter((reservation: any) => format(currentReservations[0].Date, 'MM/DD/YYYY') === format(reservation.ReservedFor || reservation.WishedTime, 'MM/DD/YYYY'));
          this.processReservations(filteredActivityStandByBookings, 'standBy');
        }        
        this.bookedSessionsFilterHandler();
        this.partyService.noOfDaysLoaded += 1;
        if (this.partyService.noOfDaysLoaded === 7) {
          this.intialSubscritionParty = true;
          this.intialSubscritionStandByParty = true;
          let propertySlottingType = this.cs.propertySettings.value && this.cs.propertySettings.value[currentPropertyId].settings ? this.cs.propertySettings.value[currentPropertyId].settings.PropertySetting[0]?.SlottingType : this.cs.settings.value.PropertySetting[0]?.SlottingType;
          if (propertySlottingType == SlottingType.NonDining) {
            this.dashboardFunctions.LoadPartiesForNonDinningProperties(this.partyService.allWeekreservations);
            this.setAddonBlocker()
          }
        }

      }
    }))
  }

  processReservations(currentReservations: any[], key = '') {
    currentReservations.forEach(reservation => {
      if (reservation.State == PartyState.Pending || reservation.State == PartyState.Seated || reservation.State == PartyState.Left) {
        this.setReservationOnGrid(reservation);
      }
    });
  }

  bookedSessionsFilterHandler() {
    let tempActivityList = _.cloneDeep(this.filteredDataList) || [];
    const filtersObj = this.partyService.timelineCommonFilters;
    let isGuestTypeFilterSelected; Object.keys(filtersObj.GuestTypeFilterState).forEach(e => filtersObj.GuestTypeFilterState[e] ? isGuestTypeFilterSelected = true : '');
    let isPaymentStatusFilterSelected; Object.keys(filtersObj.PaymentTypeFilterState).forEach(e => filtersObj.PaymentTypeFilterState[e] ? isPaymentStatusFilterSelected = true : '');
    let isActivityFilterSelected; Object.keys(filtersObj.ActivityFilterState).forEach(e => filtersObj.ActivityFilterState[e] ? isActivityFilterSelected = true : '');
    // if (isGuestTypeFilterSelected || isPaymentStatusFilterSelected)
      this.timelineData = tempActivityList?.map(x => {
        x.columnData.forEach(dayobj => {
          dayobj.data =  dayobj.data?.filter(sessionBookObj =>{ 
            
            // if (!y.ActivitySessionId) return false;

            let isGuestTypeFilterMatched = false;
            let isPaymentStatusFilterMatched = false;
            let isActivityStatusFilterMatched = false;

            if(this.selectedViewId !== ViewBy.Lessons && sessionBookObj.Type === PartyType.StandBy){
              return false;
            }

            if (!sessionBookObj.isPrivateLessonBooking && !sessionBookObj.isOpenBooking) {

              if (this.partyService.timelineCommonFilters.GuestTypeFilterState.showBookedSessions)
                isGuestTypeFilterMatched = this.partyService.allWeekActivitySessions[sessionBookObj.uniqId]?.parties.length;
              if (this.partyService.timelineCommonFilters.GuestTypeFilterState.showBookingConfirmedSessions)
                isGuestTypeFilterMatched = (this.partyService.allWeekActivitySessions[sessionBookObj.uniqId]
                  ? this.partyService.allWeekActivitySessions[sessionBookObj.uniqId]?.Available < this.partyService.allWeekActivitySessions[sessionBookObj.uniqId]?.MaxActivityStrength
                  : sessionBookObj.Available < sessionBookObj.MaxActivityStrength);
              if (!isGuestTypeFilterMatched && this.partyService.timelineCommonFilters.GuestTypeFilterState.showStandbyBookedSessions)
                isGuestTypeFilterMatched = this.partyService.allWeekActivitySessions[sessionBookObj.uniqId]?.StandbyBooked > 0;
              if (!isGuestTypeFilterMatched && this.partyService.timelineCommonFilters.GuestTypeFilterState.showOverbookedSessions)
                isGuestTypeFilterMatched = this.partyService.allWeekActivitySessions[sessionBookObj.uniqId]?.OverBooked > 0;
              if (!isGuestTypeFilterMatched && !isGuestTypeFilterSelected) isGuestTypeFilterMatched = true;

              if (this.partyService.timelineCommonFilters.PaymentTypeFilterState.showPaidBookings)
                isPaymentStatusFilterMatched = this.partyService.allWeekActivitySessions[sessionBookObj.uniqId]?.hasPaidBookings;
              if (!isPaymentStatusFilterMatched && this.partyService.timelineCommonFilters.PaymentTypeFilterState.showPartialPaidBookings)
                isPaymentStatusFilterMatched = this.partyService.allWeekActivitySessions[sessionBookObj.uniqId]?.hasDuePaymentBookings;
              if (!isPaymentStatusFilterMatched && this.partyService.timelineCommonFilters.PaymentTypeFilterState.showUnpaidBookings)
                isPaymentStatusFilterMatched = this.partyService.allWeekActivitySessions[sessionBookObj.uniqId]?.hasUnpaidBookings;
              if (!isPaymentStatusFilterMatched && !isPaymentStatusFilterSelected) isPaymentStatusFilterMatched = true;


              if(!isActivityStatusFilterMatched && this.partyService.timelineCommonFilters.ActivityFilterState.showCancelledSessions)
                isActivityStatusFilterMatched = sessionBookObj.StatusCode == Status.Cancelled
              if(!isActivityStatusFilterMatched && this.partyService.timelineCommonFilters.ActivityFilterState.showBlockedSessions)
                isActivityStatusFilterMatched = sessionBookObj.activityCustomisation == 'Unblock'
                if(!isActivityStatusFilterMatched && !isActivityFilterSelected) isActivityStatusFilterMatched = true; 

            } else if (sessionBookObj.isPrivateLessonBooking || sessionBookObj.isOpenBooking) {

              if (this.partyService.timelineCommonFilters.GuestTypeFilterState.showBookedSessions)
                isGuestTypeFilterMatched = true;
              if (!isGuestTypeFilterMatched && this.partyService.timelineCommonFilters.GuestTypeFilterState.showBookingConfirmedSessions)
                isGuestTypeFilterMatched = sessionBookObj.Type !== PartyType.StandBy;
              if (!isGuestTypeFilterMatched && this.partyService.timelineCommonFilters.GuestTypeFilterState.showStandbyBookedSessions)
                isGuestTypeFilterMatched = sessionBookObj.Type == PartyType.StandBy;
              if (!isGuestTypeFilterMatched && this.partyService.timelineCommonFilters.GuestTypeFilterState.showOverbookedSessions)
                isGuestTypeFilterMatched = sessionBookObj?.OverBooked > 0;
              if (!isGuestTypeFilterMatched && !isGuestTypeFilterSelected) isGuestTypeFilterMatched = true;

              if (this.partyService.timelineCommonFilters.PaymentTypeFilterState.showPaidBookings)
                isPaymentStatusFilterMatched = PaidState.includes(sessionBookObj.PrepaymentState);
              if (!isPaymentStatusFilterMatched && this.partyService.timelineCommonFilters.PaymentTypeFilterState.showPartialPaidBookings)
                isPaymentStatusFilterMatched = DueState.includes(sessionBookObj.PrepaymentState);
              if (!isPaymentStatusFilterMatched && this.partyService.timelineCommonFilters.PaymentTypeFilterState.showUnpaidBookings)
                isPaymentStatusFilterMatched = sessionBookObj.PrepaymentState == PartyPrepaymentState.PrepaymentRequired && sessionBookObj.State !== PartyState.Cancelled
              if (!isPaymentStatusFilterMatched && !isPaymentStatusFilterSelected) isPaymentStatusFilterMatched = true;
            }

            return isGuestTypeFilterMatched && isPaymentStatusFilterMatched && isActivityStatusFilterMatched;

            }) || [];
          return dayobj;
        });
        return x;
      })?.filter(activities => {
        let hasBookings = false;
        activities.columnData.forEach(data => {
          if(data?.data?.length) hasBookings = true;
        });
        return hasBookings;
      });
    // else
    //   this.timelineData = _.cloneDeep(this.filteredDataList) || [];

  }


  setAddonBlocker() {
    for (let i = 0; i < this.filteredDataList.length; i++) {
      this.filteredDataList[i]?.columnData?.forEach(column => {
        let filteredDate = column.date;
        column?.data?.forEach(filteredData => {
          if (filteredData.SpecialMealId && typeof filteredData.StartTime == 'string') {
            let [shr, smin, ssec] = filteredData.StartTime.split(":");
            let [ehr, emin, esec] = filteredData.EndTime.split(":");
            // filteredData.StartTime = this.getDate(+shr, +smin, +ssec,filteredDate);
            // filteredData.EndTime = this.getDate(+ehr, +emin, +esec, filteredDate);
            filteredData.addonblocked = this.partyService.getAddonBlocker(filteredDate, this.getDate(+shr, +smin, +ssec, filteredDate), this.getDate(+ehr, +emin, +esec, filteredDate), false, filteredData.SpecialMealId)
          }
        })

      });

    }
  }

  setReservationOnGrid(reservation) {
    let specialMeal = this._settings.SpecialMeals.find(s => s.Id == reservation.SpecialMealId);
    if (specialMeal && specialMeal.ClassType === ClassType.Session && reservation.BookedSessions && reservation.SpecialMealId) {
      specialMeal.StandbyBooked = 0;
      // if (reservation.Type !== PartyType.StandBy) {
        reservation.BookedSessions?.forEach(bookedSession => {
          if ((bookedSession.SessionState == PartyState.Pending || bookedSession.SessionState == PartyState.Seated || bookedSession.SessionState == PartyState.Left)
            && moment(format(bookedSession.BookedDate, 'MM/DD/YYYY')).isBetween(format(this.partyService.weekStartDate, 'MM/DD/YYYY'), format(this.partyService.weekEndDate, 'MM/DD/YYYY'), undefined, '[]')
          ) {
            if (!this.partyService.allWeekActivitySessions[bookedSession.ActivitySessionId]) {
              this.partyService.allWeekActivitySessions[bookedSession.ActivitySessionId] = this.getDataBySessionId(specialMeal, bookedSession.ActivitySessionId)
            }

            // const MaxActivityStrength = this.partyService.allWeekActivitySessions[bookedSession.ActivitySessionId]?.SessionCustomization?.MaxActivityStrength 
            //         ? this.partyService.allWeekActivitySessions[bookedSession.ActivitySessionId]?.SessionCustomization.MaxActivityStrength : specialMeal.MaxActivityStrength;
            
            // this.partyService.allWeekActivitySessions[bookedSession.ActivitySessionId].MaxActivityStrength = MaxActivityStrength;
            // this.partyService.allWeekActivitySessions[bookedSession.ActivitySessionId].Available = MaxActivityStrength - bookedSession.BookingSize;
            // this.partyService.allWeekActivitySessions[bookedSession.ActivitySessionId].Booked = bookedSession.BookingSize;
            if (reservation.Contact?.IsVip) {
              this.partyService.allWeekActivitySessions[bookedSession.ActivitySessionId].isVIP = true;
            }
            //             let [shr, smin, ssec] = this.partyService.allWeekActivitySessions[bookedSession.ActivitySessionId].StartTime.split(":");
            // let [ehr, emin, esec] = this.partyService.allWeekActivitySessions[bookedSession.ActivitySessionId].EndTime.split(":");
            // this.partyService.allWeekActivitySessions[bookedSession.ActivitySessionId].StartTime = this.getDate(+shr, +smin, +ssec,bookedSession.BookedDate);
            // this.partyService.allWeekActivitySessions[bookedSession.ActivitySessionId].EndTime = this.getDate(+ehr, +emin, +esec, bookedSession.BookedDate);
            //   this.partyService.allWeekActivitySessions[bookedSession.ActivitySessionId].addonblocked = this.partyService.getAddonBlocker(bookedSession.BookedDate, this.partyService.allWeekActivitySessions[bookedSession.ActivitySessionId].StartTime,this.partyService.allWeekActivitySessions[bookedSession.ActivitySessionId].EndTime,true,reservation.SpecialMealId);
            this.partyService.allWeekActivitySessions[bookedSession.ActivitySessionId].parties.push(reservation);

            let bookedSessions = this.partyService.allWeekActivitySessions[bookedSession.ActivitySessionId].parties.filter(party =>party.BookedSessions?.length &&  party.Type === PartyType.Reservation && (party.State == PartyState.Pending || party.State == PartyState.Seated  || party.State == PartyState.Left)).map(p=>p.BookedSessions).flat();
            let numberOfBookings = 0;
            let numberOfOverBooked = 0;

            bookedSessions?.forEach(session => {
              if(session.ActivitySessionId == bookedSession.ActivitySessionId && moment(format(session.BookedDate, 'MM/DD/YYYY')).isBetween(format(this.partyService.weekStartDate, 'MM/DD/YYYY'), format(this.partyService.weekEndDate, 'MM/DD/YYYY'), undefined, '[]') && session.SessionState !== PartyState.Cancelled && session.SessionState !== PartyState.CheckedOut && session.SessionState !== PartyState.Left){
                numberOfBookings += session.BookingSize;
                numberOfOverBooked += session.OverBooked;
              }
            });

            const MaxActivityStrength = this.partyService.allWeekActivitySessions[bookedSession.ActivitySessionId]?.SessionCustomization?.MaxActivityStrength 
              ? this.partyService.allWeekActivitySessions[bookedSession.ActivitySessionId]?.SessionCustomization.MaxActivityStrength : specialMeal.MaxActivityStrength;
            this.partyService.allWeekActivitySessions[bookedSession.ActivitySessionId].MaxActivityStrength = MaxActivityStrength;
            this.partyService.allWeekActivitySessions[bookedSession.ActivitySessionId].Available = MaxActivityStrength >= numberOfBookings ? MaxActivityStrength - numberOfBookings : 0;
            this.partyService.allWeekActivitySessions[bookedSession.ActivitySessionId].Booked = numberOfBookings;
            this.partyService.allWeekActivitySessions[bookedSession.ActivitySessionId].OverBooked = numberOfOverBooked;

            if(reservation.Type == PartyType.StandBy && reservation.State !== PartyState.Cancelled)
                this.partyService.allWeekActivitySessions[bookedSession.ActivitySessionId].StandbyBooked += reservation.Size;

            if (DueState.includes(reservation.PrepaymentState))
              this.partyService.allWeekActivitySessions[bookedSession.ActivitySessionId].hasDuePaymentBookings = true;
            if (PaidState.includes(reservation.PrepaymentState))
              this.partyService.allWeekActivitySessions[bookedSession.ActivitySessionId].hasPaidBookings = true;
            if (reservation.PrepaymentState == PartyPrepaymentState.PrepaymentRequired && reservation.State !== PartyState.Cancelled)
              this.partyService.allWeekActivitySessions[bookedSession.ActivitySessionId].hasUnpaidBookings = true;
          }

        });
      // }

    } else if (reservation.SpecialMealId) {
      if (specialMeal && specialMeal.LessonType === LessonType.PrivateLesson && (reservation.State == PartyState.Pending || reservation.State == PartyState.Seated)) {
        let rowId = -1;
        if (this.selectedViewId === ViewBy.Lessons) {
          rowId = reservation.SpecialMealId;
        } else if (this.selectedViewId === ViewBy.location) {
          rowId = reservation.TableIds[0];
        } else if (this.selectedViewId === ViewBy.Instructor) {
          rowId = reservation.ServerId;
        }
        for (let i = 0; i < this.filteredDataList.length; i++) {
          if (rowId == this.filteredDataList[i].Id) {
            let columnIndex = this.filteredDataList[i].columnData.findIndex(({ date: columnDate }) => format(columnDate, 'MM/DD/YYYY') === format(reservation.ReservedFor || reservation.WishedTime, 'MM/DD/YYYY'))
            if (!this.filteredDataList[i].columnData[columnIndex] || !this.filteredDataList[i].columnData[columnIndex]?.data) {
              this.filteredDataList[i].columnData[columnIndex].data = [];
            }
            let privateBookingData = this.getPrivateLessonData(reservation)
            this.filteredDataList[i].columnData[columnIndex].data = [...this.filteredDataList[i].columnData[columnIndex].data, privateBookingData];

            if (this.filteredDataList[i].columnData[columnIndex].data && this.filteredDataList[i].columnData[columnIndex].data.length > this.defaultMaxDisplay) {
              this.filteredDataList[i].showExpandButton = true;
            }
          }
        }
      } else if (specialMeal && specialMeal.LessonType === LessonType.GroupLesson && reservation.SessionGroupId) { // && reservation.Type !== PartyType.StandBy
          if (!this.partyService.allWeekActivitySessions["group_" + reservation.SessionGroupId]) {
            this.partyService.allWeekActivitySessions["group_" + reservation.SessionGroupId] = { parties: [], Available: specialMeal.MaxActivityStrength, Booked: 0, StandbyBooked: 0 }
          }

          let partyIndex = this.partyService.allWeekActivitySessions["group_" + reservation.SessionGroupId].parties.findIndex(({Id}) => Id === reservation.Id);
          if(partyIndex === -1){
            this.partyService.allWeekActivitySessions["group_" + reservation.SessionGroupId].parties.push(reservation);
          } else{
            this.partyService.allWeekActivitySessions["group_" + reservation.SessionGroupId].parties[partyIndex] = reservation;
          }
          // let numberOfBookings = this.partyService.allWeekActivitySessions["group_" + reservation.SessionGroupId].parties.reduce((prev, curr) => prev + curr.Size, 0);

          // const MaxActivityStrength = this.partyService.allWeekActivitySessions["group_" + reservation.SessionGroupId]?.SessionCustomization?.MaxActivityStrength
          //     ? this.partyService.allWeekActivitySessions["group_" + reservation.SessionGroupId]?.SessionCustomization.MaxActivityStrength : specialMeal.MaxActivityStrength;
          //     this.partyService.allWeekActivitySessions["group_" + reservation.SessionGroupId].MaxActivityStrength = MaxActivityStrength;

          // this.partyService.allWeekActivitySessions["group_" + reservation.SessionGroupId].Available = MaxActivityStrength - numberOfBookings;
          // this.partyService.allWeekActivitySessions["group_" + reservation.SessionGroupId].Booked = numberOfBookings;

          if (reservation.Contact?.IsVip) {
            this.partyService.allWeekActivitySessions["group_" + reservation.SessionGroupId].isVIP = true;
          }

          let reqParties = this.partyService.allWeekActivitySessions["group_" + reservation.SessionGroupId].parties.filter(party => party.Type === PartyType.Reservation && (party.State == PartyState.Pending || party.State == PartyState.Seated || party.State == PartyState.Left));
          let numberOfBookings = reqParties.reduce((prev, curr) => prev + (curr.Size || 0) - (curr.OverBooked || 0), 0);
          let overBooked = reqParties.reduce((prev, curr) => prev + (curr.OverBooked || 0), 0);
              
          const MaxActivityStrength = this.partyService.allWeekActivitySessions["group_" + reservation.SessionGroupId]?.SessionCustomization?.MaxActivityStrength
            ? this.partyService.allWeekActivitySessions["group_" + reservation.SessionGroupId]?.SessionCustomization.MaxActivityStrength : specialMeal.MaxActivityStrength;
            this.partyService.allWeekActivitySessions["group_" + reservation.SessionGroupId].MaxActivityStrength = MaxActivityStrength;
          this.partyService.allWeekActivitySessions["group_" + reservation.SessionGroupId].Available = MaxActivityStrength >= numberOfBookings ? MaxActivityStrength - numberOfBookings : 0;
          this.partyService.allWeekActivitySessions["group_" + reservation.SessionGroupId].Booked = numberOfBookings;
          this.partyService.allWeekActivitySessions["group_" + reservation.SessionGroupId].OverBooked = overBooked;

        if (reservation.Type == PartyType.StandBy && reservation.State !== PartyState.Cancelled)
          this.partyService.allWeekActivitySessions["group_" + reservation.SessionGroupId].StandbyBooked += reservation.Size;

        if (DueState.includes(reservation.PrepaymentState))
          this.partyService.allWeekActivitySessions["group_" + reservation.SessionGroupId].hasDuePaymentBookings = true;
        if (PaidState.includes(reservation.PrepaymentState))
          this.partyService.allWeekActivitySessions["group_" + reservation.SessionGroupId].hasPaidBookings = true;
        if (reservation.PrepaymentState == PartyPrepaymentState.PrepaymentRequired && reservation.State !== PartyState.Cancelled)
          this.partyService.allWeekActivitySessions["group_" + reservation.SessionGroupId].hasUnpaidBookings = true;

      }

    } else if (!reservation.SpecialMealId && this.selectedViewId === ViewBy.location && reservation.Type != PartyType.StandBy) {
      let filterredIndex = this.filteredDataList.findIndex(({ Id }) => Id === reservation.TableIds[0])
      let columnIndex = this.filteredDataList[filterredIndex].columnData.findIndex(({ date: columnDate }) => format(columnDate, 'MM/DD/YYYY') === format(reservation.ReservedFor, 'MM/DD/YYYY'))
      if (!this.filteredDataList[filterredIndex].columnData[columnIndex].data) {
        this.filteredDataList[filterredIndex].columnData[columnIndex].data = [];
      }

      if(reservation.State !== PartyState.Left){
        let openBookingData = this.getOpenBookingData(reservation)
        // this.filteredDataList[filterredIndex].columnData[columnIndex].data.push(openBookingData);
        this.filteredDataList[filterredIndex].columnData[columnIndex].data = [...this.filteredDataList[filterredIndex].columnData[columnIndex].data, openBookingData]
        if (this.filteredDataList[filterredIndex].columnData[columnIndex].data && this.filteredDataList[filterredIndex].columnData[columnIndex].data.length > this.defaultMaxDisplay) {
          this.filteredDataList[filterredIndex].showExpandButton = true;
        }
      }
    }

  }

  getPrivateLessonData(booking) {
    let StartTime = booking.ReservedFor || booking.WishedTime;
    let EndTime = booking.DepartureTime;
    return {
      ...booking,
      guestDetail: (booking.Contact.FirstName + ' ' + (booking.Contact.LastName || '')),
      isVIP: booking.Contact.IsVip,
      timeDetail: `${moment(StartTime).locale(this.dashboardFunctions.getLocaleCode()).format('LT')} - ${moment(booking.DepartureTime).locale(this.dashboardFunctions.getLocaleCode()).format('LT')}`,
      Dayofweek: new Date(StartTime).getDay(),
      Duration: (moment(EndTime).diff(moment(StartTime), 'minutes')),
      StartTime: moment(StartTime),
      EndTime: moment(EndTime),
      PrivateLessonSlotTime: moment(StartTime),
      partiesList: [booking],
      isPrivateLessonBooking: true,
      uniqId: booking.Id
    }
  }

  getOpenBookingData(booking) {
    return {
      ...booking,
      timeDetail: `${moment(booking.SeatingTime).locale(this.dashboardFunctions.getLocaleCode()).format('LT')} - ${moment(booking.DepartureTime).locale(this.dashboardFunctions.getLocaleCode()).format('LT')}`,
      guestDetail: (booking.Contact.FirstName + ' ' + (booking.Contact.LastName || '')),
      isVIP: booking.Contact.IsVip,
      Dayofweek: new Date(booking.ReservedFor).getDay(),
      Duration: (moment(booking.DepartureTime).diff(moment(booking.ReservedFor), 'minutes')),
      isOpenBooking: true,
      uniqId: booking.Id
    }

  }

  getDataBySessionId(specialMeal, sessionId) {
    if (specialMeal) {
      let sessionObj = specialMeal.ActivitySessions.find(({ ActivitySessionId }) => ActivitySessionId === sessionId);
      return {
        timeDetail: `${moment(new Date("0001-01-01T" + sessionObj.StartTime)).locale(this.dashboardFunctions.getLocaleCode()).format('LT')} - ${moment(new Date("0001-01-01T" + sessionObj.EndTime)).locale(this.dashboardFunctions.getLocaleCode()).format('LT')}`,
        className: '',
        Available: specialMeal.MaxActivityStrength,
        Booked: 0,
        parties: [],
        StandbyBooked: 0,
        ...sessionObj
      };
    }
    return specialMeal;
  }

  setDataByLocation() {
    this.filteredDataList = this._dataList.ByLocation.map(location => {
      let rowData: any = {
        Id: location.Id,
        Name: location.Name,
        columnData: []
      }
      let dayWeekMap = this.setDataByBookings([], 'openBooking', null);
      if (location.ActivityBookings && location.ActivityBookings.length) {
        let uniqActivities = _.uniqBy(location.ActivityBookings, 'Id');
        [rowData.columnData, rowData.showExpandButton] = this.getColumnData(uniqActivities, ViewBy.location, dayWeekMap);
      } else {
        [rowData.columnData, rowData.showExpandButton] = this.getColumnData([], ViewBy.location, dayWeekMap);
      }

      return rowData;
    })
  }

  setDataByClass() {
    let byClassData = _.cloneDeep(this._dataList.ByClass);
    this.filteredDataList = byClassData.map(classData => {
      let [columnData, showExpandButton] = this.getColumnData([], ViewBy.Lessons);
      let rowData: any = {
        Id: classData.Id,
        Name: classData.Name,
        CategoryId: classData.CategoryId,
        CategoryName: classData.CategoryName,
        SortOrder: classData.SortOrder,
        columnData,
        showExpandButton
      }
      if (classData.isPrivateLesson) {
        let privateBookingsDataRes = this.setPrivateLessonBookingData(classData, true);
        // let dayWeekMap = this.setDataByBookings(privateBookingsDataRes.privateBookingsData, 'privateBooking', null, classData);
        // rowData.columnData = this.getColumnData([], ViewBy.Lessons, dayWeekMap);
      } else {
        [rowData.columnData, rowData.showExpandButton] = this.getColumnData([classData], ViewBy.Lessons);
      }

      return rowData;
    });
    this.filteredDataList = [...this.filteredDataList];
  }

  setDataByBookings(bookings, bookingType: 'openBooking' | 'privateBooking', dayWeekMapTemp, classData?) {
    let dayWeekMap: any[] = dayWeekMapTemp || Array(7).fill(null);

    bookings?.forEach(bookingDetail => {

      let booking = typeof bookingDetail === 'object' ? bookingDetail :
        _.cloneDeep(this.partyService.Parties$.value.filter(x => x.Id == bookingDetail && ((x.State === PartyState.Pending)
          || (x.State === PartyState.Confirmed) || (x.State === PartyState.Seated)))[0]);
      if (booking) {
        let sessionRes = {
          ...booking,
          SlotSubheader: `${moment(booking.SeatingTime).locale(this.dashboardFunctions.getLocaleCode()).format('LT')} - ${moment(booking.DepartureTime).locale(this.dashboardFunctions.getLocaleCode()).format('LT')}`,
        }
        if (typeof bookingDetail !== 'object') {
          sessionRes = {
            ...sessionRes,
            SlotHeader: (booking.Contact.FirstName + ' ' + (booking.Contact.LastName || '')),
            Dayofweek: new Date(booking.ReservedFor).getDay(),
            Duration: (moment(booking.DepartureTime).diff(moment(booking.ReservedFor), 'minutes')),
            isPrivateLessonBooking: bookingType === 'privateBooking',
            isOpenBooking: bookingType === 'openBooking',
          }
        }

        // sessionRes.SlotSubheader = `${moment(booking.SeatingTime).format('LT')} - ${moment(booking.DepartureTime).format('LT')}, ${sessionRes.SlotSubheader}`;
        if (!dayWeekMap[sessionRes.Dayofweek]) {
          dayWeekMap[sessionRes.Dayofweek] = [];
        }
        dayWeekMap[sessionRes.Dayofweek].push(sessionRes);
      }
    });

    return dayWeekMap;
  }

  setDataByInstructor() {
    this.filteredDataList = this._dataList.ByStaff.map(staff => {
      let rowData: any = {
        Id: staff.Id,
        Name: staff.Name ?  staff.Name : (staff.FirstName || '') + " " + (staff.LastName || ''),
        columnData: []
      }

      if (staff.ActivityBookings && staff.ActivityBookings.length) {
        let uniqActivities = _.uniqBy(staff.ActivityBookings, 'Id');
        [rowData.columnData, rowData.showExpandButton] = this.getColumnData(uniqActivities, ViewBy.Instructor);
      } else {
        [rowData.columnData, rowData.showExpandButton] = this.getColumnData([], ViewBy.Instructor);
      }
      return rowData;
    });
  }

  getColumnData(activites, view, dayWeekMapTemp?) {
    let dayWeekMap: any[] = dayWeekMapTemp || Array(7).fill(null);
    let resDayWeek = [];
    let showExpandButton: boolean = false;
    let newRowDate = new Date(this.rowDataDate)
    let daysOfweek = [];
    for (let i = 0; i <= 6; i++) {
      let newDate = new Date(newRowDate);
      newDate.setDate(newRowDate.getDate() + i);
      daysOfweek.push({ dayOfWeek: newDate.getDay(), date: newDate})
    }
    activites?.forEach(classData => {      
      classData.Sessions?.forEach(session => {

        const currDate = daysOfweek.filter(x=> x.dayOfWeek == session.Dayofweek)[0].date;
        const modifiedSessionsParentIds = classData.Sessions.reduce((a, o) => (o['ParentSessionId'] && moment(o.SessionDate).format('YYYY-MM-DD') == moment(currDate).format('YYYY-MM-DD') && o.Dayofweek == currDate.getDay() && a.push(o['ParentSessionId']), a), []);
        
        if (!dayWeekMap[session.Dayofweek]) {
          dayWeekMap[session.Dayofweek] = [];
        }
        if (classData.isPrivateLesson) {
          // let privateBookingsDataRes = this.setPrivateLessonBookingData(classData, true)
          // dayWeekMap = this.setDataByBookings(privateBookingsDataRes.privateBookingsData, 'privateBooking', dayWeekMap, classData);
          // dayWeekMap = this.setDataByBookings(classData.Bookings, 'privateBooking' ,dayWeekMap);
        } else {
          let sessionRes = this.getColumnDataFromSession(session, classData.isPrivateLesson, view, classData,daysOfweek);
          if(!modifiedSessionsParentIds.includes(session.Id || session.ActivitySessionId) 
            && (!session.SessionDate || moment(session.SessionDate).format('YYYY-MM-DD') == moment(currDate).format('YYYY-MM-DD')))
            dayWeekMap[session.Dayofweek].push(sessionRes);
          if (dayWeekMap[session.Dayofweek] && dayWeekMap[session.Dayofweek].length > this.defaultMaxDisplay) {
            showExpandButton = true;
          }
        }
      });
    });
    for (let i = 0; i <= 6; i++) {
      let newDate = new Date(this.rowDataDate);
      newDate.setDate(this.rowDataDate.getDate() + i);
      let dayOfWeek = newDate.getDay();
/*       dayWeekMap[dayOfWeek]?.forEach(data => {
        if (data.SpecialMealId && typeof data.StartTime == 'string') {
          let [shr, smin, ssec] = data.StartTime.split(":");
          let [ehr, emin, esec] = data.EndTime.split(":");
          // data.StartTime = this.getDate(+shr, +smin, +ssec,newDate);
          // data.EndTime = this.getDate(+ehr, +emin, +esec, newDate);

          data.addonblocked = this.partyService.getAddonBlocker(newDate, this.getDate(+shr, +smin, +ssec, newDate), this.getDate(+ehr, +emin, +esec, newDate), true, data.SpecialMealId);
        }
      }) */
      resDayWeek.push({ dayOfWeek: dayOfWeek, date: newDate, data: dayWeekMap[dayOfWeek] });
    }
    return [resDayWeek, showExpandButton];
  }

  setPrivateLessonBookingData(data, isByClass) {
    var bookings = (isByClass) ? [data] : data.ActivityBookings;
    data.privateBookingsData = [];
    var privateLessonBookingSessions: any = []
    let privateStandByParties: any = [];
    let privateParties: any = [];
    bookings.forEach(privateLessonBooking => {
      if (privateLessonBooking.isPrivateLesson == true && privateLessonBooking?.Bookings?.length) {

        privateLessonBookingSessions.push(privateLessonBooking.Sessions);
        let confirmedParties = _.cloneDeep(this.partyService.Parties$.value.filter(x => privateLessonBooking.Bookings.includes(x.Id) && ((x.State === PartyState.Pending)
          || (x.State === PartyState.Confirmed) || (x.State === PartyState.Seated))).map(p => {
            p.PrivateLessonSlotTime = p.Type == PartyType.Reservation && p.ReservedFor ? p.ReservedFor : p.WishedTime;
            return p;
          }));
        privateParties.push(confirmedParties);


        if (this.selectedViewId !== ViewBy.location || confirmedParties.length) {
          let standByParties = _.cloneDeep(this.partyService.StandbyParties$.value.filter(x => {

            var basicFilter = privateLessonBooking.Bookings.includes(x.Id) && ((x.State === PartyState.Pending)
              || (x.State === PartyState.Confirmed) || (x.State === PartyState.Seated));

            let havingConfirmedPartiesAtSameTime = true;
            if (this.selectedViewId == ViewBy.location) {
              havingConfirmedPartiesAtSameTime = confirmedParties.filter(p => p.SpecialMealId == x.SpecialMealId && new Date(p.PrivateLessonSlotTime) <= new Date(x.WishedTime) && new Date(p.DepartureTime) >= new Date(x.DepartureTime)).length ? true : false;
            }

            return basicFilter && havingConfirmedPartiesAtSameTime;
          }).map(p => {
            p.PrivateLessonSlotTime = p.Type == PartyType.Reservation && p.ReservedFor ? p.ReservedFor : p.WishedTime;
            return p;
          }));

          privateStandByParties.push(standByParties);

        }

      }
    });
    // privateLessonBooking?.Bookings.forEach(booking => {


    privateParties = privateParties.flat();
    privateStandByParties = privateStandByParties.flat();
    privateLessonBookingSessions = privateLessonBookingSessions.flat();

    let allprivateParties = [...privateParties, ...privateStandByParties];
    allprivateParties.forEach(p => { p.PrivateLessonSlotTime = p.Type == PartyType.Reservation && p.ReservedFor ? p.ReservedFor : p.WishedTime }); //need one common field to group parties

    let partiesGroupedByStartTimeAndEndTime = groupBy(orderBy(allprivateParties, p => p.PrivateLessonSlotTime), p => p.PrivateLessonSlotTime);




    Object.keys(partiesGroupedByStartTimeAndEndTime).forEach(eachKey => {
      if (partiesGroupedByStartTimeAndEndTime[eachKey].length) {
        let maxDepartureTime = orderBy(partiesGroupedByStartTimeAndEndTime[eachKey], p => p.DepartureTime, 'desc')[0].DepartureTime;
        for (let key of Object.keys(partiesGroupedByStartTimeAndEndTime)) {
          if (new Date(key) > new Date(eachKey)) {
            let maxStartTime = orderBy(partiesGroupedByStartTimeAndEndTime[key], p => p.PrivateLessonSlotTime, 'desc')[0].PrivateLessonSlotTime;
            if (new Date(maxStartTime) < new Date(maxDepartureTime)) {
              maxDepartureTime = orderBy(partiesGroupedByStartTimeAndEndTime[key], p => p.DepartureTime, 'desc')[0].DepartureTime;
              partiesGroupedByStartTimeAndEndTime[key].forEach(p => partiesGroupedByStartTimeAndEndTime[eachKey].push(p));
              partiesGroupedByStartTimeAndEndTime[key] = [];
            }
          }
        }
        let partyList = orderBy(partiesGroupedByStartTimeAndEndTime[eachKey], p => p.DepartureTime, 'desc');
        let lesson: any = {};
        if (partyList && partyList.length) {
          lesson = partyList[0];

          lesson.StartTime = new Date(eachKey);
          lesson.EndTime = new Date(partyList[0].DepartureTime);
          lesson.partiesList = partiesGroupedByStartTimeAndEndTime[eachKey];
          lesson.isStartSlot = true;

          let lessonData = lesson;
          lessonData.isVIP = lesson.Contact ? lesson.Contact.IsVip : false;

          lessonData.isPrivateLessonBooking = true;

          //let firstName = lesson.Contact.FirstName ? lesson.Contact.FirstName : '';
          //let lastName = lesson.Contact.LastName ? lesson.Contact.LastName : '';

          let guestNames = [];

          partiesGroupedByStartTimeAndEndTime[eachKey].forEach(p => {
            if (p.Contact) {
              let firstName = (p.Contact.FirstName ? p.Contact.FirstName : '');
              let Name = (p.Contact.LastName ? (firstName ? (firstName + ' ' + p.Contact.LastName) : p.Contact.LastName)
                : (firstName ? firstName : ''));
              guestNames.push(Name)
            }
          });

          let guestName = '';
          guestName = guestNames.join(",");

          lessonData.SlotHeader = guestName.substring(0); // + ((lesson.Size - 1) > 0 ? ' + ' + (lesson.Size - 1) : '');
          let staffName = "", LocationNames = "";
          let contacts = [];
          //privateLessonBooking.Sessions.forEach(session => {
          privateLessonBookingSessions.forEach(session => {
            session.Staffs.forEach((staff, index) => {
              let contactObj = this._settings.Servers.find(server => server.Id === staff.Id);
              contacts.push(contactObj || staff);
              staffName = staffName + (staff.FirstName + ' ' + (staff.LastName || ''));
              if (index != (session.Staffs.length - 1)) {
                staffName = staffName + '/'
              }
            });
            session.Locations.forEach((loctn, index) => {
              LocationNames = LocationNames + loctn.Name;
              if (index != (session.Locations.length - 1)) {
                LocationNames = LocationNames + '/'
              }
            });
          });
          lessonData.contacts = contacts;
          //lessonData.SlotHeader = privateLessonBooking.Name;
          lessonData.Duration = (moment(lesson.EndTime).diff(moment(lesson.StartTime), 'minutes'));;
          lessonData.Dayofweek = new Date(lessonData.PrivateLessonSlotTime).getDay();
          if (!data.privateBookingsData) {
            data.privateBookingsData = [];
          }
          data.privateBookingsData.push(lessonData)
        }

      }
    });
    //}
    //});
    return data;
  }

  refactorColumnData(i, data) {
    let newDate = new Date(this.rowDataDate);
    newDate.setDate(this.rowDataDate.getDate() + i);
    return { dayOfWeek: i, date: newDate, data }
  }

  instructorData(index, item) {
    return item.Id;
  }

  getColumnDataFromSession(session, isPrivateLesson, view, classData,resDayWeek) {
    let meal = this._settings.SpecialMeals.find(meal => meal.Id == classData.Id);
    if (classData) {     

      session.uniqId = session.SessionGroupId ? ("group_"+ session.SessionGroupId) : session.ActivitySessionId;
      session.Reason = meal.ActivitySessions.find(x=> x.ActivitySessionId == session.ActivitySessionId)?.Reason || '';
      session.SpecialMealId = classData.Id;
      session.className = classData.Name;
      session.SlotHeader = classData.Name;
      session.SlotPrivateHeadline = this.dashboardFunctions.getPrivateAndPublicHeadline(meal?.ActivityAttributes.filter(x => x.AttributeType == AttributeType.PrivateHeadlines),session , resDayWeek)
      session.SlotPublicHeadline =  this.dashboardFunctions.getPrivateAndPublicHeadline(meal?.ActivityAttributes.filter(x => x.AttributeType == AttributeType.PublicHeadlines),session ,resDayWeek)      
      session.activityCustomisation = this.partyService.getActivityCustomisation(classData, session,resDayWeek ? resDayWeek.find(x => x.dayOfWeek == session.Dayofweek)?.date : this.cs.headerDate);
        if(session.activityCustomisation && session.activityCustomisation.length){
          if(this.partyService.activityBlockingChange$.value){                                      
            let activityCustomisation = Object.values(this.partyService.activityBlockingChange$.value.ActivityBlockingRule).filter(data => data.ActivityId == session.SpecialMealId)
            let columnDate = resDayWeek ? resDayWeek.find(x => x.dayOfWeek == session.Dayofweek)?.date : this.cs.headerDate;
            let customsation = activityCustomisation?.find(cust => (cust.ActivitySessionIds.includes(session.SessionId) || cust.ActivitySessionIds.includes(session.ActivitySessionId)) && (moment(columnDate).format("YYYY-MM-DD") >= moment(cust.StartDate).format("YYYY-MM-DD")) && (moment(columnDate).format("YYYY-MM-DD") <= moment(cust.EndDate).format("YYYY-MM-DD")) && (cust.AppliesTo == ActivityCustomizationAppliesTo.HostAppAndWidget || cust.AppliesTo == ActivityCustomizationAppliesTo.HostApp));
            if(customsation)
            session.blockedBy = this.translateService.instant('blockedBy') + " " + (customsation  ? this.partyService.getHostName(customsation.ActionBy) : '');
          }       
         }
    }
    if (!isPrivateLesson) {
      session.timeDetail = moment(new Date("0001-01-01T" + session.StartTime)).locale(this.dashboardFunctions.getLocaleCode()).format('LT') + ' - ' + moment(new Date("0001-01-01T" + session.EndTime)).locale(this.dashboardFunctions.getLocaleCode()).format('LT');
      // session.Available = classData.MaxActivityStrength;
      session.Booked = 0;
    }
    let staffName = "", LocationNames = "";
    let contacts = [];
    //privateLessonBooking.Sessions.forEach(session => {
    session.Staffs.forEach((staff, index) => {
      let contactObj = this._settings.Servers.find(server => server.Id === staff.Id);
      contacts.push(contactObj || staff);
      staffName = staffName + (staff.FirstName + ' ' + (staff.LastName || ''));
      if (index != (session.Staffs.length - 1)) {
        staffName = staffName + '/'
      }
    });
    session.Locations.forEach((loctn, index) => {
      LocationNames = LocationNames + ( loctn ? loctn.Name : '' );
      if (index != (session.Locations.length - 1)) {
        LocationNames = LocationNames + '/'
      }
    });
    session.contacts = contacts;
    if (this.selectedViewId === ViewBy.Instructor) {
      // session.SlotSubheader = LocationNames;
      // session.LocationAddnlDetail = locationAddnlDetail;
    } else if (this.selectedViewId === ViewBy.Lessons) {
      session.SlotHeader = staffName;
      session.SlotSubheader = LocationNames;

      // session.LocationAddnlDetail = locationAddnlDetail;
      session.contacts = contacts;
      if (classData.isPrivateLesson && staffName) {
        session.SlotHeader = staffName;
      }
    } else {
      session.contacts = contacts;
    }

    session.isPrivateLesson = classData.isPrivateLesson;
    session.ClassType = meal?.ClassType;

    const currDate = resDayWeek.find(x => x.dayOfWeek == session.Dayofweek)?.date;
    if(currDate) session.Date = moment(currDate).format("YYYY-MM-DD");

    return session;
  }

  promptBlockSession(event,activityData, item, dayData,blockSession) {
    item.startTime = item.StartTime;
    activityData.weeklyViewDate = dayData.date;
    item.ClassEndDate = this.partyService.cs.settings.value.SpecialMeals.find(meal => meal.Id == activityData.Id)?.EndDate;
    this.partyService.promptBlockSession(activityData, item, blockSession == this.translateService.instant('blockbuttontext'));
    event.preventDefault();
    event.stopPropagation();
  }

  loadUserName(dayData ,session){   
    let activityCustomisation = this.partyService.cs.settings.value.SpecialMeals.find(activity => activity.Id == session.SpecialMealId)?.ActivityCustomizations;;
    let blockedSession = activityCustomisation.find(x => {
      let dayDate = new Date(dayData.date);
      let startDate = new Date(x.StartDate);
      let endDate = new Date(new Date(x.EndDate).setDate(new Date(x.EndDate).getDate() + 2));
      return x.ActivitySessionIds.includes(session.SessionId) && (moment(dayDate).isBetween(startDate,endDate,'day', '[]'))
    });
    return this.translateService.instant('blockedBy') + " " + blockedSession?.ActionBy ? this.partyService.getHostName(blockedSession.ActionBy):'';
  }


  sessionStatus(event,rowData, element){
    
    this.sessionStatusText = this.partyService.getActivityCustomisation(rowData, element,rowData.columnData.find(day => day.dayOfWeek == element.Dayofweek)?.date || null);
    event.preventDefault();
    event.stopPropagation();

  }

  getDate(hr, min, sec, dateValue) {
    let date = new Date(dateValue.getTime());
    date.setHours(hr);
    date.setMinutes(min);
    date.setSeconds(sec);
    return date;
  }


  addBookingToSlot(instructor, date, index) {
    this.addBookingToSlotEvent.emit([instructor, null, index, date])
  }

  activitySelected(slot, rowData, weeklyViewDate) {
    this.activitySelectedEvent.emit([slot, rowData, weeklyViewDate])
  }

  modifySession(event: Event, rowData,session, sessionDate){
    event.preventDefault();
    event.stopPropagation();
    // this.modifySessionEvent.emit(event);
    this.ats.modifySession([rowData,session], sessionDate);
  }
  cancelSession(event: Event, rowData,session, sessionDate){
      event.preventDefault();
      event.stopPropagation();
      // this.cancelSessionEvent.emit(event);
      this.ats.cancelSession([rowData,session], sessionDate);
  }
  ngOnDestroy(): void {
    if (this.subscriptions) {
      this.subscriptions.unsubscribe();
    }
    if (this.getReservationSubscriptions) {
      this.getReservationSubscriptions.unsubscribe();
    }

  }

}

@Pipe({
  name: 'filterByName'
})
export class FilterByNamePipe implements PipeTransform {

  transform(array: any, searchText): any {
    let name: any;
    if (array && array.length && searchText) {
      return array.filter(({ Name }) => Name.toLowerCase().includes(searchText.toLowerCase()))
    }
    return array;
  }


}

@Pipe({
  name: 'sortByTime'
})
export class SortByTimePipe implements PipeTransform {

  transform(array: any, date): any {
    if (array && array.length) {
      return _.sortBy(array, (obj) => {
        let dateObj = this.getDateObject(obj, date);
        return dateObj.getTime();
      });
    }
    return array;
  }

  getDateObject(obj, date) {
    let dateRes;
    if (obj.ReservedFor) {
      dateRes = moment(obj.ReservedFor)
    } else if (obj.WishedTime) {
      dateRes = moment(obj.WishedTime)
    } else {
      dateRes = moment(date + "T" + obj.StartTime)
    }
    return dateRes.toDate()
  }

}
