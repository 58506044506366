import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, Pipe, PipeTransform, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ApiService } from '@app/activities-timeline/services/api.service';
import { CacheService } from '@app/core/services/cache.service';
import { CustomPopupComponent } from '@app/popup-module/components/custom-popup/custom-popup.component';
import { ComponentDetails } from '@app/popup-module/models/popup.interface';
import { PopupService } from '@app/popup-module/popup.service';
import { CancelActivityPopupComponent } from '@app/shared/components/cancel-activity-popup/cancel-activity-popup.component';
import { BookingBehavior, buttonTypes, CancelActivityType, ClassType, ComponentTypes, FinancialEffectType, HeadlineActivityType, OperationResultState, Operations, PartyState, PartyType, PricingBy, ViewBy, Status, AttributeType, FinancialEffectAction, PaymentMethod, TimeLineAvailablityShowTypes, RolesAndPermissionsType } from '@app/shared/constants/commonenums';
import { DynamicFormComponent } from '@app/shared/dynamicform/dynamic-form/dynamic-form.component';
import { ButtonValue, FieldConfig } from '@app/shared/dynamicform/models/field-config.interface';
import { PartyChargeState, PartyPrepaymentState, ReAssignAssetDTO } from '@app/shared/models/InputContact';
import { ActivityCustomizationAppliesTo, BookingChargeType, LayoutDTO, SelectionType, SettingsDTO } from '@app/shared/models/RestaurantDTO';
import { PartyService } from '@app/shared/services/party.service';
import { DashboardFunctions } from '@app/shared/utilities/dashboard-functions';
import { Utilities } from '@app/shared/utilities/utilities';
import { Subscription } from 'rxjs';
import { AlertType, ButtonType } from '@app/common/enums/shared-enums';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import moment from 'moment';
import { ConfirmationPopupComponent } from '@app/shared/components/confirmation-popup/confirmation-popup.component';
import { head, round, sortBy, uniqBy, values } from 'lodash';
import _ from 'lodash';
import { AppService } from '@app/app.service';
import { DatePipe } from '@angular/common';
import { RejectStanbyComponent } from '@app/shared/components/reject-stanby/reject-stanby.component';
import { ActivityBookingFormComponent } from '@app/shared/components/create-update-party-tab-layout/selections/activity-booking-form/activity-booking-form.component';
import { ActivitySlotRequestDTO } from '@app/shared/models/ActivitySlotRequestDTO';
import { SessionBookingDTO } from '@app/shared/models/SessionBookingDTO';
import { ActivityBookingSummaryComponent } from '@app/shared/components/create-update-party-tab-layout/selections/activity-booking-summary/activity-booking-summary.component';
import { urlConfig } from '@app/shared/constants/url-config';
import { HttpService } from '@app/shared/services/http.service';
import { seatRetailService } from '@app/shared/services/seatretail.service';
import { environment } from '@environments/environment';
import { ReportBusinessService } from '@app/retail/retail-reports/business/report-business.service';
import { CommonUtilities } from '@app/common/shared/shared/utilities/common-utilities';
import { ActivityHeadlinesComponent } from '@app/shared/components/activity-headlines/activity-headlines/activity-headlines.component';
import { ToastrService } from 'ngx-toastr';
import { AuditlogComponent } from '@app/shared/components/auditlog/auditlog.component';
import { popupDialogDimension } from '@app/shared/constants/globalConstants';
import { AuditableEntityType } from '@app/shared/models/SimpleAuditLogItemDTO';
import DisplayOptions from '@data/activitiesFilterOptions.json';
import { BookingDetail } from '../BookingDetail';
import { NgbPopover } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { CancelSessionPopupComponent } from '@app/shared/components/cancel-session-popup/cancel-session-popup.component';
import { AppPopupComponent } from '@app/popup-module/components/app-popup/app-popup.component';
import { RolesAndPermissionPipe } from '@app/shared/pipes/RolesAndPermission.pipe';

@Component({
  selector: 'class-details',
  templateUrl: './class-details.component.html',
  styleUrls: ['./class-details.component.scss'],
})
export class ClassDetailsComponent extends BookingDetail implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('dataContainer') el: ElementRef;
  classDetail: any;
  @Input() data: any;
  @Output() close: EventEmitter<any> = new EventEmitter<any>();
  blockedSessionDetails: any;
  blockedSession: any;
  public buttonControls: ButtonValue[];
  searchconfig: FieldConfig[];
  @ViewChild('searchConfigForm', { static: true }) searchConfigForm: DynamicFormComponent;
  subscriptions: Subscription = new Subscription();
  classType= ClassType;
  locations: any[];
  staffs: any[];
  showAll: any = {};
  showAddOns: any = {};
  attendees: any[] = [];
  standbyAttendees: any[] = [];
  searchAttendeesText: string = '';
  PartyState = PartyState;
  partyType = PartyType;
  financialPartyData: any;
  confirmSubscription: Subscription;
  standBySubscription: Subscription;
  partiessubscription: Subscription;
  cancelledPartySubscription: Subscription;
  warningInfoDialogRef: MatDialogRef<any>;
  selectedViewId: number;
  isRetailEnabled: boolean;
  addonForActivity: any = [];
  addonsForBooking: any = [];
  addonsForBookingPerLesson: any = [];
  rightArrowDisabled: boolean = false;
  leftArrowDisabled: boolean = true
  AllowStandBy: boolean = false;
  showAddOnIcon = false;
  showAddOnDetails = false;
  addonsForActivitySelected = [];
  publicQuickNote: any;
  privateQuickNote: any;
  headlinesForActivityGrouped: any;
  ActivityAttributeType = AttributeType;
  uniqId: any;
  notesReservation: any = [];
  bookingPaymentMethod = PaymentMethod;
  rolesAndPermissionsType = RolesAndPermissionsType;
  displayOptions = DisplayOptions;
  optionsForFilter = [PartyState.Pending, PartyState.Seated, PartyState.Cancelled, PartyState.Left];
  classDetailDate: Date;
  @ViewChild('addOnForBookingPopover') private addOnForBookingPopover: NgbPopover;
  _layout: LayoutDTO = {} as LayoutDTO;
  TimeLineAvailablityShowTypeEnum =  TimeLineAvailablityShowTypes;
  preventLoadSettings: boolean;
  headlineRequestSucceeded:boolean;
  _MESSAGES = MESSAGES;
  noActionRequired : boolean = false;
  partyWithAddon: any;
  cancellationDetails: any = {};
  isSessionCancelled : boolean = false;
  locationListGroup:any[] =[];
  LocationGroupDetails:any;
  constructor(public partyService: PartyService, public apiService: ApiService, public ts: TranslateService, public retailservice: seatRetailService,
    public dashboardFunctions: DashboardFunctions, public cs: CacheService, public appService: AppService,
    public datePipe: DatePipe, protected dialog: MatDialog, public popupService: PopupService, public httpService: HttpService, private utilities: CommonUtilities, private permissionPipe: RolesAndPermissionPipe ,public toastrService?: ToastrService
    // public actvitybkngform: ActivityBookingFormComponent, public activitySummarycomp: ActivityBookingSummaryComponent
  ) {
    super(dialog, ts)
    this.subscriptions.add(this.cs.activitiesData.subscribe(activities => {
      if (activities && this.classDetail) {
        this.partiessubscription && this.partiessubscription.unsubscribe();
        this.updateClassDetail(activities);
      }
    }));
    this.subscriptions.add(cs.layout.subscribe(layt => {
      this._layout = layt;
    }));
  }

  ngOnInit(): void {
    this._settings =  this.cs.settings?.value;
    this.classDetail = this.data?.classDetail || null;
    this.noActionRequired = this.cs.isIframeEnabled; //When VCart is enabled, actions are not permitted
    this.isSessionCancelled = this.classDetail.StatusCode == Status.Cancelled;
    if (this.classDetail?.weeklyViewDate) {
      this.classDetailDate = new Date(this.classDetail?.weeklyViewDate);
    }
    else {
      this.classDetailDate = this.cs.headerDate;
    }
    this.setMappedCustomGuestFields(this.classDetail.Id);
    this.selectedViewId = this.data?.selectedViewId || null;
    this.AllowStandBy = this._settings.SpecialMeals.find(activity => activity.Id == this.classDetail.Id)?.AllowStandBy;
    this.staffAndLocationMapping();
    this.uniqId = this.classDetail.SessionGroupId ? 'group_' + this.classDetail.SessionGroupId : this.classDetail.SessionId;
    this.addonsForActivitySelected = Utilities.getAddons(this._settings.Addons, this.classDetail.Id)
    this.loadAddOnForActivity();
    let attributes =  this._settings.SpecialMeals.find(activity => activity.Id == this.classDetail.Id)?.ActivityAttributes;
    this.loadHeadlines(attributes);
    this.loadBlockedSession();
    this.loadCancelledSession();
    this.loadLocationGroup();
    this.displayOptions.forEach(item => {
      item.isSelected = this.optionsForFilter.includes(item.id) ? true : false
    })
    this.setButtonControls();
    this.searchconfig = [
      {
        type: 'input',
        name: 'searchText',
        label: this.ts.instant('searchAttendee'),
        class: 'class-detail__search-text',
        showErrorText: true,
        appearance: true,
        icon: 'icon-search',
        icon1: 'icon-Group-591',
        cellClick: this.clearSearch.bind(this)
      }
    ];

    if (this.classDetail.ClassType === ClassType.Session) {
      if (this.partyService.allWeekActivitySessions[this.classDetail.SessionId]?.Available || this.partyService.allWeekActivitySessions[this.classDetail.SessionId]?.Available == 0) {
        this.classDetail.available = this.partyService.allWeekActivitySessions[this.classDetail.SessionId]?.Available;
      }
      else {
        this.classDetail.available = this.classDetail.available || 0;
      }

      if (this.partyService.allWeekActivitySessions[this.classDetail.SessionId]?.Booked || this.partyService.allWeekActivitySessions[this.classDetail.SessionId]?.Booked == 0) {
        this.classDetail.booked = this.partyService.allWeekActivitySessions[this.classDetail.SessionId]?.Booked;
      }
      else {
        this.classDetail.booked = this.classDetail.booked || 0;
      }

    } else if (this.classDetail.ClassType === ClassType.Class) {
      if (this.partyService.allWeekActivitySessions['group_' + this.classDetail.SessionGroupId]?.Available || this.partyService.allWeekActivitySessions['group_' + this.classDetail.SessionGroupId]?.Available == 0) {
        this.classDetail.available = this.partyService.allWeekActivitySessions['group_' + this.classDetail.SessionGroupId]?.Available;
      }
      else {
        this.classDetail.available = this.classDetail.available || 0;
      }

      if (this.partyService.allWeekActivitySessions['group_' + this.classDetail.SessionGroupId]?.Booked || this.partyService.allWeekActivitySessions['group_' + this.classDetail.SessionGroupId]?.Booked == 0) {
        this.classDetail.booked = this.partyService.allWeekActivitySessions['group_' + this.classDetail.SessionGroupId]?.Booked;
      }
      else {
        this.classDetail.booked = this.classDetail.booked || 0;
      }

    }

    if (this.classDetail.available <= 0) {
      this.classDetail.overbooked = this.classDetail.available * -1;
    }


  }
  setButtonControls() {
    this.buttonControls = [];
    if (this.classDetail?.ClassType == ClassType.Session) {
      this.buttonControls.push({ label: this._MESSAGES.labels.cancelThisActivity, type: buttonTypes.actionSecondarySmall, customclass: 'class-detail__actions__btn width-auto', name: this._MESSAGES.labels.cancelThisActivity, disbaledproperity: (this.noActionRequired || this.isSessionCancelled)});
    }

    let customisations = this.partyService.activityBlockingChange$.value?.ActivityBlockingRule ? Object.values(this.partyService.activityBlockingChange$.value?.ActivityBlockingRule).filter(r => r.ActivityId == this.classDetail.Id && (r.AppliesTo == ActivityCustomizationAppliesTo.HostApp || r.AppliesTo == ActivityCustomizationAppliesTo.HostAppAndWidget)) : null;;
    let columnDate = this.classDetail.weeklyViewDate ? this.classDetail.weeklyViewDate : this.cs.headerDate;
    if (customisations && customisations.length && customisations.filter(cust => cust.ActivitySessionIds.includes(this.classDetail.SessionId) && (moment(moment(columnDate).format("YYYY-MM-DD")) >= moment(cust.StartDate)) && (moment(moment(columnDate).format("YYYY-MM-DD")) <= moment(cust.EndDate))).length > 0) {
      this.buttonControls.push({ label: this._MESSAGES.labels.unBlock, type: buttonTypes.actionSecondaryOrange, customclass: 'class-detail__actions__btn', name: this._MESSAGES.labels.unBlock, disbaledproperity: ( this.noActionRequired || this.isSessionCancelled) })
    }
    else {
      this.buttonControls.push({ label: this._MESSAGES.labels.book, type: buttonTypes.actionPrimarySmall, customclass: 'class-detail__actions__btn', name: this._MESSAGES.labels.book , disbaledproperity : this.isSessionCancelled })
      this.buttonControls.push({ label: this._MESSAGES.labels.block, type: buttonTypes.actionFullOrange, customclass: 'class-detail__actions__btn', name: this._MESSAGES.labels.block, disbaledproperity: ( this.noActionRequired || this.isSessionCancelled)})

    }
    if (this.classDetail?.ClassType == ClassType.Session && this.LocationGroupDetails) {
      this.buttonControls.push({ label: this._MESSAGES.labels.ReAssignAsset, type: buttonTypes.actionSecondarySmall, customclass: 'class-detail__actions__btn width-auto', name: this._MESSAGES.labels.ReAssignAsset});
    }

    this.isRetailEnabled = Utilities.isRetailEnabledProperty(this._settings.General.RetailIntegrationDTO);
  }

  loadBlockedSession() {
    let activityCustomisation = this.partyService.activityBlockingChange$.value?.ActivityBlockingRule ? Object.values(this.partyService.activityBlockingChange$.value?.ActivityBlockingRule).filter(r=>r.ActivityId == this.classDetail.Id && (r.AppliesTo == ActivityCustomizationAppliesTo.HostApp || r.AppliesTo == ActivityCustomizationAppliesTo.HostAppAndWidget))  : null;
    let columnDate = this.classDetail.weeklyViewDate ? this.classDetail.weeklyViewDate : this.cs.headerDate;
    this.blockedSession = activityCustomisation?.find(x => x.ActivitySessionIds.includes(this.classDetail.SessionId) && (moment(moment(columnDate).format("YYYY-MM-DD")) >= moment(x.StartDate)) && (moment(moment(columnDate).format("YYYY-MM-DD")) <= moment(x.EndDate)));
    if (this.blockedSession) {
      this.blockedSessionDetails = {
        blockedBy: this.partyService.getHostName(this.blockedSession.ActionBy) ? this.partyService.getHostName(this.blockedSession.ActionBy) : '-',
        blockedUntil: this.classDetail.endTime ? this.classDetail.endTime : '-',
        blockingReason: this.blockedSession.ActionReason ? this.blockedSession.ActionReason : '-'
      }
    }
  }
  loadCancelledSession() {
    let selectedSession = this._settings.SpecialMeals.find(activity => activity.Id == this.classDetail.Id)?.ActivitySessions.find(session => (session.ActivitySessionId == this.classDetail.SessionId || session.ParentSessionId == this.classDetail.SessionId ) && session?.StatusCode == Status.Cancelled && moment(session.SessionDate).format('YYYY-MM-DD') == moment(this.classDetailDate).format('YYYY-MM-DD'));
    this.isSessionCancelled = selectedSession ? true : false;
    if(this.isSessionCancelled){
      this.classDetail.SessionId  = selectedSession.ActivitySessionId  
      this.cancellationDetails.CancelledReason = selectedSession.Reason;
      this.cancellationDetails.CancelledBy = this.partyService.getHostName(selectedSession.SessionCustomization?.ActionBy) || '-';
      this.cancellationDetails.CancelledUntil = this.classDetail.endTime ? this.classDetail.endTime : '-';
      this.setButtonControls();  
    }
  }
  loadLocationGroup(){
    if(this._settings.LocationGroups?.length){
      let selectedSession = this._settings.SpecialMeals.find(activity => activity.Id == this.classDetail.Id)?.ActivitySessions.find(session => (session.ActivitySessionId == this.classDetail.SessionId || session.ParentSessionId == this.classDetail.SessionId ) && moment(session.SessionDate).format('YYYY-MM-DD') == moment(this.classDetailDate).format('YYYY-MM-DD'));
      if(!selectedSession){
         selectedSession = this._settings.SpecialMeals.find(activity => activity.Id == this.classDetail.Id)?.ActivitySessions.find(session => (session.ActivitySessionId == this.classDetail.SessionId));      
      }
      if(selectedSession){
        console.log(selectedSession);
      this.LocationGroupDetails =  this._settings.LocationGroups.find(location => location.LocationGroupId == selectedSession.LocationGroupId)?.Name || null;
      }
    }
  }

  loadHeadlines(ActivityAttributes) {
    let activityAttributesForSession = ActivityAttributes ? ActivityAttributes : [];
    let publicArray = { name: "Public", values: this.getPrivateAndPublicHeadline(activityAttributesForSession.filter(x => x.AttributeType == AttributeType.PublicHeadlines), this.classDetail) || [] };
    let privateArray = { name: "Private", values: this.getPrivateAndPublicHeadline(activityAttributesForSession.filter(x => x.AttributeType == AttributeType.PrivateHeadlines), this.classDetail) || [] };
    this.publicQuickNote = publicArray;
    this.privateQuickNote = privateArray;
  }
  getPrivateAndPublicHeadline(ActivityAttributes, session) {
    let headline;
    if (ActivityAttributes?.length) {
      let columnDate = session?.weeklyViewDate || this.cs.headerDate
      headline = ActivityAttributes.find(x => x.ActivitySessionId == session.SessionId && x.Description !== '' && moment(columnDate).format("YYYY-MM-DD") == moment(x.StartDate).format("YYYY-MM-DD") && moment(columnDate).format("YYYY-MM-DD") == moment(x.EndDate).format("YYYY-MM-DD"));
     if(!headline){
        headline = ActivityAttributes.find(x => x.ActivitySessionId == session.SessionId && x.Description == '' && moment(columnDate).format("YYYY-MM-DD") == moment(x.StartDate).format("YYYY-MM-DD") && moment(columnDate).format("YYYY-MM-DD") == moment(x.EndDate).format("YYYY-MM-DD"));
      }
      if (!headline) {
        headline = ActivityAttributes.find(x => x.ApplyForAllSessions && !x.IsDefault && moment(columnDate).format("YYYY-MM-DD") == moment(x.StartDate).format("YYYY-MM-DD") && moment(columnDate).format("YYYY-MM-DD") == moment(x.EndDate).format("YYYY-MM-DD"))
      }
      if (!headline) {
        headline = ActivityAttributes.find(x => x.IsDefault && !x.ActivitySessionId);
      }
    }
    return headline && headline.Description !== '' ? [headline] : null;
  }
  updateClassDetail(activities) {
    var selectedClass = activities.ByClass.length ? activities.ByClass.find(({ Id: classId }) => classId === this.classDetail.Id) : null;
    if (selectedClass) {
      this.classDetail.Sessions = [...selectedClass.Sessions];
      var selectedSession = selectedClass.Sessions.find(session => session.SessionId === this.classDetail.SessionId)
      this.classDetail.startTime = new Date('1970-01-01 ' + selectedSession.StartTime);
      this.classDetail.endTime = new Date('1970-01-01 ' + selectedSession.EndTime);
      this.classDetail.available = selectedSession.Available;
      this.classDetail.booked = selectedSession.Booked;
      this.staffAndLocationMapping();
    } else {
      this.onClose();
    }
  }

  updateAddOnDetails(activity) {
    this.partyWithAddon = activity; 
  }


  loadAddOnForActivity() {
    this.showAddOnDetails = false;
    this.addonsForBookingPerLesson = [];
    this.addonsForActivitySelected = _.orderBy(this.addonsForActivitySelected, 'IsMandatory', 'desc')
    this.addonsForActivitySelected.forEach(data => {
      let addOnItem = this._settings.Addons.find(addOn => (data.AddonId == addOn.AddonId))

      if (addOnItem && addOnItem.SelectionType == SelectionType.PerLesson) {
        let category = this._settings.Categories.find(category => category.Id == addOnItem.CategoryId)
        if (category) {
          data['CategoryColor'] = `rgba(${category.Color.R}, ${category.Color.G}, ${category.Color.B}, ${category.Color.A})`;
        }
        this.addonsForBookingPerLesson.push(data);
      }
      else {
        this.showAddOnIcon = true;
        this.addonForActivity.push(data);
      }

    })
  }

  ngAfterViewInit() {
    this.subscriptions.add(this.partyService.activityBlockingChange$.subscribe(data=>{
      this.setButtonControls();
      this.loadBlockedSession();
    }));
    this.subscriptions.add(this.searchConfigForm?.form?.valueChanges
      .pipe(debounceTime(100),
        distinctUntilChanged())
      .subscribe((val: any) => {
        this.searchAttendeesText = val.searchText;
      }));
    this.subscriptions.add(this.partyService.Parties$.subscribe(parties => {
      this.staffAndLocationMapping();
    }))
    this.subscriptions.add(this.cs.settings.subscribe(sett => {
      this._settings = sett;
      this.addonForActivity = [];
      this.addonsForBooking = [];
      this.addonsForBookingPerLesson = [];
      this.loadAddOnForActivity();
      this.loadBlockedSession();
      this.loadCancelledSession();  
      this.loadLocationGroup();
      let attributes =  this._settings.SpecialMeals.find(activity => activity.Id == this.classDetail.Id)?.ActivityAttributes;
      if(!this.preventLoadSettings){
        this.loadHeadlines(attributes);
      }
      this.setButtonControls();
    }));

  }

  staffAndLocationMapping() {
    let tempStaffs: any[] = [];
    let tempLocations: any[] = [];
    let partyIds: any[] = [];
    // if(this.selectedViewId !== ViewBy.Lessons){
    this.classDetail.Sessions = this.classDetail.Sessions.filter(session => session.SessionId === this.classDetail.SessionId || session.ActivitySessionId === this.classDetail.SessionId);
    // }
    this.classDetail.Sessions.forEach(session => {
      tempStaffs = [...tempStaffs, ...session.Staffs];
      tempLocations = [...tempLocations, ...session.Locations];
      if (session.ActivityBookings) {
        partyIds = [...partyIds, ...session.ActivityBookings];
      }

    });
    if (this.classDetail.location && this.selectedViewId === ViewBy.location) {
      this.locations = [this.classDetail.location];
    } else {
      this.locations = Utilities.uniqueBy(tempLocations, 'Id', 'Name').map(location => location['Name']);
    }
    if (this.classDetail.instructor && this.selectedViewId === ViewBy.Instructor) {
      this.staffs = [this._settings.Servers.find(server => server.Id === this.classDetail.instructorId)];
    } else {
      //this.staffs = this.classDetail.instructors
      this.staffs = uniqBy(tempStaffs, "Id");
    }
    //setting full name
    if (this.staffs && this.staffs.length) {
      this.staffs = this.staffs.map(instrctorObj => {
        instrctorObj.fullName = instrctorObj['Name'] ? instrctorObj['Name'] : (instrctorObj['FirstName'] && instrctorObj['FirstName'] != "undefined" ? instrctorObj['FirstName'] : '') + (instrctorObj['LastName'] && instrctorObj['LastName'] != "undefined" ? " " + instrctorObj['LastName'] : '')
        return instrctorObj;
      });
    }
    let loadedParties = [];
    if (this.classDetail?.ClassType == ClassType.Session) {
      loadedParties = this.partyService.Parties$?.value.filter(party => {
        if (party.BookedSessions) {
          let ids = party.BookedSessions.map(field => field.ActivitySessionId); return ids.includes(this.classDetail.SessionId)
        } else return false;
      });
    } else {
      loadedParties = this.partyService.Parties$?.value.filter(party => party.SessionGroupId == this.classDetail.SessionGroupId);
    }
    this.loadAttendees(loadedParties?.filter(attendee => (attendee.State == PartyState.Pending || attendee.State == PartyState.Seated || attendee.State == PartyState.Left || attendee.State == PartyState.Cancelled)))
  }

  showPartyWaiverForm(party) {
    this.subscriptions.add(this.partyService.getWaiverFormUrl(party.ConfirmationCode).subscribe(data => {
      if (data.State == OperationResultState.Success) {
        if (!(data.Payload)) {
          window.open(party.WaiverFormURL, 'Waiver Form');
        }
        else {
          const binaryString = window.atob(data.Payload);
          const bytes = new Uint8Array(binaryString.length);
          const binaryToBlob = bytes.map((byte, i) => binaryString.charCodeAt(i));
          const blob = new Blob([binaryToBlob], { type: 'application/pdf' });
          this.downloadFile(blob, "123.pdf");
        }
      }
    }));
  }

  private downloadFile(blob: any, fileName: string): void {

    const url = (window.URL || window.webkitURL).createObjectURL(blob);
    window.open(url, '_blank');

    // rewoke URL after 15 minutes
    setTimeout(() => {
      window.URL.revokeObjectURL(url);
    }, 15 * 60 * 1000);
  }

  loadAttendees(partyIds: any[]) {
    if (!this.classDetail.IsForStandbyReservations && !this.AllowStandBy) {
      let partiesForActivity = [...this.partyService.Parties$.value, ...this.partyService.CancelledReservations$.value]
      this.setReservedAttendees(partiesForActivity, partyIds);
    } else {
      this.standBySubscription = this.partyService.StandbyParties$.subscribe(parties => {
        this.setReservedAttendees([...this.partyService.Parties$.value, ...this.partyService.CancelledReservations$.value], partyIds);
        if (this.classDetail?.ClassType == ClassType.Session) {
          parties = parties.filter(party => {
            if (party.BookedSessions) {
              let ids = party.BookedSessions.map(field => field.ActivitySessionId); return ids.includes(this.classDetail.SessionId)
            } else return false;
          });
        } else {
          parties = parties?.filter(party => party.SessionGroupId == this.classDetail.SessionGroupId);
        }
        this.setStandByAttendees(parties);
      });
      if (this.standbyAttendees.length) {
        this.standbyAttendees = _.uniqBy(this.standbyAttendees, function (attendee) {
          return attendee.Id;
        });
      }
      if (this.attendees.length) {
        this.attendees = _.uniqBy(this.attendees, function (attendee) {
          return attendee.Id;
        });
      }
    }
  }

  setStandByAttendees(parties) {
    this.standbyAttendees = [];
    parties.forEach(party => {
      if (party.BookedSessions && !party.SessionGroupId) {
        let bookedSession = party.SpecialMealId === this.classDetail.Id
          && party.BookedSessions?.find(bookedSession => (bookedSession.ActivitySessionId === this.classDetail.SessionId));
        if (bookedSession) {
          party.SessionAmount = bookedSession.RatePlanAmount + (bookedSession.NegotiatedAmount || 0);
          party.SessionTaxAmount = bookedSession.TaxAmount || 0;
          party.BookingSize = bookedSession.BookingSize;
          if (this.classDetail.ClassType == PricingBy.Session) {
            party.State = bookedSession.SessionState;
          }

          if (bookedSession.ActivitySessionId) {
            //const selectedSession = party.BookedSessions?.filter(session => session?.Id === bookedSession.Id)[0];
            party.BookedSessionId = bookedSession.Id;
            // party.BookingSize = party.BookedSessions?.length ? party.BookedSessions.filter(session => session.Id == bookedSession.ActivitySessionId)[0].BookingSize : null;
            party.BookingSize = bookedSession.BookingSize ? bookedSession.BookingSize : party.Size;
            party.isConfirmedReservation = bookedSession.SessionType === PartyType.Reservation ? true : false;
          } else {
            party.isConfirmedReservation = party.type === PartyType.Reservation ? true : false;
            party.BookingSize = party.BookingSize ? party.BookingSize : party.Size;
          }
          party.GuestName = party.Contact.FirstName + (party.Contact.LastName || '')
          if (party.isConfirmedReservation) {
            this.attendees.push(party);
          } else {
            party.isPast = this.isPastReservation(party);
            this.standbyAttendees.push(party);
          }
        }
      } else {
        if (parties) {
          let partySelected = parties.find(classBooking => classBooking.SessionGroupId == this.classDetail.SessionGroupId);
          if (partySelected) {
            party.SessionTaxAmount = partySelected.TaxAmount;
            party.SessionAmount = partySelected.Amount;
            party.BookingSize = party.Size;
            if (this.classDetail.ClassType == PricingBy.Session) {
              party.State = partySelected.State;
            }

            if (partySelected.BookedSessionId) {
              const selectedSession = party.BookedSessions?.filter(session => session.Id === partySelected.BookedSessionId)[0];
              party.BookedSessionId = partySelected.BookedSessionId;
              party.BookingSize = party.BookedSessions?.length ? party.BookedSessions.filter(session => session?.Id == partySelected.BookedSessionId)[0].BookingSize : null;
              party.BookingSize = party.BookingSize ? party.BookingSize : party.Size;
              party.isConfirmedReservation = selectedSession ? selectedSession.SessionType === PartyType.Reservation ? true : false : false;
            } else {
              party.isConfirmedReservation = party.type === PartyType.Reservation ? true : false;
              party.BookingSize = party.BookingSize ? party.BookingSize : party.Size;
            }
            party.GuestName = party.Contact.FirstName + (party.Contact.LastName || '')
            if (party.isConfirmedReservation) {
              this.attendees.push(party);
            } else {
              party.isPast = this.isPastReservation(party);
              this.standbyAttendees.push(party);
            }
          }
        }
      }
      //Set partyAmount 
      let bookingAmount = this.partyService.getBookingAmount(party);
      party['partyAmount'] = bookingAmount.partyAmount;
    });
  }

  navigateLeft() {
    let element = this.el.nativeElement;
    if (element.offsetWidth < element.scrollWidth) {
      this.rightArrowDisabled = false;
      element.scrollLeft = element.scrollLeft - 322;
      if (element.scrollLeft == 0) {
        this.leftArrowDisabled = true;
      }
      else {
        this.leftArrowDisabled = false;
      }
    }
  }

  navigateRight() {
    let element = this.el.nativeElement;
    if (element.offsetWidth < element.scrollWidth) {
      this.leftArrowDisabled = false;
      element.scrollLeft = element.scrollLeft + 322;
      if (element.scrollLeft == element.scrollWidth) {
        this.rightArrowDisabled = true;
      }
      else {
        this.rightArrowDisabled = false;
      }
    }
  }

  isPastReservation(party) {
    let selectedActivity = this._settings.SpecialMeals.filter(activity => activity.Id == party.SpecialMealId)[0];
    var selectedSession = selectedActivity ? selectedActivity.ActivitySessions.find(session => session.ActivitySessionId == this.classDetail.SessionId) : null;
    if (selectedSession && selectedSession.StartTime) {
      let currentTime = Utilities.getRestaurantDateTime(this._settings.General.DaylightDelta);
      var sessionDateTime = Utilities.AppendDateWithTime(this.appService.headerDate$.value, selectedSession.EndTime);
      if (moment(currentTime).diff(sessionDateTime, 'minute') >= 0) {
        return true;
      }
    }

    return false;
  }

  getDateinRestaurantDateTimeOffset(datetime: any) {
    const offset = Number(this._settings.General.DaylightDelta);
    const d = new Date(datetime);
    const utc = d.getTime() + (d.getTimezoneOffset() * 60000);
    const nd = new Date(utc + (3600000 * offset));
    return nd;
  }


  setReservedAttendees(parties, partyIds) {
    this.attendees = [];
    if (partyIds) {
      parties.forEach(party => {

        if (party.BookedSessions && !party.SessionGroupId) {
          let bookedSession = party.SpecialMealId === this.classDetail.Id
            && party.BookedSessions?.find(bookedSession => (bookedSession.ActivitySessionId === this.classDetail.SessionId));
          if (bookedSession) {
            party.BookedSessionId = bookedSession.Id;
            party.SessionAmount = bookedSession.RatePlanAmount + (bookedSession.NegotiatedAmount || 0);
            party.SessionTaxAmount = bookedSession.TaxAmount || 0;
            party.BookingSize = bookedSession.BookingSize;
            party.BookingSize = party.BookingSize ? party.BookingSize : party.Size;
            party.PackageName = this.cs.availablePackages.find(pack => pack.Id == party.PackageId)?.Name;
            party.isLocationGroupMapped = bookedSession.Locations?.length;
            party.checkInWithDues = this.partyService.validateAvailabilityForCheckInWithDues(party)
            let selectedActivity = this._settings.SpecialMeals.filter(activity => activity.Id == party.SpecialMealId)[0];
            if (selectedActivity.ClassType == PricingBy.Session) {
              party.State = bookedSession.SessionState;
            }
            party.isConfirmedReservation = true;
            party.GuestName = party.Contact.FirstName + (party.Contact.LastName || '')
            this.attendees.push(party);
          }
        } else {
          let partySelected = partyIds.find(partyId => partyId.PartyId === party.Id || partyId.Id === party.Id);
          if (partySelected) {
            party.BookedSessionId = partySelected.BookedSessionId;
            party.SessionAmount = partySelected.Amount;
            party.SessionTaxAmount = partySelected.TaxAmount;
            party.BookingSize = party.BookedSessions?.length ? party.BookedSessions.filter(session => session.Id == partySelected.BookedSessionId)[0].BookingSize : null;
            party.BookingSize = party.BookingSize ? party.BookingSize : party.Size;
            party.PackageName = this.cs.availablePackages.find(pack => pack.Id == party.PackageId)?.Name;
            party.checkInWithDues = this.partyService.validateAvailabilityForCheckInWithDues(party)
            let selectedActivity = this._settings.SpecialMeals.filter(activity => activity.Id == party.SpecialMealId)[0];
            if (selectedActivity.ClassType == PricingBy.Session) {
              party.State = partySelected.State;
            }
            party.isConfirmedReservation = true;
            party.GuestName = party.Contact.FirstName + (party.Contact.LastName || '')
            this.attendees.push(party);
          }
        }

        //Set partyAmount 
        let bookingAmount = this.partyService.getBookingAmount(party);
        party['partyAmount'] = bookingAmount.partyAmount;

      });
    }

    this.attendees = uniqBy(this.attendees, 'Id');
  }



  showWarningInfoPopUp(message, componentType, popupHeight, popupWidth, title) {

    let cancelText = 'No';
    // let title = 'Confirm Check-in';
    //let msg = 'No card details collected to capture payment amount. Are you sure to checkin the attendee?'
    let noShowSet = false;
    let confirmCheckin = false;
    let updateText = 'Yes';
    let showAlert = false;
    let noShowFeePopUp = false;

    const popUpMessage = [{
      confirmationMessage: message, dialogTitle: 'confirm', showAlert: showAlert
    }];

    const componentDetails: ComponentDetails = {
      componentName: ConfirmationPopupComponent,
      dimensionType: 'small',
      popupType: 'active',
      popUpDetails: {
        isStepper: false,
        eventName: 'notifyParent'
      },
      popupInput: popUpMessage,
      popupTitle: popUpMessage[0].dialogTitle
    };

    this.warningInfoDialogRef = this.dialog.open(CustomPopupComponent, {
      disableClose: true,
      width: popupWidth,
      height: popupHeight,
      data: {
        title,
        update: updateText,
        cancel: cancelText,
        componentDetails,
        from: componentType,
        back: false,
        standalone: true,
        showAlert: true
      }
    });


  }

  checkInClick(attendee, withDues?) {
    let confirmCheckIn = false;
    let selectedActivity = this._settings.SpecialMeals.filter(activity => activity.Id == attendee.SpecialMealId)[0];
    if (selectedActivity && selectedActivity.MinActivityStrength > this.classDetail.booked) {
      let msg = this.ts.instant('minimumActivityWarningMessage');
      this.showWarningInfoPopUp(msg, ComponentTypes.CheckinSession, '250px', '450px', 'Confirm Check-in')
      this.subscriptions.add(this.warningInfoDialogRef?.afterClosed().subscribe(event => {
        if (confirmCheckIn) {
          this.validateStartTimeForCheckIn(attendee, selectedActivity, withDues);
        }
        this.UnSubscribePopUpAction(confirmCheckInPopUpConfirmationSubscription);
      }));

      let confirmCheckInPopUpConfirmationSubscription = this.popupService.confirmedAction$.subscribe(val => {
        if (val === ComponentTypes.CheckinSession) {
          confirmCheckIn = true;
        }
      });
    }
    else {
      this.validateStartTimeForCheckIn(attendee, selectedActivity, withDues);
    }
  }

  confirmClick(attendee) {
    let confirmBooking = false;
    let selectedActivity = this._settings.SpecialMeals.filter(activity => activity.Id == attendee.SpecialMealId)[0];
    if (selectedActivity) {
      let msg = this.ts.instant('areYouSureYouWanttoConfirm');
      this.showWarningInfoPopUp(msg, ComponentTypes.ConfirmBooking, '250px', '450px', this.ts.instant('confirmReservation'))
      this.subscriptions.add(this.warningInfoDialogRef?.afterClosed().subscribe(event => {
        this.UnSubscribePopUpAction(confirmInPopUpConfirmationSubscription);
      }));

      let confirmInPopUpConfirmationSubscription = this.popupService.confirmedAction$.subscribe(val => {
        if (val === ComponentTypes.ConfirmBooking) {
          confirmBooking = true;
          this.partyService.convertActivityStandbyToReservation(attendee, false, null);
          confirmInPopUpConfirmationSubscription.unsubscribe();
        }
      });
    }
  }

  rejectClick(attendee) {
    let rejectBooking = false;
    this.partyService.cancelBookingContactObj = attendee?.Contact;
    let selectedActivity = this._settings.SpecialMeals.filter(activity => activity.Id == attendee.SpecialMealId)[0];
    if (selectedActivity && selectedActivity.MinActivityStrength) {
      const componentDetails: ComponentDetails = {
        componentName: RejectStanbyComponent,
        dimensionType: 'small',
        popupType: 'active',
        popUpDetails: {
          isStepper: false,
          eventName: 'notifyParent'
        },
        popupInput: '',
        popupTitle: this.ts.instant('rejectionReason'),
      };
      const dialogRef = this.dialog.open(CustomPopupComponent, {
        disableClose: true,
        width: '450px',
        height: '350px',
        data: {
          title: this.ts.instant('rejectionReason'),
          update: 'Yes',
          cancel: 'No',
          componentDetails,
          from: ComponentTypes.RejectBooking,
          back: false,
          standalone: true,
          showAlert: true
        }
      });
      this.subscriptions.add(dialogRef.afterClosed().subscribe(data => {
        if (rejectBooking) {
          alert('reject Click');
        }
        this.UnSubscribePopUpAction(rejectInPopUpConfirmationSubscription);
      }));

      let rejectInPopUpConfirmationSubscription = this.popupService.confirmedAction$.subscribe(val => {
        if (val === ComponentTypes.RejectBooking) {
          this.partyService.rejectStandActivity(this.partyService.ReasonForReject, attendee, attendee.Contact.EmailAddress, attendee.BookedSessionId);
          rejectInPopUpConfirmationSubscription.unsubscribe();
        }
      });
    }
  }

  validateStartTimeForCheckIn(attendee, selectedActivity, withDues?) {
    let confirmCheckIn = false;
    var selectedSession = selectedActivity ? selectedActivity.ActivitySessions.find(session => session.ActivitySessionId == this.classDetail.SessionId) : null;
    if (selectedSession && selectedSession.StartTime) {
      let currentTime = Utilities.getRestaurantDateTime(this._settings.General.DaylightDelta);
      var sessionDateTime = Utilities.AppendDateWithTime(this.appService.headerDate$.value, selectedSession.StartTime);
      if (moment(sessionDateTime).diff(currentTime, 'minute') > selectedActivity.CheckInCutOff) {
        let sessionTime = moment(sessionDateTime).diff(currentTime, 'days') == 0 ? moment(sessionDateTime).locale(this.dashboardFunctions.getLocaleCode()).format("hh:mm a") : moment(sessionDateTime).locale(this.dashboardFunctions.getLocaleCode()).format("DD-MMM-yyyy hh:mm a");
        let checkInStartTime = sessionDateTime.setMinutes(sessionDateTime.getMinutes() - selectedActivity.CheckInCutOff);
        if (selectedActivity.CheckInCutOff && selectedActivity.CheckInCutOff !== 0) {
          let checkIntime = moment(sessionDateTime).diff(currentTime, 'days') == 0 ? this.datePipe.transform(checkInStartTime, "hh:mm a") : this.datePipe.transform(checkInStartTime, "DD-MMM-yyyy hh:mm a");
          let msg = '';
          this.ts.get('CheckInAlert', { sessionTime, checkIntime }).subscribe(msgVal => msg = msgVal);
          this.showCheckInfoPopUp(msg)
        } else {
          let msg = '';
          this.ts.get('CheckInConfirm', { sessionTime }).subscribe(msgVal => msg = msgVal);
          this.showWarningInfoPopUp(msg, ComponentTypes.CheckinSession, '300px', '400px', 'Confirm Check-in')
        }
        this.subscriptions.add(this.warningInfoDialogRef?.afterClosed().subscribe(event => {
          if (confirmCheckIn) {
            // this.checkInAttendee(attendee , withDues);
            this.confirmCheckin(attendee, withDues);
          }
          this.UnSubscribePopUpAction(confirmCheckInPopUpConfirmationSubscription);
        }));

        let confirmCheckInPopUpConfirmationSubscription = this.popupService.confirmedAction$.subscribe(val => {
          if (val === ComponentTypes.CheckinSession) {
            confirmCheckIn = true;
          }
        });
      }
      else {
        // this.checkInAttendee(attendee , withDues)
        this.confirmCheckin(attendee, withDues);
      }
    }
  }

  showCheckInfoPopUp(message) {
    this.utilities.showAlert(message, AlertType.Info, ButtonType.Ok);
  }
  checkInAttendee(attendee, withDues?) {
    let confirmCheckIn = false;
    if ((attendee.PrepaymentState == PartyPrepaymentState.PrepaymentRequired && attendee.RatePlanAmount > 0 && !attendee.CreditCardId)
      || (!attendee.CreditCardId && attendee.PrepaymentState == PartyPrepaymentState.DeferredPayment && attendee.ChargeState == PartyChargeState.Pending && attendee.RatePlanAmount > 0)) {
      let msg = this.ts.instant('noCreditCardMessage');
      this.showWarningInfoPopUp(msg, ComponentTypes.CheckinSession, '250px', '450px', 'Confirm Check-in')
      this.subscriptions.add(this.warningInfoDialogRef?.afterClosed().subscribe(event => {
        if (confirmCheckIn) {
          this.confirmCheckin(attendee, withDues)
        }
        this.UnSubscribePopUpAction(confirmCheckInPopUpConfirmationSubscription);
      }));

      let confirmCheckInPopUpConfirmationSubscription = this.popupService.confirmedAction$.subscribe(val => {
        if (val === ComponentTypes.CheckinSession) {
          confirmCheckIn = true;
        }
      });
    }
    else {
      this.confirmCheckin(attendee, withDues);
    }

  }
  checkInWithDues(attendee) {
    if (Utilities.isUserHasAccessToRetail(this.appService.restaurantId)) {
      let party = this.partyService.Parties$.value.find(data => data.Id == attendee.Id);
      party.FinancialEffectId = null;
      party.Sessions = {SessionId : party.BookedSessionId};
      this.partyService.getFinancialDetails(party.Id, FinancialEffectAction.CheckIn,null,null).subscribe(data => {
        console.log(data);
        this.partyService.openCheckInWithDuesPopUp(party, data.Payload, ComponentTypes.CheckInRatePlanSummary, false);   
      })
    } else {
      this.utilities.showAlert(this.ts.instant('userPermissionMsg'), AlertType.Info, ButtonType.Ok);
    }
  }

  checkOutWithDues(attendee) {
    if (Utilities.isUserHasAccessToRetail(this.appService.restaurantId)) {
      let party = this.partyService.Parties$.value.find(data => data.Id == attendee.Id);
      party.FinancialEffectId = null;
      party.Sessions = {SessionId : party.BookedSessionId};
      this.partyService.getFinancialDetails(party.Id, FinancialEffectAction.CheckOut,null,null).subscribe(data => {
        console.log(data);
        this.partyService.openCheckInWithDuesPopUp(party, data.Payload, ComponentTypes.CheckOutRatePlanSummary, false);
      })
    } else {
      this.utilities.showAlert(this.ts.instant('userPermissionMsg'), AlertType.Info, ButtonType.Ok);
    }
  }

  confirmCheckin(attendee, withDues) {
    if (this.partyService.validateAvailabilityForCheckInWithDues(attendee) && withDues == true) {
      this.checkInWithDues(attendee);
    } else {
      this.apiService.checkIn(attendee.Id, attendee.SpecialMealId, attendee.BookedSessionId).subscribe(response => {
        //this.attendees.find(attendeeSrc => attendee.Id === attendeeSrc.Id).State = this.PartyState.Seated;
        let sessionObject;
        sessionObject = this.partyService.Parties$.value.find(data => data.Id == attendee.Id);
        if (!sessionObject) {
          sessionObject = this.partyService.StandbyParties$.value.find(data => data.Id == attendee.Id)
        }
        if (response.Payload && response.Payload.updateFinancialEffect) {
          this.partyService.Operations = Operations.checkIn;
          this.retailservice.oldReservation = _.cloneDeep(attendee);
          let isStandBy = attendee.Type == PartyType.StandBy ? true : false;
          this.partyService.selectedParty$.next(attendee);
          this.partyService.SetRetailItem(null, attendee.Id, null, response.Payload.updateFinancialEffect, isStandBy, response.Payload.updateFinancialEffect, null, response.Payload.updateFinancialEffect?.CancellationAmount)
        }

        if (response.Payload && response.Payload.PaymentState && response.Payload.PaymentState == 1 && response.Payload.PaymentAmount > 0 && response.Payload.PaymentType == FinancialEffectType.PartialRefund) {// Refund
          if (this.isRetailEnabled)
            this.partyService.openConfirmationDialog(response, this.ts.instant('retailRedirectMsg') + this.cs.settings.value.General.OperationCurrency + response.Payload.PaymentAmount.toFixed(2) + this.ts.instant('beforeCheckedIn'), null, null, null, null, sessionObject);
          else
            this.partyService.openConfirmationDialog(response, this.ts.instant('refundFor') + this._settings.General.OperationCurrency + response.Payload.PaymentAmount.toFixed(2) + this.ts.instant('successCheckedIn') + (attendee.SessionGroupId > 0 ? "Class" + this.partyService.getActivityName(attendee.SpecialMealId) : this.ts.instant('sessionMsg')), null, null, null, null, sessionObject);
        }
        else if (response.Payload && response.Payload.PaymentState && response.Payload.PaymentState == 1 && response.Payload.PaymentAmount > 0 && response.Payload.PaymentType == FinancialEffectType.PartialCharge) {// Charge
          if (this.isRetailEnabled)
            this.partyService.openConfirmationDialog(response, this.ts.instant('posRedirectMsg')
           + this.cs.settings.value.General.OperationCurrency + response.Payload.PaymentAmount.toFixed(2) + this.ts.instant('beforeCheckedIn'), null, null, null, null, sessionObject);
          else
            this.partyService.openConfirmationDialog(response, this.ts.instant('attendeeCharged') + this._settings.General.OperationCurrency + response.Payload.PaymentAmount.toFixed(2) + this.ts.instant('checkedInFor') + (attendee.SessionGroupId > 0 ? "Class" + this.partyService.getActivityName(attendee.SpecialMealId) : this.ts.instant('sessionMsg')), null, null, null, null, sessionObject);
        }
        else {
          this.partyService.openConfirmationDialog(response, this.ts.instant('attendeeCheckedIn') + (attendee.SessionGroupId > 0 ? this.ts.instant('classText') + this.partyService.getActivityName(attendee.SpecialMealId) : this.ts.instant('sessionMsg')), null, null, null, null, sessionObject);
        }
      });
    }
  }

  // getActivityName(activityId): string {
  //   return this._settings.SpecialMeals.find(activity => activity.Id == activityId).Name;
  // }

  checkOutClick(attendee) {
    if (this.partyService.validateAvailabilityForCheckInWithDues(attendee)) {
      this.checkOutWithDues(attendee);
    } else {
      this.apiService.checkOut(attendee.Id, attendee.SpecialMealId, attendee.BookedSessionId).subscribe(response => {
        //this.attendees.find(attendeeSrc => attendee.Id === attendeeSrc.Id).State = this.PartyState.Left;
        if (response.Payload && response.Payload.updateFinancialEffect) {
          this.partyService.Operations = Operations.checkOut;
          this.retailservice.oldReservation = _.cloneDeep(attendee);
          let isStandBy = attendee.Type == PartyType.StandBy ? true : false;
          this.partyService.selectedParty$.next(attendee);
          this.partyService.SetRetailItem(null, attendee.Id, null, response.Payload.updateFinancialEffect, isStandBy, response.Payload.updateFinancialEffect, null, response.Payload.updateFinancialEffect?.CancellationAmount)
        }
        if (response.Payload && response.Payload.PaymentState && response.Payload.PaymentState == 1 && response.Payload.PaymentAmount > 0 && response.Payload.PaymentType == FinancialEffectType.PartialRefund) {// Refund
          if (this.isRetailEnabled)
            this.partyService.openConfirmationDialog(response,  this.ts.instant('retailRedirectMsg')+ this.cs.settings.value.General.OperationCurrency + response.Payload.PaymentAmount.toFixed(2) + this.ts.instant('beforeCheckedOut'));
          else
            this.partyService.openConfirmationDialog(response, this.ts.instant('refundFor') + this._settings.General.OperationCurrency + response.Payload.PaymentAmount.toFixed(2) + this.ts.instant('successCheckedOut') + (attendee.SessionGroupId > 0 ? "Class" + this.partyService.getActivityName(attendee.SpecialMealId) : this.ts.instant('sessionMsg')), null);
        }
        else if (response.Payload && response.Payload.PaymentState && response.Payload.PaymentState == 1 && response.Payload.PaymentAmount > 0 && response.Payload.PaymentType == FinancialEffectType.PartialCharge) {// Charge
          if (this.isRetailEnabled)
            this.partyService.openConfirmationDialog(response, this.ts.instant('posRedirectMsg') + this.cs.settings.value.General.OperationCurrency + response.Payload.PaymentAmount.toFixed(2) + this.ts.instant('beforeCheckedOut'));
          else
            this.partyService.openConfirmationDialog(response, this.ts.instant('attendeeCharged') + this._settings.General.OperationCurrency + response.Payload.PaymentAmount.toFixed(2) + this.ts.instant('checkedOutFor') + (attendee.SessionGroupId > 0 ? "Class" + this.partyService.getActivityName(attendee.SpecialMealId) : this.ts.instant('sessionMsg')), null);
        }
        else if (this.partyService.Operations != Operations.checkOut) {
          this.partyService.openConfirmationDialog(response, this.ts.instant('attedeeCheckedOut') + (attendee.SessionGroupId > 0 ? this.ts.instant('classText') + this.partyService.getActivityName(attendee.SpecialMealId) : this.ts.instant('sessionMsg')), null);
        }
      });
    }
  }

  changeEvent(name) {
    switch (name) {
      case this._MESSAGES.labels.book: {
        if (this.staffs.length > 1) {
          //Remove booking confiramtion screen 
          // this.getBookConfirmation();
          this.bookClass();
        } else {
          this.bookClass();
        }
        break;
      }
      case this._MESSAGES.labels.cancelThisActivity: {
        if(this.permissionPipe.transform(RolesAndPermissionsType.CancelActivity)){
          this.cancelSession();
        }else{
          this.toastrService.warning(this.ts.instant('accessDenied'), "", { timeOut: 5000, closeButton: true });    
          return;
        }
      
        break;
      }
      case this._MESSAGES.labels.block: {
        this.classDetail.SpecialMealId = this.classDetail.Id;
        this.partyService.promptBlockSession(this.classDetail, this.classDetail, true)
        break;
      }
      case this._MESSAGES.labels.unBlock: {
        this.classDetail.SpecialMealId = this.classDetail.Id;
        this.partyService.promptBlockSession(this.classDetail, this.classDetail, false)
        break;
      }
      case this._MESSAGES.labels.ReAssignAsset: {
        let assetDTO:ReAssignAssetDTO ={
          ActivityId: this.classDetail.Id,
          ActivitySessionId: this.classDetail.SessionId,
          ActivitySessionDate: new Date(moment(this.appService.headerDate$.value).format('YYYY-MM-DD'))
        }
        this.subscriptions.add(this.apiService.ReAssignAsset(assetDTO).subscribe(data =>{

        }));
      }
    }
  }
  bookClass() {

    let locationList = Utilities.getRestaurantTables(this._layout.FloorPlans || this.cs.layout['_value'].FloorPlans);


    const staffTbaId = this._settings.Servers?.find(x => x.IsTemplate)?.Id;
    const locationTbaId = locationList?.find(x => x.IsTemplate)?.Id;

    let selectedData = {
      fromDetailComponent: true,
      SessionId: this.classDetail.SessionId,
      SpecialMealId: this.classDetail.Id,
      locationId: this.classDetail.locationId && this.classDetail.locationId != locationTbaId ? this.classDetail.locationId : null,
      instructorId: (this.classDetail && this.classDetail.instructorId && this.classDetail.instructorId != staffTbaId) ? this.classDetail.instructorId : (this.classDetail.instructors && this.classDetail.instructors.length > 0 && this.classDetail.instructors[0].Id != staffTbaId ? this.classDetail.instructors[0].Id : null),
      selectedSlot: true,
      activityBookedDate: this.classDetail.startTime,
      startTime: this.classDetail.startTime,
      endTime: this.classDetail.endTime,
      StartDate: this.classDetail.StartDate,
      weeklyViewDate: this.classDetail.weeklyViewDate
    }
    this.dashboardFunctions.createOrEditActivityBooking(false, selectedData);
  }

  getBookConfirmation() {
    const noText: string = this.ts.instant('notext');
    const yesText: string = this.ts.instant('yestext');

    const msg = 'This session is jointly hosted by ' + this.getStaffNames() + '. Do you want to proceed?'

    const popUpMessage = [{
      confirmationMessage: msg, dialogTitle: 'confirm'
    }];
    const componentDetails: ComponentDetails = {
      componentName: ConfirmationPopupComponent,
      dimensionType: 'small',
      popupType: 'active',
      popUpDetails: {
        isStepper: false,
        eventName: 'notifyParent'
      },
      popupInput: popUpMessage
    };

    const dialogRef = this.dialog.open(CustomPopupComponent, {
      disableClose: true,
      width: '450px',
      height: '280px',
      data: {
        title: popUpMessage[0].dialogTitle,
        update: yesText,
        cancel: noText,
        componentDetails,
        from: ComponentTypes.reservation,
        back: false,
        standalone: true,
        showAlert: false
      }
    });
    this.confirmSubscription = this.popupService.confirmedAction$.subscribe((val) => {
      this.bookClass();
    });

    this.subscriptions.add(dialogRef.afterClosed().subscribe(() => {
      if (this.confirmSubscription) {
        this.confirmSubscription.unsubscribe();
      }
    }));

  }

  getStaffNames() {
    let staffName = '';
    this.staffs.forEach((staff, index) => {
      staffName = staffName + staff.fullName;
      if (index < (this.staffs.length - 3)) {
        staffName = staffName + ', ';
      } else if (index === this.staffs.length - 2) {
        staffName = staffName + ' and ';
      }
    });

    return staffName;
  }

  clearSearch(event) {
    this.searchConfigForm.form.get('searchText').setValue('');
  }

  editParty(partyInfo) {
    if ((partyInfo.State == PartyState.Seated && !this.dashboardFunctions.checkEditCheckedInReservationsConfig()) && !this._settings?.PropertySetting[0]?.EnableMultiSlotSelection) {
      this.dashboardFunctions.editGuest(partyInfo.Contact.Id, partyInfo.Id);
    } else {
    this.subscriptions.add(this.partyService.getPartyInfo(partyInfo.Id).subscribe(response => {
      if (response.Payload) {
        this.retailservice.oldReservation = _.cloneDeep(response.Payload);
        response.Payload['isConfirmedReservation'] = !this.classDetail.IsForStandbyReservations;
        response.Payload['isStandByLessonBooked'] = this.classDetail.IsForStandbyReservations;
        response.Payload['bookingRatePlan'] = response.Payload;
        this.dashboardFunctions.createOrEditActivityBooking(true, response.Payload);
      }
    }));
  }
  }
  
  locationListUpdate(attendee) {
    let locationList = [];
    if (attendee.BookedSessions?.length) {
      let selectedSession = attendee.BookedSessions?.find(session => session.Id == attendee.BookedSessionId)
      let defaultLayouts = this.cs.layout.value?.FloorPlans?.find(floor => floor.IsDefault)?.StandaloneTables;
      selectedSession?.Locations?.forEach(location => {
        let loc = {
          Name: defaultLayouts?.find(table => table.Id == location)?.Name
        }
        locationList.push(loc);
      })
    }
    this.locationListGroup = locationList;
  }

  cancelPartyForSessionOrClass(partyInfo) {
    this.partyService.BookingBehaviour = BookingBehavior.ClassOrSession;
    this.dashboardFunctions.CancelPartyForSessionOrClass(partyInfo , this.cancellationDetails.CancelledReason);
  }

  onClose() {
    this.close.emit();
  }

  isBySession(): boolean {
    return this.classDetail.ClassType === ClassType.Session;
  }

  addHeadlinesForActivity(classDetail, headline, noteType, componentName) {
    const popUpMessage = [{
      dialogTitle: componentName == ComponentTypes.cancelHeadlines ? this.ts.instant('deleteHeadline') : headline ? this.ts.instant('updateHeadline') : this.ts.instant('createHeadline'),
      showAlert: false,
      activityDetails: classDetail,
      headlines: headline,
      isCancel: componentName == ComponentTypes.cancelHeadlines,
      noteType: noteType.name
    }];
    this.headlineRequestSucceeded = true;
    const componentDetails: ComponentDetails = Utilities.setComponentDetails(ActivityHeadlinesComponent, 'small', '', popUpMessage,
      popUpMessage[0].dialogTitle);
    let headlinePopup = this.openCustomPopup(componentDetails, componentName ? componentName : ComponentTypes.saveHeadlines, '650px', 'auto', false, popUpMessage[0].dialogTitle, popUpMessage[0].isCancel ? 'Yes' : 'Save',
      popUpMessage[0].isCancel ? 'No' : 'Cancel', true);
    let saveConfirmation;
    if (componentName !== ComponentTypes.cancelHeadlines) {
      saveConfirmation = this.popupService.confirmedAction$.subscribe(val => {
        if (saveConfirmation) {
          saveConfirmation.unsubscribe();
        }
        if (val == ComponentTypes.saveHeadlines && this.headlineRequestSucceeded) {
          this.headlineRequestSucceeded = false;
          let activityAtributes = {
            Id: headline?.IsDefault ? 0 : (headline?.ApplyForAllSessions && this.partyService.headlinesForm.value.headlineType == HeadlineActivityType.OnlyThis) ? 0 : (headline ? headline.Id : 0),
            PropertyId: Utilities.RestaurantId(),
            ActivityId: classDetail.Id,
            ActivitySessionId: classDetail.SessionId,
            Description: this.partyService.headlinesForm.value.activityHeadlines,
            ApplyForAllSessions: this.partyService.headlinesForm.value.headlineType == HeadlineActivityType.OnlyThis ? false : true,
            AttributeType: noteType.name == 'Public' ? AttributeType.PublicHeadlines : AttributeType.PrivateHeadlines,
            StartDate: classDetail.weeklyViewDate ? (moment(classDetail.weeklyViewDate).format('YYYY-MM-DD') + 'T' + moment(classDetail.startTime).format('HH:mm:ss')) : (moment(this.cs.headerDate).format('YYYY-MM-DD') + 'T' + moment(classDetail.startTime).format('HH:mm:ss')),
            EndDate: classDetail.weeklyViewDate ? (moment(classDetail.weeklyViewDate).format('YYYY-MM-DD') + 'T' + moment(classDetail.endTime).format('HH:mm:ss')) : (moment(this.cs.headerDate).format('YYYY-MM-DD') + 'T' + moment(classDetail.endTime).format('HH:mm:ss')),
            IsDefault: false,
            CreatedBy: Utilities.getHostId()
          }
          headlinePopup.close();
          let ActivityAttributes = this._settings.SpecialMeals.find(activity => activity.Id == this.classDetail.Id)?.ActivityAttributes;
          let columnDate = classDetail.weeklyViewDate || this.cs.headerDate
          headline = ActivityAttributes.find(x => x.ApplyForAllSessions && activityAtributes.AttributeType == x.AttributeType && activityAtributes.Id != x.Id && moment(columnDate).format("YYYY-MM-DD") == moment(x.StartDate).format("YYYY-MM-DD") && moment(columnDate).format("YYYY-MM-DD") == moment(x.EndDate).format("YYYY-MM-DD"));
          if (headline && this.partyService.headlinesForm.value.headlineType == HeadlineActivityType.AllRemaining) {
            this.showHeadLineWarning(headline, activityAtributes, headlinePopup);
          } else {
            this.partyService.SaveHeadlines(activityAtributes).subscribe(data => {
              this.updateHeadlineSettings(data.Payload, activityAtributes.ActivityId);
            })
          }
        }
      });
    }
    let cancelSubscription = this.popupService.cancelledAction$.subscribe(val => {
      if (val.from == ComponentTypes.saveHeadlines || val.from == ComponentTypes.cancelHeadlines || val.from == ComponentTypes.headlineUpdateWarningMessage) {
        headlinePopup.close();
        this.UnSubscribePopUpAction(cancelSubscription);
        this.UnSubscribePopUpAction(saveConfirmation);
      }
    });
    if (componentName == ComponentTypes.cancelHeadlines) {
      this.headLineDeleteAction(headline, classDetail, headlinePopup);
    }
    headlinePopup.afterClosed().subscribe(event => {
      this.UnSubscribePopUpAction(saveConfirmation);
      this.UnSubscribePopUpAction(cancelSubscription);
    })
  }
  headLineDeleteAction(headline, classDetail, headlinePopup) {
     this.headlineRequestSucceeded = true;
    let deleteConfirmation = this.popupService.confirmedAction$.subscribe(val => {
      if (deleteConfirmation) {
        this.UnSubscribePopUpAction(deleteConfirmation)
      }
      if (val == ComponentTypes.cancelHeadlines && this.headlineRequestSucceeded) {
        this.headlineRequestSucceeded = false
        headlinePopup.close();
        let sessionId = this.partyService.headlinesForm.value.headlineType == HeadlineActivityType.OnlyThis ? classDetail.SessionId : null
        let Payload = {
          activityAttributeId: headline.Id,
          StartDate: classDetail.weeklyViewDate ? (moment(classDetail.weeklyViewDate).format('YYYY-MM-DD') + 'T' + moment(classDetail.startTime).format('HH:mm:ss')) : (moment(this.cs.headerDate).format('YYYY-MM-DD') + 'T' + moment(classDetail.startTime).format('HH:mm:ss')),
          EndDate: classDetail.weeklyViewDate ? (moment(classDetail.weeklyViewDate).format('YYYY-MM-DD') + 'T' + moment(classDetail.endTime).format('HH:mm:ss')) : (moment(this.cs.headerDate).format('YYYY-MM-DD') + 'T' + moment(classDetail.endTime).format('HH:mm:ss')),
        }
        this.partyService.DeleteHeadlines(Payload, sessionId).subscribe(data => {
          this.updateHeadlineSettings(data.Payload, classDetail.Id);
          this.toastrService.success(this.ts.instant("headlineDeleteSuccessMessage"), "", { timeOut: 1000, closeButton: true });
        })
      }
    })
    headlinePopup.afterClosed().subscribe(event => {
      this.UnSubscribePopUpAction(deleteConfirmation);
    })
  }

  showHeadLineWarning(headline, activityAtributes, headlinePopup) {
    this.headlineRequestSucceeded = true
    let msg = ''
    let notes = headline.Description
    this.ts.get('headlineUpdateWarningMessage', { notes }).subscribe(msgVal => {
      msg = msgVal;
    });
    this.showWarningInfoPopUp(msg, ComponentTypes.headlineUpdateWarningMessage, '300px', '450px', this.ts.instant('alert'));
    let healineUpdateConfirmSubscription = this.popupService.confirmedAction$.subscribe(val => {
      if (val == ComponentTypes.headlineUpdateWarningMessage && this.headlineRequestSucceeded) {
        this.headlineRequestSucceeded = false;
        headlinePopup.close();
        if (healineUpdateConfirmSubscription) {
          healineUpdateConfirmSubscription.unsubscribe();
        }
        activityAtributes.Id = 0;
        this.partyService.SaveHeadlines(activityAtributes).subscribe(data => {
          this.updateHeadlineSettings(data.Payload, activityAtributes.ActivityId);
        })
      }
    })
  }
  updateHeadlineSettings(activityAttributes, activityId) {
    if (activityAttributes) {
      this.preventLoadSettings = true;
      this.loadHeadlines(activityAttributes);
      let index = this._settings.SpecialMeals.findIndex(activity => activity.Id == activityId);
      this._settings.SpecialMeals[index].ActivityAttributes = activityAttributes;
      this.cs.settings.next(this._settings);
    }
  }


  deleteHeadlinesForActivity(classDetail, data, note) {
    this.addHeadlinesForActivity(classDetail, data, note, ComponentTypes.cancelHeadlines,);
  }

  cancelSession() {
    const restaurantDateTime = Utilities.getRestaurantDateTime(this._settings.General.DaylightDelta);    
    let selectedSession = this.classDetail.Sessions?.find(session => session.ActivitySessionId == this.classDetail.SessionId)
    let endTime = moment(this.classDetailDate).format('YYYY-MM-DD') +'T'+ selectedSession.EndTime;
    if (restaurantDateTime > new Date(endTime)) {
      this.toastrService.warning(this.ts.instant('sessionExpiredCancelMessage'), "", { timeOut: 5000, closeButton: true });
      return;
    } 

    const componentDetails: ComponentDetails = {
      componentName: CancelSessionPopupComponent,
      dimensionType: 'small',
      popupType: 'active',
      popUpDetails: {
        isStepper: false,
        eventName: 'notifyParent'
      },
      popupInput: {
        activityData : this.classDetail,
        sessionData : this.classDetail.Sessions?.find(session => session.ActivitySessionId == this.classDetail.SessionId),
        cancellationDate : this.classDetailDate,
        bookings : [...this.attendees , ...this.standbyAttendees]
      }
    };
    const dialogRef = this.dialog.open(AppPopupComponent, {
      disableClose: true,
      width: '550px',
      height: '450px',
      data: {
        title: 'cancelConfirmationMsg',
        update: 'Yes',
        cancel: 'No',
        componentDetails,
        from: ComponentTypes.cancelIndividualSession,
        standalone: true,
        showAlert: true,
     
      }
    });
  }

  showConfirmationWarningPopup(message, cancelAllSessions) {
    let cancelReservation = false;
    const displayOptions: boolean = false;

    const confirmationMessage = message;

    const popUpMessage = [{
      confirmationMessage: confirmationMessage,
      dialogTitle: 'confirm',
      showAlert: true,
      displayOptions: displayOptions
    }];

    const componentDetails: ComponentDetails = {
      componentName: CancelActivityPopupComponent,
      dimensionType: 'small',
      popupType: 'active',
      popUpDetails: {
        isStepper: false,
        eventName: 'notifyParent'
      },
      popupInput: popUpMessage,
      popupTitle: popUpMessage[0].dialogTitle
    };

    const dialogRef = this.dialog.open(CustomPopupComponent, {
      disableClose: true,
      width: '550px',
      height: '350px',
      data: {
        title: 'cancelConfirmationMsg',
        update: 'Proceed',
        cancel: 'Cancel',
        componentDetails,
        from: ComponentTypes.CancelActivityBooking,
        back: false,
        standalone: true,
        showAlert: true
      }
    });
    this.subscriptions.add(dialogRef.afterClosed().subscribe(event => {
      if (this.isBySession() && cancelReservation) {
        //let cancelAllSessions = this.partyService.cancelType === CancelActivityType.AllRemaining;
        const dateTime = moment(Utilities.getRestaurantDateTime(this._settings.General.DaylightDelta)).format("YYYY-MM-DDThh:mm:ss.SSS");
        this.apiService.cancelSessionActivity(dateTime, this.classDetail.SessionId, cancelAllSessions, true).subscribe(response => {
          cancelReservation = false;
          this.onClose();
        })
      } else if (cancelReservation) {
        const dateTime = moment(Utilities.getRestaurantDateTime(this._settings.General.DaylightDelta)).format("YYYY-MM-DDThh:mm:ss.SSS");
        this.apiService.cancelClassActivity(dateTime, this.classDetail.Id, true).subscribe(response => {
          cancelReservation = false;
          this.onClose();
        })
      }
      this.UnSubscribePopUpAction(cancelPopUpConfirmSubscription);
    }));
    let cancelPopUpConfirmSubscription = this.popupService.confirmedAction$.subscribe(val => {
      if (val === ComponentTypes.CancelActivityBooking) {
        cancelReservation = true;
        //this.UnSubscribePopUpAction(cancelPopUpConfirmSubscription);
      }
    });
    let cancelPopUpCloseSubscription = this.popupService.cancelledAction$.subscribe(val => {
      if (val.from === ComponentTypes.CancelActivityBooking) {
        cancelReservation = false;
        //  this.UnSubscribePopUpAction(cancelPopUpCloseSubscription);
      }
    });
  }

  UnSubscribePopUpAction(obj) {
    if (obj) {
      obj.unsubscribe();
    }
  }

  financialDetails(attendee) {
    this.financialPartyData = attendee;
  }

  reservationNotes(attendee) {
    this.notesReservation = this.dashboardFunctions.getReservationNotes(attendee, BookingBehavior.ClassOrSession);
  }

  moveToShopScreen(attendee) {

    this.partyService.currentPartyId = attendee?.Id;
    if (Utilities.isUserHasAccessToRetail(this.appService.restaurantId)) {
      this.retailservice.SetShopItems([], null, null, attendee?.Id, null,null, attendee?.Contact?.Id,null,null,attendee?.Contact?.TrackMembershipNumber);
      this.partyService.bookingDetailViewPopupDialogRef?.close();
    } else {
      this.utilities.showAlert(this.ts.instant('userPermissionMsg'), AlertType.Info, ButtonType.Ok);
    }
  }

  showPartyAuditLog(attendee) {
    const showAlert = false;
    const title = this.ts.instant('auditlog') + " - " + attendee.ConfirmationCode;
    const popUpMessage = [{
      dialogTitle: title, showAlert, type: AuditableEntityType.Party, party: attendee
    }];

    const componentDetails: ComponentDetails = Utilities.setComponentDetails(AuditlogComponent, 'large', 'action', popUpMessage, popUpMessage[0].dialogTitle);
    this.openCustomPopup(componentDetails, ComponentTypes.reservation, popupDialogDimension.createDialogMaxWidth, popupDialogDimension.createDialogMaxHeight, false, title);
  }

  filterReservations(item) {
    item.isSelected = !item.isSelected;
    let selectedOptions = this.displayOptions.filter(x => x.isSelected).map(x => x.id);

    if (selectedOptions && selectedOptions.length == 0) {
      item.isSelected = !item.isSelected;
      return;
    }
    this.filterReservationsBasedOnselection(selectedOptions);
  }


  filterReservationsBasedOnselection(selectedOptions) {
    this.optionsForFilter = [];
    selectedOptions.forEach(option => {
      switch (option) {
        case PartyState.Pending: {
          this.optionsForFilter.push(PartyState.Pending);
          break;
        }
        case PartyState.Seated: {
          this.optionsForFilter.push(PartyState.Seated);
          break;
        }
        case PartyState.Left: {
          this.optionsForFilter.push(PartyState.Left);
          break;
        }
        case PartyState.Cancelled: {
          this.optionsForFilter.push(PartyState.Cancelled);
          break;
        }
        case 5 :{
          this.optionsForFilter.push(5); // Filter added for Exclusive Booking
          break;
        }
      }
    })

  }

  undoCheckIn(attendee){
    this.partyService.reinstateReservation(attendee);
  }

  ngOnDestroy() {
    this.partiessubscription && this.partiessubscription.unsubscribe();
    this.cancelledPartySubscription && this.cancelledPartySubscription.unsubscribe();
    this.standBySubscription && this.standBySubscription.unsubscribe();
    if (this.subscriptions) { this.subscriptions.unsubscribe(); }
  }
}

@Pipe({
  name: 'staffToolTipFormatter'
})
export class StaffNameToolTipFormatter implements PipeTransform {

  transform(data: any): any {
    let staffNames: any = _.cloneDeep(data.map(res => res.fullName));
    let staffName = staffNames.join(", ");
    return staffName;
  }
}


@Pipe({
  name: 'filterByState'
})
export class FilterByState implements PipeTransform {

  transform(data: any, states: PartyState[]): any {
    if(states.includes(5)){ // Filter for Exclusive Booking 
      return data.filter(attendee =>  attendee.IsExclusiveBooking && states.includes(attendee.State));
    }else{
      return data.filter(attendee => states.includes(attendee.State));
    }
   
  }
}

@Pipe({
  name: 'filterByType'
})
export class FilterByType implements PipeTransform {

  transform(data: any, Types: PartyType[]): any {
    return data.filter(attendee => Types.includes(attendee.Type));
  }
}

@Pipe({
  name: 'paymentDetail'
})
export class PaymentDetailPipe implements PipeTransform {

  transform(attendee: any, financialDetails): any {
    return financialDetails(attendee);
  }

}

const MESSAGES = {
  labels: {
    cancelThisActivity: "CancelThisSession",
    unBlock: "Unblock",
    book: "book",
    block: "blockbuttontext",
    ReAssignAsset: "ReAssignAsset",
  }
}
