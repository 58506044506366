import { Component, Host, Inject, OnDestroy, OnInit } from "@angular/core";
import { ComponentTypes, InvoiceLevel, buttonTypes } from "@app/shared/constants/commonenums";
import { AnyMembers, BookingGuestType, EventIntializers, mandatoryCommn, popupDialogDimension, preferences } from "@app/shared/constants/globalConstants";
import { ButtonValue } from "@app/shared/dynamicform/models/field-config.interface";
import { ContactDTO, MemberInfoBase, RequiredPartyFields, SelectionType, SettingsDTO } from "@app/shared/models/RestaurantDTO";
import { GuestBookService } from "@app/shared/services/guestbook.service";
import { PartyService } from "@app/shared/services/party.service";
import { response } from "express";
import { Subscription } from "rxjs";
import { ReservationAddonsService } from "./reservation-addons/reservation-addons.service";
import _, { cloneDeep } from "lodash";
import { COMPONENTINPUT, PopupService } from "@app/popup-module/popup.service";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";
import { TranslateService } from "@ngx-translate/core";
import { CacheService } from "@app/core/services/cache.service";
import { ExceptionPopupComponent } from "@app/shared/components/exception-popup/exception-popup.component";
import { Utilities } from '@utilities/utilities';

import { CustomGuestFields } from "../guest-selection/CustomGuestField";
import moment from "moment";
import { BookingTypeHandler } from "@app/shared/utilities/BookingTypeHandler";

@Component({
    selector: 'reservation-guests',
    templateUrl: './reservation-guests.component.html',
    styleUrls: ['./reservation-guests.component.scss'],
    providers: [ReservationAddonsService]
})
export class ReservationGuestsComponent implements OnInit, OnDestroy {
    subscriptions: Subscription = new Subscription();
    selectedGuests: {
        bookingType: any,
        coverType: any,
        contact: ContactDTO,
        addons: any,
        validAddon: boolean,
        missingCustomFields: string[],
        missingMemberType: string,
        missingMandatoryPreference: string[],
        MemberInfo: MemberInfoBase,
        showAddons: boolean,
        primaryGuest: boolean,
        SearchType: number,
        StayEnddate: string,
        Staystartdate: string;
        Id: number
    }[] = [];
    selectedGuestRowIndex: number = -1;
    addGuestButton: ButtonValue;
    selectGuestButton: ButtonValue;
    changeGuestButton: ButtonValue;
    removeGuestButton: ButtonValue;
    clearGuestButton: ButtonValue;
    createEditGuestSubscription: Subscription;
    showGuestSearch: boolean = true;
    addonValidationsArr = [];
    addonWarningArr = [];
    submitAttempt: boolean = false;
    _settings: SettingsDTO;
    _individualMemberPaymentAllowed: boolean = false;
    _overbookAllowed: boolean = false;
    _additionalData: {
        [key: string]: number
    }
    allowGuestMap: boolean = true;
    allowRemoveGuest: boolean = true;
    allowChangeGuest: boolean = true;

    slotByAllocationType: number[];
    slotByAllocationTypeName: string;
    allocationTypeError: string;
    editGuestIndex: number = -1;
    hasPrimaryGuest: boolean = false;
    _MESSAGES = MESSAGE;
    memberBucketList: any[] = [];
    _individualMemberPayment = false;
    
    GuestReservationRequiredMap = {
        PhoneNumber: [1, 3, 7, 8, 11, 12, 15],
        Email: [2, 3, 9, 10, 11, 12, 13, 15],
        SecondaryPhoneNumber: [4, 6, 7, 9, 11, 13, 14, 15],
        SecondaryEmailAddress: [5, 6, 8, 10, 12, 13, 14, 15]
    }
    lastBookingSize: number = 0;
    primaryGuestErr: string = '';
    intialGuestTypeUpdate: boolean = true;

    constructor(@Inject(MAT_DIALOG_DATA) public dialogData, private partyService: PartyService, private guestBookService: GuestBookService, @Host() private reservationAddonsService: ReservationAddonsService, private popupService: PopupService, private translateService: TranslateService, private cacheService: CacheService, @Inject(COMPONENTINPUT) public data, private bh:BookingTypeHandler) {
        this.subscriptions.add(this.cacheService.settings.subscribe(sett => {
            this._settings = sett;
            this._individualMemberPayment = this._settings.PropertySetting[0].InvoiceLevel === InvoiceLevel.IndividualGuest;
           
        }))
    }

    ngOnInit() {
        this.subscriptions.add(this.partyService.tabChangeEvent$.subscribe(value => {

            let components = [ComponentTypes.EditActivityBooking, ComponentTypes.AddActivityBooking, ComponentTypes.AddOpenBooking, ComponentTypes.EditOpenBooking, ComponentTypes.AddPrivateLessonBooking, ComponentTypes.EditPrivateLessonBooking];
            if (value?.index == 1 && components.includes(value?.from) && this.bookingData) {
                if (this.dialogData.from === ComponentTypes.EditActivityBooking || this.dialogData.from === ComponentTypes.AddActivityBooking) {
                    let allocations = this.bookingData.Slots?.map(slot => slot?.sessions.filter(session => session.Availability.AllocationTypeId).map(session => session.Availability.AllocationTypeId));
                    this.slotByAllocationType = allocations.flat()
                } else if (this.dialogData.from === ComponentTypes.EditOpenBooking || this.dialogData.from === ComponentTypes.AddOpenBooking) {
                    this.slotByAllocationType = this.bookingData.Slots?.map(slot => slot?.AllocationTypeId).filter(AllocationTypeId => AllocationTypeId);
                } else {
                    this.slotByAllocationType = [];
                }
                this.slotByAllocationTypeName = this.getAllocatoinType()

                this.popupService.tabsActionData[1].nextValidations = false;
                this.popupService.tabsActionData[1].gotoNextTab = false;
                let selectedGuestsTemp = this.selectedGuests;
                this.selectedGuests = [];
                this.reservationAddonsService.setAddons(this.bookingData.BookingSize);
                let masterAddons = cloneDeep(this.getAddons());

                if (this.data && this.data.BookingContacts) {
                    if (this.data.Id) {
                        this.allowChangeGuest = this.data.PaidAmount > 0 ? false : true;
                        this.allowGuestMap = this.bookingData.BookingSize > this.data.BookingContacts?.length || this.data.PaidAmount == 0;
                        this.allowRemoveGuest = this.bookingData.BookingSize < this.data.BookingContacts?.length || this.allowChangeGuest;
                    }

                    if ((!this.data.Id && !this.data.CartId) || ((this.data.Id || this.data.CartId) && (this.lastBookingSize != this.bookingData.BookingSize))) {
                        this.lastBookingSize = this.bookingData.BookingSize;

                        selectedGuestsTemp = this.data.BookingContacts.map(bContact => {
                            let addonsQuantity = {}
                            this.data.BookingContactAddonItems?.forEach(bContactAddon => {
                                if ((this.data.Id && bContactAddon.BookingContactId === bContact.Id)
                                    || (!this.data.Id && bContactAddon.BookingContactId === bContact.ContactId)) {
                                    addonsQuantity[bContactAddon.AddonId] = bContactAddon.AddonCount;
                                }
                            });
                            let guestAddons = _.cloneDeep(masterAddons);
                            guestAddons.forEach(mAddon => {
                                mAddon.Quantity = addonsQuantity[mAddon.Id] || 0;
                            });

                            if (this.intialGuestTypeUpdate && bContact.ContactId === this.data?.Contact?.Id && this.primaryGuestTypeRemoved(bContact.BookingTypeId || 0, bContact.CoverTypeId || 0)) {
                                let selectedGuestType = this.bookingData.CoverTypes.find(({ Covers }) => Covers);
                                bContact.BookingTypeId = selectedGuestType?.BookingTypeId || 0;
                                bContact.CoverTypeId = selectedGuestType?.CoverTypeId || 0;

                                this.intialGuestTypeUpdate = false;
                            }

                            return { bookingType: { Id: bContact.BookingTypeId || 0, Name: this.getTypeName("BookingType", (bContact.BookingTypeId || 0)) }, coverType: { Id: bContact.CoverTypeId || 0, Name: this.getTypeName("CoverType", (bContact.CoverTypeId || 0)) }, contact: bContact, addons: guestAddons, validAddon: true, primaryGuest: bContact.ContactId === this.data.Contact.Id };
                        });
                    }

                }

                this._overbookAllowed = !!this.bookingData.OverBooked || this.bookingData.IsOverBook;
                this.bookingData.CoverTypes.forEach((type) => {
                    for (let index = 0; index < type.Covers; index++) {
                        let guestIndexByType = selectedGuestsTemp.findIndex(guest => ((!guest.bookingType.Id && !type.BookingTypeId) || guest.bookingType.Id === type.BookingTypeId) && ((!guest.coverType.Id && !type.CoverTypeId) || guest.coverType.Id === type.CoverTypeId) && guest.contact);
                        let guestDetail;
                        if (guestIndexByType != -1) {
                            guestDetail = selectedGuestsTemp[guestIndexByType];
                            guestDetail.bookingType.Name = type.BookingTypeName;
                            guestDetail.coverType.Name = type.CoverTypeName;
                            selectedGuestsTemp.splice(guestIndexByType, 1);
                        } else {
                            guestDetail = { bookingType: { Id: type.BookingTypeId, Name: type.BookingTypeName }, coverType: { Id: type.CoverTypeId, Name: type.CoverTypeName }, contact: null, addons: [], validAddon: true };
                        }
                        this.selectedGuests.push(guestDetail);
                    }
                })

                this._additionalData = {};

                selectedGuestsTemp.forEach(guestTemp => {
                    if (guestTemp.contact) {
                        this._additionalData[guestTemp.bookingType.Id + "_" + guestTemp.coverType.Id] = (this._additionalData[guestTemp.bookingType.Id + "_" + guestTemp.coverType.Id] || 0) + 1;
                    }
                });

                this.selectedGuests = [...this.selectedGuests, ...selectedGuestsTemp.filter(guest => guest.contact)];
                


                this.selectedGuests.forEach((sGuest, sGuestIndex) => {
                    if (sGuest.contact) {
                        // if (this.validateInvoice(sGuestIndex)) {
                        //     this.bh.validateGuestByBookingType(this.selectedGuests[sGuestIndex], this.bookingData.FromDate, this.bookingData.ToDate, this.partyService.selectedSpecialMealId, false);
                        // }
                        this.guestValidator(this.slotByAllocationTypeName, sGuestIndex);
                    }
                })

                this.guestTypeValidator(this.slotByAllocationTypeName);
                this.validateSelectedGuest()
            }
            if(value.index == 1) {
                if (this.partyService.slotLockId) {
                    this.subscriptions.add(this.partyService.unlockSessions(this.partyService.slotLockId).subscribe((slot) => {
                      this.partyService.slotLockId = null;
                    }));
                  }
            }
            if (components.includes(value?.from)) {
                this.bookingValidation();
            }
 
        }));
        this.popupService.tabsActionData[1].nextValidations = false;
        this.popupService.tabsActionData[1].gotoNextTab = false;

        this.subscriptions.add(this.popupService.warningAction$.subscribe(() => {
            this.popupService.tabsActionData[1].nextValidations = true;
            this.popupService.nextEvent$.next(true);
        }));

        this.addGuestButton = {
            type: buttonTypes.actionPrimary,
            label: 'addGuest'
        }
        this.selectGuestButton = {
            type: buttonTypes.actionPrimarySmall,
            label: 'selectGuest'
        }
        this.changeGuestButton = {
            type: buttonTypes.actionPrimarySmall,
            label: 'changeGuestText'
        }
        this.removeGuestButton = {
            type: buttonTypes.actionSecondaryOrange,
            label: 'Remove'
        }
        this.clearGuestButton = {
            type: buttonTypes.actionSecondaryOrange,
            label: 'Clear'
        }
    }

    validateSelectedGuest(preventWarning?){
        if(this.selectedGuests && this.selectedGuests.length){
            let payload =[];
            this.selectedGuests.forEach(guest => {
                if(guest.contact){
                    guest.Id = guest.contact.ContactId;
                    if(guest.contact.Staystartdate && guest.contact.StayEnddate) {
                        guest.SearchType = guest.contact.SearchType;
                    }
                    else {
                        guest.SearchType = guest.SearchType ? guest.SearchType : guest.MemberInfo?.MemberId ? 4 : 1
                    }
                payload.push(this.bh.formValidationDTO(guest,this.bookingData.FromDate,this.bookingData.ToDate,guest.bookingType?.Id))
            }
            });
            if(!this._individualMemberPayment){
                let contactId = this.selectedGuests.find(data => data.primaryGuest == true)?.Id
                payload = payload.filter(data => data.ContactId == contactId)
            }                
            this.bh.validateBookingTypeForGuest(payload,preventWarning)
            let subscription = this.bh.proceedValidation.subscribe(data =>{
                subscription.unsubscribe();
                if(data.state == 'invalid'){
                  this.popupService.previousEvent$.next(true)
                }
              })
              this.bookingData.AdditionalInfo = this.bh.validationErrors
        }
    }

    primaryGuestTypeRemoved(bookingTypeId, coverTypeId) {
        if (!this.bookingData.CoverTypes.some(type => type.BookingTypeId + "_" + type.CoverTypeId === bookingTypeId + "_" + coverTypeId)) {
            return true;
        }
        return false;
    }

    getStayInfo(event,stayConfirmationNumber){
        this.subscriptions.add(this.guestBookService.getPartyStayInfo(stayConfirmationNumber).subscribe(stayInfo => {
            console.log(stayInfo)
        }));
        event.stopPropagation();
        event.preventDefault();
    }


    bookingValidation() {
        let bookingContacts = this.getBookingContactDetail();
        this.updateFormValidity();
        if (Object.values(this._additionalData)?.length) {
            // this.showErrorNotification([{ msg: this.translateService.instant("InvalidNumberOfGuestSelected") }]);
            return;
        }
        if (((this._individualMemberPaymentAllowed && bookingContacts?.length === this.selectedGuests.length) || (!this._individualMemberPaymentAllowed && bookingContacts.length)) && !this.addonValidationsArr.length) {
            this.bookingData.BookingContacts = bookingContacts;
           
            this.selectedGuestRowIndex = -1;
        }
       this.validateSelectedGuest(true); //prevent throwing warning      
        
    }

    getTypeName(typeCategory: 'BookingType' | 'CoverType', id: number) {
        return id ? this.bookingData.CoverTypes.find(type => type[typeCategory + "Id"] === id)[typeCategory + "Name"] : '';
    }

    allowDrop(ev, guest) {
        if (guest?.contact?.Id && this.data.PaidAmount > 0) {
            // ev.dataTransfer.effectAllowed = "none";
        } else {
            ev.preventDefault();
        }
    }

    onDropGuest(index: number) {
        this.selectedGuestRowIndex = index;
        this.editGuestIndex = -1;
    }

    removeGuest(guest, index: number, event?: Event) {
        this.stopEventBehaviour(event);
        this._additionalData[guest.bookingType.Id + "_" + guest.coverType.Id] = this._additionalData[guest.bookingType.Id + "_" + guest.coverType.Id] - 1;
        if (this._additionalData[guest.bookingType.Id + "_" + guest.coverType.Id] === 0) {
            delete this._additionalData[guest.bookingType.Id + "_" + guest.coverType.Id];
        }
        this.selectedGuests.splice(index, 1);
        if (this.selectedGuests?.length == 1) {
            this.selectedGuests[0].primaryGuest = true;
            this.selectedGuests[0].missingMandatoryPreference = this.getMissingRequiredPartyFields(this._settings.General.RequiredPartyFields, this.selectedGuests[0]);
            this.hasPrimaryGuest = true;
        }
        if (this.data && this.data.BookingContacts) {
            // this.data.BookingContacts.splice(index,1);
        }
        this.addonValidations();
    }

    getAddons() {
        let addons = _.orderBy(Object.values(this.reservationAddonsService.allAddons), 'IsMandatory', 'desc');
        if (addons?.length) {
            return addons.map((addon: any) => {
                let addonMinQuantity = 0;
                if (!this._individualMemberPaymentAllowed && addon.IsMandatory && !this.hasPrimaryGuest) { //&& (addon.SelectionType !== SelectionType.PerLesson || this.isOpenBooking)
                    addonMinQuantity = addon.selectedQuantity ? addon.selectedQuantity : 0;
                }
                return {
                    Id: addon.AddonId,
                    Quantity: addonMinQuantity,
                    Valid: true
                }
            });
        } else {
            return [];
        }

    }

    swapGuest(guest, index) {
        this.selectedGuests[this.selectedGuestRowIndex].contact = guest.contact;
        this.selectedGuests[this.selectedGuestRowIndex].addons = guest.addons;


        if (this._additionalData[guest.bookingType.Id + "_" + guest.coverType.Id]) {
            this.removeGuest(guest, index)
        }
        if (this.selectedGuests?.length == 1 || !this.primaryGuestSelected) {
            this.selectedGuests[this.selectedGuestRowIndex].primaryGuest = true;
            this.selectedGuests[this.selectedGuestRowIndex].missingMandatoryPreference = this.getMissingRequiredPartyFields(this._settings.General.RequiredPartyFields, this.selectedGuests?.length == 1 ? this.selectedGuests[0] : this.selectedGuests[this.selectedGuestRowIndex]);
            this.hasPrimaryGuest = true;
        }

        this.selectedGuestRowIndex = -1;
        this.updateFormValidity();
    }

    get primaryGuestSelected() {
        return this.selectedGuests.some(guest => guest.contact && guest.primaryGuest);
    }

    get bookingData() {
        if (this.dialogData.from === ComponentTypes.EditOpenBooking || this.dialogData.from === ComponentTypes.AddOpenBooking) {
            return this.partyService.openBookingData;
        } else if (this.dialogData.from === ComponentTypes.EditActivityBooking || this.dialogData.from === ComponentTypes.AddActivityBooking) {
            return this.partyService.sessionBookingData;
        } else if (this.dialogData.from === ComponentTypes.EditPrivateLessonBooking || this.dialogData.from === ComponentTypes.AddPrivateLessonBooking) {
            return this.partyService.privateLessonBookingData;
        }
    }

    getBookingContactDetail() {
        let bookingContacts = [];
        this.selectedGuests.forEach(guestObj => {
            if (guestObj.contact && !guestObj.missingCustomFields?.length && !guestObj.missingMemberType) {
                let bookingContact: any = {
                    Id: guestObj.contact.Id,
                    ContactId: guestObj.contact.ContactId,
                    FirstName: guestObj.contact.FirstName,
                    LastName: guestObj.contact.LastName,
                    MemberInfo: guestObj?.contact?.MemberInfo,
                    Staystartdate: guestObj?.Staystartdate,
                    StayEnddate: guestObj?.StayEnddate
                }

                bookingContact = {
                    ...bookingContact,
                    ...guestObj.contact,
                    CoverTypeId: guestObj.coverType.Id || null,
                    BookingTypeId: guestObj.bookingType.Id || null,
                    Addons: guestObj.addons.filter(addon => addon.Quantity).map(addon => {
                        return {
                            AddonId: addon.Id,
                            Quantity: addon.Quantity,
                            OverBooked: addon.OverBooked
                        }
                    })
                }

                if (guestObj.primaryGuest) {
                    bookingContacts.unshift(bookingContact);
                    this.hasPrimaryGuest = true;
                } else {
                    bookingContacts.push(bookingContact);
                }
            }
        })

        return bookingContacts;
    }

    changeGuest(i: number, event?: Event) {
        this.stopEventBehaviour(event);
        this.selectedGuestRowIndex = i;
        this.editGuestIndex = -1;
        this.showGuestSearch = true;
        
        // if (this.validateInvoice(i) && this.selectedGuests?.length > 0) {
        //     this.bh.validateGuestByBookingType(this.selectedGuests[i], this.bookingData.FromDate, this.bookingData.ToDate, this.partyService.selectedSpecialMealId,false)
        // }
    }







    clearGuest(i: number, event?: Event) {
        this.stopEventBehaviour(event);
        this.selectedGuests[i].contact = null;
        this.selectedGuests[i].addons = [];
        this.selectedGuests[i].validAddon = true;
        this.selectedGuests[i].missingCustomFields = [];
        this.selectedGuests[i].missingMemberType = '';
        this.selectedGuests[i].missingMandatoryPreference = [];
        this.addonValidations();
    }

    addGuestClickHandler() {
        this.addEditGuestHandler(null)
    }

    onGuestSelect(guest: ContactDTO) {

        this.selectedGuestRowIndex = (this.selectedGuestRowIndex === -1 && this.selectedGuests.length == 1) ? 0 : this.selectedGuestRowIndex;

        if (this.selectedGuestRowIndex >= 0 && !this.selectedGuests[this.selectedGuestRowIndex].contact) {
            this.selectedGuests[this.selectedGuestRowIndex].addons = cloneDeep(this.getAddons());
            // this.selectedGuests[this.selectedGuestRowIndex].validAddon = this.selectedGuests[this.selectedGuestRowIndex].addons.length ? null : true;
        }
        let guestIndex = this.selectedGuests.findIndex(sGuest => sGuest.contact && sGuest.contact.ContactId === guest.Id);
        let isDuplicateGuest = guestIndex > -1 && guestIndex != this.selectedGuestRowIndex;
        if (isDuplicateGuest) {
            let errObj = { msg: this.translateService.instant("AlreadyAddedGuestAddedText") };

            this.showErrorNotification([errObj]);
            return;
        }
        if (this.selectedGuestRowIndex === -1 || this.selectedGuests[this.selectedGuestRowIndex]?.contact?.ContactId === guest.Id) {
            return;
        }

        if (!this.selectedGuests[this.selectedGuestRowIndex].contact) {
            this.selectedGuests[this.selectedGuestRowIndex].showAddons = true;
        }
        this.selectedGuests[this.selectedGuestRowIndex].contact = guest;

        this.selectedGuests[this.selectedGuestRowIndex].contact.ContactId = this.selectedGuests[this.selectedGuestRowIndex].contact.Id;
        delete this.selectedGuests[this.selectedGuestRowIndex].contact.Id;

        if (!this.hasPrimaryGuest) {
            this.selectedGuests[this.selectedGuestRowIndex].primaryGuest = true;
            this.hasPrimaryGuest = true;
        }

        this.guestValidator(this.slotByAllocationTypeName, this.selectedGuestRowIndex);
        this.guestTypeValidator(this.slotByAllocationTypeName);

        this.selectedGuestRowIndex = -1;
        this.addonValidations();

    }


    getAllocatoinType(): string {
        let allAllocationTypes = this._settings.AllocationType.filter(type => this.slotByAllocationType.includes(type.Id)).map(allocationtype => allocationtype['AllocationPropertyMappings'][0]?.AllocationTypeName);
        if (allAllocationTypes.length) {
            let uniqTypes = _.uniq(allAllocationTypes);
            return uniqTypes.length > 1 ? uniqTypes.filter(uType => uType != AnyMembers)[0] : uniqTypes[0];
        }
        return '';
    }

    getMissingMemberType(guest, selectedMemberType) {
        if (selectedMemberType === AnyMembers) {
            return guest.contact?.MemberInfo?.RateType ? '' : 'AnyMember';
        } else {
            return guest.contact?.MemberInfo?.RateType !== selectedMemberType ? selectedMemberType : '';
        }
    }

    guestValidator(selectedMemberType: string, guestIndex: number) {


        this.selectedGuests[guestIndex].missingCustomFields = [];
        let requiredFields = this.requiredCustomGuestFields;
        let missingFieldIds = {}

        this.selectedGuests[guestIndex].missingMemberType = this.getMissingMemberType(this.selectedGuests[guestIndex], selectedMemberType)

        Object.keys(requiredFields).forEach(requiredFieldId => {
            let hasValue = this.selectedGuests[guestIndex].contact.ContactCustomFields?.find(contactCustomField => contactCustomField.CustomFieldId === +requiredFieldId && contactCustomField.CustomFieldValue) || null;
            if (!hasValue) {
                missingFieldIds[requiredFieldId] = true;
            }
        });
        this.selectedGuests[guestIndex].missingMandatoryPreference = this.getMissingRequiredPartyFields(this.selectedGuests[guestIndex].primaryGuest ? this._settings.General.RequiredPartyFields : this._settings.General.RequiredSecondaryGuestFields, this.selectedGuests[guestIndex]);

        if (this.selectedGuests[guestIndex].contact.CustomGuestField) {
            this.selectedGuests[guestIndex].contact.CustomGuestField.forEach(customField => {
                if (missingFieldIds[customField.Id]) {
                    this.selectedGuests[guestIndex].missingCustomFields.push(customField.FieldName);
                }
            });
        }
        this.updateFormValidity();

    }


    getMissingRequiredPartyFields(requiredFields, guest) {

        let mandatoryCommnArray = [];

        if (this.GuestReservationRequiredMap.PhoneNumber.includes(requiredFields)) {
            if (!guest.contact.PhoneNumber) {
                mandatoryCommnArray.push(mandatoryCommn.phone);
            }
        }

        if (this.GuestReservationRequiredMap.Email.includes(requiredFields)) {
            if (!guest.contact.EmailAddress) {
                mandatoryCommnArray.push(mandatoryCommn.email);
            }
        }

        if (this.GuestReservationRequiredMap.SecondaryPhoneNumber.includes(requiredFields)) {
            if (!guest.contact.PhoneNumber2) {
                mandatoryCommnArray.push(mandatoryCommn.secondaryPhone);
            }
        }

        if (this.GuestReservationRequiredMap.SecondaryEmailAddress.includes(requiredFields)) {
            if (!guest.contact.EmailAddress2) {
                mandatoryCommnArray.push(mandatoryCommn.secondaryEmail);
            }
        }

        if (RequiredPartyFields.Anyone == requiredFields) {
            if (guest.contact.PhoneNumber || guest.contact.PhoneNumber2 || guest.contact.EmailAddress || guest.contact.EmailAddress2) {
                return;
            }
            else {
                mandatoryCommnArray.push(mandatoryCommn.anyOne)
            }
        }

        return mandatoryCommnArray;
    }



    guestTypeValidator(selectedMemberType) {
        this.allocationTypeError = '';

        if (selectedMemberType) {
            let err = this.translateService.instant("MemberTypeRequiredText", { type: this.slotByAllocationTypeName });
            if (this._individualMemberPaymentAllowed) {
                this.allocationTypeError = this.selectedGuests.every(mGuest => !this.getMissingMemberType(mGuest, selectedMemberType)) ? '' : err;
            } else {
                this.allocationTypeError = this.selectedGuests.some(mGuest => !this.getMissingMemberType(mGuest, selectedMemberType)) ? '' : err;
            }
        }
        this.updateFormValidity();
    }

    editGuest(contact, index, event?: Event) {
        this.stopEventBehaviour(event);
        this.editGuestIndex = index;
        // this.selectedGuestRowIndex = index;
        let _contactObj = _.cloneDeep(contact);
        _contactObj.Id = _contactObj.ContactId;
        delete _contactObj.ContactId;
        this.addEditGuestHandler(_contactObj)
    }

    showGuestError(guest) {
        let errors = [];

        if (!guest.contact) {
            errors.push({ msg: this.translateService.instant("selectGuest") })
        } else {
            // if (!guest.validAddon) {
            //     errors.push({ msg: this.translateService.instant("InvalidAddonQuantitiesSelectedErrorText") })
            // }
            if (guest.missingCustomFields?.length) {
                errors.push({ msg: this.translateService.instant("MissingCustomFieldsErrorText") + guest.missingCustomFields.join(", ") })
            }

            if (guest.missingMemberType) {
                errors.push({ msg: this.translateService.instant("MemberTypeRequiredText", { type: guest.missingMemberType }) })
            }
            guest.missingMandatoryPreference?.forEach(preference => {
                if (preference == mandatoryCommn.phone) {
                    errors.push({ msg: this.translateService.instant("MissingPhoneNumber", { type: preference }) })
                }
                if (preference == mandatoryCommn.email) {
                    errors.push({ msg: this.translateService.instant("MissingEmailAddress", { type: preference }) })
                }
                if (preference == mandatoryCommn.secondaryPhone) {
                    errors.push({ msg: this.translateService.instant("missingAlternateNumber", { type: preference }) })
                }
                if (preference == mandatoryCommn.secondaryEmail) {
                    errors.push({ msg: this.translateService.instant("missingSecondaryEmail", { type: preference }) })
                }
                if (preference == mandatoryCommn.anyOne) {
                    errors.push({ msg: this.translateService.instant("anyOneBasicContactDetails", { type: preference }) })
                }
            })

        }
        this.showErrorNotification(errors);
    }

    showErrorNotification(errors) {
        const ErrorMessages = [{
            ErrorDetails: {
                ErrorMessage: "",
                Error: errors
            },
            dialogTitle: "Error",
            ConfirmationPopup: true,
            ErrorType: true
        }];
        if (ErrorMessages) {
            const componentInfo = Utilities.setComponentDetails(ExceptionPopupComponent, 'small', 'action', ErrorMessages,
                ErrorMessages[0].dialogTitle);
            const dialogRef = this.partyService.openCustomPopup(componentInfo, ComponentTypes.resultprocess,
                popupDialogDimension.actionDialogWidth, popupDialogDimension.actionDialogHeight, false, '', '', '', false);
            let confirmationSubscription = this.popupService.confirmedAction$.subscribe(() => {
                dialogRef.close();
            });
            this.subscriptions.add(dialogRef.afterClosed().subscribe((dialogCloseData) => {
                if (confirmationSubscription) { confirmationSubscription.unsubscribe(); }
            }));
        }
    }

    guestAddonChanged(guest) {
        // if(!this._individualMemberPaymentAllowed){
        //     let noOfAddonsSelected = guest.addons.reduce((total, currAddon) => total + currAddon.Quantity, 0);
        //     guest.primaryGuest = !!noOfAddonsSelected;
        //     this.hasPrimaryGuest = guest.primaryGuest;
        // }
        this.addonValidations();
    }

    addonValidations() {
        // Per Reservation Validations
        let addonValidationsArr = [];
        let addonWarningArr = [];
        let categoryMandatoryAddOns = {};
        this.reservationAddonsService.allEligibleAddOns.forEach(addon => {

            let totalQuantitiy = 0;
            this.selectedGuests.forEach(({ addons: guestAddons }) => {
                if (guestAddons?.length) {
                    totalQuantitiy += +guestAddons.find(guestAddon => guestAddon.Id === addon.AddonId).Quantity;
                }
            })

            if (addon.IsMandatory && totalQuantitiy < addon.MinQuantity) {
                addonValidationsArr.push(this.translateService.instant('ReservationMinimumAddonText', { addonName: addon.AddonName, minQuantity: addon.MinQuantity }))
            } else if (totalQuantitiy > addon.MaxQuantity && !addon.IsUnlimited) {
                addonValidationsArr.push(this.translateService.instant('ReservationExceedAddonText', { addonName: addon.AddonName, addonQuantity: addon.MaxQuantity }))
            } else if (this._overbookAllowed && totalQuantitiy > addon.OverAllQuantity && !addon.IsUnlimited) {
                addonValidationsArr.push(this.translateService.instant('ReservationExceedAddonText', { addonName: addon.AddonName, addonQuantity: addon.OverAllQuantity }) + this.translateService.instant('ExceedAddonQuantity', { quantity: totalQuantitiy - addon.AvailableQuantity }))
            } else if (!this._overbookAllowed && totalQuantitiy > addon.AvailableQuantity && !addon.IsUnlimited) {
                if (addon.AvailableQuantity === 0) {
                    addonValidationsArr.push(addon.AddonName + ' ' + this.translateService.instant('unavailableText'));
                } else {
                    addonValidationsArr.push(this.translateService.instant('ReservationExceedAddonText', { addonName: addon.AddonName, addonQuantity: addon.AvailableQuantity }) + this.translateService.instant('ExceedAddonQuantity', { quantity: totalQuantitiy - addon.AvailableQuantity }))
                }
            }

            if (this._overbookAllowed && totalQuantitiy > addon.AvailableQuantity && totalQuantitiy <= addon.MaxQuantity) {
                addonWarningArr.push(this.translateService.instant('ReservationOverbookAddonText', { addonName: addon.AddonName, addonQuantity: totalQuantitiy - addon.AvailableQuantity }))
            }
            if (addon.IsCategoryLevelMandatory) {
                if (categoryMandatoryAddOns[addon.CategoryId]) {
                    categoryMandatoryAddOns[addon.CategoryId] += totalQuantitiy;
                }
                else {
                    categoryMandatoryAddOns[addon.CategoryId] = totalQuantitiy;
                }
            }
        });

        Object.entries(categoryMandatoryAddOns)?.forEach(([categoryId, selectedValue]) => {
            if (categoryMandatoryAddOns[categoryId] <= 0) {
                let categoryName = this.cacheService.settings.value.Categories.find(category => category.Id == Number(categoryId))?.Text;
                addonWarningArr.push(this.translateService.instant('minCategoryAddonMessage', { name: categoryName }));
            }
        })

        this.addonValidationsArr = addonValidationsArr;
        this.addonWarningArr = addonWarningArr;
        this.updateFormValidity();
    }

    async changePrimaryGuest(guestDetail) {
        let guest = this.selectedGuests.find(guest => guest.primaryGuest == true)
        let payload = this.bh.formValidationDTO(guest, this.bookingData.FromDate, this.bookingData.ToDate, guestDetail?.bookingType.Id)
        this.bh.validateBookingTypeForGuest([payload])
        let subscriptions = this.bh.proceedValidation.subscribe(data => {
            subscriptions.unsubscribe();
            if(data.state == 'valid'){
        this.selectedGuests.forEach((guest, index) => {

            if (guest.primaryGuest && !this._individualMemberPaymentAllowed) {
                guestDetail.addons = _.cloneDeep(guest.addons);
                guest.addons.forEach(addon => addon.Quantity = 0);
            }

            if (guest.primaryGuest) {
                guest.missingMandatoryPreference = this.getMissingRequiredPartyFields(this._settings.General.RequiredSecondaryGuestFields, guest);
            }

            guest.primaryGuest = false;

        })
        guestDetail.primaryGuest = true;
        guestDetail.missingMandatoryPreference = this.getMissingRequiredPartyFields(this._settings.General.RequiredPartyFields, guestDetail);
        this.hasPrimaryGuest = true;
        this.updateFormValidity();
    }
    })

    }
    

    validateInvoice(guest){
        
       return this._individualMemberPayment || (!this._individualMemberPayment && guest?.primaryGuest)
    }

    addEditGuestHandler(contact) {
        if (this.createEditGuestSubscription) {
            this.createEditGuestSubscription.unsubscribe();
        }
        this.guestBookService.createTabsModal(true, true, contact ? true : false, contact);
        this.guestBookService.openDialogAdd(contact ? this.translateService.instant('editGuest') : this.translateService.instant('popUpTitleaddnewguest'), ComponentTypes.guestBook, { requiredCustomGuestFields: this.requiredCustomGuestFields }, EventIntializers.CREATEGUESTFORRESERVATION);
        this.createEditGuestSubscription = this.guestBookService.createEditGuestSubscription$.subscribe((response) => {
            let contactId;
            if (response.Payload) {
                contactId = response.Payload.ContactId || response.Payload.Id;
            } else if (response.Change?.ChangeSet?.AdditionalData[0]?.Id) {
                contactId = response.Change?.ChangeSet?.AdditionalData[0]?.Id;
            }
            if (this.selectedGuestRowIndex === -1 && this.editGuestIndex > -1) {
                this.selectedGuestRowIndex = this.editGuestIndex;
                this.editGuestIndex = -1;
            } else if (this.selectedGuestRowIndex === -1 && this.selectedGuests.length === 1) {
                this.selectedGuestRowIndex = 0;
            }
            this.createEditGuestSubscription.unsubscribe();
            this.guestBookService.getContact(contactId).subscribe(({ Payload: contact }) => {

                // if(this.selectedGuests[this.selectedGuestRowIndex].contact){
                //     this.selectedGuests[this.selectedGuestRowIndex].addons = cloneDeep(this.getAddons());
                // }
                if (!this.selectedGuests[this.selectedGuestRowIndex].contact) {
                    this.selectedGuests[this.selectedGuestRowIndex].addons = cloneDeep(this.getAddons());
                }
                contact.ContactId = contact.Id;
                contact.Id = this.selectedGuests[this.selectedGuestRowIndex]?.contact?.Id || null;
                this.selectedGuests[this.selectedGuestRowIndex].contact = contact;
                if (!this.hasPrimaryGuest) {
                    this.selectedGuests[this.selectedGuestRowIndex].primaryGuest = true;
                    this.hasPrimaryGuest = true;
                }
                this.guestValidator(this.slotByAllocationTypeName, this.selectedGuestRowIndex);
                this.guestTypeValidator(this.slotByAllocationTypeName);
                this.selectedGuestRowIndex = -1;

            })
        })
    }

    get requiredCustomGuestFields(): { [key: number]: boolean } {
        let requiredFields = {};
        let activitiesCustomGuestFields = this._settings.customGuestFieldsMappingValidations;
        let selectedActivityId = this.partyService.selectedSpecialMealId ? this.partyService.selectedSpecialMealId : -1;
        let CustomFields = activitiesCustomGuestFields.find(activity => activity.ActivityId == selectedActivityId)?.CustomFields

        CustomFields?.length && CustomFields.forEach(customField => {
            if (customField.Validations?.length) {
                requiredFields[customField.FieldId] = true;
            }
        })

        return requiredFields;
    }

    getBucketList(guestDetails, event) {
        event.stopPropagation();
        event.preventDefault();
        this.memberBucketList = [];
        this.memberBucketList = guestDetails?.contact?.MemberInfo?.Bucket;
    }

    updateFormValidity() {
        this.primaryGuestErr = '';
        if (this.selectedGuests?.length > 1 && this.selectedGuests?.filter(guest => guest.contact)?.length >= 1 && this.selectedGuests?.filter(guest => guest.contact && guest.primaryGuest)?.length == 0) {
            this.hasPrimaryGuest = false;
            this.primaryGuestErr = this.translateService.instant('primaryGuestError');
        }
        let isValidBookingSizeSelected = this._individualMemberPaymentAllowed ? this.selectedGuests?.filter(guests => guests.contact)?.length == this.partyService.bookingSize : this.selectedGuests?.filter(guests => guests.contact)?.length;
        let isFormValid = this.selectedGuests?.length <= this.partyService.bookingSize && isValidBookingSizeSelected && this.selectedGuests?.filter(guests => { return (guests.missingMandatoryPreference && guests.missingMandatoryPreference?.length > 0) || (guests.missingCustomFields && guests.missingCustomFields?.length > 0) || (guests.missingMemberType && guests.missingMemberType != '') })?.length == 0 && (this.allocationTypeError == '') && this.addonValidationsArr.length == 0 && this.hasPrimaryGuest && this.primaryGuestErr == '';
        this.popupService.tabsActionData[1].gotoNextTab = isFormValid;
        this.popupService.tabsActions$.next(this.popupService.tabsActionData);
    }

    stopEventBehaviour(event) {
        if (event) {
            event.preventDefault();
            event.stopPropagation();
        }
    }

    ngOnDestroy() {
        this.createEditGuestSubscription && this.createEditGuestSubscription.unsubscribe();
        if (this.subscriptions) {
            this.subscriptions.unsubscribe();
        }
    }
}


const MESSAGE = {
    label: {
        availableMinutes: 'availableRoundOrMinutesOrPoints',
    }
}