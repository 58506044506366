import { Injectable } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { CacheService } from '@app/core/services/cache.service';
import { PopupService } from '@app/popup-module/popup.service';
import { GetReservationsOperationOptions } from '@constants/commonenums';
import { urlConfig } from '@constants/url-config';
import { FloorPlanImageInputDto, InputImages } from '@models/FloorPlanImageInputDto';
import { FloorPlanDTO, SettingsDTO, StandaloneTableDTO } from '@models/RestaurantDTO';
import { TimeRangeDTO } from '@models/TimeRangeDTO';
import { HttpService } from '@services/http.service';
import { Utilities } from '@utilities/utilities';
import { endOfDay, startOfDay } from 'date-fns';
import { BehaviorSubject, Subject } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class FloorplanService {
  floorPlan: FloorPlanDTO;
  public tableSettingsForm: UntypedFormGroup;
  joinedTablesForm: UntypedFormGroup;
  newFloorForm: UntypedFormGroup;
  selectedType: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  joinTable = false;
  joinedTables: any = [];
  selectedFloorId: any;
  selectedModelPlan: any;
  newFloorPlanName: any;
  objectDeleted$: Subject<any> = new Subject<any>();
  deleteJoinedTable$: Subject<any> = new Subject<any>();
  editJoinedTable$: Subject<any> = new Subject<any>();
  removeTable$: Subject<any> = new Subject<any>();
  assignTable$: Subject<any> = new Subject<any>();
  dataChanged$: Subject<any> = new Subject<any>();
  selectedFloor$: Subject<any> = new Subject<any>();
  addNewLayout$: Subject<any> = new Subject<any>();
  onSelectionCleared: Subject<any> = new Subject<any>();
  joinedToCommunalError$: Subject<any> = new Subject<any>();
  tableSelected$: Subject<any> = new Subject<any>();
  layoutChange$: Subject<any> = new Subject<any>();
  deleteSelectedFloorPlanTables$: Subject<any> = new Subject<any>();
  timerange: TimeRangeDTO = {} as TimeRangeDTO;
  throwCommunalTableError$: Subject<any> = new Subject<any>();
  fromEdit = false;
  _settings: SettingsDTO = {} as SettingsDTO;
  selectedTabIndex$: Subject<any> = new Subject<any>();
  selectedTableBeforeAction$ = new BehaviorSubject<StandaloneTableDTO>(null);
  selectAllObjects$ = new BehaviorSubject<boolean>(false);
  labelSettingsForm: UntypedFormGroup;
  labelSelected$: Subject<any> = new Subject<any>();
  setFloorGridLines$: Subject<any> = new Subject<any>();
  gridSliderValue$: Subject<any> = new Subject<any>();
  drawLabel$: Subject<any> = new Subject<any>();
  isNewlyaddedLayout = false;
  nearestObject = false;
  toolIndex = -1;
  selectedSeatingTypeId: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  imageUploadCompletion$: Subject<any> = new Subject<any>();
  imagesCollection = [];
  applySelectedImage$: Subject<any> = new Subject<any>();
  selectedImageIds: string[] = [];
  selectedObjectImageIds: string[] = [];
  equal = true;
  discardChanges$: Subject<any> = new Subject<any>();
  applyNewFloorChanges$: Subject<any> = new Subject<any>();
  customObjectImage: InputImages = null;
  customObjectImageActionTriggered: boolean = false ;
  constructor(public httpService: HttpService, public popupService: PopupService,
    public formBuilder: UntypedFormBuilder, public cs: CacheService) {
    this.newFloorForm = this.formBuilder.group({
      basedOn: [''],
      floorPlan: [''],
    });
    this.joinedTablesForm = this.formBuilder.group({

    })
    this.tableSettingsForm = this.formBuilder.group({

    })
    this.labelSettingsForm = this.formBuilder.group({

    })
  }

  calculatePartySize(maxPartySizeAllowed) {
    const partySizeArray = [];
    for (let i = 1; i <= maxPartySizeAllowed; i++) {
      partySizeArray.push({ id: i, value: i.toString() });
    }
    //partySizeArray[maxPartySizeAllowed - 1].value = partySizeArray[maxPartySizeAllowed - 1].value + '+';
    return partySizeArray;
  }

  updateFloor(tables, ignoreSeatingParties?) {
    return this.httpService.post(`${urlConfig.updateFloorPlan}?restaurantId=${Utilities.RestaurantId()}&ignoreSeatingParties=${ignoreSeatingParties}`, tables);
  }

  createFloor(tables) {
    return this.httpService.post(`${urlConfig.createFloorPlan}?restaurantId=${Utilities.RestaurantId()}`, tables);
  }

  completeAllVisits(seatedParties) {
    let seatedIds = ''
    seatedParties.forEach((element, index) => {
      seatedIds = seatedIds + element.Id.toString();
      if (index != (seatedParties.length - 1)) {
        seatedIds = seatedIds + ','
      }
    });
    return this.httpService.post(`${urlConfig.CompleteAllVisits}?restaurantId=${Utilities.RestaurantId()}&onlyPartyIds=${seatedIds}`);
  }

  preRemoveFloorPlan(tables) {
    return this.httpService.get(`${urlConfig.preRemoveFloorPlan}?restaurantId=${Utilities.RestaurantId()}&floorPlanId=${tables.Id}`)
  }

  removeFloorPlan(tables) {
    return this.httpService.post(`${urlConfig.removeFloorPlan}?restaurantId=${Utilities.RestaurantId()}&floorPlanId=${tables.Id}`);
  }

  getReservations(startDate, endDate) {
    // const date = this.appService._headerDate;
    this.timerange.Start = startOfDay(startDate);
    this.timerange.End = endOfDay(endDate);
    this.timerange.Start = new Date(this.timerange.Start.getTime() - (this.timerange.Start.getTimezoneOffset() * 60000));
    this.timerange.End = new Date(this.timerange.End.getTime() - (this.timerange.End.getTimezoneOffset() * 60000));
    return this.httpService.post(`${urlConfig.loadReservationsURL}?restaurantId=${Utilities.RestaurantId()}&options=${GetReservationsOperationOptions.Full}`,
      { Start: this.timerange.Start.toJSON(), End: this.timerange.End.toJSON() }).pipe(map(data => data));
  }

  uploadLayoutImages(images) {
    const floorPlanImageInput: FloorPlanImageInputDto = {
      data: images
    };
    this.httpService.post(`${urlConfig.uploadLayoutImagesURL}?restaurantId=${Utilities.RestaurantId()}`,
      floorPlanImageInput).subscribe((data) => { });
  }

  deleteLayoutImages(imageIds: string[]) {
    return this.httpService.post(`${urlConfig.deleteLayoutImagesURL}?restaurantId=${Utilities.RestaurantId()}`, imageIds);
  }

  uploadObjectImages(images) {
    const floorPlanImageInput: FloorPlanImageInputDto = {
      data: images
    };
    this.httpService.post(`${urlConfig.uploadObjectImagesURL}?restaurantId=${Utilities.RestaurantId()}`,
      floorPlanImageInput).subscribe((data) => { });
  }

  deleteObjectImages(imageIds: string[]) {
    return this.httpService.post(`${urlConfig.deleteObjectImagesURL}?restaurantId=${Utilities.RestaurantId()}`, imageIds);
  }

  uploadCustomImages(images) {
    const floorPlanImageInput: FloorPlanImageInputDto = {
      data: images
    };
    this.httpService.post(`${urlConfig.uploadCustomImagesURL}?restaurantId=${Utilities.RestaurantId()}`,
      floorPlanImageInput).subscribe((data) => { });
  }

  deleteCustomImages(imageIds: string[]) {
    return this.httpService.post(`${urlConfig.deleteCustomImagesURL}?restaurantId=${Utilities.RestaurantId()}`, imageIds);
  }

  checkLayoutMappings(): boolean {
    var floorPlans = this.cs.layout.value.FloorPlans;
    var mapped = false;
    floorPlans.forEach(floorPlan => {
      if (this.selectedImageIds.includes(floorPlan.ImageId)) {
        mapped = true;
      }
    })
    return mapped;
  }

  checkObjectMappings(): boolean {
    let floorPlans = this.cs.layout.value.FloorPlans;
    let mapped = false;
    floorPlans.forEach(floorPlan => {
      floorPlan.StandaloneTables.forEach(object => {
        if (this.selectedObjectImageIds.includes(object.ImageId)) {
          mapped = true;
        }
      })
    });
    return mapped;
  }

  checkCustomImageMappings(): boolean {
    let floorPlans = this.cs.layout.value.FloorPlans;
    let mapped = false;
    floorPlans.forEach(floorPlan => {
      floorPlan.StandaloneTables.forEach(object => {
        if (this.selectedImageIds.includes(object.ImageId)) {
          mapped = true;
        }
      })
    });
    return mapped;
  }
}
