<div *ngIf="!data.openBookingSlot" class="session-card" [class.dragged-session]="moveDetail"
  [style.backgroundColor]="(data.CategoryId | activityColor)?.backgroundColor" [ngClass]="state"
  [style.transform]="moveDetail && styleObject.transform" [hidden]="moveDetail && dragHandlerService.scrollSubscriptionData" [title]="(state == 'staff-offshift' ? 'offShift' : '') | translate"
 >
 <!--  -->
  <!-- [category-indicator]="data.CategoryId" indicatorType="border" -->
  <div *ngIf="moveDetail" class="session-card__move-time-badge"[ngClass]="{'session-card__move-time-badge--start' : !switchAxis, 'session-card__move-time-badge--top': switchAxis}">
    {{moveDetail.StartTime}}
  </div>
  <div *ngIf="moveDetail" class="session-card__move-time-badge" [ngClass]="{'session-card__move-time-badge--end': !switchAxis, 'session-card__move-time-badge--bottom': switchAxis}">
    {{moveDetail.EndTime}}
  </div>
  <div class="session-card__header">
    <h6 class="session-card__header-title">
      <span *ngIf="data.staffScheduleType == StaffScheduleType.BreakOrBlock" class="icon-exclamation"><span class="path1"></span><span class="path2"></span><span class="path3"></span></span>
      <span *ngIf="data.staffScheduleType != StaffScheduleType.OffShift">{{ data.SlotHeader[selectedViewId]}}</span>
    </h6>
    <!-- <h6>{{headLineData.isSessionCancelled}} {{headLineData.cancelBy}} {{headLineData.cancelReason}} </h6> -->
    <div class="session-card__header-actions" [ngClass]="{'staff-blockhour__actions': data.staffScheduleType}">
      <div class="lock-icon">
        <span *ngIf="serviceData?.addonblocked" class="icon-No-sahre2"><span class="path1"></span><span
            class="path2"></span><span class="path3"></span></span>
      </div>
      <span class="icon-booking-cancel-icon" *ngIf="data.StatusCode == StatusMaster.Cancelled" [matTooltip]="data?.cancelledBy" ><span
        class="path1"></span><span class="path2"></span></span>
      <span *ngIf="headLineData?.activityCustomisation == 'Unblock'" [matTooltip]="headLineData?.blockedBy" 
      class="icon-block_1"></span>
      <span *ngIf="data.staffScheduleType" class="icon-Block" matTooltip="{{'blockOrBreakHours' | translate}}"></span>
      <div class="block-popover" *ngIf="!data.isPrivateLessonBooking && !data.isOpenBooking">
        <span [ngbPopover]="blockPopover" (click)="sessionStatus($event)" container="body"
          placement="bottom auto" [autoClose]="'outside'" #ps="ngbPopover" class="icon-legends icon-settings"></span>
        <ng-template #blockPopover>
          <div class="parties-grid__scrollSize">
            <div class="reservation-notes">
              <div class="reservation-notes__header seat-body-text seat-popover-header">
                {{'settingsMenuText' | translate}}
              </div>
              <ng-container *ngIf="!data.staffScheduleType; else staffBlockMenu">
                <div class="reservation-notes__content seat-popover-content">
                  <div class="reservation-notes__notes-details seat-border-color"
                    (click)="promptBlockSession($event,ps)">
                    <span class="seat-grid-header pointer" [class.disabled] = "!(RolesAndPermissionsType.ManageActivityBlocks | rolesAndPermission)">{{headLineData?.activityCustomisation}}
                      {{'SessionPluralText' | translate}}</span>
  
                  </div>
                </div>
                <div class="reservation-notes__content seat-popover-content">
                  <div class="reservation-notes__notes-details seat-border-color"
                    (click)="modifySession($event,ps);">
                    <span class="seat-grid-header pointer"  [class.disabled] = "cs.isIframeEnabled">
                      {{'ModifySession' | translate}}</span>
                  </div>
                </div>
                <div class="reservation-notes__content seat-popover-content">
                  <div class="reservation-notes__notes-details seat-border-color"
                    (click)="cancelSession($event,ps);">
                    <span class="seat-grid-header pointer"  [class.disabled] = "cs.isIframeEnabled">
                      {{'cancelSession' | translate}}</span>
                  </div>
                </div>
              </ng-container>
              <ng-template #staffBlockMenu>
                <div class="reservation-notes__content seat-popover-content">
                  <div class="reservation-notes__notes-details seat-border-color"
                    (click)="createStaffBlock($event,ps)">
                    <span class="seat-grid-header pointer" [class.disabled] = "cs.isIframeEnabled">
                      {{'Edit Block/Break Hours' | translate}}</span>
  
                  </div>
                </div>
                <div class="reservation-notes__content seat-popover-content">
                  <div class="reservation-notes__notes-details seat-border-color"
                    (click)="deleteBlockStaff($event,ps);">
                    <span class="seat-grid-header pointer"  [class.disabled] = "cs.isIframeEnabled">
                      {{'Delete Block/Break Hours' | translate}}</span>
                  </div>
                </div>
              </ng-template>
            </div>
          </div>
        </ng-template>
      </div>
      <ng-template #staffBlock>
        <div class="block-popover"><span (click)="sessionStatus($event)" class="icon-legends icon-settings"></span></div>
      </ng-template>
    </div>
  </div>

  <div class="session-card__subheader" *ngIf="(rows<2 || switchAxis || expandMode)" [title]="data.SlotSubHeader[selectedViewId]">
    <span *ngIf="selectedViewId!==2 && !data.isOpenBooking && !data.isPrivateLessonBooking && !data.staffScheduleType" class="icon icon-placeholder" ></span>
    {{data.SlotSubHeader[selectedViewId]}}
  </div>

  <div class="session-card__head-line" *ngIf="(rows===1|| switchAxis || expandMode) && !data.staffScheduleType">
    <span class="note"><i class="icon-public" matTooltip="{{'PublicQuickNote' | translate}}"
        *ngIf="headLineData?.SlotPublicHeadline"></i><span
        matTooltip="{{headLineData?.SlotPublicHeadline}}">{{headLineData?.SlotPublicHeadline}}</span></span>
    <span class="note"><i class="icon-public_off" matTooltip="{{'PrivateQuickNote' | translate}}"
        *ngIf="headLineData?.SlotPrivateHeadline"></i><span
        matTooltip="{{headLineData?.SlotPrivateHeadline}}">{{headLineData?.SlotPrivateHeadline}}</span></span>
  </div>

  <div class="session-card__footer" *ngIf="(rows<3|| switchAxis || expandMode) && !data.staffScheduleType">
    <ng-container *ngIf="!data.isOpenBooking && !data.isPrivateLessonBooking">
      <div class="session-card__footer__left">
        <label for="" *ngIf="(rows===1|| switchAxis || expandMode ) && data.StatusCode !== StatusMaster.Cancelled">{{partyService.TimeLineAvailablityShowType == TimeLineAvailablityShowTypeEnum.AvailableCapacityByTotalCapacity ? ('Available' | translate) : ('Booked' | translate)}}<span
            *ngIf="(serviceData?.Available == 0 && data.MaxActivityStrength && data.AllowStandBy)"> - <span
              >({{'standByAvailability' | translate}})</span></span></label>
        <div class="session-card__highlight-1" [ngClass]="{'cancel-details' :data.StatusCode == StatusMaster.Cancelled}">
            <span  *ngIf="data.StatusCode !== StatusMaster.Cancelled" matTooltip="{{partyService.TimeLineAvailablityShowType == TimeLineAvailablityShowTypeEnum.AvailableCapacityByTotalCapacity  ? ('AvailableToBook' | translate) : ('Booked' | translate)}}"
              >
              {{serviceData.MaxActivityStrength - overBooked < 0 ? 0 : serviceData | sessionAvailability: 'numerator'}}</span>
                <span class="text-sub" *ngIf="data.StatusCode !== StatusMaster.Cancelled"  matTooltip="{{partyService.TimeLineAvailablityShowType == TimeLineAvailablityShowTypeEnum.BookedCapacityByAvailableCapacity  ? ('AvailableToBook' | translate) : ('TotalCapacity' | translate)}}" >
                  {{partyService.TimeLineAvailablityShowType == TimeLineAvailablityShowTypeEnum.BookedCapacityByAvailableCapacity ? ' -- ' : '/' }} 
                  {{ serviceData | sessionAvailability: 'denominator'  }}
                </span>
          <span class="overbooked-session-text" [matTooltip] ="('OverbookedBy' | translate) + ' ' + overBooked" *ngIf="overBooked">({{'OverbookedBy' | translate}} {{overBooked}})</span>
          <span class="cancel-session-text cancel-completed" *ngIf="data.StatusCode == StatusMaster.Cancelled && serviceData?.Cancelled">{{'cancelledBookings' | translate}}   {{ serviceData?.Cancelled}}  </span>
          <span class="cancel-session-text cancel-pending" [matTooltip] ="('TobeCancelMessage' | translate)" *ngIf="data.StatusCode == StatusMaster.Cancelled && serviceData?.bookings">{{'TobeCancel' | translate}}  {{ serviceData?.bookings}} </span>
          <!-- <span class="cancel-session-text no-booking" *ngIf="data.StatusCode == StatusMaster.Cancelled && serviceData?.totalBookings == 0">{{'noBookings' | translate}}  </span> -->
        </div>
      </div>
    </ng-container>
    <ng-container *ngIf="data.isOpenBooking || data.isPrivateLessonBooking">
      <div class="session-card__footer__left">
        <label class="up-txt" for="" *ngIf="rows===1 || expandMode">{{'duration' | translate}}</label>
        <div class="session-card__highlight-1">
          {{data.Duration}} {{'mins' | translate}}
          <span class="overbooked-session-text" [matTooltip] ="('OverbookedBy' | translate) + ' ' + data?.OverBooked" *ngIf="data.OverBooked && data.OverBooked> 0">({{'OverbookedBy' | translate}} {{data.OverBooked}})</span>
        </div>
      </div>
    </ng-container>
    <div class="session-card__footer__right">
      <i *ngIf="data.isPrivateLessonBooking" class="icon-private_class_booking_icon private-lesson-icon"
        style="font-size: 22px;"></i>
      <i *ngIf="!data.isOpenBooking && !data.isPrivateLessonBooking && serviceData?.isVIP" class="icon-VIP"
        [ngClass]="{'VIP-selected':serviceData?.isVIP}"></i>
      <i *ngIf="(data.isOpenBooking || data.isPrivateLessonBooking) && data.Contact?.IsVip" class="icon-VIP"
        [ngClass]="{'VIP-selected':data.Contact?.IsVip}"></i>
    </div>
  </div>
  <!-- <span *ngIf="!data.isOpenBooking && !data.isPrivateLessonBooking"
      class="session-card-small__seat-count session-card-small__seat-count--1">
      <ng-container *ngIf="!data.SessionGroupId && partyService?.allWeekActivitySessions[data.SessionId]; else group_availability">

        {{partyService.allWeekActivitySessions[data.SessionId].Available > 0  ? partyService.allWeekActivitySessions[data.SessionId].Available : 0}} <span class="text-sub">
          /
          {{partyService.allWeekActivitySessions[data.SessionId].Available + partyService.allWeekActivitySessions[data.SessionId].Booked}}
          </span>
      </ng-container>
      <ng-template #group_availability>
        <div *ngIf="data.SessionGroupId && partyService.allWeekActivitySessions['group_'+data.SessionGroupId];else availability_detail" class="session-card-small__seat-count session-card-small__seat-count--1" [style.color]="partyService.allWeekActivitySessions['group_'+data.SessionGroupId].Available == 0 && '#e81212'">
          {{partyService.allWeekActivitySessions['group_'+data.SessionGroupId]?.Available > 0 ? partyService.allWeekActivitySessions['group_'+data.SessionGroupId]?.Available : 0}} <span class="text-sub">
            /
            {{(partyService.allWeekActivitySessions['group_'+data.SessionGroupId]?.Available) + (partyService.allWeekActivitySessions['group_'+data.SessionGroupId]?.Booked)}}
          </span>
          
        </div>
      </ng-template>
      <ng-template #availability_detail>

        {{data.Available}} <span class="text-sub">
          /
          {{data.Available + data.Booked}}
          </span>
      </ng-template>
    </span> -->
  <!-- <span *ngIf="data.isOpenBooking || data.isPrivateLessonBooking"
      class="session-card-small__seat-count session-card-small__seat-count--1">
      {{data.Duration}} {{'mins' | translate}}
    </span> -->

    <div *ngIf="(data.isOpenBooking || data.ActivitySessionId || (data.isPrivateLessonBooking && !data.ReservedFor)) && (RolesAndPermissionsType.ModifyActivity | rolesAndPermission) && !cs.isIframeEnabled && data.StatusCode !== StatusMaster.Cancelled && !data.staffScheduleType" class="drag-handler" cdkDrag [cdkDragLockAxis]="dragHandlerService.lockAxis" [cdkDragFreeDragPosition]="dragHandlerPosition"
    (cdkDragStarted)="dragStartHandler($event)" (cdkDragMoved)="dragMoveHandler($event)" (cdkDragReleased)="dropHandler($event)">
    
  </div>

</div>

<div *ngIf="data.openBookingSlot" class="{{'height_'+session.rows}} add-slot" [class.fullHeight]="expandMode"
  [style.gridColumnStart]="data.gridColumnStart - session.gridColumnStart + 1"
  [style.gridColumnEnd]="data.gridColumnEnd - session.gridColumnStart + 1">
  <div class="addLesson add-lesson" *ngIf="!data.bufferSlot; else bufferSlot" [class.p0]="switchAxis"
    (click)="addBooking($event)" [style.gridColumnStart]="session.gridColumnStart"
    [style.gridColumnEnd]="session.gridColumnEnd">
    <div class="staffBlock-popover" *ngIf="selectedViewId == 0">
      <span (click)="sessionStatus($event);createStaffBlock($event)" class="icon-legends icon-settings"></span>
    </div>
    <span class="add-lesson__create-icon" *ngIf="selectedViewId != 1">+</span>
  </div>
  <ng-template #bufferSlot>
    <div class="addLesson add-lesson empty" *ngIf="data.bufferSlot"
      [matTooltip]="'bufferTimeInfoText' | translate:{bufferTime: rowData.BufferTimeInMinutes}">
    </div>
  </ng-template>
</div>