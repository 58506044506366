import { AfterViewInit, Component, Inject, OnDestroy, OnInit, QueryList, ViewChild, ViewChildren, ViewEncapsulation } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ApiService } from '@app/activities-timeline/services/api.service';
import { AppService } from '@app/app.service';
import { IFormValidDetails } from '@app/settings/models/common.interface';
import { PartyState, Status, ClassType, LessonType, ComponentTypes, SlottingMode, buttonTypes, BookingBehavior, PartyType } from '@app/shared/constants/commonenums';
import { OpenBookingDTO, PrivateLessonBookingDTO } from '@app/shared/models/OpenBookingDTO';
import { CacheService } from '@core/services/cache.service';
import { DynamicFormComponent } from '@dynamicform/dynamic-form/dynamic-form.component';
import { ButtonValue, FieldConfig } from '@dynamicform/models/field-config.interface';
import { AllocationTypeDTO, FloorPlanDTO, LayoutDTO, SettingsDTO } from '@models/RestaurantDTO';
import { TranslateService } from '@ngx-translate/core';
import { COMPONENTINPUT, PopupService } from '@popup-module/popup.service';
import { PartyService } from '@services/party.service';
import { LayoutFunctions } from '@utilities/layout-functions';
import { Utilities } from '@utilities/utilities';
import moment from 'moment';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { Subscription } from 'rxjs/Subscription';
import _ from 'lodash';
import { ConfirmationPopupComponent } from '@app/shared/components/confirmation-popup/confirmation-popup.component';
import { ComponentDetails } from '@app/popup-module/models/popup.interface';
import { CustomPopupComponent } from '@app/popup-module/components/custom-popup/custom-popup.component';
import { I } from '@angular/cdk/keycodes';
import { AddOnForBookingComponent } from '@app/shared/components/add-on-for-booking/add-on-for-booking.component';
import { DashboardFunctions } from '@app/shared/utilities/dashboard-functions';
import { SettingsService } from '@app/shared/services/settings.service';
import { HostDTO } from '@app/shared/models/HostDTO';
import { EngageMemberDetailDTO, EngageProfile } from '@app/shared/models/EngageMemberDetailDTO';
import { EngageMemberByCardIdRequestDTO } from '@app/shared/models/EngageMemberByCardIdRequestDTO';
import { ExcludeLocationGroupPipe } from '@app/shared/pipes/ExcludeLocationGroup.pipe';
import { ToastrService } from 'ngx-toastr';


@Component({
  selector: 'privatelesson-booking-form',
  templateUrl: './privatelesson-booking-form.component.html',
  styleUrls: ['./privatelesson-booking-form.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class PrivateLessonBookingFormComponent implements OnInit, AfterViewInit, OnDestroy {
  invalidDate: boolean = false;
  ActivityList: any;
  lessonConfig: FieldConfig[];
  bookingFromDateConfig: FieldConfig[];
  locationConfig: FieldConfig[];
  instructorConfig: FieldConfig[];
  bookingSizeArray: any = [];
  selectedBookingSize: number;
  maxSizeAllowed: number = 60;
  minSizeAllowed: number = 1;
  locationList: any = [];
  instructorList: any = [];
  floorPlan: FloorPlanDTO[] = [];
  _settings: SettingsDTO;
  _layout: LayoutDTO = {} as LayoutDTO;
  subscriptions: Subscription = new Subscription();
  displaySlots: boolean = false;
  coverTypes = [];
  bookingTypes = [];
  hasMembershipValue: boolean = false;
  timeSlotUnitInMinutes: number = 30;
  validCoverTypes: boolean = true;
  allocationCodes: AllocationTypeDTO[];
  validBookingTypes: boolean = true;
  memberShipdetail: any = {};
  MemberDetailsArray: any = [];
  memberShipArr: any = [];
  selectedActivity: any;
  detailComponetData: any;
  showCoverTypes: boolean = false;
  showBookingTypes: boolean = false;
  isMultipleSlotSelectionEnabled: any;
  lessons = [];
  validGroupSize: boolean = true;
  GrpSizeErrorMsg = '';
  validMinCoverType: boolean = true;
  minimumQuantityErrorMsg: string = '';
  editCheckedInReservation: boolean = false;
  memberSearchButton: ButtonValue;
  @ViewChildren('form') components: QueryList<DynamicFormComponent>;
  weeklyViewDate: any;
  showSalesContact: boolean = false;
  salesContactConfig: FieldConfig[];
  @ViewChild('salesContactForm') salesContactForm: DynamicFormComponent;
  bookingTypeRequiredMsg: string = '';
  coverTypeRequiredMsg: string = '';
  intialLoad: boolean = true;
  selectedMemberDetail = null;
  sizeMapping: any;
  isValidConfig: boolean = true;
  constructor(private cs: CacheService, @Inject(COMPONENTINPUT) public data, private dialog: MatDialog, private apiService: ApiService, private toastrService: ToastrService,
    public partyService: PartyService, private fb: UntypedFormBuilder, private ps: PopupService, private ts: TranslateService, private lf: LayoutFunctions,
    public appService: AppService, private df: DashboardFunctions, private settingsService: SettingsService , public excludeLocationGroupPipe : ExcludeLocationGroupPipe) {
    if (this.data) {
      if (this.data.Size) {
        //  this.maxSizeAllowed = this.data.Size < 60 ? 60 : this.data.Size;
        //    this.maxSizeAllowed = this.ActivityList.find(activity => activity.Id == this.data.SpecialMealId)?.MaxPartySize;
      }
      this.isMultipleSlotSelectionEnabled = this.data?.Slots || false;
      if (this.data.Slots) {
        this.data.StaffIds = this.data.StaffId;
      }

    }

    this.subscriptions.add(this.cs.settings.subscribe(sett => {
      this._settings = sett;
      this.ActivityList = this._settings.SpecialMeals.filter(s => s.LessonType == LessonType.PrivateLesson);
      this.ActivityList = this.ActivityList.filter(s => s.StatusCode == Status.Approved);
      this.ActivityList = _.orderBy(this.ActivityList, ['Name'], ['asc']);
      this.timeSlotUnitInMinutes = this._settings.General.TimeSlotUnitInMinutes;
      this.coverTypes = [...this._settings.CoverTypes];
      this.bookingTypes = [...this._settings.BookingTypes];

      if (this.data) {
        if (this.data && this.data.Size) {
          //  this.maxSizeAllowed = this.data.Size < 60 ? 60 : this.data.Size;
          this.maxSizeAllowed = this.ActivityList.find(activity => activity.Id == this.data.SpecialMealId)?.MaxPartySize;
          this.minSizeAllowed = this.ActivityList.find(activity => activity.Id == this.data.SpecialMealId)?.MinPartySize;
          this.showSalesContact = this.ActivityList.find(activity => activity.Id == this.data.SpecialMealId)?.TrackingofSalesPerson || false;
        }
      }

    }));
    this.generatePartySizeArray();
    this.subscriptions.add(cs.layout.subscribe(layt => {
      this._layout = layt;
    }));
    this.loadLocations();
    this.partyService.BookingBehaviour = BookingBehavior.PrivateLesson;
  }

  ngOnInit() {
    this.partyService.privateLessonBookingDataSaveObj = null;

    this.weeklyViewDate = this.data?.weeklyViewDate;
    if (this.data && this.data.fromDetailComponent) {
      this.detailComponetData = { ...this.data };
      this.data = null;
    }
    this.partyService.customFieldValidation = [];
    this.partyService.isSlotLocked = false;
    let restaurantDate = Utilities.getRestaurantDateTime(this._settings.General.DaylightDelta);
    this.lessonConfig = [
      {
        type: 'autocomplete',
        name: 'class',
        options: this.lessons,
        value: this.data ? this.data.SpecialMealId : 0,
        class: 'field-95',
        showErrorText: true,
        isTranslate: false,
        validation: [Validators.required],
        disabled: (this.data?.isConfirmedReservation) || (this.data && !this.data.instructorView) ? true : false,
        autoCompleteWithId: true,
        icon: 'icon-search',
        icon1: 'icon-Group-591',
        returnFunction: this.clearFormField.bind(this, 'class'),
      }
    ];
    this.bookingFromDateConfig = [
      {
        type: 'date',
        name: 'fromDate',
        inputType: 'text',
        class: 'field-95',
        value: this.getFromDate(restaurantDate),
        appearance: false,
        validation: [Validators.required],
        minDate: this.df.getMinDateBasedonConfig(this._settings.SpecialMeals.find(meal => meal.Id == this.partyService.selectedSpecialMealId), restaurantDate),
        maxDate: this.data?.State == PartyState.Pending ? this.data.SpecialMeal.EndDate: null,
        disabled: (this.data?.isConfirmedReservation) || (this.editCheckedInReservation && this.data && this.data.State == PartyState.Seated) ? true : false
      }];
    this.locationConfig = [{
      type: 'autocomplete',
      name: 'location',
      options: this.locationList,
      value: (this.data && this.data.TableIds) ? this.locationList.filter(l => l.id == this.data.TableIds[0])[0].id : (this.data && this.data.addPrivateLessonBooking) ? this.locationList[0].id : this.locationList[0].id,
      class: 'field-95',
      showErrorText: true,
      isTranslate: false,
      validation: [Validators.required],
      autoCompleteWithId: true,
      icon: 'icon-search',
      icon1: 'icon-Group-591',
      returnFunction: this.clearFormField.bind(this, 'location'),
      disabled: (this.data?.isConfirmedReservation) || (this.editCheckedInReservation && this.data && this.data.State == PartyState.Seated) ? true : false
    }];
    this.instructorConfig = [{
      type: 'autocomplete',
      name: 'instructor',
      options: this.instructorList,
      value: (this.data && this.data.StaffIds) ? this.instructorList.filter(l => l.id == this.data.StaffIds[0])[0].id : (this.data && this.data.addPrivateLessonBooking) ? this.instructorList[0].id : this.instructorList[0].id,
      class: 'field-95',
      showErrorText: true,
      isTranslate: false,
      validation: [Validators.required],
      autoCompleteWithId: true,
      icon: 'icon-search',
      icon1: 'icon-Group-591',
      returnFunction: this.clearFormField.bind(this, 'instructor'),
      disabled: (this.data?.isConfirmedReservation) || (this.editCheckedInReservation && this.data && this.data.State == PartyState.Seated) ? true : false
    }];
    this.salesContactConfig = [{
      type: 'autocomplete',
      name: 'salescontacts',
      options: [],
      class: 'activity-booking__seating-type',
      showErrorText: true,
      isTranslate: false,
      autoCompleteWithId: true,
      icon: 'icon-search',
      icon1: 'icon-Group-591',
      isChipsEnabled: true,
      multiChipValues: [],
      returnFunction: this.clearFormField.bind(this, 'salescontact'),
    }];
    this.memberSearchButton = {
      type: buttonTypes.actionPrimarySmall,
      label: 'searchText',
      disbaledproperity: false,
      customclass: 'action-bar__add-class-btn'
    }
    if (!this.data || (this.data && !this.data.addPrivateLessonBooking)) {
      //Set Intial Value
    }
    this.partyService.bookingSize = (this.data && this.data.Size) ? this.data.Size : 1;
    //this.bookingSizeConfig[0].options = this.calculatePartySize();
   
    var date = (this.data && this.data.ReservedFor) ? this.data.ReservedFor : this.appService._headerDate > restaurantDate ? this.appService._headerDate : restaurantDate
    date = new Date(date);
    date.setHours(0, 0, 0, 0)
    this.getLessonsConfig(date);
    if (this.detailComponetData && this.detailComponetData.lessonId) {
      this.lessonConfig[0].value = this.detailComponetData.lessonId;
      if (!this.data) {
        this.selectedActivity = this.ActivityList.find(activity => activity.Id == this.detailComponetData.lessonId);
        this.partyService.selectedSpecialMealId = this.selectedActivity.Id;
        this.resetDropdownvalues();
      }
    }
    if (this.data?.instructorView) {
      this.selectedActivity = this.ActivityList.find(activity => activity.Id == this.data.SpecialMealId);
      this.partyService.selectedSpecialMealId = this.selectedActivity.Id;
    }
    if (this.data) {
      this.partyService.selectedSpecialMealId = this.data.SpecialMealId;
      this.getCoverTypesForSelectedActivity(this.data.SpecialMealId);
      this.getBookingTypesForSelectedActivity(this.data.SpecialMealId);
     
      // this.partyService.addOrRemoveAddOn(this.data.SpecialMealId);
 
    }
    this.subscriptions.add(this.partyService.tabChangeEvent$.subscribe(value => {
      let getAddOns = Utilities.getAddons(this.cs.settings.value.Addons, this.partyService.selectedSpecialMealId);
      let components =  [ComponentTypes.EditActivityBooking , ComponentTypes.AddActivityBooking , ComponentTypes.AddOpenBooking , ComponentTypes.EditOpenBooking , ComponentTypes.AddPrivateLessonBooking , ComponentTypes.EditPrivateLessonBooking];
      if (value?.index === 1 && components.includes(value?.from)) {
        this.setBookingData();
        if (this.partyService.selectedGuest && this.partyService.selectedGuest.MembershipType) {
          setTimeout(() => {
            this.updateFormValidity();
          }, 100)
        } else {
          this.updateFormValidity();
        }
      } else if (value?.index === 0) {
        this.updateFormValidity();
        this.ps.previousButtonEnabled$.next(true);
      }
      if(value?.index != 0)
      this.ps.previousButtonEnabled$.next(false);
    }));
    this.partyService.isEditCart = this.data ? this.data.isCartEdit : false;
    if (this.data && !this.data.addOpenBooking && (this.partyService.selectedParty$.value?.Id || this.data.isCartEdit)) {
      this.partyService.isEditData = true;
    }
    if (this.detailComponetData) {
      setTimeout(() => {
        this.setBookData();
      }, 100)
    }
    this.editCheckedInReservation = this.df.checkEditCheckedInReservationsConfig();
  }


  setBookData() {
    let { fromDetailComponent, lessonId, locationId, instructorId } = this.detailComponetData;
    var [, activity, location, instructor, , ,] = this.components;
    if (fromDetailComponent) {
      if (lessonId) {
        activity.form.controls.class.setValue(lessonId);
        this.detailComponetData.lessonId = null;
        this.detailComponetData.fromDetailComponent = false;
      }
    }
  }
  updateCoverType(value) {
    if (value > this.maxSizeAllowed) {
      this.maxSizeAllowed = value;
    } else {
      this.maxSizeAllowed = 60;
    }
    this.generatePartySizeArray();
  }
  getCoverTypeConfig(): FieldConfig[] {
    return this.coverTypes.map(coverType => {
      return {
        type: 'autocomplete',
        label: coverType.Name,
        name: coverType.Name,
        value: (this.data && this.data.CoverTypeQuantities && this.data.CoverTypeQuantities.filter(ct => ct.CoverTypeId == coverType.Id && ct.Covers > 0).length > 0) ? this.bookingSizeArray[this.data.CoverTypeQuantities.filter(ct => ct.CoverTypeId == coverType.Id)[0].Covers - 1].id : 0,
        options: [{ id: 0, value: this.ts.instant("0") }, ...this.bookingSizeArray],
        class: 'privatelesson-booking__cover-type-item',
        showErrorText: true,
        isTranslate: false,
        validation: [Validators.required],
        toolTipEnabled: true,
        inputType: 'number'
      } as FieldConfig;
    })
  }
  getBookingTypeConfig(): FieldConfig[] {
    return this.bookingTypes.map(bookingType => {
      return {
        type: 'autocomplete',
        label: bookingType.Name,
        name: bookingType.Name,
        value: (this.data && this.data.BookingTypeQuantities && this.data.BookingTypeQuantities.filter(ct => ct.BookingTypeId == bookingType.Id && ct.BookingTypes > 0).length > 0) ? this.bookingSizeArray[this.data.BookingTypeQuantities.filter(ct => ct.BookingTypeId == bookingType.Id)[0].BookingTypes - 1].id : 0,
        options: [{ id: 0, value: this.ts.instant("0") }, ...this.bookingSizeArray],
        class: 'privatelesson-booking__cover-type-item',
        showErrorText: true,
        isTranslate: false,
        validation: [Validators.required],
        toolTipEnabled: true,
        inputType: 'number'
      } as FieldConfig;
    })
  }

  loadLocations() {
    this.floorPlan = this._layout.FloorPlans;
    let standAloneTables = this.floorPlan.filter(x => x.IsDefault == true)[0].StandaloneTables;
    standAloneTables = _.orderBy(standAloneTables, ['Name'], ['asc'])
    standAloneTables = this.excludeLocationGroupPipe.transform(standAloneTables);
    standAloneTables.forEach(table => {
      if (table.IsAvailableForReservations && !table.IsTemplate) {
        this.locationList.push({ id: table.Id, value: table.Name });
      }
    });

    this._settings.Servers.forEach(server => {
      if (!server.IsTemplate)
        this.instructorList.push({ id: server.Id, value: server.Name + (server.LastName ? ' ' + server.LastName : '') });
    })
    // Instructors ordered by alphaNumeric
    const sortedArray = (servers) => {
      const sortByValue = (a, b) => a.value.localeCompare(b.value, 'en', { numeric: true });
      return servers.sort(sortByValue);
    };
    this.instructorList = sortedArray(this.instructorList);
  }

  ngAfterViewInit() {
    this.formChangeSubscribe();
    if (this.data && (this.data.ReservedFor || this.data.addPrivateLessonBooking || this.data.isCartEdit || this.data.instructorView)) {
      this.getSlotsByLocation();
      this.resetDropdownvalues();
      var activitySelected = this.ActivityList.find(activity => activity.Id == this.data.SpecialMealId);
      if (activitySelected && activitySelected.CustomFieldValidation) {
        this.partyService.customFieldValidation = activitySelected.CustomFieldValidation
      }
    } else {
      if (!this.data && !this.detailComponetData) {
        var [, activity] = this.components;
        activity.form.controls.class.setValue(this.getLessonList() ? this.setDefaultLessonbasedOnAvaiality() : '');
      }
    }
    if (this.showSalesContact) {
      this.salesContactUsers();
    }
    if (this.data && this.data.Contact) {
      this.partyService.selectedGuest = this.data.Contact;
    }
    if (this.data && this.selectedActivity && this.selectedActivity.Id && !this.data.addPrivateLessonBooking) {
      this.getCoverTypesForSelectedActivity(this.selectedActivity.Id);
      this.getBookingTypesForSelectedActivity(this.selectedActivity.Id);
    }
    this.updateFormValidity();
  }
  updateTabsNavigation() {
    let isFormValid = this.isFormValid() && this.groupSizeValidation() && this.isValidDuration()
    if (this.data && this.data.isConfirmedReservation && this.data.State !== PartyState.Seated && this.data.State !== PartyState.Pending && isFormValid) {
      setTimeout(() => {
        this.ps.formValid$.next(
          {
            isFormValid: true,
            currentTab: 0,
            gotoNextTab: true,
            tabsToNavigateCount: 1,
            disablePrevious: true
          } as IFormValidDetails
        );
      }, 500);
    }
  }
  onSlotUpdate(slot) {
    // let slotsToCheck =[];
    // for (var key in this.partyService.selectedOpenBookingSlots) {
    //   if (this.partyService.selectedOpenBookingSlots[key] == true) {
    //     slotsToCheck.push(this.partyService.slots_holder.find(x => x.Id == key));
    //   }
    // }
    this.partyService.selectedSlot$.next([slot]);
    if(this.data && this.data.WishedTime && slot.slotData){
      this.partyService.selectedSlot$.next([slot.slotData]);
    }
    this.updateFormValidity();
  }
  setDefaultLessonbasedOnAvaiality() {
    let activitySession = this.ActivityList.filter(x => x.StatusCode === Status.Approved && this.isDateAppicable(x.StartDate, x.EndDate));
    if (activitySession && activitySession.length > 0) {
      let availableSessions = activitySession.filter((session) => (new Date(session.StartDate) <= this.appService.headerDate$.value) && new Date(session.EndDate) >= this.appService.headerDate$.value)
      return availableSessions && availableSessions.length > 0 ? availableSessions[0].Id : activitySession[0].Id;
    }
  }

  getLessonList() {
    return this.ActivityList.filter(x => x.StatusCode === Status.Approved && this.isDateAppicable(x.StartDate, x.EndDate)).map(activity => {
      return {
        id: activity.Id,
        value: activity.Name
      }
    });
  }

  isDateAppicable(startdate, endDate): boolean {
    return Utilities.datetimeBetween(startdate, endDate, new Date(new Date(Utilities.getRestaurantDateTime(this._settings.General.DaylightDelta)).setHours(0, 0, 0, 0)))
      || !this.isPastDate(startdate)
  }
  getLessonTypeList() {
    return [
      { id: ClassType.Session, value: this.ts.instant('By Session') },
      { id: ClassType.Class, value: this.ts.instant('By Class') },
      { id: ClassType.Duration, value: this.ts.instant('By Duration') }
    ]
  }

  isPastDate(date): boolean { // need to get the current restaurant date time
    return moment(date).diff(moment(Utilities.getRestaurantDateTime(this._settings.General.DaylightDelta)), 'days') < 0
  }
  getSlotsByLocation() {
    var [fromDate, activity, location, instructor] = this.components;
    this.selectedActivity = this._settings.SpecialMeals.find(s => s.Id === activity.form.get("class").value);
    var date = moment(fromDate.form.get("fromDate").value).format("YYYY-MM-DD");
    if (instructor.form.get("instructor").value > 0 && activity.form.get("class").value > 0) {
      this.partyService.selectedOpenBookingSlots = {};
      this.displaySlots = false;
      this.subscriptions.add(this.partyService.getSlotsForTable([location.form.get("location").value], [instructor.form.get("instructor").value], activity.form.get("class").value, (this.data && this.data.Id) ? this.data.Id : null, date).subscribe(response => {
        this.setSlots(response);
        setTimeout(() => {
          this.partyService.slotAvailabilitySubject$.next(response);
          this.partyService.partySlots$.next(true);
        }, 100);

      }));
    }
    else {
      this.displaySlots = false;
      this.allocationCodes = [];
      this.partyService.slots_holder = [];
    }
  }

  getLessonsConfig(date: any) {

    if (date) {
      this.lessonConfig[0].options = [];
      this.ActivityList = this._settings.SpecialMeals.filter(s => s.LessonType == LessonType.PrivateLesson && new Date(s.EndDate) >= date && new Date(s.StartDate) <= date);
      this.ActivityList = _.orderBy(this.ActivityList, ['Name'], ['asc']);
      this.ActivityList.map(activity => {
        if (activity.StatusCode == Status.Approved) {
          this.lessonConfig[0].options.push({
            id: activity.Id,
            value: activity.Name
          });
        }
      })
      if (this.components) {
        var [, activity, , ,] = this.components;
        this.lessonConfig[0].options.length > 0 ? activity.form.controls.class.setValue(this.lessonConfig[0].options[0].id, { emitEvent: false }) : activity.form.controls.class.setValue(0, { emitEvent: false });
      }
      this.selectedActivityFieldChange(false);
    }

  }

  setSlots(response, partySize?) {
    this.allocationCodes = [];
    this.partyService.slots_holder = [];
    let classid = this.components.filter(x => {
      let vals = x.form.get("class");
      if (vals)
        return true;
    })[0].form.get("class").value;
    let overAllAddons = Utilities.getAddons(this.cs.settings.value.Addons, classid);
    let Addons = Utilities.getMandatoryAddons(this.cs.settings.value.Addons, classid);
    response.Payload.forEach(element => {
      element.OpenHours.forEach(openHour => {
        var tableId = openHour.SlotsByTableId[0].TableId;
        openHour.SlotsByTableId[0].Slots.sort((a, b) => Utilities.parseDateString(a.DateTime).getTime() - Utilities.parseDateString(b.DateTime).getTime());
        openHour.SlotsByTableId[0].Slots.forEach(slot => {
          if (!slot.PartyId || (this.data && slot.PartyId && this.data.Id == slot.PartyId)) {
            if (slot.AllocationTypeId && this._settings.AllocationType.find(type => type.Id === slot.AllocationTypeId)['AllocationPropertyMappings'][0]) {
              var { Color: { R, G, B, A }, AllocationTypeName } = this._settings.AllocationType.find(type => type.Id === slot.AllocationTypeId)['AllocationPropertyMappings'][0];
              slot['color'] = `rgba(${R}, ${G}, ${B}, ${A})`;
              let allocationDetail = {} as AllocationTypeDTO;
              allocationDetail.AllocationName = AllocationTypeName;
              allocationDetail.color = slot['color'];
              this.allocationCodes.push(allocationDetail);
              let isAnyMember = Utilities.IsMember(slot);
              slot.AllocationName = isAnyMember;
            }
            this.partyService.SlotIds += 1;
            slot.Id = slot.Id == 0 ? this.partyService.SlotIds : slot.Id;
            if (Utilities.filterSlotsbyAddon(this.cs.settings.value.Addons, overAllAddons, Addons, slot.AddOnAvailability, this.getCoverTypesize(), classid)) {
              if (this.df.checkBookPastDaysReservationsConfig() || (this.df.checkEditCheckedInReservationsConfig() && this.partyService.selectedParty$.value?.State == PartyState.Seated)) {
                this.partyService.slots_holder.push(slot);
              }
              else {
                if (this.isNotPastTimeSlot(slot)) {
                  this.partyService.slots_holder.push(slot);
                }
              }
            }
            let selectedSlot = null;
            if(this.data && this.data.WishedTime && this.data.WishedTime == slot.DateTime){
              slot.PartyId = this.data.Id
            }         
            if (this.data?.Slots) {
              selectedSlot = this.data.Slots.find(x => x.Time === slot.DateTime);
            }
            if (this.data && slot.PartyId && this.data.TableIds[0] == tableId && this.data.Id == slot.PartyId) {
              this.partyService.reservationFormGroup.value.selectedTime = slot.DateTime;
              this.partyService.selectedOpenBookingSlots[slot.Id] = !this.partyService.selectedOpenBookingSlots[slot.Id];
              if (!this.data.Duration) {
                this.data.Duration = Utilities.diffBetweenDatesInMinutes(this.data.DepartureTime, this.data.SeatingTime);
              }
              if (this.data.Duration > this._settings.General.TimeSlotUnitInMinutes && (!this.data || (this.data && this.data.Type != PartyType.StandBy))) {

                var duration = this.data.Duration - this._settings.General.TimeSlotUnitInMinutes;
                for (var i = 1; i <= duration / this._settings.General.TimeSlotUnitInMinutes; i++) {
                  var newSlot = this.partyService.generateSlot(Utilities.Date(slot.DateTime).add(i * this._settings.General.TimeSlotUnitInMinutes, 'minutes'));

                  newSlot.PartyId = slot.PartyId;
                  if (newSlot.Id == 0) {
                    this.partyService.SlotIds += 1;
                    newSlot.Id = this.partyService.SlotIds;
                  }
                  this.partyService.slots_holder.push(newSlot);
                  this.partyService.selectedOpenBookingSlots[newSlot.Id] = true;
                }
                this.setBookingData();
                this.onSlotUpdate(slot);
              }
              this.updateFormValidity();
              this.updateTabsNavigation();
            } else if (selectedSlot) {
              this.partyService.selectedOpenBookingSlots[slot.Id] = !this.partyService.selectedOpenBookingSlots[slot.Id];             
              if (selectedSlot.Duration > this._settings.General.TimeSlotUnitInMinutes && (!this.data || (this.data && this.data.Type != PartyType.StandBy))) {
                var duration = selectedSlot.Duration - this._settings.General.TimeSlotUnitInMinutes;
                for (var i = 1; i <= duration / this._settings.General.TimeSlotUnitInMinutes; i++) {
                  var newSlot = this.partyService.generateSlot(Utilities.Date(slot.DateTime).add(i * this._settings.General.TimeSlotUnitInMinutes, 'minutes'));
                  newSlot.PartyId = slot.PartyId;
                  if (newSlot.Id == 0) {
                    this.partyService.SlotIds += 1;
                    newSlot.Id = this.partyService.SlotIds;
                  }
                  this.partyService.slots_holder.push(newSlot);
                  this.partyService.selectedOpenBookingSlots[newSlot.Id] = true;
                  this.onSlotUpdate(slot);
                }
              }
              this.updateFormValidity();
              this.updateTabsNavigation();
            }
          }
        });
        var openhrSlots = openHour.SlotsByTableId[0].Slots;
        openhrSlots.forEach(slot => {
          if (this.data?.Slots) {
            var selectedSlot = this.data.Slots.find(x => x.Time === slot.DateTime);
            if (selectedSlot && selectedSlot.DurationInMinutes > this._settings.General.TimeSlotUnitInMinutes) {
              var duration = selectedSlot.DurationInMinutes - this._settings.General.TimeSlotUnitInMinutes;
              for (var i = 1; i <= duration / this._settings.General.TimeSlotUnitInMinutes; i++) {
                var existingslot = openhrSlots.find(x => Utilities.Date(x.DateTime).isSame(Utilities.Date(slot.DateTime).add(i * this._settings.General.TimeSlotUnitInMinutes, 'minutes')));
                if (existingslot) {
                  this.partyService.selectedOpenBookingSlots[existingslot.Id] = true;
                  this.onSlotUpdate(slot);
                }
              }
            }
          }

        });
      });
    });

    this.allocationCodes = _.uniqBy(this.allocationCodes, 'AllocationName');
    if (this.data && (this.data.addPrivateLessonBooking || this.data.instructorView)) {
      let slots: any;
      if (this.data.wishedTime)
        slots = this.partyService.slots_holder.filter(slot => Utilities.formateDateString(slot.DateTime) >= Utilities.formateDateString(this.data.WishedTime) && Utilities.formateDateString(slot.DateTime) < Utilities.formateDateString(this.data.DepartureTime));
      else if (this.data.instructorView) {
        slots = this.partyService.slots_holder.filter(slot => slot.DateTime === this.data.SelectedSlot)
      }
      else
        slots = this.partyService.slots_holder.filter(slot => Utilities.formateDateString(slot.DateTime) >= Utilities.formateDateString(this.data.SeatingTime) && Utilities.formateDateString(slot.DateTime) < Utilities.formateDateString(this.data.DepartureTime));
      if (slots && slots.length > 0) {
        slots.forEach(slot => {
          this.partyService.selectedOpenBookingSlots[slot.Id] = true;
          this.onSlotUpdate(slot);
        });
      }
      
      if (this.validCoverTypes && this.validBookingTypes) {
        this.updateFormValidity();
      }
    }
    this.displaySlots = true;
    if (this.data && this.data.isConfirmedReservation) {
      this.setBookingData();
      this.partyService.updateContactOnly = true;
    }

    if (this.data && ((this.data.State == PartyState.Seated && this.editCheckedInReservation) || this.data.isConfirmedReservation)) {
      this.partyService.slots_holder.map(items => {
        items.IsDisabled = true;
      })
    }
  }

  getCoverTypesize(): number {
    return this.sizeMapping.sizes.reduce((res, curr) => res + (+curr.Covers), 0);
  }

  isNotPastTimeSlot(slot): boolean {
    const maxPastTime = this._settings.General.MaxPastTimeForReservationsInMinutes;
    const currentTime = Utilities.getRestaurantDateTime(this._settings.General.DaylightDelta);
    let minutes = this.cs.settings.value.PropertySetting[0].ShowOngoingSlotsTill ? (this.cs.settings.value.PropertySetting[0].ShowOngoingSlotsTill * 1000) : 60;
    const past1hr = new Date(currentTime.valueOf() - (maxPastTime * minutes));
    const slotTime = Utilities.parseDateString(slot.DateTime);
    return slotTime >= past1hr;
  }

  generatePartySizeArray() {
    this.bookingSizeArray = [];
    for (let i = 1; i <= this.maxSizeAllowed; i++) {
      this.bookingSizeArray.push({ id: i, value: i.toString() });
    }
    return this.bookingSizeArray;
  }

  resetDropdownvalues() {
    var date = new Date(this.bookingFromDateConfig[0].value);
    let dayOfWeek = moment.isMoment(date) ? date.day() : date.getDay();
    var activitySessions = this.selectedActivity.ActivitySessions.filter(session => session.Dayofweek == dayOfWeek);
    this.locationConfig[0].options = this.locationList.filter(location => {
      return !!(activitySessions.filter(session => !!(session.SessionLocationMappings.find(elm => elm.StandaloneTableId === location.id))).length)

    });
    this.instructorConfig[0].options = this.instructorList.filter(server => {
      return !!(activitySessions.filter(session => !!(session.SessionStaffMappings.find(elm => elm.ServerId === server.id))).length)
    });
    if (!this.locationConfig[0].options?.length) {
      this.includeAny(this.locationConfig[0].options);
      this.locationConfig[0].value = -1;
    }
    if (!this.instructorConfig[0].options?.length) {
      this.includeAny(this.instructorConfig[0].options);
      this.instructorConfig[0].value = -1;
    }
  }

  formChangeSubscribe() {
    this.components.forEach((x, index) => {
      this.subscriptions.add(x.form?.valueChanges.pipe(debounceTime(100),
        distinctUntilChanged()).subscribe(value => {
          let _lastSelectedActivityId = this.selectedActivity?.Id;
          if (value.fromDate && !this.data) {
            (value.fromDate._isAMomentObject) ? this.getLessonsConfig(value.fromDate.toDate()) : this.getLessonsConfig(new Date(value.fromDate));
            this.onFromDateChange(new Date(value.fromDate));
          }
          if (value.class && this.ActivityList.find(activity => activity.Id == value.class)) {
            if (this.intialLoad || _lastSelectedActivityId !== value.class) {
              this.selectedActivityFieldChange(true);
            }
          }         
         
          if (((value.location && this.locationList.find(element => element.id == value.location)) || value.fromDate
            || (value.instructor && this.instructorList.find(instructor => instructor.id == value.instructor)) || value.class) && !this.invalidDate) {
            if (this.partyService.isSlotLocked) {
              if ((this.partyService.slotLockIds && this.partyService.slotLockIds.length > 0) && this.cs.settings.value.General.SlottingMode === SlottingMode.Auto) {
                this.partyService.isSlotLocked = false;
                this.subscriptions.add(this.partyService.releaseMultiple(this.partyService.slotLockIds).subscribe((slot) => {
                  this.getSlotsByLocation();
                  this.partyService.slotLockIds = [];
                }));
                this.isValidConfig = true;
              }
            } else {              
              if (_lastSelectedActivityId !== value.class) {
                this.getSlotsByLocation();
              }
            }
          }
          else {
            let locationForm = this.getFormComponent('location')[0].form;
            let instructorForm = this.getFormComponent('instructor')[0].form;
            if (locationForm.invalid || (locationForm.value.location != -1 && !this.locationList.find(element => element.id == locationForm.value.location)) || instructorForm.invalid || (instructorForm.value.instructor != -1 && !this.instructorList.find(element => element.id == instructorForm.value.instructor))) {      
              this.isValidConfig = false;
              this.toastrService.error(this.ts.instant('Select a valid', { name: this.ts.instant('location'), value: this.ts.instant('instructor') }), '', {
                timeOut: 3000
              });
            }
          }

          this.updateFormValidity();
          if (this.data && this.data.Contact && this.partyService.reservationFormGroup) {
            this.partyService.reservationFormGroup.markAsDirty();
            this.partyService.reservationFormGroup.updateValueAndValidity();
          }

          this.intialLoad = false;
        }));
    });
  }

  selectedActivityFieldChange(isFormChange) {
    if (this.components) {
      var [fromDate, activity, location, instructor,] = this.components;
      this.selectedActivity = this._settings.SpecialMeals.find(s => s.Id === activity.form.get("class").value);
      if (this.selectedActivity) {
        this.showSalesContact = this.selectedActivity?.TrackingofSalesPerson || false
        if (this.showSalesContact) {
          this.salesContactUsers();
        }
        this.partyService.selectedSpecialMealId = this.selectedActivity.Id;
        // this.partyService.addOrRemoveAddOn(this.partyService.selectedSpecialMealId)
        this.partyService.activityChanges$.next(this.selectedActivity);
        if (this.selectedActivity && this.selectedActivity.CustomFieldValidation) {
          this.partyService.customFieldValidation = this.selectedActivity.CustomFieldValidation
        }
        this.partyService.isStandBy = this.selectedActivity.IsForStandbyReservations;
        this.maxSizeAllowed = this.selectedActivity.MaxPartySize;
        this.minSizeAllowed = this.selectedActivity.MinPartySize;
        this.resetDropdownvalues();
        if (this.locationConfig && this.locationConfig[0]?.options?.length > 0) {
          this.locationConfig[0].value = this.locationConfig[0].options[0].id;
        }
        if (this.instructorConfig && this.instructorConfig[0]?.options?.length > 0) {
          this.instructorConfig[0].value = this.instructorConfig[0].options[0].id;
        }
        var [, , location, instructor, , ,] = this.components;
        if (this.detailComponetData && this.detailComponetData.locationId) {
          this.components['_results'][2].form.controls.location.setValue(this.detailComponetData.locationId, { emitEvent: false });
          this.detailComponetData.locationId = null;
        } else {
          if (this.locationConfig[0]?.options?.length > 0) { location.form.controls.location.setValue(this.locationConfig[0].options[0].id, { emitEvent: false }) };
        }
        if (this.detailComponetData && this.detailComponetData.instructorId) {
          this.components['_results'][3].form.controls.instructor.setValue(this.detailComponetData.instructorId, { emitEvent: false });
          this.detailComponetData.instructorId = null;
        } else {
          if (this.instructorConfig[0]?.options?.length > 0) { instructor.form.controls.instructor.setValue(this.instructorConfig[0].options[0].id, { emitEvent: false }) };
        }
        if (isFormChange) {

          var restaurantDate = Utilities.getRestaurantDateTime(this._settings.General.DaylightDelta);
          //   this.bookingFromDateConfig[0].minDate = this.df.getMinDateBasedonConfig(this._settings.SpecialMeals.find(meal => meal.Id == this.partyService.selectedSpecialMealId),restaurantDate);
          if (new Date(this.selectedActivity.StartDate) > new Date(this.appService._headerDate)
            && new Date(this.selectedActivity.StartDate) > restaurantDate) {
            this.getFormComponent("fromDate")[0].form.controls.fromDate.setValue(new Date(this.selectedActivity.StartDate), { emitEvent: false });
            this.onFromDateChange(new Date(this.selectedActivity.StartDate));
          } else {
            if (new Date(this.appService._headerDate) > restaurantDate) {
              this.getFormComponent("fromDate")[0].form.controls.fromDate.setValue(new Date(this.appService._headerDate), { emitEvent: false });
              this.onFromDateChange(new Date(this.appService._headerDate));
            }
            this.bookingFromDateConfig[0].disabled = false;
          }
        }
        this.getCoverTypesForSelectedActivity(this.selectedActivity.Id);
        this.getBookingTypesForSelectedActivity(this.selectedActivity.Id);

        if (!this.selectedActivity.IsPrepaymentRequired) {
          this.showActivityRatePlanInfo();
        }
      } else {
        this.displaySlots = false;
        this.allocationCodes = [];
        this.partyService.slots_holder = [];
      }
    }
  }
  onFromDateChange(fromDate) {
    if (this.selectedActivity && this.selectedActivity.ActivitySessions) {
      var date = fromDate;
      let dayOfWeek = moment.isMoment(date) ? date.day() : date.getDay();
      var activitySessions = this.selectedActivity.ActivitySessions.filter(session => session.Dayofweek == dayOfWeek);
      this.locationConfig[0].options = this.locationList.filter(location => {
        return !!(activitySessions.filter(session => !!(session.SessionLocationMappings.find(elm => elm.StandaloneTableId === location.id))).length)

      });
      this.instructorConfig[0].options = this.instructorList.filter(server => {
        return !!(activitySessions.filter(session => !!(session.SessionStaffMappings.find(elm => elm.ServerId === server.id))).length)

      });
      if (!this.locationConfig[0].options?.length) {
        this.includeAny(this.locationConfig[0].options);
        this.locationConfig[0].value = -1;
      }
      if (!this.instructorConfig[0].options?.length) {
        this.includeAny(this.instructorConfig[0].options);
        this.instructorConfig[0].value = -1;
      }
      if (this.components) {
        var [, , location, instrucor,] = this.components;
        this.locationConfig[0].options?.length > 0 ? location.form.controls.location.setValue(this.locationConfig[0].options[0].id, { emitEvent: false }) : location.form.controls.location.setValue(0, { emitEvent: false });
        this.instructorConfig[0].options?.length > 0 ? instrucor.form.controls.instructor.setValue(this.instructorConfig[0].options[0].id, { emitEvent: false }) : instrucor.form.controls.instructor.setValue(0, { emitEvent: false });
      }
    }
  }

  setSelectedMembershipDetail(memberShip: EngageMemberDetailDTO, editContactData) {
    const memberShipdetail = memberShip.ProfileInfoGetByCardId.ProfileValue;
    var memberType = this._settings.AllocationType.find(allocation => allocation.AllocationName === memberShipdetail.MembershipType);
    var guestObject = {
      FirstName: memberShipdetail.FName,
      LastName: memberShipdetail.LName,
      EmailAddress: memberShipdetail?.EmailList[0]?.EmailId,
      PhoneNumber: memberShipdetail?.PhoneList[0]?.PhoneNumber,
      CountryId: this.partyService.getCountryId(memberShipdetail?.PhoneList[0]?.PhoneCode),
      PIILastUsedDate: null,
      Birthday: memberShipdetail.Dob,
      ContactCustomFields: [],
      Notes: [],
      VisitStats: [],
      GolfPoints: memberShip.GolfPoints,
      MarketingOptedIn: null,
      memberShipId: memberShipdetail.CardNo,
      MembershipType: memberShipdetail.MembershipType,
      RateType: memberShipdetail.RateType,
      MemberRateTypeAfterRoundsUsed: memberShipdetail.RateTypeAfterRoundsUsed,
      MemberRateTypeMembershipExpiry: memberShipdetail.RateTypeMembershipExpiry,
      MemberActive: !memberShipdetail.IsMembershipExpired
    }
    if (editContactData) {
      editContactData.memberShipId = memberShipdetail.CardNo;
      editContactData.MembershipType = memberType;
      editContactData.RateType = memberShipdetail.RateType;
      editContactData.MemberActive = memberShipdetail.MembershipStatus && memberShipdetail.MembershipStatus?.toLowerCase() === "active"
    }
    this.partyService.selectedGuest = editContactData ? editContactData : guestObject;
    if (this._settings.General.UseMembershipNumber) {
      this.partyService.selectedGuest.TrackMembershipNumber = memberShipdetail?.CardNo; // to bind it in the guest form
    }
    this.partyService.selectedMembershipDetail$.next(true);
    this.updateFormValidity();
  }

  updateSelectedSlotsByMembersihps() {
    Object.entries(this.partyService.selectedOpenBookingSlots).forEach((itm => {
      if (itm[1]) {
        const allocationTypeId = this.partyService.slots_holder.find(slot_holder => slot_holder.Id == itm[0]).AllocationTypeId;
        if (allocationTypeId && (
          !this.partyService.selectedGuest || !this.partyService.selectedGuest.MembershipType || !this.partyService.selectedGuest.MemberActive || allocationTypeId !== this.partyService.selectedGuest.MembershipType?.Id)) {
          this.partyService.selectedOpenBookingSlots[itm[0]] = false;
        }
      }

    }))
  }
  getCoverTypesForSelectedActivity(activityId) {
    let activitySelected = this._settings.SpecialMeals.find(activity => activity.Id == activityId);
    this.coverTypes = activitySelected.CoverTypePrices.map((type: any) => {
      return {
        Id: type.Id,
        Name: type.CoverTypeName || type.Name,
        MinimumQuantity: type?.MinimumQuantity,
        MaximumQuantity: type?.MaximumQuantity
      }
    });
    this.showCoverTypes = true;
  }

  getBookingTypesForSelectedActivity(activityId) {

    let activitySelected = this._settings.SpecialMeals.find(activity => activity.Id == activityId);
    this.bookingTypes = activitySelected.BookingTypeActivitiesMappings.map((type : any) => {
      return {
        Id: type.Id,
        Name: type.BookingTypeName || type.Name
      }
    });
    this.showBookingTypes = true;
  }
  calculatePartySize() {
    this.bookingSizeArray = [];
    for (let i = 1; i <= this.maxSizeAllowed; i++) {
      this.bookingSizeArray.push({ id: i, value: i.toString() });
    }
    // this.bookingSizeArray.push({ id: this.maxSizeAllowed, value: this.maxSizeAllowed.toString() + "+" });

    return this.bookingSizeArray;
  }
  showActivityRatePlanInfo() {
    let message = this.ts.instant('noRatePlanApplicableForBooking')
    const popUpMessage = [{
      confirmationMessage: message, dialogTitle: 'Rate Plan Info', showAlert: true
    }];
    const componentDetails: ComponentDetails = {
      componentName: ConfirmationPopupComponent,
      popupType: 'action',
      popUpDetails: {
        isStepper: false,
        eventName: 'notifyParent'
      },
      popupInput: popUpMessage,
      popupTitle: this.ts.instant('alert')
    };
    const dialogRef = this.dialog.open(CustomPopupComponent, {
      disableClose: true,
      width: '450px',
      height: 'auto',
      data: {
        showAction: true,
        update: 'ok',
        componentDetails,
        from: ComponentTypes.ActivityRatePlanInfo
      }
    });
  }
  cellClick(event) {
    this.selectedBookingSize = event.option.value;
  }

  isFormValid() {
    var isValid = true;
    // isValid = this.components.last.form.valid && this.components.last.form.status !== 'DISABLED';
    this.components?.forEach(component => {
      if(!component.form?.value) 
      isValid = false;
    })

    let [locationForm, staffForm] = this.getFormComponent("location", "instructor");

    if ((locationForm && locationForm.form.status == 'INVALID')
      || (staffForm && staffForm.form.status == 'INVALID')) {
      isValid = false;
    }

    return isValid;
  }

  isValidDuration() {
    //var slotsSelected = Object.values(this.partyService.selectedOpenBookingSlots).filter(slot => slot);
    let selectedSlotIds = Object.keys(this.partyService.selectedOpenBookingSlots).filter(slot => this.partyService.selectedOpenBookingSlots[slot]).map(id => Number(id)).sort();
    let selectedSlots = this.partyService.slots_holder?.filter(s => selectedSlotIds.includes(s.Id))

    if (!selectedSlots?.length) {
      return false;
    }

    let isDurationValid = false;
    let mins = this._settings.General.TimeSlotUnitInMinutes;
    let count = 1;
    if (selectedSlots?.length == 1) {
      var durationSelected = mins;
      return durationSelected >= this.selectedActivity.MinDuration && durationSelected <= this.selectedActivity.MaxDuration
    }
    let validSlotsList = [];
    if (selectedSlots) {
      for (let i = 0; i < selectedSlots.length - 1; i++) {
        const diffInSlotMins = Math.abs(Utilities.diffBetweenDatesInMinutes(selectedSlots[i].DateTime, selectedSlots[i + 1].DateTime))
        if (diffInSlotMins == mins) {
          count++;
          var durationSelected = count * this.timeSlotUnitInMinutes;
          isDurationValid = durationSelected >= this.selectedActivity.MinDuration && durationSelected <= this.selectedActivity.MaxDuration;
        }
        else {
          count = 1;
          if (!isDurationValid && i > 0) {
            return false;
          }
          var durationSelected = count * this.timeSlotUnitInMinutes;
          isDurationValid = durationSelected >= this.selectedActivity.MinDuration && durationSelected <= this.selectedActivity.MaxDuration;
        }
      }
    }
    return isDurationValid;

  }

  updateFormValidity() {
    if(this.ps.tabsActionData?.length > 0) {
    this.ps.tabsActionData[0].gotoNextTab = this.isFormValid() && this.groupSizeValidation() && this.isValidDuration() && this.isValidConfig;
    this.ps.tabsActions$.next(this.ps.tabsActionData);
    }
  }


  getFormComponent(...args) {
    return args.map(arg => this.components.find(form => form.value.hasOwnProperty(arg)))
  }

  groupSizeValidation() {
    return this.sizeMapping.valid && (this.partyService?.bookingSize >= this.selectedActivity?.MinPartySize && this.partyService?.bookingSize <= this.selectedActivity?.MaxPartySize);
  }


  setBookingData() {
    var [fromDate, activity, location, instructor, memberShipDetails] = this.components;
    var instructors = instructor.form.get("instructor").value == -1 ? [] : [instructor.form.get("instructor").value];
    this.partyService.privateLessonBookingData = null;
    let bookingSizeValue = this.sizeMapping.sizes.reduce((res, curr) => +curr.Covers + res, 0);
    this.partyService.bookingSize = bookingSizeValue;
    this.partyService.privateLessonBookingData = new PrivateLessonBookingDTO(
      this.data?.Id || null,
      this.getFormValue(activity, "class"),
      this.getFormValue(activity, "classType"),
      this.getFormValue(fromDate, "fromDate"),
      this.getFormValue(fromDate, "fromDate"),
      [this.getFormValue(location, "location")],
      this.getLocationName(this.getFormValue(location, "location")),
      instructors,
      bookingSizeValue,
      this.getFormValue(this.salesContactForm, "salescontacts") || [],
      this.getFormValue(memberShipDetails, "memberShipDetails"),
      this.sizeMapping.sizes,
      [],
      this.getSlotsObj(),
      BookingBehavior.PrivateLesson,
      this.partyService.selectedSpecialMealId,
      null
    );

    if(this.data?.PageMethod){
      this.partyService.privateLessonBookingData.PageMethod = this.data.PageMethod;
    }
  }

  getLocationName(locationId) {
    return [this.locationList.find(location => location.id == locationId).value];
  }

  setSelectedGuest() {
    const [memberdetail] = this.getFormComponent('membershipId');
    const seectedGuestValue = memberdetail.form.controls.membershipId.value;
    let selectedIndex = Number(seectedGuestValue.substring(0, 1));
    memberdetail.form.controls.membershipId.setValue('');
    this.hasMembershipValue = false;
    this.memberShipdetail = this.memberShipArr[selectedIndex - 1];
    let engageIntegrationData = this._settings.General.EngageIntegrationDTO;
    let obj: EngageMemberByCardIdRequestDTO = new EngageMemberByCardIdRequestDTO(this.memberShipdetail.CardNo, engageIntegrationData?.TenantId, engageIntegrationData?.SiteId);
    this.apiService.getEngageMemberByCardId(obj).subscribe((memberDetailResponse: EngageMemberDetailDTO) => {
      this.selectedMemberDetail = memberDetailResponse;
      this.setSelectedMembershipDetail(memberDetailResponse, this.data ? this.data.Contact : null)
      this.df.scrollTop("memberInformation");
    })
  }

  getMemberOptionValue(data, index) {
    return '' + (index + 1) + ' ' + data.FName + ' - ' + data.CardNo + ' - ' + data.Email + ' - ' + data.PhoneNumber
  }

  onSizeChange() {
    this.partyService.bookingSize = this.getCoverTypesize();
    if(this.components){
      this.getSlotsByLocation();
      this.updateFormValidity();
    }
  }

  calculateEndTime(startTimeStr, duration) {
    var startTime = new Date(startTimeStr);
    var startMins = startTime.getMinutes();
    startTime.setMinutes(startMins + duration);
    return startTime;
  }

  getFormValue(formObj, fieldName) {
    if (!formObj) {
      return formObj;
    }
    return formObj.form.get(fieldName)?.value;
  }

  // getCoverTypeValues(covers) {
  //   return this.coverTypes.map(coverType => {
  //     return { Covers: this.getFormValue(covers, coverType.Name), CoverTypeId: coverType.Id }
  //   })
  // }

  getCoverTypeValues(covers) {
    return this.coverTypes.map(coverType => {
      return { Covers: this.getFormValue(covers, coverType.Name), CoverTypeId: coverType.Id, CoverTypeName: coverType.Name };
    })
  }

  getBookingTypeValues(bookingTypes) {
    return this.bookingTypes.map(bookingType => {
      return { BookingTypes: this.getFormValue(bookingTypes, bookingType.Name), BookingTypeId: bookingType.Id, BookingTypeName: bookingType.Name }
    })
  }

  getSlotsObj() {
    let { selectedOpenBookingSlots, slots_holder } = this.partyService;
    let resSlots = [];
    let slots = Object.entries(selectedOpenBookingSlots)
      .filter(arr => arr[1]);
    for (let slotI = 0; slotI < slots.length; slotI++) {
      let itm = slots[slotI];
      let currentSlotIndex = slots_holder.findIndex(slot_holder => slot_holder.Id == itm[0]);
      var slot = slots_holder[currentSlotIndex];
      // if (slot.PartyId && slot.PartyId && this.data.ReservedFor != slot.DateTime) {
      //   continue;
      // }
      if (slot && slot !== null) {
        var resSlot = {
          Time: slot.DateTime,
          DurationInMinutes: this.timeSlotUnitInMinutes,
          EndTime: this.calculateEndTime(slots_holder[currentSlotIndex].DateTime, this.timeSlotUnitInMinutes),
          PartyId: (slot.PartyId && this.data) ? slot.PartyId : null
        }

        for (let i = (currentSlotIndex + 1); slots_holder[i]; i++) {
          let slotDateTime = new Date(slots_holder[i].DateTime);
          let defaultDateTime = new Date(slots_holder[currentSlotIndex].DateTime);
          let mins: any = (slotDateTime.getTime() - defaultDateTime.getTime()) / (60 * 1000);
          if (slots.find(slot => slot[1] && +slot[0] == slots_holder[i].Id && mins <= resSlot.DurationInMinutes)) {
            resSlot.DurationInMinutes += this.timeSlotUnitInMinutes;
            slotI += 1;
          } else {
            break;
          }
        }
        resSlot.EndTime = this.calculateEndTime(resSlot.Time, resSlot.DurationInMinutes)
        resSlots.push(resSlot);
      }
    }
    return resSlots;
  }

  clearFormField(name, event) {
    var [formField] = this.getFormComponent(name);
    formField?.form.controls[`${name}`].setValue("");
  }

  resetOnOpenBookingTerminate() {
    this.partyService.selectedMembershipDetail$.next(false);
    if (!this.partyService.reservationFromGuestBook) {
      this.partyService.selectedGuest = null;
    }
    this.partyService.selectedOpenBookingSlots = {};
    this.partyService.privateLessonBookingData = null;
    this.partyService.slots_holder = [];
    this.partyService.SlotIds = 0;
    if (!Utilities.isRetailEnabledProperty(this.cs.settings.value.General.RetailIntegrationDTO)) {
      this.partyService.privateLessonBookingDataSaveObj = null;
    }
    this.partyService.isEditData = false;
  }

  getFromDate(restaurantDate: Date) {
    if (this.weeklyViewDate)
      return this.weeklyViewDate;
    else if (this.data && this.data.ReservedFor)
      return this.data.ReservedFor;
    else if (this.df.checkBookPastDaysReservationsConfig())
      return this.appService.headerDate$.value;
    else if (this.appService._headerDate > restaurantDate)
      return this.appService._headerDate
    else
      return restaurantDate;
  }

  salesContactUsers() {
    let salesContact = this.df.getSalesContractList(this.data);
    this.salesContactConfig[0].options = salesContact.contacts
    this.salesContactConfig[0].multiChipValues = salesContact.multiChip;
    if (this.data?.SalesContactIds) {
      this.salesContactForm?.form.get('salescontacts').setValue(this.data?.SalesContactIds);
    }
  }

  includeAny(arr: any[]) {
    arr.unshift({ id: -1, value: this.ts.instant("Any") })
  }


  ngOnDestroy() {
    if (this.subscriptions) {
      this.subscriptions.unsubscribe();
    }
    this.resetOnOpenBookingTerminate();
    this.partyService.customFieldValidation = [];
  }
}
