import { CdkVirtualScrollViewport } from "@angular/cdk/scrolling";
import { DatePipe } from '@angular/common';
import { AfterViewInit, ChangeDetectorRef, Component, Directive, ElementRef, Input, OnDestroy, OnInit, Pipe, PipeTransform, QueryList, ViewChild, ViewChildren, ViewEncapsulation } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { AppService } from "@app/app.service";
import { Category } from "@app/common/shared/shared/setupConstants";
import { CacheService } from "@app/core/services/cache.service";
import { LoaderService } from "@app/core/services/loader.service";
import { BookedPackagesComponent } from '@app/shared/components/booked-packages/booked-packages.component';
import { ConfirmationPopupComponent } from "@app/shared/components/confirmation-popup/confirmation-popup.component";
import { StandbyListingComponent } from "@app/shared/components/standby-listing/standby-listing.component";
import { LegendConfig } from "@app/shared/models/global.interface";
import { ActivityCreateFunctions } from "@app/shared/utilities/activity-create-functions";
import { ActivityBookingFunctions } from "@app/shared/utilities/activityBookings-functions";
import { CancelActivityPopupComponent } from '@components/cancel-activity-popup/cancel-activity-popup.component';
import { SelectBookingTypeComponent } from "@components/select-booking-type/select-reservation-type.component";
import { AttributeType, BookingBehavior, buttonTypes, CancelActivityType, ClassType, ComponentTypes, GetReservationsOperationOptions, LessonType, Menu, OperationResultState, PartyState, PartyType, PricingBy, ReservationEmailNotificationType, ReservationStatus, ReservationType, RolesAndPermissionsType, StaffScheduleType, Status, ViewBy } from "@constants/commonenums";
import * as globals from '@constants/globalConstants';
import { DueState, PaidState, controlSettings, days, popupDialogDimension } from "@constants/globalConstants";
import { DynamicFormComponent } from "@dynamicform/dynamic-form/dynamic-form.component";
import { ButtonValue, FieldConfig } from "@dynamicform/models/field-config.interface";
import { DateStateDTO, SlotDTO } from "@models/InputReservation";
import { ActivityCustomizationAppliesTo, LayoutDTO, ServerDTO, SettingsDTO, SettingType, SupportedReservationEmailConfirmationTypes, TimelineAxis } from "@models/RestaurantDTO";
import { TranslateService } from "@ngx-translate/core";
import { CustomPopupComponent } from "@popup-module/components/custom-popup/custom-popup.component";
import { ComponentDetails, TabsModel } from "@popup-module/models/popup.interface";
import { PopupService } from '@popup-module/popup.service';
import { PartyService } from "@services/party.service";
import { DashboardFunctions } from '@utilities/dashboard-functions';
import { Utilities } from "@utilities/utilities";
import { addHours, format } from "date-fns";
import _ from "lodash";
import moment from "moment";
import { NgScrollbar } from "ngx-scrollbar";
import { iif, Subscription } from "rxjs";
import { ApiService } from "../services/api.service";
import { ClassDetailsComponent } from "./class-details/class-details.component";
import { OpenBookingDetailComponent } from "./open-booking-detail/open-booking-detail.component";
import { PrivateLessonBookingDetailComponent } from "./privatelesson-booking-detail/privatelesson-booking-detail.component";
import { PartyPrepaymentState } from "@app/shared/models/InputContact";
import { TimelineFilterState } from "@app/shared/models/TimelineFilters.model";
import { debounceTime, distinctUntilChanged } from "rxjs/operators";
import { SettingsService } from "@app/shared/services/settings.service";
import { TimelineDragHandlerService } from "../services/timeline-scroll-handler.service";
import { ActivitiesTimelineService } from "../services/activities-timeline.service";
import { ExcludeLocationGroupPipe } from "@app/shared/pipes/ExcludeLocationGroup.pipe";
import { ServerService } from "@app/shared/services/server.service";
import { start } from "repl";


@Component({
  selector: 'app-activities-main-timeline',
  templateUrl: './activities-timeline-main.component.html',
  styleUrls: ['./activities-timeline-main.component.scss'],
  providers: [TimelineDragHandlerService],
  encapsulation: ViewEncapsulation.None
})

export class ActivitiesTimelineMaiComponent extends Utilities implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild(NgScrollbar) scrollbarRef: NgScrollbar;
  @ViewChild(CdkVirtualScrollViewport, { static: false })
  public viewPort: CdkVirtualScrollViewport;
  public buttonControls: ButtonValue[];
  public viewByConfig: FieldConfig[];
  public viewPeriodConfig: FieldConfig[];
  _layout: LayoutDTO = {} as LayoutDTO;
  searchconfig: FieldConfig[];
  timeArrayGenerated: any = [];
  privateLessonBookingSlots: any = [];
  public viewBy: string = "Location";
  searchBtn: ButtonValue;
  addClassButton: ButtonValue;
  bookButton: ButtonValue;
  standByListButton: ButtonValue;
  createActivityButton: ButtonValue;
  packagesListButton: ButtonValue;
  restaurantDateTime;
  public instructors = [];
  public slotTime = 30;
  tabsModel: TabsModel
  locationByInstructors;
  @ViewChild('searchConfigForm', { static: true }) searchConfigForm: DynamicFormComponent;
  @ViewChild('viewByForm', { static: true }) viewByForm: DynamicFormComponent;
  @ViewChild('viewByPeriodForm', { static: true }) viewByPeriodForm: DynamicFormComponent;
  _settings: SettingsDTO = {} as SettingsDTO;
  subscriptions: Subscription = new Subscription();
  currentTimeStart = 0;
  columnWidth: number = 7;
  zoomIncreaseWidth: number = 50;
  timelineViewByName: string;
  allBookings: any;
  currentDayShifts: any;
  timelineShiftId: any;
  activitiesBooking = [];
  componentDetails: ComponentDetails;
  ReservationType = ReservationType;
  dashboardData: any = [];
  subscription: Subscription = new Subscription();
  selectedViewId: number;
  selectedClass: any;
  selectedOpenBooking: any;
  selectedPrivateLessonBooking: any;
  CurrentDateSelected: any;
  intialized: boolean = false;
  classActivityData: any;
  initialLoad: boolean = true;
  headerPosition: string = '0px';
  selectedActivityDetail: any;
  globalsForProperty: any;
  viewPeriod: 'day' | 'week' = 'day';
  searchText: string = '';
  isInitialWeekChange = true;
  selectedTimelineViewType:any;
  dataList = {} as any;
  timeLineData:any;
  lastIndex: number = 60;
  rolesAndPermissionsType = RolesAndPermissionsType;
  settingsUpdated: boolean = false;
  currentPropertyId = Utilities.RestaurantId();
  allWeekActivitySessions:any = {};
  allWeekPrivateStandByBookings: any = {};
  allSessionsHeadLines: any = {};
  legendsConfig: LegendConfig[] = [{ State: 7, name: 'partyStatusAvailableText' }, { State: 10, name: 'Unavailable' }, { State: 0, name: 'confirmed' }, { State: 9, name: 'standby' }];
  filterData:any = {};
  categoriesList = [];
  allCategorySelected: boolean = true;
  viewByOptions = ViewBy;
  sortBy: string[] = ['CategoryName', 'SortOrder', "Name"];
  isSwitchAxis:boolean = false;
  timeFilter: number = 3;
  masterSessionWidth: number;
  _MESSAGES = MESSAGES;
  defaultWindowWidth: number = 3000;
  staffSchedules: any = [];
  staffBlockHours: ButtonValue;
  staffScheduleShitsBreakHours: any[] = [];
  public timelineActionButtonControls: ButtonValue[];
  isBlockHoursDisabled: boolean = false;
  showDayName: boolean = false;
  
  constructor(dialog: MatDialog, private apiService: ApiService, public cs: CacheService, private appService: AppService, private ts: TranslateService, private cd: ChangeDetectorRef,
    public partyService: PartyService, private abf: ActivityBookingFunctions, public translateService: TranslateService, private dashboardFunctions: DashboardFunctions, public ps: PopupService, private datePipe: DatePipe, private ls: LoaderService, private activityCreateFunctions: ActivityCreateFunctions, private ss:SettingsService,
    public dragHandlerService: TimelineDragHandlerService, private ats: ActivitiesTimelineService , public excludeLocationGroupPipe : ExcludeLocationGroupPipe, public serverService: ServerService ) {
    super(dialog);
    this.currentPropertyId = Utilities.RestaurantId();
    this.CurrentDateSelected = this.cs.headerDate;
    if (this.abf.viewPeriodSelected) {
      this.viewPeriod = this.abf.viewPeriodSelected;
    } else {
      this.abf.viewPeriodSelected = this.viewPeriod;
    }
    this.appService.showLegends = true;
    this.subscriptions.add(this.cs.settings.subscribe(sett => {
      this._settings = sett;
      if(this._settings?.MerchantSettings?.ShowDayNameinTimeline?.SettingValue == SettingType.ENABLE) {
        this.showDayName = true;
      }
      else {
        this.showDayName = false;
      }
      if(!this._settings.SpecialMeals){
        this._settings.SpecialMeals = [];
      }
      if(this.intialized){
        this.settingsUpdated = true;
      }
      if(this.currentPropertyId != this.appService.restaurantId){
        this.currentPropertyId = this.appService.restaurantId;
        this._layout = this.cs.propertySettings.value[this.currentPropertyId].layout;
        this.cs.categoryData = null;
        this.setCategories();
      }
      else {
        this.staffSchedules = [];
        this.setStaffSchedules();
      }
      if((!this.currentPropertyId || this.currentPropertyId === sett?.PropertySetting[0]?.RestaurantId)){
        if(Object.keys(this._layout).length){
          this.generateOperatingIntervals();
          this.generateData();
          let bookings = this.abf.formAllBookedActivities(this._settings, this._layout, this.cs.headerDate, this.partyService.Parties$.value, this.partyService.StandbyParties$.value, true);
          this.allBookings = bookings;
        }
        if(!this.intialized) {
        this.setViewBy({ value: this.partyService.SelectedTimelineView });
        }
      }
      appService.minuteTimer$.next(true);
    }));

    this.subscriptions.add(this.cs.layout.subscribe(layout => {
      this._layout = layout;

      if(this.settingsUpdated && this.intialized && this.currentPropertyId === this.appService.restaurantId){
        this.generateOperatingIntervals();
        this.generateData();
        this.setViewBy({ value: this.partyService.SelectedTimelineView });
        this.settingsUpdated = false;
      }
    }))
    appService.applySetting(Menu.ActivitiesTimeline);
    this.lastIndex = ((window.innerWidth || this.defaultWindowWidth ) / this.columnWidth);

    this.legendsConfig.forEach(legend => {
      legend.name = this.translateService.instant(legend.name);
    })
  }
date:any;
  generateData(){
    if(!this.cs.categoryData){
      this.cs.loadCategoryData();
    }
    if(!this.cs.layout['_value'].FloorPlans && !this._layout?.FloorPlans){
      return;
    }
    this.dataList = {} as any;
    this.allSessionsHeadLines = {} as any;
    this.allWeekPrivateStandByBookings = {} as any;
    let locobj = {};
    let insObj = {};
    let locationList = Utilities.getRestaurantTables(this._layout.FloorPlans || this.cs.layout['_value'].FloorPlans);
    locationList = this.excludeLocationGroupPipe.transform(locationList);
    this.date = this.cs.headerDate;
  //  let locationList = Utilities.getRestaurantTables(layout.FloorPlans);
    let date = this.getDateForActivities();
    let lastDateObj = new Date(this.cs.headerDate);
    lastDateObj.setDate(lastDateObj.getDate() + 7);
    let lastDate = this.getDateForActivities(lastDateObj);
    let dayofweek = date.getDay();
    let specialMealList: any = this._settings.SpecialMeals.filter(specialMeal => {
        let startDateObj = new Date(specialMeal.StartDate);
        let endDateObj = new Date(specialMeal.EndDate);
        return (specialMeal.ActivitySessions && specialMeal.ActivitySessions.length > 0 && specialMeal.ActivitySessions
          .filter(session => session.Dayofweek === this.CurrentDateSelected.getDay()).length)
        && (specialMeal.StatusCode == Status.Approved)
        && ((endDateObj.getTime() >= date.getTime() && startDateObj.getTime() <= date.getTime())
                || endDateObj.getTime() >= date.getTime() && startDateObj.getTime() <= date.getTime()
                    || startDateObj.getTime() > date.getTime() && endDateObj.getTime() < date.getTime())
    })
    this.dataList.ByClass = specialMealList.map((meal, index) => {

        let activity = {} as any;

        activity.Name = meal.Name;
        activity.Id = meal.Id;
        activity.MaxActivityStrength = meal.activity;
        activity.CategoryId = meal.CategoryId || -1;
        activity.SortOrder = meal.SortOrder;
        activity.SubcategoryId = meal.SubcategoryId || -1;
        activity.CategoryName = this.cs.categoryData[meal.CategoryId]?.name;
        activity.ClassType = meal.ClassType;
        let occupiedSots = {} as any;
        let ActivityAttributes = meal.ActivityAttributes || [];

        const modifiedSessionsParentIds = meal.ActivitySessions.reduce((a, o) => (o['ParentSessionId'] && moment(o.SessionDate).format('YYYY-MM-DD') == moment(this.CurrentDateSelected).format('YYYY-MM-DD') && o.Dayofweek == this.CurrentDateSelected.getDay() && a.push(o['ParentSessionId']), a), []);

        let sessions = meal.LessonType === LessonType.PrivateLesson
            ? meal.ActivitySessions
            .filter(session => session.Dayofweek === this.CurrentDateSelected.getDay() && !modifiedSessionsParentIds.includes(session.Id || session.ActivitySessionId) 
              && (!session.SessionDate || moment(session.SessionDate).format('YYYY-MM-DD') == moment(this.CurrentDateSelected).format('YYYY-MM-DD')))
            .map((session) => {
              session.Locations = session.SessionLocationMappings?.map(location => {
                let locationObj = locationList.find(({ Id }) => location.StandaloneTableId === Id);
                return locationObj;
              })
              session.Staffs = session.SessionStaffMappings?.map(staff => {
                let server = this._settings.Servers.find(({ Id }) => staff.ServerId === Id);
                return server;
              })
              session.privateActivity = true;
              return session;
            })
            : _.sortBy(meal.ActivitySessions
              .filter(session => session.Dayofweek === this.CurrentDateSelected.getDay() && !modifiedSessionsParentIds.includes(session.Id || session.ActivitySessionId) 
                && (!session.SessionDate || moment(session.SessionDate).format('YYYY-MM-DD') == moment(this.CurrentDateSelected).format('YYYY-MM-DD'))), "StartTime")
                .map((session) => {
                    session.specialMealName = meal.Name;
                    session.allServerNames = "";
                    session.allLocationNames = "";
                    session.Staffs = [];
                    session.Locations = [];
                    session.SessionId = session.ActivitySessionId;
                    session.CategoryId = meal.CategoryId || -1;
                    session.SubcategoryId = meal.SubcategoryId || -1;
                    session.Date = moment(this.CurrentDateSelected).format("YYYY-MM-DD");
                    session.ClassType = meal.ClassType;
                    let [startHr, startMin] = session.StartTime.split(":");
                    let [endHr, endMin] = session.EndTime.split(":");
                    let startIndex = this.getSlotIndex([+startHr, +startMin], this.timeArrayGenerated);
                    let endIndex = this.getSlotIndex([+endHr, +endMin], this.timeArrayGenerated);
                    if(endIndex < startIndex){
                      endIndex = this.timeArrayGenerated.length;
                    }
                    session['gridColumnStart'] = startIndex + 1;
                    session['gridColumnEnd'] = endIndex + 1;
                    for (let idx = startIndex + 1; idx <= endIndex + 1; idx++) {
                        occupiedSots[idx] = true;
                    }
                    session['allLocations'] = session.SessionLocationMappings?.map(location => {
                        if (!locobj[location.StandaloneTableId]) {
                            locobj[location.StandaloneTableId] = {
                                Sessions: [],
                                occupiedSots: {}
                            };
                        }
                        for (let idx = startIndex + 1; idx <= endIndex + 1; idx++) {
                            locobj[location.StandaloneTableId].occupiedSots[idx] = true;
                        }
                        locobj[location.StandaloneTableId].Sessions.push(session);
                        let locationObj = locationList.find(({ Id }) => location.StandaloneTableId === Id);
                        session.Locations.push(locationObj);
                        if(!locationObj){
                          console.log("LocationList", locationList)
                          console.log("Location", location.StandaloneTableId);
                        }
                        session.allLocationNames += (session.allLocationNames ? ',' : '') + locationObj?.Name;
                        return location;
                    })
                    session['allInstructors'] = session.SessionStaffMappings?.map(staff => {
                        if (!insObj[staff.ServerId]) {
                            insObj[staff.ServerId] = {
                                Sessions: [],
                                occupiedSots: {}
                            };
                        }
                        for (let idx = startIndex + 1; idx <= endIndex + 1; idx++) {
                            insObj[staff.ServerId].occupiedSots[idx] = true;
                        }
                        insObj[staff.ServerId].Sessions.push(session);
                        let server = this._settings.Servers.find(({ Id }) => staff.ServerId === Id);
                        session.Staffs.push(server);
                        session.allServerNames += (session.allServerNames ? ','  : '') + server?.Name; 
                        return staff;
                    })
                    session.SlotHeader = {} as any;
                    session.SlotHeader[ViewBy.Instructor] = meal.Name;
                    session.SlotHeader[ViewBy.Lessons] = session.allServerNames;
                    session.SlotHeader[ViewBy.location] = session.allServerNames;

                    session.SlotSubHeader = {} as any;
                    session.SlotSubHeader[ViewBy.Instructor] = session.allLocationNames;
                    session.SlotSubHeader[ViewBy.Lessons] = session.allLocationNames;
                    session.SlotSubHeader[ViewBy.location] = meal.Name;
                    session.State = 0;
                    session.uniqId = session.SessionGroupId ? ("group_"+ session.SessionGroupId) : session.ActivitySessionId;
                    session.MaxActivityStrength = session.SessionCustomization?.MaxActivityStrength ? session.SessionCustomization.MaxActivityStrength : meal.MaxActivityStrength;
                    session.AllowStandBy = meal.AllowStandBy;

                    if(session.StatusCode == Status.Cancelled){
                      session.cancelledBy  = this.translateService.instant('cancelledBy') + " " + (session.SessionCustomization  ? this.partyService.getHostName(session.SessionCustomization?.ActionBy) : '');
                    }
                    session.activityCustomisation = this.partyService.getActivityCustomisation(null, session);
                    if(session.activityCustomisation && session.activityCustomisation.length){
                      if(this.partyService.activityBlockingChange$.value){
                        let activityCustomisation = Object.values(this.partyService.activityBlockingChange$.value.ActivityBlockingRule).filter(data => data.ActivityId == session.SpecialMealId)
                        let columnDate = this.cs.headerDate;
                        let customsation = activityCustomisation?.find(cust => (cust.ActivitySessionIds.includes(session.SessionId) || cust.ActivitySessionIds.includes(session.ActivitySessionId)) && (moment(columnDate).format("YYYY-MM-DD") >= moment(cust.StartDate).format("YYYY-MM-DD")) && (moment(columnDate).format("YYYY-MM-DD") <= moment(cust.EndDate).format("YYYY-MM-DD")) && (cust.AppliesTo == ActivityCustomizationAppliesTo.HostAppAndWidget || cust.AppliesTo == ActivityCustomizationAppliesTo.HostApp));
                        session.blockedBy = this.translateService.instant('blockedBy') + " " + (customsation  ? this.partyService.getHostName(customsation.ActionBy) : '');
                        session.blockedState = customsation ? true : false;
                     // }
                    }
                  }
                    
                    session.SlotPrivateHeadline = this.dashboardFunctions.getPrivateAndPublicHeadline(ActivityAttributes.filter(x => x.AttributeType == AttributeType.PrivateHeadlines), session);
                    session.SlotPublicHeadline = this.dashboardFunctions.getPrivateAndPublicHeadline(ActivityAttributes.filter(x => x.AttributeType == AttributeType.PublicHeadlines), session);
                    if(session.SlotPrivateHeadline || session.SlotPublicHeadline || session.activityCustomisation || session.blockedBy){
                      this.allSessionsHeadLines[session.SessionId] = {
                        SlotPrivateHeadline: session.SlotPrivateHeadline,
                        SlotPublicHeadline:session.SlotPublicHeadline,
                        activityCustomisation: session.activityCustomisation,
                        blockedBy: session.blockedBy
                      }
                    }
                    return session;
                })
        activity.Sessions = sessions;
        activity.OpenPrivateReservations = [];
        if(sessions && sessions.length){
        activity.location = sessions[0].Locations[0]?.Name
        activity.locationId = sessions[0].Locations[0]?.Id;
        activity.instructors = sessions[0].Staffs;
        }
        return activity;
    })
    this.dataList.ByLocation = locationList.filter(location => !location.IsTemplate).map(location=>{
      let showExpandButton = false;
      // if(locobj[location.Id] && locobj[location.Id].Sessions){
      //   [locobj[location.Id].Sessions, showExpandButton] = this.setGroups(locobj[location.Id].Sessions);
      // }
        return {
            Name: location.Name,
            Id: location.Id,
            showExpandButton,
            Sessions: locobj[location.Id] && locobj[location.Id].Sessions,
            BufferTimeInMinutes: location.BufferTimeInMinutes || 0,
            OpenPrivateReservations: []
        }
    })
    this.dataList.ByInstructors = this._settings.Servers.filter(server => !server.IsTemplate).map(server => {
        return {
            Name: server.Name,
            Id: server.Id,
            Sessions: insObj[server.Id] && insObj[server.Id].Sessions,
            OpenPrivateReservations: [],
            StaffSchedule: []
        }
    })

    this.processAllParties();
    this.processStaffSchedules();
    this.partyService.allSessionsHeadLines = this.allSessionsHeadLines;
    this.sortData('ByLocation');
    this.sortData('ByInstructors');
    this.sortData('ByClass');

  }

  getDateForActivities(dateSelected?) {
    // let date = this.appService._headerDate         
    let date = new Date(dateSelected || this.date);
    date.setHours(0, 0, 0, 0)
    return date
}

  ispartyOverlapGroup(reservationStartTime, reservationDepartureTime, partyStartTime, partyDepartureTime){
    return (reservationStartTime >= partyStartTime && reservationStartTime <= partyStartTime)
          || (reservationDepartureTime >= partyStartTime && reservationDepartureTime <= partyDepartureTime)
          || (partyStartTime >= reservationStartTime && partyStartTime <= reservationDepartureTime)
          || (partyDepartureTime >= reservationStartTime && partyDepartureTime <= reservationDepartureTime)
  }

  setGroups(bookingsDataArray): [any[], false] {
    let groupItems = [];
    let showExpandButton: boolean = false
     bookingsDataArray = _.sortBy(bookingsDataArray, 'StartTime');
    bookingsDataArray.forEach(element => {
        let groupDetail = groupItems.find(groupItem => {
            return element.gridColumnStart === groupItem.gridColumnStart
                || element.gridColumnEnd === groupItem.gridColumnEnd
                || (element.gridColumnStart > groupItem.gridColumnStart && element.gridColumnStart < groupItem.gridColumnEnd)
                || (element.gridColumnStart <= groupItem.gridColumnStart && element.gridColumnEnd >= groupItem.gridColumnEnd)
                || (groupItem.gridColumnStart > element.gridColumnStart && groupItem.gridColumnStart < element.gridColumnEnd)
                || (groupItem.gridColumnStart <= element.gridColumnStart && groupItem.gridColumnEnd >= element.gridColumnEnd)
        })
        element.tempUniqValueData = [element.StartTime, element.Id].join(',');
        if (!groupDetail) {
            groupDetail = {
                gridColumnStart: element.gridColumnStart,
                gridColumnEnd: element.gridColumnEnd,
                items: [element],
                occupiedColumns: {},
                rows: 1,
                group: true
            }

            for (let col = element.gridColumnStart; col < element.gridColumnEnd; col++) {
                groupDetail.occupiedColumns["_" + col] = groupDetail.occupiedColumns["_" + col] ? groupDetail.occupiedColumns["_" + col] + 1 : 1;
            }

            groupItems.push(groupDetail);
        } else {
            if (groupDetail.gridColumnStart > element.gridColumnStart) groupDetail.gridColumnStart = element.gridColumnStart;
            if (groupDetail.gridColumnEnd < element.gridColumnEnd) groupDetail.gridColumnEnd = element.gridColumnEnd;
            for (let col = element.gridColumnStart; col < element.gridColumnEnd; col++) {
                groupDetail.occupiedColumns["_" + col] = groupDetail.occupiedColumns["_" + col] ? groupDetail.occupiedColumns["_" + col] + 1 : 1;
                if (groupDetail.occupiedColumns["_" + col] > groupDetail.rows) {
                    groupDetail.rows = groupDetail.occupiedColumns["_" + col];
                }
            }
            groupDetail.items.push(element);
            showExpandButton = true;
        }

    });


    return [groupItems, showExpandButton];
}

  sortData(type){
    let nameWithString = _.filter(this.dataList[type], function(o) { return isNaN(Number(o.Name)) });
    let nameWithNumber = _.filter(this.dataList[type], function(o) { return !isNaN(Number(o.Name)) });
    this.dataList[type] = [...(_.orderBy(nameWithNumber,function(s){return Number(s.Name)})).map(x=> { x.Name.toString();return x}),...(_.orderBy(nameWithString,['Name']))];      
}


  ngOnInit() {
    this.appService.showDashboard = false;
    this.appService.datePickerDisabled.next(false);
    this.appService.seatingAreaFilterDisabled.next(false);
    this.globalsForProperty = Utilities.controlValidate(controlSettings.Timeline_view_config, this.appService.PropertyType) ? globals.theatreTimelineView : globals.activitiesTimelineView;
    this.restaurantDateTime = Utilities.getRestaurantDateTime(this._settings.General.DaylightDelta);
    this.setFormConfig();
    this.setButtonConfig();
    this.isSwitchAxis = (this.cs.settings.value.General.DefaultTimelineAxis == TimelineAxis.Y) ? true : false;
    this.ats.sessionWidth = this.cs.settings.value.General.DefaultTimelineColumnWidth || 300;
    if(this.isSwitchAxis) {
      this.columnWidth = 4;
    }
    this.subscriptions.add(this.appService.headerDate$.subscribe(date => {
      this.ls.isLoading.next(true);
      if (date.getTime() != new Date(this.CurrentDateSelected).getTime()) {
        this.staffSchedules = [];
        this.setStaffSchedules();
      }
      this.CurrentDateSelected = date;
      let restaurantDateTime = Utilities.getRestaurantDateTime(this._settings.General.DaylightDelta);
        if(moment(restaurantDateTime).isSame(moment(this.cs.headerDate))) {
          this.isBlockHoursDisabled = false;
        }
        else {
          this.isBlockHoursDisabled = true;
        }
      this.generateOperatingIntervals();
      this.generateData();
      this.loadData();
      this.closeActivityDetail();
    }));
    this.subscriptions.add(this.appService.minuteTimer$.subscribe(() => {
      this.restaurantDateTime = Utilities.getRestaurantDateTime(this._settings.General.DaylightDelta);
      this.calculateTimelinePosition();
    }));
    this.subscriptions.add(this.appService.headerSelectedDate$.subscribe((date) => {
      this.appService.headerSelectedDate = date;
    }));

    this.partyService.timelineCommonFilters = (sessionStorage?.getItem('timelineFilters') 
      ? JSON.parse(sessionStorage?.getItem('timelineFilters')) 
      : globals.TimelineDefaultFilter) as TimelineFilterState;
  }

  loadData() {
    this.loadAllBookings()

  }

  setCategories(){
    if(!this.cs.categoryData){
      this.allCategorySelected = true;
      this.cs.loadCategoryData();
    }
    let categoriesTempData = {};
    this._settings.SpecialMeals.forEach(activity => {
      
      if(!categoriesTempData[activity.CategoryId]){
        categoriesTempData[activity.CategoryId] = [];
      }
      categoriesTempData[activity.CategoryId].push({name: activity.Name, id: activity.Id});
    });
    
    this.categoriesList = Object.values(this.cs.categoryData).filter((category: any) => category.type === 3).map((category: any) => {
      category.selected = true;
      category.collapsed = true;
      category.activities = categoriesTempData[category.id] || [];
      return category;
    });
    this.categoriesList = _.orderBy(this.categoriesList, "name")
    this.setDefaultCategoryFilterOnLoad();
  }

  setDefaultCategoryFilterOnLoad() {
    const tempCategoryFilter = sessionStorage.getItem('timelineCategoriesFilter') ? JSON.parse(sessionStorage.getItem('timelineCategoriesFilter')) : [];
    if (tempCategoryFilter?.length)
      this.categoriesList.forEach(category => {
        category.activities.forEach(activity => {
          activity.selected = tempCategoryFilter.includes(activity.id);
        });
        this.activitySelectHandler(category);
      });
    else
      this.allCategorySelectedHandler();
  }

  setFormConfig() {
    this.viewByConfig = [
      {
        type: 'select',
        name: 'viewBy',
        placeholder: '',
        options: Utilities.controlValidate(controlSettings.Timeline_view_config, this.appService.PropertyType) ? globals.theatreTimelineView : globals.activitiesTimelineView,
        class: 'timeline__view-by',
        showErrorText: true,
        appearance: true,
        isTranslate: true,
        value: this.partyService.SelectedTimelineView || (this.partyService.getDefaultTimelineView(globals.activitiesTimelineView, ViewBy.location)),
        cellClick: this.setViewBy.bind(this)
      }
    ];
    this.setCategories();
    this.searchconfig = [
      {
        type: 'input',
        name: 'searchText',
        label: 'Search by Instructor, Classes or Location',
        class: 'action-bar__search-text',
        showErrorText: true,
        appearance: true,
        icon: 'icon-search',
        icon1: 'icon-Group-591',
        cellClick: this.clearSearch.bind(this)
      }
    ];

    this.viewPeriodConfig = [
      {
        type: 'select',
        name: '',
        placeholder: '',
        options: globals.timelineViewPeriod.map(option => {
          option.value = this.translateService.instant(option.value.toString());
          return option;
        }),
        class: 'timeline__view-by',
        showErrorText: true,
        appearance: true,
        isTranslate: true,
        value: this.viewPeriod,
        cellClick: this.viewPeriodChange.bind(this)
      }
    ];
  }

  bookedSessionsFilterHandler(updateSessionValue?: boolean) {

    if(updateSessionValue)
     sessionStorage.setItem('timelineFilters', JSON.stringify(this.partyService.timelineCommonFilters));
    let tempActivityList = _.cloneDeep(this.getDataListByView()) || [];
    const filtersObj = this.partyService.timelineCommonFilters;
    let isGuestTypeFilterSelected; Object.keys(filtersObj.GuestTypeFilterState).forEach(e => filtersObj.GuestTypeFilterState[e] ? isGuestTypeFilterSelected = true : '');
    let isPaymentStatusFilterSelected; Object.keys(filtersObj.PaymentTypeFilterState).forEach(e => filtersObj.PaymentTypeFilterState[e] ? isPaymentStatusFilterSelected = true : '');
    let isActivityFilterSelected; Object.keys(filtersObj.ActivityFilterState).forEach(e => filtersObj.ActivityFilterState[e] ? isActivityFilterSelected = true : '');
    
    if (isPaymentStatusFilterSelected || isGuestTypeFilterSelected || isActivityFilterSelected)
      this.timeLineData = tempActivityList?.map(x => {
        x.Sessions = x.Sessions?.filter(y => {

          let isGuestTypeFilterMatched = false;
          let isPaymentStatusFilterMatched = false;
          let isActivityStatusFilterMatched = false;
          if (filtersObj.GuestTypeFilterState.showBookedSessions)
            isGuestTypeFilterMatched = this.partyService.allWeekActivitySessions[y.uniqId]?.parties.length;
          if (!isGuestTypeFilterMatched && filtersObj.GuestTypeFilterState.showBookingConfirmedSessions)
            isGuestTypeFilterMatched = this.partyService.allWeekActivitySessions[y.uniqId]?.Available < this.partyService.allWeekActivitySessions[y.uniqId]?.MaxActivityStrength;
          if (!isGuestTypeFilterMatched && filtersObj.GuestTypeFilterState.showStandbyBookedSessions)
            isGuestTypeFilterMatched = this.partyService.allWeekActivitySessions[y.uniqId]?.StandbyBooked > 0;
          if (!isGuestTypeFilterMatched && filtersObj.GuestTypeFilterState.showOverbookedSessions)
            isGuestTypeFilterMatched = this.partyService.allWeekActivitySessions[y.uniqId]?.OverBooked > 0;
            if (!isGuestTypeFilterMatched && filtersObj.GuestTypeFilterState.showCancelledReservations)
            isGuestTypeFilterMatched = this.partyService.allWeekActivitySessions[y.uniqId]?.parties.filter(party => party.State == PartyState.Cancelled).length > 0;
            
          if(!isGuestTypeFilterMatched && !isGuestTypeFilterSelected) isGuestTypeFilterMatched = true;

          if (this.partyService.timelineCommonFilters.PaymentTypeFilterState.showPaidBookings)
            isPaymentStatusFilterMatched = this.partyService.allWeekActivitySessions[y.uniqId]?.hasPaidBookings;
          if (!isPaymentStatusFilterMatched && this.partyService.timelineCommonFilters.PaymentTypeFilterState.showPartialPaidBookings)
            isPaymentStatusFilterMatched = this.partyService.allWeekActivitySessions[y.uniqId]?.hasDuePaymentBookings;
          if (!isPaymentStatusFilterMatched && this.partyService.timelineCommonFilters.PaymentTypeFilterState.showUnpaidBookings)
            isPaymentStatusFilterMatched = this.partyService.allWeekActivitySessions[y.uniqId]?.hasUnpaidBookings;
          if(!isPaymentStatusFilterMatched && !isPaymentStatusFilterSelected) isPaymentStatusFilterMatched = true;

          if(!isActivityStatusFilterMatched && this.partyService.timelineCommonFilters.ActivityFilterState.showCancelledSessions)
            isActivityStatusFilterMatched = y.StatusCode == Status.Cancelled
          if(!isActivityStatusFilterMatched && this.partyService.timelineCommonFilters.ActivityFilterState.showBlockedSessions)
            isActivityStatusFilterMatched = y.blockedState && y.blockedState == true
            if(!isActivityStatusFilterMatched && !isActivityFilterSelected) isActivityStatusFilterMatched = true; 
         
          return isGuestTypeFilterMatched && isPaymentStatusFilterMatched && isActivityStatusFilterMatched;

        }) || [];
        
        x.OpenPrivateReservations = x.OpenPrivateReservations?.filter(reservation => { 

          let isGuestTypeFilterMatched = false;
          let isPaymentStatusFilterMatched = false;
          let isActivityStatusFilterMatched = false;
          if (this.partyService.timelineCommonFilters.GuestTypeFilterState.showBookedSessions)
            isGuestTypeFilterMatched = true;
          if (!isGuestTypeFilterMatched && this.partyService.timelineCommonFilters.GuestTypeFilterState.showBookingConfirmedSessions)
            isGuestTypeFilterMatched = reservation.Type !== PartyType.StandBy;
          if (!isGuestTypeFilterMatched && this.partyService.timelineCommonFilters.GuestTypeFilterState.showStandbyBookedSessions)
            isGuestTypeFilterMatched = reservation.Type == PartyType.StandBy;
          if (!isGuestTypeFilterMatched && this.partyService.timelineCommonFilters.GuestTypeFilterState.showOverbookedSessions)
            isGuestTypeFilterMatched = reservation?.OverBooked > 0;
          if(!isGuestTypeFilterMatched && !isGuestTypeFilterSelected) isGuestTypeFilterMatched = true;

          if (this.partyService.timelineCommonFilters.PaymentTypeFilterState.showPaidBookings)
            isPaymentStatusFilterMatched = PaidState.includes(reservation.PrepaymentState);
          if (!isPaymentStatusFilterMatched && this.partyService.timelineCommonFilters.PaymentTypeFilterState.showPartialPaidBookings)
            isPaymentStatusFilterMatched = DueState.includes(reservation.PrepaymentState);
          if (!isPaymentStatusFilterMatched && this.partyService.timelineCommonFilters.PaymentTypeFilterState.showUnpaidBookings)
            isPaymentStatusFilterMatched = reservation.PrepaymentState == PartyPrepaymentState.PrepaymentRequired && reservation.State !== PartyState.Cancelled
          if(!isPaymentStatusFilterMatched && !isPaymentStatusFilterSelected) isPaymentStatusFilterMatched = true;

          if(this.partyService.timelineCommonFilters.ActivityFilterState.showCancelledSessions)
          isActivityStatusFilterMatched = reservation.cancelled && reservation.cancelled == true
          if(this.partyService.timelineCommonFilters.ActivityFilterState.showBlockedSessions)
            isActivityStatusFilterMatched = reservation.blockedState && reservation.blockedState == true
          return isGuestTypeFilterMatched && isPaymentStatusFilterMatched && isActivityStatusFilterMatched;

        }) || [];

        return x;
      })?.filter(activities => activities.Sessions?.length || activities.OpenPrivateReservations?.length);
    else
      this.timeLineData = tempActivityList;    
      this.partyService.timelineCommonFilters = _.cloneDeep(this.partyService.timelineCommonFilters);
  }

  timelineFilterSelectAllHandler(updateSessionValue: boolean, allSelected: boolean, propertyName: string) {
    const filtersObj = this.partyService.timelineCommonFilters;
    Object.keys(filtersObj[propertyName]).forEach(e => filtersObj[propertyName][e] = allSelected);
    this.bookedSessionsFilterHandler(updateSessionValue);
  }

  timelineFilterOnChangeHandler(updateSessionValue: boolean, propertyName: string) {
    const filtersObj = this.partyService.timelineCommonFilters;
    let allSelected = true;
    Object.keys(filtersObj[propertyName]).forEach(e => e != 'allSelected' && !filtersObj[propertyName][e] ? allSelected = false :'' );
    filtersObj[propertyName].allSelected = allSelected;
    this.bookedSessionsFilterHandler(updateSessionValue);
  }

  getDataListByView() {
    if (this.selectedViewId == 0)
      return this.dataList.ByInstructors
    else if (this.selectedViewId == 1)
      return this.dataList.ByClass;
    else if (this.selectedViewId == 2)
      return this.dataList.ByLocation;
    else 
      return [];
  }
  
  expansionToggle(event:Event, category){
    event.preventDefault();
    event.stopPropagation();
    
    category.collapsed = !category.collapsed;
  }

  allCategorySelectedHandler(){
    this.categoriesList.forEach(category => {
      category.selected = this.allCategorySelected;
      this.selectCategory(category);
    });
    this.onCategorySelectionChange();
  }

  selectCategory(category){
    category.activities && category.activities.forEach(activity => {
      activity.selected = category.selected;
    });
  }

  categorySelectHandler(category){
    this.selectCategory(category);
    this.onCategorySelectionChange();
  }

  activitySelectHandler(category){
    if(category.activities && category.activities.filter(activity => activity.selected).length === category.activities.length){
      category.selected = true;
    }else{
      category.selected = false;
    }
    this.onCategorySelectionChange();
  }

  onCategorySelectionChange(){
    if(this.categoriesList.filter(category => category.selected).length === this.categoriesList.length){
      this.allCategorySelected = true;
    }else{
      this.allCategorySelected = false;
    }
    let activities = this.categoriesList.filter(category => category.activities).map(category => category.activities).flat();
    let optionsSelected = activities.filter(activity => activity.selected).map(activity => activity.id);
    sessionStorage.setItem('timelineCategoriesFilter', JSON.stringify(this.allCategorySelected ? [] : optionsSelected));
    this.filterByCategory(optionsSelected);
  }

  filterByCategory(optionsSelected){
    let obj = {};
    obj[ViewBy.Lessons] = {activities: this.allCategorySelected ? [] : optionsSelected}
    this.filterData = Object.assign({}, this.filterData, obj);
  }

  // setViewByType(optionSelected){
  //   this.appService.showLegends = true;
  //   this.selectedViewId = optionSelected.value;
  //   this.selectedTimelineViewType = optionSelected;
  //   setTimeLineView(optionSelected){
  //     switch (optionSelected.value) {
  //         case 0:
  //          this.timeLineData = this.dataList.ByInstructors
  //           break;
  //         case 1:
  //             this.timeLineData = this.dataList.ByClass
  //           break;
  //         case 2:
  //             this.timeLineData = this.dataList.ByLocation
  //           break;
  //       }
  // }
  // }

  setViewBy(optionSelected) {
  //  this.ls.isLoading.next(true);
    this.appService.showLegends = true;
    this.selectedViewId = optionSelected.value;
    this.partyService.SelectedTimelineView = this.selectedViewId;
    if(this.globalsForProperty && this.viewByConfig){
    this.timelineViewByName = this.ts.instant(this.globalsForProperty?.find(property => property?.id == (this.selectedViewId || this.viewByConfig[0]?.value))?.value);
    }
    if (this.searchConfigForm?.form) {
      this.searchConfigForm.form.get('searchText').setValue('', { emitEvent: false });
      this.searchText = '';
    }
    this.closeActivityDetail();
      switch (optionSelected.value) {
          case 0:
            this.timelineViewByName = this.ts.instant('instructor');
           this.timeLineData = this.dataList.ByInstructors
            break;
          case 1:
            if(this.globalsForProperty && this.viewByConfig) {
              this.timelineViewByName = this.ts.instant(this.globalsForProperty?.find(property => property.id == this.selectedViewId)?.value);
            }       
              this.timeLineData = this.dataList.ByClass
            break;
          case 2:
            this.timelineViewByName = this.ts.instant('location');
              this.timeLineData = this.dataList.ByLocation
            break;
        }

        let viewConfigFormValue = this.viewByForm?.form?.controls?.viewBy?.value;
        if(viewConfigFormValue && viewConfigFormValue != this.selectedViewId){
          this.viewByForm.form.controls.viewBy.setValue(this.selectedViewId, { emitEvent: false });
        }
        if(this.searchconfig){
          this.searchconfig[0].label = 'Search by ' + globals.activitiesTimelineView[optionSelected.value].value;
        }

        this.bookedSessionsFilterHandler();
  }

  setButtonConfig() {
    this.buttonControls = [
      { label: this._MESSAGES.labels.currentTimeText, type: buttonTypes.actionSecondarySmall, customclass: 'action-bar__timeline-btn current-time-btn',name: this._MESSAGES.labels.currentTimeText },
      { label: this._MESSAGES.labels.zoomIn, type: buttonTypes.actionSecondarySmall, customclass: 'action-bar__zoom-in action-bar__timeline-btn',name: this._MESSAGES.labels.zoomIn },
      { label: this._MESSAGES.labels.zoomOut, type: buttonTypes.actionSecondarySmall, customclass: 'action-bar__zoom-out action-bar__timeline-btn', disbaledproperity: true,name: this._MESSAGES.labels.zoomOut },
      { label: this._MESSAGES.labels.reset, type: buttonTypes.actionSecondarySmall, customclass: 'action-bar__fit-view action-bar__timeline-btn',name: this._MESSAGES.labels.reset }];

    this.searchBtn = {
      type: buttonTypes.actionSecondarySmall,
      label: 'searchText',
      disbaledproperity: true,
      customclass: 'action-bar__search-btn',
    };

    this.addClassButton = {
      type: buttonTypes.actionSecondarySmall,
      label: 'addNewClass',
      disbaledproperity: false,
      customclass: 'action-bar__add-class-btn',
    };
    this.bookButton = {
      type: buttonTypes.actionSecondarySmall,
      label: 'book',
      disbaledproperity: false,
      customclass: 'action-bar__book-btn',
    };

    this.timelineActionButtonControls = [
      {
        type: buttonTypes.actionSecondarySmall,
        label: this.ts.instant('CreateActivity'),
        disbaledproperity: false,
        customclass: 'action-bar__book-btn',
        name: this._MESSAGES.labels.createActivity
      },
      {
        type: buttonTypes.actionSecondaryOrange,
        label: 'StandByList',
        disbaledproperity: false,
        customclass: 'action-bar__book-btn',
        name: this._MESSAGES.labels.standbylist
      },
      {
        type: buttonTypes.actionSecondaryOrange,
        label: 'packages',
        disbaledproperity: false,
        customclass: 'action-bar__book-btn',
        name: this._MESSAGES.labels.packages
      }
    ]
    
    this.createActivityButton = {
      type: buttonTypes.actionSecondarySmall,
      label: this.ts.instant('CreateActivity'),
      disbaledproperity: false,
      customclass: 'action-bar__book-btn',
    };
    this.standByListButton = {
      type: buttonTypes.actionSecondaryOrange,
      label: 'StandByList',
      disbaledproperity: false,
      customclass: 'action-bar__book-btn',
    };
    this.packagesListButton = {
      type: buttonTypes.actionSecondaryOrange,
      label: 'packages',
      disbaledproperity: false,
      customclass: 'action-bar__book-btn',
    }
    this.staffBlockHours = {
      type: buttonTypes.actionSecondarySmall,
      label: 'blockHours',
      disbaledproperity: false,
      customclass: 'action-bar__book-btn'
    }
  }

  createActivity(id?) {
    this.appService.isTemplateUsed =  id ?  true : false;
    this.subscriptions.add(this.appService.GetLessonByIdAndRestaurant(id || 0 ).subscribe(data => {
      console.log(data)
      this.appService.createActivitySettings = data.Payload;
      this.appService.showCreateActivity = true;
    }))
  }

  generateOperatingIntervals() {

    let shiftArray = _.cloneDeep(this._settings.OpenHours);
    let date = this.CurrentDateSelected;
    let dayOfWeek = moment.isMoment(date) ? date.day() : date.getDay();
    let allShifts = shiftArray.filter(shift => shift.DayOfWeek == dayOfWeek);
    const Slotval = 1;

    if (allShifts && allShifts.length > 0) {
        this.timeArrayGenerated = [];
        allShifts.forEach((shift, index) => {
            let Startval = shift.EffectiveRange.Start.toString().split('Z')[0];
            Startval = this.formatDate(date) + 'T' + Startval.split('T')[1];
            let endDate = new Date(this.CurrentDateSelected);
            if (new Date(shift.EffectiveRange.End).getDate() > new Date(shift.EffectiveRange.Start).getDate()) {
                endDate.setDate(date.getDate() + 1)
            }
            let Endvalue = shift.EffectiveRange.End.toString().split('Z')[0];
            let _timeSlotUnitInMinutes = allShifts[index+1] && shift.EffectiveRange.End.toString().split('Z')[0] === allShifts[index+1].EffectiveRange.Start.toString().split('Z')[0] ? 0 : this._settings.General.TimeSlotUnitInMinutes;
            const Endval = new Date(this.formatDate(endDate) + 'T' + Endvalue.split('T')[1]);
           // Endval.setMinutes(Endval.getMinutes() + _timeSlotUnitInMinutes)
           
            let Startloop = new Date(Startval);
            let estEndLoop = new Date(Startval);
            let isStart = true;
            //this.timeArrayGenerated = [];
            // estEndLoop.setMinutes(estEndLoop.getMinutes() +_timeSlotUnitInMinutes );

            while (Startloop.getTime() < Endval.getTime()) {
                const tempTimeSlot = Startloop;
                const slot: SlotDTO = <SlotDTO>{};
                let slotTime = Utilities.Date(Startloop);
                isStart = false;
                slot.DateTime = slotTime;
                let endTime = new Date(slotTime.format('YYYY-MM-DDTHH:mm:ss'));
                endTime.setMinutes(endTime.getMinutes() + Slotval);
                if (endTime <= Endval) {
                    this.timeArrayGenerated.push(slot.DateTime)
                }
                const newMinites = new Date(tempTimeSlot).getMinutes() + Slotval;
                const newDate = tempTimeSlot.setMinutes(newMinites);
                Startloop = new Date(newDate);
                // estEndLoop = new Date(newDate);
                // estEndLoop.setMinutes(estEndLoop.getMinutes() +_timeSlotUnitInMinutes );

            }

        });
    }
    this.timeArrayGenerated.sort((a: any, b: any) => {
        let _a: any = new Date(a);
        let _b: any = new Date(b);
        return _a - _b;
    });
    this.timeArrayGenerated = _.uniq(this.timeArrayGenerated);
}

  formatDate(date) {
    var d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2)
      month = '0' + month;
    if (day.length < 2)
      day = '0' + day;

    return [year, month, day].join('-');
  }




  book() {
    this.componentDetails = {
      componentName: SelectBookingTypeComponent,
      popupType: 'action',
      popUpDetails: {
        isStepper: false,
        eventName: 'notifyParent'
      }
    };
    this.partyService.reservationDialogRef = this.dialog.open(CustomPopupComponent, {
      disableClose: true,
      height: globals.popupDialogDimension.actionDialogHeight,
      width: globals.popupDialogDimension.createDialogWidth,
      backdropClass: 'backdropBackground',
      data: {
        title: 'Add popup', update: 'SAVE', cancel: 'CANCEL', componentDetails: this.componentDetails, popupType: 'action'
      }
    });
  }

  standByReservations() {
    this.selectedActivityDetail = null;
    const standbyTitle: string = this.ts.instant('standbyListPopupTitle');
    const componentDetails = {
      componentName: StandbyListingComponent,
      dimensionType: 'small',
      popupType: 'action',
      popUpDetails: {
        isStepper: false,
        eventName: 'notifyParent'
      },
      popupInput: this.classActivityData,
      popupTitle: standbyTitle
    }
    this.partyService.standbyDialog = this.dialog.open(CustomPopupComponent, {
      disableClose: true,
      width: popupDialogDimension.createDialogWidth,
      height: popupDialogDimension.createDialogHeight,
      data: {
        componentDetails,
        from: ComponentTypes.standby,
        back: true,
        standalone: true,
        showFooter: false
      }
    });
  }
  bookedPackages() {
    const packageTitle: string = this.ts.instant('packages');
    const componentDetails = {
      componentName: BookedPackagesComponent,
      dimensionType: 'small',
      popupType: 'action',
      popUpDetails: {
        isStepper: false,
        eventName: 'notifyParent'
      },
      popupInput: [],
      popupTitle: packageTitle
    }
    this.partyService.packageListPopup = this.dialog.open(CustomPopupComponent, {
      disableClose: true,
      width: '90%',
      height: '90%',
      data: {
        componentDetails,
        from: ComponentTypes.bookedPackages,
        back: true,
        standalone: true,
        showFooter: false
      }
    });
  }
  loadAllBookings() {
    this.ls.isLoading.next(true)
    let bookings = this.abf.formAllBookedActivities(this._settings, this._layout, this.cs.headerDate, this.partyService.Parties$.value, this.partyService.StandbyParties$.value, true);
    this.allBookings = bookings;
    this.partyService.allLessonBookings = this.allBookings.ByClass;
    this.setStaffName();
    this.setViewBy({ value: this.viewByConfig[0].value });
    //this.setViewByType({ value: this.viewByConfig[0].value });
    this.intialized ? this.calculateTimelinePosition() : this.currentTimeDisplay();
    this.intialized = true;
  //  this.getActivitiesByClasses(false, this.viewByConfig[0].value);
    this.initialLoad = false;
    this.ls.isLoading.next(false)
  }

  setStaffName() {
    if (this.allBookings) {
      this.allBookings.ByStaff.forEach(booking => {
        booking.Name = booking.FirstName + ' ' + (booking.LastName || '');
      });
    }
  }

  onScroll(event: Event) {
    if(!this.isSwitchAxis)
    this.lastIndex = Math.ceil(((event.target['scrollLeft'] + 200)/ this.columnWidth) + (window.innerWidth / this.columnWidth)) + 10;
    // else
    // this.lastIndex = Math.ceil(((event.target['scrollTop'] + 200)/ this.columnWidth) + (window.innerHeight / this.columnWidth)) + 10;
  }

  getAllOpenBookingSlots(bufferTime = 0, occupiedSlots?) {
    let openBookingSlots = [];
    let shiftArray = _.cloneDeep(this._settings.OpenHours);
    let date = this.CurrentDateSelected;
    let dayOfWeek = moment.isMoment(date) ? date.day() : date.getDay();
    let allShifts = shiftArray.filter(shift => shift.DayOfWeek == dayOfWeek);
    const Slotval = this._settings.General.TimeSlotUnitInMinutes;

    if (allShifts && allShifts.length) {
        allShifts.sort((a: any, b: any) => {
            let _a: any = new Date(a.EffectiveRange.Start);
            let _b: any = new Date(b.EffectiveRange.Start);
            return _a - _b;
        })

        let Startval = allShifts[0].EffectiveRange.Start.toString().split('Z')[0];
        Startval = this.formatDate(date) + 'T' + Startval.split('T')[1];
        let endDate = new Date(this.CurrentDateSelected);
        if (new Date(allShifts[allShifts.length - 1].EffectiveRange.End).getDate() > new Date(allShifts[0].EffectiveRange.Start).getDate()) {
            endDate.setDate(date.getDate() + 1)
        }
        let Endvalue = allShifts[allShifts.length - 1].EffectiveRange.End.toString().split('Z')[0];
        const Endval = new Date(this.formatDate(endDate) + 'T' + Endvalue.split('T')[1]);
        let Startloop = new Date(Startval);
        while (Startloop <= Endval) {
            let [slot, startloop] = this.getOpenSlotDataObj(Startloop, Slotval);
            Startloop = startloop;
            let availableSlot = true;
            if(slot.gridColumnStart == slot.gridColumnEnd - 1){
              if (occupiedSlots[slot.gridColumnStart] && occupiedSlots[slot.gridColumnEnd]) {
                availableSlot = false;
              }
            }else{
              for (let idx = slot.gridColumnStart + 1; idx < slot.gridColumnEnd; idx++) {
                if (occupiedSlots[idx]) {
                    availableSlot = false;
                    break;
                }
            }
          }
            if (availableSlot) {
                openBookingSlots.push(slot);
            }

            //Buffer slots
            if (Startloop <= Endval && bufferTime) {
                let [slot, startloop] = this.getOpenSlotDataObj(Startloop, bufferTime, true);
                Startloop = startloop;
                openBookingSlots.push(slot);
            }
        }

        openBookingSlots.sort((a: any, b: any) => {
            let _a: any = new Date(a.startTime);
            let _b: any = new Date(b.startTime);
            return _a - _b;
        });
    }

    return openBookingSlots
}


    getOpenSlotDataObj(Startloop, Slotval, bufferSlot?) {
        let slotObj;
        const tempTimeSlot = Startloop;
        const slot: SlotDTO = <SlotDTO>{};
        let slotTime = Utilities.Date(Startloop);
        slot.DateTime = slotTime.format('YYYY-MM-DDTHH:mm:ss');
        let endTime = new Date(slot.DateTime);        
        endTime.setMinutes(endTime.getMinutes() + Slotval);
        let gridColumnStart = this.getSlotIndex([slotTime.hours(), +slotTime.minutes()], this.timeArrayGenerated) + 1;
        let gridColumnEnd = this.getSlotIndex([endTime.getHours(), +endTime.getMinutes()], this.timeArrayGenerated) + 1;
        if (gridColumnEnd < gridColumnStart) {
          /** When Per slot time configuration is updated after some booking created */
          // let numOfSlot = ((endTime.getHours()*60) + endTime.getMinutes() - (slotTime.hours()*60) + slotTime.minutes()) / 5;
          // gridColumnEnd = gridColumnStart + numOfSlot;
          gridColumnEnd = -1;
        }
        slotObj = { StartTime: slotTime.format('HH:mm:ss'), EndTime: moment(endTime).format('HH:mm:ss'), bufferSlot, openBookingSlot: true, gridColumnStart, gridColumnEnd, DateTime: slot.DateTime };
        const newMinites = new Date(tempTimeSlot).getMinutes() + Slotval;
        const newDate = tempTimeSlot.setMinutes(newMinites);
        Startloop = new Date(newDate);

        return [slotObj, Startloop]
    }

  calculateTimelinePosition(scrollToPosition?) {
    this.ats.oneMinutePixels = (this.columnWidth / 1);
    let widthOffixedColumn = 200;
    let date = this.restaurantDateTime.getDate();
    let hours = this.restaurantDateTime.getHours();
    let minutes = this.restaurantDateTime.getMinutes();
    let index = this.getSlotIndexTimeInicator(date, hours, minutes, true);

    index = index != this.timeArrayGenerated.length ? index : this.timeArrayGenerated.length - 1;
    if (!this.isInTimeRange(this.restaurantDateTime) || ((this.getTimeDifferenceInMinutes(new Date(this.timeArrayGenerated[index]), this.restaurantDateTime) > 1)
      || new Date(this.timeArrayGenerated[index]).getHours() != hours
      || index < 0)) {
      this.currentTimeStart = -1;
      this.buttonControls[0].disbaledproperity = true;
    } else {
      minutes = minutes > new Date(this.timeArrayGenerated[index]).getMinutes() ? minutes - new Date(this.timeArrayGenerated[index]).getMinutes() : 0;
      this.currentTimeStart = ((minutes + (index * 1)) * this.ats.oneMinutePixels) + widthOffixedColumn;
      if (scrollToPosition && this.viewPort) {
        var y = window.scrollY;
        if(!this.isSwitchAxis)
        this.viewPort.scrollTo({ left: this.currentTimeStart - 400, behavior: 'auto' });
        else 
        this.viewPort.scrollTo({ top: this.currentTimeStart - 400, behavior: 'auto' });
      }
      this.buttonControls[0].disbaledproperity = false;
    }
  }

  scrollToSlotSelectedPosition(time) {
  }

  isHeaderToday() {
    return (this.restaurantDateTime.getDate() === this.cs.headerDate.getDate()
      && this.restaurantDateTime.getMonth() === this.cs.headerDate.getMonth()
      && this.restaurantDateTime.getFullYear() === this.cs.headerDate.getFullYear());
  }

  isInTimeRange(time) {
    const start = new Date(this.timeArrayGenerated[0]);
    const end = new Date(this.timeArrayGenerated[this.timeArrayGenerated.length - 1]);
    end.setMinutes(end.getMinutes() + 30);
    return time >= start && time <= end;
  }

  isInShiftTimeRange(): boolean {
    return false;
  }

  ngAfterViewInit() {
    this.subscriptions.add(this.searchConfigForm.form.valueChanges.pipe(debounceTime(500),
    distinctUntilChanged()).subscribe((val: any) => {
      this.selectedActivityDetail = null;
      if (val.searchText != '') {
        let searchString = val.searchText;
        this.searchText = searchString;
      }
      if (this.searchText && val.searchText == '') {
        this.searchText = val.searchText;
      }
    }));


    this.subscriptions.add(this.partyService.activityBlockingChange$.subscribe(parties => {
      if (!this.initialLoad) {
       this.generateData();
      }
    }));
    this.subscriptions.add(this.partyService.Parties$.subscribe(parties => {
      if (!this.initialLoad) {
        this.closeActivityDetail();
        this.processAllParties();
      }
    }));
    this.subscriptions.add(this.partyService.CancelledReservations$.subscribe(parties => {
      if (!this.initialLoad) {
        this.processAllParties();
      }
    }));
    this.subscriptions.add(this.partyService.StandbyParties$.subscribe(parties => {
      if (!this.initialLoad) {
        this.processAllParties();
      }
    }));
    this.subscriptions.add(this.partyService.RemovedParty$.subscribe(reservationData => {
      if(reservationData?.length) {
        reservationData?.forEach(element => {
          this.removeFromOldSlot(element);
        });
        this.setViewBy({value: this.viewByConfig[0].value});
      }
    }));

    this.subscriptions.add(this.viewByForm.form.valueChanges.subscribe((val: any) => {
      this.selectedActivityDetail = null;
      this.partyService.SelectedTimelineView = val.viewBy;
    }));

    this.subscriptions.add(this.cs.activitiesData.subscribe(activities => {
      if (activities) {
        let bookings = _.cloneDeep(activities);
        this.allBookings = { ...bookings };
        this.cd.detectChanges();
        this.setViewBy({ value: this.partyService.SelectedTimelineView });
        if (this.viewByConfig) {
          this.viewByConfig[0].value = this.partyService.SelectedTimelineView;
        }
      }
    }))
    this.subscriptions.add(this.appService.templateSelectionChange.subscribe(data => {
      if (data && this.appService.showCreateActivity) {
        this.appService.showCreateActivity = false;
        this.createActivity(data?.id);
        }
      }))
    this.calcTimelineBarPosition();
  }

  removeOpenBookingFromLocationView(reservationData){
    
  }

  calcTimelineBarPosition() {
    setTimeout(() => {
      this.calculateTimelinePosition(true);
    })
  }

  processAllParties(){
    
    /* to avoid count fliker in UI to default and actual booking count
    used component variales */

    this.allWeekPrivateStandByBookings = {} as any;
    this.allWeekActivitySessions = {} as any;

    let parties = this.partyService.Parties$.value;
    let cancelledParties = this.partyService.CancelledReservations$.value;
    parties= [...parties, ...cancelledParties]
    let standbyParties = this.partyService.StandbyParties$.value;
    //processing confirmed parties
    parties.forEach(reseravtion => {
        this.setReservationOnGrid(reseravtion);
    });

    this.setAddonBookings();


    //processing standby parties
    standbyParties.forEach(reseravtion => {
      reseravtion.partyStartTime = reseravtion.ReservedFor || reseravtion.SeatingTime;
    });

    standbyParties = _.sortBy(standbyParties, 'partyStartTime');

    standbyParties.forEach(reseravtion => {
          this.setReservationOnGrid(reseravtion);
    });

    // this.setPrivateBookingSessions();

    /** Setting Calculated values to service variable to reflect in UI */
      this.partyService.allWeekActivitySessions = this.allWeekActivitySessions;
    this.partyService.allWeekPrivateStandByBookings = this.allWeekPrivateStandByBookings;
    
    this.bookedSessionsFilterHandler();
  }

  setAddonBookings(){
    this.dataList.ByClass?.forEach(classDetail => {
      classDetail.Sessions.forEach(session => {
        if(!this.allWeekActivitySessions[session.uniqId]){
          this.allWeekActivitySessions[session.uniqId] =  this.getDataBySessionId(null, session.ActivitySessionId, session);
        }
        if (session.SpecialMealId && typeof session.StartTime == 'string') {
          let [shr, smin, ssec] = session.StartTime.split(":");
          let [ehr, emin, esec] = session.EndTime.split(":");
          var StartTimeObj = this.getDate(+shr, +smin, +ssec);
          var EndTimeObj = this.getDate(+ehr, +emin, +esec);
        }
        this.allWeekActivitySessions[session.uniqId].addonblocked = this.partyService.getAddonBlocker(this.CurrentDateSelected, StartTimeObj, EndTimeObj, false, classDetail?.Id);
      });
    });
  }

  getDate(hr, min, sec) {
    let date = new Date(this.CurrentDateSelected.getTime());
    date.setHours(hr);
    date.setMinutes(min);
    date.setSeconds(sec);
    return date;
  }

  setPrivateBookingSessions(){
    let bookings =  Object.values(this.allWeekPrivateStandByBookings);
    if(!bookings.length){
      return;
    }
    bookings.forEach((classData: any) => {     
      this.processPrivateClassData(classData)
    });
  }

  processPrivateClassData(classData){
    classData.parties.forEach(groupData => {
      groupData.isPrivateLessonBooking = true;
      groupData.SlotHeader = {} as any;
      let header = groupData.partiesList.reduce((header, {SlotHeader}, index) =>  header + (index ? ', ': '') + SlotHeader[ViewBy.Lessons], '');
      groupData.SlotHeader[ViewBy.Instructor] = header;
      groupData.SlotHeader[ViewBy.Lessons] = header;
      groupData.SlotHeader[ViewBy.location] = header;

      groupData.SlotSubHeader = {} as any;
      let subheader = this.translateService.instant('PrivateActivityBookings');
      groupData.SlotSubHeader[ViewBy.Instructor] = subheader;
      groupData.SlotSubHeader[ViewBy.Lessons] = subheader;
      groupData.SlotSubHeader[ViewBy.location] = subheader;
      groupData.Duration = (moment(groupData.DepartureTime).diff(moment(groupData.ReservedFor || groupData.SeatingTime || groupData.WishedTime), 'minutes'));

      groupData.Id = groupData.partiesList.map(({Id}) => Id).join(",")
      groupData.CategoryId = classData.CategoryId || -1;
      groupData.SubcategoryId = classData.SubcategoryId || -1;
      this.updateInClassView(groupData);

      //For Instructor and Location view
      groupData.partiesList.forEach(party => {  
          let partySplitData = _.cloneDeep(groupData);
          partySplitData.CategoryId = groupData.CategoryId;
          partySplitData.StaffIds = [party.ServerId];
          partySplitData.TableIds = party.TableIds;
          partySplitData.TableNames = party.TableNames;
          partySplitData.partiesList = [party];
          partySplitData.SeatingTime = party.ReservedFor || party.SeatingTime,
          partySplitData.DepartureTime = party.DepartureTime,
          partySplitData.SlotHeader[ViewBy.location] = party.SlotHeader[ViewBy.location];
          if(party.Type === 0){
            this.updateInLocationView(partySplitData);
          }
      });
    });

    classData.staffParties.forEach(groupData => {
      groupData.isPrivateLessonBooking = true;
      groupData.SlotHeader = {} as any;
      let header = groupData.partiesList.reduce((header, {SlotHeader}, index) =>  header + (index ? ', ': '') + SlotHeader[ViewBy.Instructor], '');
      groupData.SlotHeader[ViewBy.Instructor] = header;

      groupData.SlotSubHeader = {} as any;
      let subheader = this.translateService.instant('PrivateActivityBookings');
      groupData.SlotSubHeader[ViewBy.Instructor] = subheader;
      groupData.Duration = (moment(groupData.DepartureTime).diff(moment(groupData.ReservedFor || groupData.SeatingTime || groupData.WishedTime), 'minutes'));

      groupData.Id = groupData.partiesList.map(({Id}) => Id).join(",")
      groupData.CategoryId = classData.CategoryId || -1;
      groupData.SubcategoryId = classData.SubcategoryId || -1;
      this.updateInInstructorView(groupData);

    });
  }

  filterActivitiesByDayofweek(activities, dayOfWeek): any[] {
    activities.forEach(activity => {
      activity.Sessions = activity.Sessions.filter(session => session.Dayofweek === dayOfWeek)
      if (activity.bookingsData) {
        activity.bookingsData = activity.bookingsData.filter(session => session.Dayofweek === dayOfWeek)
      }
    });
    return activities.filter(activity => activity.Sessions && activity.Sessions.length);
  }

  getActivitiesByDayofWeeks(list, dayOfWeek) {
    return list.map(item => {
      item.ActivityBookings = item.ActivityBookings && item.ActivityBookings.map(activityBooking => {
        if (activityBooking && activityBooking.Sessions) {
          activityBooking.Sessions = activityBooking.Sessions.filter(session => session.Dayofweek === dayOfWeek);
        }
        return activityBooking;
      })
      return item;
    })
  }

  trackByFn(index, item) {
    return item;
  }

  instructorData(index, item) {
    return item.Id;
  }

  public get inverseOfTranslation(): string {
    if (!this.viewPort || !this.viewPort["_renderedContentOffset"]) {
      return "-0px";
    }
    let offset = this.viewPort["_renderedContentOffset"];
    return `-${offset}px`;
  }

  getTimeDifferenceInMinutes(date_1, date_2) {
    let milliseconds = date_1 - date_2;
    let minutes = milliseconds / (60 * 1000);
    return minutes > 0 ? Math.round(minutes) : -1 * Math.round(minutes);
  }

  activitySelected([activity, objectDetail, weeklyViewDate]) {
    this.selectedActivityDetail = activity;
    this.selectedClass = null;
    this.selectedOpenBooking = null;
    this.selectedPrivateLessonBooking = null;

    if (activity.isOpenBooking) {
      setTimeout(() => {
        this.selectedOpenBooking = activity;
        this.openBookingDetailView(activity)
      }, 100)
      // this.appService.showDashboard = true;
      this.partyService.selectedParty$.next(activity);

    }
    else if (activity.isPrivateLessonBooking) {
      activity.partiesList = [_.cloneDeep(activity)]
      this.partyService.selectedParty$.next(activity);
      this.openView(activity.SpecialMealId, activity.StartTime, activity.EndTime, objectDetail, activity,weeklyViewDate);
      //this.activityBookingClicked(activity.SpecialMealId, activity.StartTime, activity.EndTime, objectDetail, activity, weeklyViewDate);

    }
    else {
      this.partyService.selectedParty$.next(activity);
      this.openView(activity.SpecialMealId, activity.StartTime, activity.EndTime, objectDetail, activity,weeklyViewDate);
    //  this.activityBookingClicked(activity.SpecialMealId, activity.StartTime, activity.EndTime, objectDetail, activity, weeklyViewDate);
    }
  }
  openView(...args){
     if (args[4].isPrivateLessonBooking){
       this.classDetailView.apply(this,[...args, PrivateLessonBookingDetailComponent]);
    }
    else{
      this.classDetailView.apply(this,[...args, ClassDetailsComponent]); 
    }
  }
  openBookingDetailView(data){
    this.partyService.updateOpenBookingList$.next(null);
    const popUpMessage = {
      detail: data,
      dialogTitle: this.translateService.instant('OpenBooking'),
      showAlert: false,
      bookingDetail:this.selectedOpenBooking
    };
    const componentDetails: ComponentDetails = Utilities.setComponentDetails(OpenBookingDetailComponent, 'small', 'action', popUpMessage,
      popUpMessage.dialogTitle);
   this.partyService.bookingDetailViewPopupDialogRef =  this.openCustomPopup(componentDetails, ComponentTypes.reservation, '90%', '90%', true, '', '',
      '', false, '100vw', 'class-detail');
  }
  classDetailView(id, startTime, endTime, objectDetail, activity , weeklyViewDate, component){
    let selectedClass = this.getSelectedClassDetail(id, startTime, endTime, objectDetail, activity,weeklyViewDate);
    const popUpMessage = {
      detail: selectedClass,
      dialogTitle: selectedClass.ClassType == ClassType.Duration ? this.translateService.instant('PrivateBooking') : this.translateService.instant('addActivityBooking'),
      showAlert: false,
      classDetail:selectedClass,
      selectedViewId:this.selectedViewId
    };
    const componentDetails: ComponentDetails = Utilities.setComponentDetails(component, 'small', 'action', popUpMessage,
      popUpMessage.dialogTitle);
      this.partyService.bookingDetailViewPopupDialogRef =this.openCustomPopup(componentDetails, ComponentTypes.reservation, '90%', '90%', true, '', '',
      '', false, '100vw', 'class-detail');
  }
  getSelectedClassDetail(id, startTime, endTime, objectDetail, activity,weeklyViewDate): any{
    var selectedClass = _.cloneDeep(this.allBookings.ByClass.find(({ Id: classId }) => classId === id));
    const activityDetail = this._settings.SpecialMeals.find(itm => itm.Id === activity.SpecialMealId);
    selectedClass.available = activity.Available;
    selectedClass.booked = activity.Booked;
    selectedClass.SessionId = activity.SessionId;
    selectedClass.SessionGroupId = activity.SessionGroupId;
    selectedClass.ClassType = activityDetail.ClassType;
    selectedClass.ClassStartDate = activityDetail.StartDate;
    selectedClass.ClassEndDate = moment(activityDetail.EndDate).format('YYYY-MM-DD') + "T23:59:59.999";
    selectedClass.startTime = typeof startTime === 'string' ? new Date('1970-01-01 ' + startTime) : startTime;
    selectedClass.endTime = typeof endTime === 'string' ? new Date('1970-01-01 ' + endTime) : endTime;
    selectedClass.weeklyViewDate = weeklyViewDate;
    selectedClass.IsForStandbyReservations = activityDetail.IsForStandbyReservations;
    selectedClass.StatusCode = activity?.StatusCode;
    let standAloneTables =  this._layout.FloorPlans.filter(x => x.IsDefault == true)[0].StandaloneTables;
    const time = moment(startTime).format("hh:mm:ss");
    if (this.selectedViewId === ViewBy.location) {
      selectedClass.location = objectDetail.Name;
      selectedClass.locationId = objectDetail.Id;
      // selectedClass.instructor = activity.SlotHeader;
    } else if(activity.isPrivateLessonBooking){
      selectedClass.location = activity.TableNames?.length ? activity.TableNames[0] : null;
      selectedClass.locationId = activity.TableIds?.length ? activity.TableIds[0] : null;
      selectedClass.instructorId = activity.ServerId;
    } else if (this.selectedViewId === ViewBy.Instructor) {
      selectedClass.instructor = objectDetail.Name;
      selectedClass.instructorId = objectDetail.Id;
      selectedClass.location = activity.SlotSubheader;
      selectedClass.locationId = standAloneTables.find(x => x.Name == activity.SlotSubheader)?.Id;
      delete selectedClass.location;
      // selectedClass.location = activity.SlotSubheader;
    } else if (this.selectedViewId === ViewBy.Lessons) {
        selectedClass.location = activity?.allLocationNames;
        let loc = standAloneTables.find(x => x.Id == activity?.allLocations?.[0]?.StandaloneTableId);
        if(!loc){
          loc = standAloneTables.find(x => x.Id == activity?.Locations?.[0]?.Id);
        }
        if(!loc){
          loc = standAloneTables.find(x => x.Name == activity.LocationAddnlDetail);
        }        
        selectedClass.locationId = loc ? loc.Id : (standAloneTables[0] && standAloneTables[0].Id);
        selectedClass.instructorId = activity.Staffs[0]?.Id;
    }
    if (activity.isPrivateLessonBooking) {
      this.selectedOpenBooking = null;
      selectedClass.Bookings = [activity];
      let isMultipleLessons = [...new Set(selectedClass.Bookings[0]?.partiesList?.map(p => p.SpecialMealId))]?.length > 1;
      selectedClass.DisplayName = isMultipleLessons ? this.ts.instant('privateLessonBookings') : selectedClass.Name;
      if (activity.StaffIds.length > 0 || activity.ServerId)
        selectedClass.instructors = this._settings.Servers.find(s => s.Id === activity.StaffIds[0] || activity.ServerId);
      else
        selectedClass.instructors = activity.contacts;
    }
    else{
      selectedClass.instructors = activity.contacts;
    }      
    return selectedClass
  }
  actions(selectedAction: any) {
    const actions = globals.actionsIcons;
    switch (actions[selectedAction.index]) {
      case actions[0]:
        this.dashboardFunctions.createOrEditOpenBooking(true, this.partyService.selectedParty$.value);
        break;
      case actions[1]:
        break;
      case actions[2]:
        break;
      case actions[3]:
        break;
      case actions[4]:
        break;
    }
  }

  partyUnselectedEventHander() {
    this.appService.showDashboard = false;
  }

  setReservationOnGrid(reservation) {
    let specialMeal = this._settings.SpecialMeals.find(s => s.Id == reservation.SpecialMealId);
    if (specialMeal && specialMeal.ClassType === PricingBy.Session && reservation.BookedSessions && reservation.SpecialMealId) {
      specialMeal.StandbyBooked = 0;
          reservation.BookedSessions?.forEach(bookedSession => {
              if ( moment(format(bookedSession.BookedDate, 'MM/DD/YYYY')).isBetween(format(this.partyService.weekStartDate, 'MM/DD/YYYY'), format(this.partyService.weekEndDate, 'MM/DD/YYYY'), undefined, '[]')
            ) {
              if (!this.allWeekActivitySessions[bookedSession.ActivitySessionId]) {
                      this.allWeekActivitySessions[bookedSession.ActivitySessionId] = this.getDataBySessionId(specialMeal, bookedSession.ActivitySessionId)
                  }

                  if(!this.allWeekActivitySessions[bookedSession.ActivitySessionId].parties){
                    this.allWeekActivitySessions[bookedSession.ActivitySessionId].parties = [];
              }

                  let partyIndex = this.allWeekActivitySessions[bookedSession.ActivitySessionId].parties.findIndex(({Id}) => Id === reservation.Id);
                  if(partyIndex === -1){
                    this.allWeekActivitySessions[bookedSession.ActivitySessionId].parties.push(reservation);
                  } else{
                    this.allWeekActivitySessions[bookedSession.ActivitySessionId].parties[partyIndex] = reservation;
              }
                  let bookedSessions = this.allWeekActivitySessions[bookedSession.ActivitySessionId].parties.filter(party =>party.BookedSessions?.length && (party.State == PartyState.Pending || party.State == PartyState.Seated  || party.State == PartyState.Left)).map(p=>p.BookedSessions).flat(); 
                  let numberOfCancelled  = this.allWeekActivitySessions[bookedSession.ActivitySessionId].parties.filter(party =>party.BookedSessions?.length && party.State == PartyState.Cancelled)?.length; 
                  let numberOfParties  = this.allWeekActivitySessions[bookedSession.ActivitySessionId].parties.filter(party =>party.BookedSessions?.length && party.State !== PartyState.Cancelled && party.State !== PartyState.CheckedOut && party.State !== PartyState.Left)?.length;
                  let numberOfBookings = 0;
                  let numberOfOverBooked = 0;
      
                  bookedSessions?.forEach(session => {
                    if(session.ActivitySessionId == bookedSession.ActivitySessionId && 
                      moment(format(session.BookedDate, 'MM/DD/YYYY')).isBetween(format(this.partyService.weekStartDate, 'MM/DD/YYYY'), format(this.partyService.weekEndDate, 'MM/DD/YYYY'), undefined, '[]') && session.SessionState !== PartyState.Cancelled && session.SessionState !== PartyState.CheckedOut && session.SessionState !== PartyState.Left && session.SessionType == PartyType.Reservation){
                      numberOfBookings += session.BookingSize;
                      numberOfOverBooked += session.OverBooked;
                    }
                  });
                  const MaxActivityStrength = this.allWeekActivitySessions[bookedSession.ActivitySessionId]?.SessionCustomization?.MaxActivityStrength 
                    ? this.allWeekActivitySessions[bookedSession.ActivitySessionId]?.SessionCustomization.MaxActivityStrength : specialMeal.MaxActivityStrength;
                  this.allWeekActivitySessions[bookedSession.ActivitySessionId].MaxActivityStrength = MaxActivityStrength;
                  this.allWeekActivitySessions[bookedSession.ActivitySessionId].Available = MaxActivityStrength >= numberOfBookings ? MaxActivityStrength - numberOfBookings : 0;
                  this.allWeekActivitySessions[bookedSession.ActivitySessionId].Booked = numberOfBookings;
                  this.allWeekActivitySessions[bookedSession.ActivitySessionId].OverBooked = numberOfOverBooked;
                  this.allWeekActivitySessions[bookedSession.ActivitySessionId].Cancelled = numberOfCancelled;
                  this.allWeekActivitySessions[bookedSession.ActivitySessionId].bookings = numberOfParties;

              if(reservation.Type == PartyType.StandBy && reservation.State !== PartyState.Cancelled)
                  this.allWeekActivitySessions[bookedSession.ActivitySessionId].StandbyBooked += reservation.Size;
              
              if (DueState.includes(reservation.PrepaymentState))
                this.allWeekActivitySessions[bookedSession.ActivitySessionId].hasDuePaymentBookings = true;
              if (PaidState.includes(reservation.PrepaymentState))
                this.allWeekActivitySessions[bookedSession.ActivitySessionId].hasPaidBookings = true;
              if (reservation.PrepaymentState == PartyPrepaymentState.PrepaymentRequired && reservation.State !== PartyState.Cancelled)
                this.allWeekActivitySessions[bookedSession.ActivitySessionId].hasUnpaidBookings = true;

              if (reservation.Contact?.IsVip) {
                      this.allWeekActivitySessions[bookedSession.ActivitySessionId].isVIP = true;
              }


            }

          });

    } else if (reservation.SpecialMealId) {
        
        // if(reservationdate === currentTimeText){
          if (specialMeal && specialMeal.LessonType === LessonType.PrivateLesson && (reservation.State == PartyState.Pending || reservation.State == PartyState.Seated)) {
            let reservationdate = moment(reservation.SeatingTime).format("DD/MMM/YYYY");
            let currentTimeText = moment(this.CurrentDateSelected).format("DD/MMM/YYYY");
            if(reservationdate === currentTimeText){
              let rowId = -1;
              if (this.selectedViewId === ViewBy.Lessons) {
                  rowId = reservation.SpecialMealId;
              } else if (this.selectedViewId === ViewBy.location) {
                  rowId = reservation.TableIds[0];
              } else if (this.selectedViewId === ViewBy.Instructor) {
                  rowId = reservation.ServerId;
              }
              reservation.isPrivateLessonBooking = true;
              reservation.SlotHeader = {} as any;
              let header = reservation.Contact.FirstName + (reservation.Contact.LastName ? ' ' + reservation.Contact.LastName :  '') + (reservation.Size > 1 ? ' +' + (reservation.Size - 1) : '');
              reservation.SlotHeader[ViewBy.Instructor] = header;
              reservation.SlotHeader[ViewBy.Lessons] = header;
              reservation.SlotHeader[ViewBy.location] = header;
  
              reservation.SlotSubHeader = {} as any;
              let subheader = this.translateService.instant('PrivateActivityBookings');;
              reservation.SlotSubHeader[ViewBy.Instructor] = subheader;
              reservation.SlotSubHeader[ViewBy.Lessons] = subheader;
              reservation.SlotSubHeader[ViewBy.location] = subheader;
              reservation.Duration = (moment(reservation.DepartureTime).diff(moment(reservation.ReservedFor || reservation.SeatingTime), 'minutes'));
  
              reservation.CategoryId = specialMeal.CategoryId || -1;
              reservation.SubcategoryId = specialMeal.SubcategoryId || -1;
  
              if(!this.allWeekPrivateStandByBookings['private_'+reservation.SpecialMealId]){
                this.allWeekPrivateStandByBookings['private_'+reservation.SpecialMealId] = {
                  Type: reservation.Type,
                  SpecialMealId: reservation.SpecialMealId,
                  CategoryId: specialMeal.CategoryId || -1,
                  SubcategoryId: specialMeal.SubcategoryId || -1,
                  parties : []
                };
              }
              // this.setPrivateGroups(this.allWeekPrivateStandByBookings['private_'+reservation.SpecialMealId], reservation);
  
              this.updateInClassView(reservation);
              if(reservation.Type !== PartyType.StandBy){
                this.updateInLocationView(reservation);
                this.updateInInstructorView(reservation);
              }
            }
            

          } else if (specialMeal && specialMeal.LessonType === LessonType.GroupLesson) {
            if (!this.allWeekActivitySessions["group_" + reservation.SessionGroupId]) {
                  this.allWeekActivitySessions["group_" + reservation.SessionGroupId] = { parties: [], Available: specialMeal.MaxActivityStrength, Booked: 0, StandbyBooked: 0 }
              }

              let partyIndex = this.allWeekActivitySessions["group_" + reservation.SessionGroupId].parties.findIndex(({Id}) => Id === reservation.Id);
              if(partyIndex === -1){
                this.allWeekActivitySessions["group_" + reservation.SessionGroupId].parties.push(reservation);
              } else{
                this.allWeekActivitySessions["group_" + reservation.SessionGroupId].parties[partyIndex] = reservation;
            }

              let reqParties = this.allWeekActivitySessions["group_" + reservation.SessionGroupId].parties.filter(party => party.Type === PartyType.Reservation && (party.State == PartyState.Pending || party.State == PartyState.Seated || party.State == PartyState.Left));
            let numberOfBookings = reqParties.reduce((prev, curr) => prev + (curr.Size || 0) - (curr.OverBooked || 0), 0);
            let overBooked = reqParties.reduce((prev, curr) => prev + (curr.OverBooked || 0), 0);

            const MaxActivityStrength = this.allWeekActivitySessions["group_" + reservation.SessionGroupId]?.SessionCustomization?.MaxActivityStrength
              ? this.allWeekActivitySessions["group_" + reservation.SessionGroupId]?.SessionCustomization.MaxActivityStrength : specialMeal.MaxActivityStrength;
              this.allWeekActivitySessions["group_" + reservation.SessionGroupId].MaxActivityStrength = MaxActivityStrength;
            this.allWeekActivitySessions["group_" + reservation.SessionGroupId].Available = MaxActivityStrength >= numberOfBookings ? MaxActivityStrength - numberOfBookings : 0;
            this.allWeekActivitySessions["group_" + reservation.SessionGroupId].Booked = numberOfBookings;
            this.allWeekActivitySessions["group_" + reservation.SessionGroupId].OverBooked = overBooked;

            if(reservation.Type == PartyType.StandBy && reservation.State !== PartyState.Cancelled)
              this.allWeekActivitySessions["group_" + reservation.SessionGroupId].StandbyBooked += reservation.Size;

            if (DueState.includes(reservation.PrepaymentState))
              this.allWeekActivitySessions["group_" + reservation.SessionGroupId].hasDuePaymentBookings = true;
            if (PaidState.includes(reservation.PrepaymentState))
              this.allWeekActivitySessions["group_" + reservation.SessionGroupId].hasPaidBookings = true;
            if (reservation.PrepaymentState == PartyPrepaymentState.PrepaymentRequired && reservation.State !== PartyState.Cancelled)
              this.allWeekActivitySessions["group_" + reservation.SessionGroupId].hasUnpaidBookings = true;

            if (reservation.Contact?.IsVip) {
                this.allWeekActivitySessions["group_" + reservation.SessionGroupId].isVIP = true;
            }
          }
        // }

    } else if (!reservation.SpecialMealId && (reservation.State == PartyState.Pending || reservation.State == PartyState.Seated)) {
      let reservationdate = moment(reservation.ReservedFor || reservation.SeatingTime).format("DD/MMM/YYYY");
      let currentTimeText = moment(this.CurrentDateSelected).format("DD/MMM/YYYY");
        if(reservationdate === currentTimeText){
          reservation.SlotHeader = {} as any;
          reservation.SlotHeader[ViewBy.location] = reservation.Contact.FirstName + ' ' +(reservation.Contact.LastName || '') + (reservation.Size > 1 ? ' +' + (reservation.Size - 1) : '');

          reservation.SlotSubHeader = {} as any;
          reservation.SlotSubHeader[ViewBy.location] = this.translateService.instant('openBookings');
          reservation.isOpenBooking = true;          

          if(reservation.Type == PartyType.Reservation){
            reservation.Duration = (moment(reservation.DepartureTime).diff(moment(reservation.ReservedFor), 'minutes'));
            this.updateInLocationView(reservation);
          }
          else if(reservation.Type == PartyType.StandBy)
          {
            reservation.Duration = (moment(reservation.DepartureTime).diff(moment(reservation.WishedTime), 'minutes'));
          }
        }
    }
    else {
      this.updateInstructorScheduleView(reservation);
    }

}

getDataBySessionId(specialMeal, sessionId, session?) {
  if (specialMeal || session) {
    let sessionObj = session;
    if(!session){
      sessionObj = specialMeal.ActivitySessions.find(({ ActivitySessionId }) => ActivitySessionId === sessionId);
    }
    return {
        timeDetail: `${moment(new Date("0001-01-01T" + sessionObj?.StartTime)).format('LT')} - ${moment(new Date("0001-01-01T" + sessionObj?.EndTime)).format('LT')}`,
        className: '',
        Available: session ? session?.MaxActivityStrength : (sessionObj?.MaxActivityStrength || specialMeal?.MaxActivityStrength),
        Booked: 0,
        parties: [],
        StandbyBooked: 0,
        ...sessionObj
    };
  }
  return specialMeal;
}

  updateInClassView(reservationData) {
    let activityRow = this.dataList.ByClass.find(({ Id }) => +Id === reservationData.SpecialMealId);
    if (activityRow) {
        if (!activityRow.occupiedSlots) {
            activityRow.occupiedSlots = {}
        }
        reservationData.StartTime = new Date(reservationData.SeatingTime || reservationData.WishedTime);
        reservationData.EndTime = new Date(reservationData.DepartureTime);
        let startIndex = this.getSlotIndex([reservationData.StartTime.getHours(), reservationData.StartTime.getMinutes()], this.timeArrayGenerated) + 1;
        let endIndex = this.getSlotIndex([reservationData.EndTime.getHours(), reservationData.EndTime.getMinutes()], this.timeArrayGenerated) + 1;

        for (let idx = startIndex; idx <= endIndex; idx++) {
            activityRow.occupiedSlots[idx] = true;
        }

        reservationData.gridColumnStart = startIndex;
        reservationData.gridColumnEnd = endIndex;


        //For row data
        if(reservationData.isPrivateLessonBooking){
          let _reservationDataIndex = activityRow.OpenPrivateReservations.findIndex(({Id}) => reservationData.Id === Id)
          if(_reservationDataIndex != -1){
            activityRow.OpenPrivateReservations[_reservationDataIndex] = reservationData;
          }else{
            activityRow.OpenPrivateReservations.push(reservationData);
          }
        }else{
          activityRow.OpenPrivateReservations.push(reservationData);
        }

        activityRow = {...activityRow};

    }
}

removeFromOldSlot(reservationData){
  let oldObjData = reservationData.oldObj && Object.entries(reservationData.oldObj).length ? reservationData.oldObj : reservationData;
  let oldLocationRow = oldObjData.TableIds ? this.dataList.ByLocation.find(({ Id }) => +Id === oldObjData.TableIds[0]) : this.dataList.ByLocation.find(({ Id }) => +Id === reservationData.TableIds[0]);
  let seatingTime = oldObjData?.SeatingTime ? oldObjData.SeatingTime : (reservationData.ReservedFor || reservationData.StartTime.getTime())
  oldObjData.StartTime = new Date(seatingTime);
  if(oldLocationRow){
    if(oldObjData.TableIds || reservationData.State === PartyState.Left || reservationData.State === PartyState.Cancelled){
      oldLocationRow.OpenPrivateReservations = oldLocationRow.OpenPrivateReservations.filter(({Id}) => Id != reservationData.Id);
    }
  }

  if(reservationData.isPrivateLessonBooking && (reservationData.State === PartyState.Left || reservationData.State === PartyState.Cancelled)){

    //Remove in Staff View
    let oldStaffRow = oldObjData.StaffIds ? this.dataList.ByInstructors.find(({ Id }) => +Id === oldObjData.StaffIds[0]) : this.dataList.ByInstructors.find(({ Id }) => +Id === reservationData.StaffIds[0]);
    if(oldStaffRow){
      oldStaffRow.OpenPrivateReservations = oldStaffRow.OpenPrivateReservations?.filter(({Id}) => Id != reservationData.Id)
    }

    //Activity View
    let activityRow = this.dataList.ByClass.find(classData => classData.Id === reservationData.SpecialMealId);
    if(activityRow){
      activityRow.OpenPrivateReservations = activityRow.OpenPrivateReservations?.filter(({Id}) => Id != reservationData.Id);
    }

    if(activityRow && this.allWeekPrivateStandByBookings['private_'+reservationData.SpecialMealId]){  
      this.allWeekPrivateStandByBookings['private_'+reservationData.SpecialMealId].parties = [];
      this.allWeekPrivateStandByBookings['private_'+reservationData.SpecialMealId].staffParties = [];
      activityRow.occupiedSlots = {};
    }
    
   let instructorRow = this.dataList.ByInstructors.find(instructorData => instructorData.Id === reservationData.ServerId);
    if(instructorRow && oldStaffRow){
      oldStaffRow.OpenPrivateReservations = oldStaffRow.OpenPrivateReservations?.filter(({Id}) => Id != reservationData.Id);
    }
    
  }

  reservationData.oldObj = null;
}

updateInLocationView(reservationData) {
    let locationRow = this.dataList.ByLocation.find(({ Id }) => +Id === reservationData.TableIds[0]);
    if(reservationData.oldObj){
      let oldLocationRow;
      if(reservationData.oldObj.TableIds && reservationData.oldObj.TableIds[0] === +locationRow.Id){
        oldLocationRow = locationRow;
      }else if(reservationData.oldObj.TableIds && reservationData.oldObj.TableIds[0]){
        oldLocationRow = this.dataList.ByLocation.find(({ Id }) => +Id === reservationData.oldObj.TableIds[0]);
      }else{
        oldLocationRow = locationRow;
      }
      if(oldLocationRow){
        if(reservationData.oldObj.TableIds){
          oldLocationRow.OpenPrivateReservations = oldLocationRow.OpenPrivateReservations.filter(({Id}) => Id != reservationData.Id)
        }
      }
      reservationData.oldObj = null;
    }
    if (locationRow) {
        if (!locationRow.occupiedSlots) {
            locationRow.occupiedSlots = {}
        }
        reservationData.StartTime = new Date(reservationData.ReservedFor || reservationData.SeatingTime);
        reservationData.EndTime = new Date(reservationData.DepartureTime);
        let startHours = reservationData.StartTime.getHours();
        let startMinutes = reservationData.StartTime.getMinutes();
        if(startMinutes % 1 != 0){
          startMinutes += (1 - (startMinutes % 1));
        }
        let startIndex = this.getSlotIndex([startHours, startMinutes], this.timeArrayGenerated) + 1;
        let endIndex = this.getSlotIndex([reservationData.EndTime.getHours(), reservationData.EndTime.getMinutes()], this.timeArrayGenerated) + 1;

        if(endIndex === 0 || endIndex < startIndex){
          endIndex = this.getSlotIndex([reservationData.EndTime.getHours(), +reservationData.EndTime.getMinutes() - 1], this.timeArrayGenerated) != -1 ? this.getSlotIndex([reservationData.EndTime.getHours(), +reservationData.EndTime.getMinutes() - 1], this.timeArrayGenerated) + 2 : (this.timeArrayGenerated.length - 1) + 2 ;
        }

      if (endIndex != 0) {
        for (let idx = startIndex; idx <= endIndex; idx++) {
          locationRow.occupiedSlots[idx] = true;
        }
      } else {
        let openLocationRow = locationRow.OpenPrivateReservations.filter(group => group.openBookingSlot);
        if(openLocationRow?.length) {
          let gridColumnRange = openLocationRow[0].gridColumnEnd - openLocationRow[0].gridColumnStart;
          for (let idx = startIndex; idx <= startIndex+gridColumnRange; idx++) {
            locationRow.occupiedSlots[idx] = true;
          }
        }
        else {
          locationRow.occupiedSlots[startIndex] = true;
        }
      }


      reservationData.gridColumnStart = startIndex;
      reservationData.gridColumnEnd = endIndex == (this.timeArrayGenerated - 1) + 2  ? -1 : endIndex;

      //For timeline row

      let _reservationDataIndex = locationRow.OpenPrivateReservations.findIndex(({Id}) => reservationData.Id === Id)
      if(_reservationDataIndex != -1){
        locationRow.OpenPrivateReservations[_reservationDataIndex] = reservationData;
      }else{
        locationRow.OpenPrivateReservations.push(reservationData);
      }

    }
}

updateInInstructorView(reservationData) {
    let instructorRow = this.dataList.ByInstructors.find(({ Id }) => +Id === reservationData.ServerId);
    if(!instructorRow && reservationData.StaffIds?.length) {
      instructorRow = this.dataList.ByInstructors.find(({ Id }) => +Id === reservationData.StaffIds[0]);
    }
    if (instructorRow) {
        if (!instructorRow.occupiedSlots) {
            instructorRow.occupiedSlots = {}
        }
        reservationData.StartTime = new Date(reservationData.ReservedFor || reservationData.SeatingTime);
        reservationData.EndTime = new Date(reservationData.DepartureTime);
        let startIndex = this.getSlotIndex([reservationData.StartTime.getHours(), reservationData.StartTime.getMinutes()], this.timeArrayGenerated) + 1;
        let endIndex = this.getSlotIndex([reservationData.EndTime.getHours(), reservationData.EndTime.getMinutes()], this.timeArrayGenerated) + 1;

        for (let idx = startIndex; idx <= endIndex; idx++) {
            instructorRow.occupiedSlots[idx] = true;
        }

        reservationData.gridColumnStart = startIndex;
        reservationData.gridColumnEnd = endIndex;


        //For timelnie Row
        let _reservationDataIndex = instructorRow.OpenPrivateReservations.findIndex(({Id}) => reservationData.Id === Id)
        if(_reservationDataIndex != -1){
          instructorRow.OpenPrivateReservations[_reservationDataIndex] = reservationData;
        }else{
          instructorRow.OpenPrivateReservations.push(reservationData);
        }

    }
}

  create(btnType: ButtonValue) {
    //this.isPartySelected = true;
    switch (btnType.actionName) {
      case ReservationStatus.Arrived:
        break;
      case ReservationStatus.ReleaseTable:
        this.subscription.add(this.partyService.unseatParty(this.partyService.selectedParty$.value.Id).subscribe((response) => {
          this.partyService.MoveCheck();
        }));
        break;
      case ReservationStatus.ClearTable:
        this.subscription.add(this.partyService.clearParty(this.partyService.selectedParty$.value.Id).subscribe(() => { }));
        break;
      case ReservationStatus.Reopen:
        break;
      case ReservationStatus.UndoNoShow:
        this.partyService.tryUndoNoShowReservation = true;
        this.dashboardFunctions.createOrEditReservation(true, this.partyService.selectedParty$.value);
        break;
      case ReservationStatus.SeatParty:
        this.dashboardFunctions.seatPartyWithConfimation(this.partyService.selectedParty$.value);
        break;
    }
  }
  viewPeriodChange({ value: view }) {
    this.closeActivityDetail();
    this.viewPeriod = view;
    this.abf.viewPeriodSelected = this.viewPeriod;
    if (view == 'week') {
      this.ats.viewPort = null;
      this.partyService.isTimelineInWeekView = true;
      this.appService.datePickerRangeChange.next(7);
    } else {
      this.partyService.isTimelineInWeekView = false;
      if (this.isInitialWeekChange) {
        this.isInitialWeekChange = false;
      }
      this.appService.datePickerRangeChange.next(1);
    }
  }

  loadReservations(startDate, endDate) {
    let currentPropertyId = Utilities.RestaurantId();
    this.subscriptions.add(this.partyService.getParties(startDate, endDate, GetReservationsOperationOptions.Full, currentPropertyId).subscribe((data) => {
      if (data) {
        const currentReservations: DateStateDTO[] = data.Payload.filter((reservations: DateStateDTO) => {
          const startdate = format(new Date(startDate), 'MM/DD/YYYY');
          const enddate = format(new Date(endDate), 'MM/DD/YYYY');
          const reservationDate = format(new Date(reservations.Date), 'MM/DD/YYYY');
          return (startdate <= reservationDate) && (reservationDate <= enddate);
        });
        this.dashboardFunctions.LoadPartiesForNonDinningProperties(currentReservations);
      }
    }));
  }

  addBookingToSlot([selectedSlotData, selectedslot, slotIndex, StartDate]) {

    if (this.selectedViewId === ViewBy.location) {
      let selectedData = {
        addOpenBooking: true,
        Id: selectedSlotData.Id,
        SlotIndex: slotIndex,
        SelectedSlot: moment(selectedslot).format('YYYY-MM-DDTHH:mm:ss'),
        StartDate: StartDate,
        EndDate: StartDate
      }
      this.dashboardFunctions.createOrEditOpenBooking(false, selectedData);
    } else if (this.selectedViewId === ViewBy.Instructor) {
      let activityAssociated = this.getLessonsFortheDay(selectedSlotData);
      if (activityAssociated.length == 0) {
        this.showLessonsNotMappedError();
      }
      else {
        let selectedData = {
          addOpenBooking: true,
          StaffIds: [selectedSlotData.Id],
          SlotIndex: slotIndex,
          SelectedSlot: selectedslot,
          ActivityBookings: selectedSlotData.ActivityBookings,
          instructorView: true,
          SpecialMealId: activityAssociated[0].Id
        }
        this.dashboardFunctions.createOrEditPrivateActivityBooking(false, selectedData);
      }
    }
  }

  getLessonsFortheDay(selectedSlotData) {
    var restaurantDate = Utilities.getRestaurantDateTime(this._settings.General.DaylightDelta)
    var date = this.appService._headerDate > restaurantDate ? this.appService._headerDate : restaurantDate
    date = new Date(date);
    date.setHours(0, 0, 0, 0)
    let activityList = this._settings.SpecialMeals.filter(s => s.LessonType == LessonType.PrivateLesson && s.StatusCode == Status.Approved && new Date(s.EndDate) >= date && new Date(s.StartDate) <= date);
    let activityAssociated = activityList.filter(server => {
      return !!(server.ActivitySessions.filter(session => !!(session.SessionStaffMappings.find(elm => elm.ServerId === selectedSlotData.Id))).length)
    });
    return activityAssociated;
  }

  showLessonsNotMappedError() {
    const popUpMessage = [{
      confirmationMessage: `${this.translateService.instant('lessonMapping')}`,
      dialogTitle: this.translateService.instant('alert'),
      showAlert: false
    }];
    const componentDetails: ComponentDetails = Utilities.setComponentDetails(ConfirmationPopupComponent, 'small', 'action', popUpMessage,
      popUpMessage[0].dialogTitle);
    this.openCustomPopup(componentDetails, ComponentTypes.reservation, '450px', 'auto', true, '', 'Ok',
      'Cancel', true);
  }

  clearSearch(event) {
    this.searchConfigForm.form.get('searchText').setValue('', { emitEvent: false });
    this.searchText = '';
    // this.instructors = this.addOpenBookingSlotsToTimeLine(_.cloneDeep(this.activitiesBooking));
    // this.viewPort.scrollToIndex(0);
  }

  changeEvent(name) {
    this.selectedActivityDetail = null;
    if(!this.masterSessionWidth)
    this.masterSessionWidth = this.ats.sessionWidth;
    switch (name) {
      case this._MESSAGES.labels.zoomIn: {
        if(!this.isSwitchAxis) {
        this.columnWidth += this.zoomIncreaseWidth;
        if (this.buttonControls[2].disbaledproperity) {
          this.buttonControls[2].disbaledproperity = false;
        }
        this.ats.oneMinutePixels = (this.columnWidth / 60) * 2;
        this.calculateTimelinePosition();
      }
      else {
        this.ats.sessionWidth += this.zoomIncreaseWidth;
        if (this.buttonControls[2].disbaledproperity) {
          this.buttonControls[2].disbaledproperity = false;
        }
      }
        break;
      }
      case this._MESSAGES.labels.zoomOut: {
        if(!this.isSwitchAxis) {
        // if ((this.columnWidth - this.zoomIncreaseWidth) >= 35) {
        if ((this.columnWidth - this.zoomIncreaseWidth) >= 7) {
          this.columnWidth -= this.zoomIncreaseWidth;
        }
        // if (this.columnWidth <= 35) {
        if (this.columnWidth <= 7) {
          this.buttonControls[2].disbaledproperity = true;
        }
        this.ats.oneMinutePixels = (this.columnWidth / 60) * 2;
        this.calculateTimelinePosition();
      }
      else {
        if((this.ats.sessionWidth - this.zoomIncreaseWidth) >= this.masterSessionWidth) {
          this.ats.sessionWidth -= this.zoomIncreaseWidth;
        }
        if(this.columnWidth <= this.masterSessionWidth) {
          this.buttonControls[2].disbaledproperity = true;
        }
      }
        break;
      }
      case this._MESSAGES.labels.currentTimeText: {
        // if(!this.isSwitchAxis) 
        this.currentTimeDisplay();
        break;
      }
      case this._MESSAGES.labels.reset: {
        if(!this.isSwitchAxis) {
        // this.columnWidth = 35;
        this.columnWidth = 7;
        if (this.buttonControls[2].disbaledproperity) {
          this.buttonControls[2].disbaledproperity = false;
        }
        this.ats.oneMinutePixels = (this.columnWidth / 60) * 2;
        this.calculateTimelinePosition();
      }
      else {
        this.ats.sessionWidth = this.cs.settings.value.General.DefaultTimelineColumnWidth || 300;
        this.masterSessionWidth = null;
      }
        break;
      }
      case this._MESSAGES.labels.createActivity:
        this.createActivity();
        break;
      case this._MESSAGES.labels.packages:
        this.bookedPackages();
        break;
      case this._MESSAGES.labels.standbylist:
        this.standByReservations();
        break;
    }
  }

  currentTimeDisplay() {
    this.calculateTimelinePosition(true);
  }

  getSlotIndex([classHour = 0, classMinutes = 0], timeArrayGenerated) {
    return timeArrayGenerated.findIndex((time) => time.minutes() === +classMinutes && time.hours() === +classHour);
}

getSlotIndexTimeInicator(classDate, classHour, classMinutes, isClassStart, isOpenorPrivateBooking = false) {
  for (let i = 0; i <= this.timeArrayGenerated.length; i++) {
    if (i < this.timeArrayGenerated.length) {
      //  let [date, hour, minutes] = moment(this.timeArrayGenerated[i]).format('DD:HH:mm').split(":");      
      let date = new Date(this.timeArrayGenerated[i]).getDate();
      let hour = new Date(this.timeArrayGenerated[i]).getHours();
      let minutes = new Date(this.timeArrayGenerated[i]).getMinutes();
      if (isOpenorPrivateBooking) {
        if (classHour == +hour && classMinutes == +minutes && classDate === +date) {
          return i;
        } else if (classHour == +hour && classMinutes < +minutes && classDate === +date) {
          return isClassStart ? i - 1 : i + 1;
        } else if (classHour < +hour && classDate === +date) {
          return isClassStart ? i - 1 : i;
        }
      }
      else {
        if (classHour == +hour && classMinutes == +minutes) {
          return i;
        } else if (classHour == +hour && classMinutes < +minutes) {
          return isClassStart ? i - 1 : i + 1;
        } else if (classHour < +hour && classDate === +date) {
          return isClassStart ? i - 1 : i;
        }
      }
    } else {
      return this.timeArrayGenerated.length;
    }
  }
}



  ngOnDestroy() {
    this.partyService.isTimelineInWeekView = false;
    if (this.subscriptions) { this.subscriptions.unsubscribe(); }
  }

  activityBookingClicked(id, startTime, endTime, objectDetail, activity, weeklyViewDate?) {
    var selectedClass = _.cloneDeep(this.allBookings.ByClass.find(({ Id: classId }) => classId === id));
    if(!selectedClass){
      selectedClass = _.cloneDeep(this.allBookings.ByClass.find(({ Id: classId }) => classId === id));
    }
    if(!selectedClass){
      console.error('[Timeline Main Comp] - (Activity Booking Clicked)', 'No class found in the list');
      return;
    }
    const activityDetail = this._settings.SpecialMeals.find(itm => itm.Id === activity.SpecialMealId);

    selectedClass.available = activity.Available;
    selectedClass.booked = activity.Booked;
    selectedClass.SessionId = activity.ActivitySessionId || activity.SessionId;
    selectedClass.SessionGroupId = activity.SessionGroupId;
    selectedClass.ClassType = activityDetail.ClassType;
    selectedClass.ClassStartDate = activityDetail.StartDate;
    selectedClass.MaxActivityStrength = activityDetail.MaxActivityStrength;
    selectedClass.ClassEndDate = moment(activityDetail.EndDate).format('YYYY-MM-DD') + "T23:59:59.999";
    selectedClass.startTime = typeof startTime === 'string' ? new Date('1970-01-01 ' + startTime) : startTime;
    selectedClass.endTime = typeof endTime === 'string' ? new Date('1970-01-01 ' + endTime) : endTime;
    selectedClass.IsForStandbyReservations = activityDetail.IsForStandbyReservations;
    selectedClass.weeklyViewDate = weeklyViewDate ? weeklyViewDate : null
    let standAloneTables = this._layout.FloorPlans.filter(x => x.IsDefault == true)[0].StandaloneTables;
    const time = moment(startTime).format("hh:mm:ss");
    if (this.selectedViewId === ViewBy.location) {
      selectedClass.location = objectDetail.Name;
      selectedClass.locationId = objectDetail.Id;
      // selectedClass.instructor = activity.SlotHeader;
    } else if (activity.isPrivateLessonBooking) {
      selectedClass.location = activity.TableNames[0];
      selectedClass.locationId = activity.TableIds[0];
      selectedClass.instructorId = activity.ServerId;
    } else if (this.selectedViewId === ViewBy.Instructor) {
      selectedClass.instructor = objectDetail.Name;
      selectedClass.instructorId = objectDetail.Id;
      selectedClass.location = activity.SlotSubheader;
      selectedClass.locationId = standAloneTables.find(x => x.Name == activity.SlotSubheader)?.Id;
      delete selectedClass.location;
      // selectedClass.location = activity.SlotSubheader;
    } else if (this.selectedViewId === ViewBy.Lessons) {
      if(!(selectedClass.locationId && selectedClass.location)){
        if(activity.SlotSubHeader){
          let locName = typeof activity.SlotSubHeader === 'string' ? activity.SlotSubHeader : (activity.SlotSubHeader[0])
          selectedClass.location = locName;
          let loc = standAloneTables.find(x => x.Name == locName);
          if (!loc) {
            loc = standAloneTables.find(x => x.Name == activity.LocationAddnlDetail);
          }
          selectedClass.locationId = loc ? loc.Id : (standAloneTables[0] && standAloneTables[0].Id);
        }
      }
      else {
        selectedClass.location = activity?.Locations[0]?.Name;
        selectedClass.locationId = activity?.Locations[0]?.Id;
      }
    }
    if (activity.isPrivateLessonBooking) {
      this.selectedOpenBooking = null;
      selectedClass.Bookings = [activity];
      let isMultipleLessons = [...new Set(selectedClass.Bookings[0]?.partiesList?.map(p => p.SpecialMealId))]?.length > 1;
      selectedClass.DisplayName = isMultipleLessons ? this.ts.instant('privateLessonBookings') : selectedClass.Name;
      if (activity.StaffIds.length > 0 || activity.ServerId)
        selectedClass.instructors = this._settings.Servers.find(s => s.Id === activity.StaffIds[0] || activity.ServerId);
      else
        selectedClass.instructors = activity.contacts;
    }
    else
      selectedClass.instructors = activity.contacts;
    setTimeout(() => {
      this.partyService.selectedParty$.next(selectedClass);
      if (activity.isPrivateLessonBooking)
        this.selectedPrivateLessonBooking = { ...selectedClass };
      else
        this.selectedClass = { ...selectedClass };
    }, 100)
  }

  closeActivityDetail() {
    this.selectedClass = null;
    this.selectedOpenBooking = null;
    this.selectedPrivateLessonBooking = null;
  }

  changeAxis() {
    this.isSwitchAxis = !this.isSwitchAxis;
    this.ats.sessionWidth = this.cs.settings.value.General.DefaultTimelineColumnWidth || 300;
    if(this.isSwitchAxis) {
      this.columnWidth = 4;
    }
    else {
      this.columnWidth = 7;
    }
    setTimeout(() => {
      this.currentTimeDisplay();
    }, 100);
  }

  setStaffSchedules() {
    this.subscriptions.add(this.serverService.getAllSchedules().subscribe(data => {
      if(data?.Payload) {
        this.staffScheduleMapping(data.Payload);
        this.processStaffSchedules();
      }
    }));
  }

  getAllStaffDetails(staffs) {
    let staffIds = staffs?.map(({ ServerId }) => ServerId);
    let staffDetails: ServerDTO[] = [];
    if(staffIds?.length) {
      staffDetails = this._settings?.Servers?.filter(server => !server.IsTemplate && !staffIds?.includes(server.Id));
    }
    else {
      staffDetails = this._settings.Servers.filter(server => !server.IsTemplate);
    }
    return staffDetails?.map(detail => {
      return {
        ServerId: detail.Id, Shifts: [], BreakHours: []
      }
    })
  }

  staffScheduleMapping(data) {
    let staffSchedules = [];
    data = [...data, ...this.getAllStaffDetails(data)];
    this.staffScheduleShitsBreakHours = data;
    if (data?.length) {
      data?.forEach((schedule) => {
        let currentSchedule = { StaffId: schedule.ServerId, StaffSchedule: [] };
        let startDate = schedule.StartDate ? new Date(schedule.StartDate) : null;
        let endDate = schedule.EndDate ? new Date(schedule.EndDate) : null;
        let currentDate = this.CurrentDateSelected ? this.CurrentDateSelected : this.appService.headerSelectedDate;
        currentSchedule.StaffSchedule = this.groupStaffShiftBreakHours(startDate, endDate,currentDate,schedule);
        let selectedStaff = staffSchedules?.find(staff => staff.StaffId === schedule.ServerId)
        if(selectedStaff) {
          selectedStaff.StaffSchedule = currentSchedule.StaffSchedule;
        }else {
          if (currentSchedule?.StaffId) {
            staffSchedules?.push(currentSchedule);
          }
        }
      });
    }
    this.staffSchedules = staffSchedules;
  }

  groupStaffShiftBreakHours(startDate: Date, endDate: Date, currentDate: Date, schedule: any): any[] {
    if (startDate && endDate && currentDate) {
      let isScheduleInRange = Utilities.datetimeBetween(startDate, endDate, currentDate) ?? false;
      if(!isScheduleInRange) {
        return [...this.getOffShifts([]), ...this.getBreakorBlock([])];;
      }
      else {
        let staffSchedule = schedule.Shifts?.filter(shift => shift.DayOfWeek == currentDate.getDay() && shift.isEnabled); 
        let staffBreak = schedule.BreakHours.filter(hour => new Date(hour.Date).setHours(0, 0, 0, 0) == currentDate.setHours(0, 0, 0, 0));
        return [...this.getOffShifts(staffSchedule), ...this.getBreakorBlock(staffBreak,schedule.ScheduleId)];
      }
    }
    else {
      return [...this.getOffShifts(schedule.Shifts), ...this.getBreakorBlock(schedule.BreakHours)];
    }
  }

  getOffShifts(schedules): any[] {
    let staffOffShift = [];
    let currentDate = this.CurrentDateSelected ? this.CurrentDateSelected : this.appService.headerSelectedDate;
    let currentDayOpenHours = this._settings.OpenHours.find(hour => hour.DayOfWeek == currentDate?.getDay());
    if(schedules?.length) {
      let scheduleShiftIds = schedules.map(({ ShiftId }) => ShiftId);
      let previousEndTime = currentDayOpenHours?.DisplayedRange?.Start;
      let currentDaySchedules = this._settings?.Shifts?.filter(shift => scheduleShiftIds?.includes(shift.Id));
      if(previousEndTime && currentDaySchedules?.length) {
        currentDaySchedules.sort((schedule1, schedule2) => {
          let sort1: any = new Date(schedule1.DisplayedRange?.Start);let sort2: any = new Date(schedule2.DisplayedRange?.Start);
          return sort1 - sort2;
        })
        currentDaySchedules?.forEach((schedule, index) => {
          let endTime = new Date(schedule?.DisplayedRange?.Start).getTime();
          let startTime = new Date(previousEndTime).getTime();
          if(endTime && startTime && ((endTime - startTime) > 0)) {
            staffOffShift?.push(this.getOffShiftObj(previousEndTime,schedule?.DisplayedRange?.Start,index));
          }
          previousEndTime = schedule?.DisplayedRange?.End;
        });
      }
      /**Staff Off Shift generation with open hours end time */
      let openEndTime = new Date(currentDayOpenHours?.DisplayedRange?.End).getTime();
      let prevEndTime = new Date(previousEndTime).getTime();
      if(openEndTime && prevEndTime && ((openEndTime - prevEndTime) > 0)) {
        staffOffShift.push(this.getOffShiftObj(previousEndTime,currentDayOpenHours?.DisplayedRange?.End,staffOffShift?.length));
      }
    }
    else {
      staffOffShift?.push(this.getOffShiftObj(currentDayOpenHours?.DisplayedRange?.Start,currentDayOpenHours?.DisplayedRange?.End,0));
    }
    return staffOffShift;
  }

  getBreakorBlock(breakHours, scheduleId?:number): any[] {
    let staffBreakHours = [];
    if(breakHours?.length) {
      staffBreakHours = breakHours?.map(breaks => {
        return {
          Start: breaks?.EffectiveRange?.Start,
          End: breaks?.EffectiveRange?.End,
          Id: breaks?.BreakHourId,
          ShiftId: breaks?.ShiftId,
          ScheduleId: scheduleId,
          staffScheduleType: StaffScheduleType.BreakOrBlock,
          Reason: breaks?.EffectiveRange?.Reason ?? ''
        }
      }) || [];
      return staffBreakHours;
    }
    return staffBreakHours;
  }

  getOffShiftObj(startTime: Date,endTime: Date,id: number): {Start: Date, End: Date, Id: number, staffScheduleType: StaffScheduleType} {
    return {
      Start: startTime,
      End: endTime,
      Id: id,
      staffScheduleType: StaffScheduleType.OffShift
    }
  }

  processStaffSchedules() {
    this.staffSchedules?.forEach(staff => {
      this.setReservationOnGrid(staff);
    })
    if(this.intialized) {
      this.setViewBy({ value: this.partyService.SelectedTimelineView });
    }
  }

  updateInstructorScheduleView(staffSchedule) {
    if(staffSchedule) {
    let instructorRow = this.dataList?.ByInstructors?.find(({ Id }) => +Id === staffSchedule.StaffId);
    if (instructorRow) {
        if (!instructorRow.occupiedSlots) {
            instructorRow.occupiedSlots = {}
        }
        staffSchedule.StaffSchedule?.forEach(schedulebreak => {
          let startIndex = this.getSlotIndex([new Date(schedulebreak.Start).getHours(), new Date(schedulebreak.Start).getMinutes()], this.timeArrayGenerated) + 1;
          let endIndex = this.getSlotIndex([new Date(schedulebreak.End).getHours(), new Date(schedulebreak.End).getMinutes()], this.timeArrayGenerated) + 1;
          if(endIndex === 0 || endIndex < startIndex){
            endIndex = this.getSlotIndex([new Date(schedulebreak.End).getHours(), new Date(schedulebreak.End).getMinutes() - 1], this.timeArrayGenerated) != -1 ? this.getSlotIndex([new Date(schedulebreak.End).getHours(), new Date(schedulebreak.End).getMinutes() - 1], this.timeArrayGenerated) + 2 : (this.timeArrayGenerated.length - 1) + 2 ;
          }
          if (endIndex != 0) {
            for (let idx = startIndex; idx <= endIndex; idx++) {
              instructorRow.occupiedSlots[idx] = true;
            }
          } else {
            let staffScheduleSlot = instructorRow.StaffSchedule.filter(group => group.staffScheduleType);
            if(staffScheduleSlot?.length) {
              let gridColumnRange = staffScheduleSlot[0].gridColumnEnd - staffScheduleSlot[0].gridColumnStart;
              for (let idx = startIndex; idx <= startIndex+gridColumnRange; idx++) {
                instructorRow.occupiedSlots[idx] = true;
              }
            }
            else {
              instructorRow.occupiedSlots[startIndex] = true;
            }
          }
          schedulebreak.SlotHeader = {} as any;
          schedulebreak.SlotHeader[ViewBy.Instructor] = this.ts.instant('ModifyReason');

          schedulebreak.SlotSubHeader = {} as any;
          schedulebreak.SlotSubHeader[ViewBy.Instructor] = schedulebreak.staffScheduleType == StaffScheduleType.BreakOrBlock ? schedulebreak.Reason : '';
    
          schedulebreak.gridColumnStart = startIndex;
          schedulebreak.gridColumnEnd = endIndex == (this.timeArrayGenerated - 1) + 2  ? -1 : endIndex;

          // For timelnie Row
          let _reservationDataIndex = instructorRow.StaffSchedule?.findIndex(({Id}) => schedulebreak.Id === Id)
          if(_reservationDataIndex != -1){
            instructorRow.StaffSchedule[_reservationDataIndex] = schedulebreak;
          }else{
            instructorRow.StaffSchedule?.push(schedulebreak);
          }
        });
    }
  }
  } 
}

@Directive({
  selector: '[dynamicSlotWidth]'
})
export class SlotWidthDirective {

  public el: ElementRef;

  @Input('slotWidth') slotWidth: number = 7;
  @Input('noOfSlots') noOfSlots: number = 15;

  constructor(el: ElementRef) {
    this.el = el;
  }

  ngOnChanges() {
    this.el.nativeElement.style.minWidth = (this.slotWidth * this.noOfSlots) + "px";
    // this.el.nativeElement.style.maxWidth = (this.slotWidth * this.noOfSlots) + "px";
  }
}



@Pipe({
  name: 'partyDataFilter1'
})
export class PartyDataFiltPipe implements PipeTransform {

  transform(reservations: any[], searchString: string): any[] {

    if (reservations.length == 0 || !searchString) {
      return reservations;
    }
    reservations.forEach(booking => {
      if (booking.slots) {
        booking.slots = booking.slots.filter(res => ((res.Name.toLowerCase().includes(searchString.toLowerCase()))))
      }
    }
    );

    return reservations;
  }


}

@Pipe({
  name: 'instructorNameFormatter'
})
export class InstructorNameFormatPipe implements PipeTransform {

  transform(data: any): any {
    let name: any;
    if (data.Name) {
      name = data.Name;
    }
    else {
      if (data.FirstName) {
        name = data.FirstName + " ";
      }
      if (data.LastName) {
        name += data.LastName
      }
    }
    return name;
  }


}


@Pipe({
  name: 'weekDateGenerator'
})
export class WeekDateGeneratorPipe implements PipeTransform {

  transform(date: any, restaurantDate?): any {
    let weekDateList = [];

    for (let i = 0; i < 7; i++) {
      let newDate = new Date(date);
      newDate.setDate(date.getDate() + i);
      let isToday: boolean = false;
      if (format(newDate, 'MM/DD/YYYY') === format(restaurantDate, 'MM/DD/YYYY')) {
        isToday = true;
      }
      weekDateList.push([newDate, isToday]);
    }

    return weekDateList;
  }


}



@Pipe({
  name: 'timeArrayRefactor'
})
export class TimeArrayRefactorPipe implements PipeTransform {

  transform(data: any, filterValue=3): any {
    if (data && data.length) {
      // return data.filter((data, i) => (i % filterValue) === 0)
      return data.filter((data, i) => i % 15 === 0)
    }
    return [];
  }


}


@Pipe({
  name: 'templateColumnsConfig'
})
export class TemplateColumnConfigPipe implements PipeTransform {

  transform(element: any, columnWidth): any {
    return `repeat(${element.gridColumnEnd - element.gridColumnStart},1fr)`;
  }


}


@Pipe({
  name: 'configDateFormatter'
})
export class ConfigDateFormatter implements PipeTransform {

  constructor(private cs: CacheService, private dashboardFunctions: DashboardFunctions) { }

  transform(date: any, format?: string): any {
    if (date) {
      return moment(date).locale(this.dashboardFunctions.getLocaleCode()).format(format || this.cs?.settings?.value?.General?.DateFormat);
    }
    return date;
  }


}

const MESSAGES = {
  labels: {
    currentTimeText: "currentTimeText",
    zoomOut: "-",
    zoomIn: "+",
    reset: "100%",
    createActivity: "CreateActivity",
    packages: "packages",
    standbylist: "StandByList",
  }
}

// @Pipe({
//   name: 'searchData'
// })
// export class SearchDataPipe implements PipeTransform {

//   transform(array, searchText ): any 
//   {
//       if(array && _.isArray(array) && searchText){
//           return array.find(o => o.Name.includes(searchText));
//         }
//         return array || [];
    
//   }
// }
