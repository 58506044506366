import { DatePipe } from '@angular/common';
import { AfterViewInit, Component, ElementRef, EventEmitter, HostListener, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild, ViewEncapsulation } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AppService } from '@app/app.service';
import { PopupService } from '@app/popup-module/popup.service';
import { GuestBookService } from '@app/shared/services/guestbook.service';
import { ReservationTimesPopupComponent } from '@components/reservation-times-popup/reservation-times-popup.component';
import { buttonTypes, ComponentTypes, LayoutUpdateStatus, PartyState, PartyType, ReservationType, ShapeTypeEnum, TableLayoutConfig } from '@constants/commonenums';
import { CacheService } from '@core/services/cache.service';
import { ButtonValue, IconSizes } from '@dynamicform/models/field-config.interface';
import { FloorPlanImagesDto, InputImages } from '@models/FloorPlanImageInputDto';
import { LayoutConfig } from '@models/global.interface';
import { ReservationDTO } from '@models/InputContact';
import { FloorPlanDTO, ServerDTO, StandaloneTableDTO, TableShapeType } from '@models/RestaurantDTO';
import { TranslateService } from '@ngx-translate/core';
import { CustomPopupComponent } from '@popup-module/components/custom-popup/custom-popup.component';
import { ComponentDetails } from '@popup-module/models/popup.interface';
import { PartyService } from '@services/party.service';
import { ServerService } from '@services/server.service';
import { TablesService } from '@services/tables.service';
import { LayoutFunctions } from '@utilities/layout-functions';
import { Utilities } from '@utilities/utilities';
import { fabric } from 'fabric';
import { cloneDeep, maxBy, minBy, differenceBy, sortBy, uniq} from 'lodash';
import moment from 'moment';
import { format } from 'date-fns';
import { NgScrollbar } from 'ngx-scrollbar';
import { ISubscription, Subscription } from 'rxjs/Subscription';
import { ConfirmationPopupComponent } from '../confirmation-popup/confirmation-popup.component';
import _ from 'lodash';
import { table } from 'console';
import { LoaderService } from '@app/core/services/loader.service';
import { DashboardFunctions } from '@app/shared/utilities/dashboard-functions';
import IconSize from '@data/icon-size.json';

@Component({
  selector: 'app-layout-view',
  templateUrl: './layout-view.component.html',
  styleUrls: ['./layout-view.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class LayoutViewComponent implements OnInit, OnChanges, AfterViewInit {
  @Input() layoutConfig: LayoutConfig;
  @Input() editableServer: ServerDTO;
  @Input() seatPartyData: { seatParty: boolean; selectedTables: any; };
  @Input() editData: { isEdit: boolean; selectedTables: any; };
  @Output() selectedObject = new EventEmitter<any>();
  @Output() shrinkTablesView = new EventEmitter<any>();
  @Output() selectionCleared = new EventEmitter<any>();
  @Output() selectedTableEvent = new EventEmitter();
  @Input() layoutIconSize;
  @Input() IsServerBackgroundAvailable;
  @ViewChild(NgScrollbar) scrollbarRef: NgScrollbar;
  minimalValue = 50;
  defaultWidthValue = 100;
  percentageValue = 100;
  dataToBePushed = [];
  layoutData: LayoutConfig;
  animationCounter = 0;
  @ViewChild('canvasLoader', { static: true }) canvasLoader: ElementRef;
  canvas: any;
  floorPlanViews: any;
  tableObj: any = []
  tables: StandaloneTableDTO[] = [];
  rectangle: any;
  circle: any;
  ellipse: any;
  circleTablegroup: any;
  rectangleTableGroup: any;
  allReservations: ReservationDTO[];
  topMinValue: any;
  leftMinValue: any;
  blockingRule: any = [];
  floorPlan: FloorPlanDTO;
  objectsSelected: any = [];
  partyUnselectedEventSubscription: ISubscription;
  selectedFloorPlanSubscription: ISubscription;
  statusChangeSubscription: ISubscription;
  zoomInBtn: ButtonValue;
  zoomOutBtn: ButtonValue;
  height: number;
  width: number;
  subscriptions: Subscription = new Subscription();
  propertyChanges = ['Walls', 'Shapes', 'Labels'];
  serverDetails: ServerDTO[] = [];
  initialScaleFactor: number = 1;
  scaleFactorY = 1;
  scaleFactorX = 1;
  STATE_IDLE = 'idle';
  STATE_PANNING = 'panning';
  isDragEnable = false;
  isMouseDown = false;
  selectedTableDetails;
  tableActiveObjectSubscription: ISubscription;
  layoutImages: InputImages[] = [];
  objectImages: InputImages[] = [];
  basicRectangle: fabric.Rect;
  basicCircle: fabric.Circle;
  pin: fabric.Circle;
  customImages: InputImages[] = [];
  image: any;
  baseElement: any;
  isTablesScreen = false;
  updatedSelectedArray: number[] = [];
  selectedTables: StandaloneTableDTO[] = [];
  updatedSelectedTableNames = [];
  updatedSelectedArrayForCommunalTable = [];
  objectTextColor = '#6A6A6A';
  selectedObjectFillColor = '#6a6a6a1a';
  updatedUnselectedArray: number[] = [];
  heightValue;
  widthValue;
  lastClientX;
  lastClientY;
  isZoomAvailable = true;
  viewportTransform = [1,0,0,1,0,0];
  canAnimateObject = false;
  layoutDataCopy : LayoutConfig;
  selectedServerName: any;
  dragOverTableObject: any;
  intialized: boolean = false;
  constructor(private ts: TablesService, private ss: ServerService, private datePipe: DatePipe,
              public lf: LayoutFunctions, private appService: AppService,
              private ps: PartyService, public cs: CacheService, private dialog: MatDialog, private gbs: GuestBookService,
              private translateService: TranslateService, private popupService: PopupService,private ls:LoaderService, 
              private dashboardFunctions: DashboardFunctions) {
    this.subscriptions.add(cs.restaurantImages.subscribe((images: FloorPlanImagesDto) => {
      if (images) {
        this.layoutImages = images.LayoutImages;
        this.objectImages = images.ObjectImages;
        this.customImages = images.CustomImages;
        if (this.floorPlan?.ImageId) {
          if (this.layoutImages?.length > 0) {
            this.setBackgroundImage();
          }
        }
      }
    }));
    this.ss.loadServers();
    this.subscriptions.add(this.ss.serverDetails.subscribe((details) => {
      this.ss.mapServerColors(details);
      this.serverDetails = details;
      this.serverDetails.map( server => { 
        if (!server.color ) { 
          server.color = this.getRandomColor();
        }
      });
    }));
    this.subscriptions.add(this.appService.minuteTimer$.subscribe(val => {
      let shiftId = this.ts.restaurantShiftId ? this.ts.restaurantShiftId : 0;
      this.layoutConfig.blockingRules = this.ts.getBlockingRulesForShift(this.ts.tableBlockingRules$.value, shiftId);
	    this.blockingRule = [];
      this.setBlockingRules();// need to recode
      let CurrentDateTimeInMinutes = new Date(Utilities.getRestaurantDateTime(this.cs.settings.value.General.DaylightDelta)).getMinutes();
      const timeSlotUnitInMinutes = this.cs.settings.value.General.TimeSlotUnitInMinutes;
      if (CurrentDateTimeInMinutes % timeSlotUnitInMinutes == 0) {
        this.UpdateResevationInTables();
      }
    }));
  }

  ngOnInit() {
    if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|Mobile|mobile/i.test(navigator.userAgent)) {
      this.isZoomAvailable = false;
    }
    this.zoomInBtn = {
      type: buttonTypes.actionSecondarySmall,
      label: '',
      disbaledproperity: false,
      customclass: 'tables-layout__zoom-out',
      icon: 'icon-add'
    };
    this.zoomOutBtn = {
      type: buttonTypes.actionSecondarySmall,
      label: '-',
      disbaledproperity: false,
      customclass: 'tables-layout__zoom-in',
      icon: ''
    };
    this.layoutIconSize  =  JSON.parse(localStorage.getItem('tableIconSize')) || IconSizes.Medium;
    this.lf.layoutIconSize = this.layoutIconSize;
  }

  ngOnChanges(changes: SimpleChanges) {
    if(this.layoutConfig){
      this.layoutConfig.tables = this.layoutConfig.tables.filter(table => !table.IsTemplate);
    }
    if(!this.intialized && this.layoutConfig){
      this.layoutConfig.type = LayoutUpdateStatus.New;
      this.intialized = true;
    }
    this.layoutImages = this.cs.restaurantImages?.value?.LayoutImages;
    this.objectImages = this.cs.restaurantImages?.value?.ObjectImages;
    this.customImages = this.cs.restaurantImages?.value?.CustomImages;
    if(changes.layoutIconSize?.currentValue !== undefined){
      this.lf.layoutIconSize = changes.layoutIconSize?.currentValue;
    }

    if (this.layoutConfig && this.layoutConfig.isRefresh) {
      if (this.canvas) {
        this.canvas.dispose();
      }
      this.renderCanvas();
    } else if (this.layoutConfig && this.layoutConfig.type == LayoutUpdateStatus.New) {

      this.layoutDataCopy = cloneDeep(this.layoutConfig);
      this.isTablesScreen = this.layoutConfig.from == TableLayoutConfig.tables;
      let oldFloorPlan;
      if (this.layoutConfig.from == TableLayoutConfig.tables) {
        oldFloorPlan = this.floorPlan = cloneDeep(this.cs.layout.value.FloorPlans.filter(x => x.IsDefault == true)[0]);
        this.ss.currentLayoutId = this.floorPlan.Id;
      }
      if(this.canvas){
        this.canvas.setBackgroundImage(null);
        if(this.layoutImages)
        this.setBackgroundImage();
      }
      if (this.layoutConfig.floor && this.layoutConfig.floor.length > 0 && oldFloorPlan && oldFloorPlan.Id != this.layoutConfig.floor[0].Id && this.canvas) {
        if (this.canvas.getZoom() != 1) {
          this.canvas.setZoom(1);
        }
        this.canvas.setViewportTransform([1, 0, 0, 1, 0, 0]);
      }
      if (this.layoutConfig.tables) {
        if (this.canvas) {
          this.canvas?.clear();
        }
        
        if (document.getElementById(`${this.layoutConfig.from}-inline-btn`))
        document.getElementById(`${this.layoutConfig.from}-inline-btn`).innerHTML = ''; 
        this.blockingRule = [];
        if (this.layoutConfig.blockingRules) {
          this.setBlockingRules();
        }
      }
      let labels;
      let walls;
      let shapes;
      if (this.layoutConfig.floor && this.layoutConfig.floor.length > 0) {
        this.floorPlan = cloneDeep(this.layoutConfig.floor[0]);
        this.ss.currentLayoutId = this.floorPlan.Id;
        this.floorPlanViews = this.floorPlan.FloorPlanViews;
        labels = this.floorPlan ? this.floorPlan.Labels : [];
        walls = this.floorPlan ? this.floorPlan.Walls : [];
        shapes = this.floorPlan ? this.floorPlan.Shapes : [];
        this.tables = cloneDeep(this.layoutConfig.tables);
      }
      if (this.layoutConfig.from == TableLayoutConfig.servers) {
        if (this.canvas) {
          this.canvas.dispose();
        }
        this.renderCanvas();
      } else if (this.canvas) {
        this.renderDefaultFloor();
      }
      this.layoutData = cloneDeep(this.layoutConfig);
      this.ts.isFirstTime = false;
    } else if (this.layoutConfig && this.layoutConfig.type == LayoutUpdateStatus.Update) {
      if(!this.canvas) {
        this.renderCanvas();
      }
      this.dataToBePushed = [];
      this.layoutDataCopy = cloneDeep(this.layoutConfig);
      if (this.layoutConfig.floor && this.layoutConfig.floor.length > 0) {
        this.floorPlan = cloneDeep(this.layoutConfig.floor[0]);
      }
      if(this.canvas){
        this.canvas.setBackgroundImage(null);
        if(this.layoutImages)
        this.setBackgroundImage();
      }

      if (this.layoutConfig.blockingRules) {
        this.blockingRule = [];
        this.setBlockingRules();
      }
      const canvasObjects = this.canvas.getObjects();
      if(!this.layoutData)
      this.layoutData = cloneDeep(this.layoutConfig);
      this.lf.blockingRuleChanges(canvasObjects, this.layoutConfig, this.layoutData, this.blockingRule, this.canvas);
      let tablesToBeAdded = this.lf.tableChanges(this.layoutConfig, this.layoutData, this.canvas, this.ps.cachedMoveTables, this.ps.changedServerIds);
      this.ps.changedServerIds = [];
      let tables = cloneDeep(this.layoutConfig.tables);
      let labels = cloneDeep(this.floorPlan ? this.floorPlan.Labels : []);
      let walls = cloneDeep(this.floorPlan ? this.floorPlan.Walls : []);
      let shapes = cloneDeep(this.floorPlan ? this.floorPlan.Shapes : []);
      if (this.ts.selectedFloorPlanId != null) {
        tables = cloneDeep(this.floorPlan.StandaloneTables.filter(x => (x.FloorPlanViewId == this.ts.selectedFloorPlanId)));
        if (this.ts.selectedArea) {
          tables = tables.filter(x => (x.SeatingAreaId == this.ts.selectedArea));
        }
        labels = labels.filter(x => x.FloorPlanViewId == this.ts.selectedFloorPlanId);
        walls = walls.filter(x => x.FloorPlanViewId == this.ts.selectedFloorPlanId);
        shapes = shapes.filter(x => x.FloorPlanViewId == this.ts.selectedFloorPlanId);
      }
      this.calculateMinTopLeftValues(tables, labels, walls, shapes);
      let adjustedTopValue = this.getAdjustedTopValue();
      let adjustedLeftValue = this.getAdjustedLeftValue();

      tablesToBeAdded.forEach(element => {
        let tableToAdd = tables.find(x => x.Id == element.Id);
        if (tableToAdd) {
          tableToAdd.Left = tableToAdd.Left + (tableToAdd.Width / 2) + adjustedLeftValue;
          tableToAdd.Top = tableToAdd.Top + (tableToAdd.Height / 2) + adjustedTopValue;
          this.setTableConfiguration(tableToAdd);
        }
      });
      // this.partyDataChanges();
      const changedLayoutObjects =  this.lf.dataChanges(this.canvas, this.layoutConfig, this.layoutData);
      if (changedLayoutObjects.length)
      this.dataToBePushed.push(...changedLayoutObjects);
      if (this.ss.editableMode && this.layoutConfig.from === TableLayoutConfig.servers) {
        this.canvas.hoverCursor = 'pointer';
        this.mapSelectedServerTables();
        this.highlightSelectedServers();
      }
      if (!this.ss.editableMode && this.layoutConfig.from === TableLayoutConfig.servers) {
        this.canvas.hoverCursor = '';
        this.resetSelectedTables();
        let serverNameTimeout = setTimeout(() => {
          objs.forEach((table) => {
            this.createServerNameElement(table)
          })
          clearTimeout(serverNameTimeout);
        })
      }
      if (this.dataToBePushed.length > 0) {
        let scaleFactor;
        this.calculatescaleFactor(tables,shapes,walls,labels)
        if (this.scaleFactorX < 1 && this.scaleFactorY < 1) {
          scaleFactor = this.scaleFactorX <= this.scaleFactorY ? this.scaleFactorX : this.scaleFactorY;
        }
        else {
          scaleFactor = this.scaleFactorX >= this.scaleFactorY ? this.scaleFactorY : this.scaleFactorX;
        }
        this.dataToBePushed.forEach(data => {
          if (data.name !== 'label' && data.name !== 'wall' && data.name !== 'area')
          this.canvas.add(data);
          data.set('subTargetCheck', true);
          this.scaleSingleCanvasObject(scaleFactor, data);
          if (data.ImageId && (data.ShapeType == TableShapeType.RectangleBasic || data.ShapeType == TableShapeType.CircleBasic)) {
            this.setObjectImage(data);
          }
          if (data.ImageId && data.ShapeType == TableShapeType.Image) {
            this.setImageBorder(data);
          }
          this.createServerNameElement(data)
        });
        this.canvas.calcOffset();
      }
      const objs = this.canvas.getObjects();
      if (this.ts.selectedTableBeforeAction$.value &&
        this.layoutConfig.from == TableLayoutConfig.tables) {
        const data = objs.filter(x => x.Id == this.ts.selectedTableBeforeAction$.value.Id);
        if (data && data.length) {
          this.ts.selectedTableBeforeAction$.next(data[0]);
          this.setSeletedObject(data[0]);
        }
      }
      tablesToBeAdded.forEach(table => {
        this.lf.setTableIcons(table, objs, this.canvas, this.blockingRule);
      });
      if (this.ps.reservationFormGroup.get('selectedTable').value && this.layoutConfig.from != TableLayoutConfig.tables) {
        this.canvas._objects.forEach(obj => {
          if (obj._objects && obj._objects.filter(x => x.name == 'highlight').length) {
            obj._objects.filter(x => x.name == 'highlight').forEach(element => {
              obj.removeWithUpdate(element);
            });
          }
        })
        this.mapSelectedTables(this.ps.reservationFormGroup.get('selectedTable').value);
        this.setSelectedTables(this.ps.highlightSelectedTables);
      }
      this.layoutData = cloneDeep(this.layoutConfig);

      this.canvas.dispose();
      setTimeout(()=>{
        this.renderCanvas();
      },0)
      
    }
    if(this.layoutConfig && this.layoutConfig.type !== LayoutUpdateStatus.Update && changes.IsServerBackgroundAvailable?.currentValue !== undefined){
      this.canvas.dispose();
      setTimeout(()=>{
        this.renderCanvas();
      },500)
    }
  }
calculatescaleFactor(tables,shapes,walls,labels){
 let  basicTables = this.lf.findmaxObject(tables, labels, walls, shapes);
  const topValue = maxBy(basicTables, 'totalDataHeight');
  const leftValue = maxBy(basicTables, 'totalDataWidth');
  this.heightValue = topValue['totalDataHeight'] + 25;
  this.widthValue = leftValue['totalDataWidth'] + 25;

  let scaleFactor;
  if (this.canvas) {
    console.log('clientHeight', this.canvasLoader.nativeElement.clientHeight, 'clientWidth', this.canvasLoader.nativeElement.clientWidth);
    this.scaleFactorY = this.canvasLoader.nativeElement.clientHeight / this.heightValue;
    this.scaleFactorX = this.canvasLoader.nativeElement.clientWidth / this.widthValue;
 
}
}

  ngAfterViewInit() {
    this.renderCanvas();
    this.subscriptions.add(this.ts.partyUnselectedEvent.subscribe(val => {
      const obj = { target: null };
      this.selectedTable(obj);
      this.canvas.renderAll();
    }));
    this.subscriptions.add(this.ts.deactivateTableObject.subscribe((data) => {
      if (data) {
        this.canvas.discardActiveObject();
      }
    }));
    this.subscriptions.add(this.ps.statusChange$.subscribe(data => {
      this.layoutConfig.reservations.forEach(x => {
        if (data.party.Id == x.Id) {
          x.StatusId = data.statusId;
        }
      });
      if (this.objectsSelected.length) {
        const dataSelected = this.objectsSelected[0];
        this.layoutConfig.reservations.forEach(party => {
          if (party.Id === dataSelected.Party.Id) {
            dataSelected.Party.StatusId = party.StatusId;
          }
        });
        if (dataSelected.Id) {
          this.setSeletedObject(this.objectsSelected[0]);
        }
      }
    }));
    this.subscriptions.add(this.ts.selectedFloorPlan.subscribe(val => {
      this.ts.selectedFloorPlanId = val == -1 ? null : val;
      if (this.canvas.getZoom() != 1) {
        this.canvas.setZoom(1);
      }
      this.canvas.setViewportTransform([1, 0, 0, 1, 0, 0]);
      this.setFloorplanChanges(this.ts.selectedFloorPlanId);
    }));
    this.subscriptions.add(this.ts.selectedFloorPlanPopup.subscribe(val => {
      this.ts.selectedFloorPlanPopupId = val === -1 ? null : val;
      this.setSelectedFloor();
    }));
    if (this.layoutConfig && this.layoutConfig.from === TableLayoutConfig.servers) {
      this.subscriptions.add(this.ss.unassignAllEvent.subscribe(() => {
        this.resetServerSelectedTables();
      }));
      this.subscriptions.add(this.ss.resetSelectionArrayEvent.subscribe(() => {
        this.updatedSelectedArray = [];
        this.updatedUnselectedArray = [];
      }));
    }
    this.canvas.renderAll();
  }

  setBlockingRules() {
    this.layoutConfig.blockingRules.forEach(rule => {
        rule.TableIds.forEach(table => {
          this.blockingRule.push(table)
        });
    });
  }

  renderCanvas() {

    this.setCanvas();
    this.renderDefaultFloor();

  }

  onDrop(event) {
    console.log('onDrop',event)
    if (event.target != null && this.ps.selectedTableObjectForDrag != null) {
      this.ps.SelectedTableIds.push(event.target.Id);
      this.dashboardFunctions.seatPartyWithConfimation(this.ps.selectedTableObjectForDrag, true);
      if (event.target._objects) {
        event.target._objects.forEach(element => {
          if (element.name == 'shape') {
            element.set({
              stroke: null
            })
          }
        });
      }
    } else if (this.dragOverTableObject) {
      if (this.dragOverTableObject._objects) {
        this.dragOverTableObject._objects.forEach(tableObjectes => {
          if (tableObjectes.name === 'shape') {
            tableObjectes.set({
              stroke: null
            });
          }
        });
      }
      this.ps.SelectedTableIds.push(this.dragOverTableObject.Id);
      this.dashboardFunctions.seatPartyFromCanvas();
    }
    this.canvas.renderAll();
  }

  onDragOver(event) {
    if (event.target) {
      if (this.dragOverTableObject && this.dragOverTableObject._objects) {
        this.dragOverTableObject._objects.forEach(tableObjectes => {
          if (tableObjectes.name === 'shape') {
            tableObjectes.set({
              stroke: null
            });
          }
        });
      }
      if (event.target._objects) {
        event.target._objects.forEach(element => {
          if (element.name == 'shape') {
            element.set({
              stroke: '#1DA664',
              strokeWidth: 3
            })
          }
        });
      }
      this.dragOverTableObject = event.target;
      this.canvas.renderAll();
    } else {
      if (this.dragOverTableObject && this.dragOverTableObject._objects) {
        this.dragOverTableObject._objects.forEach(tableObjectes => {
          if (tableObjectes.name === 'shape') {
            tableObjectes.set({
              stroke: null
            });
          }
        });
      }
      this.dragOverTableObject = null;
      this.canvas.renderAll();
    }
  }

  setCanvas() {
    this.baseElement = this.canvasLoader.nativeElement;
    const canvasID = `${this.layoutConfig.from}-tablesLayout`;
    this.canvas = new fabric.Canvas(canvasID, {
      selection: false,
      allowTouchScrolling: true
    });

    this.canvas.on('drop', this.onDrop.bind(this), false);
    this.canvas.on('dragover', this.onDragOver.bind(this), false);
    this.canvas.preserveObjectStacking = true;
    this.canvas.renderOnAddRemove = true;
    if (this.layoutConfig.from == TableLayoutConfig.tables) {
      fabric.Object.prototype.set({
        transparentCorners: false,
        rotatingPointOffset: 20,
        cornerSize: 12,
        hasBorders: false,
        perPixelTargetFind: true,
        flipY: false,
        hasControls: false,
        objectCaching: false
      });
    } else {
      fabric.Object.prototype.set({
        cornerColor: '#1da664',
        cornerStrokeColor: '#1da664',
        cornerStyle: 'circle',
        transparentCorners: false,
        rotatingPointOffset: 20,
        cornerSize: 12,
        hasBorders: false,
        lockScalingFlip: true,
        lockMovementX: true,
        lockMovementY: true,
        flipX: false,
        flipY: false,
        lockScalingX: true,
        lockScalingY: true,
        hasRotatingPoint: false,
        hasControls: false,
        objectCaching: false
      });
    }
    if (this.layoutConfig.from === TableLayoutConfig.servers && !this.ss.editableMode ) {
      this.canvas.hoverCursor = '';
    } else {
      this.canvas.hoverCursor = 'pointer';
    }
    this.canvas.perPixelTargetFind = true;
    this.canvas.targetFindTolerance = 2;
    let _that = this;
    this.canvas.on({
      'mouse:down'(event) {
        if(event.subTargets.length && event.subTargets[0].name === "serverCircle") {
          _that.showServerFullName(event);
        }
        else {
        if ((_that.ss.editableMode && _that.layoutConfig.from === TableLayoutConfig.servers) || _that.layoutConfig.from != TableLayoutConfig.servers)
        _that.selectedTable(event, true);
        _that.removeIllusion();
        }
      },
      'mouse:move'(event) {
        if (_that.layoutConfig.from == TableLayoutConfig.tables) {
          if (_that.isMouseDown) {
            _that.TableMoving(event, _that);
            _that.selectedhiglightTable(event);

          } else {
            _that.removeHightLightColor(event, _that);
          }
        }
        if (event.target == null) {
          let btn = null;
          btn = document.getElementById(`${_that.layoutConfig.from}-server-name`);
          if (document.getElementById(`serverName_${_that.selectedServerName}`)) {
            let serverNameElement = document.getElementById(`serverName_${_that.selectedServerName}`);
            _that.selectedServerName = null;
            btn.removeChild(serverNameElement);
          }
        }
        if(event.subTargets.length && event.subTargets[0].name === "serverCircle") {
          _that.showServerFullName(event);
        }
      },
      'mouse:up'(event) {
        if (_that.layoutConfig.from == TableLayoutConfig.tables) {
          _that.updateTablemoveTable(event);
          _that.removeHightLightColor(event, _that);
        }
      },
      'selection:cleared'(event) {
        _that.onSelectionCleared(event, _that);
      },
      'mouse:dblclick'(event) {
        if (_that.layoutConfig.from == TableLayoutConfig.tables) {
          _that.isDragEnable = !_that.isDragEnable;
          _that.toggleDragMode(_that.isDragEnable);
        }
      },
      'mouse:up:before'(event) {
      }
    });
    this.ls.showMessage.next(false);
  }

  showServerFullName(data) {
    let event = data.target;
    let btn = null;
    btn = document.getElementById(`${this.layoutConfig.from}-server-name`);
    if (btn) {
      let serverDetails = this.serverDetails.find(sval => sval.Id === event.ServerId);
      if (document.getElementById(`serverName_${this.selectedServerName}`) && btn.childNodes && btn.childNodes.length) {
        let serverNameElement = document.getElementById(`serverName_${this.selectedServerName}`);
        btn.removeChild(serverNameElement);
      }
      this.selectedServerName = serverDetails?.Name;
      let serverFullName =  `${serverDetails?.Name} ${(serverDetails?.LastName ? serverDetails?.LastName : '')}`;
      const serverElement = document.createElement("span");
      const node = document.createTextNode(serverFullName);
      serverElement.id = `serverName_${serverDetails?.Name}`;
      serverElement.className = 'layout-view__serverName';
      serverElement.appendChild(node);
      btn.appendChild(serverElement);
      let zoom = this.canvas.getZoom() ? this.canvas.getZoom() : 1;
      serverElement.style.position = 'absolute';
      serverElement.style.left = (zoom != 1 && data.e.type == 'touchstart') ? (data.pointer.x + 15) + 'px' : + (event.left + (event.width / 4) + 25) * this.canvas.getZoom() +'px';
      serverElement.style.top = (zoom != 1 && data.e.type == 'touchstart') ? (data.pointer.y - 15) + 'px' : ((event.top - (event.height / 4)) - 5) * this.canvas.getZoom() + 'px';
      serverElement.style.textAlign = 'center';
      serverElement.style.zIndex = '1';
    }
  }

  createServerNameElement(element, clientX = 0, clientY = 0) {
    let object = element;


    let btn = null;
    btn = document.getElementById(`${this.layoutConfig.from}-inline-btn`);
    if (btn) {
      let serverName = '';
      let serverColor = '';
      let serverFullName = '';
      if (element.ServerId) {
        element.selectable = true;
        const serverval = this.serverDetails.filter(sval => {
          if (sval.Id === element.ServerId) {
            return sval;
          }
        });
        if (serverval && serverval.length > 0) {
          const serverNameArray = serverval[0].Name.split(' ');
          serverNameArray.forEach((name, index) => {
            if (index <= 1)
            serverName = serverName + name.charAt(0);
          });
          if (!serverval[0].LastName) {
            serverFullName = serverval[0].Name;
          } else {
            serverName = serverName + ( serverval[0].LastName ? serverval[0].LastName.charAt(0) : '' ),
            serverFullName = (serverval[0].Name ? serverval[0].Name : '') + " " + (serverval[0].LastName ? serverval[0].LastName : '');
          }
          serverName = `${serverName.toUpperCase()}`;
          serverColor = serverval[0].color;
        }

      }else{
        if(element.Name && element.ServerId == null ){
        this.serverDetails.forEach(item => {
          let selectedName = '';
            const serverNameArray = item.Name.split(' ');
            serverNameArray.forEach((name, index) => {
              if (index <= 1)
              selectedName = selectedName + name.charAt(0);
            });
            if (item.LastName) {
              selectedName = selectedName + ( item.LastName ? item.LastName.charAt(0) : '' )
            } 
          selectedName = `${selectedName.toUpperCase()}`;
          if(document.getElementById(`serverName_${selectedName}_${element.Name}_${this.layoutConfig.from}`)){
            console.log(element.Name)
              let tableElement = document.getElementById(`serverName_${selectedName}_${element.Name}_${this.layoutConfig.from}`);
              tableElement.remove();
          }
        })
      }
      }
      let leftvaltoadd = 0;
      let topvaltoadd = 0;
      if (serverName) {
        leftvaltoadd = element.left + (element.width / 4) + (clientX ? clientX + 10 : 0);
        topvaltoadd = (element.top - (element.height / 4)) + (clientY ? clientY + 10 : 0) - 5 ;
        if (document.getElementById(`serverName_${serverName}_${element.Name}_${this.layoutConfig.from}`) && btn.childNodes && btn.childNodes.length) {
          let tableElement = document.getElementById(`serverName_${serverName}_${element.Name}_${this.layoutConfig.from}`);
          btn.removeChild(tableElement);
        }
        const tableElement = document.createElement("span");
        const node = document.createTextNode(serverName);
        tableElement.addEventListener('mouseover', (event) => {
          if (event.target) {
            let serverNameID = event.target['id'];
            let serverNameIDArray = serverNameID.split('_');
            let tableName = null;
            if (serverNameIDArray.length) {
              tableName = serverNameIDArray[2];
              let objs = this.canvas.getObjects();
              let tableObject = objs.filter(obj => obj.Name == tableName);
              this.showServerFullName(tableObject[0]);
            }
          }
        }, false);
        tableElement.id = `serverName_${serverName}_${element.Name}_${this.layoutConfig.from}`;
        tableElement.className = 'floorplan-editor__tableSize';
        tableElement.appendChild(node);
        btn.appendChild(tableElement);
        tableElement.style.position = 'absolute';
        tableElement.style.left = leftvaltoadd * this.canvas.getZoom()  + 'px';
        tableElement.style.top = topvaltoadd * this.canvas.getZoom() + 'px';
        tableElement.style.width = serverName?.length > 2 ? '35px' : '25px';
        tableElement.style.height = '25px';
        tableElement.style.minWidth = '25px';
        tableElement.style.borderRadius = '50%';
        tableElement.style.textAlign = 'center';
        tableElement.style.backgroundColor = serverColor;
        tableElement.style.fontSize = '10px';
        tableElement.style.color = 'white';
        tableElement.style.lineHeight = '25px';
      }
    }
  }

  renderDefaultFloor() {
    if (this.ts.selectedFloorPlanId && this.ts.selectedFloorPlanId !== -1 &&
        (this.layoutConfig.from == TableLayoutConfig.tables || this.layoutConfig.from == TableLayoutConfig.servers)) {
      this.setFloorplanChanges(this.ts.selectedFloorPlanId);
    } else if (this.ts.selectedFloorPlanPopupId && this.ts.selectedFloorPlanPopupId !== -1) {
      this.setSelectedFloor();
    } else {
      let labels = this.floorPlan ? this.floorPlan.Labels : [];
      let walls = this.floorPlan ? this.floorPlan.Walls : [];
      let shapes = this.floorPlan ? this.floorPlan.Shapes : [];
      let tables =  cloneDeep(this.layoutConfig.tables);
      if (this.layoutConfig.from == TableLayoutConfig.tables || this.layoutConfig.from == TableLayoutConfig.servers) {
        this.ts.selectedFloorPlanId = null;
      } else {
        this.ts.selectedFloorPlanPopupId = null;
      }
      if (tables.length > 0)
      this.setDefaultFloor(tables, labels, walls, shapes);
    }
    if (this.editData && this.editData.isEdit) {
      this.mapSelectedTables(this.editData.selectedTables);
    } else if (this.seatPartyData && this.seatPartyData.seatParty) {
      this.mapSelectedTables(this.seatPartyData.selectedTables);
    } else if (this.ps.reservationFormGroup.get('selectedTable').value && this.layoutConfig.from != TableLayoutConfig.tables) {
      this.mapSelectedTables(this.ps.reservationFormGroup.get('selectedTable').value);
    }
    this.setSelectedTables(this.ps.highlightSelectedTables);
    if (this.ss.editableMode && this.layoutConfig.from === TableLayoutConfig.servers) {
      this.highlightSelectedServers();
    }
    this.ls.showMessage.next(false);
  }

  setFloorplanChanges(val) {
    let tables = [];
    let labels = this.floorPlan ? this.floorPlan.Labels : [];
    let walls = this.floorPlan ? this.floorPlan.Walls : [];
    let shapes = this.floorPlan ? this.floorPlan.Shapes : [];
    if (this.ts.selectedFloorPlanId != null) {

      tables = cloneDeep(this.floorPlan.StandaloneTables.filter(x => (x.FloorPlanViewId == val)));
      if (this.ts.selectedArea) {
        tables = tables.filter(x => (x.SeatingAreaId == this.ts.selectedArea));
      }
      labels = labels.filter(x => x.FloorPlanViewId == val);
      walls = walls.filter(x => x.FloorPlanViewId == val);
      shapes = shapes.filter(x => x.FloorPlanViewId == val);
    } else {
      if (this.ts.selectedArea) {
        if (tables.length) {
          tables = tables.filter(x => (x.SeatingAreaId == this.ts.selectedArea));
        } else {
          tables = cloneDeep(this.floorPlan.StandaloneTables.filter(x => (x.SeatingAreaId == this.ts.selectedArea)));
        }
      }
      else {
        tables = cloneDeep(this.floorPlan.StandaloneTables);
      }
    }
    if (tables.length > 0 || labels.length > 0 || walls.length > 0 || shapes.length > 0) {
      this.canvas.clear();
      if (document.getElementById(`${this.layoutConfig.from}-inline-btn`))
      document.getElementById(`${this.layoutConfig.from}-inline-btn`).innerHTML = ''; 
      tables = tables.filter(x => !x.IsTemplate);
      this.setDefaultFloor(tables, labels, walls, shapes);
      if (this.layoutConfig.from == TableLayoutConfig.servers) {
        if (this.selectedTables.length > 0) {
          this.selectedTables.forEach(x => {
            let data = this.canvas.getObjects().filter(obj => x.Id == obj.Id)[0];
            if (data) {
              if (this.layoutConfig.from === TableLayoutConfig.servers) {
                this.ss.editableMode ? this.addHighLightObject(data) : '';
              }
              else {
                this.addHighLightObject(data);
              }
            }
          });
        }
      }
    }
    else {
      this.canvas.clear();
      if (document.getElementById(`${this.layoutConfig.from}-inline-btn`))
      document.getElementById(`${this.layoutConfig.from}-inline-btn`).innerHTML = ''; 
    }
  }

  setSelectedFloor() {
    let tables = [];
    let labels = this.floorPlan ? this.floorPlan.Labels : [];
    let walls = this.floorPlan ? this.floorPlan.Walls : [];
    let shapes = this.floorPlan ? this.floorPlan.Shapes : [];
    if (this.ts.selectedFloorPlanPopupId != null) {
      tables = cloneDeep(this.tables.filter(x => (x.FloorPlanViewId === this.ts.selectedFloorPlanPopupId)));
      if (this.ts.selectedArea) {
        tables = tables.filter(x => (x.SeatingAreaId === this.ts.selectedArea));
      }
      labels = labels.filter(x => x.FloorPlanViewId === this.ts.selectedFloorPlanPopupId);
      walls = walls.filter(x => x.FloorPlanViewId === this.ts.selectedFloorPlanPopupId);
      shapes = shapes.filter(x => x.FloorPlanViewId === this.ts.selectedFloorPlanPopupId);
    } else {
      if (this.ts.selectedArea) {
        tables = tables.filter(x => (x.SeatingAreaId === this.ts.selectedArea));
      } else {
        tables = cloneDeep(this.tables);
      }
    }
    if (tables.length > 0) {
      this.canvas.clear();
      if (document.getElementById(`${this.layoutConfig.from}-inline-btn`))
      document.getElementById(`${this.layoutConfig.from}-inline-btn`).innerHTML = ''; 
      if (this.tables.length > 0)
      this.setDefaultFloor(tables, labels, walls, shapes);
      if (this.selectedTables.length > 0) {
        this.selectedTables.forEach(x => {
          let data = this.canvas.getObjects().filter(obj => x.Id == obj.Id)[0];
          if (data) {
            if (this.layoutConfig.from === TableLayoutConfig.servers) {
              this.ss.editableMode ? this.addHighLightObject(data) : '';
            }
            else {
              this.addHighLightObject(data);
            }
          }
        });
      }
    } else {
      this.canvas.clear();
      if (document.getElementById(`${this.layoutConfig.from}-inline-btn`))
      document.getElementById(`${this.layoutConfig.from}-inline-btn`).innerHTML = ''; 
    }
  }

  setDefaultFloor(tables, labels?, walls?, shapes?) {
    this.dataToBePushed = [];
    labels.forEach((element, index) => {
      element.id = index;
      element.name = 'label';
    });
    walls.forEach((element, index) => {
      element.id = index;
      element.name = 'wall';
    });
    shapes.forEach((element, index) => {
      element.id = index;
      element.name = 'area';
    });
    tables = cloneDeep(tables);
    labels = cloneDeep(labels);
    walls = cloneDeep(walls);
    shapes = cloneDeep(shapes);
    let basicTables = this.lf.findminObject(tables, labels, walls, shapes);
    let topMinValue = minBy(basicTables, 'totalminDataHeight');
    let leftMinValue = minBy(basicTables, 'totalminDataWidth');
    const negativeCoords = basicTables.filter(x => x.totalminDataHeight < 0 || x.totalminDataWidth < 0);
    this.topMinValue = Math.abs(topMinValue['totalminDataHeight']);
    this.leftMinValue = Math.abs(leftMinValue['totalminDataWidth']);
    if (negativeCoords.length > 0) {
      // negative values
      let adjustedTopValue = topMinValue['totalminDataHeight'] < 0 ? Math.abs(topMinValue['totalminDataHeight']) : 0;;
      let adjustedLeftValue = leftMinValue['totalminDataWidth'] < 0 ? Math.abs(leftMinValue['totalminDataWidth']) : 0;
      tables.forEach(element => {
        element.Top = element.Top + Math.abs(adjustedTopValue);
        element.Left = element.Left + Math.abs(adjustedLeftValue);
      });
      labels.forEach(element => {
        element.Top = element.Top + Math.abs(adjustedTopValue);
        element.Left = element.Left + Math.abs(adjustedLeftValue);
      });
      walls.forEach(element => {
        element.Points.forEach(point => {
          point['X'] = point['X'] + adjustedLeftValue;
          point['Y'] = point['Y'] + adjustedTopValue;
        });
      });

      shapes.forEach(element => {
        element.Top = element.Top + Math.abs(adjustedTopValue);
        element.Left = element.Left + Math.abs(adjustedLeftValue);
      });

      // find new min val
      basicTables = this.lf.findminObject(tables, labels, walls, shapes);
      topMinValue = minBy(basicTables, 'totalminDataHeight');
      leftMinValue = minBy(basicTables, 'totalminDataWidth');
      this.topMinValue = Math.abs(topMinValue['totalminDataHeight']);
      this.leftMinValue = Math.abs(leftMinValue['totalminDataWidth']);

    }
    let adjustedTopValue = 0;
    let adjustedLeftValue = 0;
    if (this.topMinValue > 50 && this.floorPlan.ImageId == null) {
      adjustedTopValue = -1 * (this.topMinValue - 30);
    }
    if (this.leftMinValue > 50 && this.floorPlan.ImageId == null) {
      adjustedLeftValue = -1 * (this.leftMinValue - 30);
    }
    tables.forEach(element => {
      element.Top = element.Top + (element.Height / 2) + adjustedTopValue;
      element.Left = element.Left + (element.Width / 2) + adjustedLeftValue;
    });

    labels.forEach(element => {
      let findstringlength = element.Text.length;
      let getwidth = 11;
      let getHeight = 11;
      let angles = element.Angle;
      if ((angles >= 0 && angles <= 20) || (angles >= 160 && angles <= 200) || (angles >= 340 && angles <= 360)) {
        getwidth = (findstringlength * 10) / 2;
      } else {
        getHeight = (findstringlength * 10) / 2;
      }
      element.Top = element.Top + adjustedTopValue + getHeight;
      element.Left = element.Left + adjustedLeftValue + getwidth;
    });

    walls.forEach(element => {
      element.Points.forEach(point => {
        point['X'] = point['X'] + adjustedLeftValue;
        point['Y'] = point['Y'] + adjustedTopValue;
      });
    });

    shapes.forEach(element => {
      element.Top = element.Top + adjustedTopValue;
      element.Left = element.Left + adjustedLeftValue;
    });
    basicTables = this.lf.findmaxObject(tables, labels, walls, shapes);
    const topValue = maxBy(basicTables, 'totalDataHeight');
    const leftValue = maxBy(basicTables, 'totalDataWidth');
    this.heightValue = topValue['totalDataHeight'] + 25;
    this.widthValue = leftValue['totalDataWidth'] + 25;

    let scaleFactor;
    if (this.canvas) {
      console.log('clientHeight', this.canvasLoader.nativeElement.clientHeight, 'clientWidth', this.canvasLoader.nativeElement.clientWidth);
      this.scaleFactorY = this.canvasLoader.nativeElement.clientHeight / this.heightValue;
      this.scaleFactorX = this.canvasLoader.nativeElement.clientWidth / this.widthValue;
      if (this.scaleFactorX < 1 && this.scaleFactorY < 1) {
        scaleFactor = this.scaleFactorX <= this.scaleFactorY ? this.scaleFactorX : this.scaleFactorY;
      }
      else {
        scaleFactor = this.scaleFactorX >= this.scaleFactorY ? this.scaleFactorY : this.scaleFactorX;
      }
      this.canvas.setHeight(this.canvasLoader.nativeElement.clientHeight);
      this.canvas.setWidth(this.canvasLoader.nativeElement.clientWidth);
    }
    tables.forEach(element => {
      this.setTableConfiguration(element);
    });
    this.dataToBePushed.forEach(data => {
      this.canvas.add(data);
      data.set('subTargetCheck', true);
    })
    if (labels) {
      labels.forEach((element, index) => {
        this.addLabel(element, index);
      });
    }
    if (shapes) {
      shapes.forEach(element => {
        this.addShape(element);
      });
    }
    if (walls) {
      walls.forEach(element => {
        this.addWall(element);
      });
    }

    // ---> Scale the canvas Objects
    this.scaleCanvasObjects(scaleFactor);
    const objs = this.canvas.getObjects();
    if (this.ts.selectedTableBeforeAction$.value && this.layoutConfig.from == TableLayoutConfig.tables) {
      const data = objs.find(x => x.Id == this.ts.selectedTableBeforeAction$.value.Id);
      this.ts.selectedTableBeforeAction$.next(data);
      this.setSeletedObject(data);
    }
    tables.forEach(table => {
      table.UpcomingReservations = this.setReservationsList(table,PartyState.Pending);
      table.OnGoingReservations = this.setReservationsList(table , PartyState.Seated);
      this.lf.setTableIcons(table, objs, this.canvas, this.blockingRule);
    });
    objs.forEach((table) => {
      if (table.ImageId && (table.ShapeType == TableShapeType.RectangleBasic || table.ShapeType == TableShapeType.CircleBasic)) {
        this.setObjectImage(table);
      }
      if (table.ImageId && table.ShapeType == TableShapeType.Image) {
        this.setImageBorder(table);
      }
      this.createServerNameElement(table)
    })
    this.percentageValue = 100;
    this.ls.showMessage.next(false);
  }
  
  calculateMinTopLeftValues(tables, labels, walls, shapes) {
    let basicTables = this.lf.findminObject(tables, labels, walls, shapes);
    let topMinValue = minBy(basicTables, 'totalminDataHeight');
    let leftMinValue = minBy(basicTables, 'totalminDataWidth');
    this.topMinValue = Math.abs(topMinValue['totalminDataHeight']);
    this.leftMinValue = Math.abs(leftMinValue['totalminDataWidth']);
    this.adjustNegativeCoords(basicTables, tables, labels, walls, shapes, topMinValue, leftMinValue);
  }

  adjustNegativeCoords(basicTables, tables, labels, walls, shapes, topMinVal, leftMinVal) {
    const negativeCoords = basicTables.filter(x => x.totalminDataHeight < 0 || x.totalminDataWidth < 0);
    if (negativeCoords.length > 0) {
      // negative values
      let adjustedTopValue = topMinVal['totalminDataHeight'] < 0 ? Math.abs(topMinVal['totalminDataHeight']) : 0;;
      let adjustedLeftValue = leftMinVal['totalminDataWidth'] < 0 ? Math.abs(leftMinVal['totalminDataWidth']) : 0;
      tables.forEach(element => {
        element.Top = element.Top + Math.abs(adjustedTopValue);
        element.Left = element.Left + Math.abs(adjustedLeftValue);
      });
      labels.forEach(element => {
        element.Top = element.Top + Math.abs(adjustedTopValue);
        element.Left = element.Left + Math.abs(adjustedLeftValue);
      });
      walls.forEach(element => {
        element.Points.forEach(point => {
          point['X'] = point['X'] + adjustedLeftValue;
          point['Y'] = point['Y'] + adjustedTopValue;
        });
      });
      shapes.forEach(element => {
        element.Top = element.Top + Math.abs(adjustedTopValue);
        element.Left = element.Left + Math.abs(adjustedLeftValue);
      });
      // find new min val
      basicTables = this.lf.findminObject(tables, labels, walls, shapes);
      let topMinValue = minBy(basicTables, 'totalminDataHeight');
      let leftMinValue = minBy(basicTables, 'totalminDataWidth');
      this.topMinValue = Math.abs(topMinValue['totalminDataHeight']);
      this.leftMinValue = Math.abs(leftMinValue['totalminDataWidth']);
    }
  }

  calculateMaxHeightWidthValues(tables, labels, walls, shapes) {
    let basicTables;
    basicTables = this.lf.findmaxObject(tables, labels, walls, shapes);
    const topValue = maxBy(basicTables, 'totalDataHeight');
    const leftValue = maxBy(basicTables, 'totalDataWidth');
    this.heightValue = topValue['totalDataHeight'] + 25;
    this.widthValue = leftValue['totalDataWidth'] + 25;
    if (this.canvas) {
      this.scaleFactorY = this.canvasLoader.nativeElement.clientHeight / this.heightValue;
      this.scaleFactorX = this.canvasLoader.nativeElement.clientWidth / this.widthValue;
    }
  }

  setTableConfiguration(element) {
    if (element.ShapeType != undefined) {
      let type;
      switch (element.ShapeType) {
        case 0:
          type = ShapeTypeEnum.Rectangle;
          break;
        case 1:
          type = this.lf.checkTableiscircle(element);
          break;
        case 2:
          type = ShapeTypeEnum.Pin;
          break
        case 3:
          type = ShapeTypeEnum.BasicRectangle;
          break;
        case 4:
          type = ShapeTypeEnum.BasicCircle;
          break;
        case 8:
          type = ShapeTypeEnum.Image;
          break;
      }
      let adjustheightval = element.Height;
      let adjustwidthtval = element.Width;
      if (type == 'rect') {
        const party = null;
        this.rectangle = new fabric.Rect({
          left: element.Left,
          top: element.Top,
          height: adjustheightval,
          width: adjustwidthtval,
          fill: '#fff',
          name: 'shape',
          rx: 2,
          ry: 2,
          stroke: '#000',
          strokeWidth: 2
        });
        this.rectangle.set({
          name: 'shape'
        });
        this.tableObj = [];
        this.tableObj.push(this.rectangle);
        this.addTableGroup(this, this.rectangle, element, party);
      }
      else if (type == 'circle') {
        const party = null;
        this.circle = new fabric.Circle({
          left: element.Left,
          top: element.Top,
          height: adjustheightval,
          width: adjustwidthtval,
          radius: (adjustwidthtval / 2) > 15 ? (adjustwidthtval / 2) : 15,
          fill: '#fff',
          stroke: '#000',
          strokeWidth: 2
        });
        this.circle.set({
          name: 'shape'
        });
        this.addTableGroup(this, this.circle, element, party)
      }
      else if (type == ShapeTypeEnum.Ellipse) {
        this.ellipse = new fabric.Ellipse({
          left: element.Left,
          top: element.Top,
          fill: '#fff',
          rx: element.Width / 2,
          ry: element.Height / 2,
          stroke: '#000',
          strokeWidth: 2
        });
        this.ellipse.set({
          name: 'shape',
        });
        this.ellipse.setControlsVisibility({
          'ml': false,
          'mt': false,
          'mr': false,
          'mb': false,
        });
        this.addTableGroup(this, this.ellipse, element, false);
      } else if (type == ShapeTypeEnum.BasicRectangle) {
        const party = null;
        this.basicRectangle = new fabric.Rect({
          left: element.Left,
          top: element.Top,
          height: adjustheightval,
          width: adjustwidthtval,
          fill: '#fff',
          rx: 2,
          ry: 2,
          stroke: '#000',
          strokeWidth: 2
        });
        this.basicRectangle.set({
          name: 'shape',
          type: 'brect'
        });
        this.tableObj = [];
        this.tableObj.push(this.basicRectangle);
        this.addTableGroup(this, this.basicRectangle, element, party);
      } else if (type == ShapeTypeEnum.BasicCircle) {
        const party = null;
        this.basicCircle = new fabric.Circle({
          left: element.Left,
          top: element.Top,
          height: adjustheightval,
          width: adjustwidthtval,
          radius: (adjustwidthtval / 2) > 15 ? (adjustwidthtval / 2) : 15,
          fill: '#fff',
          stroke: '#000',
          strokeWidth: 2
        });
        this.basicCircle.set({
          name: 'shape',
          type: 'bcircle'
        });
        this.addTableGroup(this, this.basicCircle, element, party)
      } else if (type == ShapeTypeEnum.Pin) {
        const party = null;
        this.pin = new fabric.Circle({
          left: element.Left,
          top: element.Top,
          height: adjustheightval,
          width: adjustwidthtval,
          radius: (adjustwidthtval / 2) > 15 ? (adjustwidthtval / 2) : 15,
          fill: '#fff',
          stroke: '#000',
          strokeWidth: 2,
        });
        this.pin.set({
          name: 'shape',
          type: 'pin'
        });
        this.addTableGroup(this, this.pin, element, party)
      } else if (type == ShapeTypeEnum.Image) {
        const images = this.customImages?.filter((img) => img.ImageId == element.ImageId);
        if (images && images.length == 1) {
          const image = document.createElement('img');
          image.src = 'data:image/png;base64,' + images[0].bytes;
          if (!image.height && !image.width) {
            image.setAttribute('height', '180');
            image.setAttribute('width', '250');
          }
          this.image = new fabric.Image(image, {
            left: element.Left,
            top: element.Top,
            scaleX: adjustwidthtval / image.width,
            scaleY: adjustheightval / image.height,
            stroke: '#000',
            strokeWidth: 2,
          })
          this.image.set({
            name: 'shape',
            type: 'image',
            imgWidth: adjustwidthtval,
            imgHeight: adjustheightval
          });
          this.image.setControlsVisibility({
            'ml': false,
            'mt': false,
            'mr': false,
            'mb': false,
          });
          this.addTableGroup(this, this.image, element, false);
        } 
        else {
          const party = null;
          this.image = new fabric.Rect({
            left: element.Left,
            top: element.Top,
            height: adjustheightval,
            width: adjustwidthtval,
            fill: '#fff',
            name: 'shape',
            rx: 2,
            ry: 2,
            stroke: '#000',
            strokeWidth: 2,
          });
          this.image.set({
            name: 'shape'
          });
          this.tableObj = [];
          this.tableObj.push(this.image);
          this.addTableGroup(this, this.image, element, party);
          }
      }
    }
  }

  addTableGroup(that, object, element, party) {
    let blocked = false;
    that.tableObj = [];
    const restTime = format(new Date(Utilities.getRestaurantDateTime(this.cs.settings.value.General.DaylightDelta)), 'MM/DD/YYYY');
    let reservationDate = format(new Date(this.ps.reservationFormGroup.get('selectedDate').value), 'MM/DD/YYYY');
    if(reservationDate == "Invalid Date"){
      reservationDate = (this.ps.partiesList && this.ps.partiesList.length > 0) ? format(new Date(this.ps.partiesList[0].SeatingTime), 'MM/DD/YYYY') : "Invalid Date";
    }
    if((this.layoutConfig.from != TableLayoutConfig.tables) && reservationDate != "Invalid Date" && (Utilities.Date(reservationDate).diff(Utilities.Date(restTime)) > 0) ){
      this.layoutDataCopy.reservations =
      this.layoutConfig.reservations.filter(res => (Utilities.Date(res.SeatingTime).diff(Utilities.Date(reservationDate)) > 0) );
    }
    const statusColorData = this.lf.getStatusColor(element, this.layoutDataCopy, this.cs.settings.value)

    const tableIndex = this.blockingRule.findIndex(x => x == element.Id);
    if (tableIndex != -1 && statusColorData.partyData == null) {
      blocked = true;
      party = null;
    }
    object.set('fill', '#f2f2f2');
    if (!element.IsCommunalTable) {
      object.set('fill', statusColorData.fillColor);
    }
    if (object.type == TableShapeType.Image || object.type == ShapeTypeEnum.Image) {
      object.set('stroke', statusColorData.fillColor);
      object.set('strokeWidth', 2);
    }
    if ((object.type == TableShapeType.CircleBasic || object.type == ShapeTypeEnum.BasicCircle) && element && element.ImageId) {
      object.set('fill', '#f2f2f2');
    }
    that.tableObj.push(object);
    const data = this.lf.serverUnAssignedLines(object)    
    this.tableObj.push(data.leftLine);
    this.tableObj.push(data.rightLine);
    if ((element && element.ServerId == null) || !element) {
      const data = this.tableObj.filter(x => x.name == 'serverLines')
      data.forEach(element => {
        element.set('opacity', 1);
      });
    }
    else {
      const data = this.tableObj.filter(x => x.name == 'serverLines')
      data.forEach(element => {
        element.set('opacity', 0);
      });
    }
    that.groupedObject = new fabric.Group(that.tableObj, {
      top: object.top,
      left: object.left,
      originY: 'center',
      originX: 'center'
    });
    that.groupedObject.set({
      SeatingArea: element.SeatingArea,
      SeatingAreaId: element.SeatingAreaId,
      SeatingType: element.SeatingType,
      SeatingTypeId: element.SeatingTypeId,
      ServerId: element.ServerId,
      MaxPartySize: element.MaxPartySize,
      MinPartySize: element.MinPartySize,
      Name: element.Name,    
      Price: this.cs.operationCurrency + ' ' + element.Price,
      IsAvailableForBooking: element.IsAvailableForBooking,
      IsAvailableForReservations: element.IsAvailableForReservations,
      IsCommunalTable: element.IsCommunalTable,
      FloorPlanViewId: element.FloorPlanViewId,
      Id: element.Id,
      ShapeType: element.ShapeType,
      Party: statusColorData.partyData ? statusColorData.partyData : that.setParty(element, blocked),
      UpcomingReservations: that.setReservationsList(element , PartyState.Pending),
      OnGoingReservations: that.setReservationsList(element , PartyState.Seated),
      radius: element && element.radius > 0 ? element.radius : object.width / 2,
      Angle: element ? element.RotationAngle : 0,
      ImageId: element ? element.ImageId : null,
      EventId: element ? element.EventId : null,
    });
    if (object.type == 0 || object.type == ShapeTypeEnum.Rectangle) {
      const seatData = that.lf.drawRectangleSeat(that.groupedObject, that, '#3B4532');
      seatData.forEach(seat => {
        seat.set('strokeWidth', seat.strokeWidth * that.groupedObject.scaleX)
        that.groupedObject.addWithUpdate(seat);
        seat.moveTo(that.groupedObject.size() - 5)
      });
    }
    else if (object.type == 1 || object.type == ShapeTypeEnum.Circle) {
      const obj = cloneDeep(that.groupedObject)
      const seatData = that.lf.drawCircleSeat(obj, that, '#3B4532');
      seatData.forEach(seat => {
        that.groupedObject.addWithUpdate(seat);
        seat.moveTo(that.groupedObject.size() - 5)
      });
    }
    that.groupedObject.setCoords();
    const text1 = new fabric.Text(that.groupedObject.Name.toString(), {
      fill: 'white',
      textAlign: 'center',
      fontSize: 14,
      originX: 'center',
      originY: 'center',
      name: 'text',
      fontWeight: 'bold',
      top: that.groupedObject.top,
      left: that.groupedObject.left
    });
    const numberCircle = new fabric.Circle({
      fill: (element.IsCommunalTable || (statusColorData.partyData != null)) ? statusColorData.fillColor : statusColorData.numberColor,
      radius: 14,
      top: that.groupedObject.top,
      left: that.groupedObject.left,
      originX: 'center',
      originY: 'center',
      name: 'textCircle',
      opacity: .8,
      stroke: (element.IsCommunalTable || (statusColorData.partyData != null)) ? 'grey' : '',
      strokeWidth: (element.IsCommunalTable || (statusColorData.partyData != null)) ? 2 : 0
    });
    if (element) {
      that.groupedObject.rotate(element.RotationAngle);
    }
    const size = that.groupedObject.Party.Size && that.groupedObject.Party.State == 1 ? that.groupedObject.Party.Size : 0;
    const tableNumberText = that.groupedObject.IsCommunalTable ? (size + ' / ' + that.groupedObject.MaxPartySize.toString()) : that.groupedObject.MaxPartySize.toString();
    const tableNumber = new fabric.Text(tableNumberText, {
      textAlign: 'center',
      fontSize: 14,
      fill: statusColorData.textColor,
      originX: 'center',
      originY: 'center',
      name: 'tableNumber',
      fontWeight: 'bold',
      top: numberCircle.top - numberCircle.radius - IconSize[IconSizes[this.layoutIconSize]].TableSizeMoveTop,
      left: numberCircle.left,
    });
    this.addReservationTimingBubble(that.groupedObject, true);

    if(element && element.Price && element.Price != '0' && this.layoutConfig.from != TableLayoutConfig.tables && !this.layoutConfig.isAdvanceBlock)
    this.addTablePrice(that.groupedObject, true);

    // Map Server Names
    this.lf.mapServerToTable(element, object, that, this.serverDetails);
      tableNumber.scaleX = tableNumber.scaleX * IconSize[IconSizes[this.layoutIconSize]].TableSize;
      tableNumber.scaleY =   tableNumber.scaleY *IconSize[IconSizes[this.layoutIconSize]].TableSize;
    that.groupedObject.addWithUpdate(tableNumber);
    numberCircle.radius = numberCircle.radius * IconSize[IconSizes[this.layoutIconSize]].TableNameCircle;
    that.groupedObject.addWithUpdate(numberCircle);
    text1.fontSize = text1.fontSize * IconSize[IconSizes[this.layoutIconSize]].TableName;
    that.groupedObject.addWithUpdate(text1);
    that.groupedObject.addWithUpdate();
    let IsServerBackgroundAvailable = JSON.parse(localStorage.getItem('IsServerBackgroundAvailable'));
    if (element.ServerId && IsServerBackgroundAvailable) {
      // Commented server BG color as it is not required for the current release (July 29 2022)
      let serverColor = '';
      const serverval = this.serverDetails.find(sval => sval.Id === element.ServerId);
      serverColor = serverval.color;
      let serverBgColor = new fabric.Rect({
        left: that.groupedObject.left,
        top: that.groupedObject.top,
        height: (object.height * object.scaleY) + 30,
        width: (object.width * object.scaleX) + 30,
        fill: serverColor ? serverColor : '#1DA664',
        name: 'serverBgColor',
        rx: 5,
        ry: 5,
        opacity: (this.floorPlan?.ImageId && this.layoutImages?.length > 0 ) ? 0.4 : 0.2,
        originX: 'center',
        originY: 'center',
        stroke: serverColor,
        strokeWidth: 0.5
      });
      that.groupedObject._objects.forEach(element => {
        if (element.name == 'shape') {
          element.set({
            stroke: '#000',
            strokeWidth: 2
          })
        }
      });
      that.groupedObject.addWithUpdate(serverBgColor);
      serverBgColor.moveTo(0)
    }
    if (element && element.scaleX) {
      let tempScaleX = element.scaleX;
      if (tempScaleX == 0 || tempScaleX < 0.1) {
        tempScaleX = 1;
        that.groupedObject.set({
          scaleX: tempScaleX
        })
      }
    }
    if (element && element.scaleY) {
      let tempScaleY = element.scaleY;
      if (tempScaleY == 0 || tempScaleY < 0.1) {
        tempScaleY = 1;
        that.groupedObject.set({
          scaleY: tempScaleY
        })
      }
    }
    that.groupedObject.setCoords();
    if (object.type == TableShapeType.Image || object.type == ShapeTypeEnum.Image) {
      if (element && element.Angle) {
        that.groupedObject.set({
          angle: element.Angle
        })
      }
    }
    that.count++;   
    that.dataToBePushed.push(that.groupedObject);
  }
  getRandomColor(){
    //Random color used when there is no color assigned for server 
   let colors = ['green','blue','magenta','brown','cadetblue','salmon','olive'];
    return colors[Math.floor(Math.random() * colors.length)];
  }

  scaleCanvasObjects(scaleFactor) {
    let that = this;
    if (this.floorPlan?.ImageId) {
      if (this.layoutImages?.length > 0) {
        this.setBackgroundImage();
      }
      if (this.floorPlan.Dimensions && this.floorPlan.Dimensions.length > 0 && this.floorPlan.Dimensions[0].Width > 0 && this.floorPlan.Dimensions[0].Height > 0) {
        this.scaleFactorX = this.canvasLoader.nativeElement.clientWidth / this.floorPlan.Dimensions[0].Width;
        this.scaleFactorY = this.canvasLoader.nativeElement.clientHeight / this.floorPlan.Dimensions[0].Height;
        this.canvas.getObjects().forEach(element => {
          element.set({
            scaleX: element.scaleX * that.scaleFactorX,
            scaleY: element.scaleY * that.scaleFactorY,
            top: element.top * that.scaleFactorY,
            left: element.left * that.scaleFactorX
          })
          element.setCoords();
        });
      }
      else {
        this.lf.scaleObjects(this.canvas, this.scaleFactorX, this.scaleFactorY, scaleFactor);
      }
    } else {
      this.lf.scaleObjects(this.canvas, this.scaleFactorX, this.scaleFactorY, scaleFactor);
    }
  }

  setImageBorder(table) {
    const isTableOccupied = this.lf.checkTableIsOccupied(table, this.layoutConfig);
    if (isTableOccupied) {
      let statusColorData = this.lf.getStatusColor(table, this.layoutConfig, this.cs.settings.value);
      let imageRectangleObject = new fabric.Rect({
        width: table.width + 10,
        height: table.height + 10,
        originX: 'center',
        originY: 'center',
        name: 'imageBorder',
        top: table.top,
        left: table.left,
        stroke: statusColorData.fillColor,
        strokeWidth: 3,
        fill: '#fff',
        scaleX:table.scaleX,
        scaleY:table.scaleY,
      });
      table.addWithUpdate(imageRectangleObject);
      imageRectangleObject.moveTo(0);
    }
  }

  setObjectImage(table, isTableOccupied = false) {
    let selectedTableObject = this.canvas.getObjects().filter(tableObj => tableObj.Id == table.Id);
    let tableImage = this.objectImages.filter((img) => img.ImageId == table.ImageId);
    let statusColorData = this.lf.getStatusColor(table, this.layoutConfig, this.cs.settings.value);
    isTableOccupied = this.lf.checkTableIsOccupied(selectedTableObject[0], this.layoutConfig);
    if (selectedTableObject && selectedTableObject.length > 0 && tableImage.length > 0) {
      let compressedPhoto;
      fabric.Image.fromURL('data:image/png;base64,' + tableImage[0].bytes, (img) => {
        compressedPhoto = img;
        let tableObject;
          let shapedObject = selectedTableObject[0]._objects.filter(ele => ele.name == 'shape')[0];
          if (selectedTableObject[0].ShapeType == 4) {
              compressedPhoto.set({
                  top: selectedTableObject[0].top ,
                  left: selectedTableObject[0].left ,
                  clipTo: (ctx) => {
                      ctx.arc(0, 0, selectedTableObject[0].radius*(img.width/shapedObject.width), 0, Math.PI * 2, true);
                  },
                  originX: 'center',
                  originY: 'center',
                  name: 'image',
                  scaleX: ((shapedObject.width) / img.width) * (selectedTableObject[0].scaleX),
                  scaleY: ((shapedObject.height) / img.height) * (selectedTableObject[0].scaleY),
              });
              if (isTableOccupied) {
                tableObject = new fabric.Ellipse({
                  rx: (selectedTableObject[0].width/2) +10 ,
                  ry: (selectedTableObject[0].height/2) + 10,
                  name: 'seatObject',
                  fill: '#fff',
                  stroke: statusColorData.fillColor,
                  strokeWidth: 3,
                  top: selectedTableObject[0].top,
                  left: selectedTableObject[0].left,
                  originX: 'center',
                  originY: 'center',
                  scaleX:selectedTableObject[0].scaleX,
                  scaleY:selectedTableObject[0].scaleY,
                });
              }
          } else {
              compressedPhoto.set({
                  scaleX: ((shapedObject.width) / img.width) * (selectedTableObject[0].scaleX),
                  scaleY: ((shapedObject.height) / img.height) * (selectedTableObject[0].scaleY),
                  top: selectedTableObject[0].top ,
                  left: selectedTableObject[0].left ,
                  originX: 'center',
                  originY: 'center',
                  name: 'image',
                  width: img.width,
                  height: img.height
              });
              if (isTableOccupied) {
                tableObject = new fabric.Rect({
                  width: selectedTableObject[0].width + 10,
                  height: selectedTableObject[0].height + 10,
                  originX: 'center',
                  originY: 'center',
                  name: 'imageBorder',
                  top: selectedTableObject[0].top,
                  left: selectedTableObject[0].left,
                  stroke: statusColorData.fillColor,
                  strokeWidth: 3,
                  fill: '#fff',
                  scaleX:selectedTableObject[0].scaleX,
                  scaleY:selectedTableObject[0].scaleY,
                })
          }
        }
        let allObjects = selectedTableObject[0]._objects.filter(ele => ele.name == 'textCircle' ||
          ele.name == 'tableNumber' || ele.name == 'text' || ele.name == 'serverLines' || ele.name == 'timingBubble').length;
        let objectPositionLength = allObjects > 3 ? (allObjects + 1) : allObjects;
        selectedTableObject[0].addWithUpdate(compressedPhoto);
        compressedPhoto.moveTo(selectedTableObject[0].size() - objectPositionLength);
        if (tableObject) {
          selectedTableObject[0].addWithUpdate(tableObject);
          tableObject.moveTo(0);
        }
        selectedTableObject[0].setCoords();
        this.canvas.renderAll();
      })
    }
  }

  setBackgroundImage() {
    let selectedImage = this.layoutImages.filter((img: InputImages) => img.ImageId === this.floorPlan?.ImageId);
    if (selectedImage && selectedImage.length > 0) {
      this.canvas.setBackgroundImage('', this.canvas.renderAll.bind(this.canvas));
      fabric.Image.fromURL('data:image/png;base64,' + selectedImage[0].bytes, (img) => {

        this.canvas.setBackgroundImage(img,
          this.canvas.renderAll.bind(this.canvas), {
          scaleX: ((this.canvas.width) / img.width),
          scaleY: ((this.canvas.height) / img.height)
        });
      });
    }
  }

  getAdjustedTopValue() {
    let adjustedTopValue = 0;
    if (this.topMinValue > 50 && this.floorPlan.ImageId == null) {
      adjustedTopValue = -1 * (this.topMinValue - 30);
    }
    return adjustedTopValue;
  }

  getAdjustedLeftValue() {
    let adjustedLeftValue = 0;
    if (this.leftMinValue > 50 && this.floorPlan.ImageId == null) {
      adjustedLeftValue = -1 * (this.leftMinValue - 30);
    }
    return adjustedLeftValue;
  }

  scaleSingleCanvasObject(scaleFactor, object) {
    let that = this;
    if (this.floorPlan.ImageId) {
      if (this.floorPlan.Dimensions && this.floorPlan.Dimensions.length > 0 && this.floorPlan.Dimensions[0].Width > 0 && this.floorPlan.Dimensions[0].Height > 0) {
        this.scaleFactorX = this.canvasLoader.nativeElement.clientWidth / this.floorPlan.Dimensions[0].Width;
        this.scaleFactorY = this.canvasLoader.nativeElement.clientHeight / this.floorPlan.Dimensions[0].Height;
        object.set({
          scaleX: object.scaleX * that.scaleFactorX,
          scaleY: object.scaleY * that.scaleFactorY,
          top: object.top * that.scaleFactorY,
          left: object.left * that.scaleFactorX
        })
        object.setCoords();
      }
      else {
        this.lf.scaleObject(object, this.scaleFactorX, this.scaleFactorY, scaleFactor);
      }
    } else {
      this.lf.scaleObject(object, this.scaleFactorX, this.scaleFactorY, scaleFactor);
    }
  }

  partyDataChanges() {
    const reservationChange = differenceBy(this.layoutConfig.reservations, this.layoutData.reservations, 'Id');
    const reservationRemoval = differenceBy(this.layoutData.reservations, this.layoutConfig.reservations, 'Id');
    if (reservationChange.length > 0) {
      this.addOrRemoveReservations(reservationChange);
    }
    if (reservationRemoval.length > 0) {
      this.addOrRemoveReservations(reservationRemoval);
    }
  }

  addOrRemoveReservations(reservations) {
    const that = this;
    let adjustedTopValue = this.getAdjustedTopValue();
    let adjustedLeftValue = this.getAdjustedLeftValue();
    reservations.forEach(data => {
      data['TableIds'].forEach(tableId => {
        this.canvas.remove(that.canvas.getObjects().filter(x => x.Id == tableId)[0]);
        let removedTable = this.layoutConfig.floor[0].StandaloneTables.filter(x => x.Id == tableId)[0];
        removedTable.Left = removedTable.Left + (removedTable.Width / 2) + adjustedLeftValue;
        removedTable.Top = removedTable.Top + (removedTable.Height / 2) + adjustedTopValue;
        this.setTableConfiguration(removedTable);
      });
    })
  }

  resetServerSelectedTables() {
    const objs = this.canvas.getObjects();
    this.updatedUnselectedArray = [];
    objs.forEach(objElement => {
      const availableTable = this.editableServer.StandaloneTables.filter((table) => table.Id === objElement.id);
      if (objElement.selected && availableTable.length > 0) {
        this.updatedUnselectedArray.push(objElement.mappedTable ? objElement.mappedTable : objElement.id);
        const index = this.updatedSelectedArray.indexOf(objElement.id);
        if (index > -1) {
          this.updatedSelectedArray.splice(index, 1);
          this.ss.selectedTableIds = [...this.updatedSelectedArray]
        }
      }
    });
    if (this.updatedUnselectedArray.length > 0) {
      this.ss.unselectedTableIds = [...this.updatedUnselectedArray];
    }
    this.ss.deSelectTablesOnUnassignAllEvent.next();
    this.ps.tableSelected.next(true);
  }

  setSelectedTables(tables) {
    const objs = this.canvas.getObjects();
    if (this.layoutConfig.from === TableLayoutConfig.preferredTables && this.gbs.selectedTables.Id) {
      objs.forEach(table => {
        if (table.Id === this.gbs.selectedTables.Id) {
          this.animationCounter = 0;
          this.selectedTables.push(table);
          let rectangle = this.addHighLightObject(table);
          // this.animateObject(rectangle, 0, 0);
        }
      });
    } else {
      objs.forEach(element => {
        const availableTable = tables.filter((table) => table === element.Id);
        if (availableTable.length > 0) {
          this.selectedTables.push(element);
          this.animationCounter = 0;
          let rectangle = this.addHighLightObject(element);
          // this.animateObject(rectangle, 0, 0);
        }
      });
    }
    this.selectedTables = uniq(this.selectedTables);
    this.canvas.renderAll();
  }

  resetSelectedTables() {
    const objs = this.canvas.getObjects();
    if (this.ss.editableMode) {
      objs.forEach(objElement => {
        if (objElement.selected && objElement.Id && this.ss.unselectedTableIds.indexOf(objElement.Id) > -1) {
          objElement.selected = false;
        }
      });
    }
    else {
      objs.forEach(objElement => {
        if (objElement.selected && objElement.Id) {
          objElement.selected = false;
          this.removeHightLightColorObject(objElement);
        }
      });
    }
    this.canvas.renderAll();
  }

  mapSelectedTables(tables) {
    this.ps.highlightSelectedTables = [];
    const objs = this.canvas.getObjects();
    objs.forEach(element => {
      const availableTable = tables.filter((table) => table === element.id);
      if (availableTable.length > 0) {
        element.selected = true;
        element.set('fill', '');
        this.updatedSelectedArray.push(element.id);
        this.updatedSelectedTableNames.push(element.name);
        this.updatedSelectedArrayForCommunalTable.push({ tableId: element.id, isCommunal: element.iscommunaltable });
      }
    });
    tables.forEach(tableId => {
      const table = this.layoutConfig.tables.filter(x => x.Id == tableId);
      if (table && table.length > 0) {
        this.updatedSelectedArray.push(table[0].Id);
        this.updatedSelectedTableNames.push(table[0].Name);
        this.updatedSelectedArrayForCommunalTable.push({ tableId: table[0].Id, isCommunal: table[0].IsCommunalTable });
      }
    })
    if (this.updatedSelectedArray.length > 0) {
      this.updatedSelectedArray = [...new Set(this.updatedSelectedArray)];
      this.updatedSelectedTableNames = [...new Set(this.updatedSelectedTableNames)];
      this.updatedSelectedArrayForCommunalTable = this.updatedSelectedArrayForCommunalTable.reduce((unique, o) => {
        if (!unique.some(obj => obj.tableId === o.tableId && obj.isCommunal === o.isCommunal)) {
          unique.push(o);
        }
        return unique;
      }, []);
      this.canvas.renderAll();
      this.ps.highlightSelectedTables = this.updatedSelectedArray;
      if (this.seatPartyData && this.seatPartyData.seatParty) {
        this.ps.SelectedTableIds = this.updatedSelectedArray;
      }
      if (this.layoutConfig && this.layoutConfig.from === TableLayoutConfig.reservationTableSelection) {
        this.ps.tableSelected.next(this.updatedSelectedArray);
      }
    }
  }

  mapSelectedServerTables() {
    this.updatedSelectedArray = [];
    this.serverDetails.forEach(server => {
      if (server.StandaloneTables != null && server.StandaloneTables != undefined && server.StandaloneTables.length > 0) {
        server.StandaloneTables.forEach((table) => {
          let selectedTable = this.layoutConfig.tables.filter(layoutTable => layoutTable.Id == table.Id);
          if (selectedTable && selectedTable.length > 0) {
            selectedTable.forEach((layoutTable) =>{
              if (layoutTable.ServerId == this.editableServer.Id) {
                this.updatedSelectedArray.push(layoutTable.Id);
              }
            })
          }
        })
      }
    })
  }

  highlightSelectedServers() {
    this.selectedTables = [];
    const objs = this.canvas.getObjects();
    objs.forEach(table => {
      const isServerMatched = this.editableServer.Id === table.ServerId;
      if (isServerMatched && !table.selected) {
        table.selected = true;
        this.selectedTables.push(table);
        let rectangle = this.addHighLightObject(table);
        // this.animateObject(rectangle, 0, 0);
      }
    });
    this.canvas.renderAll();
  }

  addHighLightObject(table) {
    if (this.layoutConfig && this.layoutConfig.from === TableLayoutConfig.tables)
    {
      return;
    }
    let shape = table.getObjects().filter(x => x.name == 'shape')[0];
    let serverData = table.getObjects().filter(x => x.name == 'serverCircle');
    let join = table.getObjects().filter(x => x.name == 'join');
    let notes = table.getObjects().filter(x => x.name == 'notes');
    let shapetype = shape.get('type');
    let icontop = (table.top) + ((table.height * table.scaleY) / 2);
    let iconleft = table.left - ((table.width * table.scaleX) / 2);
    if (shapetype == "circle") {

      let x = shape.radius * Math.sin(Math.PI * 2 * 45 / 360);
      let y = shape.radius * Math.cos(Math.PI * 2 * 45 / 360);
      let newval = join && join.length > 0 || notes && notes.length > 0 || shape.radius <= 10 ? 10 : 3;
      let notesval = notes && notes.length > 0 && shape.radius <= 10 ? 8 : shape.radius <= 10 ? 3 : 0;
      icontop = (icontop - newval) - (y / 2);
      iconleft = iconleft + notesval + (x / 2) - ((shape.radius / 25) * 5);

    } else if (shapetype == 'ellipse') {


      let x = shape.rx * Math.sin(Math.PI * 2 * 45 / 360);
      let y = shape.ry * Math.cos(Math.PI * 2 * 45 / 360);
      if (shape.angle) {
        x = shape.rx * Math.sin(Math.PI * 2 * shape.angle / 360);
        y = shape.ry * Math.cos(Math.PI * 2 * shape.angle / 360);
        y = y < 0 ? -1 * y : y;
      }
      icontop = (icontop) - (y / 2);
      if (shape.angle)
        iconleft = iconleft + 25;
    }
    else {
      icontop = shape.angle ? icontop - 22 : icontop - 10;
      iconleft = join && join.length > 0 ? iconleft + 6 : iconleft;
    }

    let myobj = cloneDeep(this.cs.TableselectIcon);
    if (myobj) {
      myobj.set({
        top: icontop,
        left: iconleft
      });
      myobj.set({
        name: 'highlight',
      });
      myobj.scaleToWidth(IconSize[IconSizes[this.layoutIconSize]].Check);
      myobj.scaleToHeight(IconSize[IconSizes[this.layoutIconSize]].Check);
      table.addWithUpdate(myobj);
      myobj.bringToFront();
      this.canvas.renderAll();
      //  this.canvas.add(rectangle);
      return myobj;
    }

  }

 

  removeHightLightColorObject(obj) {
    obj._objects.filter(x => x.name == 'highlight').forEach(element => {
      obj.removeWithUpdate(element);
    });
  }

  selectedhiglightTable(obj) {
    const objs = this.canvas.getObjects();
    let p = this.canvas.getPointer(obj.e);
    if (obj.target != null) {
      this.objectsSelected = [];
      let selectedval = obj.target;
      let getzoom = this.canvas.getZoom();
      let adjustvalX = this.canvas.viewportTransform[4] / getzoom;
      let adjustvalY = this.canvas.viewportTransform[5] / getzoom;

      objs.forEach(object => {
        object.setCoords;
        let coords = object.calcCoords();
        let distX = Math.abs(p.x - object.left),
          distY = Math.abs(p.y - object.top),
          dist = (Math.sqrt(Math.pow(distX, 2) + Math.pow(distY, 2))),
          objectXend = (coords.br.x / getzoom) - adjustvalX,
          objectYend = (coords.br.y / getzoom) - adjustvalY,
          objectXstart = (coords.tl.x / getzoom) - adjustvalX,
          objectYstart = (coords.tl.y / getzoom) - adjustvalY
          ;


        if (selectedval.Id && selectedval.Id != object.Id) {
          let newopacity = .6;
          if (object.Id && (objectXstart <= p.x && objectXend >= p.x) && (objectYstart <= p.y && objectYend >= p.y)) {
            newopacity = 1;
          }
          if (object.name == "dummy") {
            newopacity = 1;
          }
          object.set('opacity', newopacity);

        }
      });
      this.canvas.renderAll();

      const objsnew = this.canvas.getObjects();
      let newval = objsnew.filter(x => x.opacity >= 1 && x.name != "dummy" && selectedval.Id != x.Id);
      if (newval.length > 1) {
        let minObj = null;
        let minLength = 10000000;

        let origX = p.x;
        let origY = p.y;
        objsnew.forEach(object => {
          if (object.name != "dummy" && object.opacity >= 1) {
            if (Math.pow(object.left - origX, 2) + Math.pow(object.top - origY, 2) < minLength) {
              minObj = object;
              minLength = Math.pow(object.left - origX, 2) + Math.pow(object.top - origY, 2);
            }
          }
        });
        objsnew.forEach(object => {
          if (object == minObj) {
            object.set('opacity', 1);
          } else {
            if (object.name != "dummy" && selectedval.Id != object.Id) {
              object.set('opacity', .2);
            }
          }
        });
        this.canvas.renderAll();
      }

    }



  }

  updateTablemoveTable(obj) {
    const objs = this.canvas.getObjects();
    let val = this.removeIllusion();

    if (obj.target != null && this.isMouseDown && val) {
      this.objectsSelected = [];
      let selectedval = obj.target;

      const object = objs.filter(x => x.Id && selectedval.Id != x.Id && x.opacity >= 1);

      if (object && object.length > 0) {
        this.ts.dragedTablesId.next(object[0].Id);
      }
    }
  }

  removeHightLightColor(obj, _that) {
    this.isMouseDown = false;
    const objs = _that.canvas.getObjects();
    objs.forEach(object => {
      object.set('opacity', 1);
    });
  }

  onSelectionCleared(eve, that) {
      that.selectionCleared.emit(eve);
  }

  removeIllusion() {
    let val = this.canvas.getObjects().filter(x => x.name == 'dummy')[0]
    if (val) {
      this.canvas.remove(val);
      return true;
    }
    return false
  }

  TableMoving(eve, that) {
    this.removeIllusion();
    if (this.selectedTableDetails) {
      let pointer = that.canvas.getPointer(eve.e);

      var rect1 = new fabric.Rect({
        width: 30,
        height: 30,
        fill: that.selectedTableDetails["Colors"],
        stroke: 'rgba(34,177,76,1)',
        strokeWidth: .8,
        originX: 'center',
        originY: 'center',
        opacity: 0.7

      });
      let partysize = that.selectedTableDetails["NoofParty"] + "";
      var text1 = new fabric.Text(partysize, {
        fontSize: 15,
        originX: 'center',
        originY: 'center',
        fill: '#000000',
      });
      var group1 = new fabric.Group([rect1, text1], {
        left: pointer.x,
        top: pointer.y
      });

      let partyName = that.selectedTableDetails["PartyName"];
      const truncatedName = partyName.length > 15 ? `${partyName.substring(0, 15)}...` : partyName;
      var text2 = new fabric.Text(truncatedName, {
        fontSize: 14,
        originX: 'center',
        originY: 'center',
        fill: '#ffffff',
      });


      var rect2 = new fabric.Rect({
        width: 120,
        height: 30,
        fill: '#000000',
        stroke: '#000000',
        strokeWidth: .8,
        originX: 'center',
        originY: 'center',
        opacity: 0.7

      });
      var group2 = new fabric.Group([rect2, text2], {
        left: pointer.x + 30,
        top: pointer.y

      });
      var group = new fabric.Group([group1, group2], {
        left: pointer.x,
        top: pointer.y,
        name: 'dummy'
      });
      this.canvas.add(group);
      this.ts.tableDragStart.next(true);
    }
  }

  addLabel(obj, index) {
    obj.id = index;
    obj.name = 'label';
    this.canvas.add(new fabric.Text(obj.Text.toString(), {
      fontFamily: 'Roboto Light',
      left: obj.Left,
      top: obj.Top,
      fontSize: 20,
      fontWeight: 'bold',
      fill: '#0000008A',
      data: index,
      name: 'label',
      selectable: false,
      angle: obj.Angle,
      originX: 'center',
      originY: 'center'
    }));
    this.canvas.renderAll();
  }

  addShape(element) {
    let shape;
    let newcolor = element.Color ? 'rgba(' + element.Color.R + ',' + element.Color.G + ',' + element.Color.B + ',' + element.Color.A + ')' : '#3B4532'
    if (element.ShapeType != undefined) {
      let type;
      switch (element.ShapeType) {
        case 0:
          type = ShapeTypeEnum.Rectangle
          break;
        case 1:
          type = this.lf.checkTableiscircle(element);
          break;
        case 2:
          type = ShapeTypeEnum.Pin;
          break
        case 3:
          type = ShapeTypeEnum.BasicRectangle;
          break;
        case 4:
          type = ShapeTypeEnum.BasicCircle;
          break;
      }
      if (type == ShapeTypeEnum.Rectangle) {
        shape = new fabric.Rect({
          left: element.Left,
          top: element.Top,
          height: element.Height,
          width: element.Width,
          stroke: newcolor,
          strokeWidth: 2,
          strokeDashArray: [8, 3],
          name: 'area',
          fill: '',
          angle: element.Rotation,
          selectable: false
        });
      } else if (type == ShapeTypeEnum.Circle) {

        shape = new fabric.Circle({
          radius: (element.Width > element.Height ? element.Height : element.Width) / 2,
          left: element.Left,
          top: element.Top,
          height: element.Height,
          width: element.Width,
          stroke: newcolor,
          strokeWidth: 2,
          strokeDashArray: [8, 3],
          name: 'area',
          fill: 'transparent',
          angle: element.Rotation,
          perPixelTargetFind: true,
        });
      } else if (type == ShapeTypeEnum.Ellipse) {

        shape = new fabric.Ellipse({
          left: element.Left,
          top: element.Top,
          rx: element.Width / 2,
          ry: element.Height / 2,
          stroke: newcolor,
          strokeWidth: 2,
          strokeDashArray: [8, 3],
          name: 'area',
          fill: 'transparent',
          angle: element.Rotation,
          perPixelTargetFind: true,
        });

      } else if (type == ShapeTypeEnum.BasicRectangle) {
        shape = new fabric.Rect({
          left: element.Left,
          top: element.Top,
          height: element.Height,
          width: element.Width,
          stroke: newcolor,
          strokeWidth: 2,
          strokeDashArray: [8, 3],
          name: 'area',
          fill: '',
          angle: element.Rotation,
          selectable: false,
        });
        shape.set({
          type: 'brect'
        })
      } else if (type == ShapeTypeEnum.BasicCircle) {
        shape = new fabric.Circle({
          radius: (element.Width > element.Height ? element.Height : element.Width) / 2,
          left: element.Left,
          top: element.Top,
          height: element.Height,
          width: element.Width,
          stroke: newcolor,
          strokeWidth: 2,
          strokeDashArray: [8, 3],
          name: 'area',
          fill: 'transparent',
          angle: element.Rotation,
          perPixelTargetFind: true,
        });
        shape.set({
          type: 'bcircle'
        })
      } else if (type == ShapeTypeEnum.Pin) {
        shape = new fabric.Circle({
          radius: (element.Width > element.Height ? element.Height : element.Width) / 2,
          left: element.Left,
          top: element.Top,
          height: element.Height,
          width: element.Width,
          stroke: newcolor,
          strokeWidth: 2,
          strokeDashArray: [8, 3],
          name: 'area',
          fill: 'transparent',
          angle: element.Rotation,
          perPixelTargetFind: true,
        });
        shape.set({
          type: 'pin'
        })
      }
    }
    this.canvas.add(shape)
    this.canvas.sendToBack(shape);
    this.canvas.renderAll();
  }

  addWall(element) {
    element.name = 'wall'
    let linesArray = []
    element.Points.forEach(point => {
      linesArray.push({ x: point.X, y: point.Y })
    });
    let wall = new fabric.Polyline(linesArray, {
      stroke: '#3B4532',
      strokeWidth: 2,
      fill: 'transparent',
      data: element.id,
      name: 'wall',
      perPixelTargetFind: true,
    });
    this.canvas.add(wall)
    this.canvas.sendToBack(wall);
    this.canvas.renderAll();
  }

  setReservationsList(table , reservationState?) {
    let upcomingReservationsTemp = [];
    if (this.layoutConfig.reservations && this.layoutConfig.reservations.length > 0) {
      this.layoutConfig.reservations.forEach(reservation => {
        let index = reservation.TableIds.findIndex(tableId => tableId == table.Id);
        if (reservation.Type == ReservationType.Reservation && index > -1 && reservation.State == reservationState) {
          upcomingReservationsTemp.push(reservation);
        }
      });
    }

    if (upcomingReservationsTemp.length > 0) {
      sortBy(upcomingReservationsTemp, 'ReservedFor')
    }

    return upcomingReservationsTemp;

  }

  zoomIn() {
    if (this.percentageValue == 180) {
      this.zoomInBtn.disbaledproperity = true;
      this.zoomOutBtn.disbaledproperity = false;
    }
    else {
      this.zoomInBtn.disbaledproperity = false;
      this.zoomOutBtn.disbaledproperity = false;
      this.percentageValue = this.percentageValue + 10;
      this.canvas.setZoom(this.canvas.getZoom() * 1.1);
      this.canvas.setWidth(this.canvas.getWidth() * 1.1);
      this.canvas.setHeight(this.canvas.getHeight() * 1.1);
    }
    if (document.getElementById(`${this.layoutConfig.from}-inline-btn`))
    document.getElementById(`${this.layoutConfig.from}-inline-btn`).innerHTML = '';
    const objs = this.canvas.getObjects();
    objs.forEach((table) => {
      this.createServerNameElement(table)
    })
  }

  zoomOut() {
    if (this.percentageValue == 20) {
      this.zoomOutBtn.disbaledproperity = true;
      this.zoomInBtn.disbaledproperity = false;
    }
    else {
      this.zoomOutBtn.disbaledproperity = false;
      this.zoomInBtn.disbaledproperity = false;
      this.percentageValue = this.percentageValue - 10;
      this.canvas.setZoom(this.canvas.getZoom() / 1.1);
      this.canvas.setWidth(this.canvas.getWidth() / 1.1);
      this.canvas.setHeight(this.canvas.getHeight() / 1.1);
    }
    if (document.getElementById(`${this.layoutConfig.from}-inline-btn`))
    document.getElementById(`${this.layoutConfig.from}-inline-btn`).innerHTML = '';
    const objs = this.canvas.getObjects();
    let serverNameTimeout = setTimeout(() => {
      objs.forEach((table) => {
        this.createServerNameElement(table)
      })
      clearTimeout(serverNameTimeout);
    })
    
  }

  viewFullScreen() {
    this.canvas.setViewportTransform([1, 0, 0, 1, 0, 0]);
    if (this.canvas.getZoom() != 1 || this.percentageValue < 100) {
      this.canvas.setZoom(1);
      this.canvas.clear();
      if (document.getElementById(`${this.layoutConfig.from}-inline-btn`))
      document.getElementById(`${this.layoutConfig.from}-inline-btn`).innerHTML = ''; 
      this.canvas.calcViewportBoundaries();
      this.setFloorplanChanges(this.ts.selectedFloorPlanId);
    }
    if (this.isDragEnable) {
      if (document.getElementById(`${this.layoutConfig.from}-inline-btn`))
      document.getElementById(`${this.layoutConfig.from}-inline-btn`).innerHTML = '';
      const objs = this.canvas.getObjects();
      objs.forEach((table) => {
        this.createServerNameElement(table)
      })
    }
    this.percentageValue = 100;
  }

  setSeletedObject(data) {
    const objs = this.canvas.getObjects();
    let selectedElement;
    const that = this;
    if (data) {
      objs.forEach(element => {
        if (element.Id && element.Id == data.Id) {
          selectedElement = element;
          element._objects.forEach(element => {
            if (element.name == 'shape' || element.name == 'seat') {
              that.canvas.setActiveObject(element);
              element.set({
                stroke: '#1DA664'
              })
              if (element.name == 'shape') {
                element.set({
                  strokeWidth: 2
                })
              }
            }
          });
        }
      });
      this.canvas.renderAll();
    };
  }

  setParty(table, status) {
    const party = { State: null, TableIds: [], Contact: [], MaxPartySize: 0, MinPartySize: 0, ServerId: null };
    if (status) {
      party.TableIds.push(table.Id);
      party.State = PartyState.Blocked;
      party.Contact = [];
    }
    else {
      party.TableIds.push(table.Id);
      party.State = PartyState.Open;
      party.Contact = [];
      party.MaxPartySize = table.MaxPartySize;
      party.MinPartySize = table.MinPartySize;
      party.ServerId = table.ServerId
    }
    return party;
    //this.dashboardData.partyInfo = party;
  }

  selectedTable(obj, ismouseDowm?) {
    const objs = this.canvas.getObjects();
    if (this.layoutConfig.from === TableLayoutConfig.reservationTableSelection) {
      if (this.ps.isAssignTables && obj.target && !obj.target.ServerId) {
        return;
      }
    }
    if (this.layoutConfig.from === TableLayoutConfig.preferredTables) {
      const objs = this.canvas.getObjects();
      objs.forEach(object => {
        if (object._objects) {
          let isServerBGColorAvailable = object._objects.find(objName => objName.name == 'serverBgColor');
          object._objects.forEach(tableObjectes => {
            this.removeHightLightColorObject(object);
            if (tableObjectes.name === 'shape') {
              tableObjectes.set({
                stroke: '#000'
              });
            }
          });
        }
      });
    }
    if (this.layoutConfig.from == TableLayoutConfig.tables) {
      objs.forEach(object => {
        if (object._objects) {
          let isServerBGColorAvailable = object._objects.find(objName => objName.name == 'serverBgColor');
          object._objects.forEach(element => {
            if (element.name == 'seat') {
              element.set({
                stroke: '#000'
              })
            }
            if (element.name == 'shape') {
              element.set({
                stroke: '#000'
              })
            }
            if (isServerBGColorAvailable && element.name == 'shape') {
              element.set({
                stroke: '#000'
              })
            }
          });
        }
      });
    }
    if (obj.target != null) {
      let selectedval = obj.target;
      this.selectedTableDetails = null;
      if (obj.subTargets && obj.subTargets.length > 0 && obj.subTargets.find(x => x.name && (x.name == 'showUpcomingReservationTimingsList' || x.name == 'timingBubble'))) {
        let ResevationTimings = [];
        if (obj.target.UpcomingReservations && obj.target.UpcomingReservations.length > 0) {

          obj.target.UpcomingReservations.forEach(reservation => {
            const datePipeString = this.datePipe.transform(reservation.ReservedFor, 'dd-MM-yyyy h:mm a');
            ResevationTimings.push(datePipeString);
          });

          this.showTimingsPopUp(ResevationTimings);
        }
      }
      else {
        if (this.layoutConfig.from == TableLayoutConfig.tables) {
          this.objectsSelected = [];
          if (obj.target._objects) {
            obj.target._objects.forEach(element => {
              if (element.name == 'shape' || element.name == 'seat') {
                element.set({
                  stroke: '#1DA664',
                  //   strokeWidth: 3
                })
                if (element.name == 'shape') {
                  element.set({
                    strokeWidth: 2
                  });
                  if (obj.target.Party && obj.target.Party.State == PartyState.Seated && obj.target.Party.Contact && obj.target.Party.Contact.Id && ismouseDowm) {
                    let name = (obj.target.Party.Contact.FirstName || obj.target.Party.Contact.LastName) ? (obj.target.Party.Contact.FirstName ? obj.target.Party.Contact.FirstName : '') + ' ' + (obj.target.Party.Contact.LastName ? obj.target.Party.Contact.LastName : '') : "Unnamed";
                    this.selectedTableDetails = { "Colors": element.fill, "NoofParty": obj.target.Party.Size, "PartyName": name, "Id": obj.target.Id };
                    this.isMouseDown = true;
                  }
                }
  
              }
              if (ismouseDowm && element.name == 'textCircle' && obj.target.IsCommunalTable && obj.target.Party && obj.target.Party.State == PartyState.Seated && obj.target.Party.Contact && obj.target.Party.Contact.Id) {
                let name = (obj.target.Party.Contact.FirstName || obj.target.Party.Contact.LastName) ? (obj.target.Party.Contact.FirstName ? obj.target.Party.Contact.FirstName : '') + ' ' + (obj.target.Party.Contact.LastName ? obj.target.Party.Contact.LastName : '') : "Unnamed";
                this.selectedTableDetails = { "Colors": element.fill, "NoofParty": obj.target.Party.Size, "PartyName": name };
                this.isMouseDown = true;
              }
            });
          }
          let selectedObjVal = obj.target; 
          this.ps.selectedTableLocationId = selectedObjVal.Id;
          if (obj.target && !obj.target.Id && this.canvas._activeObject) {
            selectedObjVal = this.canvas._activeObject.group;
          }
          this.objectsSelected.push(obj.target);
          if (obj.target._objects) {
            this.ts.selectedTableBeforeAction$.next(selectedObjVal);
            // this.shrinkTablesView.emit();
          }
        } else {
          this.animationCounter = 0;
          if (obj.target && !obj.target.Id) {
            selectedval = this.canvas._activeObject.group;
          }
          let index = this.selectedTables.findIndex(x => x.Id == selectedval.Id);
          if (index == -1) {
            this.selectedTables.push(selectedval);
            let table = selectedval;
            let rectangle = this.addHighLightObject(table);
            // this.animateObject(rectangle, 0, 0);
          }
          else {
            selectedval.selected = true;
            this.selectedTables.splice(index, 1);
            this.removeHightLightColorObject(selectedval);
          }
          this.objectsSelected = [];
          if (obj.target && !obj.target.Id) {
            selectedval = this.canvas._activeObject.group;
          }
          this.objectsSelected.push(selectedval);
          this.selectedTableObject(selectedval);
        }
      }
    } else if (this.ts.selectedTableBeforeAction$.value != null && obj.target == null) {
      //  && this.layoutConfig.from !== TableLayoutConfig.tables
      this.ts.selectedTableBeforeAction$.next(obj.target);
      this.selectionCleared.emit(obj.target);
    }
    if (this.ts.selectedTableBeforeAction$.value && this.layoutConfig.from == TableLayoutConfig.tables) {
      const data = objs.find(x => x.Id == this.ts.selectedTableBeforeAction$.value.Id);
      this.setSeletedObject(data);
    }
    this.canvas.renderAll();
  }

  selectedTableObject(event) {
    if (event) {
      if (this.layoutConfig.from === TableLayoutConfig.preferredTables) {
        if (event.Id) {
          if (!event.selected) {
            this.emitSelectedObject(event);
          }
          else {
            event.selected = false;
            this.emitSelectedObject('');
          }
        }
      }
      if (this.layoutConfig.from === TableLayoutConfig.reservationTableSelection) {
        if (this.ps.isAssignTables && event.ServerId) {
          this.selectedObjectForReservation(event);
        } else if (!this.ps.isAssignTables) {
          this.selectedObjectForReservation(event);
        }
      }
      if (this.ss.editableMode && this.layoutConfig.from === TableLayoutConfig.servers) {
        this.selectedObjectForServers(event);
      }
    }
  }

  emitSelectedObject(obj) {
    let tables = []
    if (obj.Id) {
      obj._objects.forEach(element => {
        if (element.name === 'shape') {
          element.set({
            strokeWidth: 2
          });
        }
      });
      tables.push(obj.Id);
    } else if (obj.mappedTable) {
      const objs = this.canvas.getObjects();
      objs.forEach(element => {
        if (element.id != obj.mappedTable) {
          if (element.id == undefined) {
            element.set('fill', this.objectTextColor);
          } else {
            element.set('stroke', this.objectTextColor);
            element.set('strokeWidth', 1);
          }
        } else {
          element.set('stroke', 'green');
          element.set('strokeWidth', 3)
          element.set('fill', '');
        }
      });
      obj = objs.filter(x => x.id == obj.mappedTable)[0];
    }
    this.canvas.renderAll();
    this.ps.tableSelected.next(obj);
    this.selectedTableEvent.emit(tables);
  }

  selectedObjectForReservation(obj) {
    let tableSelect = true;
    let tablename = "";
    let tableId = obj.mappedTable ? obj.mappedTable : obj.Id;
    let table = this.updatedSelectedArrayForCommunalTable.filter(table => table.tableId == tableId);

    if (table && table.length > 0) {
      tableSelect = true;
    } else {
      if (this.ps.reservationType != ReservationType.Blockingrule && this.ps.reservationType != ReservationType.Unblock) {
        if (this.updatedSelectedArrayForCommunalTable.some(table => table.isCommunal == true)) {
          tableSelect = false;
        }
        else {
          if (this.updatedSelectedArrayForCommunalTable.length > 0 && obj.IsCommunalTable) {
            tableSelect = false;
          } else {
            tableSelect = true;
          }
        }
      }
    }
    if (tableSelect) {
      if (obj.Id) {
        tablename = obj.Name;
        if (!obj.selected) {
          obj.selected = true;
        } else {
          obj.selected = false;
        }
      } else if (obj.mappedTable) {
        const objs = this.canvas.getObjects();
        objs.forEach(element => {
          if (element.id == obj.mappedTable) {
            tablename = element.name;
            if (!obj.selected) {
              obj.selected = true;
            } else {
              obj.selected = false;
            }
          }
        });
      }
    }
    const index = this.updatedSelectedArray.indexOf(tableId);
    const index1 = this.updatedSelectedArrayForCommunalTable.findIndex(table => table.tableId == tableId);
    const index2 = this.updatedSelectedTableNames.indexOf(obj.Name);
    if (obj.selected) {
      if (tableSelect) {
        this.updatedSelectedArray.push(tableId);
        this.updatedSelectedTableNames.push(obj.Name);
        if (this.ps.reservationType !== ReservationType.Blockingrule && this.ps.reservationType !== ReservationType.Unblock) {
          this.updatedSelectedArrayForCommunalTable.push({ tableId, isCommunal: obj.IsCommunalTable });
        }
      }
    } else if (index !== -1) {
      this.updatedSelectedArray.splice(index, 1);
      if (this.ps.reservationType != ReservationType.Blockingrule && this.ps.reservationType != ReservationType.Unblock) {
        this.updatedSelectedArrayForCommunalTable.splice(index1, 1);
      }
      this.updatedSelectedTableNames.splice(index2, 1);
    }
    //removes duplicate values
    this.updatedSelectedArray = [...new Set(this.updatedSelectedArray)];
    this.updatedSelectedTableNames = [...new Set(this.updatedSelectedTableNames)];
    this.updatedSelectedArrayForCommunalTable = this.updatedSelectedArrayForCommunalTable.reduce((unique, o) => {
      if (!unique.some(obj => obj.tableId === o.tableId && obj.isCommunal === o.isCommunal)) {
        unique.push(o);
      }
      return unique;
    }, []);
    this.canvas.renderAll();   
    if (this.seatPartyData && this.seatPartyData.seatParty) {
      this.ps.SelectedTableIds = this.updatedSelectedArray;
      if (tableSelect) {
        this.selectedTableEvent.emit(this.ps.SelectedTableIds);
      }
    } else {
      this.ps.tableSelected.next(this.updatedSelectedArray);
    }
    this.ps.tableSelectedNames.next(tablename);
    if (!tableSelect) {
      obj.selected = false;
      let message = this.translateService.instant('communalTableError');
      this.removeHightLightColorObject(obj);
      const popUpMessage = [{
        confirmationMessage: message, dialogTitle: 'confirm', showAlert: true
      }];
      this.popupService.restrictCloseDialog = false;
      const componentDetails: ComponentDetails = {
        componentName: ConfirmationPopupComponent,
        popupType: 'static',
        popUpDetails: {
          isStepper: false,
          eventName: 'notifyParent'
        },
        popupInput: popUpMessage,
        popupTitle: this.translateService.instant('alert')
      };
      const dialogRef = this.dialog.open(CustomPopupComponent, {
        disableClose: true,
        width: '450px',
        height: 'auto',
        data: {
          title: this.translateService.instant('alert'),
          showAction: true,
          update: 'ok',
          cancel: 'cancel',
          componentDetails,
          from: ComponentTypes.reservation,
          standalone: true,
        }
      });
    }
  }

  selectedObjectForServers(obj) {
    if (obj.Id) {
      if (!obj.selected) {
        obj.selected = true;
      } else {
        obj.selected = false;
      }
    } else if (obj.mappedTable) {
      const objs = this.canvas.getObjects();
      objs.forEach(element => {
        if (element.id === obj.mappedTable) {
          if (!obj.selected) {
            obj.selected = true;
            element.set('fill', this.selectedObjectFillColor);
          } else {
            obj.selected = false;
            element.set('fill', '');
          }
        }
      });
    }
    const index = this.updatedSelectedArray.indexOf(obj.mappedTable ? obj.mappedTable : obj.Id);
    if (obj.ServerId === this.editableServer.Id) {
      const tableIndex = this.updatedUnselectedArray.indexOf(obj.mappedTable ? obj.mappedTable : obj.Id);
      if (obj.selected) {
        if (tableIndex > -1) {
          this.updatedUnselectedArray.splice(tableIndex, 1);
        }
        this.updatedSelectedArray.push(obj.mappedTable ? obj.mappedTable : obj.Id);
      } else {
        if (index > -1) {
          this.updatedSelectedArray.splice(index, 1);
        }
        this.updatedUnselectedArray.push(obj.mappedTable ? obj.mappedTable : obj.Id);
      }
    } else {
      if (obj.selected) {
        this.updatedSelectedArray.push(obj.mappedTable ? obj.mappedTable : obj.Id);
      } else {
        this.updatedSelectedArray.splice(index, 1);
      }
    }
    this.updatedSelectedArray = [...new Set(this.updatedSelectedArray)];
    this.updatedUnselectedArray = [...new Set(this.updatedUnselectedArray)];
    this.ss.selectedTableIds = [...this.updatedSelectedArray];
    this.ss.unselectedTableIds = [...this.updatedUnselectedArray];
    this.canvas.renderAll();
    const isTableEdited = this.updatedSelectedArray.length > 0 || this.updatedUnselectedArray.length > 0;
    this.ps.tableSelected.next(isTableEdited);
  }

  showTimingsPopUp(timingsList) {
    const componentDetails: ComponentDetails = {
      componentName: ReservationTimesPopupComponent,
      popupType: 'action',
      popUpDetails: {
        isStepper: false,
        eventName: 'notifyParent'
      },
      popupInput: timingsList,
      popupTitle: 'Times'
    };

    const timingPopUp = this.dialog.open(CustomPopupComponent, {
      disableClose: true,
      width: '260px',
      height: '380px',
      data: {
        title: 'Times',
        showAction: true,
        update: 'ok',
        componentDetails,
        from: ComponentTypes.ReservationTimesPopup,
      }
    });

    let confirmSubscription = this.popupService.confirmedAction$.subscribe((data) => {
      if (data && data == ComponentTypes.ReservationTimesPopup && timingPopUp) {
        timingPopUp.close();
      }
    });

    let cancelSubscription = this.popupService.cancelledAction$.subscribe((data) => {
      if (data && data.from == ComponentTypes.ReservationTimesPopup && timingPopUp) {
        timingPopUp.close();
      }
    });

    this.subscriptions.add(timingPopUp.afterClosed().subscribe(() => {
      if (confirmSubscription) { confirmSubscription.unsubscribe(); }
      if (cancelSubscription) { cancelSubscription.unsubscribe(); }
    }));
  }

  UpdateResevationInTables() {
    if (this.layoutConfig && this.layoutConfig.reservations && this.layoutConfig.reservations.length > 0) {
      let objs = this.canvas.getObjects()
      let holdTimeBeyondReservationInMinutes = this.cs.settings.value.General.HoldTimeBeyondReservationInMinutes;
      let holdTimeBeforeReservationInMinutes = this.cs.settings.value.General.HoldTimeBeforeReservationInMinutes;
      let currentRestaurantTime = Utilities.getRestaurantDateTime(this.cs.settings.value.General.DaylightDelta);
      let reservationsToShow = this.layoutConfig.reservations.filter(reservation => {
        if (reservation.Type == PartyType.Reservation && reservation.TableIds && reservation.TableIds.length > 0 && reservation.State == PartyState.Pending) {
          let isResevationTimeElapsed = moment(reservation.ReservedFor) < moment(currentRestaurantTime);
          let holdReservation = isResevationTimeElapsed ?
            moment(currentRestaurantTime).diff(moment(reservation.ReservedFor), "minutes") <= holdTimeBeyondReservationInMinutes : moment(reservation.ReservedFor).diff(moment(currentRestaurantTime), "minutes") <= holdTimeBeforeReservationInMinutes
          if (holdReservation) {
            return reservation;
          }
        }
      });
      if (reservationsToShow && reservationsToShow.length > 0) {
        reservationsToShow.forEach(reservation => {
          reservation.TableIds.forEach(tableId => {
            let table = this.canvas.getObjects().filter(x => x.Id == tableId)[0];
            if (table) {
              const tableIndex = this.blockingRule.findIndex(x => x == table.Id);
              const statusColorData = this.lf.getStatusColor(table, this.layoutConfig, this.cs.settings.value)
              let blocked = false;
              if (tableIndex != -1 && statusColorData.partyData == null) {
                blocked = true;
                //party = null;
              }
              let object = table._objects.filter(x => x.name && x.name == 'shape')[0];
              let reservationTimingsBubbles = table._objects.filter(x => x.name && (x.name == 'timingBubble' || x.name == 'showUpcomingReservationTimingsList'));
              if (reservationTimingsBubbles && reservationTimingsBubbles.length > 0) {
                reservationTimingsBubbles.forEach(element => {
                  table.removeWithUpdate(element);
                });

              }
              object.set('fill', '#f2f2f2');
              if (!table.IsCommunalTable) {
                object.set('fill', statusColorData.fillColor);
              }
              else {
                let textCircle = table._objects.filter(x => x.name && x.name == 'textCircle')[0];
                textCircle.set('fill', statusColorData.fillColor);
              }
              table.Party = statusColorData.partyData ? statusColorData.partyData : this.setParty(table, blocked)
              this.addReservationTimingBubble(table, false);
            }
          });
        });
      }

      this.canvas.renderAll();
    }
  }

  addReservationTimingBubble(object, notUpdate: boolean) {
    if (this.layoutConfig.from !== TableLayoutConfig.servers) {
      let upcomingReservationsText: any = [];
      let count = 0;
      if (object.UpcomingReservations && object.UpcomingReservations.length > 0) {
        let holdTimeBeyondReservationInMinutes = this.cs.settings.value.General.HoldTimeBeyondReservationInMinutes;
        let currentRestaurantTime = Utilities.getRestaurantDateTime(this.cs.settings.value.General.DaylightDelta);
        let top = 0;
        let left = 0;
        object.UpcomingReservations.forEach((reservation) => {
          if (moment(currentRestaurantTime) < moment(reservation.ReservedFor) || moment(currentRestaurantTime).diff(moment(reservation.ReservedFor), "minutes") < holdTimeBeyondReservationInMinutes) {
            if (count < 2) {
              count++;
              const datePipeString = this.datePipe.transform(reservation.ReservedFor, 'h:mm a');
              const rect = new fabric.Rect({
                width: 40,
                height: 15,
                strokeWidth: 2,
                originX: 'center',
                originY: 'center',
                rx: 5,
                ry: 5,
                fill: '#1DA664',
              });
              const text = new fabric.Text(datePipeString, {
                fontSize: 10,
                originX: 'center',
                originY: 'center',
                fill: 'white'
              });
              let topCalculation = notUpdate ? (object.height * object.scaleY / 2) : object.ServerId ? (object.height * object.scaleY / 2) - 25 : (object.height * object.scaleY / 2);
              let leftCalculation = notUpdate ? (object.width * object.scaleX / 2) : object.ServerId ? (object.width * object.scaleX / 2) + 5 : (object.width * object.scaleX / 2);
              upcomingReservationsText = new fabric.Group([rect, text], {
                top: ( (object.top - 10) - topCalculation + top) * IconSize[IconSizes[this.layoutIconSize]].TimeBubbleGap,
                left: ((object.left - 10) - leftCalculation + left) ,
                name: 'timingBubble'
              });
              top = notUpdate ? top + 25 : object.ServerId ? top + 15 : top + 25;
              left = notUpdate ? left + 10 : object.ServerId ? left + 15 : left + 10;
              upcomingReservationsText.scaleX = upcomingReservationsText.scaleX * IconSize[IconSizes[this.layoutIconSize]].TimeBubble;
              upcomingReservationsText.scaleY =   upcomingReservationsText.scaleY *IconSize[IconSizes[this.layoutIconSize]].TimeBubble;
              object.addWithUpdate(upcomingReservationsText);
            }
            else if (count == 2) {
              count++;
              const rect = new fabric.Rect({
                width: 12,
                height: 12,
                strokeWidth: 2,
                originX: 'center',
                originY: 'center',
                rx: 5,
                ry: 5,
                fill: '#3C9CD8',

                //stroke: 'red'
              });
              const text = new fabric.Text('+', {
                fontSize: 14,
                originX: 'center',
                originY: 'center',
                fill: 'white',

              });
              let topCalculation = notUpdate ? object.top - (object.height * object.scaleY / 2) + 30 : object.ServerId ? object.top - (object.height * object.scaleY / 2) + 45 : object.top - (object.height * object.scaleY / 2) + 30;
              let leftCalculation = object.left - (object.width * object.scaleX / 2);
              upcomingReservationsText = new fabric.Group([rect, text], {
                top: topCalculation,
                left: leftCalculation,
                name: 'showUpcomingReservationTimingsList',
                data: object.Id,

              });
              upcomingReservationsText.scaleX = upcomingReservationsText.scaleX * IconSize[IconSizes[this.layoutIconSize]].TimeBubble
              upcomingReservationsText.scaleY =   upcomingReservationsText.scaleY *IconSize[IconSizes[this.layoutIconSize]].TimeBubble
              object.addWithUpdate(upcomingReservationsText);
            }
          }
        });
      }
    }
  }

  addTablePrice(object, notUpdate: boolean) {
    if (this.layoutConfig.from !== TableLayoutConfig.servers) {
      let tablePriceText: any = [];    
      const rect = new fabric.Rect({
        width: 40,
        height: 15,
        strokeWidth: 2,
        originX: 'center',
        originY: 'center',
        rx: 5,
        ry: 5,
        fill: '#B93535',
      });
      const text = new fabric.Text(object.Price, {
        fontSize: 10,
        originX: 'center',
        originY: 'center',
        fill: 'white'
      });

      let topCalculation = notUpdate ? (object.height * object.scaleY / 2) : object.ServerId ? (object.height * object.scaleY / 2) - 25 : (object.height * object.scaleY / 2);
      let leftCalculation = notUpdate ? (object.width * object.scaleX / 2) : object.ServerId ? (object.width * object.scaleX / 2) + 5 : (object.width * object.scaleX / 2); 
      tablePriceText = new fabric.Group([rect, text], {
        top: (object.top - 10) - topCalculation,
        left: (object.left  + leftCalculation) - rect.width, 
        name: 'tablePriceText'
      }); 
      tablePriceText.scaleToWidth(IconSize[IconSizes[this.layoutIconSize]].TablePrice);
      tablePriceText.scaleToHeight(IconSize[IconSizes[this.layoutIconSize]].TablePrice);
        object.addWithUpdate(tablePriceText);
    }
  }

  toggleDragMode(dragMode) {
    // Remember the previous X and Y coordinates for delta calculations
    // Keep track of the state
    let state = this.STATE_IDLE;
    // We're entering dragmode
    if (dragMode && this.isDragEnable) {
      // Discard any active object
      this.canvas.discardActiveObject();
      // Set the cursor to 'move'
      this.canvas.defaultCursor = 'move';
      // Loop over all objects and disable events / selectable. We remember its value in a temp variable stored on each object
      this.canvas.forEachObject((object) => {
        object.prevEvented = object.evented;
        object.prevSelectable = object.selectable;
        object.evented = false;
        object.selectable = false;
      });
      // Remove selection ability on the canvas
      this.canvas.selection = false;
      // When MouseUp fires, we set the state to idle
      this.canvas.on('mouse:up', (e) => {
        if (this.isDragEnable) {
          state = this.STATE_IDLE;
          this.canvas.forEachObject((table) => {
            let boundingProperties = table.getBoundingRect();
            let clientXVal = boundingProperties.left - table.left ;
            let clientYVal = boundingProperties.top - table.top;
            this.createServerNameElement(table, clientXVal, clientYVal);
          })
        }
      });
      // When MouseDown fires, we set the state to panning
      this.canvas.on('mouse:down', (e) => {
        if (this.isDragEnable) {
          state = this.STATE_PANNING;
          this.lastClientX = e.e.clientX;
          this.lastClientY = e.e.clientY;
          if (document.getElementById(`${this.layoutConfig.from}-inline-btn`)) {
            document.getElementById(`${this.layoutConfig.from}-inline-btn`).innerHTML = '';
          }
        }
      });
      // When the mouse moves, and we're panning (mouse down), we continue
      this.canvas.on('mouse:move', (e) => {
        if (state === this.STATE_PANNING && e && e.e && this.isDragEnable) {
          // let delta = new fabric.Point(e.e.movementX, e.e.movementY); // No Safari support for movementX and movementY
          // For cross-browser compatibility, I had to manually keep track of the delta
          // Calculate deltas
          let deltaX = 0;
          let deltaY = 0;
          if (this.lastClientX) {
            deltaX = e.e.clientX - this.lastClientX;
          }
          if (this.lastClientY) {
            deltaY = e.e.clientY - this.lastClientY;
          }
          // Update the last X and Y values
          this.lastClientX = e.e.clientX;
          this.lastClientY = e.e.clientY;
          let delta = new fabric.Point(deltaX, deltaY);
          if (this.isDragEnable) {
            this.canvas.relativePan(delta);
            this.canvas.trigger('moved');
          }
        }
      });
    } else {
      // When we exit dragmode, we restore the previous values on all objects
      this.canvas.forEachObject(function (object) {
        object.evented = (object.prevEvented !== undefined) ? object.prevEvented : object.evented;
        object.selectable = (object.prevSelectable !== undefined) ? object.prevSelectable : object.selectable;
      });
      // Reset the cursor
      this.canvas.defaultCursor = 'default';
      // Remove the event listeners
      /*  this.canvas.off('mouse:up');
       this.canvas.off('mouse:down');
       this.canvas.off('mouse:move'); */
      // Restore selection ability on the canvas
      this.canvas.selection = true;
    }
  }

  pinchIn(e) {
    let delta = e.deltaY;
    let zoom = this.canvas.getZoom();
    zoom *= 0.999 ** delta;
    if (zoom > 10) zoom = 10;
    if (zoom < 1) zoom = 1;
    if (zoom == 1) {
      this.canvas.setViewportTransform([1,0,0,1,0,0]);
      this.canvas.calcViewportBoundaries();
    }
    let point = new fabric.Point(e.center.x, e.center.y);
    this.canvas.zoomToPoint({ x: e.center.x - 150, y: e.center.y - 150 }, zoom);
    let vpt = this.viewportTransform;
    if (zoom < 400 / 1000) {
      vpt[4] = 200 - 1000 * zoom / 2;
      vpt[5] = 200 - 1000 * zoom / 2;
    } else {
      if (vpt[4] >= 0) {
        vpt[4] = 0;
      } else if (vpt[4] < this.canvas.getWidth() - 1000 * zoom) {
        vpt[4] = this.canvas.getWidth() - 1000 * zoom;
      }
      if (vpt[5] >= 0) {
        vpt[5] = 0;
      }
      else if (vpt[5] < this.canvas.getHeight() - 1000 * zoom) {
        vpt[5] = this.canvas.getHeight() - 1000 * zoom;
      }
    }
  }



  pinchOut(e) {
    let delta = e.deltaY;
    if (e.deltaY <= 0) {
      e.deltaY = 10;
      delta = e.deltaY;
    }
    let zoom = this.canvas.getZoom();
    zoom *= 1.001 ** delta;
    if (zoom > 10) zoom = 10;
    if (zoom < 1) zoom = 1;
    this.canvas.zoomToPoint({ x: e.center.x + 150, y: e.center.y + 150 }, zoom);
    let vpt = this.viewportTransform;
    if (zoom < 400 / 1000) {
      vpt[4] = 200 - 1000 * zoom / 2;
      vpt[5] = 200 - 1000 * zoom / 2;
    } else {
      if (vpt[4] >= 0) {
        vpt[4] = 0;
      } else if (vpt[4] < this.canvas.getWidth() - 1000 * zoom) {
        vpt[4] = this.canvas.getWidth() - 1000 * zoom;
      }
      if (vpt[5] >= 0) {
        vpt[5] = 0;
      }
      else if (vpt[5] < this.canvas.getHeight() - 1000 * zoom) {
        vpt[5] = this.canvas.getHeight() - 1000 * zoom;
      }
    }
    if (zoom == 1) {
      this.canvas.setViewportTransform([1,0,0,1,0,0]);
      this.canvas.calcViewportBoundaries();
    }
  }

  swipeFn(event) {
    if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|Mobile|mobile/i.test(navigator.userAgent)) {
      let delta = new fabric.Point(event.deltaX + 50, event.deltaY + 50);
      this.canvas.relativePan(delta);
    }
  }

  ngOnDestroy() {
    this.ps.highlightSelectedTables = [];
    this.ts.selectedFloorPlanId = null;
    this.ts.selectedFloorPlanPopupId = null;
    this.tableActiveObjectSubscription ? this.tableActiveObjectSubscription.unsubscribe() : '';
    this.partyUnselectedEventSubscription ? this.partyUnselectedEventSubscription.unsubscribe() : '';
    this.selectedFloorPlanSubscription ? this.selectedFloorPlanSubscription.unsubscribe() : '';
    this.subscriptions ? this.subscriptions.unsubscribe() : '';
    if (document.getElementById(`${this.layoutConfig.from}-inline-btn`))
        document.getElementById(`${this.layoutConfig.from}-inline-btn`).innerHTML = '';  
    if (this.canvas) {
      this.canvas.removeListeners();
      this.canvas.clear();
    }
  }
}
