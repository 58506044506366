import { ChangeDetectionStrategy, ChangeDetectorRef, Component, DEFAULT_CURRENCY_CODE, Directive, ElementRef, EventEmitter, HostListener, Inject, Input, OnChanges, OnInit, Output, Pipe, PipeTransform, SimpleChanges, ViewChild, ViewEncapsulation } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';
import { ApiService } from '@app/activities-timeline/services/api.service';
import { AppService } from '@app/app.service';
import { CacheService } from '@app/core/services/cache.service';
import { CustomPopupComponent } from '@app/popup-module/components/custom-popup/custom-popup.component';
import { ComponentDetails } from '@app/popup-module/models/popup.interface';
import { PopupService } from '@app/popup-module/popup.service';
import { BookingBehavior, buttonTypes, ComponentTypes, PaymentGateways, PaymentStates, ReservationEmailNotificationType, SingleDayActivityType, ActivityType, PartyType, PaymentMethod, LoginOperation, OperationResultState, ValidationMessageType } from '@app/shared/constants/commonenums';
import { ActivitiesVenue, DEFAULT_LANGUAGE_ID, controlSettings, popupDialogDimension } from '@app/shared/constants/globalConstants';
import { DynamicFormComponent } from '@app/shared/dynamicform/dynamic-form/dynamic-form.component';
import { ButtonValue, FieldConfig } from '@app/shared/dynamicform/models/field-config.interface';
import { LoginResultDTO, RestaurantAvailableForLoginDTO } from '@app/shared/models/LoginResultDTO';
import { BookingChargeType, LayoutDTO, LockMode, SettingsDTO, SupportedReservationEmailConfirmationTypes, TaxableItem } from '@app/shared/models/RestaurantDTO';
import { LoadMultiplePropertiesService } from '@app/shared/services/load-multiple-properties.service';
import { PartyService } from '@app/shared/services/party.service';
import { DashboardFunctions } from '@app/shared/utilities/dashboard-functions';
import { Utilities } from '@app/shared/utilities/utilities';
import { environment } from '@environments/environment';
import { TranslateService } from '@ngx-translate/core';
import { format } from 'date-fns';
import _, { groupBy } from 'lodash';
import moment from 'moment';
// import { Key } from 'protractor';
import { Subscription, interval } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { ConfirmationPopupComponent } from '../confirmation-popup/confirmation-popup.component';
import { SelectBookingTypeComponent } from '../select-booking-type/select-reservation-type.component';
import { RetailLoginService } from '@app/shared/services/retail-login.service';
import { AppPopupComponent } from '@app/popup-module/components/app-popup/app-popup.component';
import { NgbPopover } from '@ng-bootstrap/ng-bootstrap';
import { MultiplePropertyUnlockRequestDTO } from '@app/shared/models/ActivityLockSessionDTO';
import { CartWindowMessageType } from '@app/common/components/menu/vcart/vcart.modal';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-cart-items-display',
  templateUrl: './cart-items-display.component.html',
  styleUrls: ['./cart-items-display.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class CartItemsDisplayComponent implements OnInit, OnChanges {
  DataGridList: any;
  buttonValueProceed: ButtonValue;
  buttonValueClose: ButtonValue;
  applyPromoCode: ButtonValue;
  clearCartBtn: ButtonValue;
  selectAllBtn: ButtonValue;
  unselectAllBtn: ButtonValue;
  proceedBtn: ButtonValue;
  proceedPaymentBtn: ButtonValue;
  releaseBtn: ButtonValue;
  continuebtn: ButtonValue;
  cartSumaryDataList = [];
  groupedCartSummaryDataList: any;
  @Input() showCartItem;
  @Input() count;
  @Input() cartUpdated;
  @ViewChild('applyPromoCodePopover') private promoCodePopover: NgbPopover;
  lodash = _;
  subscriptions: Subscription = new Subscription();
  _settings: SettingsDTO;
  _layout: LayoutDTO = {} as LayoutDTO;
  locationList: any = [];
  instructorList: any = [];
  numberOfItemsSelected: number = 0;
  packageDiscount: number = 0;
  cartTotal: number = 0;
  cartTax: number = 0;
  cartVAT: number = 0;
  cartServiceCharge: number = 0;
  VAT: number = 0;
  cartServiceChargeWithVAT: number = 0;
  cartServiceChargeWithTax: number = 0;
  VATSCAmt: number = 0;
  promoDiscount: number = 0;
  subTotal: number = 0;
  tax: number = 0;
  serviceCharges: number = 0;
  serviceChargesWithTax: number = 0;
  locked: boolean = false;
  showIframe: boolean = false;
  showCartLockWarning: boolean = false;
  iframeURL: any;
  PaymentGateway: number;
  ReservationAttemptData: any;
  isUserAllowedToclickConfirm;
  waiverFormTrancationId;
  messageDialogRef: MatDialogRef<any>;
  IsLockTimer = false;
  componentDetails: ComponentDetails;
  warningInfoDialogRef: any;
  isActivityProperty = false;
  packageDiscountAmount: number
  searchconfig: FieldConfig[];
  cartNegotiatedAmount: number = 0;
  negotiatedAmount: number = 0;
  @ViewChild('searchConfigForm', { static: true }) searchConfigForm: DynamicFormComponent;
  searchText: string = '';
  searchConfigFormSubscription: Subscription;
  CartExpireTime: Date;
  Timer: number;
  timerApplyButtonConfig: ButtonValue;
  byPassLock = false;
  minutesExtended: number =0;
  isPackageApplied : boolean = false;
  promoCodeEligibleProperty:number[] = [];
  @ViewChild('timerPopup') public popover: NgbPopover;
  constructor(public dialogRef: MatDialogRef<CartItemsDisplayComponent>,
    @Inject(MAT_DIALOG_DATA) public dialogData, public dashboardData: DashboardFunctions,
    public partyService: PartyService, public ts: TranslateService, public cs: CacheService
    , private sanitizer: DomSanitizer, private popupService: PopupService, public dialog: MatDialog,
    public _as: AppService, private ls: LoadMultiplePropertiesService, private api: ApiService,private rls:RetailLoginService,private ref:ChangeDetectorRef , public toastrService?: ToastrService) {
    const propType = this.cs.settings.value.PropertyType;
    this.subscriptions.add(this.cs.settings.subscribe(sett => {
      this._settings = sett;
      this.minutesExtended = (Number(this._settings.General.SlotLockInSec) || 0) / 60;
    }));
    this.subscriptions.add(cs.layout.subscribe(layt => {
      this._layout = layt;
    }));
    this.loadOptions();
  }

  @HostListener('window:message', ['$event'])
  onMessage({ origin, data }) {
    if (!this.showCartItem || (origin != environment.authorizeIframeResponseOrigin && origin != environment.rGuestPayIframeResponsOrigin)) { // set your origin
      return false;
    }
    if ((this.partyService.activityBookingDialogRef && this.partyService.activityBookingDialogRef?.getState() !== 2)
      || (this.partyService.openBookingDialogRef && this.partyService.openBookingDialogRef?.getState() !== 2)
      || (this.partyService.privateLessonBookingDialogRef && this.partyService.privateLessonBookingDialogRef?.getState() !== 2)
      || (this.partyService.additionalChargePaymentIframeDialogRef && this.partyService.additionalChargePaymentIframeDialogRef?.getState() !== 2)) {
      return false;
    }
    var rGuestPayRespose = data;
    var payResponse = data;
    if (typeof data != "object") {
      rGuestPayRespose = JSON.parse(data);
    }
    if ((data.type == "txn-completed" && data.transactionInfo && data.transactionInfo.transactionInfo && data.transactionInfo.transactionInfo.payments) || (rGuestPayRespose && (rGuestPayRespose.code || rGuestPayRespose.gatewayResponseData))) {
      if (this.PaymentGateway == PaymentGateways.AuthorizePay) { //Authorize Pay

        var transInfo = payResponse.transactionInfo.transactionInfo;
        if (transInfo.errorMessage || transInfo.successCode != 1) {
          if (transInfo.errorMessage) {
            this.showMessage(transInfo.errorMessage);
          }
          else {
            this.showMessage(this.ts.instant("CardInformationProcessNotCompleted"));
          }
        } else if (payResponse && transInfo && transInfo.successCode == 1) {

          var transactions = this.processTransactions(transInfo);
          var dto = {
            RestaurantId: Utilities.RestaurantId(),
            PartyId: null,
            SlotLockIds: this.cartSumaryDataList.filter(({ selected }) => selected).map(({ LockSlots }) => LockSlots)['flat']().map(({ Id }) => Id),
            BookingID: null,
            TokenType: "",
            PaymentAmount: transInfo.paidAmount,
            PaymentGateway: this.PaymentGateway,
            AuthorizeTransactions: transactions,
            ReservationAttemptIds: this.getAttemptIds(),
          };

          this.UpdateReservationAttempt(dto);
        } else {
          this.showMessage(this.ts.instant("CardInformationProcessNotCompleted"));
        }
      }
      else {
        if (rGuestPayRespose.code && rGuestPayRespose.code != "100") {
          rGuestPayRespose = JSON.parse(data);
          if (rGuestPayRespose.validationErrors && rGuestPayRespose.validationErrors.length > 0) {
            var errorString = "";
            rGuestPayRespose.validationErrors.forEach(function (error, i, arr) {
              errorString = error.fieldName + " " + error.value + "\n";
            })
            this.showMessage(errorString);
          }
          else if (rGuestPayRespose && rGuestPayRespose.gatewayResponseData && rGuestPayRespose.gatewayResponseData.message) {
            this.showMessage(rGuestPayRespose.gatewayResponseData.message);
          }
          else {
            this.showMessage(this.ts.instant("CardInformationProcessNotCompleted"));
          }
        }
        else if (rGuestPayRespose && rGuestPayRespose.gatewayResponseData && rGuestPayRespose.gatewayResponseData.code == "100") {
          let callbackDto = {
            ReservationAttemptIds: this.getAttemptIds(),
            CardNumber: rGuestPayRespose.cardInfo.accountNumberMasked,
            ExpirationDate: new Date(data.cardInfo.expirationYearMonth.substring(0, 4) + '-' + data.cardInfo.expirationYearMonth.substring(4) + '-01'),
            CVC: "",
            FirstNameHolder: "",
            LastNameHolder: "",
            AddressLine1: "",
            AddressLine2: "",
            City: "",
            State: "",
            PostalCode: "",
            TokenType: "",
            Token: data.token
          };
          this.UpdateReservationAttempt(callbackDto);
        }
        else {
          this.showMessage(this.ts.instant("CardInformationProcessNotCompleted"));
        }
      }
    }
  }

  getAttemptIds() {
    return this.ReservationAttemptData.map(item => {
      if (item.ReservationAttemptId) {
        return item.ReservationAttemptId;
      } else if (item.Slots && item.Slots.length) {
        return item.Slots.filter(x => x.ReservationAttemptId).map(({ ReservationAttemptId }) => ReservationAttemptId)
      } else {
        return []
      }
    }).flat();
  }

  closePopover(){
    this.popover.close();
  }

  promoCodeApplied(offer) {
    this.promoCodePopover.close();
    let requestPayload = {
      "CartIds":this.cartSumaryDataList.filter(item => item.selected).map(item => item.Id),
  "PromoCode" : offer?.OfferCode,
  "RatePlans" : null,
  "PartyId": null
};
this.subscriptions.add(this.api.calculateDiscount(requestPayload).subscribe(data =>{
  if(data.State == OperationResultState.Success){
   // this.partyService.promoCodeApplied$.next({'payload':data.Payload[0],'offer':offer})
   this.cartSumaryDataList.filter(item => item.selected).forEach(cartItem =>{
      cartItem.RatePlan= data.Payload.RatePlans.find(ratePlan => cartItem.Id == ratePlan.CartId)?.RatePlan || null;
      cartItem.ShopItem= data.Payload.RatePlans.find(ratePlan => cartItem.Id == ratePlan.CartId)?.ShopItem || null;
     if(cartItem.RatePlan){
      cartItem.CartItemDetail.rateSummary.OfferCode =  cartItem.RatePlan.Discounts.length ? cartItem.RatePlan.Discounts[0]?.ChargeName : null;
      cartItem.CartItemDetail.rateSummary.Discount =  cartItem.RatePlan.Discount;
      cartItem.CartItemDetail.rateSummary.TaxOnSCAmt =  cartItem.RatePlan.TaxOnServiceChargeAmount;
      cartItem.CartItemDetail.rateSummary.VAT =  cartItem.RatePlan.VAT;
      cartItem.CartItemDetail.rateSummary.VATSCAmt =  cartItem.RatePlan.VATOnServiceChargeAmount;
      cartItem.CartItemDetail.rateSummary.Amt = cartItem.RatePlan.SubTotal;
      cartItem.CartItemDetail.rateSummary.SCAmt = cartItem.RatePlan.ServiceCharge;
      cartItem.CartItemDetail.rateSummary.TaxAmt = cartItem.RatePlan.Tax;
     }
      
   }) 
   this.calculateCartTotal()
  }

 
}));
}
  processTransactions(transInfo) {
    //var authTransactionID = transInfo.authorizeTransactionID;
    //var paymentType = transInfo.paymentType;
    var totalAmount = transInfo.paidAmount;
    var overAllStatus = transInfo.status;
    var successCode = transInfo.successCode;
    var errorCode = transInfo.errorCode;
    var errorMessage = transInfo.errorMessage;
    var transactions = transInfo.payments;
    var authorizeTransactions = [];

    if (transactions && transactions.length > 0) {
      transactions.forEach(transaction => {
        if (transaction.successCode == 1) {
          var name = '';
          var paymentDetail = transaction.paymentDetail;
          var roomDetails = {
            RoomNumber: null,
            ConfirmationNumber: null,
            LastName: null
          };
          var pointsDetails = {
            AccountNumber: null,
            BucketName: null,
            Name: null,
            LoyaltyTransactionId: null
          };

          if (transaction.paymentType == 1 || transaction.paymentType == 2) { //credit card
            name = paymentDetail.accountName;
          }

          if (transaction.paymentType == 3) { //Post to room charges
            roomDetails.RoomNumber = paymentDetail.cardIssuer;
            roomDetails.ConfirmationNumber = paymentDetail.cardNumber;
            roomDetails.LastName = paymentDetail.accountName;
            name = paymentDetail.accountName;
          }
          if (transaction.paymentType == 5) { //Loyalty
            pointsDetails.BucketName = paymentDetail.tender;
            pointsDetails.AccountNumber = paymentDetail.cardNumber;
            pointsDetails.Name = paymentDetail.accountName;
            pointsDetails.LoyaltyTransactionId = paymentDetail.followOnData;
            name = paymentDetail.accountName;
          }
          var dto = {
            CardNumber: paymentDetail.cardNumber ? paymentDetail.cardNumber : "",
            ExpirationDate: paymentDetail.expirationDate ? paymentDetail.expirationDate.substring(0, 4) + paymentDetail.expirationDate.substring(4) : "",
            Name: name ? name : "",
            Token: paymentDetail.token ? paymentDetail.token : null,
            TransactionFollowOnData: paymentDetail.followOnData ? paymentDetail.followOnData : null,
            AuthorizeTransactionID: transaction.authorizeTransactionID,
            Amount: transaction.paidAmount,
            PaymentType: transaction.paymentType ? transaction.paymentType : null,
            InvoiceID: transaction.invoiceID ? transaction.invoiceID : null,
            TransactionDate: transaction.transactionsDate ? transaction.transactionsDate : null,
            RoomNumber: roomDetails && roomDetails.RoomNumber ? roomDetails.RoomNumber : null,
            ConfirmationNumber: roomDetails && roomDetails.ConfirmationNumber ? roomDetails.ConfirmationNumber : null,
            BucketName: pointsDetails && pointsDetails.BucketName ? pointsDetails.BucketName : null,
            LoyaltyAccountNumber: pointsDetails && pointsDetails.AccountNumber ? pointsDetails.AccountNumber : null,
            LoyaltyTransactionId: pointsDetails && pointsDetails.LoyaltyTransactionId ? pointsDetails.LoyaltyTransactionId : null,
          };
          authorizeTransactions.push(dto);
        } else if (transaction.errorMessage) {
          this.showMessage(transaction.errorMessage);
        }
      });
      return authorizeTransactions;
    }
  }

  UpdateReservationAttempt(Dto) {
    this.subscriptions.add(this.partyService.UpdateReservationAttempt(Dto).subscribe(data => {
      if (data && (data.ErrorMessage || data.ExceptionMessage || (data.ValidationMessages && data.ValidationMessages.length > 0) || data.length == 0)) {
        if (data.ErrorMessage && data.ErrorMessage.length > 0) {
          var sorrysomethingwentwromg = this.ts.instant('SorryWeGotSomeErrorWhileProcessing');
          var CCUprocessableTryAgainErrorMessage = this.ts.instant('CCUprocessableTryAgainErrorMessage');
          var ImportantErrorMessage = this.ts.instant('ImportantErrorMessage');
          var CreditCardAuthorizationError = this.ts.instant('CreditCardAuthorizationError');
          var TryAgainMessage = this.ts.instant('TryAgainMessage');
          var CardNumbershouldContainOnlyDigits = this.ts.instant('CardNumbershouldContainOnlyDigits');
          var CardNumberShouldConsists13To16Digits = this.ts.instant('CardNumberShouldConsists13To16Digits');
          var CardNumberShouldNotBlank = this.ts.instant('CardNumberShouldNotBlank');
          var SorryWeGotSomeErrorCardExpired = this.ts.instant('SorryWeGotSomeErrorWhileProcessingCardExpired');
          if (data.ErrorMessage == "Please try it one more time, if the problem repeats, contact to rGuestSeat@Agilysys.com")
            var url = sorrysomethingwentwromg + TryAgainMessage;
          else if (data.ErrorMessage == "We're sorry, but we couldn�t process your credit card based on the information you provided. Please try again.")
            var url = sorrysomethingwentwromg + CCUprocessableTryAgainErrorMessage;
          else if (data.ErrorMessage == "[IMPORTANT] Some error happens at the moment of database saving. There is a probability that the payment transaction has performed successfully but this fact hasn't been saved properly. Don't try to do it one more time. Please, contact rGuestSeat@Agilysys.com regarding this error")
            var url = sorrysomethingwentwromg + ImportantErrorMessage;
          else if (data.ErrorMessage == "The provided credit card hasn't been authorized succesfully")
            var url = sorrysomethingwentwromg + CreditCardAuthorizationError;
          else if (data.ErrorMessage.indexOf("The card is expired") > -1)
            var url = SorryWeGotSomeErrorCardExpired;
          else if (data.ErrorMessage == "The card number should consist of only digits")
            var url = sorrysomethingwentwromg + CardNumbershouldContainOnlyDigits;
          else if (data.ErrorMessage == "The card number should consist of from 13 to 16 digits")
            var url = sorrysomethingwentwromg + CardNumberShouldConsists13To16Digits;
          else if (data.ErrorMessage == "The card number shouldn't be blank")
            var url = sorrysomethingwentwromg + CardNumberShouldNotBlank;
          else
            var url = sorrysomethingwentwromg + data.ErrorMessage;
          this.showMessage(url);
        }
        else {
          var url = sorrysomethingwentwromg + TryAgainMessage;
          this.showMessage(url);
        }
        this.showMessage(url);
      } else if (data.RedirectUrl) {
        this._as.offTimer();
        this.LoadIframe(data.RedirectUrl);
        setTimeout(() => {
          let msg; 
          this.ts.get('processPendingAmount', { paymentAmount: this._settings.General.OperationCurrency + Dto.PaymentAmount, pendingAmount: this._settings.General.OperationCurrency + data.PendingAmount  }).subscribe(msgVal => msg = msgVal);
          this.showMessage(msg);
        }, 1);
      }
      else {
        if (this.ReservationAttemptData.ReservationAttemptId > 0) {
          this.subscriptions.add(this.partyService.GetRegisterReservationAttempt(this.ReservationAttemptData.ReservationAttemptId).subscribe(data => {
            if (data && (data.State == PaymentStates.Authorized || data.State == PaymentStates.ChargeCaptured)) {
              this.isUserAllowedToclickConfirm = true;
              this.reservationConfirm();
            }
            else {
              this.showMessage(this.ts.instant("CreditCardNotBeProcessed"));
            }
          }));
        }
        else {
          const ReservationIds = this.ReservationAttemptData.map((item) => {
            if (item.Slots && item.Slots.length) {
              return item.Slots.filter(x => x.ReservationAttemptId).map(x => x.ReservationAttemptId);
            } else {
              return item.ReservationAttemptId
            }
          }).flat().filter(id => id);
          if (data.ReservationState) {
            let isAuthorized = true;
            data.ReservationState.forEach(reservationData => {
              if (reservationData.State != PaymentStates.Authorized && reservationData.State != PaymentStates.ChargeCaptured) {
                isAuthorized = false;
              }
            });

            if (isAuthorized) {
              this.isUserAllowedToclickConfirm = true;
              this.reservationConfirm();
            } else {
              this.showMessage(this.ts.instant("CreditCardNotBeProcessed"));
            }
          }
        }
      }
    }));
  }

  reservationConfirm() {

    var selectedCartItems = this.cartSumaryDataList.filter(item => item.selected);
    var CartBookingInputList = [];
    selectedCartItems.forEach(item => {

      var obj = {
        CartItemId: item.Id,
        BookingType: item.CartItemDetail.BookingBehavior,
        LockId: 0,
        SlotTime: '',
        ReservationAttemptId: 0,
        PropertyId: item.PropertyId,
        EnableTicketPrinting: false,
        TicketPerPerson: false,
        IsForStandbyReservations: false,
        PackageDetails: this.partyService.getDiscountPercentForCartItem(item.CartItemDetail?.SpecialMealId),
        RetailCardTokenId: this.partyService.RetailCardTokenId,
        FinancialEffectId: item.CartItemDetail.FinancialEffectId,
        PaymentMethod: this.partyService.bookingPaymentType,
        LanguageId: Number( sessionStorage.getItem(`languageId${Utilities.getSessionStorageType()}`)) || DEFAULT_LANGUAGE_ID
      }
      if (item.CartItemDetail && item.CartItemDetail.SpecialMealId && BookingBehavior.OpenBooking !== item.CartItemDetail.BookingBehavior) {
        let { EnableTicketPrinting, TicketPerPerson, IsForStandbyReservations } = this.cs.specialMealListForMerchant.find(meal => meal.Id === item.CartItemDetail.SpecialMealId);
        obj.EnableTicketPrinting = EnableTicketPrinting;
        obj.TicketPerPerson = TicketPerPerson;
        obj.IsForStandbyReservations = IsForStandbyReservations;
      }
      if (BookingBehavior.OpenBooking == item.CartItemDetail.BookingBehavior && item.CartItemDetail.IsForStandbyReservations) {
        obj.IsForStandbyReservations = item.CartItemDetail.IsForStandbyReservations;
      }
      let lockResult = item.SlotLockResult ? item.SlotLockResult : item.CartItemDetail?.SlotLockResultDTO;
      if (lockResult && lockResult.SlotLockIdDTO) {
        obj.LockId = lockResult.SlotLockIdDTO.Id;
        obj.ReservationAttemptId = this.getReservationAttemptId(item.Id);
        CartBookingInputList.push(obj);
      } else if (lockResult &&  !item.CartItemDetail?.IsForStandbyReservations) {
        item.LockSlots = Object.keys(lockResult).forEach(slotLockKey => {
          let tempObj = { ...obj };
          tempObj.SlotTime = slotLockKey;
          tempObj.ReservationAttemptId = this.getOpenBookingReservationAttemptId(item.Id, slotLockKey);
          tempObj.LockId = lockResult[slotLockKey].SlotLockIdDTO?.Id;
          CartBookingInputList.push(tempObj);
        });
      } else if (item.CartItemDetail && item.CartItemDetail.IsForStandbyReservations) {
        if(BookingBehavior.OpenBooking === item.CartItemDetail.BookingBehavior || BookingBehavior.PrivateLesson === item.CartItemDetail.BookingBehavior) {
          let tempObj = { ...obj };
          CartBookingInputList.push(tempObj);
        }
      }
    });
    this.bookCart(CartBookingInputList, false);
  }
  bookCart(CartBookingInputList, ignoreBookingValidation) {
    this.showIframe = false;
    this.iframeURL = null;
    this.subscriptions.add(this.partyService.bookCartItemsForMultipleProperties(CartBookingInputList, ignoreBookingValidation).subscribe(response => {
      if (response.State == OperationResultState.ConsentMessages && response.ValidationMessages?.length) {
        this.showExistingReservationPopup(ComponentTypes.CreateCartBooking, CartBookingInputList, response.ValidationMessages)
        return;
      }
      this.showIframe = false;
      this.iframeURL = null;
      this.IsLockTimer = false;
      this.partyService.selectedPackage = null;
      this.partyService.bookingConfirmationData = response.Payload[0];
      let anyConfirmedReservations = response.Payload[0]?.filter(party => party.ConfirmedSessionsForCart && party.ConfirmedSessionsForCart.length && party.ConfirmedSessionsForCart.filter(p => p.SessionType == PartyType.Reservation)?.length);
      let enableTickets = CartBookingInputList.filter((ticket) => ticket.EnableTicketPrinting == true && ticket.IsForStandbyReservations === false);
      if (enableTickets && enableTickets.length > 0 && anyConfirmedReservations && anyConfirmedReservations.length) {
        this.partyService.ShowTicket();
      } else {
        this.partyService.openConfirmationDialog(response, this.ts.instant('CartBookSuccessMessageText'), null, null, ComponentTypes.CreateCartBooking);
      }
      // this.cartSumaryDataList = this.cartSumaryDataList.filter(item => !item.selected);
      // this.partyService.TotalCartBookingObj = this.cartSumaryDataList.filter(item => item);
      this.partyService.TotalCartBookingObj = this.partyService.TotalCartBookingObj.filter(x => x.Id != 0 && !CartBookingInputList.map(y => y.CartItemId).includes(x.Id));
      this.partyService.cartCount = this.partyService.TotalCartBookingObj.length;
      // this.partyService.cartCount = this.cartSumaryDataList.length;
      this._as.isCartPaymentInProgress = false;
      this._as.showCartItem = false;
      this.calculateCartTotal();
      if (response.Payload[0] && response.Payload[0].length === 1) {
        let propertyId = response.Payload[0][0].PropertyId;
        this.partyService.showEmailPopup(response, response.Payload[0][0], ReservationEmailNotificationType.Created, propertyId);
        // if (response.Payload[0].EmailAddress && (this.cs.propertySettings.value[propertyId].settings.General.SupportedReservationEmailConfirmationTypes & SupportedReservationEmailConfirmationTypes.Created) == SupportedReservationEmailConfirmationTypes.Created) {
        //   let data = response;
        //   data.Payload = data.Payload[0];
        //   this.popupService.restrictCloseDialog = false;
        //   this.partyService.openConfirmationDialog(data, null, null, ReservationEmailNotificationType.Created);
        // }
      } else {
        this.partyService.showEmailPopup(response, response.Payload[0][0], ReservationEmailNotificationType.Created);
      }
    }))
  }
  getReservationAttemptId(cartId) {
    if (this.partyService.IsSkipPayment) {
      return null
    } else {
      return this.ReservationAttemptData.find(item => +item.CartId === cartId)?.ReservationAttemptId;
    }
  }

  getOpenBookingReservationAttemptId(cartId, key) {
    if (this.partyService.IsSkipPayment) {
      return null
    } else {
      return this.ReservationAttemptData.find(item => +item.CartId === cartId)?.Slots?.find(slot => slot.LocalTime === key)?.ReservationAttemptId;
    }
  }

  ngOnInit(): void {
  //  this.partyService.lockCartType = this.cs.settings.value.PropertySetting[0].LockMode;
    this.isActivityProperty = ActivitiesVenue.find(x => x == this._as.PropertyType) ? true : false;
    this.buttonValueProceed = {
      type: buttonTypes.actionPrimary,
      label: this.ts.instant('Proceed'),
      customclass: 'next-btn',
      disbaledproperity: true
    }
    this.timerApplyButtonConfig = {
      type: buttonTypes.actionPrimarySmall,
      label: this.ts.instant('apply'),
      customclass: 'next-btn'
    }
    this.applyPromoCode = {
      type: buttonTypes.actionPrimary,
      label: this.ts.instant('promo Code'),
      customclass: 'next-btn',
      disbaledproperity: true
    }
    this.clearCartBtn = {
      type: buttonTypes.actionSecondary,
      label: this.ts.instant('ClearCart'),
      customclass: 'next-btn',
    }
    this.searchconfig = [
      {
        type: 'input',
        name: 'searchText',
        label: this.ts.instant('searchText'),
        class: 'action-bar__search-text',
        showErrorText: true,
        appearance: true,
        icon: 'icon-search',
        icon1: 'icon-Group-591',
        cellClick: this.clearSearch.bind(this)
      }
    ];
    this.selectAllBtn = {
      type: buttonTypes.actionSecondarySmall,
      label: this.ts.instant('selectAll'),
      customclass: 'next-btn',
    }
    this.unselectAllBtn = {
      type: buttonTypes.actionSecondarySmall,
      label: this.ts.instant('unselectAll'),
      customclass: 'next-btn',
    }
    this.proceedBtn = {
      type: buttonTypes.actionPrimary,
      label: this.ts.instant('Proceed'),
      disbaledproperity: true,
    }
    this.proceedPaymentBtn = {
      type: buttonTypes.actionPrimary,
      label: this.ts.instant('ProceedPayment')
    }
    this.releaseBtn = {
      type: buttonTypes.actionPrimary,
      label: this.ts.instant('cancel')
    }
    this.buttonValueClose = {
      type: buttonTypes.actionPrimary,
      label: this.ts.instant('Close'),
      customclass: 'next-btn',
    }
    this.continuebtn = {
      type: buttonTypes.actionSecondary,
      label: this.ts.instant('ContinueShopping'),
      customclass: 'next-btn',
      disbaledproperity: !this.isActivityProperty
    }
    this.partyService.editCartId = null;
    this.getCartItems();
    this.subscriptions.add(this.cs.refreshCart$.subscribe(newData => {
      this.showCartItem ? this.unlockCartItems(null, null) : '';
      const oldcartvalues = this.partyService.TotalCartBookingObj.filter(item => item.selected).map(({ Id, SlotLockResult, RatePlanTotal, LockSlots }) => { return { Id: Id, SlotLockResult: SlotLockResult, LockSlots: LockSlots, RatePlanTotal: RatePlanTotal, } });
      this.partyService.TotalCartBookingObj = newData;
      this.partyService.TotalCartBookingObj.forEach(item1 => {
        var itemFromArr2 = oldcartvalues.find(item2 => item2.Id == item1.Id);
        if (itemFromArr2) {
          item1.selected = true;
          item1.SlotLockResult = itemFromArr2.SlotLockResult;
          item1.RatePlanTotal = itemFromArr2.RatePlanTotal;
          item1.LockSlots = itemFromArr2.LockSlots;

        }
      }
      )
      this.partyService.cartCount = this.partyService.TotalCartBookingObj.length;
      // if(newData && this.showCartItem){
         this.getCartData();
      //   //this.unlockCartItems(true);
      //   //this.lockCartItems();
      // }
    }));
    this.cs.VCartItineraryUpdated$.subscribe(vCart => {
      if(vCart && vCart.Type == CartWindowMessageType.CartItemEdited){
        let cartItem = this.partyService.TotalCartBookingObj.find(cart => cart.Id == vCart.data);
        
        if(Utilities.controlValidate(controlSettings.Widget_Dining_Cart , this.cs.propertySettings?.value[cartItem.PropertyId]?.settings.PropertyType)){
          this.popupService.closeDialog$.next(true);
          this.formDiningEditCartInputs(cartItem);
        }else if(Utilities.controlValidate(controlSettings.Widget_Non_Dining_cart , this.cs.propertySettings?.value[cartItem.PropertyId]?.settings.PropertyType)){
          this.popupService.closeDialog$.next(true);
          this.editCartData(null , cartItem);
        }
      }
    })
  }

  formDiningEditCartInputs(cartItem){
    let cart = Object.assign({} , cartItem.CartItemDetail)
    cart.Id = null;
    cart.ReservedFor = cart.Slot?.Time;
    cart.SeatingTime = cart.Slot?.Time;
    cart.RestaurantId = cartItem.PropertyId;
    cart.cartId = cartItem.Id;
    this.partyService.editCartId = cartItem.Id;
    this.dashboardData.createOrEditReservation(true , cart);

  }

  ngOnChanges() {
    if (this.continuebtn) {
      this.continuebtn.disbaledproperity = ActivitiesVenue.find(x => x == this._as.PropertyType) ? false : true;
    }
    if (this.showCartItem) {
      if (this.partyService.proceedToRetailPayment) {
        this.partyService.proceedToRetailPayment = this.partyService.proceedToRetailPayment ? false : this.partyService.proceedToRetailPayment;
        this.unlockCartItems(null, null);
        this.partyService.SetRetailItem([]);
        if(this.isPackageApplied){
          this.applyPackage(false);
          if(!this.partyService.selectedPackage){
            this.isPackageApplied = false;
          }
        }
      }
      this.showCartLockWarning = false;
      this.packageDiscountAmount = 0;
      this.loadOptions();
      this.getCartData();
      this.locked = false;
      this.setCartSummaryDataList();
      this.calculateCartTotal();
      this.proceedBtnValidate();
      this._as.TimerOff$.subscribe(data => {
        if(!data){
          this.cartSumaryDataList.forEach(cartItem => {        
            cartItem.lockExpired = moment(cartItem.CartItemDetail.ExpireAt).isBefore(moment().utc());
            cartItem.isAvailable = !cartItem.lockExpired;
          });
        this.partyService.TotalCartBookingObj = this.cartSumaryDataList;

          this.locked = true;
        }
      })
      this.setTimer(this.partyService.getCartExpireTime());
      this.searchConfigFormSubscription = this.searchConfigForm.form.valueChanges.pipe(debounceTime(100)).subscribe((val: any) => {
        this.searchText = val.searchText;
      });
    } else {
      this.clearSearch();
      this.searchText = '';
      this.showIframe = false;
      if (!this.partyService.proceedToRetailPayment && this.partyService.lockCartType === LockMode.Auto) {
        this.unlockCartItems(null, null);
      }
      if(!this.partyService.proceedToRetailPayment){
        if(this.isPackageApplied && this.partyService.selectedPackage){
          this.applyPackage(false);
        }else if(!this.partyService.selectedPackage){
          this.isPackageApplied = false;
        }
        this.partyService.TotalCartBookingObj?.forEach(cart => {
          cart.selected = false;
        });
      }
      this.searchConfigFormSubscription && this.searchConfigFormSubscription.unsubscribe();
    }
  }

  clearSearch(event?) {
    this.searchConfigForm && this.searchConfigForm.form && this.searchConfigForm.form.get('searchText').setValue('', { emitEvent: true });
  }

  customWildSearch({ CartItemDetail }) {
    let values = [
      CartItemDetail.Contact.FirstName, CartItemDetail.Contact.LastName, CartItemDetail.Contact.EmailAddress, CartItemDetail.Contact.PhoneNumber.replace(/[^a-z\d]+/gi, ""),
      CartItemDetail.PartyName, CartItemDetail.SpecialMeal, CartItemDetail.Size
    ]
    if (CartItemDetail.Locations) {
      values.push(...CartItemDetail.Locations)
    }
    if (CartItemDetail.Instructors) {
      values.push(...CartItemDetail.Instructors)
    }
    return values.map((itm: string | number) => itm ? itm.toString().toLowerCase() : '');
  }

  setCartSummaryDataList(){
    if(this.partyService.lockCartType === LockMode.Auto){
      this.lockCartItems(this.byPassLock, true);
    }else{
      this.cartSumaryDataList.forEach(cartItem => {        
        cartItem.lockExpired = moment(cartItem.CartItemDetail.ExpireAt).isBefore(moment().utc());
        cartItem.isAvailable = !cartItem.lockExpired;
      });
      this.locked = true;
    }
  }

  loadOptions() {
    var floorPlan = this._layout.FloorPlans;
    this.locationList = this.cs.locationListForMerchant;
    this.instructorList = this.cs.instructorListForMerchant;
  }

  getCartData() {
    let cartData = _.cloneDeep(this.partyService.TotalCartBookingObj); //need to remove
    cartData.forEach(data => {
      data.ExpireAt = data.CartItemDetail['ExpireAt'];
      data.CartItemDetail['PropertyId'] = data.PropertyId;
      data.CartItemDetail['RestaurantId'] = data.PropertyId;
      data.CartItemDetail['Notes'] = data.CartItemDetail.Notes;
      data.CartItemDetail['rateSummary'] = data.CartItemDetail?.rateSummary;
      data.CartItemDetail['PartyName'] = (data.CartItemDetail.Contact.FirstName ? data.CartItemDetail.Contact.FirstName : '') + ' ' + (data.CartItemDetail.Contact.LastName ? data.CartItemDetail.Contact.LastName : '')
      data.CartItemDetail['TotalSessions'] = this.getSessionTotal(data.CartItemDetail);
      data.StandBy = data.CartItemDetail.IsForStandbyReservations || data.CartItemDetail.BookingBehavior === BookingBehavior.PrivateLesson;
      if (data.CartItemDetail.BookingBehavior === BookingBehavior.ClassOrSession) {
        data.CartItemDetail['SpecialMeal'] = this.getSpecialMealName(data.CartItemDetail.SpecialMealId);
        let cartItemSessions = data.CartItemDetail.Sessions.Sessions.map(sesItem => sesItem.SessionIds).flat();
        let filteredsessionObj = this.cs.specialMealListForMerchant.find(meal => meal.Id === data.CartItemDetail.SpecialMealId)
        if (filteredsessionObj && filteredsessionObj.ActivitySessions) {
          let sessionObj = filteredsessionObj.ActivitySessions.filter(session => cartItemSessions.includes(session.ActivitySessionId))
          data.CartItemDetail.Locations = sessionObj.map(({ SessionLocationMappings }) => SessionLocationMappings.map(({ StandaloneTableId }) => this.getLocationName(StandaloneTableId)))['flat']();
          data.CartItemDetail.Instructors = sessionObj.map(({ SessionStaffMappings }) => SessionStaffMappings.map(({ ServerId }) => this.getInstructorName(ServerId)))['flat']();
          data.CartItemDetail.Locations = _.uniq(data.CartItemDetail.Locations);
          data.CartItemDetail.Instructors = _.uniq(data.CartItemDetail.Instructors);
        }
      } else {
        if (data.CartItemDetail.BookingBehavior === BookingBehavior.OpenBooking) {
          data.CartItemDetail['SpecialMeal'] = 'Open Booking';          
        }
        else {
          data.CartItemDetail['SpecialMeal'] = this.getSpecialMealName(data.CartItemDetail.SpecialMealId);
          data.CartItemDetail.Instructors = data.CartItemDetail.InstructorIds  ? data.CartItemDetail.InstructorIds.map(id => this.getInstructorName(id)) : '';
        }
        data.CartItemDetail.Locations = data.CartItemDetail.TableIds?.map(id => this.getLocationName(id));
      }
      // data.selected = false;
    });
    
    this.cartSumaryDataList = [...cartData];
    this.groupedCartSummaryDataList = _.chain(this.cartSumaryDataList).groupBy('CartGroupName').map((value,key) =>({groupName:key,groupItems:value,expiresAt: this.getExpireTime(value?.[0].CartItemDetail)})).value()
    
    // this.partyService.getCartList().subscribe(data => {
    //   this.cartSumaryDataList = data;
    //   this.cartSumaryDataList = _.cloneDeep(this.partyService.TotalCartBookingObj); //need to remove
    //   this.cartSumaryDataList.forEach(data => {
    //     data['PartyName'] = data.Contact.FirstName + ' ' + data.Contact.LastName
    //     data['SpecialMeal'] = data.SpecialMealId ? data.SpecialMealId : 'NA';
    //     data['TotalSessions'] = !data.SpecialMealId ? data.Slots?.length : data.Sessions.Sessions?.length;
    //     this.DataGridList = new MatTableDataSource<any>(this.cartSumaryDataList);
    //   });
    // })
  }
  
  getSessionTotal(cartDetails: any) {
    if(cartDetails.BookingBehavior === BookingBehavior.ClassOrSession){
      let sessionCount = 0 ;
      cartDetails.Sessions.Sessions.forEach(item => sessionCount += item.SessionIds?.length);
      return sessionCount;
    }else{
      return cartDetails.Slots?.length
    }
  }

  getExpireTime(element){
    if(element.ExpireAt && (moment(element.ExpireAt).diff(moment().utc(), "seconds") > 0)){
      return (moment(element.ExpireAt).diff(moment().utc(), "seconds"));
    }
    return "check Availabiliy";
  }
  getSpecialMealName(mealId) {
    return this.cs.specialMealListForMerchant.find(meal => meal.Id === mealId)?.Name;
  }

  getLocationName(locationId) {
    return this.locationList.find(location => location.id == locationId) ? this.locationList.find(location => location.id == locationId).value : this.ts.instant("Any");
  }

  getInstructorName(instructorId) {
    return this.instructorList.find(instrustor => instrustor.id == instructorId) ? this.instructorList.find(instrustor => instrustor.id == instructorId).value : this.ts.instant("Any");
  }

  cartItemClicked(cartGroup, item, isFromGroupSelect?) {
    if((this.cartSumaryDataList.find(cartItem => cartItem.packageAvailable) && this.isPackageApplied) ){
      this.applyPackage(false);
    }
    
    if (!this.showCartLockWarning) {
      if (this.showIframe) {
        return;
      }
      if (item.selected) {
        item.selected = false;
      } else if ((item.isAvailable && !item.lockExpired) || item.standbyOpenBooking || item.StandBy) {
          item.selected = true;
      }
      this.calculateCartTotal();
      this.packageCartTotal(this.cartSumaryDataList.filter(item => item.selected));
    }

    if(!isFromGroupSelect){
      this.afterSelectionHandler(cartGroup)
    }

  }

  editCartData(event: Event, data, skipWarning:boolean = false) {
    this._as.showCartItemOnEdit = true;
    if (data) {
      if (this.cartSumaryDataList.find(item => item.Id == data.Id && item.packageAvailable) && !skipWarning) {
        this.applyPackage(false , true , {eventData : event , cartData : data});
      }
      else {
        data.CoverTypeQuantities = _.cloneDeep(data.CoverTypes);
        if (data.CartItemDetail.BookingBehavior == BookingBehavior.PrivateLesson) {
          data.addPrivateLessonBooking = true
        }
        this.unlockCartItems(event, data);
      }
    }
    event.preventDefault();
    event.stopPropagation();
  }



  clearCart() {
    if (this.showIframe) {
      return;
    }
    let popUpMessage = [{
      confirmationMessage: this.ts.instant('clearCartConfirmation'), dialogTitle: 'confirm', showAlert: true
    }];
    this.showWarningInfoPopUp(popUpMessage, ComponentTypes.CartItemsDisplayComponent);
  }

  removeItem(event: Event, data) {
    event.stopPropagation();
    if (this.showIframe) {
      return;
    }
    this.removeCartItems([data.Id]);
  }

  removeCartItem(event: Event, data){
    this.unlockCartItemsMultiple(this.getUnlockCartItems([data]));
    this.removeItem(event, data);
  }

  getUnlockCartItems(cartItems): MultiplePropertyUnlockRequestDTO[]{
    let lockData = cartItems.map((cartItem) => {
      let _lockId = null;

      if(cartItem.CartItemDetail.SlotLockResultDTO?.SlotLockIdDTO){
        _lockId = cartItem.CartItemDetail.SlotLockResultDTO.SlotLockIdDTO.Id;
      }else if(cartItem.CartItemDetail.SlotLockIdDTO){
        _lockId = cartItem.CartItemDetail.SlotLockIdDTO.Id;
      }else if(cartItem.CartItemDetail.BookingBehavior === BookingBehavior.OpenBooking
        && ((cartItem.CartItemDetail.SlotLockResultDTO && Object.values(cartItem.CartItemDetail.SlotLockResultDTO).length) || (cartItem.SlotLockResult))){
        let lockData = [] as MultiplePropertyUnlockRequestDTO [];
          if(cartItem.CartItemDetail.SlotLockResultDTO) {
        Object.values(cartItem.CartItemDetail.SlotLockResultDTO).forEach((item: any) => {
          if (item.SlotLockIdDTO) {
            lockData.push({
              LockId: item.SlotLockIdDTO.Id,
              PropertyId: cartItem.PropertyId,
              BookingBehavior: cartItem.CartItemDetail.BookingBehavior
            })
          }
        });
      }
      else {
        Object.values(cartItem.SlotLockResult).forEach((item: any) => {
          if (item.SlotLockIdDTO) {
            lockData.push({
              LockId: item.SlotLockIdDTO.Id,
              PropertyId: cartItem.PropertyId,
              BookingBehavior: cartItem.CartItemDetail.BookingBehavior
            })
          }
        })
      }
        return lockData
      }

      if(!_lockId && cartItem.SlotLockResult?.SlotLockIdDTO?.Id){
        _lockId = cartItem.SlotLockResult?.SlotLockIdDTO?.Id;
      }

      return {
        LockId: _lockId,
        PropertyId: cartItem.PropertyId,
        BookingBehavior: cartItem.CartItemDetail.BookingBehavior
      } as MultiplePropertyUnlockRequestDTO;
    })

    return lockData?.flat() || [];
  }
  

  removeCartItems(cartIds: number[]) {
    var revomeItem = this.cartSumaryDataList.filter(item => item.Id == cartIds[0]);
    if (revomeItem.length && revomeItem['LockSlots']) {
      var unlockId = revomeItem.map(item => { item.LockSlots?.forEach(slot => slot.PropertyId = item.PropertyId); return item.LockSlots; })['flat']().map(({ Id, Type, PropertyId }) => {
        return { LockId: Id, Type }
      });
    }
    this.subscriptions.add(this.partyService.removeCartItems(cartIds).subscribe(data => {
      this.cartSumaryDataList = this.cartSumaryDataList.filter(({ Id }) => cartIds.findIndex(cartId => cartId === Id) === -1);
      this.showCartItem ? ((unlockId && unlockId.length) ? this.unlockCartItems(null, null, unlockId) : this.unlockCartItems(null, null)) : '';
      this.partyService.cartCount = this.cartSumaryDataList.length;
      this.partyService.TotalCartBookingObj = [...this.cartSumaryDataList];
      this.calculateCartTotal();
    }));
    this.proceedBtnValidate();
  }

  getCartItems() {
    let loginResult: LoginResultDTO = JSON.parse(localStorage.getItem(`${sessionStorage.getItem(`sessionGUID${Utilities.getSessionStorageType()}`)}_loginResult`));
    let availablePropertiesForLogin: RestaurantAvailableForLoginDTO[] = loginResult.RestaurantsAvailableForLogin;
    //availablePropertiesForLogin.forEach(property => {
    this.subscriptions.add(this.partyService.getCartList().subscribe(data => {
      if (data.Payload) {
        this.partyService.TotalCartBookingObj = [...this.partyService.TotalCartBookingObj, ...data.Payload];
        this.partyService.cartCount = this.partyService.TotalCartBookingObj.length;

      }
      this.getCartData();
      this.setCartSummaryDataList();
      this.partyService.TotalCartBookingObj = this.cartSumaryDataList;
      this.loadOtherPropertiesAndBlock();
    }));
    this.getCartData();
  }

  loadOtherPropertiesAndBlock(){
    this.ls.ts.getBlocks();
    this.ls.ts.getActivityBlocks();
    if(!this.cs.LoadMultiplePropertiesServiceInit){this.ls.init()};
  }



  lockCartItems(bypassLock, isInitial?) {
    this.showIframe = false;
    this.cartSumaryDataList.forEach(item => {
      //if(!item.selected){
      item.LockSlots = null;
      //}
    });
    let cartIdsBasedOnProperty = _.groupBy(this.cartSumaryDataList, 'PropertyId')
    let cartIdsBasedOnPropertyArray = [];
    for (let key in cartIdsBasedOnProperty) {
      cartIdsBasedOnPropertyArray.push({ "propertyId": key, 'cartData': cartIdsBasedOnProperty[key] });
    }
    let cartIterator = 0;
    cartIdsBasedOnPropertyArray.forEach(cartIdList => {
      cartIterator++;
      if (cartIdList.cartData && cartIdList.cartData.length) {
        cartIdList.cartData.forEach((item) => {
          this.IsLockTimer = false;
          item.isLoading = true;
          this.subscriptions.add(this.partyService.lockCartItems(this.partyService.editCartId ? [this.partyService.editCartId] : [item.Id], item.PropertyId, bypassLock,null).subscribe(({ Payload: data }) => {           
            let index = _.findIndex(data, (cartitem) => { return this.partyService.editCartId ? cartitem['CartId'] == this.partyService.editCartId : cartitem['CartId'] == item.Id; });
            this.availabilityHandler(item, data[index]);            
            if(cartIterator == cartIdsBasedOnPropertyArray.length){
              this.locked = true;
            }
          }));
        })
        // this.locked = true;
        if (isInitial) {
          this.selectAllChange(true)
        }
        this.partyService.TotalCartBookingObj = this.cartSumaryDataList;
        this.partyService.editCartId = null;
      }
    });
  }

  lockCartForGroup(cartItem,expireTime?){
    this.unlockCartItemsMultiple(this.getUnlockCartItems(cartItem.groupItems));
    cartItem.groupItems.forEach((data,index) =>{
      this.lockCartItemHandler(data,this.partyService.TotalCartBookingObj.findIndex(item =>item.Id == data.Id),expireTime)
    })
   // this.groupedCartSummaryDataList =[...this.groupedCartSummaryDataList];
    this.ref.detectChanges();
  }

  lockCartItemHandler(item, _sindex,expireTime?){
    item.isLoading = true;
    if(!expireTime){
      let _extendMinutes = this._settings.General.SlotLockInSec ? this._settings.General.SlotLockInSec / 60 : 4;
      let expireDate = moment().utc().add(_extendMinutes,'minutes')
      expireTime = (moment(expireDate).format('YYYY-MM-DD') + 'T' + moment(expireDate).format('HH:mm:ss') + 'Z').toString();
    }
    this.subscriptions.add(this.partyService.lockCartItems(this.partyService.editCartId ? [this.partyService.editCartId] : [item.Id], item.PropertyId, false,expireTime).subscribe(({ Payload: data }) => {           
      let index = _.findIndex(data, (cartitem) => { return this.partyService.editCartId ? cartitem['CartId'] == this.partyService.editCartId : cartitem['CartId'] == item.Id; });
      this.availabilityHandler(item, data[index],expireTime);
      //item.ExpireAt = expireTime;
      this.partyService.TotalCartBookingObj[_sindex].CartItemDetail.ExpireAt = data[index]?.SlotLockResultDTO?.ExpiresAt || item.ExpireAt;
      this.partyService.TotalCartBookingObj[_sindex].CartItemDetail.SlotLockResultDTO = data[index].SlotLockResultDTO || item.SlotLockResultDTO;
      this.partyService.TotalCartBookingObj[_sindex].SlotLockResult = data[index].SlotLockResultDTO || item.SlotLockResultDTO;

    //  this.setTimer(this.partyService.getCartExpireTime());
     // this.groupedCartSummaryDataList =[...this.groupedCartSummaryDataList];
    }));
  }

  lockCartItem(item, _sindex,expireTime?){
    this.partyService.editCartId = null;
    this.unlockCartItemsMultiple(this.getUnlockCartItems([item]));
    let indexInCartSummary = this.partyService.TotalCartBookingObj.findIndex(data => data.Id == item.Id);
    this.lockCartItemHandler(item, indexInCartSummary,expireTime);

  }

  availabilityHandler(item, {RatePlanTotal, NegotiatedAmount, Comments, SlotLockResultDTO},expireTimer?){
    item.isLoading = false;
    if (!this.partyService.editCartId || this.partyService.editCartId === item.Id) {
      item.RatePlanTotal = RatePlanTotal;
      item.NegotiatedAmount = NegotiatedAmount;
      item.Comments = Comments;
      item.CartItemDetail.SlotLockResultDTO = SlotLockResultDTO;
      if (!item.CartItemDetail.SlotLockResultDTO) {
        item.isAvailable = false;
      }
      else {
        item.isAvailable = (item.CartItemDetail.SlotLockResultDTO.Available || item.CartItemDetail.SlotLockResultDTO?.SlotLockIdDTO?.Id) && !item.CartItemDetail.IsForStandbyReservations;
          item.lockExpired = moment(item.CartItemDetail?.SlotLockResultDTO?.ExpiresAtUTC).isBefore(moment().utc());
        item.ExpireAt = expireTimer ? expireTimer : item.CartItemDetail.SlotLockResultDTO.ExpiresAt;
      }
      if (item.CartItemDetail.SlotLockResultDTO.SlotLockIdDTO) {
        item.LockSlots = [item.CartItemDetail.SlotLockResultDTO.SlotLockIdDTO];
        item.isAvailable = (item.CartItemDetail.SlotLockResultDTO.Available || item.CartItemDetail.SlotLockResultDTO?.SlotLockIdDTO?.Id) && !item.CartItemDetail.IsForStandbyReservations;
        item.lockExpired = moment(item.CartItemDetail?.SlotLockResultDTO?.ExpiresAtUTC).isBefore(moment().utc());
        item.ExpireAt = expireTimer ? expireTimer : item.CartItemDetail.SlotLockResultDTO.ExpiresAt;
      } 
      else if (Object.values(item.CartItemDetail.SlotLockResultDTO)[0]['ExpiresAt']) {
        let availableLockCount = Object.values(item.CartItemDetail.SlotLockResultDTO).filter(slotLock => slotLock['Available']).length;

        //if (item.LockSlots && item.LockSlots.length && item.CartItemDetail.SlotLockResult) {
        item.isAvailable = false;
        if (Object.values(item.CartItemDetail.SlotLockResultDTO)?.length == availableLockCount) {
          item.isAvailable = true;
          item.ExpireAt = expireTimer ? expireTimer : Object.values(item.CartItemDetail.SlotLockResultDTO)[0]['ExpiresAt'];
          item.lockExpired = moment(item.ExpireAt).isBefore(moment().utc());
        }
        //}

      }

      if (item.CartItemDetail) {
        let cartItemStartDate = format(new Date(item.CartItemDetail.StartDate), 'MM/DD/YYYY');
        let cartItemEndDate = format(new Date(item.CartItemDetail.EndDate), 'MM/DD/YYYY');
        let currentDate = moment().utc().format('MM/DD/YYYY');
        item.isExpired = (cartItemStartDate < currentDate) && (cartItemEndDate < currentDate) && !this.dashboardData.checkBookPastDaysReservationsConfig();
        item.isAvailable = item.isAvailable && !item.isExpired;

        if ((!item.Available && item.CartItemDetail.IsForStandbyReservations) || item.CartItemDetail.BookingBehavior === BookingBehavior.PrivateLesson) {
          item.StandBy = true;
        }
      }
      if( item.CartItemDetail.BookingBehavior == BookingBehavior.OpenBooking){
        item.standbyOpenBooking = item.CartItemDetail.IsForStandbyReservations && !item.CartItemDetail.SlotLockResultDTO;
        item.isAvailable = Object.values(item.CartItemDetail.SlotLockResultDTO)?.filter((slot:any) => slot.Available)?.length == Object.values(item.CartItemDetail.SlotLockResultDTO)?.length;
        let expire=  Object.values(item.CartItemDetail.SlotLockResultDTO)[0]['ExpiresAtUTC'] ||  item.ExpireAt ;
        item.lockExpired = moment(expire).isBefore(moment().utc());
      }

      let group = this.groupedCartSummaryDataList.find(group => group.groupName === item.CartGroupName);
      group.groupItems = [...group.groupItems];
      //item.isAvailable = data[index].Available;
      this.calculateCartTotal();
    }
  }

  selectAllChange(selection) {
    if (this.showCartItem) {
      if (this.searchText && selection) {
        this.cartSumaryDataList.forEach(item => {
          if ((item.isAvailable && !item.Expired) && this.locked && this.customWildSearch(item).find(val => val.includes(this.searchText.toLowerCase()))) {
            item.selected = true;
          }
        });
      } else {
        this.cartSumaryDataList.forEach(item => {
          item.packageAvailable = false;
          if (selection && item.isAvailable && this.locked) {
            item.selected = true;
          } else {
            item.selected = false;
          }
        });
      }
      this.calculateCartTotal();
      this.proceedBtnValidate();
    }
  }

  setTimer(expireTime) {
    if (expireTime) {
      this._as.offTimer();
      this.IsLockTimer = true;
      let timer = moment(expireTime).diff(moment().utc(), "seconds");
      this._as.showTimer = true;
      this._as.runTimer(timer);
    }
  }

  unlockCartItems(event: Event, partydata, unlockId?) {

    this.showCartLockWarning = false;
    let cartLockData = (unlockId) ? unlockId : this.getLockData();
    if (unlockId && unlockId.length) {
      cartLockData.push(...unlockId);
    }
    this.IsLockTimer = false;
    if (cartLockData && cartLockData.length) {
      let cartLockDataGroupedByPropertyIds = _.groupBy(cartLockData, 'PropertyId')
      Object.keys(cartLockDataGroupedByPropertyIds).forEach(propertyId => {
        this.subscriptions.add(this.partyService.unlockCartItems(propertyId, cartLockDataGroupedByPropertyIds[propertyId]).subscribe(data => {
          console.log("Unlock cart items success");

          //edit cart item
          this.openEditPopUp(event, partydata)
        }));
      });
    }
    else {
      this.partyService.slotLockId = null;
      this.openEditPopUp(event, partydata)
    }
  }

  unlockCartItemsMultiple(unlockCartItems: MultiplePropertyUnlockRequestDTO[]){
    this.subscriptions.add(this.partyService.unLockCartForMultipleProperties(unlockCartItems).subscribe(data => {
      console.log("Unlock cart items success");
    }));
  }


  openEditPopUp(event: Event, data) {
    if (data) {
      if (data.PropertyId == this._as.restaurantId) {
        this.dashboardData.editItem(event, data);
      }
      else {
        this._as.editItemParameters = { event: event, data: data };
        this.ls.setDataForSelectedRestaurant(data.PropertyId, true)
      }
    }
  }
  roleTypeAccessMessagePop() {
    const popUpMessage = [{
      confirmationMessage: this.ts.instant("userPermissionMsg"),
      dialogTitle: this.ts.instant('authFailed')
    }];
    let okbutton = this.ts.instant('ok');
    const componentDetails: ComponentDetails = Utilities.setComponentDetails(ConfirmationPopupComponent, 'small', 'action',
      popUpMessage, popUpMessage[0].dialogTitle);
    this.openCustomPopup(componentDetails, ComponentTypes.RoleTypeAccess, '450px', 'auto', true, '', okbutton,
      '', true);
  }

  public openCustomPopup(componentDetails: ComponentDetails, fromComponent: ComponentTypes, width?: string, height?: string,
    back?: boolean, title?: string, update?: string, cancel?: string, showAction?: boolean, maxWidth?: string,
    panelClass?: string, selectionView?: boolean, autoFocus?: boolean) {
    const dialogRef = this.dialog.open(CustomPopupComponent, {
      disableClose: true,
      width,
      height,
      maxWidth,
      panelClass,
      minHeight: popupDialogDimension.actionDialogMinHeight,
      autoFocus,
      data: {
        title,
        update,
        cancel,
        componentDetails,
        from: fromComponent,
        back,
        standalone: true,
        showAction,
        selectionView
      }
    });
    return dialogRef;
  }
  cartProceed(event) {
    this.showCartLockWarning = false;
    if ((Utilities.isRetailEnabledProperty(this.cs.settings.value.General.RetailIntegrationDTO) && !Utilities.isRetailEnabledPropertyWithToken(this.cs.settings.value.General.RetailIntegrationDTO) && this.cartTotal > 0)) {
      event.preventDefault();
      this.roleTypeAccessMessagePop();
      event.stopPropagation();
      return;
    }
    if (this.partyService.selectedPackageId && !this.cartSumaryDataList.find(item => item.packageAvailable)) {
      let message = ''
      let packageName = this.cs.availablePackages.find(pack => pack.Id == this.partyService.selectedPackageId)?.Name;
      this.ts.get('applyPackgeWarningMessage', { packageName }).subscribe(msgVal => {
        message = msgVal;
      })
      let popUpMessage = [{
        confirmationMessage: message, dialogTitle: this.ts.instant('alert'), showAlert: true
      }];
      this.showWarningInfoPopUp(popUpMessage, ComponentTypes.applyPackageConfirmation);
    } else {
      // this.proceed();
      this.proceedForPayment();
    }
  }
  proceed() {
    let cartIdsWithPropertyId = this.cartSumaryDataList.filter(({ selected }) => selected).map(item => { return { Item1: item.Id, Item2: item.PropertyId } })
    let allSelectedSlotsAvailable = true;
    let isStandbyPrivateBooking: boolean = false;
    let isStandbyOpenBooking: boolean = false;
    let isStandbyActivityBooking: boolean = false;
    // let cartIdsBasedOnPropertyArray = [];
    // for (let key in cartIdsBasedOnProperty) {
    //   cartIdsBasedOnPropertyArray.push({ "propertyId": key, 'cartData': cartIdsBasedOnProperty[key] });
    // }
    //cartIdsBasedOnPropertyArray.forEach(cartIdList => {
    // cartIdList.cartData.forEach((item) => {
    //this.subscriptions.add(this.partyService.lockCartForMultipleProperties(this.partyService.editCartId ? [this.partyService.editCartId] :cartIdList.cartData.map(({ Id }) => Id), cartIdList.propertyId, !this.byPassLock).subscribe(({ Payload: data }) => {
    this.subscriptions.add(this.partyService.lockCartForMultipleProperties(cartIdsWithPropertyId, false).subscribe(({ Payload: data }) => {
      // if(item.SlotLockResult && item.SlotLockResult.SlotLockIdDTO) {
      //   //proceed
      // }
      let timerLockResult = null;
      if (data && data.length > 0) {
        var locks = data.flat();
        locks.forEach(lockData => {
          var item = this.cartSumaryDataList.filter(c => c.Id == lockData.CartId)[0];
          isStandbyPrivateBooking = item.CartItemDetail.BookingBehavior == BookingBehavior.PrivateLesson;
          isStandbyOpenBooking = item.CartItemDetail.IsForStandbyReservations && item.CartItemDetail.BookingBehavior == BookingBehavior.OpenBooking && !item.SlotLockResult.Available
          if (!isStandbyActivityBooking) {
            isStandbyActivityBooking = item.CartItemDetail.IsForStandbyReservations && item.CartItemDetail.BookingBehavior == BookingBehavior.ClassOrSession && !item.SlotLockResult
          }
          item.SlotLockResult = lockData.SlotLockResultDTO;
          if (item.SlotLockResult && item.SlotLockResult.SlotLockIdDTO) {
            item.LockSlots = [item.SlotLockResult.SlotLockIdDTO];
            timerLockResult = item.SlotLockResult;
          }
          else if (item.SlotLockResult && Object.values(item.SlotLockResult)?.length) {
            item.LockSlots = Object.values(item.SlotLockResult).filter(slotLock => slotLock['SlotLockIdDTO']).map(slotLock => slotLock['SlotLockIdDTO']);

            if (item.LockSlots && item.LockSlots.length && item.SlotLockResult) {
              timerLockResult = Object.values(item.SlotLockResult).filter(slotLock => slotLock['SlotLockIdDTO'])[0];
              if (Object.values(item.SlotLockResult)?.length != item.LockSlots?.length) {
                item.isAvailable = false;
                item.selected = false;
                allSelectedSlotsAvailable = false;

              }
            }
            else {
              if (!isStandbyPrivateBooking && !isStandbyOpenBooking) {
                item.isAvailable = false;
                item.selected = false;
                allSelectedSlotsAvailable = false;
              }

            }
          }
          else {
            item.isAvailable = false;
            item.selected = false;
            allSelectedSlotsAvailable = false;
          }
        });
        this.partyService.TotalCartBookingObj = this.cartSumaryDataList;
        if (timerLockResult || isStandbyPrivateBooking || isStandbyOpenBooking || isStandbyActivityBooking) {
          if (allSelectedSlotsAvailable) {
            this.proceedForPayment();
          }
          else {
            this.showCartLockWarning = true;
            this.calculateCartTotal()

          }
        }
        else {

        }
        // });
        //});

      }
      else {

      }
    }));
  }

  proceedForPayment() {
    let cartData = this.cartSumaryDataList.filter(({ selected }) => selected);
    let cartIds = this.cartSumaryDataList.filter(({ selected }) => selected).map(({ Id }) => Id);
    if (cartData && cartData.length) {
      let reservationAttemptDto = []
      cartData.forEach(cartItem => {
        reservationAttemptDto.push({
          "PropertyId": cartItem.PropertyId,
          "PartySize": cartItem.CartItemDetail.Size,
          "ActivityId": cartItem.CartItemDetail.SpecialMealId,
          "InstructorIds": cartItem.CartItemDetail.InstructorIds,
          "SeatingTypeIds": cartItem.CartItemDetail.SeatingTypeIds,
          "TableIds": cartItem.CartItemDetail.TableIds,
          "Slots": cartItem.CartItemDetail.Slots?.map(slotData => { return { "LocalTime": slotData.Time, "Duration": slotData.DurationInMinutes } }),
          "Sessions": cartItem.CartItemDetail.Sessions,
          "CoverTypes": cartItem.CartItemDetail.CoverTypes,
          "BookingTypes": cartItem.CartItemDetail.BookingTypes,
          "BookingBehavior": cartItem.CartItemDetail.BookingBehavior,
          "NegotiatedAmount": cartItem.CartItemDetail.NegotiatedAmount,
          "MemberType": cartItem.CartItemDetail.Contact?.IsMemberActive && cartItem.CartItemDetail.Contact.MemberType ? cartItem.CartItemDetail.Contact.MemberType : null,
          "CartId": cartItem.Id,
          "StartDate": cartItem.CartItemDetail.StartDate,
          "EndDate": cartItem.CartItemDetail.EndDate,
          "AddOns": cartItem.CartItemDetail.SelectedAddOns,
        })
      })
      if (this.partyService.bookingPaymentType == PaymentMethod.Prepayment) {
        this.prePaymentBookingOperation(reservationAttemptDto, cartIds)
      } else if (this.partyService.IsSkipPayment) {
        this.skipPaymentOperation(cartIds);
      }

    }
  }

  prePaymentBookingOperation(reservationAttemptDto, cartIds) {
    this.subscriptions.add(this.partyService.proceedCartBookingForMultipleProperties(reservationAttemptDto).subscribe(data => {
      this.ReservationAttemptData = data.Payload.ResponseDTOs;
      this.cartSumaryDataList = this.cartSumaryDataList.map(item => {
        let data = this.ReservationAttemptData.find(cart => cart.CartId == item.Id)
        if (data) {
          item.TotalPackageDiscountPercent = data.TotalPackageDiscountPercent ? data.TotalPackageDiscountPercent : null;
          item.TotalPackageDiscountedAmount = data.TotalPackageDiscountedAmount ? data.TotalPackageDiscountedAmount : null;
          item.PackageId = this.partyService.selectedPackage ? this.partyService.selectedPackage.Id : null;
        }
        return item;
      })
      this.partyService.TotalCartBookingObj = this.cartSumaryDataList;
      this.partyService.ReservationAttemptData = data.Payload.ResponseDTOs;
      this.partyService.MultipleReservationAttemptData = data.Payload;
      if (data.Payload.ShouldCreditCardBeEntered) {
        if (this._settings.General.RetailIntegrationDTO.IsEnabled) {
          this.partyService.Operations = 1;
          this.partyService.proceedToRetailPayment = true;
          //  this.partyService.SetRetailItem(cartIds);
          this.partyService.RetailCreateOperation(cartIds);
          this._as.showCartItem = false;
        }
        else {
          if (data.Payload.CreditCardIframeUrl) {
            this.showIframe = true;
            this.iframeURL = this.sanitizer.bypassSecurityTrustResourceUrl(data.Payload.CreditCardIframeUrl + "&origin=" + window.location.origin)
            this.iframeURL = this.iframeURL.changingThisBreaksApplicationSecurity ? this.iframeURL.changingThisBreaksApplicationSecurity : this.iframeURL;
            this.PaymentGateway = data.Payload.PaymentGateway;
            this._as.isCartPaymentInProgress = true;
          }
          else {
            this.showMessage(this.ts.instant('SorryForPaymentOffline'));
          }
        }
      }
      else {
        this.reservationConfirm();
      }
    }));
  }
  skipPaymentOperation(ItemIds) {
    this.reservationConfirm();
  }

  LoadIframe(iframeURl: string) {
    if (iframeURl) {
      this.iframeURL = this.sanitizer.bypassSecurityTrustResourceUrl(iframeURl + "&origin=" + window.location.origin);
      this.iframeURL = this.iframeURL.changingThisBreaksApplicationSecurity ? this.iframeURL.changingThisBreaksApplicationSecurity : this.iframeURL;
      this.showIframe = true;
    }
    else {
      this.showMessage(this.ts.instant('somethingWentWrong'));
    }
  }


  showWarningInfoPopUp(message, componentTypes , cartData?) {
    const popUpMessage = message
    const componentDetails: ComponentDetails = {
      componentName: ConfirmationPopupComponent,
      dimensionType: 'small',
      popupType: 'active',
      popUpDetails: {
        isStepper: false,
        eventName: 'notifyParent'
      },
      popupInput: popUpMessage,
      popupTitle: popUpMessage[0].dialogTitle
    };

    var warningInfoDialogRef = this.dialog.open(CustomPopupComponent, {
      disableClose: true,
      width: '550px',
      height: '320px',
      data: {
        title: this.ts.instant('Confirmation'),
        update:  this.ts.instant('Yes'),
        cancel:  this.ts.instant('No'),
        componentDetails,
        from: componentTypes,
        back: false,
        standalone: true,
        showAlert: true
      }
    });

    let confirmationSubscription = this.popupService.confirmedAction$.subscribe(val => {
      if (val === ComponentTypes.CartItemsDisplayComponent) {
        this._as.offTimer();
        this.unlockCartItemsMultiple(this.getUnlockCartItems(this.cartSumaryDataList));
        this.removeCartItems(this.cartSumaryDataList.map(({ Id }) => Id));
      }
      if (val === ComponentTypes.applyPackageConfirmation) {
        this.applyPackage(true , null , null , true);
      }
    });
    let cancelSubscription = this.popupService.cancelledAction$.subscribe(val => {
      if (val && val.from === ComponentTypes.applyPackageConfirmation && val.value == 1) {
        this.partyService.selectedPackage = null;
        this.partyService.selectedPackageId = null;
        this.applyPackage(false , null , null , true);
      }
    });
    warningInfoDialogRef.afterClosed().subscribe(data => {
      confirmationSubscription.unsubscribe();
      cancelSubscription.unsubscribe();
    });
  }

  getLockData() {
    return this.cartSumaryDataList.filter(x => x.LockSlots && x.LockSlots.length).map(item => { item.LockSlots?.forEach(slot => slot.PropertyId = item.PropertyId); return item.LockSlots; })['flat']().map(({ Id, Type, PropertyId }) => {
      return { LockId: Id, Type, PropertyId }
    });
  }

  calculateCartTotal() {
    var selectedItems = this.cartSumaryDataList.filter(item => item.selected);
    this.numberOfItemsSelected = selectedItems.length;
    this.cartTax = selectedItems.reduce((currentValue, item) => {
      return currentValue +(item.CartItemDetail.rateSummary.TaxAmt || 0);
    }, 0)
    this.cartServiceCharge = selectedItems.reduce((currentValue, item) => {
      return currentValue + (item.CartItemDetail.rateSummary.SCAmt || 0);
    }, 0)
    this.cartServiceChargeWithTax = selectedItems.reduce((currentValue, item) => {
      return currentValue + (item.CartItemDetail.rateSummary.TaxOnSCAmt || 0);
    }, 0)
    this.cartVAT = selectedItems.reduce((currentValue, item) => {
      return currentValue + (item.CartItemDetail.rateSummary.VAT || 0);
    }, 0)
    this.cartServiceChargeWithVAT = selectedItems.reduce((currentValue, item) => {
      return currentValue + (item.CartItemDetail.rateSummary.VATSCAmt || 0);
    }, 0)
    this.cartTotal = selectedItems.reduce((currentValue, item) => {
      return currentValue + (item.RatePlan.Total || 0)
        }, 0)
    this.cartNegotiatedAmount = selectedItems.reduce((currentValue, item) => {
      return currentValue + (item.CartItemDetail.rateSummary.OverAllNegotiation ? (item.CartItemDetail.rateSummary.OverAllNegotiation || 0) : 0);
    }, 0)

  }

  extendLockSessionTime(CartItem,event, popover){
    // document.getElementById('time-overlay').classList.remove('report-transform3dnone');
    // if (this.utils.getTimeDifference(this.reportsinput.controls.startTime.value, this.reportsinput.controls.endTime.value, 'Min') < 0) {
    //   this.reportsinput.controls.endTime.setErrors({ invalid: true });
    // }

    CartItem.isLoading = true;
    this.partyService.editCartId = null;

    let expireTime = null;
    if(event){
    //expireTime = moment(Utilities.getRestaurantDateTime(this._settings.General.DaylightDelta)).format('YYYY-MM-DD') + 'T' + this.format24HrTime(event)    
      // let expireDate = moment().utc().add(event,'minutes')
      // expireTime = (moment(expireDate).format('YYYY-MM-DD') + 'T' + moment(expireDate).format('HH:mm:ss')).toString();
      let expireDate = moment().add(event,'minutes')
      expireTime = (moment(expireDate).utc().format('YYYY-MM-DD') + 'T' + moment(moment.utc(expireDate).valueOf()).format('HH:mm:ss')).toString();

      let cartIds = CartItem.groupItems.map(groupItem => groupItem.Id);
      this.partyService.extendCartLock(cartIds, CartItem.groupItems[0].PropertyId, event).subscribe(data => {
        CartItem.groupItems.forEach(cartGroupItem => {
          cartGroupItem.ExpireAt = expireTime;
          let _cartBookingObj = this.partyService.TotalCartBookingObj.find(cartBookingObj => cartBookingObj.Id === cartGroupItem.Id)

          if(_cartBookingObj?.CartItemDetail){
            _cartBookingObj.CartItemDetail.ExpireAt = expireTime;
          }
        });
      })
    }
    else{
      let expireDate = moment().utc().add((Number(this.cs.settings.value.General.SlotLockInSec) / 60), 'm').toDate();
      expireTime = (moment(expireDate).format('YYYY-MM-DD') + 'T' + moment(expireDate).format('HH:mm:ss')).toString();
      this.lockCartForGroup(CartItem,expireTime)
    }
  }

  format24HrTime(dateValue: any): string {
    let date = dateValue.split(' ')[0];
    let format =  dateValue.split(' ')[1];
    let hrs = date.split(':')[0] < 10 ? '0' + date.split(':')[0] : date.split(':')[0];
    if (format == "PM" && hrs < 12) 
    hrs = Number(hrs) + 12;
     if (format == "AM" && hrs == 12) 
     hrs = Number(hrs) - 12;
   
    // const minutes =
    // date.split(':')[1] < 10 ? '0' + date.split(':')[1] : date.split(':')[1];
    return hrs + ':' + date.split(':')[1] + ':' + '00';
  }


  applyPackage(applyPackage , isEditCartItem : boolean = false , editCartData: any = {} , proceedPayment:boolean = false) {

    var selectedCartItems = this.cartSumaryDataList.filter(item => item.selected || item.packageAvailable);
    let payload = [];
    selectedCartItems.forEach(item => {
      let RatePlanReCalculationRequest = {
        RatePlan: item.RatePlan || null,
        ActivityId: item.CartItemDetail.SpecialMealId,
        ReservationId: null,
        PackageDetails: this.partyService.getDiscountPercentForCartItem(item.CartItemDetail.SpecialMealId),
        PropertyId: item.PropertyId,
        CartId: item.Id,
        ContactId: item.CartItemDetail?.Contact.Id,
        FinancialEffectId: item.CartItemDetail?.FinancialEffectId || null,
      }
      payload.push(RatePlanReCalculationRequest)
    })
    if (applyPackage && this.partyService.selectedPackage) { // Apply Package
      this.subscriptions.add(this.api.ApplyPackage(payload).subscribe(data => {
        if (data) {
          this.isPackageApplied = true;
          this.toastrService.success(this.ts.instant("PackageAppliedMessage"), "", { timeOut: 1000, closeButton: true });
          this.highlightPackage(true);
          this.mapPackageWithCartItem(data.Payload, true);
          if(proceedPayment){
            this.proceedForPayment();
          }
        }
      }))
    } else if((selectedCartItems?.length && this.isPackageApplied && this.partyService.selectedPackage) ){
      this.subscriptions.add(this.api.ClearPackage(payload).subscribe(data => {
        if (data) {
          this.partyService.selectedPackageId = null;
          this.partyService.selectedPackage = null;
          this.isPackageApplied = false;
          this.packageDiscountAmount = 0;
          this.toastrService.success(this.ts.instant("packageCleared"), "", { timeOut: 1000, closeButton: true });
          this.highlightPackage(false);
          this.mapPackageWithCartItem(data.Payload, false);
          if(isEditCartItem){
            this.editCartData(editCartData.eventData , editCartData.cartData , true);
          }
        }
      }))
    }else if(!applyPackage && !this.isPackageApplied){
      this.partyService.selectedPackageId = null;
      this.partyService.selectedPackage = null;
      this.isPackageApplied = false;
      this.highlightPackage(false);
      if(proceedPayment){
        this.proceedForPayment();
      }
    }

  }

  highlightPackage(packageApplied) {
    if (this.cartSumaryDataList && this.cartSumaryDataList.length) {
      this.cartSumaryDataList.map(item => {
        if (item.selected && packageApplied) {
          item.packageAvailable = packageApplied;
        } else {
          item.packageAvailable = false;
        }
      })
    }
  }
  mapPackageWithCartItem(packageData: any, packageApplied) {
    if (this.cartSumaryDataList && this.cartSumaryDataList.length) {
      this.cartSumaryDataList.map(item => {
        if (packageData?.find(data => data.CartId == item.Id)) {
          let ratePlanData = packageData?.find(data => data.CartId == item.Id) || null;
          item.RatePlan = ratePlanData?.RatePlan || null;
          item.ShopItems = ratePlanData?.ShopItems || null
          if(item.packageAvailable){
            item.CartItemDetail.PackageDetails = this.partyService.getDiscountPercentForCartItem(item.CartItemDetail.SpecialMealId);
          }else{
            item.CartItemDetail.PackageDetails = null;
          }
        }
      })
    }
    if (packageApplied) {
      this.packageDiscountAmount = _.sumBy(packageData, (rates: any) => { return rates.RatePlan.Total });
    } else {
      this.packageDiscountAmount = 0;
    }
    this.packageCartTotal(this.cartSumaryDataList.filter(item => item.selected))
  }



  packageCartTotal(cartItem){
    this.cartTax = 0;
    this.cartVAT = 0;
    this.cartServiceCharge = 0;
    this.cartServiceChargeWithTax = 0;
    this.cartNegotiatedAmount= 0;
    this.subTotal = 0;
    this.promoDiscount = 0;
    this.packageDiscount =0;
    cartItem.forEach(item =>{
      this.cartTax += item.RatePlan?.Tax ||0;
      this.cartVAT += item.RatePlan?.VAT || 0;
      this.cartServiceCharge += item.RatePlan?.ServiceCharge || 0;
      this.cartServiceChargeWithVAT += item.RatePlan?.VATOnServiceChargeAmount || 0;
      this.cartServiceChargeWithTax  += item.RatePlan.TaxOnServiceChargeAmount || 0;
      this.cartNegotiatedAmount += item.RatePlan.Negotiated || 0;
      this.subTotal += item.RatePlan.SubTotal || 0;
      this.promoDiscount += item.RatePlan.Discount || 0; 
      this.tax = this.cartTax;
      this.serviceCharges = this.cartServiceCharge;
      this.serviceChargesWithTax = this.cartServiceChargeWithTax;
      this.packageDiscount += item.RatePlan.PackageDiscountAmount || 0
      this.negotiatedAmount = this.cartNegotiatedAmount;
    })
    this.calculateCartTotal();
  }
  updatePopOver(event, cartItem?, total?) {
    if (cartItem) {
      if(this.partyService.lockCartType === LockMode.Manual){
        this.subTotal = cartItem.RatePlan.SubTotal;
        this.tax = cartItem.RatePlan.Tax;
        this.serviceCharges = cartItem.RatePlan.ServiceCharge;
        this.VAT = cartItem.RatePlan.VAT
        this.VATSCAmt = cartItem.RatePlan.VATOnServiceChargeAmount
        this.serviceChargesWithTax = cartItem.RatePlan.TaxOnServiceChargeAmount;
        this.promoDiscount = cartItem.RatePlan.Discount;
        this.packageDiscount = cartItem.RatePlan.PackageDiscountAmount;
      }else{
        this.subTotal = (cartItem.RatePlanTotal?.TotalRatePlan || 0) + (cartItem.RatePlanTotal?.TotalAddonAmount || 0) + (cartItem.RatePlanTotal?.OverAllNegotiation || 0);
        this.tax = (cartItem.RatePlanTotal.TotalTaxAmount || 0) + (cartItem.RatePlanTotal.TotalAddonTaxAmount || 0);
        this.serviceCharges = (cartItem.RatePlanTotal.TotalServiceChargeAmount || 0) + (cartItem.RatePlanTotal.TotalAddonServiceChargeAmount || 0);
        this.negotiatedAmount = (cartItem.RatePlanTotal.OverAllNegotiation || 0);
        this.VAT = cartItem.CartItemDetail.rateSummary.VAT
        this.VATSCAmt = cartItem.CartItemDetail.rateSummary.VATSCAmt
        this.promoDiscount =cartItem.RatePlan.Discount;
        this.packageDiscount = cartItem.RatePlan.PackageDiscountAmount;
        this.serviceChargesWithTax = (cartItem.RatePlanTotal.TotalTaxOnServiceChargeAmount || 0) + (cartItem.RatePlanTotal.TotalAddonTaxOnServiceChargeAmount || 0);
      }
    }else if(total) {
      this.packageCartTotal(this.cartSumaryDataList.filter(item => item.selected));
    }
    event.stopPropagation();
  }

  showExistingReservationPopup(fromType: ComponentTypes, bookingDTO, sessions) {
    let proceedBooking = false;
    sessions = sessions.filter((messageData) => messageData.Type == ValidationMessageType.ConsentMessages);
    sessions = sessions.map(messageData => messageData?.Message || messageData);
    if (sessions && sessions.length) {
      let msg = this.ts.instant('existingBookingInfoMessage');
      this.partyService.showWarningInfoPopUp(msg, ComponentTypes.ExistngReservationWarning, '450px', '750px', sessions)
      this.subscriptions.add(this.partyService.warningInfoDialogRef.afterClosed().subscribe(event => {
        if (proceedBooking) {
          this.bookCart(bookingDTO, true);
        }
        confirmationSubscription.unsubscribe();
      }));

      let confirmationSubscription = this.popupService.confirmedAction$.subscribe(val => {
        if (val === ComponentTypes.ExistngReservationWarning) {
          proceedBooking = true;
        }
      });
    }
  }

  showMessage(msg) {
    const title = this.ts.instant('alert');
    this.messageDialogRef = Utilities.showSettingsChangedInfo(msg, title, this.dialog);
  }

  ngOnDestroy() {
    this._as.offTimer();
    this.partyService.promoCodeApplied$.next(null);
    this.IsLockTimer = false;
    this.partyService.TotalCartBookingObj = [];
    this.partyService.cartCount = 0;
    if (this.subscriptions) {
      this.subscriptions.unsubscribe();
    }
    if (this.searchConfigFormSubscription) {
      this.searchConfigFormSubscription.unsubscribe();
    }
  }

  continueShopping() {
    this._as.isCartPaymentInProgress = false;
    this._as.showCartItem = false;
    if (Utilities.IsActivitiesVenues(this._as.PropertyType)) {
      this.componentDetails = {
        componentName: SelectBookingTypeComponent,
        popupType: 'action',
        popUpDetails: {
          isStepper: false,
          eventName: 'notifyParent'
        }
      };
      this.partyService.reservationDialogRef = this.dialog.open(CustomPopupComponent, {
        disableClose: true,
        height: popupDialogDimension.actionDialogHeight,
        width: popupDialogDimension.createDialogWidth,
        backdropClass: 'backdropBackground',
        data: {
          title: this.ts.instant('addPopupTitle'), update: this.ts.instant('SAVE'), cancel: this.ts.instant('CANCEL'), componentDetails: this.componentDetails, popupType: 'action'
        }
      });
    } else {

      this.partyService.reservationDialogRef = this.dialog.open(CustomPopupComponent, {
        disableClose: true,
        height: popupDialogDimension.actionDialogHeight,
        width: popupDialogDimension.actionDialogWidth,
        backdropClass: 'backdropBackground',
        data: {
          title: this.ts.instant('addPopupTitle'), update: this.ts.instant('SAVE'), cancel: this.ts.instant('CANCEL'), componentDetails: this.componentDetails, popupType: 'action'
        }
      });
    }
  }


  //Proceed Button Enable/Disable property
  proceedBtnValidate() {
    let selectedItem = this.cartSumaryDataList?.filter(item => item.selected && (item.isAvailable || item.StandBy) );
    if (selectedItem?.length && ((PaymentMethod.Authorize == this.partyService.bookingPaymentType && this.partyService.RetailCardTokenId) || (PaymentMethod.Authorize !== this.partyService.bookingPaymentType))) {
      this.proceedBtn.disbaledproperity = false;
      this.applyPromoCode.disbaledproperity = false;
    } else {
      this.proceedBtn.disbaledproperity = true;
      this.applyPromoCode.disbaledproperity = true;
    }
    this.promoCodeEligibleProperty = selectedItem?.map(item => item.PropertyId)?.flat();
    this.partyService.packageMatchValidation$.next(selectedItem);
  }
  setPartyPaymentType(data) {
    if (data) {
    this.proceedBtnValidate();
    }
  }
  setRetailCardTokenId(data) {
    if (data ) {
      this.proceedBtnValidate()
    }
  }

  timerExpired(cartGroup){
    cartGroup.groupItems.forEach(data =>{
      data.isAvailable = false;
      data.lockExpired = true;
      this.cartItemClicked(cartGroup, data);
    })
    cartGroup.groupItems = [...cartGroup.groupItems];

    this.afterSelectionHandler(cartGroup);
  }
  selectAllInGroup(event: Event, cartGroup){
    event.stopPropagation();
    event.preventDefault();
    if(cartGroup.groupItems.every(cartItem => cartItem.selected)){
      cartGroup.groupItems.forEach(element => {
        element.selected = false;
      });
    }
    else{
      cartGroup.groupItems.forEach(cartItem => {
        cartItem.selected = false;
        this.cartItemClicked(cartGroup, cartItem, true);
      });
    }
    this.afterSelectionHandler(cartGroup);
  }

  afterSelectionHandler(cartGroup){
    this.groupedCartSummaryDataList.forEach(group =>{
      if(group.groupName != cartGroup.groupName){
        group.allSelected = false;
        group.groupItems.forEach(element => {
          element.selected = false;
        });
      }
    });
    cartGroup.allSelected = cartGroup.groupItems.every(cartItem => cartItem.selected);
    this.calculateCartTotal();
    this.proceedBtnValidate();
  }
  removeGroup(event,cartItem,index){
    this.openConfirmationForDelete(event,cartItem,index)
  }

  openConfirmationForDelete(event,cartItem,index) {
    const popUpMessage = [{
      confirmationMessage: this.ts.instant('deleteGroup', {selectedGroup: cartItem.groupName}),
      dialogTitle: this.ts.instant('deleteTitle')
    }];
    let componentDetails = {
      componentName: ConfirmationPopupComponent,
      popupType: 'active',
      popUpDetails: {
        isStepper: false,
        eventName: 'notifyParent'
      },
      popupInput: popUpMessage,
      // ""
    };

    let dialogRef = this.dialog.open(CustomPopupComponent, {
      disableClose: true,
      height: 'auto',
      width: '500px',
      data: {
        title: this.ts.instant('deleteTitle'),
        update: this.ts.instant('delete'),
        cancel: this.ts.instant('cancel'),
        componentDetails,
        back: false,
        from: ComponentTypes.commonconfirmmessage,
        standalone: true
      }
    });

    dialogRef.afterClosed().subscribe(data=>{
      if (confirmedAction) {
        confirmedAction.unsubscribe();
      }
    });

    let confirmedAction = this.popupService.confirmedAction$.subscribe(data => {

      this.partyService.TotalCartBookingObj = _.difference(this.partyService.TotalCartBookingObj, cartItem.groupItems);

      this.unlockCartItemsMultiple(this.getUnlockCartItems(this.groupedCartSummaryDataList[index].groupItems));

      this.groupedCartSummaryDataList[index].groupItems.forEach(item =>{
        this.removeItem(event, item);
      })
      this.groupedCartSummaryDataList.splice(index,1);
    })
  }

  _keyUp(event) {
    if (event.target.value.length == 1 && event.which == 48) {
      this.minutesExtended = null;
    }

    if(isNaN(this.minutesExtended)){
      this.minutesExtended = this.minutesExtended['substring'](1, -1)
    }

    this.timerApplyButtonConfig.disbaledproperity = !this.minutesExtended || isNaN(this.minutesExtended);
  }
}


@Directive({
  selector: '[showExpireTime]'
})
export class ShowExpiryTime   implements OnChanges{
  @Input() CartItem:any;
  @Input() _settings:SettingsDTO;
  @Input() isAvailable:any;
  @Input() expireTime:any;
  @Output() timerExpired: EventEmitter<any> = new EventEmitter<any>();
  
  timerSubscription: Subscription;

  constructor(private _el: ElementRef, private availableCartItem: AvailableCartItem, private ts: TranslateService){}
  
  ngOnInit(){
  

  }


  ngOnChanges(changes : SimpleChanges){
    this.loadTimer();
  }

  loadTimer(){
    this.CartItem.isLoading = true;
    let _cartItem = this.availableCartItem.transform(this.CartItem.groupItems);

    if(this.isAvailable && this.expireTime && (moment(this.expireTime).diff(moment().utc(), "seconds") > 0)){
      _cartItem.availableCartItemValue.CartItemDetail.Time = '';
      this.setTimer(this.expireTime);
     // this.CartItem.Time = this.formatTime((moment(this.CartItem.ExpireAt).diff(moment(Utilities.getRestaurantDateTime(this._settings.General.DaylightDelta)), "seconds")));

    }
    else{

      if(_cartItem?.availableCartItemValue){
        _cartItem.availableCartItemValue.CartItemDetail.Time =  null;
      }
      this.CartItem.isLoading = false;
    }
  }
  setTimer(ExpireTime){
  //  this.offTimer();

  if(this.timerSubscription){
    this.timerSubscription.unsubscribe();
  }
    this.timerSubscription = interval(1000 * 1).subscribe(() => {
      // this.totalTime = this.totalTime - 1;
      // this.minutes = Math.floor(this.totalTime / 60);
      // this.seconds = Number(this.totalTime % 60);
      let time =  (moment(this.expireTime).diff(moment().utc(), "seconds"));
      if (time <= 0) {
        // this.showCartItem = false;
        this.CartItem.isLoading = true;
        this.offTimer();
      }
      else{
        let _cartItem = this.availableCartItem.transform(this.CartItem.groupItems);
        if(_cartItem?.availableCartItemValue){
          _cartItem.availableCartItemValue.CartItemDetail.Time =  this.formatTime(time);
        }
      }
    })
    this.CartItem.isLoading = false;
  }
  offTimer() {
    this.CartItem.isLoading = false;
    if (this.timerSubscription) {
      this.timerSubscription.unsubscribe();
    }
    this.timerExpired.emit();
    let _cartItem = this.availableCartItem.transform(this.CartItem.groupItems);

    if(_cartItem?.availableCartItemValue){      
      _cartItem.availableCartItemValue.CartItemDetail.Time =  null;
    }
  }

  formatTime(time){
    return Math.floor(time / 60) + ' '+ this.ts.instant('mins') +' ' + Number(time % 60) + ' '+this.ts.instant('secs') + ' ';
  }
}


@Pipe({
  name: 'searchDataGroupPipe'
})
export class SearchDataGroupPipe implements PipeTransform {

  transform(cartItems: any[], searchString: string): any[] {

    if (cartItems.length == 0 ||  !searchString) {
      return cartItems;
    }
    return cartItems.filter(x => {
       if(((x.groupName ? x.groupName.toLowerCase() : '').includes(searchString)) || (( x.groupItems.filter(groupData => {  return  groupData.CartItemDetail.PartyName.toLowerCase().includes(searchString)}))).length > 0){
        return true;
       }
       else{
        return false;
       }
       


    }
    );
  }


}

@Pipe({
  name: 'getSelectedPipe',
  pure: false
})
export class GetSelectedPipe implements PipeTransform {

  transform(cartItems: any[]): boolean {

      return (_.filter(cartItems,'selected').length == cartItems.length)



    }
  }

  
@Pipe({
  name: 'availableCartItem'
})
export class AvailableCartItem implements PipeTransform {

  transform(cartItems: any[]): any {
      let _isStandByOnly = false;

      if(cartItems.length === cartItems.filter(cartItem => cartItem.CartItemDetail.IsForStandbyReservations || cartItem.CartItemDetail.BookingBehavior === BookingBehavior.PrivateLesson).length){
        _isStandByOnly = true;
      }

      let _availableCartItemValue = null;

      if(!_isStandByOnly){
        _availableCartItemValue = cartItems.find(cartItem => {
          let _isAvailable = cartItem.isAvailable ?? !moment(cartItem.CartItemDetail.ExpireAt).isBefore(moment().utc());
          return _isAvailable && !cartItem.CartItemDetail.IsForStandbyReservations && cartItem.CartItemDetail.BookingBehavior !== BookingBehavior.PrivateLesson
        })
      }

      return {
        availableCartItemValue: _availableCartItemValue,
        isStandByOnly: _isStandByOnly
      }
    }
  }


  @Pipe({
    name: 'checkSlotLock'
  })
  export class CheckSlotLockPipe implements PipeTransform {
  
    transform(cartItem): any {
      if(Object.values(cartItem.CartItemDetail.SlotLockResultDTO).length){
        if(BookingBehavior.OpenBooking != cartItem.CartItemDetail.BookingBehavior ){
        return cartItem.CartItemDetail.SlotLockResultDTO;
        }
      else {
        return Object.values(cartItem.CartItemDetail.SlotLockResultDTO)?.[0]['SlotLockIdDTO'];
      }
      }
       
      }
    }
  

  