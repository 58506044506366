import { Injectable } from "@angular/core";
import { CacheService } from "@app/core/services/cache.service";
import { ReservationType } from "@app/shared/constants/commonenums";
import { AddonBookingDTO, SelectionType } from "@app/shared/models/RestaurantDTO";
import { PartyService } from "@app/shared/services/party.service";
import { Utilities } from "@app/shared/utilities/utilities";
import _ from "lodash";

@Injectable()
export class ReservationAddonsService {
    addonHeader;
    data;
    mandatoryAddOns;
    additionalAddOns;
    allEligibleAddOns;
    allAddons = {};
    _settings;
    constructor(private partyService: PartyService, private chacheService: CacheService) {
        this.chacheService.settings.subscribe(settings => {
            this._settings = settings;
        })
    }

    setAddons(bookingSize: number) {
        let activity;
        if (this.partyService.reservationType == ReservationType.OpenBooking) {
            activity = null;
        }
        else {
            activity = this.data && this.data.SpecialMealId ? this.data.SpecialMealId : this.partyService.selectedSpecialMealId;
        }
        let getAddOns;
        if ((this.partyService.reservationType == ReservationType.OpenBooking || this.partyService.reservationType == ReservationType.Reservation) && this.partyService.selectedSlot$?.value?.length) {
            getAddOns = Utilities.getAddons(this.chacheService.settings.value.Addons, activity, this.partyService.selectedSlot$?.value[0]?.DateTime);
        } else {
            getAddOns = Utilities.getAddons(this.chacheService.settings.value.Addons, activity);
        }
        this.mandatoryAddOns = [];
        this.additionalAddOns = [];
        this.allEligibleAddOns = [];
        getAddOns.forEach(item => {
            let availableMandatoryAddOn: AddonBookingDTO = this.chacheService.settings.value.Addons.find(addOnData => addOnData.AddonId == item.AddonId);
            if (availableMandatoryAddOn) {
                availableMandatoryAddOn['IsVisible'] = item.IsVisible;
                if (availableMandatoryAddOn.SelectionType == SelectionType.PerLesson) {
                    availableMandatoryAddOn['MaxQuantity'] = 1;
                } else if(availableMandatoryAddOn.SelectionType == SelectionType.PerGuest){
                    availableMandatoryAddOn['MaxQuantity'] = item.MaxQuantity;
                } else {
                    availableMandatoryAddOn['MaxQuantity'] = item.MaxQuantity;
                }

                if(availableMandatoryAddOn.SelectionType == SelectionType.PerGuest){
                    availableMandatoryAddOn['MinQuantity'] = item.MinQuantity * bookingSize;
                    // availableMandatoryAddOn['MaxQuantity'] = item.MaxQuantity * bookingSize;  // BookingSize is not multiplied by maximum quantity; it is set based on configuration
                    // availableMandatoryAddOn['MaxQuantity'] = item.MaxQuantity * bookingSize; // BookingSize is not multiplied by maximum quantity; it is set based on configuration
                }else{
                    availableMandatoryAddOn['MinQuantity'] = item.MinQuantity;
                }
                availableMandatoryAddOn['Price'] = availableMandatoryAddOn.AddOnDetails?.length ? availableMandatoryAddOn.AddOnDetails[0]?.Price : 0;
                availableMandatoryAddOn['IsCategoryLevelMandatory'] = item.IsCategoryLevelMandatory;
                availableMandatoryAddOn['OverAllQuantity'] = this.partyService.selectedSlot$.value?.[0]?.AddOnAvailability?.find(data => data.AddonId == availableMandatoryAddOn.AddonId)?.OverAllQuantity || 0;


                if (this.partyService.selectedSlot$.value?.[0]?.isStandBy && availableMandatoryAddOn.SelectionType !== SelectionType.PerLesson) {
                    availableMandatoryAddOn.AvailableQuantity = availableMandatoryAddOn.OverAllQuantity;
                } else {
                    let quantity = this.getAddOnQuantityForBooking(availableMandatoryAddOn);
                    availableMandatoryAddOn.AvailableQuantity = quantity >= 0 ? quantity : 0;
                }

                availableMandatoryAddOn['IsMandatory'] = item.IsMandatory;
                availableMandatoryAddOn['IsUnlimited'] = this.partyService.selectedSlot$.value?.[0]?.AddOnAvailability?.find(data => data.AddonId == availableMandatoryAddOn.AddonId)?.IsUnlimited || false;
                availableMandatoryAddOn['PricingType'] = availableMandatoryAddOn.AddOnDetails?.length ? availableMandatoryAddOn.AddOnDetails[0]?.PricingType : null;
                availableMandatoryAddOn['PricingCategory'] = availableMandatoryAddOn.AddOnDetails?.length ? availableMandatoryAddOn.AddOnDetails[0]?.PricingCategory : null;

                let addOnQuantity = 0;
                if (item.IsMandatory || item.IsCategoryLevelMandatory) {
                    addOnQuantity = this.getAddOnQuantity(availableMandatoryAddOn)
                }
                availableMandatoryAddOn.selectedQuantity = (!item.IsMandatory) && addOnQuantity > availableMandatoryAddOn.AvailableQuantity ? availableMandatoryAddOn.AvailableQuantity : addOnQuantity; // && !item.IsCategoryLevelMandatory

                if ((availableMandatoryAddOn['IsMandatory'] || availableMandatoryAddOn['IsCategoryLevelMandatory']) && addOnQuantity > availableMandatoryAddOn.AvailableQuantity) {
                    availableMandatoryAddOn.OverBooking = availableMandatoryAddOn.OverBooked = addOnQuantity - availableMandatoryAddOn.AvailableQuantity;
                } else {
                    availableMandatoryAddOn.OverBooking = availableMandatoryAddOn.OverBooked = 0;
                }

                //Changes for Standby dining reservation 
                if (this.partyService.isStandbySlotSelected && (this.partyService.reservationType == ReservationType.Reservation || this.partyService.reservationType == ReservationType.StandbyParties)) {
                    let quantity = this.getAddOnQuantityForBooking(availableMandatoryAddOn);
                    availableMandatoryAddOn.AvailableQuantity = availableMandatoryAddOn.AddOnDetails[0].Quantity;
                    availableMandatoryAddOn['OverAllQuantity'] = quantity >= 0 ? quantity : 0
                    availableMandatoryAddOn['IsUnlimited'] = availableMandatoryAddOn.AddOnDetails[0].IsUnlimited;
                    availableMandatoryAddOn.OverBooking = availableMandatoryAddOn.OverBooked = 0;
                }

                if (this.data) {
                    if (this.data.BookingContactAddonItems) {
                        let bookedAddon = this.data.BookingContactAddonItems.find(addOn => addOn.AddonId == availableMandatoryAddOn.AddonId);
                        if (bookedAddon && bookedAddon != undefined && bookedAddon != null) {
                            if ((availableMandatoryAddOn.SelectionType == SelectionType.PerGuest && !item.IsMandatory) || (availableMandatoryAddOn.SelectionType != SelectionType.PerGuest)) {
                                availableMandatoryAddOn.selectedQuantity = bookedAddon.AddonCount || 0;
                            }

                        }
                    }
                    else if (this.data.SelectedAddOns) {
                        if ((availableMandatoryAddOn.SelectionType == SelectionType.PerGuest && !item.IsMandatory) || (availableMandatoryAddOn.SelectionType != SelectionType.PerGuest)) {
                            availableMandatoryAddOn.selectedQuantity = this.data.SelectedAddOns.find(addOn => addOn.AddonId == availableMandatoryAddOn.AddonId)?.Quantity || 0;
                        }

                    }
                }
                availableMandatoryAddOn.selectedQuantity = Number(availableMandatoryAddOn.selectedQuantity);
                if (item.IsMandatory || item.IsCategoryLevelMandatory) {
                    this.mandatoryAddOns.push(availableMandatoryAddOn);
                    this.allEligibleAddOns.push(availableMandatoryAddOn);

                }
                else {
                    this.additionalAddOns.push(availableMandatoryAddOn);
                    this.allEligibleAddOns.push(availableMandatoryAddOn);

                }

            }
        })
        //this.additionalAddOns = _.difference(this.chacheService.settings.value.Addons, this.mandatoryAddOns)
        this.mandatoryAddOns = this.getAddOnsGroupedByCategory(this.mandatoryAddOns)
        this.allEligibleAddOns = this.allEligibleAddOns.filter(_a => _a.IsVisible);
        this.allEligibleAddOns = _.orderBy(this.allEligibleAddOns, 'IsMandatory', 'desc');
        //this.allEligibleAddOns = this.getAddOnsGroupedByCategory(this.allEligibleAddOns)
        // this.additionalAddOns.forEach((item: any) => {
        //   item.selectedQuantity = this.data && this.data.SelectedAddOns.find(addOn => addOn.AddonId == item.AddonId) ? this.data.SelectedAddOns.find(addOn => addOn.AddonId == item.AddonId).Quantity : 0;
        //   item['IsVisible'] = item.IsVisible;
        //   item['MaxQuantity'] = item.MaxQuantity;      
        //   item['MinQuantity'] = item.MinQuantity;
        //   item['IsCategoryLevelMandatory'] = item.IsCategoryLevelMandatory;
        // })
        this.additionalAddOns = this.getAddOnsGroupedByCategory(this.additionalAddOns)
        this.partyService.addOnList = _.flatten([_.flatten(_.map(this.mandatoryAddOns, 'Addons')), _.flatten(_.map(this.additionalAddOns, 'Addons'))]);
        this.allEligibleAddOns.forEach(addon => {
            this.allAddons[addon.AddonId] = addon;
        });
    }


    getAddOnQuantityForBooking(availableMandatoryAddOn) {
        let addOnList = [];
        this.partyService.selectedSlot$.value?.forEach(addOnDetail => {
            addOnList.push(addOnDetail.AddOnAvailability?.find(data => data.AddonId == availableMandatoryAddOn.AddonId))
        })
        return _.minBy(addOnList, 'AvailableQuantity')?.AvailableQuantity || 0
    }

    getAddOnQuantity(addon): number {
        switch (addon.SelectionType) {
            case SelectionType.PerGuest:
                return +this.partyService.bookingSize;
            case SelectionType.PerBooking:
                return +addon.MinQuantity
            case SelectionType.PerLesson:
                return +addon.MinQuantity
        }
    }

    getAddOnsGroupedByCategory(addOnList) {
        return _.chain(addOnList)
            .groupBy("CategoryId")
            .map((value, key) => {
                return {
                    CategoryId: value[0].CategoryId,
                    Addons: value
                }
            })
            .value();
    }

    increaseAddOn(addOnItem) {
        let updateQuantitiy = addOnItem.Quantity + 1;
        if((updateQuantitiy <= this.allAddons[addOnItem.Id].MaxQuantity) || this.allAddons[addOnItem.Id].IsUnlimited ){
            addOnItem.Quantity = updateQuantitiy;
        }
    }

    decreaseAddOn(addOnItem) {
        let updateQuantitiy = addOnItem.Quantity - 1;
        if(updateQuantitiy < 0){
            addOnItem.Quantity = 0;
            return;
        }
        addOnItem.Quantity = updateQuantitiy;
    }

    
    validPerGuestAddonQuantity(addOnItem) {
        addOnItem.Valid = this.allAddons[addOnItem.Id].SelectionType === SelectionType.PerBooking || !(addOnItem.Quantity > this.allAddons[addOnItem.Id].MaxQuantity)
    }
}