import { Component, Directive, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, Renderer2 } from "@angular/core";
import { PartyService } from "@app/shared/services/party.service";
import { Utilities } from "@app/shared/utilities/utilities";
import _ from "lodash";
import { RolesAndPermissionsType, TimeLineAvailablityShowTypes, ViewBy , Status, StaffScheduleType } from "@app/shared/constants/commonenums";
import { RolesAndPermissionPipe } from "@app/shared/pipes/RolesAndPermission.pipe";
import moment from "moment";
import { TimelineDragHandlerService } from "@app/activities-timeline/services/timeline-scroll-handler.service";
import { ActivitiesTimelineService, ModifyConfirmationData } from "@app/activities-timeline/services/activities-timeline.service";
import { LocalizedDatePipe } from "@app/shared/pipes/localize-date.pipe";
import { ModifySessionRequestState } from "@app/shared/models/global.interface";
import { CacheService } from "@app/core/services/cache.service";
import { GetSnackBarMessagesPipe } from "@app/shared/pipes/snackbar-messages.pipe";
import { TranslateService } from "@ngx-translate/core";
import { ToastrService } from 'ngx-toastr';
import { OpenHoursDTO } from "@app/shared/models/RestaurantDTO";
import { DashboardFunctions } from "@app/shared/utilities/dashboard-functions";
@Component({
  selector: '[session-card]',
  templateUrl: './session-card.component.html', 
  styleUrls: ['./session-card.component.scss']
})

export class SessionCardComponent implements OnInit, OnChanges {
  @Input() data;
  @Input() rowData;
  @Input() selectedViewId;
  @Input() serviceData;
  @Input() rows;
  @Input() expandMode;
  @Input() headLineData;
  @Input() switchAxis;
  @Input() session;
  @Input() timeArrayGenerated;
  @Output() promptBlockSessionEvent = new EventEmitter();
  @Output() modifySessionEvent = new EventEmitter();
  @Output() cancelSessionEvent = new EventEmitter();
  @Output() addBookingEvent = new EventEmitter();
  @Output() blockStaffEvent = new EventEmitter();
  @Output() deleteBlockStaffEvent = new EventEmitter();
  state: string;
  overBooked: number = 0;
  RolesAndPermissionsType = RolesAndPermissionsType;
  TimeLineAvailablityShowTypeEnum = TimeLineAvailablityShowTypes;
  timeDetail: {
    StartTime: Date,
    EndTime: Date
  }
  moveDetail: {
    StartTime: string,
    EndTime: string
  };
  styleObject = {
    transform: 'none'
  }
  dragHandlerPosition: {x: number, y: number} = {x: 0, y: 0};
  StatusMaster = Status;
  ViewBy = ViewBy;
  StaffScheduleType = StaffScheduleType;
  constructor(private renderer: Renderer2, private partyService:PartyService, private permissionPipe: RolesAndPermissionPipe, public dragHandlerService:TimelineDragHandlerService, private ats: ActivitiesTimelineService, private localizeDate: LocalizedDatePipe,public cs: CacheService , public toastrService: ToastrService , private ts : TranslateService,private dashboardFunction: DashboardFunctions) {
  }

  ngOnInit(): void {
    if(this.selectedViewId == ViewBy.Lessons && this.switchAxis) {
      this.dragHandlerService.lockAxis = 'y';
    }
    else if(this.selectedViewId == ViewBy.Lessons && !this.switchAxis){
      this.dragHandlerService.lockAxis = 'x';
    }
    else {
      this.dragHandlerService.lockAxis = '';
    }
    this.timeDetail = {
      StartTime: typeof this.data.StartTime === 'string' ? Utilities.getDateFromTime(this.data.StartTime, new Date(this.data.Date)) : this.data.StartTime,
      EndTime: typeof this.data.StartTime === 'string' ? Utilities.getDateFromTime(this.data.EndTime, new Date(this.data.Date)) : this.data.EndTime
    } 
  }

  ngOnChanges() {
  if(!this.serviceData){
    this.serviceData = {
      Available: this.data.MaxActivityStrength,
      Booked: 0,
      addonblocked: false,
      isVIP: false
    }
  }
  if(this.selectedViewId == ViewBy.Instructor) {
    if(this.data?.staffScheduleType == StaffScheduleType.BreakOrBlock) {
    this.state = 'staff-blockhour'
    }
    else if(this.data?.staffScheduleType == StaffScheduleType.OffShift) {
      this.state = 'staff-offshift'
    }
  }
   if(this.data.isPrivateLessonBooking){
    this.state = 'standby-session';
    let confirmedParty = this.data.ReservedFor;
    if(confirmedParty){
      this.state = 'confirmed-session';
    }
   }else{
    if(this.data.StatusCode == Status.Cancelled){
      this.state = 'cancelled-session'

    }else if(this.data.isOpenBooking || this.serviceData?.Available > 0 || ( this.serviceData?.Available != 0 && !this.serviceData?.Available && this.data.MaxActivityStrength)){
      this.state = 'available-session'
      this.overBooked = this.data?.OverBooked || 0;
    }
    else if(!this.data.isOpenBooking && (this.serviceData?.Available == 0)){
      this.state = 'unavailable-session';
    }
   else if(this.serviceData?.Available == 0 && this.data.MaxActivityStrength && this.data.AllowStandBy){
      this.state = 'standby-session'
    }
    this.overBooked = this.serviceData?.OverBooked || 0;
   
   }

  }

  dragStartHandler(event){
    if(event.event.type === 'touchmove'){
      this.dragHandlerService.dragStartPoints.left = event.event.touches[0].clientX + 2;
      this.dragHandlerService.dragStartPoints.top = event.event.touches[0].clientY + 2;
    }else if(event.event.type === "mousemove"){
      this.dragHandlerService.dragStartPoints.left = event.event.clientX + 2;
      this.dragHandlerService.dragStartPoints.top = event.event.clientY + 2;
    }else {
      event.preventDefault();
      return;
    }
    this.dragHandlerService.dragStarted = true;
  }

  dragMoveHandler(event){
    let distance;
    if(event.event.type === 'touchmove'){
      distance = {
        x: event.event.touches[0].clientX - this.dragHandlerService.dragStartPoints.left,
        y: event.event.touches[0].clientY - this.dragHandlerService.dragStartPoints.top,
      }
      let elm = document.elementFromPoint(event.event.touches[0].clientX, event.event.touches[0].clientY)
      let direction : 1 | -1 | any = +elm.getAttribute("direction");
      let axis : 'left' | 'top' | any = elm.getAttribute("axis");

      let rowId: any = +elm.getAttribute("rowId");
      if(rowId){
        this.dragHandlerService.hoveredRowId = rowId;
      }

      if(direction && axis){
        !this.dragHandlerService.scrollSubscriptionData && this.dragHandlerService.autoScrollHandler(direction, this.ats.viewPort, axis);
      }else if(this.dragHandlerService.scrollSubscriptionData){
        this.dragHandlerService.mouseLeave();
      }
    }else if(event.event.type === "mousemove"){
      distance = {
        x: event.event.clientX - this.dragHandlerService.dragStartPoints.left,
        y: event.event.clientY - this.dragHandlerService.dragStartPoints.top,
      }
    }else {
      event.preventDefault();
      return;
    }
    let minutes = Math.ceil((this.switchAxis ? distance.y : distance.x) / this.ats.oneMinutePixels);
    let mismatchMinutes = minutes % 5;
    let moveMinutes = minutes - mismatchMinutes;
    let StartTime = new Date(this.timeDetail.StartTime);
    let EndTime = new Date(this.timeDetail.EndTime);
    StartTime.setMinutes(StartTime.getMinutes() + moveMinutes);
    EndTime.setMinutes(EndTime.getMinutes() + moveMinutes);
    this.moveDetail = {
      StartTime: this.localizeDate.transform(StartTime, 'LT'),
      EndTime: this.localizeDate.transform(EndTime, 'LT')
    }
    if(this.dragHandlerService.lockAxis == ''){
      this.styleObject.transform = `translate(${distance.x}px,${distance.y}px)`
    } else if(this.dragHandlerService.lockAxis == 'x'){
      this.styleObject.transform = `translate(${distance.x}px,${0}px)`
    } else if(this.dragHandlerService.lockAxis == 'y'){
      this.styleObject.transform = `translate(${0}px,${distance.y}px)`
    }
    
  }

  dropHandler(event) {
    let propertyId: string;
    let propertyName: string;

    if (event.event.type == 'touchend') {
      let touchPoints = event.event.changedTouches[0];
      let dropElm = document.elementFromPoint(touchPoints.clientX, touchPoints.clientY);
      propertyId = dropElm.getAttribute("rowId");
      propertyName = dropElm.getAttribute("rowName");
    } else {
      propertyId = event.event.target.getAttribute("rowId");
      propertyName = event.event.target.getAttribute("rowName");
    }

    this.dragHandlerService.dragStarted = false;
    this.dragHandlerService.hoveredRowId = null;
    this.dragHandlerPosition = { x: 0, y: 0 }
    this.dragHandlerService.scrollSubscriptionData = null;

    if (propertyId && propertyName) {
      let changes = [];
      let subText, title = '';

      let currentDayOpenHours = Utilities.getRestaurantOpenHoursForDay(this.cs.settings.value.OpenHours, this.cs.headerDate);
      if (currentDayOpenHours?.length) {
        let isValid = this.validateStartEndTime(currentDayOpenHours[0]);
        if(!isValid) {
          this.moveDetail = null;
          this.dashboardFunction.showErrorPopUp(this.ts.instant("The selected {{session}} cannot be moved because it falls outside the property's operating hours {{time}}. Please select a time within the designated hours.", { session: this.data.ActivitySessionId ? this.data.specialMealName : this.data.SlotSubHeader[this.selectedViewId], time: `${this.localizeDate.transform(currentDayOpenHours[0]?.EffectiveRange.Start, 'LT')} - ${this.localizeDate.transform(currentDayOpenHours[0]?.EffectiveRange.End, 'LT')}`}));
          return
        }
      }

      if (this.moveDetail.StartTime !== this.localizeDate.transform(this.timeDetail.StartTime, 'LT')) {
        changes.push({ label: 'time', newValue: `${this.moveDetail.StartTime} - ${this.moveDetail.EndTime}`, oldValue: `${this.localizeDate.transform(this.timeDetail.StartTime, 'LT')} - ${this.localizeDate.transform(this.timeDetail.EndTime, 'LT')}`, time: `${this.localizeDate.transform(this.timeDetail.StartTime, this.cs.settings.value.General.DateFormat)}` });
      }
      if (this.data.isOpenBooking) {
        title = this.data.SlotHeader[this.selectedViewId];
        subText = this.data.SlotSubHeader[this.selectedViewId];
        propertyId != this.rowData.Id && changes.push({ label: 'location', newValue: `${propertyName}`, oldValue: `${this.rowData.Name}`, newPropertyId: `${propertyId}` });
      } else if (this.selectedViewId === ViewBy.location) {
        propertyId != this.rowData.Id && changes.push({ label: 'location', newValue: `${propertyName}`, oldValue: `${this.rowData.Name}`, newPropertyId: `${propertyId}` });
        title = this.data.SlotSubHeader[this.selectedViewId];
        subText = this.data.SlotHeader[this.selectedViewId];
      } else if (this.selectedViewId === ViewBy.Instructor) {
        propertyId != this.rowData.Id && changes.push({ label: 'instructor', newValue: `${propertyName}`, oldValue: `${this.rowData.Name}`, newPropertyId: `${propertyId}` });
        title = this.data.SlotHeader[this.selectedViewId];
        subText = this.data.SlotSubHeader[this.selectedViewId];
      } else if (this.data.isPrivateLessonBooking) {
        // propertyId != this.rowData.Id && changes.push({label: 'Lessons', newValue: `${propertyName}`, oldValue: `${this.rowData.Name}`});
        title = this.data.SlotHeader[this.selectedViewId];
        subText = this.data.SlotSubHeader[this.selectedViewId];
      } else {
        title = this.rowData.Name;
        subText = this.data.SlotSubHeader[this.selectedViewId];
      }

      if (changes.length) {
        let data: ModifyConfirmationData = {
          title,
          subText,
          changes,
        }
        if (this.data.isOpenBooking || this.data.isPrivateLessonBooking) {
          this.ats.getBooking(data, this.data, this.moveDetail);
        }
        else if (this.data.ActivitySessionId) {
          this.sessionModifyConfirmation(data);
        }
      }
    }
    this.moveDetail = null;

  }

  convert24HrTime(timeString: string)  {
    const [time, modifier] = timeString?.split(' ');
   let [hours, minutes] = time?.split(':');
   if (hours === '12') {
      hours = '00';
  }
   if (modifier === 'PM') {
      hours = (parseInt(hours, 10) + 12).toString();
   }
   return `${hours}:${minutes}`;
  }

  validateStartEndTime(currentDayOpenHours: OpenHoursDTO) {
    let isValid = true;
    let openHourEndTime = this.localizeDate.transform(currentDayOpenHours?.EffectiveRange.End, 'HH:mm');
    let openHourEndTimeIndex = this.timeArrayGenerated.findIndex((time) => time._i.getMinutes() === +openHourEndTime.split(":")[1] && time._i.getHours() === +openHourEndTime.split(":")[0]);
    let moveDetailEndTime = this.convert24HrTime(this.moveDetail.EndTime);
    let moveDetailEndTimeIndex = this.timeArrayGenerated.findIndex((time) => time._i.getMinutes() === +moveDetailEndTime.split(":")[1] && time._i.getHours() === +moveDetailEndTime.split(":")[0]);
    let openHourStartTime = this.localizeDate.transform(currentDayOpenHours?.EffectiveRange.Start, 'HH:mm');
    let openHourStartTimeIndex = this.timeArrayGenerated.findIndex((time) => time.minutes() === +openHourStartTime.split(":")[1] && time.hours() === +openHourStartTime.split(":")[0]);
    let moveDetailStartTime = this.convert24HrTime(this.moveDetail.StartTime);
    let moveDetailStartTimeIndex = this.timeArrayGenerated.findIndex((time) => time._i.getMinutes() === +moveDetailStartTime.split(":")[1] && time._i.getHours() === +moveDetailStartTime.split(":")[0]);
    if(moveDetailStartTimeIndex > moveDetailEndTimeIndex && (moveDetailEndTimeIndex < openHourEndTimeIndex || moveDetailStartTimeIndex > openHourStartTimeIndex)) {
      isValid = false;
    }
    return isValid
  }

  sessionModifyConfirmation(modifyData) {
    this.data.SessionLocationMappings?.map(location => {
      location.ActivitySessionId = this.data.ParentSessionId ? location.ActivitySessionId : 0,
        location.Id = this.data.ParentSessionId ? location.Id : 0,
        location.StandaloneTableId = location.StandaloneTableId
    })
    this.data.SessionStaffMappings?.map(staff => {
      staff.ActivitySessionId = this.data.ParentSessionId ? staff.ActivitySessionId : 0,
        staff.Id = this.data.ParentSessionId ? staff.Id : 0,
        staff.StandaloneTableId = staff.ServerId
    })
    let locationIndex = modifyData.changes?.find(elem => elem.label == 'location');
    if (this.data.SessionLocationMappings?.length && locationIndex) {
      this.data.SessionLocationMappings[0].StandaloneTableId = Number(locationIndex.newPropertyId);
    }
    let instructorIndex = modifyData.changes?.find(elem => elem.label == 'instructor');
    if (this.data.SessionStaffMappings?.length && instructorIndex) {
      this.data.SessionStaffMappings[0].ServerId = Number(instructorIndex.newPropertyId);
    }
    let ActivitySessionId = this.data.ParentSessionId ? this.data.ActivitySessionId : 0
    const reqObj: ModifySessionRequestState[] = [{
      ActivitySessionId,
      ParentSessionId: this.data.ParentSessionId ? this.data.ParentSessionId : this.data.ActivitySessionId,
      Dayofweek: this.data.Dayofweek,
      SpecialMealId: this.data.SpecialMealId,
      Index: this.data.Index,
      SessionDate: moment(this.data.Date).format('YYYY-MM-DD') + "T00:00:00.000",
      Reason: this.data.Reason,
      AllocationTypeId: null,
      StartTime: moment.utc(this.moveDetail.StartTime, 'LT').format('HH:mm:ss'),
      EndTime: moment.utc(this.moveDetail.EndTime, 'LT').format('HH:mm:ss'),
      SessionLocationMappings: this.data.SessionLocationMappings,
      SessionStaffMappings: this.data.SessionStaffMappings,
      SessionCustomization: this.data.SessionCustomization,
      SessionGroupId: this.data.SessionGroupId
    }];
    modifyData.request = reqObj;
    this.ats.updateConfirmation(modifyData);
  }

  sessionStatus(event: Event){
    event.stopPropagation();
  }
  
  promptBlockSession(event: Event,ps){
    ps?.close();
    if(this.permissionPipe.transform(RolesAndPermissionsType.ManageActivityBlocks)){
      event.preventDefault();
      event.stopPropagation();
      this.promptBlockSessionEvent.emit(event);
    }else{
      this.toastrService.warning(this.ts.instant('accessDenied'), "", { timeOut: 5000, closeButton: true });
    }
  }

  modifySession(event: Event,ps){
    ps?.close();
    if(this.permissionPipe.transform(RolesAndPermissionsType.ModifyActivity)){
    event.preventDefault();
    event.stopPropagation();
    this.modifySessionEvent.emit(event);
    }else{
      this.toastrService.warning(this.ts.instant('accessDenied'), "", { timeOut: 5000, closeButton: true });
    }
  }
  cancelSession(event: Event,ps){
    ps?.close();
    if(this.permissionPipe.transform(RolesAndPermissionsType.CancelActivity)){
      event.preventDefault();
      event.stopPropagation();
      this.cancelSessionEvent.emit(event);
    }else{
      this.toastrService.warning(this.ts.instant('accessDenied'), "", { timeOut: 5000, closeButton: true });
      
    }
  }

  createStaffBlock(event,ps) {
    ps?.close();
    this.blockStaffEvent.emit(event);
  }

  deleteBlockStaff(event: Event,ps) {
    ps?.close();
    this.deleteBlockStaffEvent.emit(event);
  }

  addBooking(event: Event) {
    this.addBookingEvent.emit(event);
  }

}