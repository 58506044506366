import { Injectable } from '@angular/core';
import { CommonUtilities } from '@app/common/shared/shared/utilities/common-utilities';
import { ComponentDetails } from '@app/popup-module/models/popup.interface';
import { PopupService } from '@app/popup-module/popup.service';
import { ConfirmationPopupComponent } from '@app/shared/components/confirmation-popup/confirmation-popup.component';
import { ModifySessionComponent } from '@app/shared/components/modify-session/modify-session.component';
import { BookingBehavior, ComponentTypes, PartyType, RolesAndPermissionsType } from '@app/shared/constants/commonenums';
import { ModifySessionRequestState } from '@app/shared/models/global.interface';
import { PartyService } from '@app/shared/services/party.service';
import { Utilities } from '@app/shared/utilities/utilities';
import { TranslateService } from '@ngx-translate/core';
import moment from 'moment';
import { UpdateConfirmationDetailComponent } from '../components/update-confirmation-detail/update-confirmation-detail.component';
import { popupDialogDimension } from '@app/shared/constants/globalConstants';
import { DashboardFunctions } from '@app/shared/utilities/dashboard-functions';
import { FacadeService } from '@app/shared/services/facade.service';
import { ApiService } from './api.service';
import { CancelSessionPopupComponent } from '@app/shared/components/cancel-session-popup/cancel-session-popup.component';
import { AppPopupComponent } from '@app/popup-module/components/app-popup/app-popup.component';
import { MatDialog } from '@angular/material/dialog';
import { GetSnackBarMessagesPipe } from '@app/shared/pipes/snackbar-messages.pipe';
import { ToastrService } from 'ngx-toastr';
import { RolesAndPermissionPipe } from '@app/shared/pipes/RolesAndPermission.pipe';
import { StaffBlockDTO } from '@app/shared/models/RestaurantDTO';

@Injectable()

export class ActivitiesTimelineService {

    oneMinutePixels: number;
    viewPort;
    lockResponse;
    serviceCharge: any;
    tax: any;
    taxOnServiceCharge: any;
    grandTotal: any;
    subtotal: any;
    grandTotalValidated: any;
    sessionWidth: number;

    constructor(public partyService: PartyService, public translateService: TranslateService, public ps: PopupService, private utilities: CommonUtilities, public dashboardFunctions: DashboardFunctions,
        private facadeService: FacadeService, private apiService: ApiService , protected dialog: MatDialog , public toastrService?: ToastrService ,private permissionPipe?: RolesAndPermissionPipe) {
    }

    modifySession([activityData, item], CurrentDateSelected) {
        let options, isModifyApiCallInProgress: boolean;
        let title = this.translateService.instant('ModifySession');
        const popUpMessage = [{
            dialogTitle: title,
            showAlert: false,
            options: options,
            activityData: JSON.parse(JSON.stringify(activityData)),
            sessionData: JSON.parse(JSON.stringify(item))
        }];
        const componentDetails: ComponentDetails = Utilities.setComponentDetails(ModifySessionComponent, 'medium', '', popUpMessage,
            popUpMessage[0].dialogTitle);
        let dialogRef = this.partyService.openCustomPopup(componentDetails, ComponentTypes.ModifySession, '700px', 'auto', false,
            popUpMessage[0].dialogTitle, this.translateService.instant('save'), 'Cancel', true);

        dialogRef.afterClosed().subscribe((val) => {
            if (val == ComponentTypes.ModifySession && !isModifyApiCallInProgress) {
                console.log('isModifyApiCallInProgress', isModifyApiCallInProgress)
                isModifyApiCallInProgress = true;
                const ActivitySessionId = item.ParentSessionId ? item.ActivitySessionId : 0;
                const locationMappings = this.partyService.modifySingleSessionMappingValue[0].SessionLocationMappings.map(val => {
                    val.ActivitySessionId = ActivitySessionId;
                    return val;
                });

                const staffMappings = this.partyService.modifySingleSessionMappingValue[0].SessionStaffMappings.map(val => {
                    val.ActivitySessionId = ActivitySessionId;
                    return val;
                });

                const reqObj: ModifySessionRequestState[] = [{
                    ActivitySessionId,
                    ParentSessionId: item.ParentSessionId ? item.ParentSessionId : item.ActivitySessionId,
                    Dayofweek: item.Dayofweek,
                    SpecialMealId: item.SpecialMealId,
                    Index: item.Index,
                    SessionDate: moment(CurrentDateSelected).format('YYYY-MM-DD') + "T00:00:00.000",
                    Reason: this.partyService.modeifySingleSessionForm?.value?.Reason,
                    AllocationTypeId: this.partyService.modifySingleSessionMappingValue[0].AllocationTypeId || null,
                    StartTime: this.partyService.modifySingleSessionMappingValue[0].StartTime,
                    EndTime: this.partyService.modifySingleSessionMappingValue[0].EndTime,
                    SessionLocationMappings: locationMappings,
                    SessionStaffMappings: staffMappings,
                    SessionCustomization: {
                        MaxActivityStrength: Number(this.partyService.modeifySingleSessionForm?.value?.MaxActivityStrength || item.MaxActivityStrength)
                    },
                    SessionGroupId: item.SessionGroupId,
                    LocationGroupId: this.partyService.modifySingleSessionMappingValue[0].LocationGroupId
                }]
                console.log(reqObj);

                this.partyService.modifySession(reqObj, item.SpecialMealId, this.partyService.modeifySingleSessionForm?.value?.SendMail).subscribe(response => {
                    this.showSuccessDialog();
                }, err => {
                }, () => {
                    // isModifyApiCallInProgress = false;
                    // modifySessionConfirmSubscription.unsubscribe();
                })
            } else {
                // modifySessionConfirmSubscription.unsubscribe();
            }
        })

        // let modifySessionConfirmSubscription = this.ps.confirmedAction$.subscribe(val => {
        //     if (val == ComponentTypes.ModifySession && !isModifyApiCallInProgress) {
        //         console.log('isModifyApiCallInProgress', isModifyApiCallInProgress)
        //         isModifyApiCallInProgress = true;

        //         const locationMappings = this.partyService.modifySingleSessionMappingValue[0].SessionLocationMappings.map(val => {
        //             val.ActivitySessionId = item.ParentSessionId ? item.ParentSessionId : 0;
        //             return val;
        //         });

        //         const staffMappings = this.partyService.modifySingleSessionMappingValue[0].SessionStaffMappings.map(val => {
        //             val.ActivitySessionId = item.ParentSessionId ? item.ParentSessionId : 0;
        //             return val;
        //         });

        //         const reqObj: ModifySessionRequestState[] = [{
        //             ActivitySessionId: item.ParentSessionId ? item.ActivitySessionId : 0,
        //             ParentSessionId: item.ParentSessionId ? item.ParentSessionId : item.ActivitySessionId,
        //             Dayofweek: item.Dayofweek,
        //             SpecialMealId: item.SpecialMealId,
        //             Index: item.Index,
        //             SessionDate: moment(CurrentDateSelected).format('YYYY-MM-DD') + "T00:00:00.000",
        //             Reason: this.partyService.modeifySingleSessionForm?.value?.Reason,
        //             AllocationTypeId: this.partyService.modifySingleSessionMappingValue[0].AllocationTypeId || null,
        //             StartTime: this.partyService.modifySingleSessionMappingValue[0].StartTime,
        //             EndTime: this.partyService.modifySingleSessionMappingValue[0].EndTime,
        //             SessionLocationMappings: locationMappings,
        //             SessionStaffMappings: staffMappings,
        //             SessionCustomization: {
        //                 MaxActivityStrength: Number(this.partyService.modeifySingleSessionForm?.value?.MaxActivityStrength || item.MaxActivityStrength)
        //             },
        //             SessionGroupId: item.SessionGroupId
        //         }]
        //         console.log(reqObj);

        //         this.partyService.modifySession(reqObj, activityData.Id).subscribe(response => {
        //             this.showSuccessDialog();
        //         }, err => {
        //         }, () => {
        //             // isModifyApiCallInProgress = false;
        //             modifySessionConfirmSubscription.unsubscribe();
        //         })
        //     } else {
        //         modifySessionConfirmSubscription.unsubscribe();
        //     }
        // });
    }

    cancelSession([activityData, item], CurrentDateSelected) {
        const restaurantDateTime = Utilities.getRestaurantDateTime(this.partyService._settings.General.DaylightDelta);
        let endTime = moment(item.Date).format('YYYY-MM-DD') + 'T' + item.EndTime;
        if (!this.permissionPipe.transform(RolesAndPermissionsType.CancelActivity)) {
            this.toastrService.warning(this.translateService.instant('accessDenied'), "", { timeOut: 5000, closeButton: true });
            return;
        }
        else if (restaurantDateTime > new Date(endTime)) {
            this.toastrService.warning(this.translateService.instant('sessionExpiredCancelMessage'), "", { timeOut: 5000, closeButton: true });
            return;
        }

        let parties = this.partyService.Parties$?.value?.filter(party => party.BookedSessions?.filter(session => session.ActivitySessionId == item?.ActivitySessionId)?.length);
        let standbyParties = this.partyService.StandbyParties$?.value?.filter(party => party.BookedSessions?.filter(session => session.ActivitySessionId == item?.ActivitySessionId)?.length);

        const componentDetails: ComponentDetails = {
            componentName: CancelSessionPopupComponent,
            dimensionType: 'small',
            popupType: 'active',
            popUpDetails: {
                isStepper: false,
                eventName: 'notifyParent'
            },
            popupInput: {
                activityData: JSON.parse(JSON.stringify(activityData)),
                sessionData: JSON.parse(JSON.stringify(item)),
                cancellationDate: CurrentDateSelected,
                bookings: [...parties, ...standbyParties]
            }
        };
        const dialogRef = this.dialog.open(AppPopupComponent, {
            disableClose: true,
            width: '550px',
            height: '450px',
            data: {
                title: 'cancelConfirmationMsg',
                update: 'Yes',
                cancel: 'No',
                componentDetails,
                from: ComponentTypes.cancelIndividualSession,
                standalone: true,
                showAlert: true,
            }
        });
    }

    showSuccessDialog() {
        const popUpMessage = [{
            confirmationMessage: this.translateService.instant('SessionModifySuccess'),
            dialogTitle: null,
        }];
        let okbutton = this.translateService.instant('ok');
        const componentDetails: ComponentDetails = Utilities.setComponentDetails(ConfirmationPopupComponent, 'small', 'action',
            popUpMessage, popUpMessage[0].dialogTitle);
        this.partyService.openAppPopup(componentDetails, ComponentTypes.ModifySessionConfirmation, '400px', '400px', true, '', okbutton,
            '', true);
        let popupSubscription = this.ps.confirmedAction$.subscribe(val => {
            if (val == ComponentTypes.ModifySessionConfirmation) {
                popupSubscription.unsubscribe();
            }
        });
    }

    updateConfirmation(data: ModifyConfirmationData, componentType?: ComponentTypes) {
        let emailConfirmationConfig;

        if (componentType !== ComponentTypes.EditOpenBooking && componentType !== ComponentTypes.EditPrivateLessonBooking) {

            emailConfirmationConfig = [{
                type: 'checkbox',
                name: 'SendMail',
                value: true,
                placeholder: this.translateService.instant('SendMailConfirmation'),
                class: 'session-edit-send-mail-chekbox'
            }]
        }
        const componentDetails: ComponentDetails = {
            componentName: UpdateConfirmationDetailComponent,
            popupType: '',
            dimensionType: 'small',
            popUpDetails: {
                isStepper: false,
                eventName: 'notifyParent'
            },
            popupInput: { inputData: data, formConfig: emailConfirmationConfig },
            popupTitle: 'Confirmation'
        };

        const dialogRef = this.partyService.openAppPopup(componentDetails, ComponentTypes.commonconfirmmessage,
            popupDialogDimension.actionDialogWidth, 'auto', false, this.translateService.instant('Confirmation'), this.translateService.instant('ok'), this.translateService.instant('cancel'), true);

        dialogRef.afterClosed().subscribe((result: { action: "cancelled" | "confirmed", data }) => {
            if (result?.action === "confirmed") {
                // this.partyService.Operations = 2;
                // if(this.partyService.IsSkipPayment) {
                //     this.partyService.IsSkipPayment = false;
                // }
                // if (componentType == ComponentTypes.EditOpenBooking) {
                //     this.partyService.updateOpenBooking(this.partyService.openBookingDataSaveObj, false)
                // }
                // else if (componentType == ComponentTypes.EditPrivateLessonBooking) {
                //     this.partyService.updatePrivateLessonBooking(this.partyService.privateLessonBookingDataSaveObj, false)
                // }
                // else {
                    this.sessionUpdate(data, result.data);
                // }
            }

            if(result?.action == "cancelled" && this.partyService.slotLockId) {
                this.partyService.unlockSlot(this.partyService.slotLockId);
            }
        })
    }

    sessionUpdate(updateData: ModifyConfirmationData, confirmationData) {
        let modifySubscription = this.partyService.modifySession(updateData.request, updateData.request[0].SpecialMealId, confirmationData.SendMail).subscribe(response => {
            this.showSuccessDialog();
            if (modifySubscription) {
                modifySubscription.unsubscribe();
            }
        });
    }

    getBooking(updateData, partyData, modifyDetails?) {
        this.partyService.getPartyInfo(partyData.Id).subscribe(response => {
            if (response.Payload) {
                if(partyData.isOpenBooking) {
                    response.Payload.isOpenBooking = true;
                    if(!response.Payload.Duration) { response.Payload.Duration = partyData.Duration; }
                    this.partyService.BookingBehaviour = BookingBehavior.OpenBooking;
                }
                else if(partyData.isPrivateLessonBooking) {
                    response.Payload.isPrivateLessonBooking = true;
                    if(!response.Payload.Duration) { response.Payload.Duration = partyData.Duration; }
                    this.partyService.BookingBehaviour = BookingBehavior.PrivateLesson;
                }
                partyData = response.Payload;
              this.lockSlotTable(updateData, partyData, modifyDetails)
            }
          });
    }

    lockSlotTable(updateData, partyData, modifyDetails?) {
        if (updateData) {
            let locationIndex = updateData.changes?.find(elem => elem.label == 'location');
            let bypassLock = partyData.Type == PartyType.StandBy;
            partyData.TableIds = locationIndex ? [Number(locationIndex.newPropertyId)] : partyData.TableIds;
            let instructorIndex = updateData.changes?.find(elem => elem.label == 'instructor');
            partyData.StaffIds = instructorIndex ? [Number(instructorIndex.newPropertyId)] : partyData.StaffIds;
            var slotRequest = {
                LocalTime: modifyDetails ? this.getSlotTime(modifyDetails.StartTime, partyData.SeatingTime) : partyData.SeatingTime,
                PartySize: partyData.Size,
                OverBooked: this.partyService.openBookingData?.OverBooked ? partyData.Size : 0,
                SeatingAreaId: null,
                SeatingTypeId: null,
                IsCommunalTable: false,
                FromWebsite: true,
                DurationInMinutes: modifyDetails ? moment.duration(moment.utc(modifyDetails.EndTime, 'LT').diff(moment.utc(modifyDetails.StartTime, 'LT')), 'milliseconds').asMinutes() : partyData.Duration,
                Duration: modifyDetails ? moment.duration(moment.utc(modifyDetails.EndTime, 'LT').diff(moment.utc(modifyDetails.StartTime, 'LT')), 'milliseconds').asMinutes() : partyData.Duration,
                ReservationIdToIgnore: partyData?.Id,
                TableIds: partyData.TableIds,
                AddOns: this.getAddons(partyData),
                ExpireAt: null,
                lockIdToIgnore: undefined
            }
            let acquireSubscription = this.apiService.lockTables([slotRequest], bypassLock).subscribe((response: any) => {
                if (response.Payload && Object.values(response.Payload)[0]?.['Available']) {
                    this.lockResponse = response.Payload;
                    this.partyService.slotLockId = Object.values(response.Payload)[0]?.['SlotLockIdDTO']?.Id;
                    this.getRatePlanCalculated([slotRequest], partyData,updateData);
                    // this.updateConfirmation(updateData, partyData.isOpenBooking ? ComponentTypes.EditOpenBooking : ComponentTypes.EditPrivateLessonBooking);
                }
                else {
                    const popUpMessage = [{
                        confirmationMessage: `${this.translateService.instant('SelectedSlotNotAvailable')}`,
                        dialogTitle: this.translateService.instant('errorTitle'),
                        showAlert: true
                    }];
                    const componentDetails: ComponentDetails = Utilities.setComponentDetails(ConfirmationPopupComponent, 'small',
                        'action', popUpMessage, '');
                    this.partyService.openAppPopup(componentDetails, ComponentTypes.timeline, '450px', 'auto', true, '', this.translateService.instant('ok'),
                        this.translateService.instant('cancel'), true);
                }
                if (acquireSubscription) {
                    acquireSubscription.unsubscribe();
                }
            });
        }
    }

    getSlotTime(moveTime: string, partyDate: string) {
        let localTime = moment.utc(moveTime, 'LT').format("HH:mm:ss");
        let date = moment(partyDate).format('YYYY-MM-DD');
        return date + 'T' + localTime;
    }

    getAddons(partyData) {
        let addons = [];
        if (partyData.BookingContactAddonItems?.length) {
            this.partyService._settings?.Addons?.filter(addon => {
                partyData.BookingContactAddonItems?.some(contactAddon => {
                    if (addon.AddonId === contactAddon.AddonId) {
                        addons.push({ Name: addon.AddonName, Price: contactAddon.Amount, AddonId: contactAddon.AddonId, Quantity: contactAddon.AddonCount, Category: addon.AddOnDetails?.length ? addon.AddOnDetails[0].PricingCategory : 0, OverBooked: contactAddon.OverBooked })
                    }
                })
            })
        }
        return addons;
    }

    getRatePlanCalculated(data, partyData,updateData) {
        let request = this.formRatePlanRequestDTO(partyData,data);

        // var request = {
        //     Slots: data,
        //     ActivityId: null,
        //     GuestTypes: partyData.Covers,
        //     BookingTypes: partyData.BookingTypeQuantities,
        //     TableIds: partyData.TableIds,
        //     Size: partyData.Size,
        //     SessionSlot: null,
        //     BookingBehavior: partyData.isOpenBooking ? BookingBehavior.OpenBooking : BookingBehavior.PrivateLesson,
        //     SelectedAddOns: this.getAddons(partyData),
        //     SeatingTypeIds: null
        // };
        let ratePlansubscription = this.apiService.GetRatePlanCalculated(request, null).subscribe(response => {
            if(response.Payload){
            this.partyService.FinancialEffectId = response.Payload.FinancialEffectId;
            this.partyService.ratePlanForBooking = response.Payload;
            this.partyService.ratePlanObject = response.Payload;
            let reqObj = this.createSaveRequestObject(data, partyData);
            if (partyData?.isOpenBooking) {
                this.partyService.openBookingDataSaveObj = reqObj;
            }
            else if (partyData.isPrivateLessonBooking) {
                this.partyService.privateLessonBookingDataSaveObj = reqObj;
                this.partyService.privateLessonBookingDataSaveObj.InstructorIds = partyData.StaffIds;
                this.partyService.privateLessonBookingDataSaveObj.SpecialMealId = partyData.SpecialMealId;
            }
            this.calculatePaymentDetails(response.Payload, partyData);
            this.setRateSummary(partyData);
            this.partyService.Operations = 2;
            if(this.partyService.IsSkipPayment) {
                this.partyService.IsSkipPayment = false;
            }
            if (partyData?.isOpenBooking) {
                this.partyService.openBookingDataSaveObj.modifyDetails = updateData;
                this.partyService.addToCartRequestObj = this.partyService.openBookingDataSaveObj;
                this.partyService.updateOpenBooking(this.partyService.addToCartRequestObj, false)
            }
            else if (partyData.isPrivateLessonBooking) {
                this.partyService.privateLessonBookingDataSaveObj.modifyDetails = updateData;
                this.partyService.addToCartRequestObj = this.partyService.privateLessonBookingDataSaveObj;
                this.partyService.updatePrivateLessonBooking(this.partyService.addToCartRequestObj, false)
            }
        }
            if (ratePlansubscription) {
                ratePlansubscription.unsubscribe();
            }
        
        });
        let contactSubscription = this.facadeService.getContact(partyData.Contact.Id, partyData.Id).subscribe(data => {
            this.setguestDetails(data.Payload, partyData);
            if (contactSubscription) {
                contactSubscription.unsubscribe();
            }
        });
    }

    formRatePlanRequestDTO(partyData,data) {
        this.getSelectedAddons(partyData);
        return {
            Slots: [{
                Time: data[0].LocalTime,
                DurationInMinutes: data[0].Duration,
                EndTime: partyData?.EndTime,
                PartyId: partyData ? partyData.Id : null,
                AllocationTypeId: null
              }],
            SessionSlot: {
                Sessions: [],
            },
            Size: partyData.Size,
            TableIds: partyData.TableIds || [],
            CoverTypes: partyData.CoverTypeQuantities,
            ActivityId: partyData.ActivityId || null,
            FinancialEffectId: null,
            BookingBehavior: partyData.isOpenBooking ? BookingBehavior.OpenBooking : BookingBehavior.PrivateLesson,
            StartDate: data[0].LocalTime,
            EndDate: data[0].LocalTime,
            BookingContacts: partyData.BookingContacts,
            RatePlan: null,
            AddOns: [],
            InstructorIds: partyData.StaffIds || [],
            PartyId: partyData.Id
        };

    }

    getSelectedAddons(partyData) {
        partyData.BookingContacts?.map((bookingContact) =>{
            bookingContact.Addons = [];
            if(partyData.BookingContactAddonItems?.length)
            partyData.BookingContactAddonItems.map(addon => {
        if(bookingContact.Id === addon.BookingContactId)
                bookingContact.Addons.push({ AddonId: addon.AddonId, Quantity: addon.AddonCount});
            })
        })
    }

    setguestDetails(data, partyData) {
        this.partyService.selectedGuest = data;
        this.partyService.reservationFormGroup.get('selectedGuest').setValue(null);

        if (data) {
            data.memberShipId = this.partyService.selectedGuest?.memberShipId;
            data.MembershipType = this.partyService.selectedGuest?.MembershipType;
            data.MemberActive = this.partyService.selectedGuest?.MemberActive;
            data.RateType = this.partyService.selectedGuest?.RateType;
        } else {
            data = {};
            data.memberShipId = this.partyService.selectedGuest?.memberShipId;
            data.MembershipType = this.partyService.selectedGuest?.MembershipType;
            data.MemberActive = this.partyService.selectedGuest?.MemberActive;
            data.RateType = this.partyService.selectedGuest?.RateType;
        }

        if (partyData && partyData.Contact && !partyData.Id && partyData.Contact.SecondaryContactsList?.length) {
            data.SecondaryContactsList = partyData.Contact.SecondaryContactsList;
        }

        this.partyService.reservationFormGroup.get('selectedGuest').setValue(data);
        this.partyService.isNewGuestFromReservation = false;
    }

    createSaveRequestObject(slots, partyData) {
        let saveRequestObj = {
            Id: (partyData && partyData.Id) ? partyData.Id : null,
            Size: partyData.Size,
            BookingSize: partyData.Size,
            RemindAboutVisit: false,
            RemindAboutVisitEmail: false,
            AgreedWithTermsOfUse: true,
            Contact: partyData.Contact,
            Slots: this.getRequestObjectSlots(slots, partyData),
            PartyId: (partyData && partyData.Id) ? partyData.Id : null,
            SeatingAreaId: partyData.SeatingAreaId ? partyData.SeatingAreaId : null,
            SeatingTypeId: partyData.SeatingTypeId ? partyData.SeatingTypeId : null,
            ConfirmationMessageOption: 0,
            Notes: partyData.Notes,
            TableIds: partyData.TableIds,
            CoverTypes: partyData.Covers ? partyData.Covers : [],
            BookingTypes: partyData.BookingTypeQuantities ? partyData.BookingTypeQuantities : [],
            IsCommunalTable: false,
            FromWebsite: true,
            Language: 0,
            ReservationAttemptId: null,
            waiverFormTransactionId: null,
            SecondaryContactsList: null,
            BookingBehavior: partyData.isOpenBooking ? BookingBehavior.OpenBooking : BookingBehavior.PrivateLesson,
            StartDate: new Date(partyData.StartDate).toDateString(),
            EndDate: new Date(partyData.EndDate).toDateString(),
            NegotiatedAmount: partyData.NegotiatedAmount,
            Comments: this.dashboardFunctions.negotiationReason(partyData),
            AttemptSlots: this.getAttemptSlotObj(slots),
            TotalPayable: partyData.RatePlanAmount,
            TaxAmount: partyData.TaxAmount,
            PackageConfirmationId: (partyData && partyData.PackageConfirmationId) ? partyData.PackageConfirmationId : null,
            PackageId: (partyData && partyData.PackageId) ? partyData.PackageId : null,
            IsForStandbyReservations: false,
            Type: PartyType.Reservation,
            HostId: partyData?.Id ? partyData.HostId : JSON.parse(localStorage.getItem(`${sessionStorage.getItem(`sessionGUID${Utilities.getSessionStorageType()}`)}_loginResult`)).HostId,
            FinancialEffectId: this.partyService.FinancialEffectId,
            RetailCardTokenId: this.partyService.RetailCardTokenId,
            PaymentMethod: partyData.PaymentMethod,
            summaryState: 'valid',
            SalesContactIds: partyData?.SalesContactIds,
            OverBooked: partyData?.OverBooked,
            MinRateNegotiatedAdjustment: 0,
            Addons: this.getAddons(partyData),
            SelectedAddOns: this.getAddons(partyData),
            BookingContacts: partyData.BookingContacts,
            isDragged: true,
        } as any;
        saveRequestObj.Slot = saveRequestObj.Slots[0];
        return saveRequestObj;
    }

    getRequestObjectSlots(slots, partyData) {
        return slots.map((slot, index) => {
            return {
                Time: slot.LocalTime,
                LockId: null,
                Type: 3,
                DurationInMinutes: slot.Duration,
                OverBooked: this.partyService.isOverBookSlotSelected ? partyData.OverBooked : null
            }
        })
    }

    getAttemptSlotObj(timeslots) {
        return timeslots.map((slot) => {
            return {
                LocalTime: slot.LocalTime,
                Duration: slot.Duration,
                ReservationAttemptId: null
            }
        })
    }

    calculatePaymentDetails(payload, partyData) {
        this.onNegotiate(payload, partyData, true);
        this.serviceCharge = payload.RatePlan.ServiceCharge;
        this.tax = payload.RatePlan.Tax;
        this.taxOnServiceCharge = payload.RatePlan.TaxOnServiceChargeAmount
        this.grandTotal = payload.RatePlan.Total;
    }

    onNegotiate(payload, partyData, forGrandTotal?) {
        if (partyData.isOpenBooking) {
            this.partyService.openBookingDataSaveObj.summaryState = forGrandTotal ? 'valid' : 'invalid';
        } else {
            this.partyService.privateLessonBookingDataSaveObj.summaryState = forGrandTotal ? 'valid' : 'invalid';
        }
        this.grandTotalValidated = forGrandTotal || false;
        this.subtotal = payload.RatePlan.SubTotal;
    }

    setRateSummary(partyData) {
        if (partyData.isOpenBooking) {
            this.partyService.openBookingDataSaveObj.rateSummary = { Amt: this.subtotal, TaxAmt: this.tax, SCAmt: this.serviceCharge, TaxOnSCAmt: this.taxOnServiceCharge };
        }
        else {
            this.partyService.privateLessonBookingDataSaveObj.rateSummary = { Amt: this.subtotal, TaxAmt: this.tax, SCAmt: this.serviceCharge, TaxOnSCAmt: this.taxOnServiceCharge };
        }
    }

    createStaffBlockHr(headerDate: Date | string, staffSchedule: any, staffIds?: number[], startTime?: string, endTime?: string, reason?: string,breakOrBlockHourId: number = 0, isEdit: boolean = false, shiftId?: number, scheduleId?: number) {
        let staffBlockObj: StaffBlockDTO = { isEdit: isEdit || false, Date: new Date(headerDate), BreakHourId: breakOrBlockHourId, StaffIds: staffIds, EffectiveRange: { Start: startTime || null, End: endTime || null, Reason: reason}, ShiftId: shiftId || null, StaffScheduleId: scheduleId ? [scheduleId] : [] };
        this.dashboardFunctions.blockStaff(staffBlockObj,staffSchedule);
    }

    deleteStaffBlock(staffBlockObj: StaffBlockDTO) {
        this.dashboardFunctions.deleteBlockStaffConfirmation(staffBlockObj);
    }
}

export interface ModifyConfirmationData {
    title: string,
    subText: string,
    request?: any,
    changes: {
        label: string,
        newValue: string,
        oldValue: string,
        newPropertyId: string
    }[]
}
